import { BrandService } from 'src/app/components/myAccount/manage-brand/service/BrandService';
import { brand } from 'src/app/models/admin';
import { Component, OnInit } from '@angular/core';
import { cSessionService } from './../../../services/session.service';
import { cWebSocketService } from './../../../services/websocket.service';
import { cDataService } from './../../../services/data.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { cClientService } from './../../../services/client.service';
import { OnboardingManagerService } from './../../../Managers/onboarding-manager.service';
import { VideoManagerService } from './../../../Managers/video-manager.service';
import { Console, log } from 'console';
import { environment } from './../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { AccountManagerService } from 'src/app/Managers/account-manager.service';
import { ErrorService } from 'src/app/services/error.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MediaManagerService } from 'src/app/Managers/media-manager.service';

declare var $: any;

@Component({
    selector: 'app-dashboard-socialmedia',
    templateUrl: './dashboard-socialmedia.component.html',
    styleUrls: ['./dashboard-socialmedia.component.scss'],
})
export class DashboardSocialmediaComponent implements OnInit {
    DisconnectSocialForm: FormGroup;
    connected_social_platforms: any;

    oUserName: any;
    oSocialChannel: string;
    sDisplayDisconnectErrorMessage: string;
    bDisplayErrorMessage: boolean = false;
    sSessionId: string;
    sUserEmail: string;
    pages: any = [];
    nIncomingPage: any;
    oVideoDetails: any;
    sDisplayResponseMessage: string;
    bDisplaySuccessMessage: boolean = false;
    oSocialPlatformValue: any = '';
    return_url: string;

    socialMediaName: string;
    inCommingSocialMedia: string;
    disconnectMediaPopup = false;
    paramsObject: any;
    sVideoId: string;
    statusResult: string;
    isBrandVideo = false;
    brandDetail: any;
    brandSocialNetworkConnection = {
        fb: false,
        linkedin: false,
        youtube: false,
        instagram: false
    };
    constructor(
        private oSessionService: cSessionService,
        private oFormBuilder: FormBuilder,
        private oDataService: cDataService,
        public onboardingManagger: OnboardingManagerService,
        public videoManager: VideoManagerService,
        private oClientService: cClientService,
        private errorService: ErrorService,
        private accountManager: AccountManagerService,
        private route: ActivatedRoute,
        public loader: LoaderService,
        private brandService: BrandService,
        private mediaManger: MediaManagerService,
    ) { }

    async ngOnInit() {
        // this.connected_social_platforms =
        //   this.onboardingManagger.profile.connected_social_platforms;

        //   console.log("this.onboardingManagger.profile.connected_social_platforms  ", this.onboardingManagger.profile.connected_social_platforms);

        if (!this.onboardingManagger.profile) {
            await this.onboardingManagger.getUserProfile(this.sUserEmail, this.sSessionId).then((res) => {
                this.connected_social_platforms = this.onboardingManagger.profile.connected_social_platforms;
            });
        } else {
            this.connected_social_platforms = this.onboardingManagger.profile.connected_social_platforms;
        }

        console.log(' this.connected_social_platforms ', this.connected_social_platforms);

        this.sSessionId = this.oSessionService.SessionService_GetSessionId();
        this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
        this.oVideoDetails = this.videoManager.selectedVideo;
        this.sVideoId = this.oVideoDetails?.fpath;

        if (!this.oVideoDetails || this.videoManager.selectedVideo == undefined || this.videoManager.selectedVideo.video_id == '') {
            this.route.queryParamMap.subscribe(async (params) => {
                this.paramsObject = { ...params.keys, ...params };
                this.sVideoId = this.paramsObject.params.video_id;
                this.statusResult = this.paramsObject.params.result;

            });
            if (this.sVideoId && this.sVideoId != '') {
                this.loader.showLoader();
                this.oSessionService.cSessionService_SetVideoId(this.sVideoId);
                const responseVid = await this.loadVideoInformation(this.sVideoId);
            } else {
                this.sVideoId = this.oSessionService.SessionService_GetVideoId();
                this.loader.showLoader();
                await this.loadVideoInformation(this.sVideoId);
            }
        } else {
            if (this.oVideoDetails.fpath) {
                this.sVideoId = this.oVideoDetails.fpath;
                this.oSessionService.cSessionService_SetVideoId(this.sVideoId);
                await this.loadVideoInformation(this.sVideoId);
            }
        }

        this.return_url = environment.WS_HOST + '/dashboard/socialmedia?video_id=' + this.videoManager.selectedVideo?.fpath;

        this.DisconnectSocialForm = this.oFormBuilder.group({
            platform: '',
        });
    }

    async loadVideoInformation(videoId): Promise<any> {
        return new Promise(async (resolve, reject) => {
            this.sVideoId = videoId;
            this.loader.showLoader();
            await this.videoManager
                .getVideoById(this.sUserEmail, this.sSessionId, this.sVideoId)
                .then(async (res: any) => {
                    this.oVideoDetails = res;
                    this.loader.hideLoader();
                    this.videoManager.selectedVideo = res;

                    if (this.videoManager.selectedVideo.brand_id && this.onboardingManagger.profile.plan == 'enterprise') {
                        this.isBrandVideo = true;
                        this.checkBrandFBConnected();
                        this.checkBrandYouTubeConnected();
                        this.checkBrandLinkedInConnected();
                        this.checkBrandInstagramConnected();
                    }
                    await this.videoManager.getVideoSignedURL(this.sUserEmail, this.sSessionId, res).then((data) => {
                        this.videoManager.selectedVideo.video_url = data.url;
                    });
                    await this.getVideoThumbnail(res.thumb);
                    if (this.videoManager.selectedVideo.brand_id) {
                        this.brandService.getBrandProfile(this.videoManager.selectedVideo.brand_id, true).then((profile) => {
                            this.brandDetail = profile;
                        });
                    }
                    setTimeout(() => {
                        this.statusResult = '';
                    }, 2000);
                    resolve(res);
                })
                .catch((err) => {
                    this.loader.hideLoader();
                    reject(undefined);
                });
        });
    }

    checkBrandFBConnected(): void {
        this.brandService.getFBAccessToken(this.videoManager.selectedVideo.brand_id).then((accessToken: any) => {
            if (accessToken.result) {
                this.brandSocialNetworkConnection.fb = true;
                this.connected_social_platforms.facebook = true;
            } else {
                this.brandSocialNetworkConnection.fb = false;
                this.connected_social_platforms.facebook = false;
            }
        });
    }
    checkBrandLinkedInConnected(): void {
        this.brandService.getLinkInAccessToken(this.videoManager.selectedVideo.brand_id).then((accessToken: any) => {
            if (accessToken.result) {
                this.brandSocialNetworkConnection.linkedin = true;
                this.connected_social_platforms.linkedin = true;
            } else {
                this.brandSocialNetworkConnection.linkedin = false;
                this.connected_social_platforms.linkedin = false;
            }
        });
    }
    checkBrandInstagramConnected(): void {
        this.brandService.getInstagramAccessToken(this.videoManager.selectedVideo.brand_id).then((accessToken: any) => {
            if (accessToken.result) {
                this.brandSocialNetworkConnection.instagram = true;
                this.connected_social_platforms.instagram = true;
            } else {
                this.brandSocialNetworkConnection.instagram = false;
                this.connected_social_platforms.instagram = false;
            }
        });
    }
    checkBrandYouTubeConnected(): void {
        this.brandService.getYouTubeAccessToken(this.videoManager.selectedVideo.brand_id).then((accessToken: any) => {
            if (accessToken.result) {
                this.brandSocialNetworkConnection.youtube = true;
                this.connected_social_platforms.youtube = true;
            } else {
                this.brandSocialNetworkConnection.youtube = false;
                this.connected_social_platforms.youtube = false;
            }
        });
    }
    DashboardSocialMediaComponent_ShareVideoOnSocialChannel(value: any, event: any) {
        event.stopPropagation();
        this.oSocialPlatformValue = value;
        if (value == 'google' && (this.connected_social_platforms.google || this.brandSocialNetworkConnection.youtube)) {
            $('#share-modal-Youtube').modal('show');
        } else if (value == 'linkedin' && this.connected_social_platforms.linkedin) {
            $('#share-modal-linkedin').modal('show');
        } else if (
            (value == 'facebook' && this.connected_social_platforms.facebook) ||
            (value == 'instagram' && this.connected_social_platforms.instagram)
        ) {
            $('#share-modal').modal('show');
            this.pages = [];
            let params = new HttpParams();
            params = params.set('email', this.sUserEmail);
            params = params.set('session_id', this.sSessionId);
            let url = cDataService.faceBookPages + '?' + params.toString();
            try {
                this.oDataService.DataService_GetFacebookPages(url).subscribe(
                    (oResponse) => {
                        this.pages = oResponse.pages;
                        if (this.pages) {
                            this.nIncomingPage = this.pages[0];
                        }
                    },
                    (error) => {
                        if (error.url) {
                            console.log('CustomEditVideo_FacebookChange : Error ==> ', error);
                            window.location.assign(error.url);
                        } else {
                            console.log('CustomEditVideo_FacebookChange : Error ==> ', error);
                        }
                    },
                );
            } catch (oError) {
                console.log('CustomEditVideo_FacebookInstaChange: exception:');
                console.log(oError);
            }
        }
    }

    DashboardSocialMediaComponent_SocialMediaChannelsLink(oData: any) {
        this.oSessionService.cSessionService_SetVideoId(this.videoManager?.selectedVideo?.fpath);
        let sURL: string = '';

        if (oData == 'google') {
            sURL = this.oDataService.googleJoinLink + '?' + 'email=' + this.sUserEmail + '&session_id=' + this.sSessionId;
        } else if (oData == 'facebook') {
            sURL = this.oDataService.faceBookJoinLink + '?' + 'email=' + this.sUserEmail + '&session_id=' + this.sSessionId;
        } else if (oData == 'instagram') {
            sURL = this.oDataService.instagramJoinLink + '?' + 'email=' + this.sUserEmail + '&session_id=' + this.sSessionId;
        } else if (oData == 'linkedin') {

            if (this.isBrandVideo && this.connected_social_platforms.linkedin) {
                sURL = 'linkedin_brand_upload?' + 'brand_id=' + this.oVideoDetails.brand_id + '&email=' + this.sUserEmail + '&session_id=' + this.sSessionId;
            } else {
                sURL = this.oDataService.linkedInJoinLink + '?' + 'email=' + this.sUserEmail + '&session_id=' + this.sSessionId;
            }

        }

        this.oSessionService.cSessionService_SetRedirectURL(3);
        location.assign(sURL);
    }

    getSocialConnectionMessage(isConnected : boolean, network : string) : string{
        let brandName = this.brandDetail?.name ? this.brandDetail.name : '';
        return this.oDataService.getSocialConnectionMessage(isConnected, network, this.oVideoDetails?.brand_id , brandName);
    }
    DashboardSocialMediaComponent_DisconnectSocialChannel(event: any) {
        event.stopPropagation();
        this.disconnectMediaPopup = false;
        let value = this.inCommingSocialMedia;
        this.oSocialChannel = value;

        this.accountManager
            .disconnectSocialPlatform(this.sUserEmail, this.sSessionId, value)
            .then((res) => {
                console.log('social value ', res);
                switch (value) {
                    case 'google': {
                        this.onboardingManagger.profile.connected_social_platforms.google = false;
                        break;
                    }
                    case 'facebook': {
                        this.onboardingManagger.profile.connected_social_platforms.facebook = false;
                        break;
                    }
                    case 'linkedin': {
                        this.onboardingManagger.profile.connected_social_platforms.linkedin = false;
                        break;
                    }
                    case 'instagram': {
                        this.onboardingManagger.profile.connected_social_platforms.instagram = false;
                        break;
                    }
                    default:
                        break;
                }
            })
            .catch((err) => {
                this.errorService.handleError(err.errorMessage, err.errorType, true);
            });
    }

    DashboardSocialMediaComponent_SelectPageForShare(nIncomingPage) {
        this.nIncomingPage = nIncomingPage;
    }
    DashboardSocialMediaComponent_ShareVideo() {
        try {
            let params = new HttpParams();
            params = params.set('email', this.sUserEmail);
            params = params.set('session_id', this.sSessionId);
            params = params.set('title', encodeURIComponent(this.oVideoDetails.title));
            params = params.set('return_url', encodeURIComponent(this.return_url));
            params = params.set('video_id', encodeURIComponent(this.oVideoDetails.fpath));

            params = params.set('description', encodeURIComponent(this.oVideoDetails.about));
            let pageId = this.nIncomingPage.id;
            let selectedPage: any;
            this.pages.forEach(function (value) {
                if (value.id == pageId) {
                    selectedPage = value;
                }
            });
            let reqBody = {
                page: {
                    access_token: selectedPage.access_token,
                    id: selectedPage.id,
                    name: selectedPage.name,
                },
                video_id: this.oVideoDetails.fpath,
                title: this.oVideoDetails.title,
                description: this.oVideoDetails.about,
            };
            let facebookInstaParams = new HttpParams();
            facebookInstaParams = params.set('email', this.sUserEmail);
            params = facebookInstaParams.set('session_id', this.sSessionId);
            if (this.oSocialPlatformValue == 'facebook') {
                let facebookUrl = cDataService.faceBookUpload + '?' + facebookInstaParams.toString();
                this.oClientService.ClientService_PostVideoToFacebookPage(facebookUrl, reqBody).subscribe(
                    (oResponse) => {
                        this.bDisplaySuccessMessage = true;
                        this.sDisplayResponseMessage = oResponse.message;
                        setTimeout(() => {
                            this.bDisplaySuccessMessage = false;
                        }, 5000);
                    },
                    (error) => {
                        this.bDisplayErrorMessage = true;
                        this.sDisplayResponseMessage = error.error.errorMessage;
                        setTimeout(() => {
                            this.bDisplayErrorMessage = false;
                        }, 5000);
                    },
                );
            } else {

                let instagramUrl = cDataService.instagramUpload + '?' + facebookInstaParams.toString();
                if(this.oVideoDetails.brand_id){
                    instagramUrl = cDataService.instagramBrandUpload + '?' + facebookInstaParams.toString();
                    this.shareToInstagram();
                } else {
                    this.oClientService.ClientService_PostVideoToInstagramPage(instagramUrl, reqBody).subscribe(
                        (oResponse) => {
                            this.bDisplaySuccessMessage = true;
                            this.sDisplayResponseMessage = oResponse.message;
                            setTimeout(() => {
                                this.bDisplaySuccessMessage = false;
                            }, 5000);
                        },
                        (error) => {
                            this.bDisplayErrorMessage = true;
                            this.sDisplayResponseMessage = error.error.errorMessage;
                            setTimeout(() => {
                                this.bDisplayErrorMessage = false;
                            }, 5000);
                        },
                    );
                }

            }
        } catch (oError) {
            this.bDisplayErrorMessage = true;
            this.sDisplayResponseMessage = oError;
            setTimeout(() => {
                this.bDisplayErrorMessage = false;
            }, 5000);
        }
    }

    // Youtube Section
    DashboardSocialMediaComponent_ShareVideoOnYoutube() {
        console.log(" Share on youtube ..");
        const youTubeUploadBody = {
            category: '22',
        };
        let apiURL = cDataService.youTubeUpload;
        let params = new HttpParams();
        if (this.oVideoDetails.brand_id && this.onboardingManagger.profile.plan == 'enterprise') {
            console.log('is brand clip');
            apiURL = cDataService.youTubeBrandUpload;
            params = params.set('brand_id', this.oVideoDetails.brand_id);
        }

        const title = this.oVideoDetails.title ? this.oVideoDetails.title : this.videoManager.selectedVideo?.title;
        const fpath = this.oVideoDetails.fpath ? this.oVideoDetails.fpath : this.videoManager.selectedVideo?.fpath;
        const about = this.oVideoDetails.about ? this.oVideoDetails.about : this.videoManager.selectedVideo?.about;
        const keyterms = this.oVideoDetails.key_terms
            ? this.oVideoDetails.key_terms.join(',')
            : this.videoManager.selectedVideo?.key_terms.join(',');

        params = params.set('email', this.sUserEmail);
        params = params.set('session_id', this.sSessionId);
        params = params.set('title', encodeURIComponent(title));
        params = params.set('video_id', encodeURIComponent(fpath));
        params = params.set('return_url', encodeURIComponent(this.return_url));
        params = params.set('description', encodeURIComponent(about));
        let url = '';
        console.log(this.oSocialPlatformValue);
        console.log(params);
        if (this.oSocialPlatformValue == 'google') {
            let keyTermCombination = keyterms;

            params = params.set('category', encodeURIComponent(youTubeUploadBody.category));
            params = params.set('keywords', encodeURIComponent(keyTermCombination));
            params = params.set('privacyStatus', encodeURIComponent('public'));
            url = apiURL + '?' + params.toString();
        }
        this.oSessionService.cSessionService_SetRedirectURL(3);
        console.log("Redirect url ...");
        //window.open(url, '_self');
        if(this.oVideoDetails.brand_id && this.oVideoDetails.brand_id != ''){
            if (this.oSocialPlatformValue == 'google') {
                let keyTermCombination = keyterms;

                params = params.set('category', encodeURIComponent(youTubeUploadBody.category));
                params = params.set('keywords', encodeURIComponent(keyTermCombination));
                params = params.set('privacyStatus', encodeURIComponent('public'));
                url = apiURL + '?' + params.toString();
            }
            this.oSessionService.cSessionService_SetRedirectURL(3);
            let paramsBrand = new HttpParams();
            params = params.set('email', this.sUserEmail);
            params = params.set('session_id', this.sSessionId);
            params = params.set('title', encodeURIComponent(title));
            params = params.set('video_id', encodeURIComponent(fpath));
            params = params.set('video_url', encodeURIComponent(this.videoManager.selectedVideo.fpath));
            params = params.set('brand_id', encodeURIComponent(this.oVideoDetails.brand_id));
            apiURL = cDataService.youTubeBrandUpload;
            let urlBrand = apiURL + '?' + params.toString();

            this.brandService
                    .shareVideoOnYoutube(urlBrand)
                    .then((response: any) => {
                        this.bDisplaySuccessMessage = true;
                        this.sDisplayResponseMessage = response.result ? response.result : 'Youtube Brand video shared successfully';
                        this.loader.hideLoader();
                    })
                    .catch((err: any) => {
                        this.loader.hideLoader();
                        this.bDisplayErrorMessage = true;
                        let errorMessage = err.error ? err.error : err.errorMessage;

                        if(errorMessage.indexOf("user has exceeded the number") > -1 || errorMessage.indexOf("have exceeded your") > -1 ){
                            this.sDisplayResponseMessage = "The user has exceeded the number of videos they may upload.";
                        } else {
                            this.sDisplayResponseMessage = err.error ? err.error : err.errorMessage;
                        }
                    });

            // window.open(url, '_self');
        } else {
            const youTubeUploadBody = {
                category: '22',
            };
            let params = new HttpParams();
            let apiURL = cDataService.youTubeUpload;
            if (this.oVideoDetails.brand_id && this.onboardingManagger.profile.plan == 'enterprise') {
                console.log('is brand clip');
                apiURL = cDataService.youTubeBrandUpload;
                params = params.set('brand_id', this.oVideoDetails.brand_id);
            }
            params = params.set('email', this.sUserEmail);
            params = params.set('session_id', this.sSessionId);
            params = params.set('title', encodeURIComponent(this.oVideoDetails.title));
            params = params.set('video_id', encodeURIComponent(this.oVideoDetails.fpath));
            params = params.set('return_url', encodeURIComponent(this.return_url));
            params = params.set('description', encodeURIComponent(this.oVideoDetails.about));
            let url = '';

            let keyTermCombination = this.oVideoDetails.key_terms.join(',');
            console.log('Incoming Key Terms ==>>>>', keyTermCombination);
            params = params.set('category', encodeURIComponent(youTubeUploadBody.category));
            params = params.set('keywords', encodeURIComponent(keyTermCombination));
            params = params.set('privacyStatus', encodeURIComponent('public'));
            url = apiURL + '?' + params.toString();
            console.log('YouTube URl====>', url);

            this.oSessionService.cSessionService_SetRedirectURL(4);
            window.open(url, '_self');
            // this.oClientService.postVideoOnYoutube(url, {}).subscribe(
            //     (oResponse) => {
            //         this.bDisplaySuccessMessage = true;
            //         this.sDisplayResponseMessage = oResponse.message;
            //         setTimeout(() => {
            //             this.bDisplaySuccessMessage = false;
            //         }, 5000);
            //     },
            //     (error) => {
            //         this.bDisplayErrorMessage = true;
            //         this.sDisplayResponseMessage = error.errorMessage ?  error.errorMessage :  error.errorType;
            //         setTimeout(() => {
            //             this.bDisplayErrorMessage = false;
            //         }, 5000);
            //     },
            // );
        }

        // window.open(url, '_self');
    }

    shareVideoOnInstagram() {
        if(!this.brandSocialNetworkConnection.instagram){

        } else {
            let params = new HttpParams();
            params = params.set('email', this.sUserEmail);
            params = params.set('session_id', this.sSessionId);
            params = params.set('title', encodeURIComponent(this.oVideoDetails.title));
            params = params.set('return_url', encodeURIComponent(this.return_url));
            params = params.set('video_id', encodeURIComponent(this.oVideoDetails.fpath));

            params = params.set('description', encodeURIComponent(this.oVideoDetails.about));


            let selectedPage: any;
            this.pages.forEach(function (value) {
                if (value.id == value.id ) {
                    selectedPage = value;
                }
            });
            let reqBody = {
                page: {
                    access_token: selectedPage?.access_token,
                    name: selectedPage?.name,
                },
                video_id: this.oVideoDetails.fpath,
                title: this.oVideoDetails.title,
                description: this.oVideoDetails.about,
            };

            let instagramUrl = cDataService.instagramUpload + '?' + params.toString();
            if(this.oVideoDetails.brand_id){
                instagramUrl = cDataService.instagramBrandUpload + '?' + params.toString();
                this.shareToInstagram();
            } else {
                this.oClientService.ClientService_PostVideoToInstagramPage(instagramUrl, reqBody).subscribe(
                    (oResponse) => {
                        this.bDisplaySuccessMessage = true;
                        this.sDisplayResponseMessage = oResponse.message;
                        setTimeout(() => {
                            this.bDisplaySuccessMessage = false;
                        }, 5000);
                    },
                    (error) => {
                        this.bDisplayErrorMessage = true;
                        this.sDisplayResponseMessage = error.error.errorMessage;
                        setTimeout(() => {
                            this.bDisplayErrorMessage = false;
                        }, 5000);
                    },
                );
            }
        }
    }
    // Linkedin Section
    DashboardSocialMediaComponent_ShareVideoOnLinkedIn() {
        let params = new HttpParams();
        params = params.set('email', this.sUserEmail);
        params = params.set('session_id', this.sSessionId);
        params = params.set('title', encodeURIComponent(this.oVideoDetails.title));
        params = params.set('return_url', encodeURIComponent(this.return_url));
        params = params.set('video_id', encodeURIComponent(this.oVideoDetails.fpath));
        params = params.set('description', encodeURIComponent(this.oVideoDetails.about));

        let url = '';
        if (this.oSocialPlatformValue == 'linkedin') {
            params = params.set('privacyStatus', encodeURIComponent('public'));
            url = cDataService.LinkedInUpload + '?' + params.toString();
            if (this.isBrandVideo && this.connected_social_platforms.linkedin) {
                params = params.set('brand_id', this.oVideoDetails.brand_id);
                url = cDataService.linkedInBrandUpload + '?' + params.toString();
            }
        }
        this.oSessionService.cSessionService_SetRedirectURL(3);
        window.open(url);
    }
    isDisconnectModal(data: string): void {
        this.disconnectMediaPopup = true;
        if (data == 'google') {
            this.socialMediaName = 'Youtube';
        } else {
            this.socialMediaName = data;
        }
        this.inCommingSocialMedia = data;
    }

    closeActivateModal(): void {
        this.disconnectMediaPopup = false;
    }
    openFacebbokShareModal() {
        $('#share-modal-Facebook').modal('show');
    }
    shareToFacebook() {
        if (this.brandSocialNetworkConnection.fb) {
            this.loader.showLoader();

            this.bDisplayErrorMessage = false;
            const data = {
                video_url: this.videoManager.selectedVideo.video_url,
                message: this.videoManager.selectedVideo.title,
                brand_id: this.videoManager.selectedVideo.brand_id,
            };
            this.sDisplayResponseMessage = '';
            this.brandService
                .shareVideoOnFacebook(data)
                .then((response: any) => {
                    this.bDisplaySuccessMessage = true;
                    this.sDisplayResponseMessage = response.result ? response.result : 'Facebook Brand video shared successfully';
                    this.loader.hideLoader();
                })
                .catch((err: any) => {
                    this.loader.hideLoader();
                    this.bDisplayErrorMessage = true;
                    this.sDisplayResponseMessage = err.error ? err.error : 'Error sharing video on Facebook.';
                });
        }
    }
    shareToInstagram() {
        console.log(" SHARE ON INSTAGRAM ");
        console.log(this.videoManager.selectedVideo);
        if (this.brandSocialNetworkConnection.instagram) {
            this.loader.showLoader();

            this.bDisplayErrorMessage = false;
            console.log(this.videoManager.selectedVideo);
            const data = {
                video_id: this.videoManager.selectedVideo.fpath,
                message: this.videoManager.selectedVideo.title,
                brand_id: this.videoManager.selectedVideo.brand_id,
            };
            if(data.video_id){
                this.sDisplayResponseMessage = '';
                this.brandService
                    .shareVideoOnInstagram(data)
                    .then((response: any) => {
                        this.bDisplaySuccessMessage = true;
                        this.sDisplayResponseMessage = response.result ? response.result : 'Instagram Brand video shared successfully';
                        this.loader.hideLoader();
                    })
                    .catch((err: any) => {
                        this.loader.hideLoader();
                        this.bDisplayErrorMessage = true;
                        this.sDisplayResponseMessage = err.error ? err.error : 'Error sharing video on Instagram.';
                    });
            } else {
                this.loader.hideLoader();
                this.bDisplayErrorMessage = true;
                this.sDisplayResponseMessage = 'Error sharing video on Instagram.';
            }

        }
    }
    shareToYoutube() {
        this.oSocialPlatformValue = 'google';
        console.log(this.oSocialPlatformValue);
        $('#share-modal-Youtube').modal('show');
        // if (this.brandSocialNetworkConnection.youtube) {
        //   this.loader.showLoader();

        //   this.bDisplayErrorMessage = false;
        //   const data = {
        //     video_url: this.videoManager.selectedVideo.video_url,
        //     message: "This is my video",
        //     brand_id: this.videoManager.selectedVideo.brand_id,
        //   };

        //   this.sDisplayResponseMessage = "";
        //   this.brandService
        //     .shareVideoOnYoutube(data)
        //     .then((response: any) => {
        //       this.bDisplaySuccessMessage = true;
        //       this.sDisplayResponseMessage = response.result
        //         ? response.result
        //         : "Brand video shared successfully";
        //       this.loader.hideLoader();
        //     })
        //     .catch((err: any) => {
        //       this.loader.hideLoader();
        //       this.bDisplayErrorMessage = true;
        //       this.sDisplayResponseMessage = err.error
        //         ? err.error
        //         : "Error sharing video on Youtube.";
        //     });
        // }
    }
    getVideoThumbnail(fPath) {
        const fpahArray = [fPath];
        this.mediaManger
            .getMediaThumbandURL(
                this.oSessionService.cSessionService_GetUserEmail(),
                this.oSessionService.SessionService_GetSessionId(),
                fpahArray,
            )
            .then((resThumb: any) => {
                if (resThumb && resThumb.output) {
                    this.videoManager.selectedVideo.thumbnail = resThumb.output[0][fpahArray[0]];
                }
            });
    }
}
