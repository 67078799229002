<div class="py-4 account-heading border-bottom-0">
  <div class="container mx-0 px-0 business-media" id="container-view">
    <app-media-library-shared
      [bSelectMultipleVisuals]="bSelectMultipleVisuals"
      [sInputVisualPath]="sInputVisualPath"
    ></app-media-library-shared>
  </div>
</div>

<div class="overlay sidebarOverlay"></div>
