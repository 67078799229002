<div class="media-editor-popup">
    <div class="heading mb-4">
        <span class="bold">Adjust Voice Segment &nbsp; &nbsp;|</span>
        <span class="lite ms-2">Select your desired voice duration, ensuring it aligns with or is shorter than your
            video's length. </span>
        <img class="close-editor" src="../../../assets/images/svg/close-square.svg" (click)="closeEditor()">
    </div>
    <div class="preview">
        <div style="width: max-content">
            <div class="title">
                <div class="text">Selected Voice Duration</div>
                <div class="seconds">{{playerInfo.clip.duration}}</div>
                <div class="btn">Reset</div>
                <button class="btn btn-primary" (click)="saveVoice()">Save</button>
            </div>
            <!-- <video muted constrols="false" crossorigin="anonymous | use-credentials" #mediaEditorPlayer
                [src]=""></video> -->
            <div class="d-flex audio-wave">
                <button *ngIf="bToggleVoiceoverButton" style="top: -13px; left: 11px" class="filter-btn audio-buttons"
                    (click)="OnVoiceoverPlayButtonClick()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                        <path d="M2 24v-24l20 12-20 12z" fill="#ffff" style="height: 10px; margin-top: -3px" />
                    </svg>
                </button>
                <button *ngIf="!bToggleVoiceoverButton" style="top: -13px; left: 11px" class="filter-btn audio-buttons"
                    (click)="OnAudioPauseButtonClick()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                        <path d="M10 24h-6v-24h6v24zm10-24h-6v24h6v-24z" fill="#ffff"
                            style="height: 10px; margin-top: -3px" />
                    </svg>
                </button>
                <div #dragableParentContainer class="thumbs video-duration-boundary voice-slider-container "
                    [ngStyle]="{ width: scaleAudioListWidth + 'px' }"
                    style="position: relative !important; height: 60px; padding-top: 6px; margin-left: 15px">

                    <div class="caption-block ">
                        <p class="ms-2">{{returnFileName(playerInfo.clip?.fpath?.split("/").pop())}}</p>
                        <p class="me-2">{{playerInfo.clip.duration}}</p>
                    </div>
                    <div class="slider slider-moveable" *ngIf="loadSlider" cdkDrag #slider
                        [cdkDragFreeDragPosition]="dragPosition" [style.width.px]="this.playerInfo.clip.selector_width"
                        cdkDragLockAxis="x" (cdkDragMoved)="dragMoveSlider($event)" (cdkDragEnded)="dragEnded($event)"
                        cdkDragBoundary=".video-duration-boundary" (cdkDragStarted)="dragStarted($event)"
                        (cdkDragReleased)="dragSliderEnds($event)" (mouseleave)="dragOver()">
                        <div [style.width.px]="(this.playerInfo.clip.selector_width - 30)" cdkDragBoundary=".video-duration-boundary"  class="moveable-div" style=""
                            cdkDragHandle></div>
                        <span #dragHandleRight cdkDragLockAxis="x" class="dragHandle right" cdkDrag
                           (cdkDragMoved)="resizeMove(dragHandleRight, $event, 'x')"
                            cdkDragBoundary=".video-duration-boundary"></span>
                        <span #dragHandleLeft cdkDragLockAxis="x" class="dragHandle left dragHandlerLeft" cdkDrag
                       (cdkDragMoved)="resizeMove(dragHandleLeft, $event, '-x')"
                            cdkDragBoundary=".video-duration-boundary"></span>


                        <div class="left-corner corner"></div>
                        <div class="right-corner corner"></div>
                        <!-- <div class="second">{{ playerInfo.pointer.seconds }}</div>
                        <div class="second-end">{{playerInfo.pointer.seconds + maxAllowedClipLength}}</div>
                        <div class="border-sec"></div>
                        <div class="border-sec-end"></div> -->
                        <div>
                            <div class="second">{{ playerInfo.pointer.seconds }}</div>
                            <!-- <div class="border-sec"></div> -->
                        </div>
                        <div>
                            <div class="second-end">{{ ((playerInfo.pointer.seconds) + maxAllowedClipLength).toFixed(2) }}</div>
                            <!-- <div class="border-sec-end"></div> -->
                        </div>
                    </div>
                    <ng-waveform disable *ngIf="playerInfo.clip.url" #voiceoverWaveform class="waveform" [width]="200"
                        [src]="playerInfo?.clip?.url " [height]="40" [useRegion]="true" backgroundColor="#404243"
                        regionBackgroundColor="rgba(255, 255, 255, 0)" regionStartStickColor="" regionEndStickColor=""
                        regionTextColor="#09417e" [withRegionLabels]="false" waveColor="#fff"
                        (trackLoaded)="LoadAudioFile($event)"
                        (rendered)="renderedAudioFile($event)"
                        (timeUpdate)="playVoiceMedia($event)"
                        (durationChange)="playerDuratioChanged($event)"
                        (paused)="OnAudioPauseButtonClick()" style="pointer-events:none;">
                    </ng-waveform>
                </div>
            </div>
            <div class="bottom-controls" style="" *ngIf="true">
                <div></div>
                <div class="inputs">
                    <label class="time-clock-icon" style="background-position: center"></label>
                    <label class="" style="">Start</label>
                    <input type="text" readonly class="" [value]="playerInfo.pointer.seconds | secondsToHoursTime" />
                    <label class="" style="padding-left: 13px !important; border-left: 1px solid #ccc">End</label>
                    <input type="text" readonly [value]=" ((playerInfo.pointer.seconds) + maxAllowedClipLength).toFixed(2) | secondsToHoursTime" />
                    <label class="time-clock-icon" style="padding-right: 21px; background-position: center"></label>
                </div>
            </div>
        </div>
        <div class="audio-setting ms-5">
            <h3 class="text-start mb-0 ">Audio Settings <img class="ms-3" src="../../../assets/images/svg/filter.svg" />
            </h3>
            <div class="audio-setting-volume mt-4">
                <img *ngIf="!this.volumeMuteField" (click)="muteVolume(true)" src="../../../assets/images/svg/speaker.svg" />
                <img *ngIf="this.volumeMuteField" (click)="muteVolume(false)" src="../../../assets/images/svg/mute .svg" />
                <div class="volume-seekbar">
                    <input type="range" class="range-field mt-0" id="points" name="points" [(ngModel)]="volumeRangeField"
                          [style.background]="'linear-gradient(to right, #FF8369 0%, #FF8369 '+(volumeRangeField)+'%, #DCDCDC '+(volumeRangeField)+'%, #DCDCDC 100%)'"
                        min="0" max="100" /><span class="ranges">{{volumeRangeField?volumeRangeField:0}}%</span>
                </div>
            </div>
            <div class="audio-setting-fadeInOut mt-3" [ngStyle]="{'border-bottom' : !fading ? 'none' : ''}">

                <div class="pt-3 ps-3 d-flex justify-content-between" style="border-bottom: 1px solid #cccc">
                    <p class="text-start">
                        Fade In/Out </p>
                    <div class="form-switch toggle_switch pe-3" style="margin-top: -3px;">
                        <input class="text-end form-check-input" [checked]="this.fading" (change)="allowFading($event)"
                            type="checkbox" />
                    </div>
                </div>
                <div *ngIf="this.fading">
                    <div class="fade-seekbar pt-3 pb-2" *ngIf="playerInfo?.clip?.duration">
                        <p class="m-0"><span class="mt-3">Fade In</span></p>
                        <input type="range" class="range-field" id="pointss" name="pointss" [(ngModel)]="fadeInRangeField"
                          [style.background]="'linear-gradient(to right, #FF8369 0%, #FF8369 '+(((fadeInRangeField)/(playerInfo?.clip?.duration))*100)+'%, #DCDCDC '+(((fadeInRangeField)/(playerInfo?.clip?.duration))*100)+'%, #DCDCDC 100%)'"
                   min="0" [max]="returnRound(playerInfo?.clip?.duration)" /><span
                            class="ranges">{{(fadeInRangeField?fadeInRangeField: 0).toFixed(2)}}s</span>
                    </div>
                    <div class="fade-seekbar pb-2" *ngIf="playerInfo?.clip?.duration">
                        <p class="m-0"><Span class="mt-3">Fade Out</Span></p>
                        <input type="range" class="range-field" id="points" name="points"
                          [style.background]="'linear-gradient(to right, #FF8369 0%, #FF8369 '+(((fadeOutRangeField)/(playerInfo?.clip?.duration))*100)+'%, #DCDCDC '+(((fadeOutRangeField)/(playerInfo?.clip?.duration))*100)+'%, #DCDCDC 100%)'"
                            [(ngModel)]="fadeOutRangeField" min="0" [max]="returnRound(playerInfo.clip.duration)" /><span
                            class="ranges">{{(fadeOutRangeField?fadeOutRangeField : 0).toFixed(2)}}s</span>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div style="position: relative; overflow: hidden; padding-top: 30px; margin-left: 90px; margin-right: 90px"
        #durationbar class="video-top-duration-boundary"></div>
</div>