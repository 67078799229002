import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    ViewChild,
    OnChanges,
    ElementRef,
    AfterViewInit,
    HostListener,
    HostBinding,
    SimpleChanges,
    Output,
} from '@angular/core';
import { ProntoSettingService } from 'src/app/services/utils/pronto-setting.service';

const enum Status {
    OFF = 0,
    RESIZE = 1,
    MOVE = 2,
}
@Component({
    selector: 'app-timeline-item',
    templateUrl: './timeline-item.component.html',
    styleUrls: ['./timeline-item.component.scss'],
})
export class TimelineItemComponent implements OnInit, AfterViewInit, OnChanges {
    @Input('width') public width: number = 0;
    @Input('height') public height: number = 0;
    @Input('duration') public duration: number = 0;
    @Input('item') public item: any;
    @Input('items') public items: any;
    @Input('startFromFirstFrame') public startFromFirstFrame: boolean = false;
    @HostBinding('style.left')
    @Input('left')
    public left: number = 0;
    @Input('top') public top: number = 0;
    @Input('id') public id: number = 0;

    @Input('selected') public selected: boolean = false;
    @Input('enableResizing') public enableResizing: boolean = false;
    @Input('backgroundImage') public backgroundImage: string = '';
    @Input('startMoveFrame') public startMoveFrame: number = 1;
    @ViewChild('box') public box: ElementRef = new ElementRef(undefined);
    previousLeft: number = 0;
    previouswidth: number = 0;
    @Output() refreshTimeLine = new EventEmitter();
    @Output() removeItem = new EventEmitter();
    @HostBinding('style.left') sleft: number = 0;
    private boxPosition: { left: number; top: number } = { left: 0, top: 0 };
    private containerPos: { left: number; top: number; right: number; bottom: number } = { left: 0, top: 0, right: 0, bottom: 73 };
    public mouse: { x: number; y: number } = { x: 0, y: 0 };
    public status: Status = Status.OFF;
    private mouseClick: { x: number; y: number; left: number; top: number } = { x: 0, y: 0, left: 0, top: 0 };
    private parentWidth = 0;
    public currentIndex = 0;
    public minFrameLevel = 2;
    public leftBlock = 0;
    public rightBlock = 0;
    isMouseDrag = false;
    mousePrev = 0;
    ngOnInit() {
        console.log(' enableResizing ', this.enableResizing);
    }

    constructor(private prontoSetting: ProntoSettingService) {}

    ngOnChanges(changes: SimpleChanges): void {}

    remove() {
        this.removeItem.emit();
    }
    ngAfterViewInit() {
        // console.log(' width of the bar should be ', this.width);
        if(this.startFromFirstFrame){
            this.minFrameLevel = 0;
        }
        this.parentWidth = this.box.nativeElement.parentElement.parentElement.getBoundingClientRect().width;
        this.left = (this.parentWidth / this.duration) * this.item.ts[0];

        this.leftBlock = (this.parentWidth / this.duration) * this.minFrameLevel;
        this.rightBlock = (this.parentWidth / this.duration) * (this.duration - this.minFrameLevel);
        if(this.startFromFirstFrame){
            this.rightBlock = (this.parentWidth / this.duration) * (this.duration - this.minFrameLevel);
        }

        this.previousLeft = (this.parentWidth / this.duration) * this.item.ts[0];
        let widthBar  = (this.parentWidth / this.duration) * this.item.ts[1];
        // console.log( " width ", this.width , widthBar, widthBar > this.width, this.startFromFirstFrame);
        if( widthBar > this.width && this.startFromFirstFrame ){
            this.width = this.width;
        }else {
            this.width = (this.parentWidth / this.duration) * this.item.ts[1];
        }
        this.previouswidth = (this.parentWidth / this.duration) * this.item.ts[1];
        this.item.width = this.width;
        this.loadBox();
        this.loadContainer();
        this.calculatecurrentIndex();
    }

    private loadBox() {
        const { left, top } = this.box.nativeElement.getBoundingClientRect();

        // console.log(' left . right ', left, top);
        this.boxPosition = { left, top };
    }

    private loadContainer() {
        const left = this.boxPosition.left - this.left;
        this.item.left = this.left;
        const top = this.boxPosition.top - this.top;
        const right = this.parentWidth;
        const bottom = this.height;
        this.containerPos = { left, top, right, bottom };
    }

    setStatus(event: MouseEvent, status: number) {
        this.mouseMoveMethod(event);
        if (status == 2) {
            this.isMouseDrag = true;
        } else {
            this.isMouseDrag = false;
            // this.prontoSetting.showSapVideoTimeLineDurationBar = false;
        }
        if (status === 1) event.stopPropagation();
        else if (status === 2) this.mouseClick = { x: event.clientX, y: event.clientY, left: this.item.left, top: this.top };
        else {
            if (status === 0) {
                this.isMouseDrag = false;
            } else {
                this.isMouseDrag = true;
            }

            if (this.checkOverlaping()) {
                // console.log("Over lapping is true .....");
                this.left = this.previousLeft;
                // console.log(' previous left ', this.previousLeft)
                this.item.left = this.previousLeft;
                this.width = this.previouswidth;
                this.item.width = this.previouswidth;
            } else {
            //   console.log(" SET PREVIOUS TO NEW POSITION ...", this.left);

                this.left = this.left;
                this.previousLeft = this.left;
                this.previouswidth = this.width;

            }
            this.loadBox();
            this.refreshTimeLine.emit();
        }
        this.status = status;
    }

    @HostListener('window:mousemove', ['$event'])
    onMouseMove(event: MouseEvent) {
        this.mouse = { x: event.clientX, y: event.clientY };
        if (this.status === Status.RESIZE) this.resize();
        else if (this.status === Status.MOVE) this.move();
    }
    private checkOverlaping() {
        let isIntersect = false;
        if (this.items.length == 1) {
            isIntersect = false;
        } else {
          this.items.forEach((element, index) => {
            if (this.id != index) {
                if (
                    this.item.ts[0] + this.item.ts[1] + 3 >= this.duration ||
                    (this.left + this.width > element.left && this.left + this.width < element.left + element.width) ||
                    (this.left > element.left && this.left < element.left + element.width)
                ) {
                    isIntersect = true;
                }
            }
        });
        }

        return isIntersect;
    }

    isHover = false;
    testDrag($event) {
        if(this.isMouseDrag){
            this.mouseMoveMethod($event);
            // console.log('Dragged called');
        }
    }
    reDrawTimeBar($event, isDown) {
        if (this.isHover && isDown) {
            this.prontoSetting.showSapVideoTimeLineDurationBar = true;
            let elemBar = document.querySelectorAll('.showSapVideoTimeLineDurationBar');

            if (elemBar && elemBar[0]) {
                elemBar[0].setAttribute(
                    'style',
                    ` height: 500px; left : ${Math.round(this.previousLeft)}px; width: ${Math.round(this.previouswidth)}px`,
                );
            }
        } else {
            // this.prontoSetting.showSapVideoTimeLineDurationBar = false;
        }
    }
    mouseEnter() {
        this.isHover = true;
    }

    mouseLeave() {
        this.isHover = false;
        this.prontoSetting.showSapVideoTimeLineDurationBar = false;
    }
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.ngAfterViewInit();
    }
    private resize() {
        if (this.resizeCondMeet()) {
            this.width = Number(this.mouse.x > this.boxPosition.left) ? this.mouse.x - this.boxPosition.left : 0;
            this.item.width = this.width;
            this.calculatecurrentIndex();
            //  this.height = Number(this.mouse.y > this.boxPosition.top) ? this.mouse.y - this.boxPosition.top : 0;
        }
    }

    private resizeCondMeet() {
        return this.mouse.x < this.containerPos.right + 1000;
    }

    private move() {

        // console.log( " this.startMoveFrame ", this.leftBlock, this.rightBlock, this.left);
        if (this.currentIndex >= this.startMoveFrame && this.currentIndex <= this.duration - this.startMoveFrame){
            // console.log(' you can move .....');
            if (this.moveCondMeet()) {

                this.left = this.mouseClick.left + (this.mouse.x - this.mouseClick.x);
                this.item.left =
                    this.left >= this.leftBlock ? (this.left <= this.rightBlock ? this.left : this.rightBlock) : this.leftBlock;
                this.left = this.item.left;
                // console.log(' ...this left ', this.left, this.item.left);
                this.calculatecurrentIndex();
                ///this.top = this.mouseClick.top + (this.mouse.y - this.mouseClick.y);
            }
        }
    }

    private calculatecurrentIndex() {
        let index = Math.round(this.left / (this.parentWidth / this.duration));
        // console.log(" calculate index here ", index);
        this.currentIndex = index > this.startMoveFrame ? index : this.startMoveFrame + 1;
        if(this.startFromFirstFrame){
            this.currentIndex = index >= this.startMoveFrame ? index : this.startMoveFrame + 1;
        }
        let dw = Math.round(this.item.width / (this.parentWidth / this.duration));

        this.item.ts[0] = this.currentIndex > this.startMoveFrame ? this.currentIndex : this.startMoveFrame + 1;
        this.item.ts[1] = dw;
        // console.log("Calucate index ", this.item.ts[0]);
        // console.log("Calucate index ", this.item.ts[1]);

    }

    mouseDirection = "";
    mouseOldPosition = 0;
    mouseMoveMethod (e: any) {

        if (e.pageX < this.mouseOldPosition) {
            this.mouseDirection = "left"
        } else if (e.pageX > this.mouseOldPosition) {
            this.mouseDirection = "right"
        }

        // document.body.innerHTML = direction;

        this.mouseOldPosition = e.pageX;

}

    private moveCondMeet() {
        const offsetLeft = this.mouseClick.x - this.boxPosition.left;
        let offsetRight = 2; //this.width - offsetLeft;
        if(this.startFromFirstFrame){
            offsetRight = 2; //this.width - offsetLeft;
        }
        //  const offsetTop = this.mouseClick.y - this.boxPosition.top;
        // const offsetBottom = this.height - offsetTop;
        // console.log("  Mouse xz" , this.mouse.x, this.containerPos.left, offsetLeft, this.containerPos.right , offsetRight);
        if( this.startFromFirstFrame){
            let elemBar = document.querySelectorAll('.voice-over-heading');
            let elmWidth = elemBar[0].getBoundingClientRect().width + 60;

            return (
                this.mouse.x > this.containerPos.left + offsetLeft && (( this.mouseDirection == 'left' && this.width < this.parentWidth) || ((this.left + this.width) < this.parentWidth ))
            );
        } else {
            return (
                this.mouse.x > this.containerPos.left + offsetLeft && this.mouse.x < this.containerPos.right - offsetRight
            );
        }
        this.mousePrev = this.mouse.x;

    }
}
