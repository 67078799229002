
                        <div class="py-4 px-4 account-heading">
                            <div class="row">
                                <div class="col-md-7 d-flex align-items-center">
                                    <h3 class="weight-700 text-gray-2">My Videos</h3>
                                </div>
                                <div class="col-md-5">
                                    <div class="d-flex justify-content-end">
                                        <div class="formItm search-icon d-none">
                                            <input id="loginEmail" type="email" value="" placeholder="Email Address" required>
                                            <label for="loginEmail" class="text-sm">Search your video</label>
                                            <img src="../../../../assets/images/svg/search.svg">

                                        </div>


                                        <!-- <a (click)="createNewVideo()"
                                            class="weight-normal btn btn-primary btn-round-6 md-btn mt-4 d-inline-block"><svg
                                                width="17" height="16" viewBox="0 0 17 16" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M8.68363 4.98126C7.46518 4.17 5.83312 5.04234 5.83312 6.50486V9.51855C5.83312 10.9811 7.46518 11.8534 8.68363 11.0421L10.9468 9.53531C12.0354 8.8105 12.0354 7.21291 10.9468 6.4881L8.68363 4.98126ZM6.83319 6.50486C6.83319 5.84008 7.57504 5.44356 8.12888 5.81232L10.392 7.31916C10.8868 7.64862 10.8868 8.3748 10.392 8.70425L8.12888 10.2111C7.57504 10.5799 6.83319 10.1833 6.83319 9.51855V6.50486Z"
                                                    fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M8.49999 15.9904C12.9186 15.9904 16.5006 12.413 16.5006 7.99999C16.5006 3.58699 12.9186 0.009552 8.49999 0.009552C4.08137 0.009552 0.499359 3.58699 0.499359 7.99999C0.499359 12.413 4.08137 15.9904 8.49999 15.9904ZM8.49999 14.9916C12.3663 14.9916 15.5005 11.8614 15.5005 7.99999C15.5005 4.13862 12.3663 1.00836 8.49999 1.00836C4.63369 1.00836 1.49944 4.13862 1.49944 7.99999C1.49944 11.8614 4.63369 14.9916 8.49999 14.9916Z"
                                                    fill="white" />
                                            </svg>
                                            Create a New Video</a> -->

                                            <a id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style=" min-width:222px"
                                            class="weight-normal btn btn-primary btn-round-6 md-btn mt-4 d-inline-block">
                                            <img src="../../../../assets/images/svg/create video.svg">
                                            Create a New Video<img src="../../../../assets/images/divider-solid-svgrepo-com.svg">
                                            <img class="btn-svg-icon ms-1 white" src="../../../../assets/images/svg/down arrow.svg"></a>


                                  <ul class="dropdown-menu custom-dropdown" aria-labelledby="navbarDropdown"
                                  style="      width: 226px;  transform: translate(1212px, 88px);">
                                   <li><a class="dropdown-item" href="javascript:void(0)" (click)="createNewVideo()">Create a New Video</a></li>
                                   <li *ngIf="this.onboardingManager.showSAPVideo"><a class="dropdown-item" href="javascript:void(0)" (click)="createNewModifiedVideo()">Customize a Video</a></li>
                                   <li *ngIf="this.onboardingManager.profile.localization_enabled && this.onboardingManager.profile.plan == 'enterprise' "><a class="dropdown-item" [routerLink]="['/localise-existing-videos']">Localize Existing Videos</a></li>
                               </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                      <app-schuled-videos (nEmittedScheduledVideosCount)="SchuledVideosComponent_GetVideosCount($event)"></app-schuled-videos>


