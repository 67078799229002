import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { MediaManagerService } from './../../../Managers/media-manager.service';
import { mediaLib } from './../../../models/media';
import { cSessionService } from './../../../services/session.service';
import { cWebSocketService } from './../../../services/websocket.service';
import { ErrorService } from './../../../services/error.service';
import { BrandService } from '../manage-brand/service/BrandService';
import { brand, brandList } from 'src/app/models/admin';

@Component({
    selector: 'app-mediaa-libraries',
    templateUrl: './mediaa-libraries.component.html',
    styleUrls: ['./mediaa-libraries.component.scss'],
})
export class MediaaLibrariesComponent implements OnInit {
    lMediaLibraries: any = [];
    sSessionId: string;
    sUserEmail: string;
    lMediaLibrariesCount: any;
    fAddLibraryForm: FormGroup;
    mediaObj: any;
    isActive: false;
    isDeactive: false;
    mediaLibStatus = 'all';
    MediaModal: boolean = false;
    searchTermText = '';
    brandList: brandList;
    collapseMyLib = false;
    totalMediaCount = 0;
    searchKeyword = "";
    searchFilterStatus = 'all';
    constructor(
        private oRouter: Router,
        private oFormBuilder: FormBuilder,
        private oWebSocketService: cWebSocketService,
        public mediaManger: MediaManagerService,
        public oSessionService: cSessionService,
        public errorService: ErrorService,
        public brandService: BrandService,
    ) {}

    ngOnInit(): void {
        this.sSessionId = this.oSessionService.SessionService_GetSessionId();
        this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
        this.oSessionService.setAccountSettingHeaderName('Media Libraries');
        console.log("Load user media libs ....");
        this.mediaManger.getUsersMyMediaLibraries({
            email : this.sUserEmail,
            session_id : this.sSessionId,
            status: this.searchFilterStatus,
            name : this.searchKeyword}).then(() => {
            if(this.mediaManger.mediaLibs && this.mediaManger.mediaLibs.length > 0){
            this.totalMediaCount = 0;
            }
        });
        this.fAddLibraryForm = this.oFormBuilder.group({
            email: this.sUserEmail,
            session_id: this.sSessionId,
            lib_name: ['', Validators.required],
            user_roles: [['creator'], Validators.required],
            description: ['', Validators.required],
        });

        this.reloadBrandList();
    }

    reloadBrandList() {
        let __this = this;
        if(this.brandList){
            // this.brandList.result = [];
        }
        this.brandService
            .getListBrand(this.sUserEmail, this.sSessionId, 0, 20, 'created', -1, '', 'name', true)
            .then(function (listbrand: any) {

                __this.brandList = listbrand;
                __this.loadBrandMediaLibs();
                // __this.populatePagination();
            });
    }

    loadBrandMediaLibs() {
        this.totalMediaCount = 0;
        this.brandList?.result.map((brand: brand) => {
            this.brandService.getUserBrandMediaLibList({
                brand_id : brand._id.$oid,
                status : this.searchFilterStatus,
                name : this.searchKeyword
            }).then((res: any) => {
                console.log('media library ///', res);
                if (res.result) {
                    brand.mediaLibs = res.result;
                    this.totalMediaCount += res.result.length;
                }
            });
        });
    }
    checkValueActive(event: boolean) {}
    checkValueDeactive(event: boolean) {}
    apply() {

        let activation_status = 'all';
        if (this.isActive && this.isDeactive) {
            activation_status = 'all';

        }else if(this.isDeactive || this.isActive) {
            if(this.isDeactive){
                activation_status="inactive"
            } else {
                activation_status="active"
            }
        }
        this.searchFilterStatus = activation_status;
        this.visibleFilter = false;

            this.mediaManger.getUsersMyMediaLibraries({ email : this.sUserEmail,
                session_id: this.sSessionId,
                status: this.searchFilterStatus,
                name : this.searchKeyword}).then();
            this.handleCancel();
            this.visibleFilter = false;
            // this.isActive = false;
            // this.isDeactive = false;
            this.loadBrandMediaLibs();
    }

    clearAll() {
        this.isActive = false;
        this.isDeactive = false;
        let activation_status = 'all';
        this.searchFilterStatus = 'all';
        this.searchKeyword ="";
        if(this.mediaManger.mediaLibs && this.mediaManger.mediaLibs.length > 0){
            this.mediaManger.mediaLibs = [];
            this.totalMediaCount = 0;
        }
        this.reloadBrandList();
        this.mediaManger.getUsersMyMediaLibraries({ email : this.sUserEmail, session_id : this.sSessionId, status : activation_status, name : ""}).then();
        this.handleCancel();
        this.visibleFilter = false;
        this.isActive = false;
        this.isDeactive = false;
    }

    addMediaLib() {
        console.log('add media library start');
        if (this.fAddLibraryForm.valid) {
            const lib_name = this.fAddLibraryForm.value.lib_name;
            const description = this.fAddLibraryForm.value.description;
            const keyterms = [];
            const category = '';

            console.log('add media library', lib_name, description, this.sUserEmail, this.sSessionId);
            try {
                this.mediaManger.addMediaLib(this.sUserEmail, this.sSessionId, lib_name, keyterms, description, category).then((res) => {
                    this.fAddLibraryForm.controls['lib_name'].setValue('');
                    this.fAddLibraryForm.controls['description'].setValue('');
                });
            } catch (err) {
                this.errorService.handleError(err.errorMessage, err.errorType, true);
            }
            this.closeMedia();
        }
    }

    updateMediaLibrary(media, isbrand = false, brand  = undefined) {

        let mediaLibId = media?.lib_id ? media?.lib_id : media?._id?.$oid;
        //    this.searchFilterStatus = media.activation_status;
        if (media.activation_status == 'inactive') {
            media.activation_status = 'active';
        } else if (media.activation_status == 'active') {
            media.activation_status = 'inactive';
        } else {
            media.activation_status = 'all';
        }
        this.reloadBrandList();
        this.mediaManger.updateUserLibrary(media?.name, media.activation_status, this.sUserEmail, this.sSessionId, mediaLibId, this.searchKeyword, this.searchFilterStatus).then();
            this.handleCancel();

    }

    UserMediaComponent_HandleMediaLibraryListResponse = (IncomingMessage) => {
        console.log('UserMediaComponent_HandleMediaLibraryListResponse: IncomingMessage', JSON.parse(IncomingMessage));

        if (JSON.parse(IncomingMessage).errorMessage) {
            console.error('UserMediaComponent_HandleMediaLibraryListResponse : Error ==> ', JSON.parse(IncomingMessage).errorMessage);
        } else {
            let oFormatedIncommingMessage = JSON.parse(IncomingMessage);
            this.lMediaLibraries = oFormatedIncommingMessage.media_libs;
            this.lMediaLibrariesCount = oFormatedIncommingMessage.media_libs.length;
        }
    };
    UserMediaComponent_NavigateToMediaSection(media: mediaLib) {
        if(!media.lib_id){
            media.lib_id = media._id.$oid;
        }
        this.mediaManger.selectedmedialib = media;
        this.mediaManger.uploadMediaList = [];
        this.oSessionService.setAccountSettingHeaderName('Media Libraries > ' + media.name);
        this.oRouter.navigate(['/account-setting/media-libraries/media']);
    }
    UserMediaComponent_nIncomingMediaLibraries(event) {
        console.log('Incoming Media lIbraries =====>>>>>', event);
        this.lMediaLibraries = [];
        this.lMediaLibraries = event[0];
    }

    visibleMedia = false;
    placementMedia: NzDrawerPlacement = 'right';
    openMedia(): void {
        this.visibleMedia = true;
    }
    closeMedia(): void {
        this.visibleMedia = false;
    }

    visibleFilter = false;
    placementFilter: NzDrawerPlacement = 'right';
    openFilter(): void {
        this.visibleFilter = true;
    }
    closeFilter(): void {
        this.visibleFilter = false;
    }

    // modal sign out all devices
    isVisible = false;
    showModal(media) {
        // console.log("dd",media);
        this.mediaObj = media;
        this.isVisible = true;
    }
    handleOk(): void {
        console.log('Button ok clicked!');
        this.isVisible = true;
    }
    handleCancel(): void {
        console.log('Button cancel clicked!');
        this.isVisible = false;
    }
    mediaSelected(event) {
        console.log('visual', event);
    }

    // modal media library
    visible = false;
    showMediaModal() {
        this.visible = true;
    }
    handleMediaOk(): void {
        console.log('Button ok clicked!');
        this.visible = true;
    }
    closeMediaModal(): void {
        console.log('Button cancel clicked!');
        this.visible = false;
    }

    searchLibrary() {
        this.searchKeyword = this.searchTermText;
        this.mediaManger.getUsersMyMediaLibraries({
            email : this.sUserEmail,
            session_id : this.sSessionId,
            status: this.searchFilterStatus,
            name : this.searchKeyword}).then(() => {
            if(this.mediaManger.mediaLibs && this.mediaManger.mediaLibs.length > 0){
            this.totalMediaCount = 0;
            }
        });
        this.reloadBrandList();
    }

    collapseLibraries(brand: any, collapse: boolean) {
        brand.collapse = collapse;
    }

    toggleMyLib(collapse: boolean) {
        this.collapseMyLib = collapse;
    }
}
