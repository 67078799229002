<div class="col-md-12 ps-0 d-flex flex-column h-100">
  <div class="video-header row m-0 ms-2 px-4 pt-4 ps-0">
    <p class="h3 weight-600 text-gray-2 pb-1 px-0 col-lg-8 mb-0">
      <a href="javascript:void(0)" routerLink="/account-setting/manage-brand" class="me-2">
        <img src="../../../../../assets/images/svg/back-arrow-with-tail.svg">
      </a>
      {{ this.brandDetail?.name ? this.brandDetail?.name : "Brand Name" }}
    </p>
  </div>
  <div class="container ps-0 ms-3" id="">
    <div class="row filter-post">
      <div class="col-md-12 p-0 ps-1">
        <ul class="breadcrumb-tabs nav m-0">
          <!-- <li class="nav-item ps-0">
            <a
              [routerLink]="['/account-setting/manage-brand/account', brandId]"
              class="nav-link"
              >Account</a
            >
          </li> -->
          <li class="nav-item ps-0">
            <a [routerLink]="[
                '/account-setting/manage-brand/',
                brandId,
                'business-details'
              ]" class="nav-link">Business Detail</a>
          </li>
          <li class="nav-item">
            <a [routerLink]="[
                '/account-setting/manage-brand/',
                brandId,
                'published-videos'
              ]" class="nav-link active">Published Video</a>
          </li>
          <li class="nav-item">
            <a [routerLink]="[
                '/account-setting/manage-brand/',
                brandId,
                'list-media-libraries'
              ]" class="nav-link">Media Libraries</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="flex-grow-1">
    <div class="" id="container-view">
      <div class="py-4 px-3">
        <div class="row row-cols-md-2 g-0 justify-content-between">
          <div class="loginSecItmForm pt-0" aria-labelledby="loginYourself">
            <div class="row video-header mx-0">
              <ul class="container p-0 mb-1 d-flex justify-content-between align-items-center">
                <li class="col-md-4 mb-2 pe-3">
                  <div class="formItm search-icon searchDropdown">
                    <input id="search-feild" type="search" value="" [(ngModel)]="searchTermText"
                      placeholder="Search brand" required="" (keyup.enter)="searchPublishedVideos()" />
                    <label for="search-feild" class="text-sm" style="cursor: default">Search Video</label>
                    <img src="../../../../../assets/images/svg/search.svg">
                  </div>
                  <div class="searchtem" *ngIf="searchTerm != ''" (click)="clearSearchTerm()">
                    <div>{{ searchTerm }}</div>
                    <mat-icon class="link">close</mat-icon>
                  </div>
                </li>
                <li class="col-md-2 mb-2 ps-2" id="selectpreff">
                  <div class="formItm down_arrow me-1 mt-4 search-sort-panel">
                    <div class="dropdown dropdownBtn">
                      <button class="dropdown-toggle text-md" type="button" (click)="openSearchSortTypeDD()">
                        <span class="dropdownBtnText"><strong>Sort by </strong>
                          {{ searchBy == "created" ? "Created" : "" }}
                          {{ searchBy == "name" ? "Name" : "" }}
                        </span>
                      </button>
                      <ul class="dropdown-menu dropdown-menu-end dropdownMenu"
                        [ngClass]="{ showDropDown: showSortByDD }">
                        <li>
                          <a class="dropdown-item text-md" href="javascript:void(0)"
                            (click)="sortBySearchResult('name')">Name
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item text-md" (click)="sortBySearchResult('created')">Last Created
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="sort-arrows pos-right">
                      <img src="../../../../../assets/images/DataSort-1.svg" height="25px" *ngIf="asc == -1"
                        (click)="sortTypeSearch(1)" />
                      <img src="../../../../../assets/images/DataSort-3.svg" height="25px" *ngIf="asc == 1"
                        (click)="sortTypeSearch(-1)" />
                    </div>
                    <!-- <select>
                                    <option selected>Last Modified</option>
                                </select> -->
                  </div>
                </li>

                <li class="col-md-4 mb-2"></li>
              </ul>
            </div>

            <div class="" id="container-view">
              <div class="row grid m-0" id="cardListing">
                <div class="accountSecContent py-5">

                  <li *ngFor="let video of this.videoManager.videos; index as i" style="margin-bottom: 30px !important">
                    <div class="video-outer" [ngStyle]="{
                    minHeight:
                  session.dashboardVideoListView
                  ? '67px'
                  : (heightThumbs+'px')
              }" [ngClass]="
              session.dashboardVideoListView ? 'h-67' : ''
            ">
                      <div class="pageloader media-loader-outer" *ngIf="video.loadingThumbUrl">
                        <div id="loader-inner" class="media-loader-inner"></div>
                      </div>

                      <div class="video_wrapper video_wrapper_full js-videoWrapper" [ngClass]="
                        sSelectedVideoId && sSelectedVideoId === video._id
                          ? 'videoWrapperActive'
                          : ''
                      ">
                        <div class="video-tag-container">
                          <p *ngIf="
                            video.publish_status == 'draft' &&
                            !thumbListView &&
                            this.isLocations
                          " class="video-tag">
                            Localized
                          </p>
                          <p *ngIf="
                            video.publish_status == 'draft' &&
                            !thumbListView &&
                            !this.isLocations
                          " class="video-tag">
                            Draft
                          </p>
                          <p *ngIf="
                            video.publish_status == 'published' &&
                            !thumbListView
                          " class="video-tag">
                            Published -
                          </p>
                        </div>
                        <div class="share-edit" *ngIf="video?.owner == sUserEmail">
                          <button id="toggle-slow" class="showSingle" target="1">
                            <img src="../../../../../assets/images/svg/ellipsis.svg" class="ellipsis">

                          </button>
                          <div id="div1" class="targetDiv">
                            <!-- <div>
                              <a href="javascript:void(0)" (click)="editVideo(video)" class="edit-option">
                                <img src="../../../../../assets/images/svg/edit-video.svg">

                              </a>
                            </div> -->
                            <br />
                            <a href="javascript:void(0)" (click)="navigateToShare(video)" class="share-option">
                              <img src="../../../../../assets/images/svg/share-video.svg">

                            </a>
                            <br />
                            <a href="javascrip:void(0)" *ngIf="
                              video.publish_status != 'published' &&
                              this.isLocations == false
                            " data-bs-toggle="modal" data-bs-target="#duplicate-popup"
                              (click)="selectDuplicateVideo(video)">
                              <img src="../../../../../assets/images/svg/duplicate-video - Copy.svg">

                            </a>
                          </div>
                        </div>
                        <!-- <app-pr-video-player [video]="video"></app-pr-video-player> -->
                        <video controls controlsList="nodownload noplaybackrate" class="videoIframe js-videoIframe"
                          style="min-width:auto; width: auto; min-height:100%; object-fit: fill;" [muted]="true"
                          [src]="video.video_url" [id]="video.fpath"></video>
                        <figure class="video-baner video-banner"
                          style="min-width: 80px; min-height:50px; background-color: #dcdcdc;"
                          [style.height.px]="heightThumbs" [style.width.px]="widthThumbs">
                          <img style="min-height: 191px;" [src]="video.thumbnail" alt="video1" />
                          <span class="video-duration">00:{{ video.duration }}</span>
                        </figure>
                        <button class="videoPoster js-videoPoster"
                          (click)="CreatedVideosComponent_PlayVideo(video.fpath)"></button>
                      </div>

                      <a href="javascript:void(0)" (click)="toggleideoDetailModal(true, video, i)"
                        class="text-sm p-1 m-0 text-gray-2 d-block text-truncate" [title]="video.title">{{ video.title
                        }}</a>
                      <div class="list-v-dis">
                        <span class="video-duration mr-2">{{ video.duration | secondsToTime}}</span>
                        <p>Created on {{ video.created | date }}</p>
                        <div class="share-edit">
                          <button id="toggle-slow" class="showSingle" target="1">
                            <img src="../../../../../assets/images/svg/ellipsis.svg" class="ellipsis">
                          </button>
                          <div id="div1" class="targetDiv" style="min-width: 125px; position: absolute" [ngStyle]="{
                            right:
                              video.publish_status != 'published' &&
                              this.isLocations == false
                                ? '40px'
                                : '-20px'
                          }">
                            <a href="javascrip:void(0)" *ngIf="
                              video.publish_status != 'published' &&
                              this.isLocations == false
                            " data-bs-toggle="modal" data-bs-target="#duplicate-popup"
                              (click)="selectDuplicateVideo(video)">
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M2.55359 5.88721C2.09324 6.34757 1.83337 7.11631 1.83337 8.40033V10.9337C1.83337 12.2177 2.09324 12.9864 2.55359 13.4468C3.01395 13.9071 3.78269 14.167 5.06671 14.167H7.60004C8.88406 14.167 9.6528 13.9071 10.1132 13.4468C10.5735 12.9864 10.8334 12.2177 10.8334 10.9337V8.93366C10.8334 8.65752 11.0572 8.43366 11.3334 8.43366C11.6095 8.43366 11.8334 8.65752 11.8334 8.93366V10.9337C11.8334 12.3163 11.5599 13.4142 10.8203 14.1539C10.0806 14.8935 8.98269 15.167 7.60004 15.167H5.06671C3.68406 15.167 2.58613 14.8935 1.84649 14.1539C1.10684 13.4142 0.833374 12.3163 0.833374 10.9337V8.40033C0.833374 7.01768 1.10684 5.91975 1.84649 5.18011C2.58613 4.44046 3.68406 4.16699 5.06671 4.16699H7.06671C7.34285 4.16699 7.56671 4.39085 7.56671 4.66699C7.56671 4.94313 7.34285 5.16699 7.06671 5.16699H5.06671C3.78269 5.16699 3.01395 5.42686 2.55359 5.88721Z"
                                  fill="#7A7A7A" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M6.87531 4.20507C7.06215 4.12768 7.2772 4.17045 7.4202 4.31345L11.6869 8.58012C11.8299 8.72312 11.8726 8.93818 11.7953 9.12502C11.7179 9.31185 11.5355 9.43367 11.3333 9.43367H9.19998C8.37228 9.43367 7.66138 9.30217 7.17976 8.82056C6.69815 8.33895 6.56665 7.62804 6.56665 6.80034V4.66701C6.56665 4.46478 6.68847 4.28246 6.87531 4.20507ZM7.56665 5.87411V6.80034C7.56665 7.57264 7.70182 7.9284 7.88687 8.11345C8.07192 8.29851 8.42768 8.43367 9.19998 8.43367H10.1262L7.56665 5.87411Z"
                                  fill="#7A7A7A" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M7.2334 1.3335C7.2334 1.05735 7.45726 0.833496 7.7334 0.833496H10.4001C10.6762 0.833496 10.9001 1.05735 10.9001 1.3335C10.9001 1.60964 10.6762 1.8335 10.4001 1.8335H7.7334C7.45726 1.8335 7.2334 1.60964 7.2334 1.3335Z"
                                  fill="#7A7A7A" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M6.66675 1.8335C5.83622 1.8335 5.16675 2.50297 5.16675 3.3335C5.16675 3.60964 4.94289 3.8335 4.66675 3.8335C4.39061 3.8335 4.16675 3.60964 4.16675 3.3335C4.16675 1.95069 5.28394 0.833496 6.66675 0.833496H8.41342C8.68956 0.833496 8.91342 1.05735 8.91342 1.3335C8.91342 1.60964 8.68956 1.8335 8.41342 1.8335H6.66675Z"
                                  fill="#7A7A7A" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M14.6667 4.8335C14.9428 4.8335 15.1667 5.05735 15.1667 5.3335V9.46016C15.1667 10.7696 14.1028 11.8335 12.7933 11.8335C12.5172 11.8335 12.2933 11.6096 12.2933 11.3335C12.2933 11.0574 12.5172 10.8335 12.7933 10.8335C13.5505 10.8335 14.1667 10.2174 14.1667 9.46016V5.3335C14.1667 5.05735 14.3905 4.8335 14.6667 4.8335Z"
                                  fill="#7A7A7A" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M10.4754 0.871571C10.6622 0.79418 10.8773 0.836958 11.0203 0.979957L15.0203 4.97996C15.1633 5.12296 15.2061 5.33802 15.1287 5.52485C15.0513 5.71169 14.869 5.83351 14.6667 5.83351H12.6667C11.889 5.83351 11.2115 5.71034 10.7507 5.24956C10.2899 4.78878 10.1667 4.11121 10.1667 3.33351V1.33351C10.1667 1.13128 10.2886 0.948961 10.4754 0.871571ZM11.1667 2.54062V3.33351C11.1667 4.05581 11.2936 4.37824 11.4578 4.54246C11.622 4.70668 11.9444 4.83351 12.6667 4.83351H13.4596L11.1667 2.54062Z"
                                  fill="#7A7A7A" />
                              </svg>
                            </a>
                            <br />
                            <a href="javascript:void(0)" (click)="navigateToShare(video)" class="share-option">
                              <img src="../../../../../assets/images/svg/share-video.svg">

                            </a>
                            <br />
                            <div *ngIf="
                              video.publish_status != 'published' &&
                              this.isLocations == false
                            ">

                              <a href="javascript:void(0)" (click)="editVideo(video)" class="edit-option">
                                <img src="../../../../../assets/images/svg/edit-video.svg">

                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Select Media  sidebar Modal Start-->
    <!-- <nz-drawer
    [nzClosable]="false"
    [nzVisible]="showVideoDetailPopup"
    [nzPlacement]="placementUser"
    (nzOnClose)="toggleideoDetailModal(false)"
    [nzWidth]="478"
  >
    <ng-container *nzDrawerContent> -->
    <nav *ngIf="this.showVideoDetailPopup" class="rightSideBar sidebar-block right-0">
      <!-- <nav id="addAgencyUser" class="rightSideBar rightSideBarRight sidebar-block"> -->
      <div class="sidebar-header">
        <h6 class="text-gray-2 weight-700">{{ selectedMediaFile?.title }}</h6>
        <a *ngIf="isOwnerClip" href="#" class="del-media" data-bs-target="#del-popup" data-bs-toggle="modal">
          <img src="../../../../../assets/images/svg/delete.svg">
        </a>
        <div class="closeRightSideBar" (click)="toggleideoDetailModal(false)">
          <img src="../../../../../assets/images/svg/drawer-close.svg">
        </div>
      </div>

      <div class="sidebar-content">
        <div class="seleted-media mb-3">
          <img src="{{ selectedMediaFile?.thumbnail }}" />
        </div>
        <p class="text-gray-2 text-lg mb-2">Keywords</p>
        <ul class="media-tags" *ngIf="
              selectedMediaFile?.key_terms &&
              selectedMediaFile?.key_terms.length > 0
            ">
          <li class="tag-term" *ngFor="let term of selectedMediaFile?.key_terms">{{ term }}<a (click)="removeTag(term)">
              <img src="../../../../../assets/images/svg/close.svg" class="remove-tag">
              <!-- <svg width="8" height="8" viewBox="0 0 8 8" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0.273304 7.86667C0.203312 7.86667 0.136652 7.84 0.0833245 7.79C-0.0199979 7.68667 -0.0199979 7.51667 0.0833245 7.41333L3.55962 3.93333L0.0766585 0.453333C0.0266638 0.403333 0 0.336667 0 0.266667C0 0.196667 0.0266638 0.13 0.0766585 0.08C0.129986 0.0266667 0.196646 0 0.266638 0C0.336631 0 0.403291 0.0266667 0.456618 0.0766667L3.93625 3.55667L7.41255 0.0766667C7.46254 0.0266667 7.5292 0 7.5992 0C7.66919 0 7.73585 0.0266667 7.78918 0.0766667C7.8925 0.18 7.8925 0.35 7.78918 0.453333L4.31288 3.93333L7.78584 7.40667C7.83584 7.45667 7.8625 7.52333 7.8625 7.59333C7.8625 7.66333 7.83584 7.73 7.78584 7.78333C7.73585 7.83667 7.66919 7.86 7.59586 7.86C7.52254 7.86 7.45921 7.83333 7.40588 7.78333L3.93625 4.31L0.459951 7.79C0.409957 7.84 0.343297 7.86667 0.273304 7.86667Z"
                fill="#7A7A7A" />
            </svg> -->
            </a>
          </li>
        </ul>

        <div class="m-0">
          <p class="text-gray-2 text-lg mb-3">Media Info</p>
          <p class="d-flex cruunt-plan">
            <span class="text-gray-2 text-xs">Published on</span>
            <span class="text-gray-2 text-xs weight-600 text-end">{{
              selectedMediaFile?.created | date : "short" : "UTC"
              }}</span>
          </p>
          <!-- <p class="d-flex cruunt-plan">
              <span class="text-gray-2 text-xs">Video Size</span>
              <span class="text-gray-2 text-xs weight-600 text-end"
                >160.01 MB</span
              >
            </p> -->
        </div>
      </div>
    </nav>
    <!-- </ng-container
    ></nz-drawer> -->
    <!-- Select Media sidebar Modal end-->
  </div>
  <div class="modal fade" id="del-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">

        <div class="modal-body p-4">
          <h5>Are you sure you want to delete this Video?</h5>
          <p class="text-sm text-gray-3 pt-1 pb-4">The video will be permanently deleted from your account.
          </p>
          <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" data-bs-dismiss="modal">Cancel</a>
          <a href="javascript:void(0)" (click)="deleteVideo()" id="sidebarCollapse" data-bs-dismiss="modal"
            class="mt-3 text-gray-3 text-sm"> Delete</a>
        </div>
      </div>
    </div>
  </div>