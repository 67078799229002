import { error } from '@angular/compiler/src/util';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { promise } from 'protractor';
import { observable, of } from 'rxjs';

import { profile, profileLite, user } from '../models/onboarding';
import { cDataService } from '../services/data.service';
import { OnboardingDataService } from '../services/dataServices/onboarding-data.service';
import { AccountsettingService } from '../services/dataServices/accountsetting.service';
import { LoaderService } from '../services/loader.service';
import { cSessionService } from '../services/session.service';
import { Router } from '@angular/router';
import { ConfigManagerService } from './config-manager.service';
import { cIntercomService } from '../services/intercom.service';
import * as moment from 'moment';
import { ErrorService } from '../services/error.service';
import { SubscriptionplanManagerService } from './subscriptionplan-manager.service';

@Injectable({
    providedIn: 'root',
})
export class OnboardingManagerService {
    constructor(
        private intercomService: cIntercomService,
        private subscrptionManager: SubscriptionplanManagerService,
        public errorService: ErrorService,
        public dataService: OnboardingDataService,
        private route: Router,
        private oSessionService: cSessionService,
        private loader: LoaderService,
        private configManager: ConfigManagerService,
    ) {
        let email = this.oSessionService.cSessionService_GetUserEmail();
        let sessionid = this.oSessionService.SessionService_GetSessionId();
        this.loggedUser = this.oSessionService.cSessionService_GetLoggedUser();

        if (email && sessionid) {
            let oName: any = [];
            oName = email.split('@')[0];
            this.loggedUser = new user();
            this.loggedUser.email = email;
            (this.loggedUser.name = oName), (this.loggedUser.videoCount = 0);
            this.loggedUser.session_id = sessionid;

            // this.getUserProfile(email,sessionid).then();
        } else {
            // this.route.navigate(['/login']);
        }
    }

    public loggedUser: user;
    public profile: profile;
    public showSAPVideo = true;

    async signin(email: string, password: string): Promise<unknown> {
        this.loader.showLoader();
        let promise = new Promise((resolve, reject) => {
            this.dataService
                .signIn(email, password)
                .toPromise()
                .then(
                    async (res: any) => {
                        this.profile = res['user_profile'];
                        this.profile.email = email;
                        this.profile.session_id = res.session_id;
                        this.profile.user_account_type = this.profile.plan;
                        this.showSAPVideo = false;
                        if (this.profile.plan == 'enterprise') {
                            if (environment.SHOW_SAP) {
                                this.showSAPVideo = true;
                            }
                        } else {
                            if (environment.SHOW_SAP && environment.SHOW_SAP_FOR_SMB) {
                                this.showSAPVideo = true;
                            }
                        }
                        //SHOW SAP ENABLED BY DEFAULT
                        this.showSAPVideo = true;
                        // console.log(`%c --IS SAP VIDEO ENABLED -> ${this.showSAPVideo}`,"Font-size: 16px; color: green");
                        if (this.profile.organization && this.profile.organization._id && this.profile.organization._id.$oid) {
                            this.profile.organization_id = this.profile.organization._id.$oid;
                        }

                        this.oSessionService.cSessionService_SetUserEmail(email);
                        this.oSessionService.cSessionService_SetSessionId(res.session_id);
                        this.oSessionService.setUserOrganization(res.organization_id);
                        let isLocalizeEnable = this.profile.localization_enabled && this.profile.plan == 'enterprise'
                        this.oSessionService.setLocalizationEnable(isLocalizeEnable);
                        this.oSessionService.SessionService_StartSessionExpiryTimer();

                        // console.log("profile >>> Login", this.profile);

                        if (this.profile.user_status == 'confirmed_not_subscribed') {
                            this.loader.hideLoader();
                            // TODO remove show plan dialogue
                            // this.subscrptionManager.showPlanDialogue();
                            //this.route.navigate(['/create-account/select-plan']);
                        }

                        if (this.profile.user_status == 'canceled') {
                            var date = new Date(this.profile.next_billing_date);
                            var date2 = moment(new Date(this.profile.next_billing_date));
                            var tempDate = moment(new Date());

                            const diffDays = date2.diff(tempDate, 'days');
                            if (diffDays > 0) {
                                let errorMessage = 'Your subscription expires in ' + diffDays + ' days';
                                setTimeout(() => {
                                    this.errorService.handleError(errorMessage, 'Subscription', true, false, true);
                                }, 3000);
                            } else {
                                this.loader.hideLoader();
                                if (this.configManager.metaInfo.stripe_enabled) {
                                    this.route.navigate(['/create-account/select-plan']);
                                }
                            }
                        }

                        this.configManager.getTemplates2(email, res.session_id, this.profile.allowed_templates);

                        await this.getUserVideosCount(email, res.session_id).then((countRes) => {
                            let videosCount = countRes as number;

                            let oName: any = [];
                            oName = email.split('@')[0];
                            this.loggedUser = new user();
                            this.loggedUser.email = email;
                            (this.loggedUser.name = oName), (this.loggedUser.videoCount = videosCount);
                            this.loggedUser.session_id = res.session_id;
                            this.oSessionService.cSessionService_SetLoggedUser(this.loggedUser);
                        });

                        resolve(this.loggedUser);

                        this.loader.hideLoader();
                        // console.log("logged User" + this.loggedUser);
                    },
                    (msg) => {
                        // Error
                        this.loader.hideLoader();
                        reject(msg);
                    },
                );
        });
        return promise;
    }

    async signUp(profile: profile): Promise<unknown> {
        this.loader.showLoader();
        profile.is_onboarding = false;
        let promise = new Promise((resolve, reject) => {
            this.dataService
                .signUp(profile)
                .toPromise()
                .then(
                    (res: any) => {
                        this.loader.hideLoader();
                        this.profile = profile;
                        console.log(res);
                        resolve(res);
                    },
                    (msg) => {
                        // Error
                        this.loader.hideLoader();
                        reject(msg);
                    },
                );
        });
        return promise;
    }

    async updateBusinessDetail(businessProfile: any, is_onboarding: boolean): Promise<unknown> {
        this.loader.showLoader();
        // delete businessProfile.user_role;
        // businessProfile.is_onboarding = is_onboarding;
        let promise = new Promise((resolve, reject) => {
            this.dataService
                .updateBusinessProfile(businessProfile)
                .toPromise()
                .then(
                    (res: any) => {
                        console.log(res);
                        this.loader.hideLoader();
                        resolve('Updated');
                    },
                    (msg) => {
                        // Error
                        this.loader.hideLoader();
                        reject(msg);
                    },
                );
        });
        return promise;
    }
    async updateProfile(is_onboarding: boolean): Promise<unknown> {
        this.loader.showLoader();

        this.profile.is_onboarding = is_onboarding;
        let promise = new Promise((resolve, reject) => {
            this.dataService
                .updateProfile(this.profile)
                .toPromise()
                .then(
                    (res: any) => {
                        this.loader.hideLoader();
                        resolve('Updated');
                    },
                    (msg) => {
                        // Error
                        this.loader.hideLoader();
                        reject(msg);
                    },
                );
        });
        return promise;
    }

    async singOut(): Promise<unknown> {
        let promise = new Promise((resolve, reject) => {
            this.dataService
                .singOut(this.loggedUser.email, this.loggedUser.session_id)
                .toPromise()
                .then(
                    (res: any) => {
                        console.log(res);

                        resolve('SignOut');
                    },
                    (msg) => {
                        // Error
                        reject(msg);
                    },
                );
        });
        return promise;
    }

    async singOutAll(): Promise<unknown> {
        let promise = new Promise((resolve, reject) => {
            this.dataService
                .signoutAll(this.loggedUser.email, this.loggedUser.session_id)
                .toPromise()
                .then(
                    (res: any) => {
                        console.log(res);

                        resolve('SignOut');
                    },
                    (msg) => {
                        // Error
                        reject(msg);
                    },
                );
        });
        return promise;
    }

    async deleteAccount(): Promise<unknown> {
        let promise = new Promise((resolve, reject) => {
            this.dataService
                .deleteUserProfile(this.loggedUser.email, this.loggedUser.session_id)
                .toPromise()
                .then(
                    (res: any) => {
                        console.log(res);

                        resolve(res);
                    },
                    (msg) => {
                        // Error
                        reject(msg);
                    },
                );
        });
        return promise;
    }

    async forgotPassword(email): Promise<unknown> {
        this.loader.showLoader();
        let promise = new Promise((resolve, reject) => {
            this.dataService
                .forgotPassword(email)
                .toPromise()
                .then(
                    (res: any) => {
                        this.loader.hideLoader();

                        console.log(res);

                        resolve('SignOut');
                    },
                    (msg) => {
                        // Error
                        this.loader.hideLoader();
                        reject(msg);
                    },
                );
        });
        return promise;
    }

    async verifyPasswordResetCode(email, code): Promise<any> {
      this.loader.showLoader();
        return new Promise((resolve, reject) => {
            this.dataService.verifyResetPasswordCode(email, code).then((data) => {
              this.loader.hideLoader();
                resolve(data);
            }).catch( (error)=>{
              this.loader.hideLoader();
              reject(error);
            });
        });
    }

    async updateForgotPassword(email, code, password): Promise<unknown> {
        this.loader.showLoader();
        let promise = new Promise((resolve, reject) => {
            this.dataService
                .updateForgotPassword(email, code, password)
                .toPromise()
                .then(
                    (res: any) => {
                        this.loader.hideLoader();
                        console.log(res);

                        resolve('password reset');
                    },
                    (msg) => {
                        // Error
                        this.loader.hideLoader();
                        reject(msg);
                    },
                );
        });
        return promise;
    }

    async resetPassword(email, session_id, old_password, password, bypassInterceptor?: boolean): Promise<unknown> {
        this.loader.hideLoader();
        let promise = new Promise((resolve, reject) => {
            this.dataService
                .updatePassword(email, session_id, old_password, password, bypassInterceptor)
                .toPromise()
                .then(
                    (res: any) => {
                        this.loader.hideLoader();
                        console.log(res);

                        resolve('password reset');
                    },
                    (msg) => {
                        // Error
                        this.loader.hideLoader();
                        reject(msg);
                    },
                );
        });
        return promise;
    }

    async getUserProfile(email: string, session_id: string, Loader?): Promise<unknown> {
        this.loader.loading = !Loader;
        let promise = new Promise(async (resolve, reject) => {
            await this.dataService
                .getUserProfile(email, session_id)
                .toPromise()
                .then(
                    async (res: any) => {
                        // console.log("response", res);
                        this.loader.hideLoader();
                        this.profile = res;
                        //this.profile.user_status = "confirmed_not_subscribed";
                        this.profile.email = email;
                        this.profile.session_id = session_id;
                        this.profile.user_account_type = 'smb';
                        let isLocalizeEnable = this.profile.localization_enabled && this.profile.plan == 'enterprise'
                        this.oSessionService.setLocalizationEnable(isLocalizeEnable);
                        if (this.profile.user_status && this.profile.user_status == 'cancelled') {
                            localStorage.clear();
                            this.route.navigateByUrl('/login');
                        }
                        // this.profile.localization_enabled = false;
                        this.configManager.bDisplayLocales = this.profile.localization_enabled;

                        await this.getUserVideosCount(email, session_id).then((countRes) => {
                            let videosCount = countRes as number;

                            let oName: any = [];
                            oName = email.split('@')[0];
                            this.loggedUser = new user();
                            this.loggedUser.email = email;
                            (this.loggedUser.name = oName), (this.loggedUser.videoCount = videosCount);
                            this.loggedUser.session_id = res.session_id;
                            this.loggedUser.session_id = res.session_id;
                            this.oSessionService.cSessionService_SetLoggedUser(this.loggedUser);
                        });
                        this.intercomService.cIntercomService_InitializeIntercomForLoggedInUser(res);

                        resolve(res);
                    },
                    (err: any) => {
                        this.loader.hideLoader();
                        reject(err);
                    },
                );
        });
        return promise;
    }

    updateLogoFile(): Promise<unknown> {
        var brandLogodata = {
            email: this.loggedUser.email,
            session_id: this.loggedUser.session_id,
            organization_id: this.profile.organization_id,
            plan: this.profile.plan,
            user_logo_file: this.profile?.user_logo_file ? this.profile?.user_logo_file : '',
            logo_file: this.profile?.logo_file ? this.profile?.logo_file : '',
        };
        this.loader.showLoader();

        let promise = new Promise((resolve, reject) => {
            this.dataService
                .updateUserProfile(brandLogodata)
                .toPromise()
                .then((res: any) => {
                    this.loader.hideLoader();
                    resolve(res);
                })
                .catch((err: any) => {
                    this.loader.hideLoader();
                    console.log('Error ', err);
                });
        });
        return promise;
    }

    updateUserLogo(profile: profile): Promise<unknown> {
        const userProfileInfo = {
            email: this.loggedUser.email,
            session_id: this.loggedUser.session_id,
            user_logo_file: profile.user_logo_file,
            plan: profile.plan,
        };

        this.loader.showLoader();
        let promise = new Promise((resolve, reject) => {
            this.dataService
                .updateUserProfile(userProfileInfo)
                .toPromise()
                .then(
                    (res: any) => {
                        this.loader.hideLoader();
                        resolve(res);
                    },
                    (err: any) => {
                        this.loader.hideLoader();
                        reject(err);
                    },
                );
        });
        return promise;
    }
    updateUserProfile(profile: profile): Promise<unknown> {
        profile.email = this.loggedUser.email;
        profile.session_id = this.loggedUser.session_id;
        // profile.organization_id = profile.organization._id.$oid;
        // profile.organization = null;
        profile.is_onboarding = false;
        this.loader.showLoader();
        let promise = new Promise((resolve, reject) => {
            this.dataService
                .updateUserProfile(profile)
                .toPromise()
                .then(
                    (res: any) => {
                        this.loader.hideLoader();
                        // this.profile = res;

                        resolve(res);
                    },
                    (err: any) => {
                        this.loader.hideLoader();
                        reject(err);
                    },
                );
        });
        return promise;
    }

    verifyEmail(email: string, code: string): Promise<unknown> {
        this.loader.showLoader();
        let promise = new Promise((resolve, reject) => {
            this.dataService
                .verifyEmail(email, code)
                .toPromise()
                .then(
                    (res: any) => {
                        this.loader.hideLoader();

                        resolve(res);
                    },
                    (err: any) => {
                        this.loader.hideLoader();
                        reject(err);
                    },
                );
        });
        return promise;
    }

    reVerifyEmail(email: string): Promise<unknown> {
        this.loader.showLoader();
        let promise = new Promise((resolve, reject) => {
            this.dataService
                .reVerifyEmail(email)
                .toPromise()
                .then(
                    (res: any) => {
                        this.loader.hideLoader();

                        resolve(res);
                    },
                    (err: any) => {
                        this.loader.hideLoader();
                        reject(err);
                    },
                );
        });
        return promise;
    }

    getUserVideosCount(email: string, sessionId: string) {
        // this.loader.showLoader();
        let promise = new Promise((resolve, reject) => {
            this.dataService
                .getUserVideoCount(email, sessionId)
                .then((countRes) => {
                    // console.log("count", countRes);
                    //  this.loader.hideLoader();
                    let videosCount = countRes['draft_video_count'] + countRes['published_video_count'];
                    if (this.loggedUser) this.loggedUser.videoCount = videosCount;
                    resolve(videosCount);
                })
                .catch((err) => {
                    //  this.loader.hideLoader();
                    reject(err);
                });
        });
        return promise;
    }

    selectPlan(email, plan) {
        let promise = new Promise((resolve, reject) => {
            this.dataService
                .seletPayment(email, plan)
                .toPromise()
                .then((redirectUrl) => {
                    if (redirectUrl['redirect_url'] != '') {
                        resolve(redirectUrl['redirect_url']);
                    } else {
                        reject('');
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return promise;
    }
}
