import { OnboardingDataService } from './../services/dataServices/onboarding-data.service';
import { voice } from './../models/video';
import { browser } from 'protractor';
import { SearchData } from 'src/app/models/admin';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiURL } from 'src/app/conf/api-path';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { OnboardingManagerService } from '../Managers/onboarding-manager.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { VoiceEditorPopupComponent } from '../shared/voice-editor-popup/voice-editor-popup.component';
import { VideoVoiceOverSelectionComponent } from '../shared/components/video-voice-over-selection/video-voice-over-selection.component';
import { CreateVideoManagerService } from '../Managers/create-video-manager.service';
import { UploadFileDialogComponent } from '../shared/components/upload-file-dialog/upload-file-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ErrorService } from '../services/error.service';
import { MediaManagerService } from '../Managers/media-manager.service';

@Injectable({
    providedIn: 'root',
})
export class DialogDataStore {
    destroy$: Subject<boolean> = new Subject<boolean>();
    constructor(public videoManger: CreateVideoManagerService, private dialog: MatDialog, private errorService: ErrorService, private mediaManger: MediaManagerService) {}

    openFileUploadModal(callback, callbackDuplicateMedia, options : any) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '100wv';
        dialogConfig.height = '100vh';
        dialogConfig.panelClass = 'pronto-dialog';
        dialogConfig.closeOnNavigation = true;

        dialogConfig.data = {
            uploadFor: options?.fileType? options?.fileType : 'image_video_logo',
            multipleUpload: false,
            // accept: "video/mp4, video/avi",
            dropBoxText: '',
            heading: options?.heading? options?.heading : 'Upload a Logo',
            allowDuplicate: true,
            mediaLibraryId:
                this.videoManger.selectedVideo?.brand_id &&
                this.videoManger.videoBrand?.media_libs &&
                this.videoManger.videoBrand?.media_libs.length > 0
                    ? this.videoManger.videoBrand?.media_libs[0]._id?.$oid
                    : undefined,
        };

        let showUploadModal = true;
        if (!dialogConfig.data.mediaLibraryId) {
            delete dialogConfig.data.mediaLibraryId;
        }
        if (showUploadModal) {
            let dialogModal = this.dialog.open(UploadFileDialogComponent, dialogConfig);

            dialogModal.afterClosed().subscribe((data: any) => {
                // console.log("response upload ", data);
                callback(data);
            });
        } else {
            this.errorService.handleError('Brand does not have any Media Library', 'Logo Update', true, false, true);
        }
        this.mediaManger.replaceMediaFromVisualPopup.pipe(takeUntil(this.destroy$)).subscribe((item) => {
            this.mediaManger.showMediaReplacementPopup = false;
            // console.log(" HEre in subscirbed case ..", item);
            callbackDuplicateMedia(item);
        });
    }
}
