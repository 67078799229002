import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-timeout-error',
  templateUrl: './timeout-error.component.html',
  styleUrls: ['./timeout-error.component.scss']
})
export class TimeoutErrorComponent implements OnInit {
@Input() lInputErrorMessage;
  constructor() { }

  ngOnInit(): void {
    this.TimeOutErrorComponent_InitializeJqueryCode()
    if(this.lInputErrorMessage == undefined)
    {
      this.lInputErrorMessage='Pronto is experiencing network issues right now. We’re working on fixing this as soon as possible. Please refresh or try again later.';
    }
  }
  TimeOutErrorComponent_InitializeJqueryCode()
  {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.text = `
    jQuery('.button-gl').on('click', function (e) {
      jQuery('.button-gl').removeClass('active');
      jQuery(this).addClass('active');

      if (jQuery(this).hasClass('grid')) {
          jQuery('#container-view div.row').removeClass('list').addClass('grid');
      }
      else if (jQuery(this).hasClass('list')) {
          jQuery('#container-view div.row').removeClass('grid').addClass('list');
      }
  });

  jQuery(function () {
    
      jQuery('.showSingle').click(function () {
          jQuery('.targetDiv').hide('.cnt');
          jQuery('#div' + $(this).attr('target')).slideToggle();

      });
  })`;
  }

}
