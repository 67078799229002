import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fontNameTruncate'
})
export class FontNameTruncate implements PipeTransform {

  transform(text: string, length: number = 10): string {
        let splitedName = text.split("/");
        let nameFile = text;

        if(splitedName.length > 0 ){
            let nameFile = splitedName[splitedName.length - 1];
            nameFile = nameFile.substring(0, nameFile.lastIndexOf('.'));
            let truncated: string = nameFile.substring(0, length).trim().replace("-"," ").replace("_"," ");
            return truncated;
        } else {
            let truncated: string = nameFile.substring(0, length).trim().replace("-"," ").replace("_"," ");
            return truncated;
        }



  }
}