import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { cDataService } from './../../../services/data.service';
import { cSessionService } from './../../../services/session.service';
import { cWebSocketService } from './../../../services/websocket.service';


@Component({
  selector: 'app-media-libraryy',
  templateUrl: './media-libraryy.component.html',
  styleUrls: ['./media-libraryy.component.scss']
})
export class MediaLibraryyComponent implements OnInit {
  lFilesToUploadList : any = [];
  nCurrentFileIndex : number = 0;
  sSessionId: string;
  sUserEmail: string;
  oCurrentVisualToUpload : any;
  bSelectMultipleVisuals : boolean = false;
  sSelectedMediaLibrary : string;
  sInputVisualPath : string;
  lInputVisualsToDelete : any = [];
  slides = [
    {img: "../assets/images/business_Profile/img-sel.png"},
    {img: "../assets/images/business_Profile/img-sel.png"},
    {img: "../assets/images/business_Profile/img-sel.png"},
    {img: "../assets/images/business_Profile/img-sel.png"},
    {img: "../assets/images/business_Profile/img-sel.png"}
  ];
  slideConfig = {
    "slidesToShow": 3,
    "slidesToScroll": 1,
    "dots": false,
    "infinite": true,
    "autoplay" : true,
    "autoplaySpeed" : 1500
  };  
  addSlide() {
    this.slides.push({img: "http://placehold.it/350x150/777777"})
  }
  
  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }
  
  slickInit(e) {
    console.log('slick initialized');
  }
  
  breakpoint(e) {
    console.log('breakpoint');
  }
  
  afterChange(e) {
    console.log('afterChange');
  }
  
  beforeChange(e) {
    console.log('beforeChange');
  }  

  bDisplayMediaLibraryTab: boolean = false;

  constructor(private oRouter : Router,  private oSessionService : cSessionService) 
  {
  }

  ngOnInit(): void {
    if(this.oRouter.url === '/business-profile/brands/media-library')
    {
       this.bDisplayMediaLibraryTab = true
    }
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
  }
  MediaLibrarySharedComponent_ToggleMultiSelection()
  {
    this.bSelectMultipleVisuals=true;
  }
 
  MediaLibraryComponent_ReturnFileSize(bytes, decimals = 2)
  {
    if (bytes === 0)
    {
      return "0 b";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["b", "kb", "mb", "gb", "tb", "pb", "eb", "zb", "yb"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm))+sizes[i];
  }

  InitializeJqueryCode()
  {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.text = `
    $(document ).ready(function() {
      $("[data-sidebar]").click(function() {
        var sideBarId = $(this).attr("data-sidebar");
        $(".rightSideBar").removeClass("active");
        $(".sidebarOverlay").addClass("active");
        $(sideBarId).addClass("active");
      });
      
      
      $(".sidebarOverlay, .closeRightSideBar").click(function() {
        $(".rightSideBar").removeClass("active");
        $(".sidebarOverlay").removeClass("active");
        $("body").removeClass("sidbar_contentslide");
      });
      
      $("[data-sidebar2]").click(function() {
        var sideBarId = $(this).attr("data-sidebar2");
        $(".rightSideBarTutorialsVideo").removeClass("active");
        $(".sidebarOverlay2").addClass("active");
        $(sideBarId).addClass("active");
      });
      
      
      $(".sidebarOverlay2, .closeRightSideBar2").click(function() {
        $(".rightSideBarTutorialsVideo").removeClass("active");
        $(".sidebarOverlay2").removeClass("active");
        $("body").removeClass("sidbar_contentslide");
      });
    
      // data-custom-select
      $("[data-custom-select]").click(function() {
        $("body").addClass("sidbar_contentslide");
      });
      // end data-custom-select
    
    // dropdownBtn
      $(".dropdownBtn .dropdown-item, .dropdownSelect .dropdown-item").on("click", function () {
        var dropVal = $(this).text();
        $(this).parent("li").siblings().children().removeClass("active");
        $(this).addClass("active");
        $(this).closest(".dropdownBtn, .dropdownSelect").find(".dropdownBtnText").text(dropVal);
    });
    // end dropdownBtn
    
    });
      $(function($) {
        $.autofilter();
      });
      `;
    document.body.appendChild(chatScript);
  }
}

