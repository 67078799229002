import { CreateVideoManagerService } from "src/app/Managers/create-video-manager.service";
import { Pipe, PipeTransform } from "@angular/core";

/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
 */
@Pipe({ name: "contentTextLayersMerger" })
export class ContentTextLayersMerger implements PipeTransform {
  constructor(private videoManager: CreateVideoManagerService) {}
  pushNumKeys = [];
  filteredField = [];
  transform(dataTextDuration: any[] = [], flag: boolean = true) {
    if (dataTextDuration.length > 0) {
      this.getDurationList(dataTextDuration).then((res) => {
        this.videoManager.duration_fine_print_text_List.map((item: any) => {
          // console.log('Checking fine print field ', item.duration);
          if (this.pushNumKeys.indexOf(Math.floor(item.duration)) === -1) {
            item.durationMin = Math.floor(item.duration);
            this.filteredField.push(item);
          }
        });
        this.filteredField.sort((a: any, b: any) => a.durationMin - b.durationMin);
        return this.filteredField;
      });

        this.filteredField.sort((a: any, b: any) => a.durationMin - b.durationMin);
        return this.filteredField;
    } else {
      return dataTextDuration;
    }
  }

  getDurationList(dataTextDuration: any[]) {
    return new Promise((resolve, reject) => {
      this.filteredField = dataTextDuration.filter((item: any) => {
        if (
          item.duration != undefined &&
          item.textlist &&
          item.textlist.length > 0
        ) {
          if (this.pushNumKeys.indexOf(Math.floor(item.duration)) === -1) {
            item.durationMin = Math.floor(Math.floor(item.duration));
            this.pushNumKeys.push(Math.floor(item.duration));
            return item;
          }
        }
      });
      resolve(this.filteredField);
    });
  }
}
