import { EventEmitter, Injectable } from '@angular/core';

import { block_args, duration, scheduledVideos, video } from '../models/video';

import { Observable, interval, timer, observable } from 'rxjs';

import { takeWhile } from 'rxjs/operators';
import { VideoService } from '../services/dataServices/video.service';
import { LoaderService } from '../services/loader.service';
import { template, templateBlockConfiguration, templatesCategory } from '../models/template';
import { ConfigManagerService } from './config-manager.service';

import { OnboardingManagerService } from './onboarding-manager.service';
import { ErrorService } from '../services/error.service';
import { Router } from '@angular/router';
import { item } from '../components/modify-video/sap-video-timeline/sap-video-timeline.component';

@Injectable({
    providedIn: 'root',
})
export class FtCreateVideoManagerService {
    constructor(
        public dataservice: VideoService,
        public loader: LoaderService,
        public configManager: ConfigManagerService,
        public onboardManger: OnboardingManagerService,
        public errorService: ErrorService,
        private oRouter: Router,
    ) { }
    public submitForm: EventEmitter<string> = new EventEmitter();
    public previousSubmit: EventEmitter<number> = new EventEmitter();
    public clearGenerationInterval = false;
    public previewMode: boolean = false;
    public selectedVideo: video;
    public selectedTemplate: template;
    public selectedCategory: templatesCategory;
    public selectedDuration: number;
    public isEditMode: boolean = false;
    public guestEmail: string;
    public isFirstTime: boolean = false;
    public durations: duration[] = [];
    public blocksconfig: block_args[] = [];
    public duration_auto_fill_List: duration[] = [];
    indexMultiLiner = [];
    // public guest_session = "";
    // public guest_email = "";
    templateFtVideoLimit: any;
    templateFtVideoDurationLimit = 0;
    public guestUser(guest_email) {
        this.loader.showLoader();
        let promise = new Promise((resolve, reject) => {
            this.dataservice
                .guestUser(guest_email)
                .then((res) => {
                    this.selectedVideo = undefined;
                    this.loader.hideLoader();
                    resolve(res);
                })
                .catch((err) => {
                    console.log('wrong email h here');
                    this.errorService.handleError(err.errorMessage, err.errorType, true);
                    this.loader.hideLoader();
                    reject(err);
                });
        });
        return promise;
    }

    public setEditVideo(email, session_id, video: video) {
        this.selectedVideo = video;
        this.isEditMode = true;
        this.isFirstTime = false;
        console.log('list', this.configManager.Templates);
        this.selectedVideo.email = email;
        this.selectedVideo.session_id = session_id;
        console.log('selected Video', this.selectedVideo);
        console.log(this.configManager.Templates.filter((c) => c.category === this.selectedVideo.template_category));
        this.selectedCategory = this.configManager.Templates.filter((c) => c.category === this.selectedVideo.template_category)[0];
        this.selectedTemplate = this.selectedCategory?.templates.filter((c) => c.template_name === video.template_name)[0];

        console.log('category', this.selectedCategory);

        console.log('template', this.selectedTemplate);
        if (this.selectedTemplate) {
            this.changeTemplate(this.selectedTemplate);
        }
        this.getsignedThumbandURLForBlock(email, session_id);
    }

    public newVideo() {
        console.log('NEw video Click');
        this.selectedVideo = undefined;
        this.isEditMode = false;
        this.isFirstTime = false;
    }
    public firstTimeVideo() {
        this.selectedVideo = undefined;
        this.isEditMode = false;
        this.isFirstTime = true;
    }

    public changeTemplate(template: template) {
        this.durations = [];
        console.log(' this.selectedVideo ', this.selectedVideo.aspect_ratio);
        console.log(' this.selectedVideo ', this.selectedVideo.duration);
        console.log('template to Change', template);
        if (template && template.previews) {
            console.log("previews are avaiable now ...");
            let filterTemplates = template.previews.filter((item: any) => {
                if (item.aspect_ratio == this.selectedVideo.aspect_ratio && item.duration == this.selectedVideo.duration) {
                    return item;
                }
            });
            console.log('FILTER TEMPLATES ...', filterTemplates);
            // template.previews = filterTemplates;
            // console.log('template to Change', template);
        }


        this.selectedTemplate = template;

        let numSize = 0;

        if (template) {
            this.selectedVideo.video_preview_url = template.video_preview_url ? template.video_preview_url : template.preview_url;
            console.log('URL is working fine..', this.selectedVideo.video_preview_url);
            this.duration_auto_fill_List = [];
            this.dataservice.getTemplateContent(template.template_name, 'text', this.selectedVideo?.duration).then((res) => {
                let texts = res['template_data'];
                // console.log('templatesss', texts);
                let prevText: string[] = [];
                this.durations = [];
                texts.forEach((txt, index) => {
                    // console.log(txt);
                    if (txt.text != '_address_' && txt.text != '_url_' && txt.text != '_phone_') {
                        this.durations.forEach((d) => {
                            if (d.placehoder == txt.text) {
                                if (txt.ts[0] <= d.duration) {
                                    d.duration = txt.ts[0];
                                }
                            }
                        });

                        if (!prevText.includes(txt.text)) {
                            prevText.push(txt.text);
                            this.createDurationObject(txt, template, index, index);
                        }
                    }
                });
                this.durations.sort((a: any, b: any) => a.duration - b.duration);
            });
        }

        /* let blocktemplate = this.selectedTemplate.templateBlockConfiguration.filter(c => c.timeframe == this.selectedVideo.duration)[0];
     this.blocksconfig = blocktemplate.blocks;

     blocktemplate.blocks.forEach((ele, index) => {

       console.log('element', ele);
       let dur = new duration();
       dur.id = template.template_name + index;
       dur.duration = numSize;
       dur.num_text = ele.num_texts



       for (let nTextAreaToPush = 0; nTextAreaToPush < ele.num_texts; nTextAreaToPush++) {
         if (nTextAreaToPush == 0) {
           dur.textlist.push({ placeholder: "Add Title", value: "" });
         }
         else {
           if (nTextAreaToPush == ele.num_texts - 1) {
             if (ele.num_texts == 2) {
               dur.textlist.push({ placeholder: "Add Subtitle " + nTextAreaToPush, value: "" });
             }
             else {
               dur.textlist.push({ placeholder: "Add Description", value: "" });
             }
           }
           else {
             dur.textlist.push({ placeholder: "Add Subtitle " + nTextAreaToPush, value: "" });
           }
         }
       }


       if (index == 0) {
         this.durations.push(dur);
       } else {
         this.durations.push(dur);
       }

       console.log('video_gen ', ele.video_gen);
       numSize += ele.video_gen?.duration;

     });*/
        console.log(this.durations);
    }

    createDurationObject(txt, template, index, orgIndex) {
        let dur = new duration();
        dur.id = template.template_name + index;
        dur.duration = txt.ts[0];
        dur.originalSortOrder = orgIndex;
        dur.pos = txt.pos;
        dur.size = txt.size;
        dur.index = index;
        dur.parentIndex = txt.parentIndex ? txt.parentIndex : undefined;
        dur.max_chars = txt.max_chars ? txt.max_chars : 40;
        dur.isMultiLiner = txt.line_count && txt.line_count > 1 ? true : false;
        dur.content = txt.content;
        dur.placeholder = 'Enter text';
        if (dur.isMultiLiner) {
            // console.log(Array.isArray(txt.placeholder), this.indexMultiLiner.indexOf(index));
            if (this.indexMultiLiner.indexOf(index) > -1) {
                let loopIndex = 0;
                for (let i = 0; i < txt.line_count; i++) {
                    const txtObj = {
                        content: txt.content,
                        id: txt.id + '' + i,
                        placeholder:
                            txt.placeholder && Array.isArray(txt.placeholder)
                                ? txt.placeholder.length >= i && txt.placeholder[i]
                                    ? txt.placeholder[i]
                                    : ''
                                : txt.placeholder,
                        // placeholder: "text here",
                        stretch: '',
                        isMultiLiner: false,
                        max_chars: txt.max_chars ? txt.max_chars : 40,
                        parentIndex: index,
                        text: '_txt1_',
                        index: index,
                        pos: txt.pos,
                        size: txt.size,
                        ts: txt.ts,
                    };
                    txtObj.placeholder = txtObj.placeholder;
                    // console.log("recreate obj");
                    this.createDurationObject(txtObj, template, index + '-' + i, index);
                }
            }
        } else {
            if (txt.placeholder) {
                dur.placeholder = txt.placeholder;
            }
        }

        dur.textlist.push({ placeholder: dur.placeholder, value: '' });
        if (!txt.content || dur.content != 'auto_text') {
            this.durations.push(dur);
        } else {
            this.duration_auto_fill_List.push(dur);
        }
    }

    public createVideo(video: video) {
        this.loader.showLoader();
        video.watermark = true;
        let promise = new Promise((resolve, reject) => {
            if (video.template_name == undefined || video.template_name == '' || video.template_name.toLowerCase() != 'sap') {
                delete video.sap_video_duration;
            }
            this.dataservice
                .createVideo(video)
                .then((res) => {
                    this.loader.hideLoader();
                    resolve(res);
                })
                .catch((err) => {
                    this.errorService.handleError(err.errorMessage, err.errorType, true);
                    this.loader.hideLoader();
                    reject(err);
                });
        });

        return promise;
    }

    updateVideoStatus(email, session_id, video_id, status): Promise<unknown> {
        this.loader.showLoader();
        let promise = new Promise((resolve, reject) => {
            this.dataservice
                .updateStatus(email, session_id, video_id, status)
                .then((res) => {
                    this.loader.hideLoader();
                    resolve(res);
                })
                .catch((err) => {
                    this.loader.hideLoader();
                    reject(err);
                });
        });
        return promise;
    }
    getVideo(email, session_id, video_id): Promise<unknown> {
        let promise = new Promise((resolve, reject) => {
            this.dataservice
                .getVideo(email, session_id, video_id)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return promise;
    }
    generateVideo(email, session_id, video_id): Promise<unknown> {
        let promise = new Promise((resolve, reject) => {
            this.dataservice
                .generateVideo2(email, session_id, video_id)
                .then(async (res) => {
                    await this.getVideo(email, session_id, video_id).then(async (res) => {
                        this.selectedVideo = res['video'] as video;

                        await this.dataservice.getSignedURL(email, session_id, video_id).then((result) => {
                            this.selectedVideo.video_url = result['url'];
                        });
                        if (this.selectedVideo.thumb != '')
                            await this.dataservice.getSignedURL(email, session_id, this.selectedVideo.thumb).then((result) => {
                                this.selectedVideo.thumbnail = res['url'];
                            });
                    });

                    this.gv_task_id = res['gv_task_id'];

                    resolve(res);
                })
                .catch((err) => {
                    let sErrorMessage;
                    if (err.errorMessage && err.errorMessage.indexOf("No clips found") > -1) {
                        let sErrorMessage = err.errorMessage;
                        let sErrorMessageTitle = 'Video Generation Failed';
                        // console.log('process failed ', err);
                        this.errorService.handleError(sErrorMessage, sErrorMessageTitle, true);
                        // this.oRouter.navigateByUrl('/dashboard/myvideos');
                    } else {
                        if (err.errorMessage.includes("Our AI couldn't find relevant clips")) {
                            sErrorMessage = err.errorMessage;
                        }
                        else {
                            sErrorMessage = `We're sorry, but we were unable to generate your video due to technical issues. Please try again later or contact our support team for assistance.`;
                        }
                        let sErrorMessageTitle = 'Video Generation Failed';
                        this.errorService.handleError(sErrorMessage, sErrorMessageTitle, true);
                        // this.oRouter.navigateByUrl('/dashboard/myvideos');
                    }

                    reject(err);
                });
        });
        return promise;
    }
    public gv_task_id: string;
    public isVideoGenerated: boolean = false;
    generateVideoStatus(email, session_id, gv_task_id): Promise<unknown> {
        if (this.isVideoGenerated) {
            this.isVideoGenerated = false;
        }

        let promise = new Promise((resolve, reject) => {
            let generationInterval = interval(2000)
                .pipe(takeWhile(() => !this.isVideoGenerated))
                .subscribe(() => {
                    if (this.clearGenerationInterval) {
                        generationInterval.unsubscribe();
                    }
                    this.dataservice
                        .generatedVideoStatus(email, session_id, gv_task_id)
                        .then((data) => {
                            if (data['task_status'] == 'success') {
                                this.isVideoGenerated = true;
                                resolve(data);
                            } else {
                                this.isVideoGenerated = false;
                            }
                        })
                        .catch((err) => {
                            this.isVideoGenerated = true;
                            reject(err);
                        });
                });
        });

        return promise;
    }

    getsignedThumbandURLForBlock(email, session_id) {
        console.log('----', this.selectedVideo);
        if (this.selectedVideo.music_file) {
            this.dataservice
                .getSignedURL(email, session_id, this.selectedVideo.music_file)
                .then((res) => {
                    this.selectedVideo.music_file_url = res['url'];

                    // console.log("singed-------------------" + JSON.stringify(vid));
                })
                .catch((err) => { });
        }
        if (this.selectedVideo.voice_file) {
            this.dataservice
                .getSignedURL(email, session_id, this.selectedVideo.voice_file)
                .then((res) => {
                    this.selectedVideo.voice_file_url = res['url'];

                    // console.log("singed-------------------" + JSON.stringify(vid));
                })
                .catch((err) => { });
        }

        if (this.selectedVideo.vo_text_file) {
            this.dataservice
                .getSignedURL(email, session_id, this.selectedVideo.vo_text_file)
                .then((res) => {
                    this.selectedVideo.voice_file_url = res['url'];

                    // console.log("singed-------------------" + JSON.stringify(vid));
                })
                .catch((err) => { });
        }

        let batchUrl = [];
        this.selectedVideo.block_config.forEach((vid) => {
            vid.loadingUrl = true;
            if (vid.block_file_path != '') {
                vid.thumb = vid.block_file_path.slice(0, vid.block_file_path.indexOf('.mp4')) + '_thumb.jpg';

                batchUrl.push(vid.block_file_path);
                if (vid.thumb != '') batchUrl.push(vid.thumb);
            }
        });

        this.dataservice
            .getBatchSignedURL(email, session_id, batchUrl)
            .then((res) => {
                let output = res['output'];
                // console.log('output',output);
                this.selectedVideo.block_config.forEach((e, index) => {
                    e.loadingUrl = false;

                    // console.log(e.thumb);
                    e.url = output.filter((a) => a[e.block_file_path])[0][e.block_file_path];
                    e.thumbnail = output.filter((a) => a[e.thumb])[0][e.thumb];
                    // console.log('thumb', output.filter(a => a[e.thumb]));
                    // console.log(e);
                });
                //  console.log("singed-------------------" + JSON.stringify(vid));
            })
            .catch((err) => {
                this.selectedVideo.block_config.forEach((e, index) => {
                    e.loadingUrl = false;
                });
            });
    }

    public changeTemplateForClip(template: template) {
        this.templateFtVideoDurationLimit = 0;
        if (template) {
            this.dataservice
                .getTemplateContent(template.template_name, 'clip', this.selectedVideo?.duration)
                .then((res) => {
                    let templateVideos = res['template_data'];
                    this.templateFtVideoLimit = templateVideos.length;
                    templateVideos.map((data) => {
                        if (data.ts[1] > this.templateFtVideoDurationLimit) {
                            this.templateFtVideoDurationLimit = data.ts[1];
                        }

                    })
                })
                .catch((err) => {
                    // console.log(err);
                    this.errorService.handleError(err.error ? err.error : err, 'Content Generation Failed', true);
                });
        }

    }
}
