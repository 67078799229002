import { Directive, ElementRef, HostListener, Input, Output,EventEmitter } from '@angular/core';





@Directive({
  selector: '[appMousepositiontrack]'
})
export class MousepositiontrackDirective {

  constructor(public el: ElementRef) {

 }
 @Input() duration = 0;
isMouseInsideDiv:boolean =false;
 @Output() currentIndex =  new EventEmitter();


 @HostListener('mousemove', ['$event'])
 onMousemove(event: MouseEvent) {
  // console.log(" mouse move called...");
   this.MouseMove(event);
 }

 @HostListener('mouseenter', ['$event'])
 onMouseEnter(event: MouseEvent) {
  // console.log(" mouse entered inside ..");
   this.isMouseInsideDiv = true;
 }

 @HostListener('mouseleave', ['$event'])
 onMouseLeave(event: MouseEvent) {
  // console.log(" Mouse leave event called..");
  this.isMouseInsideDiv = false;
 }



 private MouseMove(event) {

  if(this.isMouseInsideDiv){

    let field = document.querySelectorAll(".custom-video-timeline")[0];
    let fieldMain = document.querySelector("#pills-tabContent");
    let fieldMainInside = document.querySelectorAll(".timelineViewRowTitle");
    let timelineViewRowLayer  = document.querySelectorAll(".timelineViewRowLayer ");

    let diffPanels = 0;
    let diffMargin = window.outerWidth - window.innerWidth;

    if(field){
      diffPanels = fieldMain.clientWidth - field.clientWidth;
    }

    // console.log( " diffPanels ", diffPanels);
    if( diffPanels == 0 ){
      this.currentIndex.emit(event.clientX - 0);
    } else {
      this.currentIndex.emit(event.clientX - 267);
    }

  }
 }

}
