import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SuccessMessageModalService {
    show: boolean = false;
    showSuccessModal: boolean = false;
    message: string = 'Uploading start';
    title: string = 'Title of Box';
    buttonTitle: string = 'Ok';
    showHead = true;
    showLoader = true;

    showInfoToasterModal: boolean = false;
    infoToastMessage: string = '';
    csvFileInfo: any;
    showCSVUploadResponseBox = false;
    constructor() {}

    isAlreadyOpen() {
        return this.showSuccessModal;
    }

    success(error: any, errorTitle: string, showDialogue: boolean, autoClose?: boolean, showLoader = true) {
        this.showSuccessModal = showDialogue;
        this.message = error;
        this.title = errorTitle;
        this.showHead = true;

        if (autoClose) {
            setTimeout(() => {
                this.showSuccessModal = false;
            }, 3000);
        }
    }

    //Without Header....
    displayMessage(error: any, errorTitle = '', showDialogue = true, showHeader = false, autoClose = false, showLoader = true) {
        // console.log(" info pop called..");
        this.show = showDialogue;
        this.showSuccessModal = true;
        this.message = error;
        this.show;
        this.title = errorTitle;
        this.showHead = showHeader;

        if (autoClose) {
            setTimeout(() => {
                this.show = false;
            }, 3000);
        }
    }

    //Without Header....
    displayCSVFileUploadResponse(error: any, errorTitle = '', showDialogue = true, showHeader = false, autoClose = false, response: any) {
        // console.log(" info pop called..");
        this.show = showDialogue;
        this.showSuccessModal = true;
        this.message = error;
        this.show;
        this.title = errorTitle;
        this.showHead = showHeader;
        this.csvFileInfo = response;
        if (autoClose) {
            setTimeout(() => {
                this.show = false;
            }, 3000);
        }
    }
    info(error: any, errorTitle: string, showDialogue: boolean, autoClose?: boolean, showLoader = true) {
        // console.log(" info pop called..");
        this.show = showDialogue;
        this.showSuccessModal = true;
        this.message = error;
        this.title = errorTitle;
        this.showHead = true;

        if (autoClose) {
            setTimeout(() => {
                this.show = false;
            }, 3000);
        }
    }

    display(error: any, errorTitle: string, showDialogue: boolean, autoClose?: boolean, showLoader = true) {
        this.show = showDialogue;
        this.message = error;
        this.title = errorTitle;
        this.showHead = true;
        if (autoClose) {
            setTimeout(() => {
                this.show = false;
            }, 3000);
        }
    }

    close() {
        this.show = false;
        this.showSuccessModal = false;
        this.showHead = true;
    }

    showInfoToaster(message: any, showDialogue: boolean, autoClose?: boolean) {
        this.showInfoToasterModal = showDialogue;
        this.infoToastMessage = message;

        if (autoClose) {
            setTimeout(() => {
                this.showInfoToasterModal = false;
            }, 3000);
        }
    }
    csvFileUploadBar = false;
    csvUploadMessage = '';

    public bottomBar = {
      show : false,
      message : '',
      title : ''
    }

    resetBottomBar() {
      this.bottomBar.show = false;
        this.bottomBar.message =  '';
        this.bottomBar.title =  '';
    }
    showCSVFileUploadBar(options: any, procces : Subject<{data : any}>) : void {
    //   console.log(" show bottom bar ...");
        this.bottomBar.show = true;
        this.bottomBar.message = options.message ? options.message : '';
        this.bottomBar.title = options.title ? options.title : '';
        procces.subscribe( (item : any) =>{
          this.resetBottomBar();
          if(item.type && item.type == 'success'){
          this.success(item.title, item.message, true , true, false);
          }
        })
    }
}
