<section class="loginSec">
    <app-onboarding-aside-section class="loginSecItm loginSecLeft"></app-onboarding-aside-section>
    <div class="loginSecItm loginSecRight">
        
        <div class="loginSecItmContent">
            <div class="d-lg-none pb-5">
                <a  routerLink="/login">
                    <img src="../assets/images/logo-mobile.svg" alt="logo-mobile" width="120" />
                </a>
            </div>

            <div class="row my-3">
                <div class="col-md-8" style="margin-left: -8px;">
                    <img src="../../../assets/images/verify-cross.svg">
                    
                </div>
            </div>
            <h1 class="text-gray-1 mt-4">Email verification link Expired</h1>
            <div class="loginSecItmForm" aria-labelledby="loginYourself" style="padding: 0px !important;">
                    <div class="row">
                        <div class="col-md-8">
                            <p class="py-2 text-muted">Looks like the verification link has expired, Not to worry, we can send the link again</p>
                            <div  *ngIf="this.sentSuccessfull == false">
                            <form [formGroup]="this.fResetPasswordForm" *ngIf="this.fResetPasswordForm">
                            
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="formItm">
                                            <input id="loginEmail" type="email" required placeholder="Email Address" formControlName="email">
                                            <label for="loginEmail" class="text-sm">Email Address</label>
                                            <span
                                                *ngIf="fResetPasswordForm?.controls['email'].hasError('required') && fResetPasswordForm?.controls['email'].touched"
                                                class="error">Please enter your email address.</span>
                                            <span
                                                *ngIf="fResetPasswordForm?.controls['email'].hasError('pattern') && fResetPasswordForm?.controls['email'].touched"
                                                class="error">Please enter a valid email address.</span>
                                        </div>
                                    </div>
                                </div>
                            <button class="btn btn-secondary btn-round-6 mt-3" [disabled]="!this.fResetPasswordForm.valid"
                            (click) ="reVerifyEmail()">Resend Verification Link</button>
                                </form>
                                </div>
                            <div *ngIf="this.sentSuccessfull == true" class="alert alert-success d-flex align-items-center
                                              alert-dismissible fade show col-md-12" role="alert">
                            
                                <img src="../../../../assets/images/svg/success toast.svg">
                                <div class="ps-2">Verification email has been sent, please check your email.
                                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                                        <img class="success-close-svg" src="../../../../assets/images/svg/close.svg">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                

                    <!-- <div class="row my-4">
                        <div class="col-md-8">
                            <p class="py-1 text-muted">You can re-enter your email <a (click)="this.showEmailInput = true"><b class="text-danger">here</b></a> if the one you entered was incorrect.</p>
                        </div>
                    </div> -->

            </div>
        </div>
        <!-- <div class="loginSecItmBottom d-flex align-items-center text-md-end " id="Redirect_to_Intercom">
            <div class="privacy-terms-links text-start text-lg-end text-xs text-md-end ps-md-0 ps-md-0">
                <img src="../assets/images/onBoarding/help-icon.svg" alt="help-icon" />
                <p><a class="text-gray-2" href="javascript:void(0)">Need Support?</a><br>
                    We're here to help.</p>
            </div>

        </div> -->
    </div>
</section>