<app-timeline-view-process *ngIf="bLoading" [bResCheck]="bResponse"></app-timeline-view-process>

<!-- main section -->
<section *ngIf="!bLoading && !bDisplayErrorMsg" class="accountSec main-body bg-gray-7 h-100">
    <div style="padding-top: 15px !important;">

        <div class="h-85vh br12 curve-bg tab-content bg-white page-shadow" id="pills-tabContent">
            <div class="tab-pane fade show active">

                <div class="content_area ps-5">
                    <div class="publishVideoRow">
                        <div class="publishVideoRowLeft">
                            <div class="video_wrapper video_wrapper_full js-videoWrapper ms-auto w-320">
                                <video #videoPreview controls disablepictureinpicture
                                    controlsList="nodownload noplaybackrate" class="videoIframe js-videoIframe"
                                    width="620" height="350px" [src]=" this.videoManger.selectedVideo?.video_url">
                                </video>
                                <!-- <figure class="video-baner"
                            [ngClass]="this.videoPreviewPlay? 'zind' : ''">
                              <img height="100%" [src]="this.videoManger.selectedVideo?.thumbnail" alt="thumbnail" />
                            </figure> -->
                                <button class="videoPoster js-videoPoster h-25" [ngClass]="hideButton?'d-none':''"
                                    (click)="PreviewComponent_PlayVideo()"
                                    style="background-image: url({{this.videoManger.selectedVideo?.thumbnail}})"></button>
                            </div>
                            <div class="d-flex justify-content-end mt-3 me-6">
                                <button (click)="showModal()"
                                    class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btn-md-same-pad d-inline btnShadow">
                                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M5.87957 4.67878C5.69984 4.46937 5.38417 4.44511 5.17449 4.62461C4.96481 4.80411 4.94053 5.11938 5.12025 5.32879L7.66515 8.29405C8.07528 8.77194 8.74076 8.94285 9.33085 8.72185L12.676 7.469C12.9346 7.37216 13.0656 7.0843 12.9687 6.82604C12.8717 6.56779 12.5835 6.43695 12.3249 6.53379L8.9797 7.78664C8.783 7.86031 8.56118 7.80334 8.42446 7.64404L5.87957 4.67878Z"
                                            fill="#BABCBE" />
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M16.5008 8.00021C16.5008 12.4132 12.9188 15.9906 8.50015 15.9906C4.08152 15.9906 0.499512 12.4132 0.499512 8.00021C0.499512 3.58721 4.08152 0.00976562 8.50015 0.00976562C12.9188 0.00976562 16.5008 3.58721 16.5008 8.00021ZM15.5007 8.00021C15.5007 11.8616 12.3664 14.9918 8.50015 14.9918C4.63385 14.9918 1.49959 11.8616 1.49959 8.00021C1.49959 4.13883 4.63385 1.00857 8.50015 1.00857C12.3664 1.00857 15.5007 4.13883 15.5007 8.00021Z"
                                            fill="#BABCBE" />
                                    </svg>
                                    <span class="ms-2">Schedule </span>
                                </button>
                                <a (click)="publishVideo()"
                                    class="ms-3 btn btn-primary btn-round-6 d-flex align-items-center text-xs small-pad btnShadow weight-600">
                                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M11.9455 1.297C13.4774 0.787123 14.4804 1.00765 14.9904 1.51767C15.501 2.0282 15.7214 3.0324 15.2149 4.56205L15.2147 4.56263L12.8248 11.7216L12.8248 11.7218C12.4343 12.8936 11.9931 13.7334 11.5491 14.2703C11.1037 14.8088 10.7017 14.9918 10.3769 14.9918C10.052 14.9918 9.65002 14.8088 9.20467 14.2703C8.76066 13.7334 8.3195 12.8936 7.92901 11.7218L7.92892 11.7216L7.26682 9.73822L10.2839 6.71726C10.4789 6.52197 10.4785 6.20575 10.2829 6.01096C10.0874 5.81617 9.77078 5.81658 9.57574 6.01187L6.55203 9.03951C6.5218 9.06977 6.49627 9.10295 6.47543 9.13812L4.77474 8.57207L4.77449 8.57199C3.60077 8.18202 2.75971 7.74197 2.22196 7.29918C1.68255 6.85501 1.49965 6.45448 1.49965 6.13104C1.49965 5.80745 1.68271 5.4063 2.22236 4.96099C2.76023 4.51716 3.60134 4.07563 4.77493 3.68362L4.77523 3.68352L11.9455 1.297ZM16.1643 4.87632C16.7217 3.19312 16.6001 1.71383 15.6981 0.811814C14.7955 -0.0907919 13.3154 -0.211819 11.6293 0.349368L4.45853 2.73606L4.45803 2.73623C3.22041 3.14959 2.2531 3.63993 1.58533 4.19096C0.919299 4.74054 0.499512 5.39728 0.499512 6.13104C0.499512 6.86496 0.919458 7.52125 1.58573 8.06987C2.25362 8.61983 3.22099 9.1085 4.45853 9.5197L4.45877 9.51978L6.34974 10.1492L6.98005 12.0373L6.98013 12.0375C7.39199 13.2734 7.88199 14.2394 8.43354 14.9063C8.98377 15.5717 9.64176 15.9906 10.3769 15.9906C11.112 15.9906 11.77 15.5717 12.3202 14.9063C12.8718 14.2394 13.3618 13.2734 13.7736 12.0375L13.7737 12.0373L16.1639 4.87744L16.1643 4.87632Z"
                                            fill="white" />
                                    </svg>
                                    <span class="ms-2">Publish Now</span>
                                </a>
                            </div>
                        </div>
                        <div class="publishVideoRowRight ps-0">

                            <h3 class="primaryFont weight-700 text-gray-2 my-0 mt-3 mb-1 ml-n1 ps-3">

                                <!-- <label>
                                    <input type="file" class="d-none" />
                                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">

                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M24.5945 21.6031C24.5944 21.9441 24.3179 22.2205 23.9768 22.2204C23.6357 22.2203 23.3593 21.9438 23.3594 21.6027L23.3618 12.3791L19.4808 16.6481C19.2514 16.9004 18.8794 16.9004 18.65 16.6481C18.4206 16.3957 18.4206 15.9865 18.65 15.7342L23.5723 10.3196C23.8018 10.0673 24.1737 10.0673 24.4032 10.3196L29.3255 15.7342C29.555 15.9865 29.555 16.3957 29.3255 16.6481C29.0961 16.9004 28.7241 16.9004 28.4947 16.6481L24.5969 12.3605L24.5945 21.6031ZM14.7479 18.4066C15.089 18.4066 15.3655 18.683 15.3655 19.0241V20.5679C15.3655 25.0018 18.9599 28.5961 23.3937 28.5961H24.6313C29.0652 28.5961 32.6595 25.0018 32.6595 20.5679V19.0241C32.6595 18.683 32.936 18.4066 33.2771 18.4066C33.6182 18.4066 33.8947 18.683 33.8947 19.0241V20.5679C33.8947 25.6839 29.7473 29.8312 24.6313 29.8312H23.3937C18.2777 29.8312 14.1304 25.6839 14.1304 20.5679V19.0241C14.1304 18.683 14.4069 18.4066 14.7479 18.4066Z"
                                            fill="#FF8369" />
                                        <defs>
                                            <filter id="filter0_d_932:25309" x="0" y="0" width="48" height="48"
                                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                <feColorMatrix in="SourceAlpha" type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                    result="hardAlpha" />
                                                <feOffset dy="4" />
                                                <feGaussianBlur stdDeviation="2" />
                                                <feComposite in2="hardAlpha" operator="out" />
                                                <feColorMatrix type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                                                <feBlend mode="normal" in2="BackgroundImageFix"
                                                    result="effect1_dropShadow_932:25309" />
                                                <feBlend mode="normal" in="SourceGraphic"
                                                    in2="effect1_dropShadow_932:25309" result="shape" />
                                            </filter>
                                        </defs>
                                    </svg>
                                </label> -->

                                Preview & Publish Your Video
                            </h3>
                            <p class="text-gray-3 text-sm mb-0">
                                Publish or schedule your video. Once published, you can <br>
                                select your ideal video quality then download or share <br>
                                your video across social channels.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
            <div class="wtermarklogo">
                <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
            </div>
        </div>
    </div>
</section>
<!-- <div class="fixsidebar">
    <ul>
        <li>
            <a href="javascript:void(0);" (click)="openLocation()">
                <figure>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10 11.75C10.41 11.75 10.75 11.41 10.75 11V6C10.75 5.59 10.41 5.25 10 5.25C9.59 5.25 9.25 5.59 9.25 6V11C9.25 11.41 9.59 11.75 10 11.75ZM10.71 13.2899C10.8 13.3899 10.87 13.4999 10.92 13.6199C10.97 13.7399 11 13.8699 11 13.9999C11 14.1299 10.97 14.2599 10.92 14.3799C10.87 14.5099 10.8 14.6099 10.71 14.7099C10.61 14.7999 10.5 14.8699 10.38 14.9199C10.26 14.9699 10.13 14.9999 10 14.9999C9.87 14.9999 9.74 14.9699 9.62 14.9199C9.5 14.8699 9.39 14.7999 9.29 14.7099C9.2 14.6099 9.13 14.5099 9.08 14.3799C9.03 14.2599 9 14.1299 9 13.9999C9 13.8699 9.03 13.7399 9.08 13.6199C9.13 13.4999 9.2 13.3899 9.29 13.2899C9.39 13.1999 9.5 13.1299 9.62 13.0799C9.86 12.9799 10.14 12.9799 10.38 13.0799C10.5 13.1299 10.61 13.1999 10.71 13.2899Z" fill="#7A7A7A"/>
                    </svg>
                </figure>
                Overview
            </a>
        </li>


    </ul>
</div> -->
<nz-drawer [nzClosable]="false" [nzVisible]="visibleLocation" [nzPlacement]="placementLocation"
    (nzOnClose)="closeLocation()" [nzWidth]="478">
    <ng-container *nzDrawerContent>
        <nav class="pt-5">
            <div class="sidebar-header">
                <h3 class="text-gray-2 weight-400 text-lg ps-4">Preview Video</h3>
                <div class="closeRightSideBar pe-2" (click)="closeLocation()">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.409957 11.8C0.304968 11.8 0.204978 11.76 0.124987 11.685C-0.0299968 11.53 -0.0299968 11.275 0.124987 11.12L5.33943 5.9L0.114988 0.68C0.0399958 0.605 0 0.505 0 0.4C0 0.295 0.0399958 0.195 0.114988 0.12C0.194979 0.04 0.294969 0 0.399958 0C0.504947 0 0.604936 0.04 0.684927 0.115L5.90437 5.335L11.1188 0.115C11.1938 0.04 11.2938 0 11.3988 0C11.5038 0 11.6038 0.04 11.6838 0.115C11.8387 0.27 11.8387 0.525 11.6838 0.68L6.46931 5.9L11.6788 11.11C11.7538 11.185 11.7938 11.285 11.7938 11.39C11.7938 11.495 11.7538 11.595 11.6788 11.675C11.6038 11.755 11.5038 11.79 11.3938 11.79C11.2838 11.79 11.1888 11.75 11.1088 11.675L5.90437 6.465L0.689927 11.685C0.614935 11.76 0.514945 11.8 0.409957 11.8Z"
                                fill="#7A7A7A"></path>
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect width="11.8" height="11.8" fill="white"></rect>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
            <div class="side-content pt-0">
                <div class="video_wrapper video_wrapper_full js-videoWrapper">

                    <iframe class="videoIframe js-videoIframe" width="620" height="350"
                        src="https://www.youtube.com/embed/XULUBg_ZcAU" title="" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                    <button class="videoPoster js-videoPoster"></button>
                </div>

                <p class="text-md text-gray-3 mt-4 pt-2">Prontopia Tips & Track</p>
            </div>
        </nav>
    </ng-container>
</nz-drawer>


<nz-modal [(nzVisible)]="isVisible" nzCentered [nzWidth]="1800" [nzBodyStyle]="{height:'94vh'}" [nzFooter]=null
    [nzClosable]=false (nzOnCancel)="handleCancel()">
    <app-scheduled-popup (click)="PreviewComponent_GetClickLocation($event)" [lInputClosePopup]="oClosePopup"
        (onClose)="handleCancel()"></app-scheduled-popup>
</nz-modal>


<div class="overlay sidebarOverlay"></div>
<div class="overlay sidebarOverlay2"></div>
<!-- error modal -->
<div class="modal fade" id="error-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog width-483 modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-body">
                <button type="button" class="btn-close cust-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <h5 class="mb-4">{{sErrorMessageTitle}}</h5>
                <div class="alert alert-danger p-3 mb-4 g-0 row rounded-1">
                    <div class="col-1 p-0">
                        <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                                <path
                                    d="M13.9993 3.47998C6.92399 3.47998 1.16602 9.20176 1.16602 16.2326C1.16602 23.2635 6.92399 29 13.9993 29C21.0747 29 26.8327 23.2782 26.8327 16.2473C26.8327 9.21646 21.0747 3.47998 13.9993 3.47998ZM15.1095 15.7619C15.1095 16.365 14.6062 16.8651 13.9993 16.8651C13.3925 16.8651 12.8892 16.365 12.8892 15.7619V8.40747C12.8892 7.80441 13.3925 7.3043 13.9993 7.3043C14.6062 7.3043 15.1095 7.80441 15.1095 8.40747V15.7619Z"
                                    fill="#FF8369"></path>
                                <path
                                    d="M17.7366 2.32H10.2647C9.74765 2.32 9.33398 1.808 9.33398 1.168C9.33398 0.528 9.74765 0 10.2647 0H17.7366C18.2537 0 18.6673 0.512 18.6673 1.152C18.6673 1.792 18.2537 2.32 17.7366 2.32Z"
                                    fill="#FF8369"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="28" height="29" fill="white"></rect>
                                </clipPath>
                            </defs>
                        </svg>


                    </div>
                    <div class="col-11 p-0">

                        <p class="text-sm text-gray-3 mb-0 ms-2">{{sErrorMessage}}</p>
                    </div>
                </div>

                <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" data-bs-dismiss="modal"
                    routerLink="/dashboard/myvideos">Go Back</a>

            </div>
        </div>
    </div>
</div>
<!-- error modal end -->