import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BrandService } from 'src/app/components/myAccount/manage-brand/service/BrandService';
import { ProntoSettingService } from 'src/app/services/utils/pronto-setting.service';

@Component({
  selector: 'pronto-system-fonts-dropdown',
  templateUrl: './system-fonts-dropdown.component.html',
  styleUrls: ['./system-fonts-dropdown.component.scss']
})
export class SystemFontsDropdownComponent implements OnInit {

  @Input() selectedVideoFontFile = "";
  @Input() isPopup = false;
  @Input() public leftAlign: boolean = false;
  @Output() setFontFile = new EventEmitter<any>();
  isFontModalVisible = false;
  selectedFont = "";
  selectedFontStyle = "";
  brandFontArray: any = [];
  otherFontArray: any = [];
  submenuFontWeight = ".submenuFontWeight";
  submenuFontWeightList = [];
  loadMoreFont = 10;
  externalFontsStyleSheet = "";
  internalFontsStyleSheet = "";
  selectedFontSignedURL = undefined;
  constructor(
    private brandService: BrandService,
    private prontoSettingService: ProntoSettingService) { }

  @Input() set brandFonts(values: any[]) {
    this.brandFontArray = values;
  }
  ngOnInit(): void {
    this.selectedFont = this.selectedVideoFontFile;
    this.getDefaultFonts();
    this.prontoSettingService.dropDownHideSubscribe().subscribe((value) => {
      this.closeFontWeightSubmenu();
    });
  }
  otherFontParent = [];

  public otherFontParentMap: Map<string, any[]> = new Map<string, any[]>();
  collectOtherFontsParent() {
    let loop = 0;
    this.otherFontArray.forEach((font) => {
      let nameFont = this.fontStyleWithoutSub(font.name);
      font.fontStyle = this.fontStyle(font.name);
      if (this.otherFontParentMap.get(nameFont)) {
        let mapValue: any[] = this.otherFontParentMap.get(nameFont);
        mapValue.push(font);
        this.otherFontParentMap.set(nameFont, mapValue);
      } else {

        this.otherFontParentMap.set(nameFont, [font]);
      }
    });
  }

  assignFontToVideo(font) {

  }
  getDefaultFonts() {
    if (this.prontoSettingService.defaultFontList && this.prontoSettingService.defaultFontList.length > 0) {
      this.otherFontArray = this.prontoSettingService.defaultFontList;
      this.startFontListGeneration();
    } else {
      this.brandService.getDefaultFontLists().then((list: any) => {
        this.prontoSettingService.defaultFontList = list.result;
        this.otherFontArray = list.result;
        this.startFontListGeneration();
      });
    }

  }

  startFontListGeneration() {
    this.collectOtherFontsParent();
    if (this.otherFontArray && this.otherFontArray.length > 0) {
      this.internalFontsStyleSheet = "";
      let loopIndex = 0;
      let continueLoop = true;
      let fontGeneration = setInterval(() => {
        // console.log(loopIndex, " > = ", this.otherFontArray.length);
        if (loopIndex >= this.otherFontArray.length) {
          continueLoop = false;
          // console.log("Clear loop");

          clearInterval(fontGeneration);
        } else {
          this.loadFontData(loopIndex, 40);
        }
        loopIndex += 40;
      }, 300);
    }
  }

  loadFontData(index, limit) {
    var filterArray = [];
    for (let i = index; i < index + limit; i++) {
      if (this.otherFontArray.length >= i) {
        filterArray.push(this.otherFontArray[i]);
      }
    }
    let internalFontsStyleSheet = "";
    filterArray.map((item: any) => {
      // console.log(item)
      if (item) {
        let fontPath = item.name;
        let fontSignedURLPath = item.signed_url;
        internalFontsStyleSheet += this.getFontFaceStyleCSS(
          fontPath,
          fontSignedURLPath
        );
      }
    });
    var head = document.getElementsByTagName("head")[0];
    let styleElement = document.createElement("style");
    styleElement.textContent = internalFontsStyleSheet;
    head.appendChild(styleElement);
  }

  getFontFaceStyleCSS(fontPath, fontSignedURLPath) {
    this.selectedFontSignedURL = fontSignedURLPath;
    let fontFamily = "";
    let path = fontPath.split(".");
    let fontExtension = path[path.length - 1];
    let pathName = fontPath.split("/");
    let fontFamilyName = pathName[pathName.length - 1]
      ? pathName[pathName.length - 1].split(".")[0]
      : pathName[pathName.length - 1];

    let fontFormate = fontExtension;
    if (
      fontFormate == "otf" ||
      fontFormate == "OTF" ||
      fontFormate == "ttf" ||
      fontFormate == "TTF"
    ) {
      fontFormate = "opentype";
    }
    return `
    @font-face {
      font-family: '${fontFamilyName}';
      font-style: normal;
      font-weight: 500;
      src: url('${fontSignedURLPath}') format('${fontFormate}');}`;
  }

  hideFontPopUP(): void {
    // console.log("Button cancel clicked!");
    this.isFontModalVisible = false;
  }
  selectFont(incommingFont) {
    this.selectedFont = incommingFont;
    this.selectedFontStyle = this.fontStyle(this.selectedFont);
    this.emitFontFile();
  }
  selectOtherFont(incommingFont) {
    this.selectedFont = incommingFont.value[0].name;
    this.selectedFontStyle = this.fontStyle(this.selectedFont);
    this.emitFontFile();
  }
  fontStyle(incommingFont) {
    if (!incommingFont.value) {
      let fontNameSpliter = incommingFont.split("/");
      let nameFont = fontNameSpliter[fontNameSpliter.length - 1];
      return nameFont.split(".")[0];
    }
  }

  otherFontStyle(fontObj) {
    // console.log(" other font called");
    if (fontObj && fontObj.value && fontObj.value.length > 0) {
      let fontNameSpliter = fontObj.value[0]?.name.split("/");
      let nameFont = fontNameSpliter[fontNameSpliter.length - 1];
      return nameFont.split(".")[0];
    }

    return "";
  }

  fontWeightName(name): string {
    let nameWeight = name.toLowerCase();
    if (nameWeight.indexOf("semibolditalic") > -1) {
      return "Semi Bold Italic";
    }
    if (nameWeight.indexOf("extrabolditalic") > -1) {
      return "Extra Bold Italic";
    }
    if (nameWeight.indexOf("lightbolditalic") > -1) {
      return "Light Bold Italic";
    }
    if (nameWeight.indexOf("mediumbolditalic") > -1) {
      return "Medium Bold Italic";
    }
    if (nameWeight.indexOf("regularbolditalic") > -1) {
      return "Bold Italic";
    }
    if (nameWeight.indexOf("ExtraLightItalic") > -1) {
      return "Extra Light Italic";
    }
    if (nameWeight.indexOf("variable") > -1) {
      return "Variable Font";
    }
    if (nameWeight.indexOf("mediumitalic") > -1) {
      return "Medium Italic";
    }
    if (nameWeight.indexOf("lightitalic") > -1) {
      return "Light Italic";
    }
    if (nameWeight.indexOf("semibold") > -1) {
      return "Semi Bold";
    }
    if (nameWeight.indexOf("bolditalic") > -1) {
      return "Bold Italic";
    }
    if (nameWeight.indexOf("thinitalic") > -1) {
      return "Thin Italic";
    }
    if (nameWeight.indexOf("regulartalic") > -1) {
      return "Regular Italic";
    }
    if (nameWeight.indexOf("extralight") > -1) {
      return "Extra Light";
    }
    if (nameWeight.indexOf("extrabold") > -1) {
      return "Extra Bold ";
    }
    if (nameWeight.indexOf("bold") > -1) {
      return "Bold";
    }
    if (nameWeight.indexOf("thin") > -1) {
      return "Thin";
    }
    if (nameWeight.indexOf("light") > -1) {
      return "Light";
    }
    if (nameWeight.indexOf("italic") > -1) {
      return "italic";
    }
    if (nameWeight.indexOf("regular") > -1) {
      return "Regular";
    }
    if (nameWeight.indexOf("medium") > -1) {
      return "Medium";
    }
    if (nameWeight.indexOf("normal") > -1) {
      return "Normal";
    }
    return name;
  }

  fontStyleWithoutSub(incommingFont) {
    let fontNameSpliter = incommingFont.split("/");
    let nameFont = fontNameSpliter[fontNameSpliter.length - 1];
    let fontText = nameFont.split(".")[0];
    let realName = nameFont.split(".")[0];

    var mapObj = {
      semibolditalic: "semibolditalic",
      extrabolditalic: "extrabolditalic",
      lightbolditalic: "lightbolditalic",
      mediumbolditalic: "mediumbolditalic",
      regularbolditalic: "regularbolditalic",
      mediumitalic: "mediumitalic",
      bolditalic: "bolditalic",
      thinitalic: "thinitalic",
      regulartalic: "regulartalic",
      variablefont_wght: "variablefont wght",
      extralight: "extralight",
      extrabold: "extrabold",
      semibold: "semibold",
      bold: "bold",
      thin: "thin",
      light: "light",
      italic: "italic",
      regular: "regular",
      medium: "medium",
      hyphon: "-",
      underscore: "_",
      undef: "undefined",
    };

    var re = new RegExp(Object.keys(mapObj).join("|"), "gi");
    fontText = fontText.replace(re, function (matched) {
      return mapObj[matched];
    });

    if (realName == fontText) {
      fontText = "Regular";
    }
    fontText = fontText.replaceAll("undefined", "");
    fontText = fontText.replaceAll("-", "");
    fontText = fontText.replaceAll("_", "");
    return fontText.replace("undefined", "");
  }

  closeFontWeightSubmenu() {
    let fontWeightDropDown = document.querySelectorAll(this.submenuFontWeight);
    if (fontWeightDropDown && fontWeightDropDown.length > 0) {
      let dropDown = fontWeightDropDown[0];
      dropDown.classList.remove("show");
    }
  }

  ngOnDestroy() {
    document.querySelector(".brand-font-list").removeEventListener(
      "scroll",
      undefined,
      false
    );
  }

  loadFontWeightSubMenu(font) {
    let weightList = font?.value;
    this.submenuFontWeightList = [];

    let fontWeightDropDown = document.querySelectorAll(this.submenuFontWeight);
    if (fontWeightDropDown && fontWeightDropDown.length > 0) {
      let dropDown = fontWeightDropDown[0];
      if (weightList && weightList.length > 1) {
        weightList.map((weight) => {
          let weightStyle = this.fontStyle(weight.name);
          let weightName = this.fontWeightName(weight.name);
          weight.styleName = weightStyle;
          weight.titleName = weightName;
          if (!weight.sort) {
            weight.sort = this.sortFontWeight(weight.titleName);
          }
          this.submenuFontWeightList.push(weight);
        });
        this.submenuFontWeightList.sort((a, b) => (a.sort - b.sort));
        dropDown.classList.add("show");
        let fontListDD = document.querySelectorAll(".brand-font-list");
        if (fontListDD && fontListDD[0]) {

          var divOffset = this.offsetElement(fontListDD[0]);
          // console.log(divOffset.left, divOffset.top);
          if (this.isPopup) {
            dropDown["style"].left = "280px";
            dropDown["style"].top = divOffset.top - 70 + "px";
          } else {
            if (this.leftAlign) {
              dropDown["style"].left = divOffset.left - 195 + "px";
              dropDown["style"].top = divOffset.top - 120 + "px";
            } else {
              dropDown["style"].left = divOffset.left + 230 + "px";
              dropDown["style"].top = divOffset.top - 120 + "px";
            }

          }

          // console.log(fontListDD[0] .top)
        }
      } else {
        dropDown.classList.remove("show");
      }
      // dropDown.innerHTML = strElement;

    }
  }
  offsetElement(el) {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }
  sortFontWeight(name): number {
    let sortNum = 0;

    switch (name.toLowerCase()) {
      case "thin light": sortNum = 1; break;
      case "thin": sortNum = 2; break;
      case "regular italic": sortNum = 3; break;
      case "thin italic": sortNum = 1; break;
      case "light bold italic": sortNum = 4; break;
      case "extra light": sortNum = 5; break;
      case "extra light italic": sortNum = 6; break;
      case "light": sortNum = 7; break;
      case "light italic": sortNum = 8; break;
      case "italic": sortNum = 9; break;
      case "regular": sortNum = 10; break;
      case "regular": sortNum = 11; break;
      case "normal": sortNum = 12; break;
      case "medium": sortNum = 12; break;
      case "medium italic": sortNum = 13; break;
      case "semi bold": sortNum = 14; break;
      case "bold": sortNum = 15; break;
      case "bold italic": sortNum = 16; break;
      case "light bold italic": sortNum = 17; break;
      case "medium bold italic": sortNum = 18; break;
      case "semi bold italic": sortNum = 19; break;
      case "extra bold": sortNum = 20; break;
      case "extra bold": sortNum = 21; break;
      case "extra bold": sortNum = 22; break;
      case "bolder": sortNum = 23; break;
      default: sortNum = 24; break;
    }
    return sortNum;
  }
  loadMoreOtherFonts(event) {
    this.loadMoreFont += 10;
    event.stopPropagation();
    event.preventDefault();

  }


  emitFontFile() {
    this.setFontFile.emit({ file: this.selectedFont });
  }

}
