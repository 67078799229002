import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { profile } from './../../../models/onboarding';
import { ErrorService } from './../../../services/error.service';
import { cSessionService } from './../../../services/session.service';
import { FtCreateVideoManagerService } from './../../../Managers/ft-create-video-manager.service';
import { UtilsService } from '../../../services/utils.service';
import { OnboardingManagerService } from './../../../Managers/onboarding-manager.service';
import { ConfigManagerService } from './../../../Managers/config-manager.service';

@Component({
    selector: 'app-ft-login',
    templateUrl: './ft-login.component.html',
    styleUrls: ['./ft-login.component.scss'],
})
export class FtLoginComponent implements OnInit {
    fGuestLoginForm: FormGroup;
    sSessionId: any;
    isFtLogin: boolean = true;

    constructor(
        private oFromBuilder: FormBuilder,
        public videoManger: FtCreateVideoManagerService,
        private oSessionService: cSessionService,
        private oRouter: Router,
        public errorService: ErrorService,
        private Utils_Service: UtilsService,
        public onboardinManger: OnboardingManagerService,
        private config: ConfigManagerService,
    ) {}

    ngOnInit(): void {
        this.FtueComponent_FtueLoginFormInitialize();
    }

    FtueComponent_FtueLoginFormInitialize() {
        const sEmailRejex = /(^[\w-\.]+@([\w-]+\.)+[\w-]{2,14}$)/;
        this.fGuestLoginForm = this.oFromBuilder.group({
            email: [null, [Validators.required, Validators.pattern(sEmailRejex)]],
        });
    }

    FtueComponent_SendFtueloginRequest() {
        if (this.fGuestLoginForm.valid) {
            let userProfile = new profile();
            userProfile.email = this.fGuestLoginForm.get('email').value;

            this.videoManger
                .guestUser(userProfile.email)
                .then((res: any) => {
                    if (res['errorMessage']) {
                        if (res.errorMessage && res.errorMessage.indexOf('already exists') > -1) {
                            this.errorService.handleError(
                                'This email is already associated with an account. Please sign in with your password or use a different email to continue.',
                                'Email Already in Use',
                                true,
                            );
                        } else {
                            this.errorService.handleError(res['errorMessage'], 'Error', true);
                        }
                    } else {
                        this.sSessionId = res['guest_session_id'];

                        this.oSessionService.cSessionService_SetGuestEmail(userProfile.email);
                        this.oSessionService.cSessionService_SetGuestSession(this.sSessionId);

                        if (this.sSessionId !== '' && userProfile.email !== '') {
                            console.log('Started2');

                            if (!this.onboardinManger.profile) {
                                console.log('Started3');

                                this.getProfile(userProfile.email, this.sSessionId);
                            } else if (this.config.Templates.length === 0) {
                                console.log('Started4');
                                this.config.getTemplates2(
                                    userProfile.email,
                                    this.sSessionId,
                                    this.onboardinManger.profile.allowed_templates,
                                );
                            }
                        }
                        this.oRouter.navigateByUrl('/ft/overview');
                    }
                })
                .catch((err) => {
                    console.log("  Error here ", err);
                    this.errorService.handleError(err.errorMessage, err.errorType, true);
                });
        } else {
            this.fGuestLoginForm.markAllAsTouched();
        }
    }

    async getProfile(email, session) {
        console.log('onbtop');
        this.config.getVoices(email, session).then();
        await this.onboardinManger.getUserProfile(email, session).then(async (res: profile) => {
            // console.log("onb", res);

            await this.config.getTemplates2(email, session, res.allowed_templates);
        });

        await this.onboardinManger.getUserVideosCount(email, session).then((count) => {});

        console.log('Started4');
    }
}
