import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'changeTitleOfVideoText'})
export class ChangeTitleOfVideoText implements PipeTransform {
  transform(text : string, value : string, valNew : string): string {

      if(text == value ){
        text = valNew;
      }
    return text;
  }
}