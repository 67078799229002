import { Session } from 'protractor';
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NzDrawerPlacement } from "ng-zorro-antd/drawer";
import { CreateVideoManagerService } from "src/app/Managers/create-video-manager.service";
import { MediaManagerService } from "src/app/Managers/media-manager.service";
import { VideoManagerService } from "src/app/Managers/video-manager.service";
import { brand, SearchData } from "src/app/models/admin";
import { video, videoLight } from "src/app/models/video";
import { cSessionService } from "src/app/services/session.service";
import { Userservice } from "../../users/service/user-service";
import { BrandService } from "../service/BrandService";

@Component({
  selector: "app-manage-brand-published-videos",
  templateUrl: "./manage-brand-published-videos.component.html",
  styleUrls: ["./manage-brand-published-videos.component.scss"],
})
export class ManageBrandPublishedVideosComponent implements OnInit {
  brandId = "";
  brandDetail: brand;
  offset = 0;
  limit = 15;
  searchBy = "created";
  sortType = "created";
  searchTerm = "";
  asc = -1;
  public publishedVideos: video[];
  sUserEmail: any;
  sSessionId: any;
  searchTermText: "";
  placementUser: NzDrawerPlacement = "right";
  thumbListView: boolean = false;
  isLocations : false;
  sSelectedVideoId: string;
  isOwnerClip = false;
  totalGridColumn = 0;
  widthThumbs = 100;
  heightThumbs = 100;
  numGridCol = 4;

  constructor(
    public oSessionService: cSessionService,
    private brandService: BrandService,
    public router: Router,
    private route: ActivatedRoute,
    public userService: Userservice,
    public mediaService: MediaManagerService,
    public session: cSessionService,
    public videoManager: VideoManagerService,
    public createVideoManger: CreateVideoManagerService
  ) {
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
      this.resizeThumbs();
  }

  editVideo(video: video) {
    this.videoManager.selectedVideo = video;
    this.createVideoManger.setEditVideo(
      this.sUserEmail,
      this.sSessionId,
      this.videoManager.selectedVideo
    );
    // let result = video.hasOwnProperty('sap_video_id');

    console.log("vid edit", video);
    if (video.sap_video_id && video.sap_video_id != "") {
      this.router.navigate(["mv/overview/"]);
    } else {
      this.router.navigate(["wizard/overview"]);
    }
  }

  navigateToShare(video: video) {
    this.videoManager.selectedVideo = video;
    this.router.navigate(["/dashboard/socialmedia"]);
  }

  navigateToOverview(video:video)
  {
    this.videoManager.selectedVideo = video;
    this.createVideoManger.setEditVideo(this.sUserEmail,this.sSessionId,this.videoManager.selectedVideo);

    this.router.navigate(['/dashboard/overview']);
  }

  selectedDuplicateVideo: any;
  selectDuplicateVideo(video: any) {
    this.selectedDuplicateVideo = video;
    console.log(this.selectedDuplicateVideo);
  }

  CreatedVideosComponent_PlayVideo(nIncommingVideoId) {
    this.sSelectedVideoId = nIncommingVideoId;
    this.videoManager.videos.forEach(element => {
      var video = <HTMLVideoElement>document.getElementById(element.fpath);
      if (element.fpath == nIncommingVideoId) {
        video.play();
      }
      else {
        video.pause();
      }
    });
  }
  ngOnInit(): void {
    this.resizeThumbs();
    this.videoManager.videos = [];
    this.brandId = this.route.snapshot.params["id"];
    this.getBrandProfile();
    this.InitializeJqueryCode();
    // this.session.dashboardVideoGridView = true;
    this.session.dashboardVideoListView = false;
  }

  loadBrandAccount() {
    this.router.navigate([
      `/account-setting/manage-brand/account/${this.brandId}`,
    ]);
  }
  InitializeJqueryCode() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.text = `
    jQuery(function(){
      jQuery('.showSingle').click(function(){
          jQuery('.targetDiv').hide('.cnt');
          jQuery('#div'+$(this).attr('target')).slideToggle();

      });
      });

      $(document).ready(function(){
          var submitIcon = $('.searchbox-icon');
          var inputBox = $('.searchbox-input');
          var searchBox = $('.searchbox');
          var isOpen = false;
          submitIcon.click(function(){
              if(isOpen == false){
                  searchBox.addClass('searchbox-open');
                  inputBox.focus();
                  isOpen = true;
              } else {
                  searchBox.removeClass('searchbox-open');
                  inputBox.focusout();
                  isOpen = false;
              }
          });
           submitIcon.mouseup(function(){
                  return false;
              });
          searchBox.mouseup(function(){
                  return false;
              });
          $(document).mouseup(function(){
                  if(isOpen == true){
                      $('.searchbox-icon').css('display','block');
                      submitIcon.click();
                  }
              });
      });
      $(document ).ready(function() {
        $("[data-sidebar]").click(function() {
          var sideBarId = $(this).attr("data-sidebar");
          $(".rightSideBar").removeClass("active");
          $(".sidebarOverlay").addClass("active");
          $(sideBarId).addClass("active");
        });


        $(".sidebarOverlay, .closeRightSideBar , .closeSidepanel").click(function() {
          $(".rightSideBar").removeClass("active");
          $(".sidebarOverlay").removeClass("active");
          $("body").removeClass("sidbar_contentslide");
        });

        $("[data-sidebar2]").click(function() {
          var sideBarId = $(this).attr("data-sidebar2");
          $(".rightSideBarTutorialsVideo").removeClass("active");
          $(".sidebarOverlay2").addClass("active");
          $(sideBarId).addClass("active");
        });


        $(".sidebarOverlay2, .closeRightSideBar2").click(function() {
          $(".rightSideBarTutorialsVideo").removeClass("active");
          $(".sidebarOverlay2").removeClass("active");
          $("body").removeClass("sidbar_contentslide");
        });

        // data-custom-select
        $("[data-custom-select]").click(function() {
          $("body").addClass("sidbar_contentslide");
        });
        // end data-custom-select


        // dropdownBtn
        $(".dropdownBtn .dropdown-item, .dropdownSelect .dropdown-item").on("click", function () {
          var dropVal = $(this).text();
          $(this).parent("li").siblings().children().removeClass("active");
          $(this).addClass("active");
          //$(this).closest(".dropdownBtn, .dropdownSelect").find(".dropdownBtnText").text("sortb by "+ dropVal);
        });
        // end dropdownBtn






        // toggle-class
        $("[toggle-class]").on("click", function () {
        var toggleClass = $(this).attr("toggle-class");
        $(toggleClass).toggleClass("active");
        });
        // end toggle-class

        $(".animationBg").mousemove(function(e){
        var amountMovedX = (e.pageX / 6);
        var amountMovedY = (e.pageY / 6);
        $(this).css('background-position', amountMovedX + 'px ' + amountMovedY + 'px');
        });
        // $(".animationBg").mouseout(function(e){
        //   $(this).css('background-position', 'center');
        // });


      });
        $(function($) {
          $.autofilter();
        });


      $("[data-sidebar]").click(function() {
        var sideBarId = $(this).attr("data-sidebar");
        $(".rightSideBar").removeClass("active");
        $(".sidebarOverlay").addClass("active");
        $(sideBarId).addClass("active");
      });


      $(".sidebarOverlay, .closeRightSideBar , .closeSideBar").click(function() {
        $(".rightSideBar").removeClass("active");
        $(".sidebarOverlay").removeClass("active");
        $("body").removeClass("sidbar_contentslide");
      });

      $("[data-sidebar2]").click(function() {
        var sideBarId = $(this).attr("data-sidebar2");
        $(".rightSideBarTutorialsVideo").removeClass("active");
        $(".sidebarOverlay2").addClass("active");
        $(sideBarId).addClass("active");
      });


      $(".sidebarOverlay2, .closeRightSideBar2 , .closeSideBar").click(function() {
        $(".rightSideBarTutorialsVideo").removeClass("active");
        $(".sidebarOverlay2").removeClass("active");
        $("body").removeClass("sidbar_contentslide");
      });

      // data-custom-select
      $("[data-custom-select]").click(function() {
        $("body").addClass("sidbar_contentslide");
      });
      `;
    document.body.appendChild(chatScript);
  }

  getBrandProfile() {
    let __this = this;
    this.brandService.getBrandProfile(this.brandId).then((brand: brand) => {
      __this.brandDetail = brand;
      __this.brandService.selectedBrandProfile = brand;
      __this.loadPublishedVideos();
    });
  }

  loadPublishedVideos() {
    let __this = this;
    this.brandService
      .getListPublishedVideos({
        offset: this.offset,
        limit: this.limit,
        search_by: "video.title", //this.searchBy,
        sort_by: this.sortType,
        asc: this.asc,
        search_term: this.searchTerm,
        brand_id: this.brandId,
      })
      .then(async function (res: SearchData) {
        if (res.result) {
          __this.publishedVideos = [];
          __this.videoManager.videos = [];
          res.result.forEach((element: any) => {
            element.video.video_url = element.public_url;
            element.video.last_modified = element.last_modified;
            element.video.created = element.created?.$date;
            element.video.owner = element?.owner;
            element.video._id = element?._id;
            element.video.thumbnail = undefined;
            // element.video.key_terms = ["technoogies", "test", "imp"];
            __this.publishedVideos.push(element?.video);
            __this.videoManager.videos.push(element?.video);
          });
          __this.mediaService.getVideoThumbnailUrls(
            __this.sUserEmail,
            __this.sSessionId,
            __this.videoManager.videos
          );
        }
      });
  }

  showVideoDetailPopup = false;
  selectedMediaFile: any = undefined;
  showSortByDD = false;

  selectedMediaIndex = 0;

  toggleideoDetailModal(action: boolean, media: any = undefined, index = 0): void {
    this.isOwnerClip = false;
    if(action && media.owner == this.sUserEmail) {
      this.isOwnerClip = true;
    }
    this.selectedMediaIndex = index;
    if (media) {
      console.log(media);
      this.selectedMediaFile = media;
    }
    this.showVideoDetailPopup = action;
  }

  searchPublishedVideos() {
    this.offset = 0;
    this.searchTerm = this.searchTermText;
    this.loadPublishedVideos();
  }

  clearSearchTerm() {
    this.offset = 0;
    this.searchTerm = "";
    this.searchTermText = "";
    this.loadPublishedVideos();
  }

  sortTypeSearch(val): void {
    this.asc = val;
    this.loadPublishedVideos();
  }

  openSearchSortTypeDD() {
    this.showSortByDD = this.showSortByDD ? false : true;
    console.log(" seatch : ", this.showSortByDD);
  }

  hideSearchSortTypeDD($event) {
    if (this.showSortByDD) {
      const __this = this;
      setTimeout(() => {
        __this.showSortByDD = false;
      }, 200);
    }
  }

  sortBySearchResult(value) {
    this.offset = 0;
    this.searchBy = value;
    this.showSortByDD = false;
    this.hideSearchSortTypeDD(undefined);
    this.asc = -1;
    this.loadPublishedVideos();
  }

  deleteVideo(){
   this.videoManager.deleteVideo(this.sUserEmail, this.sSessionId, this.selectedMediaFile.fpath).then(_ => {
      this.videoManager.videos.splice(this.selectedMediaIndex, 1);
      this.selectedMediaFile = undefined;
      this.toggleideoDetailModal(false);
   });
  }

  removeTag(name){
   const termRemain =  this.selectedMediaFile.key_terms.filter( (term) => {
      return ( term != name);
    });

    const indexFile = this.videoManager.videos.findIndex( (item) => (item.fpath = this.selectedMediaFile.fpath) );
    this.selectedMediaFile.key_terms  = termRemain;
    this.selectedMediaFile.email  = this.sUserEmail;
    this.selectedMediaFile.session_id  = this.sSessionId;

    // TODO remove code after test
    termRemain.push("test1");
    termRemain.push("test2");
    termRemain.push("test3");
    let videoLobj : videoLight = {
      key_terms : termRemain,
      email  : this.sUserEmail,
      session_id  : this.sSessionId,
      fpath  : this.selectedMediaFile._id,
      video_id  : this.selectedMediaFile._id,
      template_name : this.selectedMediaFile.template_name,
    }

    if(this.selectedMediaFile.sap_video_duration){
      videoLobj.sap_video_duration = this.selectedMediaFile.sap_video_duration;
    }
    this.videoManager.videos[indexFile] = this.selectedMediaFile;
    this.videoManager.createVideo(videoLobj).then((video) => {
      console.log(" video saved ..");
    });
  }

  resizeThumbs() {
    if(!this.session.dashboardVideoListView){
      let widthSideBar = document.querySelector('#content');
      let windowWidth = window.innerWidth;

      if (widthSideBar) {
          windowWidth = widthSideBar.clientWidth;
      } else {
          let widthPageContainer = document.querySelectorAll('.accountSecContent');
          if (widthPageContainer && widthPageContainer.length > 0) {
              windowWidth = widthPageContainer[0].clientWidth + 20;
          }
      }
      this.widthThumbs = (windowWidth / this.numGridCol - this.numGridCol * 6);
      this.heightThumbs = (this.widthThumbs * 56) / 100;
    }else{
      this.heightThumbs = 67;
    }
  }
}
