import { SuccessMessageModalService } from "src/app/services/success-message-modal.service";
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MediaManagerService } from "src/app/Managers/media-manager.service";
import { visual } from "src/app/models/admin";
import { mediaFile } from "src/app/models/media";
import { ErrorService } from "src/app/services/error.service";
import { cSessionService } from "src/app/services/session.service";

@Component({
  selector: "app-success-message-dialog",
  templateUrl: "./success-message.component.html",
  styleUrls: ["./success-message.component.scss"],
})
export class SuccessMessageComponent implements OnInit {

  showHead = false;
  title = "";
  message = "";
  showLoader = false;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private oSessionService: cSessionService,
    private dialogRef: MatDialogRef<SuccessMessageComponent>
  ) {}
  ngOnInit(): void {   
    console.log('success message openned ....') ;
    if(this.data.showHeader){
      this.showHead = this.data.showHeader;
    }
    if(this.data.title){
      this.title = this.data.title;
    }
    if(this.data.message){
      this.message = this.data.message;
    }
  }
}
