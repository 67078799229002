import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyAccountComponent } from './my-account/my-account.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { UsersComponent } from './users/users.component';
import { LoginSecurityComponent } from './login-security/login-security.component';
import { LocationsComponent } from './locations/locations.component';
import { UserAccountsComponent } from './users/user-account/user-account.component';
import { UserAccountPublishedVideosComponent } from './users/user-published-videos/user-published-videos.component';
import { ManageBrandComponent } from './manage-brand/list/manage-brand.component';
import { ManageBrandAccountComponent } from './manage-brand/account/manage-brand-account.component';
import { ManageBrandPublishedVideosComponent } from './manage-brand/manage-brand-published-videos/manage-brand-published-videos.component';
import { ManageBrandScheduleVideosComponent } from './manage-brand/manage-brand-schedule-videos/manage-brand-schedule-videos.component';
import { ManageBrandMediaLibrariesComponent } from './manage-brand/manage-brand-media-libraries/manage-brand-media-libraries.component';
import { BrandMediaLibsComponent } from './manage-brand/list-media-libs/brand-media-libs.component';
import { ManageBrandsBusinessDetailsComponent } from './manage-brand/business-detail/manage-brands-business-details.component';
import { UserAccountScheduleVideoComponent } from './users/user-account-schedule-video/user-account-schedule-video.component';
import { UserAccountMediaLibrariesComponent } from './users/user-account-media-libraries/user-account-media-libraries.component';
import { SociallChannelsComponent } from './sociall-channels/sociall-channels.component';
import { StorageDetailsComponent } from './storage-details/storage-details.component';
import { MediaaLibrariesComponent } from './mediaa-libraries/mediaa-libraries.component';
import { PublisheddVideoComponent } from './publishedd-video/publishedd-video.component';
import { MediaLibraryyComponent } from './media-libraryy/media-libraryy.component';
import { MediaPlatformsComponent } from './media-platforms/media-platforms.component';
import { BillingComponent } from './billing/billing.component';
import { AuthGuard } from 'src/app/services/auth.guard';
import { SubscriptionGuard } from 'src/app/services/gaurds/subscription.guard';
import { AccountSettingComponent } from './account-setting.component';
import { BrandsIdentityComponent } from './brand-identity/brand-identity.component';
import { TimestampToDate } from 'src/app/services/pipes/timestamp-to-date-convertor.pipe';
import { MyAccountVoiceOverComponent } from './my-account-voice-over/my-account-voice-over.component';

const routesAccount: Routes = [
    {
          path: 'account-setting', canActivate: [AuthGuard, SubscriptionGuard],
          component: AccountSettingComponent,
          children: [
            { path: '', redirectTo: 'myAccount', pathMatch: 'full' },
            {
                path: 'myAccount',
                component: MyAccountComponent,
            },
            {
              path: 'profile',
              component: BrandsIdentityComponent
            },
            {
                path: '',
                component: MyAccountComponent,
            },
            {
                path: 'subscription',
                component: SubscriptionComponent,
            },
            {
                path: 'users',
                component: UsersComponent,
            },
            {
                path: 'loginSecurity',
                component: LoginSecurityComponent,
            },
            {
                path: 'locations',
                component: LocationsComponent,
            },
            {
                path: 'users',
                component: UsersComponent,
            },
            {
                path: 'user-account',
                component: UserAccountsComponent,
            },
            {
                path: 'user-published-videos',
                component: UserAccountPublishedVideosComponent,
            },
            {
                path: 'manage-brand',
                component: ManageBrandComponent,
            },
            {
                path: 'manage-brand/account',
                component: ManageBrandAccountComponent,
            },
            {
                path: 'manage-brand/account/:id',
                component: ManageBrandAccountComponent,
            },
            {
                path: 'manage-brand/:id/published-videos',
                component: ManageBrandPublishedVideosComponent,
            },
            {
                path: 'manage-brand/schedule-videos',
                component: ManageBrandScheduleVideosComponent,
            },
            {
                path: 'manage-brand/media-libraries',
                component: ManageBrandMediaLibrariesComponent,
            },
            {
                path: 'manage-brand/:id/media-libraries/:mediaLibId',
                component: ManageBrandMediaLibrariesComponent,
            },
            {
                path: 'manage-brand/:id/list-media-libraries',
                component: BrandMediaLibsComponent,
            },
            {
                path: 'manage-brand/:id/business-details',
                component: ManageBrandsBusinessDetailsComponent,
            },
            {
                path: 'user-schedule-video',
                component: UserAccountScheduleVideoComponent,
            },
            {
                path: 'user-media-libraries',
                component: UserAccountMediaLibrariesComponent,
            },
            {
                path: 'social-channels',
                component: SociallChannelsComponent,
            },
            {
                path: 'storage-details',
                component: StorageDetailsComponent,
            },
            {
                path: 'media-libraries',
                component: MediaaLibrariesComponent,
            },
            {
                path: 'published-videos',
                component: PublisheddVideoComponent,
            },
            {
                path: 'media-libraries/media',
                component: MediaLibraryyComponent,
            },
            {
                path: 'media-platforms',
                component: MediaPlatformsComponent,
            },
            {
                path: 'billing',
                component: BillingComponent,
            },
            { path: 'voice', component: MyAccountVoiceOverComponent },
        ]
    }


];

@NgModule({
    imports: [RouterModule.forChild(routesAccount)],
    exports: [RouterModule],

})
export class MyAccountRoutingModule {}
