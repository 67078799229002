import { Component, ElementRef, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { CreateVideoManagerService } from 'src/app/Managers/create-video-manager.service';

@Component({
    selector: 'app-add-keywords',
    templateUrl: './add-keywords.component.html',
    styleUrls: ['./add-keywords.component.scss'],
})
export class AddKeywordsComponent implements OnInit {
    loadingclip: boolean = false;
    showMyAssetPortion = true;
    lKey_terms: any = [];
    bDisplayAddInputField: boolean = false;
    inputValue: string = '';
    @Output() emitUpdateKeywords = new EventEmitter();
    @ViewChild('AddInputTagElement') AddInputTagElement: ElementRef;
    constructor(public videoManager : CreateVideoManagerService) {}

    ngOnInit(): void {
      this.lKey_terms = this.videoManager.selectedVideo.key_terms;
    }
    removeKeyword(index) {
        this.lKey_terms.splice(index, 1);
        this.updateKeyWords();
        // this.videoManager.selectedVideo.key_terms = this.lKey_terms;
    }
    addTagOnKeyEnter(keycode) {
        if ((keycode == 13 && this.inputValue != '') || (keycode == 188 && this.inputValue != '')) {
            this.AddTagInputField();
        }
    }
    getInputValue(event) {
        this.inputValue = event.target.value;
        console.log('Input Value ==>', this.inputValue);
    }
    toggleBtn() {
        this.inputValue = '';
        setTimeout(() => this.AddInputTagElement.nativeElement.focus());
        this.bDisplayAddInputField = true;
    }
    AddTagInputField() {
        this.bDisplayAddInputField = false;
        if (!this.lKey_terms) this.lKey_terms = [];

        let tagIndex = this.lKey_terms.findIndex((item: string) => {
            return item.toLowerCase() == this.inputValue.trim().toLowerCase();
        });

        if (tagIndex == -1 && this.inputValue.trim() != '') {
            this.lKey_terms.push(this.inputValue.trim());
        }
        setTimeout(() => {
            this.bDisplayAddInputField = false;
        }, 100);

        this.inputValue = '';
        this.updateKeyWords();
    }

    updateKeyWords(){
      this.emitUpdateKeywords.emit(this.lKey_terms);
    }
}
