import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { cSessionService } from './../../../services/session.service';
import { debounceTime,distinctUntilChanged } from 'rxjs/operators';
import { cDataService } from './../../../services/data.service';
import { Router } from '@angular/router';
import { CreateVideoManagerService } from './../../../Managers/create-video-manager.service';
import { OnboardingManagerService } from 'src/app/Managers/onboarding-manager.service';

@Component({
  selector: 'app-dashboard-schduled-videos',
  templateUrl: './dashboard-schduled-videos.component.html',
  styleUrls: ['./dashboard-schduled-videos.component.scss']
})
export class DashboardSchduledVideosComponent implements OnInit, OnDestroy {
 
  nScheduledVideosCount : number;
  bLoading : boolean = true;
  oSetTimeOut: any;
  bAlert: boolean = false;
  sUserName : string = "";
  fSearchVideo : FormGroup;


  constructor(private oSessionService : cSessionService, private oRouter : Router, private oFormBuilder : FormBuilder, private oDataService : cDataService,private videoManger:CreateVideoManagerService,
    public onboardingManager: OnboardingManagerService,) {}

  ngOnInit()
  {
    this.sUserName = this.oSessionService.cSessionService_GetUsername();
    this.fSearchVideo = this.oFormBuilder.group({title : ['']});
  }
  ngOnDestroy()
  {
    this.oDataService.sSearchVideoByTitle.next({title : "",type : ""});
  }
  ScheduledVideoComponent_SetTimeOut()
  {
    this.oSetTimeOut = setTimeout(() => {this.bLoading=false;}, 10000);
  }
  createNewVideo(){
    {
      this.videoManger.newVideo();
      this.oRouter.navigate(['wizard/overview']);
    }
  }

  SchuledVideosComponent_GetVideosCount(nIcommingVideosCount : any )
  {
    this.nScheduledVideosCount = nIcommingVideosCount;
    clearTimeout(this.oSetTimeOut);
    this.bLoading = false;
  }
  DashboardSchduledVideosComponent_SearchVideo()
  {
    this.fSearchVideo.get('title').valueChanges.pipe(debounceTime(1000),distinctUntilChanged())
    .subscribe(data=>
    {
      this.bLoading = true;
      this.oDataService.sSearchVideoByTitle.next({title : data,type : "scheduled"});
      this.ScheduledVideoComponent_SetTimeOut();
      console.log("Search video : ==> ", data)
    });
  }
  createNewModifiedVideo() {
    this.videoManger.newVideo();
    this.oRouter.navigate(["mv"]);
  }
}

          





