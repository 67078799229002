<div class="pt-4 px-0 d-flex flex-column">
    <div class="data-table m-0 d-flex flex-column flex-grow-1">
        <div class="table-header row m-0 px-4">
            <div class="col-5 px-0 text-start">
                <h3 class="weight-700 text-gray-2 mb-1">Locations</h3>
                <p class="mx-0 my-0 text-sm">Expand your reach by localizing your location information with ease</p>
            </div>
            <div class="col-7 px-0 text-end">
                <button
                    type="button"
                    (click)="showModal()"
                    class="btn btn-secondary btn-round-6 d-inline-block small-pad ms-0 float-end mt-1 text-xs"
                >
                    Upload Location File
                </button>
                <div class="float-end border-start d-inline-block ps-3 py-1 ms-1 mt-2">
                    <a class="text-gray-6 text-sm me-4" href="javascript:void(0)" (click)=" clearFields();">
                        Add a location</a>
                </div>
                <div class="float-end dropdown dropdownBtn btn-dropDown d-inline-block ms-4"></div>

                <ul class="dropdown-menu dropdown-menu-end dropdownMenu">
                    <li>
                        <a class="dropdown-item text-md" href="#;">Bulk Action</a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="flex-grow-1">
            <div class="row video-header mx-0" [ngClass]="search_term != '' ? 'mb-5' : ''">
                <ul class="container p-0 mb-1 d-flex justify-content-between align-items-center">
                    <li class="col-md-5">
                        <p class="m-0 mt-4">
                            <span class="text-lg text-gray-2">Total Locations </span>
                            <span class="badge rounded-pill bg-gray-3 ms-2 w-30 d-inline-block custom-badge text-xs">{{
                                oTotalCount
                            }}</span>
                        </p>
                    </li>
                    <li class="col-md-4 mb-2 pe-3 relative">
                        <div class="formItm search-icon searchDropdown">
                            <input
                                id="search-feild"
                                type="search"
                                value=""
                                [(ngModel)]="searchTermText"
                                placeholder="Search location"
                                required=""
                                (keyup.enter)="searchLocation()"
                            />
                            <label for="search-feild" class="text-sm" style="cursor: default">Search Location</label>

                            <img class="icon" (click)="searchLocation()" src="../../../../assets/images/svg/search.svg">
                        </div>
                        <div class="searchtem" *ngIf="search_term != ''" (click)="clearSearchTerm()">
                            <div>{{ search_term }}</div>
                            <mat-icon class="link">close</mat-icon>
                        </div>
                    </li>
                    <li class="col-md-3 mb-2 ps-2" id="selectpreff">
                        <div class="formItm down_arrow me-1 mt-4 search-sort-panel">
                            <div class="dropdown dropdownBtn">
                                <button
                                    class="dropdown-toggle text-md"
                                    type="button"
                                    (click)="openSearchSortTypeDD()"
                                    (blur)="hideSearchSortTypeDD($event)"
                                >
                                    <span class="dropdownBtnText"
                                        ><strong>Sort by </strong>
                                        {{ order_by == 'created' || order_by == '-created' ? 'Created' : '' }}
                                        {{ order_by == 'name' || order_by == '-name' ? 'Name' : '' }}
                                    </span>
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end dropdownMenu" [ngClass]="{ showDropDown: showSortByDD }">
                                    <li>
                                        <a class="dropdown-item text-md" href="javascript:void(0)" (click)="sortBySearchResult('name')"
                                            >Name
                                        </a>
                                    </li>
                                    <!-- <li>
                                        <a class="dropdown-item text-md" (click)="sortBySearchResult('created')">Last Created </a>
                                    </li> -->
                                </ul>
                            </div>
                            <div class="sort-arrows pos-right">
                                <img
                                    src="../../../../../assets/images/DataSort-1.svg"
                                    height="25px"
                                    *ngIf="sort_type == -1"
                                    (click)="sortTypeSearch(1)"
                                />
                                <img
                                    src="../../../../../assets/images/DataSort-3.svg"
                                    height="25px"
                                    *ngIf="sort_type == 1"
                                    (click)="sortTypeSearch(-1)"
                                />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <table class="table overflow-auto tableCustom table-hover mt-3 mb-0">
                <thead>
                    <tr>
                        <!-- <th scope="col" class="tableCheck">
                            <div class="custom-switch">
                            </div>
                        </th> -->
                        <th scope="col" *ngFor="let options of locationViewOptions | removeCopyOfLocationFields" >
                            {{options.title == 'name' ? 'Location' :options.title}}
                        </th>
                        <th scope="col" class="tableAction">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of this.listLocations; let i; as: index">

                        <td  *ngFor="let options of locationViewOptions | removeCopyOfLocationFields" >
                            <span style="text-align: center" [innerHTML]="getLocationRow(item, options.name, false)"> </span>
                        </td>

                        <td class="tableAction">
                            <a href="#" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                                <img src="../../../../assets/images/svg/ellipsis.svg" height="25px">
                            </a>
                            <ul class="dropdown-menu add_location page-shadow" aria-labelledby="dropdownMenuButton1">
                                <li data-sidebar="#updateLocation" (click)="editLocation(item)">Edit Location</li>
                                <li (click)="deleteLocationModal(item)">Delete Location</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="table-pagination py-3 px-4">
                <div class="row">
                    <div class="col-sm-4"></div>
                    <div class="col-sm-8 text-end" *ngIf="this.oTotalCount > 9">
                        <mat-paginator
                            style="margin-top: 15px"
                            pageIndex="nCurrentPage"
                            [length]="this.oTotalCount"
                            [pageSize]="this.nPageSize"
                            [pageSizeOptions]="[10, 20, 25, 50]"
                            (page)="loadPageEvent($event)"
                            showFirstLastButtons
                        ></mat-paginator>
                        <!-- <ul id="pagination" *ngIf="this.accountManger.locales?.length>0">
                                            <li>
                                                <a href="javascript:void(0)" class="preview-page"  (click)="nCurrentPage==1?'':loadPage(nCurrentPage-1)"
                                                        >

                                                </a>
                                            </li> -->
                        <!-- <li>
                                                <a href="javascript:void(0)"><span class="add_color">
                                                    {{nCurrentPage}}
                                                </span>of {{this.lPagination.length}}</a>
                                            </li> -->
                        <!-- <li *ngFor="let page of this.lPagination; let i as index">
                                                <a href="javascript:void(0)"  [ngClass]="i==nCurrentPage?'add_color':''"
                                                        (click)="loadPage(page)"  >{{page}}</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)" class="next-page" (click)="nCurrentPage==lPagination.length?'':loadPage(nCurrentPage+1)"
                                                    >

                                                </a>
                                            </li>
                                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Location side Nav Start -->
<nz-drawer
    [nzClosable]="false"
    [nzVisible]="visibleLocation"
    [nzPlacement]="placementLocation"
    (nzOnClose)="closeLocation()"
    [nzWidth]="478"
>
    <ng-container *nzDrawerContent>
        <nav style="padding: 24px;
        height: calc(100vh - 90px);
        overflow-y: auto;">
            <div class="sidebar-header pt-2 pb-3">
                <h3 class="text-gray-2 weight-700">{{ selectedLocationId != undefined  ? 'Edit Location' : 'Add location'}}</h3>
                <div (click)="closeLocation()" class="closeRightSideBar">

                    <img src="../../../../assets/images/close-square.png">
                </div>
            </div>
            <form *ngIf="this.fAddLocationForm ">
                <div class="loadLocationDataLoading" *ngIf="loadLocationDataLoading">  <div class="loading-boxes-animation">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div></div>
                <div class="sidebar-content pt-0 flex-grow-1 overflow-auto" *ngIf="locationOptions && locationOptions.length > 0 ">
                    <div class="formItm mb-3" *ngFor="let localField of locationOptions"
                    [ngStyle]="{'padding-top': !localField.heading ? '1.01rem' : '0' }">
                        <input
                            *ngIf="localField.type == 'string'  && (localField.copy_of == undefined) "
                            id="{{ localField.name }}"
                            name="{{ localField.name }}"
                            type="text"
                            placeholder="{{ localField.placeholder ? localField.placeholder : '' }}"
                            [(ngModel)]="localField.value"
                            [ngStyle]="{'padding-top': localField.heading ? '0' : '' }"
                        />
                        <label *ngIf="localField.type == 'string' && (!localField.heading)" class="fs-6 fw-normal" for="{{ localField.name }}"
                            >{{ localField.title ? localField.title : 'name' }}
                        </label>
                        <div *ngIf="localField.type == 'string' && localField.value2 != undefined" class="formItm">
                            <input
                                id="{{ localField.name }}2"
                                name="{{ localField.name }}2"
                                type="text"
                                placeholder="{{ localField.placeholder ? localField.placeholder : '' }}"
                                required=""
                                [(ngModel)]="localField.value2"
                            />
                        </div>
                        <div class="error" *ngIf="localField.name == 'name' && localField.error" style="position: relative;
                        margin-top: -20px; padding-bottom: 12px;     color: #ed0d2e;">Location name is empty</div>

                        <label *ngIf="localField.type == 'logo'" class="fs-6 fw-normal" for="{{ localField.name }}2">{{
                            localField.title ? localField.title : 'name'
                        }}</label>

                        <div class="d-flex" style="margin-top: 40px; align-items: baseline;" *ngIf="localField.type == 'logo'">
                            <p>
                                <button class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2" (click)="openFileUploadModal(localField)">
                                    <img class="svgSelect" src="../../../../assets/images/svg/upload.svg" />

                                    &nbsp; &nbsp; Upload a Logo
                                </button> <br>
                                <span class="text-siver">Supported formats (.jpg, .png)</span>
                            </p>

                            <button
                                class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 ms-2"
                                (click)="showMediaLibrary('image', localField)"
                            >
                                <img src="../../../../assets/images/svg/mediaLibrary.svg" />
                                &nbsp; &nbsp; Select From Media Library
                            </button>
                        </div>
                        <div *ngIf="localField.type == 'logo' && localField.media_obj && localField.media_obj != undefined && localField.media_obj != 'undefined'">
                            <li class="mb-3 img-list d-flex mt-3">
                                <figure class="media_figure" style="width: 60px">
                                    <span class="media"> <img [src]="localField.media_obj.thumbnail? localField.media_obj.thumbnail : localField.media_obj.thumbnail_signed_url" alt="Logo" /></span>
                                </figure>
                                <figcaption class="ms-2">
                                    <div class="caption w-100">
                                        <p class="text-xs title" *ngIf="localField.media_obj?.thumbnail && localField.media_obj?.thumbnail !=''">
                                            {{ returnFpath(localField.media_obj)}}
                                        </p>
                                        <p class="text-xs sub_title" *ngIf="localField.media_obj.size">
                                            {{ ContentComponent_ReturnFileSize(localField.media_obj.size) }}
                                        </p>
                                    </div>
                                </figcaption>
                                <div class="cta d-flex align-items-center ms-5" (click)="removeMediaFile(localField)">
                                    <a href="javascript:void(0)" class="me-3">
                                        <img src="../../../../assets/images/Delete.svg" style="height: 16px; width: 18px" />
                                    </a>
                                </div>
                            </li>
                        </div>

                        <label for="loginEmail" class="fs-6 fw-normal" *ngIf="localField.type == 'voice'">Voiceover</label>

                        <div *ngIf="localField.type == 'voice'" class="d-flex" style="margin-top: 40px;">
                            <!-- <p> -->
                                <button class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2" (click)="openVoiceEditor(localField)">
                                    <img class="svgSelect" src="../../../../assets/images/svg/upload.svg" />

                                    &nbsp; &nbsp; Add Voiceover
                                </button>
                                <!-- <br>
                                <span class="text-siver">Supported formats (.mp3, m4a, .wav)</span>
                            </p> -->
                            <button
                                class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 ms-2"
                                (click)="showMediaLibrary('audio',localField)"
                            >
                                <img src="../../../../assets/images/svg/mediaLibrary.svg" />
                                &nbsp; &nbsp; Select From Media Library
                            </button>
                        </div>
                        <div *ngIf="localField.type == 'voice'"  class="formItm mb-3 ng-star-inserted">
                            <div *ngIf="localField.type == 'voice'">
                                <li class="mb-3 img-list d-flex" *ngIf="localField.media_obj && localField.media_obj != undefined && localField.media_obj != 'undefined'">
                                    <figure class="mb-0" style="width: 80px; position:relative;" >

                            <button class="muteBtn btn-hover-w voice-btn"
                            (mouseover)="AudioSidePanelComponent_PlayAudio(localField?.media_obj?.url? localField?.media_obj?.url: localField?.media_obj?.signed_url)"
                            (mouseout)="AudioSidePanelComponent_StopAudio(localField?.media_obj?.url? localField?.media_obj?.url: localField?.media_obj?.signed_url)">
                            <img src="../assets/images/microphone-off.svg" class="off"
                                style="margin-top: 0px;margin-left: 4px;" width="14" height="13" />
                            <img src="../assets/images/microphone.svg" class="on"
                                style="margin-top: 0px;margin-left: 4px;" width="14" height="13" />
                        </button>
                                        <img [src]="imageStaticThumb" alt="audio-line" height="80px" style="object-fit: cover" />
                                    </figure>
                                    <figcaption class="ms-2">
                                        <div class="caption w-100">
                                            <p class="text-xs title" *ngIf="localField.media_obj && localField.media_obj != 'undefined' && localField.media_obj != '' && localField.media_obj.fpath != ''">
                                                {{  returnFileName(localField?.media_obj?.fpath?.split('/')?.pop()) }}
                                            </p>
                                            <p class="text-xs sub_title" *ngIf="localField.media_obj.duration">
                                                {{ ContentComponent_ReturnFileSize(localField.media_obj.duration) }}
                                            </p>
                                        </div>
                                    </figcaption>
                                    <div class="cta d-flex align-items-center ms-5" (click)="removeAudioFile(localField)">
                                        <a href="javascript:void(0)" class="me-3">
                                            <img src="../../../../assets/images/Delete.svg" style="height: 16px; width: 18px" />
                                        </a>
                                    </div>
                                </li>
                            </div>
                        </div>

                    </div>
                    <div class="side-footer d-flex align-items-center" style="    position: absolute;
                    bottom: 4vh;">
                        <button class="btn btn-primary btn-round-6 d-inline-block px-4 py-3" (click)="updateLocationValue()">
                            <span class="d-block"
                                >{{
                                     selectedLocationId != undefined  ? 'Save' : 'Save'
                                }}
                            </span>
                        </button>
                    </div>
                </div>

            </form>
        </nav>
    </ng-container>
</nz-drawer>
<!-- contactus sidebar Modal end-->

<!-- modal Upload Location File start -->
<nz-modal [(nzVisible)]="isVisible" nzCentered [nzWidth]="780" [nzFooter]="null" [nzClosable]="false" (nzOnCancel)="handleCancel()">
    <div>
        <div class="modal-dialog modal-dialog-centered modal-lg drag-dropfile">
            <div class="modal-content" style="padding: 10px 40px 40px 40px !important">
                <div class="modal-header">
                    <h5 class="modal-title pb-5" id="exampleModalLabel">Upload Location File</h5>
                    <button
                        type="button"
                        #closeModal
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        (click)="handleCancel()"
                    ></button>
                </div>
                <div class="modal-body mt-0">
                    <div class="form-group files">
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-device" role="tabpanel" aria-labelledby="pills-device-tab">
                                <div class="file-drop-area">
                                    <figure>
                                        <img  class="svgSelect" src="../../../../assets/images/svg/upload.svg" />
                                    </figure>
                                    <span class="choose-file-button">Upload a File</span>
                                    <span class="text-line"
                                        >Click to <font class="text-primary-3">browse</font>, or<br />
                                        drag & drop your file here</span
                                    >
                                    <span class="file-message">No file selected</span>
                                    <input
                                        #resetField
                                        (click)="LocationsComponent_ResetField()"
                                        class="file-input"
                                        type="file"
                                        accept=".csv"
                                        (change)="LocationsComponent_ReadCSVFile($event)"
                                        multiple
                                    />
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-drive" role="tabpanel" aria-labelledby="pills-drive-tab">
                                <div class="file-drop-area">
                                    <figure>

                                        <img src="../../../../assets/images/svg/my device.svg">
                                    </figure>
                                    <span class="choose-file-button">Upload a File (Drive)</span>
                                    <span class="text-line"
                                        >Click to <font class="text-primary-3">browse</font>, or<br />
                                        drag & drop your file here</span
                                    >
                                    <span class="file-message">No file selected</span>
                                    <input #resetField class="file-input" type="file" multiple />
                                </div>
                            </div>
                            <ul class="nav mb-3" id="pills-tab" role="tablist">
                                <li class="col nav-item" role="presentation">
                                    <button
                                        class="nav-link"
                                        (click)="resetField.click()"
                                        id="pills-device-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-device"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-device"
                                        aria-selected="true"
                                    >
                                        <img src="../assets/images/business_Profile/device.png" alt="tab-icon" />
                                        <span>My Device</span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nz-modal>

<!-- Delete location Modal pop-up Start-->
<nz-modal
    [(nzVisible)]="showDeleteLocationModal"
    nzCentered
    [nzWidth]="500"
    [nzFooter]="null"
    [nzClosable]="false"
    (nzOnCancel)="showDeleteLocationModal = false"
>
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="btn-close cust-close closeRightBtn" (click)="showDeleteLocationModal = false"></button>

                <h5>Are you sure you want to Delete this Location?</h5>
                <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">You'll not be able to watch this Location again.</p>
                <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" (click)="showDeleteLocationModal = false">Cancel</a>
                <a href="javascript:void(0)" class="mt-3 text-gray-3 text-sm" (click)="deleteLocale()">Delete</a>
            </div>
        </div>
    </div>
</nz-modal>
<!--Delete location Modal pop-up ends -->

<nz-modal
    [(nzVisible)]="visible"
    *ngIf="this.visible"
    nzCentered
    [nzWidth]="825"
    [nzFooter]="null"
    [nzClosable]="true"
    (nzOnCancel)="closeMediaModal()"
>
    <app-media-library-popup
        [mediaLoadType]="'image'"
        (lEmittedFilesToUpload)="ContentComponent_lEmittedFilesToUpload($event)"
        [mediaPopUpLoc]="'mediaPopUpLoc'"
        [isSingleSelected]="true"
    >
    </app-media-library-popup>
</nz-modal>

<nz-modal
    [(nzVisible)]="visibleforAudio"
    *ngIf="this.visibleforAudio"
    nzCentered
    [nzWidth]="825"
    [nzFooter]="null"
    [nzClosable]="true"
    (nzOnCancel)="closeAudioModal()"
>
    <app-media-library-popup
        [mediaLoadType]="'audio'"
        (lEmittedFilesToUpload)="EmittedAudioFiles($event)"
        [audioOnly]="true"
        [isSingleSelected]="true"
    >
    </app-media-library-popup>
</nz-modal>
