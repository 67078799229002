import { EventEmitter, Injectable } from '@angular/core';
import { mediaLib, visual, mediaFile, clip } from '../models/media';
import { overlayclip, overlayTexts, sap_config, video } from '../models/video';
import { MediaService } from '../services/dataServices/media.service';
import { LoaderService } from '../services/loader.service';
import { OnboardingManagerService } from './onboarding-manager.service';

@Injectable({
  providedIn: 'root'
})
export class ModifyVideoManagerService {



  isPreview = false;
  isModifyVideo: boolean = true;
  selectedVideo: video;
  constructor(
    public dataservice: MediaService,
    public loader: LoaderService,
    private onboardingService : OnboardingManagerService) {
    this.selectedVideo = new video();
this.selectedVideo.duration =60;
    this.selectedVideo.uploaded_video_id = "";
    this.selectedVideo.video_url="https://storage.googleapis.com/1000874121157-dev/users/wazikhan%40outlook.com/videos/Test_30_16x9_4450c2645a.mp4?Expires=1671123742&GoogleAccessId=pronto-service-account%40pronto-project.iam.gserviceaccount.com&Signature=HOjmgXBnFk339U9BRjLPY4ThSNSuljnsD4A0XdAwW466a2fZvhRE8GuYJiZZTzC4rpgluXWfSW%2B1%2FqhNvOwX7B%2BBq112Y35aUsGIIJ63LDI54W45ClH1vRGNcJbBsdRSC6s1Bv6GrX3bBIw5tg3ZdVJNDHSKtTeVdqWjTg17X%2Fa3AMrCLveT5uXv%2Bu4QdO2baFAX7Rm0xQsSq5wi77IZIRxTmMrcWynji0%2FecyDMPUJzlYTIibnsb5nU3Pw0q46B6d2At2V4uHS2uwm7F7lMAE73esG0NyzmT431daQUaydIVirnpPyQ58lwbgkNCvny%2BfxMRi%2FEP7mgwTnOhlHIsg%3D%3D";
    this.selectedVideo.sap_config = new sap_config();
    let clip = new overlayclip();
    clip.ts = [0, 4.5];
    clip.video_id = "1";
    // clip.video_id = "fpath";
    clip.video_thumbnail = "https://storage.googleapis.com/1000874121157-dev/users/wazikhan%40outlook.com/videos/Test_30_16x9_4450c2645a_thumb.jpg?Expires=1671123742&GoogleAccessId=pronto-service-account%40pronto-project.iam.gserviceaccount.com&Signature=iZG3pUbxV2veE2%2FEtt3adpWoMVhU51ouUeFjswyZ4O7gYRce%2BUs6WEgKAq2p7%2BMZA2g3ZB8OmF7EKwCE2qmzMfL8LLtz34vzPNCgigGaye64t7glACCi%2BIaqZXwHX8U8E3Ud%2FW6L22z05Q6pZpVapI4YvotKVYuXwlJVWQT4q0dhzIpzSnu%2F1bDGlPlisBmXV%2BwqCBEoWGT7mEjfktUgBv7WuEH5DdovtjYNp%2FBz1vV3j9rapDKlM3P9Q4jShzDvgVbV0WCpNeYw880bgAARiOwTUPlRHXBfUjD1NvAEzTUDtO%2FKWoZ1KlGOK2Pi4pqW2cC%2B%2BocDDXkgX0svkVZdFA%3D%3D";

    clip.video_url ="https://storage.googleapis.com/1000874121157-dev/users/wazikhan%40outlook.com/videos/Test_30_16x9_4450c2645a.mp4?Expires=1671123742&GoogleAccessId=pronto-service-account%40pronto-project.iam.gserviceaccount.com&Signature=HOjmgXBnFk339U9BRjLPY4ThSNSuljnsD4A0XdAwW466a2fZvhRE8GuYJiZZTzC4rpgluXWfSW%2B1%2FqhNvOwX7B%2BBq112Y35aUsGIIJ63LDI54W45ClH1vRGNcJbBsdRSC6s1Bv6GrX3bBIw5tg3ZdVJNDHSKtTeVdqWjTg17X%2Fa3AMrCLveT5uXv%2Bu4QdO2baFAX7Rm0xQsSq5wi77IZIRxTmMrcWynji0%2FecyDMPUJzlYTIibnsb5nU3Pw0q46B6d2At2V4uHS2uwm7F7lMAE73esG0NyzmT431daQUaydIVirnpPyQ58lwbgkNCvny%2BfxMRi%2FEP7mgwTnOhlHIsg%3D%3D";
    
    // clip.video_url = signedurl..
    
    let clip2 = new overlayclip();
    clip2.ts = [6.0, 5.5];
    clip2.video_id = "2";
    clip2.video_thumbnail = "https://storage.googleapis.com/1000874121157-dev/users/wazikhan%40outlook.com/videos/Test_30_16x9_4450c2645a_thumb.jpg?Expires=1671123742&GoogleAccessId=pronto-service-account%40pronto-project.iam.gserviceaccount.com&Signature=iZG3pUbxV2veE2%2FEtt3adpWoMVhU51ouUeFjswyZ4O7gYRce%2BUs6WEgKAq2p7%2BMZA2g3ZB8OmF7EKwCE2qmzMfL8LLtz34vzPNCgigGaye64t7glACCi%2BIaqZXwHX8U8E3Ud%2FW6L22z05Q6pZpVapI4YvotKVYuXwlJVWQT4q0dhzIpzSnu%2F1bDGlPlisBmXV%2BwqCBEoWGT7mEjfktUgBv7WuEH5DdovtjYNp%2FBz1vV3j9rapDKlM3P9Q4jShzDvgVbV0WCpNeYw880bgAARiOwTUPlRHXBfUjD1NvAEzTUDtO%2FKWoZ1KlGOK2Pi4pqW2cC%2B%2BocDDXkgX0svkVZdFA%3D%3D";
   clip2.video_url="https://storage.googleapis.com/1000874121157-dev/users/wazikhan%40outlook.com/videos/Test_30_16x9_4450c2645a.mp4?Expires=1671123742&GoogleAccessId=pronto-service-account%40pronto-project.iam.gserviceaccount.com&Signature=HOjmgXBnFk339U9BRjLPY4ThSNSuljnsD4A0XdAwW466a2fZvhRE8GuYJiZZTzC4rpgluXWfSW%2B1%2FqhNvOwX7B%2BBq112Y35aUsGIIJ63LDI54W45ClH1vRGNcJbBsdRSC6s1Bv6GrX3bBIw5tg3ZdVJNDHSKtTeVdqWjTg17X%2Fa3AMrCLveT5uXv%2Bu4QdO2baFAX7Rm0xQsSq5wi77IZIRxTmMrcWynji0%2FecyDMPUJzlYTIibnsb5nU3Pw0q46B6d2At2V4uHS2uwm7F7lMAE73esG0NyzmT431daQUaydIVirnpPyQ58lwbgkNCvny%2BfxMRi%2FEP7mgwTnOhlHIsg%3D%3D";

;
    this.selectedVideo.sap_config.clips.push(clip);
    this.selectedVideo.sap_config.clips.push(clip2);

    let text = new overlayTexts();
    text.video_id = "1";
    text.ts = [0, 4.5];
    text.text =  ['Title 1','subtile'];    
    let text2 = new overlayTexts();
    text2.video_id = "2";
    text2.ts = [6.0, 5.5];
    text2.text =  ['Title 3'];    
    this.selectedVideo.sap_config.texts.push(text);
    this.selectedVideo.sap_config.texts.push(text2);

  }

  public submitForm: EventEmitter<string> = new EventEmitter();
  public previousSubmit: EventEmitter<number> = new EventEmitter();


  public selectedmedialib: mediaLib;
  public mediaLibs: mediaLib[] = [];
  public uploadMediaList: mediaFile[] = [];
  public visuals: visual[] = [];

  async getMediaLibraries(email, session_id, mediaLibStatus): Promise<unknown> {
    this.loader.showLoader();
    let promise = new Promise((resolve, reject) => {
      this.dataservice.getMediaLibrary(email, session_id, mediaLibStatus).toPromise().then(res => {
        this.loader.hideLoader();
        this.mediaLibs = res['media_libs'] as mediaLib[];

        resolve(this.mediaLibs);
      }).catch(err => {
        reject(err);
      });
    });
    return promise;
  }

  async uploadMediaDefaultLibrary(email, session_id, media: mediaFile): Promise<visual> {

    if (this.mediaLibs.length == 0) {
      await this.getMediaLibraries(email, session_id, 'active').then();
    }

    let promise = new Promise<visual>(async (resolve, reject) => {

      let vs = new visual();

      media.status = "uploading";
      console.log( "%c*********CHECKING DEFAULT MEDIA LIB********", "color : red");
      console.log(`%c${this.onboardingService?.profile?.default_media_lib?._id?.$oid}`,"color : red");
      let uploadMediaLibraryId = this.mediaLibs[0].lib_id;
      if( this.onboardingService?.profile?.default_media_lib?._id?.$oid){
        console.log("Note: Uploading into Default Media Library ", 'color: red; background: yellow; font-size: 30px');
        uploadMediaLibraryId = this.onboardingService?.profile?.default_media_lib?._id?.$oid;
      }

      await this.dataservice.DataService_BulkUpload(email, session_id, uploadMediaLibraryId, media.file.name, media.file.type).then(async e => {
        var signed_url = e['signed_url'];
        var fpath = e['fpath'];

        await this.dataservice.DataService_UploadFileOnSignedUrl(media.file, signed_url).then(async es => {

          await this.dataservice.AddUserMedia(email, session_id, media.file.name, uploadMediaLibraryId, fpath).then(async res => {
            media.status = "uploaded";

            await this.dataservice.getVisual(email, session_id, res['fpath']).then(async vis => {
              vs = vis as visual;
              // visual.thumb = res['thumb']
              // visual.url = res['url'];
              vs.name = media.name;
              vs.loadingUrl = true;
              vs.loadingThumbnail = true;
              await this.dataservice.getSignedURL(email, session_id, vs.visual_path).then(async resp => {
                console.log("resp", resp);

                vs.url = resp['url']
                if (vs.visual_type == "image") {
                  vs.thumbnail = resp['url'];
                }
                vs.loadingUrl = false;
              }).catch(err => {
                vs.loadingUrl = false;

              });

              if (vs.visual_type == "clip") {

                await this.dataservice.getThumbBatchSignedURL(email, session_id, [vs.visual_path]).then(async resp => {

                  console.log(resp[0]);
                  vs.thumbnail = resp['output'][0].thumb_signed_url;
                  vs.loadingThumbnail = false;

                  // console.log('visual_thumbnail', visual);
                }).catch(err => {

                  vs.loadingThumbnail = false;
                });
              }





            })




          }).catch(err => {

            throw err;
          })

        }).catch(err => {

          throw err;
        });



        resolve(vs);
      }).catch(err => {

        reject(err);
      });




    });
    return promise;
  }
}
