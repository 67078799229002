import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ErrorService } from 'src/app/services/error.service';
import { SuccessMessageModalService } from 'src/app/services/success-message-modal.service';
import { UploadFileDialogComponent } from '../components/upload-file-dialog/upload-file-dialog.component';
import { MediaManagerService } from './../../Managers/media-manager.service';
import { mediaFile, visual } from './../../models/media';
import { cDataService } from './../../services/data.service';
import { cSessionService } from './../../services/session.service';
import { cTemplateConfigService } from './../../services/template.service';
import { cWebSocketService } from './../../services/websocket.service';
import { CreateVideoManagerService } from 'src/app/Managers/create-video-manager.service';
import { OnboardingManagerService } from 'src/app/Managers/onboarding-manager.service';

@Component({
  selector: 'app-audio-sidepanel',
  templateUrl: './audio-sidepanel.component.html',
  styleUrls: ['./audio-sidepanel.component.scss']
})
export class AudioSidepanelComponent implements OnInit {
  @ViewChild('closeModal') closeModal: ElementRef;
  lBreadcrumbOptions: any = [
    { name: 'Prontopia Library', filter: 'pronto' },
    { name: 'Brand Library', filter: 'myassets' },
    // { name: 'Turn off Music', filter: 'turn_off' }
  ]
  sSessionId: any;
  sUserEmail: any;
  fUserVisualListForm: FormGroup;
  nUserAudiosCount: number;
  fUserVisualCountForm: FormGroup;
  sSelectedMediaLibrary: string;
  nPageSize: number = 30;
  lPagination: any = [];
  lUserPagination: any = [];
  lUserVisualsList: visual[] = [];
  lProntoVisualsList: visual[] = [];
  nUserVisualID: number = 0;
  nAlreadyFetchedVisuals: number = 0;
  sVisualType: string = '';
  nCurrentPage: number = 1;
  nUserVisualsCount: number;
  nUserImagesCount: number;
  nUserProntoCount: number;
  nVoiceOverFileSize: number = 0;
  sDisplayResponseMessage: string;
  oUploadedVoiceOverFile: any;
  sUploadedVoiceOverFileName: any;
  oSelectedAudio: any;
  oVisualType: string = 'all';
  @Input() oMusicFile;
  @Output() oMusicFileurl = new EventEmitter<string>();
  bMediaLoader: boolean = false;
  lIncomingProntoAudios: any = [];
  oSavedFormData: any;
  fMusicFileForm: FormGroup;
  bSavedNotClicked: boolean = true;
  oSelectedMusicFile: any;
  bLetProntoChoose: boolean = true;
  bDisplayMyMediaSection: boolean = false;
  bDisplayUploadSection: boolean = true;
  lUploadedFiles: any = [];
  lUserSelectedVisuals: any = [];
  sResetTextOverLays: string;
  bShowTextToSpeechSection: boolean = true;
  lBlockConfigurations: any = [];
  oAudio = new Audio();
  bTurnOff: boolean = false;
  @Output() onClose = new EventEmitter<any>();
  selectedGenre: any;
  genreList: any = [
    { name: "All", value: "All" },
    { name: "Genre 1", value: "Genre 1" },
    { name: "Genre 2", value: "Genre 2" },
    { name: "Genre 3", value: "Genre 3" },
  ];

    isGenre = true;
  constructor(private oTemplateConfigService: cTemplateConfigService,
    private oFormBuilder: FormBuilder, private oWebSocketService: cWebSocketService, private oSessionService: cSessionService, private oDataService: cDataService,
    public mediaManager: MediaManagerService,
    private errorMessage: ErrorService,
    private successMessage: SuccessMessageModalService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private videoManager: CreateVideoManagerService,
    public onBoarding: OnboardingManagerService) { }

  ngOnInit() {
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();

    this.changeTab('pronto');
    this.oVisualType = 'pronto';
    this.selectedGenre = this.genreList[0].name;
    this.mediaManager.getListGenres({email : this.sUserEmail, session_id : this.sSessionId}).then( (listGen) => {
      if(listGen) {
        this.genreList = listGen;
      }
    })


  }
  filterList: visual[] = [];
  private fetchAudio(type: string) {

  }

  MediaLibrarySharedComponent_ProntoAudiosRequest() {
    this.fUserVisualListForm.patchValue({ email: this.sUserEmail, session_id: this.sSessionId, num_items: 10, num_skip: 0 });
    this.oWebSocketService.WebSocket_SendMessage('pronto_audio_list_request', JSON.stringify(this.fUserVisualListForm.value));
  }

  changeTab(nIncomingValue) {


    if (this.oVisualType !== nIncomingValue) {
      this.lPagination = [];
      this.oVisualType = nIncomingValue;
      this.MediaLibrarySharedComponent_ResetView();
      this.nCurrentPage = 1;
      if (this.oVisualType == "myassets") {
        // this.bTurnOff = false;
        this.getUserAudios(this.nPageSize, 0);
        this.drawPagination(this.nUserAudiosCount);
      }
      else if (this.oVisualType == "pronto") {
        // this.bTurnOff = false;
        this.getProntoAudios(30, 0);
        // this.drawPagination(183);
      }
      // else if (this.oVisualType == 'turn_off') {
      //   this.bTurnOff = true;
      // }
      else {
        // this.bTurnOff = false;
        this.drawPagination(this.nUserVisualsCount);
        this.nPageSize = 10 / this.nUserVisualsCount;
        // console.log("Total : ==> ", this.nPageSize);
        this.getProntoAudios(this.nPageSize, 0);
        this.getUserAudios(Math.floor(this.nPageSize * this.nUserAudiosCount), 0);
      }
      // console.log("User Visuals List ======>>>", this.lUserVisualsList);
    }
  }

  async getProntoAudios(nIemsToFetch: number, nItemsToSkip: number) {
    let nIemsToFetchs = nIemsToFetch && nIemsToFetch > 0 ? nIemsToFetch : 30;
    this.lUserVisualsList = [];
    this.isGenre = true;
    if(this.selectedGenre == 'All' || this.selectedGenre == 'all'){
      this.selectedGenre = '';
    }
    // console.log( " item to skip ", nItemsToSkip);
    this.mediaManager.getAudios(this.sUserEmail, this.sSessionId, nIemsToFetchs, nItemsToSkip, this.selectedGenre).then(res => {

      this.mediaManager.audios.forEach(v => {
        v.assetType = 'pronto';
        this.lUserVisualsList.push(v);
        this.isGenre = false;
      });
      this.nUserAudiosCount = res['count'];
      this.drawPagination(this.nUserAudiosCount);
    });
  }

  getUserAudios(nIemsToFetch: number, nItemsToSkip: number) {
    this.lUserVisualsList = [];

    let mediaLib = '';
    // let emailAdd = this.sUserEmail;
    // Checking is brand Video
    let isBrandClip = false;
    if (this.videoManager.videoBrand) {
      mediaLib = (this.videoManager.videoBrand.media_libs && this.videoManager.videoBrand.media_libs.length > 0) ? this.videoManager.videoBrand.media_libs[0]?._id?.$oid : '';
      if (mediaLib && mediaLib != '') {
        isBrandClip = true;
      }
    }

    this.mediaManager.getVisuals(this.sUserEmail, this.sSessionId, 'audio', mediaLib, nIemsToFetch, nItemsToSkip, '-created', '', isBrandClip).then(res => {
      this.nUserVisualsCount = res['count'];
      this.mediaManager.visuals.forEach(v => {
        v.assetType = 'user';
        this.lUserVisualsList.push(v);
      });

    });
  }

  MediaLibrarySharedComponent_HandleUserVisualsResponse = async (IncomingMessage) => {
    if (IncomingMessage.errorMessage) {
      // console.error('MediaLibrarySharedComponent_HandleUserVisualsResponse : Error ==> ', JSON.parse(IncomingMessage.errorMessage));
    }
    else {
      let formatedIcomingMessage = JSON.parse(IncomingMessage);
      // console.log('MediaLibrarySharedComponent_HandleUserVisualsResponse (Raw_Response): ', formatedIcomingMessage);
      this.bMediaLoader = true;
      if (formatedIcomingMessage.length !== 0) {
        for (let nVisualToPushIndex = 0; nVisualToPushIndex < formatedIcomingMessage.length; nVisualToPushIndex++) {
          await this.MediaLibrarySharedComponent_GenerateDisplayList(formatedIcomingMessage[nVisualToPushIndex]);
        }
      }
      else {
        // console.log('You have got a empty array in the response');
      }
      this.bMediaLoader = false;
      // console.log('List to display :', this.lUserVisualsList);
    }
  }

  async MediaLibrarySharedComponent_GenerateDisplayList(oIncommingVisual: any) {
    this.nUserVisualID = this.nUserVisualID + 1;
    let visual_path = oIncommingVisual.visual_path;
    if (!visual_path) {
      visual_path = oIncommingVisual.fpath;
    }
    let filename = visual_path.substr(visual_path.indexOf('.com/') + 5, visual_path.length);
    let key_terms = oIncommingVisual.key_terms ? oIncommingVisual.key_terms : [];
    let visual_type = oIncommingVisual.visual_type;
    if (!visual_type) {
      visual_type = oIncommingVisual.type;
    }
    let url = await this.oDataService.DataService_GetSignedURLForDownload(visual_path);
    let size = oIncommingVisual.size;
    let duration = oIncommingVisual.duration
    let library = oIncommingVisual.media_lib;
    let used_in = oIncommingVisual.videos_used_in ? oIncommingVisual.videos_used_in : [];
    let sThumbnail: string;
    let checked: boolean = false
    if (this.oVisualType === "myassets") {
      this.lUserVisualsList.unshift({
        thumb: sThumbnail, url: url, visual_path: visual_path, key_terms: key_terms, visual_type: visual_type, name: filename, size: size,
        library: library, used_in: used_in,
        checked: checked, duration: duration
      });
    }
    else if (this.oVisualType === "all") {
      this.lUserVisualsList.unshift({
        thumb: sThumbnail, url: url, visual_path: visual_path, key_terms: key_terms, visual_type: visual_type, name: filename, size: size,
        library: library, used_in: used_in,
        checked: checked, duration: duration
      });
    }
  }

  MediaLibrarySharedComponent_HandleUserProntoResponse = async (IncomingMessage) => {
    if (IncomingMessage.errorMessage) {
      //console.error('MediaLibrarySharedComponent_HandleUserProntoResponse : Error ==> ', JSON.parse(IncomingMessage.errorMessage));
    }
    else {
      let formatedIcomingMessage = JSON.parse(IncomingMessage);
      console.log('MediaLibrarySharedComponent_HandleUserProntoResponse (Raw_Response): ', formatedIcomingMessage);

      this.bMediaLoader = true;
      if (formatedIcomingMessage.audio_files.length !== 0) {
        console.log("Incoming Length of the response ===>>", formatedIcomingMessage.audio_files.length);
        for (let nVisualToPushIndex = 0; nVisualToPushIndex < 20; nVisualToPushIndex++) {
          this.lIncomingProntoAudios.push(formatedIcomingMessage.audio_files[nVisualToPushIndex]);
        }
      }
      else {
        console.log('You have got a empty array in the response');
      }
      this.bMediaLoader = false;
      console.log('List to display :', this.lUserVisualsList);
    }
  }

  async MediaLibrarySharedComponent_GenerateDisplayListForProntoVisuals(oIncommingVisual: any) {
    this.nUserVisualID = this.nUserVisualID + 1;
    let filename = oIncommingVisual?.substr(oIncommingVisual?.indexOf('.com/') + 10, oIncommingVisual?.length);
    let visual_path = oIncommingVisual;
  }

  openFileUploadModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "100wv";
    dialogConfig.height = "100vh";
    dialogConfig.panelClass = "pronto-dialog";
    dialogConfig.closeOnNavigation = true;

    let mediaLib = '';
    if (this.videoManager.videoBrand) {
      mediaLib = (this.videoManager.videoBrand.media_libs && this.videoManager.videoBrand.media_libs.length > 0) ? this.videoManager.videoBrand.media_libs[0]?._id?.$oid : '';
    }

    dialogConfig.data = {
      uploadFor: "audio",
      multipleUpload: false,
      // accept: "video/mp4, video/avi",
      dropBoxText: "",
      heading: "Upload a Audio File",
      mediaLibraryId: mediaLib
    };
    if (mediaLib == '') {
      delete dialogConfig.data.mediaLibraryId;
    }

    let dialogModal = this.dialog.open(UploadFileDialogComponent, dialogConfig);

    dialogModal.afterClosed().subscribe(async (data: any) => {
      if (data.status == "success") {
        const uploadedFiles = data.result[0]
        this.oSessionService.oUploadedLogo = uploadedFiles;

        this.oUploadedVoiceOverFile = uploadedFiles;
        this.sUploadedVoiceOverFileName = uploadedFiles.name;
        this.closeModal.nativeElement.click();
        if (this.oUploadedVoiceOverFile.length !== 0) {
          await this.MediaLibrarySharedComponent_GenerateDisplayList(this.oUploadedVoiceOverFile);
        }
        else {
          this.cd.detectChanges();
        }
      } else {

      }
    });
  }

  async ContentComponent_UploadedVoiceOverFile(event) {
    let file = event.target.files[0];
    if (file) {
      this.nVoiceOverFileSize = file.size;
      console.log("Audio File Upload ....>>>> : File To Upload ==> ", file);
      var oFormData: any = new FormData();
      oFormData.append('session_id', this.sSessionId);
      oFormData.append('email', this.sUserEmail);
      oFormData.append('file', file);
      try {
        this.successMessage.success("Audio file is uploading, please wait", 'File Upload', true, false);
        let media = new mediaFile();
        media.file = file;
        media.name = file.name;

        this.mediaManager
          .uploadMediaDefaultLibrary(this.sUserEmail, this.sSessionId, media)
          .then(async (result) => {
            this.successMessage.close();
            this.oUploadedVoiceOverFile = result;
            this.sUploadedVoiceOverFileName = file.name;
            this.closeModal.nativeElement.click();
            if (this.oUploadedVoiceOverFile.length !== 0) {
              await this.MediaLibrarySharedComponent_GenerateDisplayList(this.oUploadedVoiceOverFile);
            }
            else {
              console.log('You have got a empty array in the response');
            }
          }).catch((error) => {
            this.successMessage.close();
            this.oUploadedVoiceOverFile = null;
            this.errorMessage.handleError(error.error.errorMessage ? error.error.errorMessage : error.error, "Upload Audio", true);
            console.error("ContentComponent_UploadedVoiceOverFile : Error ==>", error);
            this.closeModal.nativeElement.click();
          });
      }
      catch (error) {
        this.successMessage.close();
        this.errorMessage.handleError(error.error.errorMessage ? error.error.errorMessage : error.error, "Upload Audio", true);
        console.error("ContentComponent_UploadedVoiceOverFile : ERROR ==>", error);
      }
    }
  }

  MediaLibrarySharedComponent_ResetView() {
    this.lUserVisualsList = [];
    this.nAlreadyFetchedVisuals = 0;
    this.nUserVisualID = 0;
    this.nCurrentPage = 1;
    this.fUserVisualListForm = this.oFormBuilder.group({ email: this.sUserEmail, session_id: this.sSessionId, num_skip: 0, num_items: 10, visual_type: '', media_lib: this.sSelectedMediaLibrary });
  }
  AudioSidePanelComponent_SelectAudioFile(nIncomingIndex, nIncomingVisual) {
    console.log("nIncoming Selected Audio======>>>>", nIncomingVisual);
    this.oSelectedMusicFile = nIncomingVisual;
    this.oSelectedAudio = nIncomingIndex;
  }


  changeVisual(nIncomingIndex, nIncomingVisual: visual) {
    this.lUserVisualsList.forEach(ele => { ele.isSelected = false });
    nIncomingVisual.isSelected = true;

    this.oSelectedAudio = nIncomingVisual;
  }
  AudioSidePanelComponent_RevertMusicFile() {

    this.oMusicFileurl.emit(this.oMusicFile);
  }
  AudioSidePanelComponent_SaveMusicFileForVideo() {
    if (this.bTurnOff == false) {

      this.oMusicFileurl.emit(this.oSelectedAudio.visual_path);
    } else {
      this.oMusicFileurl.emit("");
    }

  }

  // /////////////////// Pagination Section Start /////////////////////

  MediaLibrarySharedComponent_DisplayMyMediaLibrary() {
    this.oWebSocketService.WebSocket_RegisterEvent('visual_count_result', this.MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse);

    this.MediaLibrarySharedComponent_GetUserAudioCount();
  }
  MediaLibrarySharedComponent_GetUserAudioCount() {
    this.fUserVisualCountForm.patchValue({ visual_type: 'audio' });
    this.oWebSocketService.WebSocket_SendMessage('visual_count_request', JSON.stringify(this.fUserVisualCountForm.value));
  }

  MediaLibrarySharedComponent_FetchPage(nIncommingPageNumber: number) {
    console.log("Incoming Page Number =====>>>>>", nIncommingPageNumber);
    if (nIncommingPageNumber !== this.nCurrentPage) {
      this.oSelectedAudio = "";
      this.MediaLibrarySharedComponent_ResetView();
      this.nCurrentPage = nIncommingPageNumber;
      let skipAudios = (nIncommingPageNumber - 1) * 30;
      if (this.oVisualType === 'pronto') {
        this.getProntoAudios(30, skipAudios);
      }
      else if (this.oVisualType === 'myassets') {
        this.getUserAudios(10, skipAudios);
      }
      else {
        this.nPageSize = 10 / this.nUserVisualsCount;
        let nPronto = Math.floor(this.nPageSize * 20);
        let nAudios = Math.floor(this.nPageSize * this.nUserAudiosCount);
        console.log("Audio to fetch ==> ", nAudios);
        console.log("pronto to fetch ==> ", nPronto);

        let prontoSkip = (nIncommingPageNumber - 1) * nPronto;
        let audioSkip = (nIncommingPageNumber - 1) * nAudios;
        console.log("Audio to Skip ==> ", audioSkip);
        console.log("Pronto to Skip ==> ", prontoSkip);
        this.getProntoAudios(nPronto, 0);
        this.getUserAudios(nAudios, audioSkip);
      }
    }
  }

  MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse = (IncomingMessage) => {
    if (IncomingMessage.errorMessage) {
      console.error('MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse : Error ==> ', JSON.parse(IncomingMessage.errorMessage));
    }
    else {
      let formatedIcomingMessage = JSON.parse(IncomingMessage);
      console.log('MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse : ', formatedIcomingMessage);
      if (formatedIcomingMessage.visual_type == 'audio') {
        this.nUserAudiosCount = formatedIcomingMessage.count;
      }
      this.nUserVisualsCount = 20 + this.nUserAudiosCount;
      console.log('MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse : Total visuals count ==> ', this.nUserVisualsCount);
      if (this.nUserVisualsCount) {
        this.oWebSocketService.WebSocket_UnRegisterEvent('visual_count_result', this.MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse);
      }
    }
  };
  drawPagination(nIncommingVisualCount: number) {
    this.lPagination = [];
    this.nPageSize = Math.ceil(nIncommingVisualCount / 30);
    for (let nPaginationIndex = 1; nPaginationIndex <= this.nPageSize; nPaginationIndex++) {
      this.lPagination.push(nPaginationIndex);
    }
    // console.log("Pagination List : ==> ",this.lPagination);
  }
  AudioSidePanelComponent_PlayAudio(oIncommingAudioUrl) {
    // console.log(oIncommingAudioUrl);
    if (this.bTurnOff == false){
      this.oAudio = new Audio();
      this.oAudio.src = oIncommingAudioUrl;
      this.oAudio.load();
      this.oAudio.play();
    }

  }
  AudioSidePanelComponent_StopAudio(oIncommingAudioUrl) {
    let filename = oIncommingAudioUrl.replace(/^.*(\\|\/|\:)/, '');
    if (this.oAudio.src.replace(/^.*(\\|\/|\:)/, '') == filename) {
      this.oAudio.pause();
      this.oAudio = new Audio();
      this.oAudio.src = oIncommingAudioUrl;
      this.oAudio.load();
      this.oAudio.pause();
    }
  }


  closeAudio(): void {
    this.onClose.emit();
  }
  turnOffMusic(val){
      this.bTurnOff = val;
  }
  selectGenre(gen){
    this.nCurrentPage = 1;
    this.selectedGenre = gen;
    this.getProntoAudios(30, 0);
  }
}
