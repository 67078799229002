import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CreateVideoManagerService } from 'src/app/Managers/create-video-manager.service';
import { VideoManagerService } from 'src/app/Managers/video-manager.service';

import { VideoService } from 'src/app/services/dataServices/video.service';
import { ErrorService } from 'src/app/services/error.service';
import { cSessionService } from 'src/app/services/session.service';
import { ProntoSettingService } from 'src/app/services/utils/pronto-setting.service';

@Component({
    selector: 'app-aspect-ratio-popup',
    templateUrl: './aspect-ratio-popup.component.html',
    styleUrls: ['./aspect-ratio-popup.component.scss'],
})
export class AspectRatioPopupComponent implements OnInit {
    // private readonly dialogRef: MatDialogRef<AspectRatioPopupComponent>;
    private readonly triggerElementRef: ElementRef;
    selectedAspectRatio = [];
    sSessionId = '';
    sUserEmail = '';
    videoAspectRatios = [];
    processingStart = false;
    hideHeader = false;
    callReponseType = true;
    constructor(
        public prontoSetting: ProntoSettingService,
        private videoService: VideoService,
        public oSessionService: cSessionService,
        private videoManager: CreateVideoManagerService,
        public videoManagerService: VideoManagerService,
        private errorMessage: ErrorService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private readonly dialogRef: MatDialogRef<AspectRatioPopupComponent>,
        _matDialogRef: MatDialogRef<AspectRatioPopupComponent>,
    ) {
        this.selectedAspectRatio = [];
        this.sSessionId = this.oSessionService.SessionService_GetSessionId();
        this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    }

    ngOnInit(): void {
        this.prontoSetting.systemAspects.map((item: any) => {
            item.selected = false;
        });
        this.prontoSetting.videoAspectRatioTabs
        this.selectedAspectRatio = [];
        let videoAlreadyCreatedAspects = Object.assign([], this.data.video.ars ? this.data.video.ars : [this.data.video.aspect_ratio]);

        if (this.data?.hideHeader) {
            this.hideHeader = this.data.hideHeader;
        }

        let indexParent = videoAlreadyCreatedAspects.findIndex((item) => item == this.data.video.aspect_ratio);
        if (indexParent == -1) {
            videoAlreadyCreatedAspects.push(this.data.video.aspect_ratio);
        }

        if (!videoAlreadyCreatedAspects || videoAlreadyCreatedAspects.length == 0) {
            videoAlreadyCreatedAspects = [];
            videoAlreadyCreatedAspects.push(this.data.video.aspect_ratio);
        }

        let filterAspectArray = this.prontoSetting.systemAspects.filter((item: any) => {
            return videoAlreadyCreatedAspects.indexOf(item.ratio_value) == -1;
        });
        this.videoAspectRatios = filterAspectArray;
    }
    close(data = undefined) {
        this.dialogRef.close(data);
    }

    selectAspectRatio(aspect) {
        aspect.selected = !aspect.selected;
    }

    async initiateResizeProcess() {

        let selectedAspects = this.videoAspectRatios.filter((item) => item.selected === true);
        let listProcessPromises = [];
        if (selectedAspects && selectedAspects.length > 0) {
            this.processingStart = true;
            selectedAspects.map((item) => {
                listProcessPromises.push(this.convertVideo(item.ratio_value));
            });
        }
        const allPromise = Promise.all(listProcessPromises);

        try {
            const values = await allPromise;
            let aspectList = [];
            if(values){
                values.forEach((item) => {
                    if(item != undefined){
                        this.processingStart = false;
                        aspectList.push(item.aspect_ratio);
                    }
                });
                this.resetAspectArray(aspectList);
            }

            // console.log(values); // [resolvedValue1, resolvedValue2]
        } catch (error) {
            // console.log(error); // rejectReason of any first rejected promise
        }
    }
    convertVideo(aspectRatio): Promise<any> {
        return new Promise((resolve, reject) => {
            let dataVideo = {
                email: this.sUserEmail,
                session_id: this.sSessionId,
                video_id: this.data.video.fpath,
                aspect_ratio: aspectRatio,
            };

            this.videoService
                .convertVideo(dataVideo)
                .then((result: any) => {
                    this.callReponseType = true;
                    this.startVideoGeneration(result, aspectRatio).then((result) => {
                        resolve(result);
                    });
                })
                .catch((error) => {
                    this.callReponseType = false;
                    this.errorMessage.handleError(error.errorMessage ? error.errorMessage : error, 'Video Resize Error', true, true);
                    reject(undefined);
                    // this.startVideoGeneration({ video_id: this.data.video.fpath }, aspectRatio).then((result) => {
                    //     resolve(result);
                    // });
                });
        });
    }

    startVideoGeneration(result: any, aspectRatio: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (result && result.video_id) {

                if(this.data.hideLoader == undefined || !this.data.hideLoader){
                    // this.data.video.status = "in-progress";
                }
                this.videoManager
                    .generateVideo(this.sUserEmail, this.sSessionId, result.video_id, false)
                    .then((video: any) => {
                        this.callReponseType = true;
                        this.videoService.monitorVideoGenerationProcessing(this.sUserEmail, this.sSessionId, video.gv_task_id, result.video_id, this.videoManagerService.videos);
                        this.processingStart = false;
                        resolve({ type: 'success', aspect_ratio: aspectRatio });
                    })
                    .catch((error) => {
                        this.callReponseType = false;
                        this.errorMessage.handleError(error.errorMessage ? error.errorMessage : error, 'Video Resize Error', true, true);
                        resolve({ type: 'error', aspect_ratio: aspectRatio });
                    });
            }
        });
    }

    resetAspectArray(selectedAspects: any) {
        this.close({ success: this.callReponseType, video: this.data.video, aspect: selectedAspects });
    }
}
