<div class="py-4 px-2 account-heading">
    <div class="row">
        <div class="col-md-8">
            <h3 class="weight-700 text-gray-2">Voice Overs</h3>
            <p class="mx-0 my-0 text-sm">Discover your voice. Choose from <span class="text-primary-4 fw-bold"> {{voiceStore.reamingVoices}} available voices</span> to amplify your content.
                Once a voice is selected, it's set—so make it count. Ready for an even richer selection? Elevate your
                experience by upgrading to unlock more voices and possibilities.</p>
        </div>
        <div class="col-md-4 text-end mt-4">
            <button href="javascript:void(0)" *ngIf="this.onboardinManger.profile.plan =='business'" class="weight-normal btn btn-primary btn-round-6 p-2 me-3" (click)="upgradeSelction()">Upgrade</button>
        </div>
    </div>
</div>

<app-browse-voice-over></app-browse-voice-over>