<p class="text-gray-3 text-xs">Add or Edit Keywords to Update Clips. This helps our AI find relevant clips and footage.</p>
            <div class="col-sm-12">
              <div class="formItm static_lable order_list mb-2 ms-0">
                <div class="createTag_wrapp pt-2 mt-1">
                  <figure class="my-1 mx-2">
                    <img src="../../../assets/images/svg/keyword tag.svg">
                  </figure>
                  <span class="tag mb-2" *ngFor="let keyterm of lKey_terms;let i = index;">{{keyterm}}
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" class="me-1 position-relative" width="10"
                      height="10" viewBox="0 0 24 24" style=" fill:#7A7A7A; left: 5px;"
                      (click)="removeKeyword(i)">
                      <path
                        d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z">
                      </path>
                    </svg>
                  </span>
                  <div class="tagfield createTag_wrappAdd" [ngClass]="bDisplayAddInputField?'d-inline-block':'d-none'">
                    <input class="add-Tag" maxlength="32" #AddInputTagElement onkeypress="return event.charCode != 32"
                      (focusout)="AddTagInputField()" (keydown)="addTagOnKeyEnter($event.keyCode)" [(ngModel)]="inputValue"
                      [ngModelOptions]="{standalone: true}" type="text"
                      (change)="getInputValue($event)"><input class="tag-btn" type="button"
                      value="Add" [disabled]="!inputValue" (click)="AddTagInputField()" toggle-class="#createTag_wrapp">
                  </div>
                  <a *ngIf="!bDisplayAddInputField" href="javascript:void(0)"
                    (click)="toggleBtn()"
                    class="text-nowrap text-sm text-primary-4 createTag_wrappAddBtn" toggle-class="#createTag_wrapp">
                    <svg class="me-1" width="12" height="12" viewBox="0 0 12 12" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.26087 0.391304C6.26087 0.175193 6.08568 0 5.86957 0C5.65345 0 5.47826 0.175193 5.47826 0.391304L5.47826 5.73913H0.391304C0.175193 5.73913 0 5.91432 0 6.13043C0 6.34655 0.175193 6.52174 0.391304 6.52174H5.47826L5.47826 11.6087C5.47826 11.8248 5.65345 12 5.86956 12C6.08568 12 6.26087 11.8248 6.26087 11.6087L6.26087 6.52174H11.6087C11.8248 6.52174 12 6.34655 12 6.13043C12 5.91432 11.8248 5.73913 11.6087 5.73913H6.26087L6.26087 0.391304Z"
                        fill="#FF8369" />
                    </svg>
                    Add a Keyword
                  </a>
                </div>
              </div>
            </div>