<div class="row">
  <div class="col-sm-12 col-md-12 col-lg-12 px-5">
    <div class="col-md-6 col-lg-6 mx-auto">
      <div *ngIf="bDisplaySuccessMessage || this.statusResult == 'success'"
        class="alert alert-success d-flex align-items-center alert-dismissible fade show col-md-12" role="alert">
        <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14 0C6.26801 0 0 6.26801 0 14V15C0 22.732 6.26801 29 14 29V29C21.732 29 28 22.732 28 15V14C28 6.26801 21.732 0 14 0V0Z"
            fill="#00C599" />
          <g>
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M21.0045 9.94163C21.178 10.1234 21.1713 10.4112 20.9894 10.5846L12.0848 19.0732C11.9088 19.2409 11.632 19.2408 11.4562 19.0729L7.01176 14.8286C6.83006 14.6551 6.82353 14.3672 6.99718 14.1857C7.17082 14.0041 7.45889 13.9976 7.64059 14.1711L11.7709 18.1154L20.3611 9.92649C20.543 9.75313 20.8311 9.75991 21.0045 9.94163Z"
              fill="#00C599" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M19.9219 9.43247C20.3598 9.01505 21.0456 9.03012 21.4652 9.46955C21.8975 9.9224 21.8792 10.6484 21.4281 11.0784L12.5234 19.5671C12.0992 19.9714 11.4404 19.9711 11.0166 19.5664L6.57212 15.322C6.12133 14.8916 6.10371 14.1656 6.53641 13.7132C6.95627 13.2741 7.64212 13.2596 8.07967 13.6775L11.771 17.2026L19.9219 9.43247ZM7.45106 14.3352L7.45084 14.335Z"
              fill="white" />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="15.5556" height="16.1111" fill="white" transform="translate(6.22266 6.44434)" />
            </clipPath>
          </defs>
        </svg>
        <div>
          {{
          sDisplayResponseMessage || this.statusResult
          ? "Video has been shared successfully"
          : ""
          }}
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0.546609 15.7333C0.406624 15.7333 0.273304 15.68 0.166649 15.58C-0.0399958 15.3733 -0.0399958 15.0333 0.166649 14.8267L7.11925 7.86667L0.153317 0.906667C0.0533277 0.806667 0 0.673333 0 0.533333C0 0.393333 0.0533277 0.26 0.153317 0.16C0.259972 0.0533333 0.393292 0 0.533277 0C0.673262 0 0.806581 0.0533333 0.913237 0.153333L7.8725 7.11333L14.8251 0.153333C14.9251 0.0533333 15.0584 0 15.1984 0C15.3384 0 15.4717 0.0533333 15.5784 0.153333C15.785 0.36 15.785 0.7 15.5784 0.906667L8.62575 7.86667L15.5717 14.8133C15.6717 14.9133 15.725 15.0467 15.725 15.1867C15.725 15.3267 15.6717 15.46 15.5717 15.5667C15.4717 15.6733 15.3384 15.72 15.1917 15.72C15.0451 15.72 14.9184 15.6667 14.8118 15.5667L7.8725 8.62L0.919903 15.58C0.819913 15.68 0.686594 15.7333 0.546609 15.7333Z"
                fill="#FF8369" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="timelineViewOnProntoTop">
  <h3 class="primaryFont weight-700 text-gray-2 text-md">
    Connect your accounts
  </h3>
  <p class="text-xs text-gray-2 mb-0">Share your Prontopia creations to social</p>
  <div class="row">
    <div class="col-sm-12 social-channels-hover col-md-12 col-lg-6 ps-0">
      <div *ngIf="isBrandVideo" [ngClass]="
        brandSocialNetworkConnection.youtube
            ? 'cursorClass'
            : ''
        " class="d-flex select-channels p-4 me-4 mt-3">
        <img (click)=" brandSocialNetworkConnection.youtube ? shareToYoutube() :''" width="40px" height="40px"
          src="../assets/images/dashboardVideo/Youtube.png" alt="sc" />
        <p (click)="brandSocialNetworkConnection.youtube ? shareToYoutube() : '' " class="px-3">
          <b class="d-block">Youtube
            <img *ngIf="brandSocialNetworkConnection.youtube" width="12px" height="12px"
              src="../assets/images/dashboardVideo/verifyCheck.png" alt="sc" /></b>
          <span *ngIf="brandSocialNetworkConnection.youtube" class="text-xs text-primary-1 ">
            Your Youtube account is connected to </span>
          <span *ngIf="!brandSocialNetworkConnection.youtube" class="text-danger">
            Connect your YouTube account to allow Prontopia to publish videos to
            your channel. </span>
          <span *ngIf="brandSocialNetworkConnection.youtube" class="text-xs text-primary-1 "> {{ brandDetail?.name ?
            brandDetail.name : "" }}.</span>
          <span *ngIf="!brandSocialNetworkConnection.youtube" class="text-xs text-primary-1 "> {{ brandDetail?.name ?
            brandDetail.name : "" }}</span>
        </p>
      </div>
      <div *ngIf="!isBrandVideo" [ngClass]="
          connected_social_platforms && connected_social_platforms.google
            ? 'cursorClass'
            : ''
        " class="d-flex select-channels p-4 pe-0 me-4 mt-3">
        <img (click)="
            TimelineViewProntoShareComponent_ShareVideoOnSocialChannel('google')
          " width="40px" height="40px" src="../assets/images/dashboardVideo/Youtube.png" alt="sc" />
        <p (click)="
            TimelineViewProntoShareComponent_ShareVideoOnSocialChannel('google')
          " class="px-3">
          <b class="d-block">Youtube
            <img *ngIf="
                connected_social_platforms && connected_social_platforms.google
              " width="12px" height="12px" src="../assets/images/dashboardVideo/verifyCheck.png" alt="sc" /></b>
          <span *ngIf="
              connected_social_platforms && connected_social_platforms.google
            " class="text-xs text-primary-1 d-block">
            Your Youtube account is connected to Prontopia.</span>
          <span class="text-danger" *ngIf="
              connected_social_platforms && !connected_social_platforms.google
            ">
            Connect your YouTube account to allow Prontopia to publish videos to
            your channel.
          </span>
          <span *ngIf="
              connected_social_platforms && connected_social_platforms.google
            " class="text-xs text-primary-1 d-block">{{ oUserName }}</span>
        </p>
        <a *ngIf="
            connected_social_platforms && !connected_social_platforms.google
          " (click)="
            TimelineViewProntoShareComponent_SocialMediaChannelsLink(
              'google',
              $event
            )
          " class="btn btn-secondary btn-round-6 d-inline-block small-pad ms-2 text-xs px-1">Connect with Youtube</a>
        <a *ngIf="
            connected_social_platforms && connected_social_platforms.google
          " href="javascript:void(0)" (click)="isDisconnectModal('google')"
          class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 small-btn btnShadow">Disconnect</a>
      </div>
    </div>

    <div class="col-sm-12 social-channels-hover col-md-12 col-lg-6 ps-0">
      <div *ngIf="!isBrandVideo" [ngClass]="
          connected_social_platforms && connected_social_platforms.facebook
            ? 'cursorClass'
            : ''
        " class="d-flex select-channels p-4 me-4 mt-3">
        <img (click)="
            TimelineViewProntoShareComponent_ShareVideoOnSocialChannel(
              'facebook'
            )
          " width="40px" height="40px" src="../assets/images/dashboardVideo/Facebook.png" alt="sc" />
        <p (click)="
            TimelineViewProntoShareComponent_ShareVideoOnSocialChannel(
              'facebook'
            )
          " class="px-3">
          <b class="d-block">Facebook<img *ngIf="
                connected_social_platforms &&
                connected_social_platforms.facebook
              " width="12px" height="12px" src="../assets/images/dashboardVideo/verifyCheck.png" alt="sc" /></b>
          <span *ngIf="
              connected_social_platforms && connected_social_platforms.facebook
            " class="text-xs text-primary-1 d-block">
            Your Facebook account is connected to Prontopia.</span>
          <span class="text-danger" *ngIf="
              connected_social_platforms && !connected_social_platforms.facebook
            ">
            Connect your Facebook account to allow Prontopia to publish videos to
            your channel.
          </span>
          <span *ngIf="
              connected_social_platforms && connected_social_platforms.facebook
            " class="text-xs text-primary-1 d-block">{{ oUserName }}</span>
        </p>
        <a *ngIf="
            connected_social_platforms && !connected_social_platforms.facebook
          " (click)="
            TimelineViewProntoShareComponent_SocialMediaChannelsLink(
              'facebook',
              $event
            )
          " class="btn btn-secondary btn-round-6 d-inline-block small-pad ms-2 text-xs px-1">Connect with Facebook</a>
        <a *ngIf="
            connected_social_platforms && connected_social_platforms.facebook
          " href="javascript:void(0)" (click)="isDisconnectModal('facebook')"
          class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 small-btn btnShadow">Disconnect</a>
      </div>
      <div *ngIf="isBrandVideo" [ngClass]="
          connected_social_platforms && connected_social_platforms.facebook
            ? 'cursorClass'
            : ''
        " class="d-flex select-channels social-channels-hover p-4 me-4 mt-3">
        <img (click)="shareToFacebook()" width="40px" height="40px" src="../assets/images/dashboardVideo/Facebook.png"
          alt="sc" />
        <p class="px-3" (click)="shareToFacebook()">
          <b class="d-block">Facebook
            <img *ngIf="brandSocialNetworkConnection.fb" width="12px" height="12px"
              src="../assets/images/dashboardVideo/verifyCheck.png" alt="sc" /></b>
          <span *ngIf="brandSocialNetworkConnection.fb" class="text-xs text-primary-1 d-block">
            Your Facebook account is connected to <a class="text-primary-1">'{{ brandDetail?.name ? brandDetail.name :
              "" }}'
            </a>.</span>
          <span *ngIf="!brandSocialNetworkConnection.fb" class="text-danger">
            Request Admin to Connect your Brand
            <a class="text-primary-1">'{{ brandDetail?.name ? brandDetail.name : "" }}'</a>
            Facebook account to publish your videos directly to your channel.
          </span>
          <span *ngIf="
              connected_social_platforms && connected_social_platforms.facebook
            " class="text-xs text-primary-1 d-block">{{ oUserName }}</span>
        </p>
      </div>
    </div>

    <div class="col-sm-12 social-channels-hover col-md-12 col-lg-6 ">
      <div class="d-flex select-channels p-4 pe-0 me-4 mt-3 ps-2">
        <img (click)="connected_social_platforms.linkedin
                  ? TimelineViewProntoShareComponent_ShareVideoOnSocialChannel(
                  'linkedin')
                  : ''" [ngClass]="connected_social_platforms &&
                  connected_social_platforms.linkedin
                  ? 'cursorClass'
                  : ''" src="../../../../assets/images/icons/linkedin.png" alt="Linkedin" height="40px" width="40px"
          class="s-icon" />
        <p (click)="connected_social_platforms.linkedin
                  ? TimelineViewProntoShareComponent_ShareVideoOnSocialChannel(
                  'linkedin'
                  )
                  : ''" [ngClass]="connected_social_platforms &&
                  connected_social_platforms.linkedin
                  ? 'cursorClass'
                  : ''" class="px-3">
          <b class="d-block">LinkedIn
            <img *ngIf="connected_social_platforms && connected_social_platforms.linkedin" width="12px" height="12px"
              src="../assets/images/dashboardVideo/verifyCheck.png" alt="sc" /></b>
          <span *ngIf="isBrandVideo &&  connected_social_platforms && connected_social_platforms.linkedin" class="text-xs
                    text-primary-1 d-block">
            Your LinkedIn account is connected to <a class="text-xs
            text-primary-1 d-block">`{{ brandDetail?.name ?brandDetail.name : "Pronto" }}`.</a></span>
          <span *ngIf="!isBrandVideo &&  connected_social_platforms && connected_social_platforms.linkedin" class="text-xs
                    text-primary-1 d-block">
            Your LinkedIn account is connected to '{{ brandDetail?.name ? brandDetail.name : "Pronto" }}'.</span>
          <span class="text-danger" *ngIf="!isBrandVideo && connected_social_platforms &&
                    !connected_social_platforms.linkedin">
            Connect your LinkedIn account to allow Prontopia to publish your
            videos directly to your channel.
          </span>
          <span class="text-danger" *ngIf="isBrandVideo && connected_social_platforms &&
                    !connected_social_platforms.linkedin">

            Connect your brand '{{ brandDetail?.name ? brandDetail.name : "" }}' LinkedIn account to publish your
            videos directly to your channel.
          </span>
          <span *ngIf="!isBrandVideo && connected_social_platforms &&
                    connected_social_platforms.linkedin" class="text-xs
                    text-primary-1 d-block">{{ oUserName }}</span>

        </p>
        <a *ngIf="!isBrandVideo && connected_social_platforms &&
                  !connected_social_platforms.linkedin" (click)="TimelineViewProntoShareComponent_SocialMediaChannelsLink(
                  'linkedin', $event
                  )" class="btn btn-secondary btn-round-6 text-xs w-172 small-pad
                  ms-5">Connect with LinkedIn</a>
        <a *ngIf="!isBrandVideo &&  connected_social_platforms &&
                  connected_social_platforms.linkedin" href="javascript:void(0)" data-bs-dismiss="modal"
          (click)="isDisconnectModal('linkedin')" class="btn weight-600 text-xs btn-outline-gray-radius
                  text-gray-2 small-btn btnShadow">Disconnect</a>
      </div>
    </div>
    <div *ngIf="isBrandVideo" [ngClass]="connected_social_platforms &&
                connected_social_platforms.instagram
                ? 'cursorClass'
                : ''" class="col-sm-12 social-channels-hover col-md-12 col-lg-6 ps-0">
      <div class="d-flex select-channels p-4 me-4 mt-3">
        <img (click)="shareToInstagram()" width="40px" height="40px" src="../assets/images/dashboardVideo/Instagram.png"
          alt="sc" />
        <p class="px-3" (click)="shareToInstagram()">
          <b class="d-block">Instagram
            <img *ngIf="brandSocialNetworkConnection.instagram" width="12px" height="12px"
              src="../assets/images/dashboardVideo/verifyCheck.png" alt="sc" /></b>
          <span
            [ngClass]="{'text-danger' : !brandSocialNetworkConnection.instagram , 'text-xs text-primary-1' : brandSocialNetworkConnection.instagram}"
            class=" text-xs text-primary-1 ng-star-inserted"
            [innerHTML]="getSocialConnectionMessage(brandSocialNetworkConnection.instagram,'Instagram')">
          </span>
        </p>
        <a *ngIf="!isBrandVideo && connected_social_platforms &&
                  !connected_social_platforms.instagram" (click)="TimelineViewProntoShareComponent_ShareVideoOnSocialChannel(
                  'instagram'
                  )" class="btn btn-secondary btn-round-6 text-xs w-172 small-pad
                  ms-5">Connect with Instagram</a>
        <a *ngIf="!isBrandVideo &&  connected_social_platforms &&
                  connected_social_platforms.instagram" href="javascript:void(0)" data-bs-dismiss="modal"
          (click)="isDisconnectModal('instagram')" class="btn weight-600 text-xs btn-outline-gray-radius
                  text-gray-2 small-btn btnShadow">Disconnect</a>
      </div>
    </div>
    <!-- <div class="col-sm-12 social-channels-hover col-md-12 col-lg-6">
      <div
        [ngClass]="
          connected_social_platforms && connected_social_platforms.linkedin
            ? 'cursorClass'
            : ''
        "
        class="d-flex select-channels p-4 me-4"
      >
        <img
          (click)="
            TimelineViewProntoShareComponent_ShareVideoOnSocialChannel(
              'linkedin'
            )
          "
          src="../assets/images/dashboardVideo/Linkedin.png"
          alt="Linkedin"
          height="40px"
          width="40px"
          class="s-icon"
        />
        <p
          (click)="
            TimelineViewProntoShareComponent_ShareVideoOnSocialChannel(
              'linkedin'
            )
          "
          class="px-3"
        >
          <b class="d-block"
            >LinkedIn<img
              *ngIf="
                connected_social_platforms &&
                connected_social_platforms.linkedin
              "
              width="12px"
              height="12px"
              src="../assets/images/dashboardVideo/verifyCheck.png"
              alt="sc"
          /></b>
          <span
            *ngIf="
              connected_social_platforms && connected_social_platforms.linkedin
            "
            class="text-xs text-primary-1 d-block"
          >
            Your LinkedIn account is connected to Prontopia.</span
          >
          <span
            *ngIf="
              connected_social_platforms && !connected_social_platforms.linkedin
            "
          >
            Connect your LinkedIn account to allow Prontopia to publish videos to
            your channel.
          </span>
          <span
            *ngIf="
              connected_social_platforms && connected_social_platforms.linkedin
            "
            class="text-xs text-primary-1 d-block"
            >{{ oUserName }}</span
          >
        </p>
        <a
          *ngIf="
            connected_social_platforms && !connected_social_platforms.linkedin
          "
          (click)="
            TimelineViewProntoShareComponent_SocialMediaChannelsLink(
              'linkedin',
              $event
            )
          "
          class="btn btn-secondary btn-round-6 d-inline-block small-pad ms-2 text-xs px-1"
          >Connect with LinkedIn</a
        >
        <a
          *ngIf="
            connected_social_platforms && connected_social_platforms.linkedin
          "
          href="javascript:void(0)"
          (click)="isDisconnectModal('linkedin')"
          class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 small-btn btnShadow"
          >Disconnect</a
        >
      </div>
    </div> -->
  </div>
</div>

<div class="modal fade" id="share-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body p-4">
        <!-- if no social page -->
        <div *ngIf="pages.length == 0" class="no-page">
          <h5>Currently no page is available!</h5>
          <p class="text-sm text-gray-3 pt-1 pb-4">
            There is no page created till now. Please create a page first.
          </p>
        </div>
        <!-- social pages -->
        <div class="social-page" *ngIf="pages.length">
          <h5>Select page where you want to share your video</h5>
          <p class="text-sm text-gray-3 pt-1 pb-4">
            The files will be permanently share. You can't undo this action
          </p>
          <div>
            <div class="dropdown dropdownSelect pb-4">
              <label class="text-sm text-gray-3">Select your preferred page</label>
              <button class="dropdown-toggle text-md" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {{ nIncomingPage.name }}
              </button>
              <ul class="dropdown-menu dropdownMenu">
                <li *ngFor="let page of pages" [class.active]="page.name == nIncomingPage.name" (click)="
                    DashboardSocialMediaComponent_SelectPageForShare(page)
                  ">
                  <a class="dropdown-item text-md" href="javascript:void(0)">{{
                    page.name
                    }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <a class="btn btn-secondary btn-round-6 text-xs" *ngIf="pages.length" data-bs-dismiss="modal"
          (click)="DashboardSocialMediaComponent_ShareVideo()">Share</a>
        <a href="javascript:void(0)" id="sidebarCollapse" data-bs-dismiss="modal" class="ms-4 mt-3 text-gray-3 text-sm"
          *ngIf="pages.length">Cancel</a>
        <a class="btn btn-secondary btn-round-6 text-xs" *ngIf="pages.length == 0" data-bs-dismiss="modal">Cancel</a>
      </div>
    </div>
  </div>
</div>
<!-- Modal pop-up End-->
<div class="modal fade" id="share-modal-Youtube" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body p-4">
        <!-- if no social page -->
        <div class="no-page">
          <h5>Are you sure you want to share the video on Youtube</h5>
          <p class="text-sm text-gray-3 pt-1 pb-4">
            The files will be permanently shared. You can't undo this action
          </p>
        </div>
        <!-- social pages -->
        <a class="btn btn-secondary btn-round-6 text-xs" data-bs-dismiss="modal"
          (click)="DashboardSocialMediaComponent_ShareVideoOnYoutube()">Share</a>
        <a href="javascript:void(0)" id="sidebarCollapse" data-bs-dismiss="modal"
          class="ms-4 mt-3 text-gray-3 text-sm">Cancel</a>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="share-modal-linkedin" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body p-4">
        <!-- if no social page -->
        <div class="no-page">
          <h5>Are you sure you want to share the video on LinkedIn</h5>
          <p class="text-sm text-gray-3 pt-1 pb-4">
            The files will be permanently shared. You can't undo this action
          </p>
        </div>
        <!-- social pages -->
        <a class="btn btn-secondary btn-round-6 text-xs" data-bs-dismiss="modal"
          (click)="DashboardSocialMediaComponent_ShareVideoOnLinkedIn()">Share</a>
        <a href="javascript:void(0)" id="sidebarCollapse" data-bs-dismiss="modal"
          class="ms-4 mt-3 text-gray-3 text-sm">Cancel</a>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="share-modal-instagram" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body p-4">
        <!-- if no social page -->
        <div class="no-page">
          <h5>Are you sure you want to share the video on Instagram</h5>
          <p class="text-sm text-gray-3 pt-1 pb-4">
            The files will be permanently shared. You can't undo this action
          </p>
        </div>
        <!-- social pages -->
        <a class="btn btn-secondary btn-round-6 text-xs" data-bs-dismiss="modal"
          (click)="DashboardSocialMediaComponent_ShareVideoOnLinkedIn()">Share</a>
        <a href="javascript:void(0)" id="sidebarCollapse" data-bs-dismiss="modal"
          class="ms-4 mt-3 text-gray-3 text-sm">Cancel</a>
      </div>
    </div>
  </div>
</div>

<!--  pop-up Start-->

<nz-modal [(nzVisible)]="disconnectMediaPopup" nzCentered [nzWidth]="566" [nzFooter]="null" [nzClosable]="true"
  (nzOnCancel)="closeActivateModal()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <h5>
          Are you sure you want to disconnect
          {{ this.socialMediaName | titlecase }}?
        </h5>
        <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">
          You'll no longer be able to share your videos straight to
          {{ this.socialMediaName | titlecase }}.
        </p>
        <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" (click)="closeActivateModal()">Cancel</a>
        <a href="javascript:void(0)" id="sidebarCollapse" class="mt-3 text-gray-3 text-sm" (click)="
            TimelineViewProntoShareComponent_DisconnectSocialChannel($event)
          ">Disconnect</a>
      </div>
    </div>
  </div>
</nz-modal>

<!--  pop-up End-->