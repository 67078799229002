<app-header></app-header>
    <section class="accountSec main-body bg-gray-7 h-100">
        <form>
        <div class="container">
            <ul class="breadcrumb my-3">
                <li><a href="#">Business Profile</a></li>
                <li>Franchisees</li>
            </ul>
            <div class=" br12 bg-white page-shadow curve-bg h-80vh">
                <div class="py-4 px-4 account-heading">
                    <div class="row">
                        <div class="col-md-12">
                            <h3 class="weight-700 text-gray-2">Business Profile</h3>
                            <p class="mx-0 my-0 text-sm">A complete profile will help us to understand your business and provide you with the best videos.</p>
                        </div>

                    </div>
                </div>
            <div class="accountSecInner">
                <div class="accountSecNav py-5">
                    <app-business-sidebar></app-business-sidebar>
                </div>
                <div class="accountSecContent py-5 pe-4 pb-2">
                    <div class="video-header row m-0 px-2">
                        <p class="text-lg text-gray-2 pb-1 px-0 col-lg-8 mb-0">
                            <a routerLink="/business-profile/franchesees" class="me-2">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.01519 15.1432C8.21658 15.3392 8.21658 15.657 8.01519 15.853C7.81379 16.049 7.48727 16.049 7.28588 15.853L0.55383 9.30125C-0.18461 8.58259 -0.18461 7.41741 0.55383 6.69874L7.28588 0.146999C7.48727 -0.0489997 7.81379 -0.0489997 8.01519 0.146999C8.21658 0.342998 8.21658 0.660776 8.01519 0.856774L1.28314 7.40852C1.2526 7.43824 1.22484 7.46964 1.19986 7.50241L15.4823 7.50241C15.7682 7.50241 16 7.72627 16 8.00242C16 8.27856 15.7682 8.50242 15.4823 8.50242L1.20358 8.50243C1.22759 8.53345 1.25411 8.56323 1.28314 8.59148L8.01519 15.1432Z" fill="#404243"/>
                                </svg>
                            </a>
                            Subway City A
                        </p>
                    </div>
                    <div class="container mx-0 px-0 business-media" id="container-view">
                        <div class="row filter-post">
                            <div class="col-md-12">
                                <ul class="breadcrumb-tabs nav mx-2">
                                    <li class="nav-item">
                                        <a routerLink="/business-profile/franchesees/account" class="nav-link active">Account</a>
                                    </li>
                                    <li class="nav-item">
                                        <a routerLink="/business-profile/franchesees/published-video" class="nav-link">Published Video</a>
                                    </li>
                                    <li class="nav-item">
                                        <a routerLink="/business-profile/franchesees/media-library" class="nav-link">Media Library</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div class="row p-0 m-0 mt-4">
                            <div class="col-md-4">
                                <div class="formItm">
                                    <input id="busName" type="text" value="Subway City A"
                                        placeholder="Subway City A" name="textfeild" required>
                                    <label for="busName" class="text-sm">Franchisee Name</label>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="formItm">
                                    <input id="userName" type="text" value="Estelle Bates"
                                        placeholder="Estelle Bates" name="textfeild" required>
                                    <label for="userName" class="text-sm">User Name</label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="formItm">
                                    <input id="usermail" type="email" value="Si@ja.net"
                                        placeholder="Si@ja.net" name="textfeild" required>
                                    <label for="usermail" class="text-sm">User Email</label>
                                </div>
                            </div>

                            <div class="col-md-6 px-4">
                                <div class="row mt-1 ac-overview">
                                    <div class="col-md-10 px-0">
                                        <p class="text-lg text-gray-2 pb-0 mb-2">Email Preferences</p>
                                        <p class="text-xs text-gray-3 pb-1">The choice is yours. Decide if you'd like to receive emails from us.</p>
                                    </div>
                                    <div class="col-md-2 text-end px-0 pe-4">
                                        <a href="#" class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2">Unsbscribe</a>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="row p-0 m-0 mt-3 mb-1">
                            <div class="col-sm-12 col-md-12 col-lg-12">

                                <p class="text-lg text-gray-2 pt-3 m-0">Connected Social Account</p>
                                <p class="text-xs text-gray-3 py-1">Services that you use to Connected with social Account</p>


                                <div class="row mt-4">
                                    <div class="col-sm-6col-md-6 col-lg-6">

                                        <div class="d-flex select-channels">
                                            <img width="40px" height="40px" src="../assets/images/dashboardVideo/Facebook.png" alt="sc">
                                            <p class="px-3">
                                                <b class="d-block">Facebook<img width="12px" height="12px" src="../assets/images/dashboardVideo/verifyCheck.png" alt="sc"></b>
                                                Your Facebook account is connected to Prontopia. <a href="#">Subway City A</a>
                                            </p>
                                        </div>
                            </div>
                            <div class="col-sm-6col-md-6 col-lg-6">
                                <div class="d-flex select-channels">
                                    <img width="40px" height="40px" src="../assets/images/dashboardVideo/Instagram.png" alt="sc">
                                    <p class="px-3">
                                        <b class="d-block">Instagram<img width="12px" height="12px" src="../assets/images/dashboardVideo/verifyCheck.png" alt="sc"></b>
                                        Your Instagram account is connected to Prontopia. <a href="#">Subway City A</a>

                                    </p>

                                </div>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div class="row p-0 m-0 mt-4 pt-2 border-top">
                            <div class="col-sm-12 col-md-12 col-lg-12">

                                <p class="text-lg text-gray-2 pt-3 m-0">Billing Address</p>
                                <p class="text-xs text-gray-3 py-1 mb-2">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium</p>
                                <div class="row">
                                    <div class="col-sm-6col-md-6 col-lg-6">
                                        <div class="address-box">

                                            <p class="px-3 text-xs text-gray-3 m-0">
                                                <span class="d-block text-lg text-gray-2 pb-2">Address</span>
                                                Family Chiropractic center of Richmond<br>
                                                123 Main Street City, State 0000

                                            </p>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
               </div>
            </div>

        </div>
    </form>
    </section>