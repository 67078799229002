<app-header></app-header>

    <section class="page pageNoSide accountSec main-body bg-gray-7 h-100">
        <div class="container">
            <ul class="breadcrumb my-3">

                <li class="nav-item" role="presentation"><a href="javascript:void(0)">Manage Account</a></li>
                <li class="nav-item" role="presentation">{{session.accountSettingHeaderName}}</li>
            </ul>
            <div class=" br12 bg-white page-shadow h-80vh d-flex flex-column curve-bg pb-0">
                <div class="accountSecInner flex-grow-1 row" id="row-main">

                    <app-business-sidebar class="col-md-3 pe-0 pb-0 position-relative sidebar" id="sidebar-left"></app-business-sidebar>
                    <div class="col-md-9 border-start ps-0" id="content">
                        <router-outlet></router-outlet>
                    </div>
                </div>

            </div>
        </div>
    </section>


