import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'removeCopyOfLocationFields' })
export class RemoveCopyOfLocationFields implements PipeTransform {
    transform(list : any[]): any {
        if (list) {
            let listNoCopy =  list.filter( (item) => item.copy_of == undefined);
            return listNoCopy;
        } else {
            return list;
        }

    }
}
