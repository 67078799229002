import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { cSessionService } from './../../services/session.service';
import { VideoManagerService } from './../../Managers/video-manager.service';
import { cWebSocketService } from './../../services/websocket.service';
import { differenceInCalendarDays } from 'date-fns';
declare var $: any;
@Component({
    selector: 'app-scheduled-popup',
    templateUrl: './scheduled-popup.component.html',
    styleUrls: ['./scheduled-popup.component.scss'],
})
export class ScheduledPopupComponent implements OnInit, OnChanges {
    @Input() lInputClosePopup;
    sSelectRange: any = ['Every Week', 'Once a Month', 'Every Year', 'On Specific Dates'];
    fScheduledVideoForm: FormGroup;
    sSessionId: any;
    sUserEmail: any;
    sVideoId: any;
    lWeekDay: any = [];
    lMonthName: any = [];
    lMonthDays: any = [];
    FormatedDateArray: any = [];
    automateMonthlyDays = [
        { name: 1, checked: false },
        { name: 2, checked: false },
        { name: 3, checked: false },
        { name: 4, checked: false },
        { name: 5, checked: false },
        { name: 6, checked: false },
        { name: 7, checked: false },
        { name: 8, checked: false },
        { name: 9, checked: false },
        { name: 10, checked: false },
        { name: 11, checked: false },
        { name: 12, checked: false },
        { name: 13, checked: false },
        { name: 14, checked: false },
        { name: 15, checked: false },
        { name: 16, checked: false },
        { name: 17, checked: false },
        { name: 18, checked: false },
        { name: 19, checked: false },
        { name: 20, checked: false },
        { name: 21, checked: false },
        { name: 22, checked: false },
        { name: 23, checked: false },
        { name: 24, checked: false },
        { name: 25, checked: false },
        { name: 26, checked: false },
        { name: 27, checked: false },
        { name: 28, checked: false },
        { name: 29, checked: false },
        { name: 30, checked: false },
        { name: 31, checked: false },
    ];
    SelectDate: any = '';
    bToggleDaysDropdown: boolean = false;
    bEnableSetScheduleButton: boolean = false;
    sDisplayErrorMessage: string = '';
    bDisplayErrorMessage: boolean = false;
    bDisplaySuccesMessage: boolean = false;
    nClosePopup: any = 0;

    public listdates: string[] = [''];
    @Output() onClose = new EventEmitter<any>();

    schedule_id: string = undefined;
    public selectduration: string = 'Select Duration';

    public timeRange: string = 'morning';

    public lSelectedWeekDay = [
        { name: 'M', value: 'monday', checked: false },
        { name: 'T', value: 'tuesday', checked: false },
        { name: 'W', value: 'wednesday', checked: false },
        { name: 'T', value: 'thursday', checked: false },
        { name: 'F', value: 'friday', checked: false },
        { name: 'S', value: 'saturday', checked: false },
        { name: 'S', value: 'sunday', checked: false },
    ];
    public lSelectedMonth = [
        { name: 'January', value: 1, checked: false },
        { name: 'February', value: 2, checked: false },
        { name: 'March', value: 3, checked: false },
        { name: 'April', value: 4, checked: false },
        { name: 'May', value: 5, checked: false },
        { name: 'June', value: 6, checked: false },
        { name: 'July', value: 7, checked: false },
        { name: 'August', value: 8, checked: false },
        { name: 'September', value: 9, checked: false },
        { name: 'October', value: 10, checked: false },
        { name: 'November', value: 11, checked: false },
        { name: 'December', value: 12, checked: false },
    ];
    constructor(
        private oFormBuilder: FormBuilder,
        private oSessionService: cSessionService,
        private oDatepipe: DatePipe,
        private oRouter: Router,
        public videoManager: VideoManagerService,
    ) {}
    ngOnChanges() {
        this.nClosePopup = 0;
        this.nClosePopup = this.lInputClosePopup;
        // console.log("ng on changes called !!!!!");
        if (this.nClosePopup > 0) {
            this.ScheduledVideoComponent_ResetView();
            document.getElementById('close').click();
            this.nClosePopup = 0;
        }
    }
    ngOnInit() {
        this.InitializeJquery();
        this.sSessionId = this.oSessionService.SessionService_GetSessionId();
        this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
        // console.log(this.videoManager.selectedVideo);
        this.sVideoId = this.videoManager.selectedVideo?.fpath
            ? this.videoManager.selectedVideo.fpath
            : this.videoManager.selectedVideo.schedule?.video_id;

        this.ScheduledVideoComponent_InitializeUserProfileForm();

        this.EditMode();
    }
    today = new Date();
    disabledDate = (current: Date): boolean =>
        // Can not select days before today and today
        differenceInCalendarDays(current, this.today) < 0;

    private EditMode() {
        console.log(this.videoManager.selectedVideo);
        if (this.videoManager.selectedVideo.schedule) {
            console.log(this.videoManager.selectedVideo.schedule);
            this.schedule_id = this.videoManager.selectedVideo.schedule.schedule_id;
            this.selectduration = this.videoManager.selectedVideo.schedule.interval;
            this.ScheduledVideoComponent_SelectAutomateInterval(this.videoManager.selectedVideo.schedule.interval);
            this.timeRange = this.videoManager.selectedVideo.schedule.time_range;
            this.ScheduledVideoComponent_SelectTimeRange(this.videoManager.selectedVideo.schedule.time_range);
            if (this.videoManager.selectedVideo.schedule.week_days) {
                this.lSelectedWeekDay.forEach((week: any, index) => {
                    this.videoManager.selectedVideo.schedule.week_days.forEach((ele) => {
                        if (week.value == ele) {
                            setTimeout(() => {
                                //this.lSelectedWeekDay[index].checked = true;
                                this.ScheduledPopupComponent_lSelectedWeekDayValue(week.value);
                            }, 10);
                        }
                    });
                });
            }

            if (this.videoManager.selectedVideo.schedule.given_months) {
                this.videoManager.selectedVideo.schedule.given_months.forEach((ele: any) => {
                    this.lSelectedMonth.forEach((day: any) => {
                        if (day.value == ele) {
                            setTimeout(() => {
                                //day.checked = true;
                                this.ScheduledPopupComponent_lSelectedMonth(day.name);
                            }, 10);
                        }
                    });
                });
            }
            if (this.videoManager.selectedVideo.schedule.given_dates) {
                this.listdates = this.videoManager.selectedVideo.schedule.given_dates;
            }
            if (this.videoManager.selectedVideo.schedule.given_days) {
                this.videoManager.selectedVideo.schedule.given_days.forEach((ele) => {
                    this.automateMonthlyDays.forEach((day) => {
                        if (day.name == ele) {
                            setTimeout(() => {
                                //day.checked = true;
                                this.ScheduledPopupComponent_lSelectedDays(day.name);
                            }, 10);
                        }
                    });
                });
            }
            this.ScheduledVideoComponent_SelectAutomateInterval(this.videoManager.selectedVideo.schedule.interval);
        }
    }
    addDate() {
        this.listdates.push('');
    }
    removeDate(index) {
        this.listdates.splice(index, 1);
    }

    ScheduledVideoComponent_InitializeUserProfileForm() {
        this.fScheduledVideoForm = this.oFormBuilder.group({
            session_id: this.sSessionId,
            email: this.sUserEmail,
            given_days: [null, Validators.required],
            interval: [null, [Validators.required]],
            given_months: [null, [Validators.required]],
            week_days: [null, [Validators.required]],
            time_range: ['morning', [Validators.required]],
            given_dates: [null, [Validators.required]],
        });
    }

    ScheduledPopupComponent_lSelectedWeekDayValue(value) {
        console.log('List of Selected Week Days ==> : ', this.lWeekDay);
        for (let nDayIndex = 0; nDayIndex < this.lSelectedWeekDay.length; nDayIndex++) {
            if (this.lSelectedWeekDay[nDayIndex].value === value) {
                // setTimeout(() => {
                    this.lSelectedWeekDay[nDayIndex].checked = !this.lSelectedWeekDay[nDayIndex].checked;
                // }, 10);
            }
        }
        this.lWeekDay = [];
        this.lSelectedWeekDay.forEach((element) => {
            if (element.checked) {
                this.lWeekDay.push(element.value);
            }
        });

        if (this.lWeekDay.length >= 0) {
            this.bEnableSetScheduleButton = true;
        } else {
            this.bEnableSetScheduleButton = false;
        }

        this.fScheduledVideoForm.patchValue({ week_days: this.lWeekDay });
        console.log('List of Selected Week Days ==> : ', this.lWeekDay);
    }
    ScheduledVideoComponent_SelectAutomateInterval(value) {
        console.log('ScheduledVideoComponent_SelectAutomateInterval ==> : ', value);
        this.fScheduledVideoForm.patchValue({ interval: value });
        this.selectduration = value;
        this.ScheduledVideoComponent_ResetView();
    }
    ScheduledVideoComponent_ResetView() {
        this.bEnableSetScheduleButton = false;
        this.bToggleDaysDropdown = false;
        this.SelectDate = '';
        this.lWeekDay = [];
        this.lMonthName = [];
        this.lMonthDays = [];
        this.FormatedDateArray = [];
        this.lSelectedWeekDay.forEach((element) => {
            element.checked = false;
        });
        this.lSelectedMonth.forEach((element) => {
            element.checked = false;
        });
        this.automateMonthlyDays.forEach((element) => {
            element.checked = false;
        });
        // $('.datepicker').multiDatesPicker('resetDates');
        this.lInputClosePopup = undefined;
    }

    delete() {
        console.log(this.schedule_id);
        this.videoManager
            .deteSchedule(this.sUserEmail, this.sSessionId, this.schedule_id)
            .then((res) => {
                this.videoManager.selectedVideo.schedule = undefined;
                this.schedule_id = undefined;
                this.ScheduledVideoComponent_ResetView();
                document.getElementById('close').click();
                this.onClose.emit();
            })
            .catch((err) => {});
    }
    ScheduledVideoComponent_DisplayDaysDropdown() {
        this.bToggleDaysDropdown = !this.bToggleDaysDropdown;
    }
    ScheduledVideoComponent_HideDaysDropdown() {
        this.bToggleDaysDropdown = false;
    }
    ScheduledVideoComponent_SelectTimeRange(value) {
        console.log('ScheduledVideoComponent_SelectTimeRange ==> : ', value);
        this.timeRange = value;
        this.fScheduledVideoForm.patchValue({ time_range: value });
    }
    ScheduledVideoComponent_SelectInterval() {
        const sInterval = this.fScheduledVideoForm.get('interval').value;

        let oAutomateRequestPayload = {
            email: this.sUserEmail,
            schedule_id: this.schedule_id,
            session_id: this.sSessionId,
            video_id: this.sVideoId,
            interval: this.fScheduledVideoForm.get('interval').value,
            given_days: this.fScheduledVideoForm.get('given_days').value,
            time_range: this.fScheduledVideoForm.get('time_range').value,
            given_months: this.fScheduledVideoForm.get('given_months').value,
            week_days: this.fScheduledVideoForm.get('week_days').value,
            given_dates: this.fScheduledVideoForm.get('given_dates').value,
        };
        if (!this.schedule_id) {
            delete oAutomateRequestPayload.schedule_id;
        }
        if (sInterval === 'weekly') {
            delete oAutomateRequestPayload.given_days;
            delete oAutomateRequestPayload.given_dates;
            delete oAutomateRequestPayload.given_months;
        } else if (sInterval === 'monthly') {
            if (oAutomateRequestPayload.given_months === null) {
                delete oAutomateRequestPayload.given_months;
            }
            delete oAutomateRequestPayload.week_days;
            delete oAutomateRequestPayload.given_dates;
        } else {
            delete oAutomateRequestPayload.given_days;
            delete oAutomateRequestPayload.given_months;
            delete oAutomateRequestPayload.week_days;
        }
        console.log('Payload : ==>', oAutomateRequestPayload);
        return oAutomateRequestPayload;
    }
    ScheduledVideoComponent_SetScheduledVideo() {
        console.log('step to save' + this.fScheduledVideoForm.value);

        this.videoManager
            .createSchedule(this.ScheduledVideoComponent_SelectInterval())
            .then((res) => {
                this.bDisplaySuccesMessage = true;
                this.videoManager.selectedVideo.schedule = res;
                this.schedule_id = this.videoManager.selectedVideo.schedule.schedule_id;
                setTimeout(() => {
                    this.bDisplaySuccesMessage = false;
                }, 5000);
            })
            .catch((err) => {
                this.bDisplayErrorMessage = true;
                this.sDisplayErrorMessage = JSON.parse(err)['errorMessage'];
                setTimeout(() => {
                    this.bDisplayErrorMessage = false;
                }, 5000);
            });
    }
    ScheduledVideo_HandleAutomateResponse = (InComingMessage) => {
        console.log('step reponse ' + InComingMessage);

        if (JSON.parse(InComingMessage)['errorMessage']) {
            console.log('ScheduledVideo_HandleAutomateResponse Error ' + JSON.parse(InComingMessage)['errorMessage']);
            this.bDisplayErrorMessage = true;
            this.sDisplayErrorMessage = JSON.parse(InComingMessage)['errorMessage'];
            setTimeout(() => {
                this.bDisplayErrorMessage = false;
            }, 5000);
        } else {
            console.log('ScheduledVideo_HandleAutomateResponse ==> success Response', JSON.parse(InComingMessage));
            this.bDisplaySuccesMessage = true;
            setTimeout(() => {
                this.bDisplaySuccesMessage = false;
            }, 5000);
        }
    };

    ScheduledPopupComponent_lSelectedMonth(value) {
        for (let nMonthIndex = 0; nMonthIndex < this.lSelectedMonth.length; nMonthIndex++) {
            if (this.lSelectedMonth[nMonthIndex].name === value) {
                this.lSelectedMonth[nMonthIndex].checked = !this.lSelectedMonth[nMonthIndex].checked;
            }
        }
        this.lMonthName = [];
        this.lSelectedMonth.forEach((element) => {
            if (element.checked) {
                this.lMonthName.push(element.value);
            }
        });
        console.log('List of Selected Months values ==> : ', this.lMonthName);
        this.fScheduledVideoForm.patchValue({ given_months: this.lMonthName });
    }

    ScheduledPopupComponent_lSelectedDays(nIncommingDay: number) {
        for (let nMonthDayIndex = 0; nMonthDayIndex < this.automateMonthlyDays.length; nMonthDayIndex++) {
            if (this.automateMonthlyDays[nMonthDayIndex].name === nIncommingDay) {
                this.automateMonthlyDays[nMonthDayIndex].checked = !this.automateMonthlyDays[nMonthDayIndex].checked;
            }
        }
        this.lMonthDays = [];
        this.automateMonthlyDays.forEach((element) => {
            if (element.checked) {
                this.lMonthDays.push(element.name);
            }
        });
        if (this.lMonthDays.length > 0) {
            this.bEnableSetScheduleButton = true;
        } else {
            this.bEnableSetScheduleButton = false;
        }
        console.log('List of Selected Months Days Name ==> : ', this.lMonthDays);
        this.fScheduledVideoForm.patchValue({ given_days: this.lMonthDays });
    }
    ScheduledVideoComponent_SelectDate(event) {
        console.log(event);
        //this.SelectDate = event.target.value;
        console.log('Selected Date is ==> : ', this.SelectDate);
        //  let datesarray = this.SelectDate.split(",");
        this.FormatedDateArray = [];
        this.listdates.forEach((element) => {
            if (element !== '') {
                const formateddate = this.oDatepipe.transform(element, 'yyyy-MM-dd');
                this.FormatedDateArray.push(formateddate);
            }
        });
        if (this.FormatedDateArray.length > 0) {
            this.bEnableSetScheduleButton = true;
        } else {
            this.bEnableSetScheduleButton = false;
        }
        console.log('Formated date aray ==> ', this.FormatedDateArray);
        this.fScheduledVideoForm.patchValue({ given_dates: this.FormatedDateArray });
    }

    InitializeJquery() {
        $('.selectionDurationDropDown .dropdown-item').on('click', function () {
            var dropVal = $(this).text();

            console.log(dropVal);
            // $(".durationOption").removeClass("active");
            $('.autoCreateModalTop').removeClass('autoCreateModalTopEveryYear');
            if (dropVal === 'Every Week') {
                //  $("#durationEveryWeek").addClass("active");
            } else if (dropVal === 'Once a Month') {
                //$("#durationEveryMonth").addClass("active");
            } else if (dropVal === 'Every Year') {
                //  $("#durationEveryYear").addClass("active");
                $('.autoCreateModalTop').addClass('autoCreateModalTopEveryYear');
            } else if (dropVal === 'Every Year') {
                //  $("#durationEveryYear").addClass("active");
                //   $(".autoCreateModalTop").addClass("autoCreateModalTopEveryYear");
            } else if (dropVal === 'On Specific Dates') {
                // $("#durationspecificDates").addClass("active");
                //$(".autoCreateModalTop").addClass("autoCreateModalTopEveryYear");
            }
        });
        // dropdownBtn
        $('.dropdownBtn .dropdown-item, .dropdownSelect .dropdown-item').on('click', function () {
            var dropVal = $(this).text();
            $(this).parent('li').siblings().children().removeClass('active');
            $(this).addClass('active');
            $(this).closest('.dropdownBtn, .dropdownSelect').find('.dropdownBtnText').text(dropVal);
        });
        // end dropdownBtn
    }
    handleCancel(): void {
        this.onClose.emit();
    }
}
