<div class="submenu-floating-panel submenuFontWeight fency-scroll" (window:resize)="closeFontWeightSubmenu()"
  #fontSubMeu>

  <div class="item truncate-line" *ngFor="let font of submenuFontWeightList">
    <mat-icon class="green-tik-font" [ngClass]="{ active: this.selectedFont == font.name }">check_circle</mat-icon>
    <div type="radio" class="no-tik-font" *ngIf="this.selectedFont != font.name"
      [ngClass]="{ 'show': this.selectedFont != font.name }" (click)="selectFont(font.name)"></div>
    <a class="dropdown-item text-md truncate-line " style=" border:1px; font-family:'{{font.styleName}}'">{{
      font.titleName}}
    </a>
  </div>
</div>

<div class="tab-pane" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
  <p class="text-gray-3 text-xs">Set default fonts and colors for your video</p>
  <div class="formItm down_arrow pt-2" style="width:250px;">
    <!-- <p class="text-md text-gray-3 mb-0" *ngIf="selectedFont && selectedFont != ''">
          Available Fontsss</p> -->
    <div class="dropdown dropdownSelect">
      <button class="dropdown-toggle text-md text-capitalize truncate-line" style=" font-family:'{{selectedFontStyle}}'"
        type="button" data-bs-toggle="dropdown" aria-expanded="false">
        {{
        selectedFont != ""
        ? [selectedFont | fontFileNameTruncate : 50]
        : "Available Font"
        }}
      </button>
      <ul class="dropdown-menu dropdownMenu font-list brand-font-list" aria-labelledby="dropdownMenuClickableInside">
        <li>
          <p *ngIf="brandFontArray?.length > 0" class="text-lg text-gray-2 mb-0 ms-3 pt-3 pb-0">
            <strong>Brand Fonts</strong>
          </p>

          <ul class="p-0">
            <div *ngIf="brandFontArray?.length > 0"></div>
            <li class="font-list-item truncate-line" *ngFor="let font of brandFontArray; let i = index; ">
              <mat-icon class="green-tik-font" [ngClass]="{ active: this.selectedFont == font }">check_circle</mat-icon>
              <div type="radio" class="no-tik-font" *ngIf="this.selectedFont != font"
                [ngClass]="{ 'show': this.selectedFont != font }" (click)="selectFont(font)"></div>
              <a class="dropdown-item text-md test truncate-line text" style="font-family:'{{fontStyle(font)}}'">{{ font
                | fontFileNameTruncate : 55 }}
              </a>
            </li>

          </ul>
        </li>
        <li>
          <p class="text-lg text-gray-2 mb-0 ms-3 py-2">
            <strong>Others Fonts</strong>
          </p>
          <ul class="p-0">
            <li class="font-list-item  truncate-line"
              *ngFor=" let font of otherFontParentMap | keyvalue | slice:0:loadMoreFont "
              (mouseenter)="loadFontWeightSubMenu(font)">

              <mat-icon class="green-tik-font"
                [ngClass]="{ active: this.selectedFont == font.value[0].name}">check_circle</mat-icon>
              <div type="radio" class="no-tik-font" *ngIf="this.selectedFont != font.value[0].name"
                [ngClass]="{ 'show': this.selectedFont != font.value[0].name }" (click)="selectOtherFont(font)"></div>

              <a class="dropdown-item text-md truncate-line text"
                style=" border:1px; font-family:'{{font.value[0].fontStyle}}'">{{ font.key }}
              </a>
              <a class="arrowOpenWeightMenu" *ngIf="font.value  && font.value.length > 1"
                (mouseenter)="loadFontWeightSubMenu(font)">
                <img src="../assets/images/icons/rightarrow.png" />
              </a>
            </li>
          </ul>
        </li>
        <li class="loadmore" *ngIf="loadMoreFont < otherFontParentMap.size"
          aria-labelledby="dropdownMenuClickableInside" (click)="loadMoreOtherFonts($event)"> Load more </li>
      </ul>
    </div>
  </div>
</div>