<div class="py-4 px-4 account-heading"[ngClass]="
search_term != ''
  ? 'py-5'
  : ''
">
    <div class="row">
        <div class="col-md-5 d-flex align-items-center">
            <h3 class="weight-700 text-gray-2">My Videos</h3>
        </div>
        <div class="col-md-7 d-flex justify-content-end">
            <div class="col-md-6 d-flex mt-1">
                <div class="formItm search-icon relative">
                    <div>
                    <input id="loginEmail" type="text" value="" [(ngModel)]="title" (keyup.enter)="SearchVideo()"
                        placeholder="Search Video" required>
                    <label for="loginEmail" class="text-sm">Search your videos</label>
                    <img (click)="SearchVideo()" src="../../../../assets/images/svg/search.svg">
                </div>
                 
                 <div
                 class="searchtem"
                 *ngIf="search_term != ''"
                 (click)="clearSearchTerm()"
               >
                 <div>{{ search_term }}</div>
                 <mat-icon class="link">close</mat-icon>
               </div>

                </div>

            </div>

            <div class="p-0">
                <a *ngIf="!this.onBoardingManager.profile.localization_enabled"
                    (click)="DraftVideosComponent_NavigateToCreateVideo()"
                    class="weight-normal btn btn-primary btn-round-6 md-btn mt-4 d-inline-block">
                    <img src="../../../../assets/images/svg/create video.svg">
                    Create a New Video</a>

                <a *ngIf="this.onBoardingManager.profile.localization_enabled" href="javascript:void(0)"
                    id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"
                    style="padding: 10px; min-width:215px"
                    class="weight-normal btn btn-primary btn-round-6 md-btn mt-4 d-inline-block dropdown">
                    <img src="../../../../assets/images/svg/create video.svg">
                    Create a New Video
                    <img src="../../../../assets/images/divider-solid-svgrepo-com.svg">
                    <img class="btn-svg-icon white" src="../../../../assets/images/svg/down arrow.svg">
                </a>
                <ul class="dropdown-menu custom-dropdown" aria-labelledby="navbarDropdown"
                    style="      width: 225px;  transform: translate(1212px, 88px);">
                    <li><a class="dropdown-item" href="javascript:void(0)"
                            (click)="DraftVideosComponent_NavigateToCreateVideo()">Create a New Video</a></li>
                    <li><a class="dropdown-item" [routerLink]="['/localise-existing-videos']">Localize Existing
                            Videos</a></li>
                </ul>

            </div>
        </div>

    </div>
</div>

<created-videos [numGridCol]="4" [sInputVideoStatus]="'draft,published'" [title]="'Localize'" [isLocations]="true"
    class="accountSecContent py-4 px-3"></created-videos>