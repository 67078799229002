import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountManagerService } from './../../../Managers/account-manager.service';
import { OnboardingManagerService } from './../../../Managers/onboarding-manager.service';
import { cDataService } from './../../../services/data.service';
import { cSessionService } from './../../../services/session.service';
import { cWebSocketService } from './../../../services/websocket.service';
import { ErrorService } from './../../../services/error.service';
import { users } from 'src/app/models/onboarding';
import { mediaFile, mediaLib, visual } from 'src/app/models/media';
import { MediaManagerService } from 'src/app/Managers/media-manager.service';
import { MediaService } from 'src/app/services/dataServices/media.service';
import { LoaderService } from 'src/app/services/loader.service';
import { SuccessMessageModalService } from 'src/app/services/success-message-modal.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UploadFileDialogComponent } from 'src/app/shared/components/upload-file-dialog/upload-file-dialog.component';
declare var $: any;

@Component({
    selector: 'app-my-account',
    templateUrl: './my-account.component.html',
    styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent implements OnInit {
    fUserProfileForm: FormGroup;
    sSessionId: string;
    sUserEmail: any;
    bDisplaySubscriptionButton: boolean;
    // sFacebookSigninLink: string
    // sGoogleSigninLink: string;
    // sLinkedInSigninLink: string
    DisconnectSocialForm: FormGroup;
    connected_social_platforms: any;
    oSocialChannel: string;
    oUserName: any;
    sDisplayDisconnectErrorMessage: string;
    bDisplayErrorMessage: boolean = false;
    bDisabledButtons: boolean = false;
    bEditButton: boolean = true;
    defaultMediaLibrary: any;
    userLogoPath: any;

    public mediaLibs: mediaLib[] = [];

    constructor(
        private oSessionService: cSessionService,
        private oFormBuilder: FormBuilder,
        private oWebSocketService: cWebSocketService,
        private oRouter: Router,
        private oDataService: cDataService,
        private accountManager: AccountManagerService,
        public onboardingManger: OnboardingManagerService,
        private oActivatedRoute: ActivatedRoute,
        public errorService: ErrorService,
        private mediaManger: MediaManagerService,
        public dataservice: MediaService,
        public loader: LoaderService,
        private onboardingService: OnboardingManagerService,
        private successMessage: SuccessMessageModalService,
        private dialog: MatDialog,
    ) {}

    ngOnInit() {
        if (this.onboardingManger.profile.user_logo_file && this.onboardingManger.profile.user_logo_file != undefined) {
            this.updateUserAvatarPath(this.onboardingManger.profile.user_logo_file);
        }

        const authResult = this.oActivatedRoute.snapshot.queryParams['auth_result'];
        if (
            authResult != null &&
            (authResult == 'google_auth_successful' || authResult == 'fb_auth_successful' || authResult == 'linkedin_auth_successful')
        ) {
            // const email = this.oActivatedRoute.snapshot.queryParamMap.get('email');
            // const session = this.oActivatedRoute.snapshot.queryParamMap.get('session_id');
            // this.oSessionService.cSessionService_SetUserEmail(email);
            // this.oSessionService.cSessionService_SetSessionId(session);
        }

        this.sSessionId = this.oSessionService.SessionService_GetSessionId();
        this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
        this.fUserProfileForm = this.oFormBuilder.group({
            session_id: this.onboardingManger.loggedUser.session_id,
            // _id: this.onboardingManger.profile._id,
            email: [this.onboardingManger.loggedUser.email, Validators.required],

            name: ['', Validators.required],
            get_pronto_newsletter: this.onboardingManger.profile.get_pronto_newsletter
                ? this.onboardingManger.profile.get_pronto_newsletter
                : false,
        });

        this.GetUserProfile();
        this.DisconnectSocialForm = this.oFormBuilder.group({
            platform: '',
        });
        this.oSessionService.setAccountSettingHeaderName('Account Overview');
    }

    GetUserProfile() {
        if (!this.onboardingManger.profile) {
            this.onboardingManger
                .getUserProfile(this.onboardingManger.loggedUser.email, this.onboardingManger.loggedUser.session_id)
                .then((res) => {
                    this.initializeForm();
                })
                .catch((err) => {
                    this.errorService.handleError(err.errorMessage, err.errorType, true);
                });
        } else {
            this.initializeForm();
        }
    }

    private initializeForm() {
        this.fUserProfileForm.get('name').setValue(this.onboardingManger.profile.name);
        this.bDisplaySubscriptionButton = this.onboardingManger.profile.is_subscribed_to_email == 0 ? false : true;
        this.onboardingManger.profile.get_pronto_newsletter && this.onboardingManger.profile.get_pronto_newsletter == true
            ? this.onboardingManger.profile.get_pronto_newsletter
            : false;
    }

    toggleSubscription() {
        this.onboardingManger.profile.is_subscribed_to_email = this.onboardingManger.profile.is_subscribed_to_email == 1 ? 0 : 1;
        this.bDisplaySubscriptionButton = this.onboardingManger.profile.is_subscribed_to_email == 0 ? false : true;
    }
    MyAccountComponent_ConnectSocialChannels(value: string) {
        let sURL: string;
        if (value == 'facebook') {
            sURL =
                this.oDataService.faceBookJoinLink +
                '?' +
                'email=' +
                this.onboardingManger.loggedUser.email +
                '&session_id=' +
                this.onboardingManger.loggedUser.session_id;
        } else if (value == 'google') {
            sURL =
                this.oDataService.googleJoinLink +
                '?' +
                'email=' +
                this.onboardingManger.loggedUser.email +
                '&session_id=' +
                this.onboardingManger.loggedUser.session_id;
        } else if (value == 'linkedin') {
            sURL =
                this.oDataService.linkedInJoinLink +
                '?' +
                'email=' +
                this.onboardingManger.loggedUser.email +
                '&session_id=' +
                this.onboardingManger.loggedUser.session_id;
        }

        this.oSessionService.cSessionService_SetRedirectURL(2);
        location.assign(sURL);
    }

    MyAccountComponent_ResetView() {
        this.initializeForm();
        this.bDisabledButtons = false;
        this.bEditButton = true;
    }

    MyAccountComponent_SendProfileUpdateRequest() {
        if (this.fUserProfileForm.valid) {
            this.onboardingManger.profile.name = this.fUserProfileForm.get('name').value;
            this.onboardingManger.profile.get_pronto_newsletter = this.fUserProfileForm.get('get_pronto_newsletter').value;
            if (this.onboardingManger.profile?.default_media_lib) {
                delete this.onboardingManger.profile.default_media_lib;
            }
            this.onboardingManger
                .updateUserProfile(this.onboardingManger.profile)
                .then((res) => {
                    this.initializeForm();
                    this.bDisabledButtons = false;
                    this.bEditButton = true;
                })
                .catch((err) => {
                    this.errorService.handleError(err.errorMessage, err.errorType, true);
                });
        } else {
            this.fUserProfileForm.markAllAsTouched();
        }
    }

    MyAccountComponent_DisconnectSocialChannel(value) {
        this.accountManager
            .disconnectSocialPlatform(this.onboardingManger.loggedUser.email, this.onboardingManger.loggedUser.session_id, value)
            .then((res) => {
                switch (value) {
                    case 'google': {
                        this.onboardingManger.profile.connected_social_platforms.google = false;
                        break;
                    }
                    case 'facebook': {
                        this.onboardingManger.profile.connected_social_platforms.facebook = false;
                        break;
                    }
                    case 'linkedin': {
                        this.onboardingManger.profile.connected_social_platforms.linkedin = false;
                        break;
                    }
                    default:
                        break;
                }
            })
            .catch((err) => {
                this.errorService.handleError(err.errorMessage, err.errorType, true);
            });
    }

    MyAccountComponent_EditForm() {
        this.bEditButton = false;
        this.bDisabledButtons = true;
    }

    MyAccountComponent_UnsubscriptionModal() {
        $('#subscription-popup').modal('show');
    }

    async updateProfile() {
        this.MyAccountComponent_SendProfileUpdateRequest();
    }

    // async uploadUserLogo(event) {
    //     let file = event.target.files[0];
    //     if (file) {

    //         let hasError = false;
    //         let responseUpload = undefined;
    //         let filePath = '';
    //         let thumbLogo = '';
    //         let media = new mediaFile();
    //         media.file = file;
    //         media.name = file.name;
    //         let vs = new visual();

    //         if (
    //             file.type.indexOf('png') > -1
    //             || file.type.indexOf('jpeg') > -1
    //             || file.type.indexOf('jpg') > -1) {
    //             try {
    //                 this.successMessage.displayMessage('Uploading user avatar');

    //                 await this.mediaManger
    //                     .uploadMediaDefaultLibrary(this.sUserEmail, this.sSessionId, media)
    //                     .then((res) => {
    //                         this.successMessage.close();
    //                         responseUpload = res;
    //                         filePath = responseUpload['visual_path'];
    //                         thumbLogo = res['thumbnail'];
    //                     })
    //                     .catch((err) => {
    //                         hasError = true;
    //                         console.log('Errr here ', err);
    //                         responseUpload = err;
    //                         this.successMessage.close();
    //                     });
    //             } catch (err) {
    //                 this.successMessage.close();
    //                 hasError = true;
    //                 responseUpload = err;
    //             }

    //             if (hasError) {
    //                 filePath = responseUpload.fpath ? responseUpload.fpath : '';
    //                 await this.dataservice
    //                     .getSignedURL(this.sUserEmail, this.sSessionId, filePath)
    //                     .then(async (resp) => {
    //                         thumbLogo = resp['url'];
    //                     })
    //                     .catch((err) => {});
    //             } else {
    //                 hasError = false;
    //                 filePath = responseUpload['visual_path'];
    //             }
    //             this.successMessage.close();
    //             this.onboardingManger.profile._id = this.oSessionService.cSessionService_GetUserEmail();
    //             this.onboardingManger.profile.user_logo_file = filePath;
    //             this.userLogoPath = thumbLogo;
    //             this.oSessionService.userProfileLogoPath = thumbLogo;
    //             this.successMessage.displayMessage('Updating profile data.');
    //             this.onboardingManger.updateUserLogo(this.onboardingManger.profile).then(() => {
    //                 this.loader.hideLoader();
    //                 this.successMessage.close();
    //             });
    //         } else {
    //             this.successMessage.close();
    //             this.errorService.handleError(
    //                 "Oops! Looks like the logo you uploaded isn't in the right format. Please upload a PNG or JPG file to use as your logo.",
    //                 'Invalid File Type',
    //                 true,
    //             );
    //         }
    //     }
    // }

    async uploadUserLogo() {
    //     this.openFileUploadModal();
    // }
    // openFileUploadModal() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "100wv";
        dialogConfig.height = "100vh";
        dialogConfig.panelClass = "pronto-dialog";
        dialogConfig.closeOnNavigation = true;

        dialogConfig.data = {
          uploadFor: "profile_logo",
          multipleUpload: false,
          dropBoxText: "",
          heading: "Upload a Logo",
        };

        let dialogModal = this.dialog.open(UploadFileDialogComponent, dialogConfig);

        dialogModal.afterClosed().subscribe((data: any) => {
          if (data?.status == "success" || data?.type == "success") {
            console.log("Success called");
            const res = data.result[0];
                console.log("rescheck", res);

                this.successMessage.close();
                this.onboardingManger.profile._id = this.oSessionService.cSessionService_GetUserEmail();
                this.onboardingManger.profile.user_logo_file = res.visual_path;
                this.userLogoPath = res.url;
                this.oSessionService.userProfileLogoPath = res.url;
                this.successMessage.displayMessage('Updating profile data.');
                this.onboardingManger.updateUserLogo(this.onboardingManger.profile).then(() => {
                    this.loader.hideLoader();
                    this.successMessage.close();
                });
                    this.successMessage.close();
          }
        else {

          }
        });
      }

    updateUserAvatarPath(fPath) {
        const fpahArray = [fPath];
        this.mediaManger
            .getMediaThumbandURL(
                this.oSessionService.cSessionService_GetUserEmail(),
                this.oSessionService.SessionService_GetSessionId(),
                fpahArray,
            )
            .then((resThumb: any) => {
                if (resThumb && resThumb.output) {
                    this.userLogoPath = resThumb.output[0][fpahArray[0]];
                    this.oSessionService.userProfileLogoPath = resThumb.output[0][fpahArray[0]];
                }
            });
    }

    deleteProfilePic() {
        this.onboardingManger.profile._id = this.oSessionService.cSessionService_GetUserEmail();
        this.onboardingManger.profile.user_logo_file = '';
        if (this.onboardingManger.profile?.default_media_lib) {
            delete this.onboardingManger.profile.default_media_lib;
        }
        this.onboardingManger.updateUserProfile(this.onboardingManger.profile).then(() => {
            this.userLogoPath = '';
            this.oSessionService.userProfileLogoPath = '';
            this.onboardingManger.profile.user_logo_file = '';
        });
    }
    deleteProfilePicModal() {
        $('#DeleteProfilepic-popup').modal('show');
    }
}
