import { Pipe, PipeTransform } from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
 */
@Pipe({ name: 'aspectRatio' })
export class AspectRatioPipe implements PipeTransform {
    transform(data: any[], aspect): any {
        if (data) {

            let dataArray = data.filter((item) => {

                    console.log(item.aspect_ratio, aspect);
                    return  item.aspect_ratio && item.aspect_ratio == aspect;
            });
            return dataArray;
        }
            return data;


    }
}
