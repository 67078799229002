<app-header></app-header>
    <section class="accountSec main-body bg-gray-7 h-100">
        <div class="container">
            <ul class="breadcrumb my-3">
                <li><a href="#">Business Profile</a></li>
                <li>Customer Experience</li>
            </ul>
            <div class=" br12 bg-white page-shadow curve-bg h-80vh">
                <div class="py-4 px-4 account-heading">
                    <div class="row">
                        <div class="col-md-12">
                            <h3 class="weight-700 text-gray-2">Business Profile</h3>
                            <p class="mx-0 my-0 text-sm">Keep your business information up to date for the best results</p>
                        </div>

                    </div>
                </div>
            <div class="accountSecInner">
                <div class="accountSecNav py-5">
                    <app-business-sidebar></app-business-sidebar>
                </div>
                <div class="accountSecContent py-5">
                    <form [formGroup]="fCustomerExperienceForm">
                    <div class="row">
                        <div class="col-md-6">
                           <div class="loginSecItmForm pt-0" aria-labelledby="loginYourself">
                                <h4 class="text-gray-2 mb-4">
                                    Customer Experience
                                </h4>
                                <div class="row">
                                            <div class="col-sm-12">
                                                <div class="formItm static_lable order_list pt-0">
                                                    <label class="primaryFont" for="new-business"><span class="counts">1.</span>How would you greet a new customer?</label>
                                                    <input class="secondaryFont" id="new-business" type="text" placeholder="Welcome I’m glad you’re here" formControlName="greeting">
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <div class="formItm static_lable order_list mb-2">
                                                    <label class="primaryFont" for="aboutwebsite"><span class="counts">2.</span>How would you want your customer to feel?<font class="text-xs text-primary-4 ms-2">(Choose up to 3)</font></label>
                                                    <p class="text-sm text-gray-2 pt-2 mb-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,</p>

                                                            <div class="radio_select_wrapp">
                                                                <label class="radio_select" *ngFor="let mood of lSelectedMoodListValues">
                                                                    <input type="checkbox" [checked]="mood.checked" value="{{mood.name}}" class="radio_input_element" (change)="CustomerExperienceComponent_MoodListValue(mood.name)"/>
                                                                <div class="radio_caption"><span>{{mood.name}}</span></div>
                                                            </label>

                                                            </div>
                                                </div>
                                            </div>
                                </div>
                           </div>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-4 small-side offset-md-1">

                            <div class="w-info">
                                <p class="text-lg text-gray-2 pb-1 mb-0">
                                    Similar Videos
                                </p>
                                <p class="text-xs text-gray-3">Based on your selections. Here are some sample matching videos.</p>
                            </div>

                            <div class="right-sde-info">
                               <ul class="d-flex business-media" id="container-view">
                                    <li class="video-screen grid">
                                        <div class="video-outer">
                                            <div class="video_wrapper video_wrapper_full js-videoWrapper">

                                                <iframe class="videoIframe js-videoIframe" src="https://www.youtube.com/embed/XULUBg_ZcAU" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            <figure class="video-baner"><img src="../assets/images/myVideos/v2.png" alt="video1">
                                                <span class="video-duration">00.55</span>
                                            </figure>
                                                <button class="videoPoster js-videoPoster"></button>
                                            </div>

                                         </div>
                                        </li>
                                    <li class="v-text ps-2 ms-2 pt-1">
                                        <h4 class="text-sm text-gray-2">Lorem Ipsum dolar eist</h4>
                                        <p class="text-xs text-gray-3 my-2">Lorem Ipsum dolar eist Lorem Ipsum dolar eist Lorem Ipsum dol...</p>
                                        <h5 class="text-xs text-gray-2">July 2,2021</h5>
                                    </li>
                                </ul>
                                <ul class="d-flex business-media" id="container-view">
                                    <li class="video-screen grid">
                                        <div class="video-outer">
                                            <div class="video_wrapper video_wrapper_full js-videoWrapper">

                                                <iframe class="videoIframe js-videoIframe" src="https://www.youtube.com/embed/XULUBg_ZcAU" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            <figure class="video-baner"><img src="../assets/images/myVideos/v1.png" alt="video1">
                                                <span class="video-duration">00.55</span>
                                            </figure>
                                                <button class="videoPoster js-videoPoster"></button>
                                            </div>

                                         </div>
                                        </li>
                                    <li class="v-text ps-2 ms-2 pt-1">
                                        <h4 class="text-sm text-gray-2">Lorem Ipsum dolar eist</h4>
                                        <p class="text-xs text-gray-3 my-2">Lorem Ipsum dolar eist Lorem Ipsum dolar eist Lorem Ipsum dol...</p>
                                        <h5 class="text-xs text-gray-2">July 2,2021</h5>
                                    </li>
                                </ul>
                                <ul class="d-flex business-media" id="container-view">
                                    <li class="video-screen grid">
                                        <div class="video-outer">
                                            <div class="video_wrapper video_wrapper_full js-videoWrapper">

                                                <iframe class="videoIframe js-videoIframe" src="https://www.youtube.com/embed/XULUBg_ZcAU" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            <figure class="video-baner"><img src="../assets/images/myVideos/v3.png" alt="video1">
                                                <span class="video-duration">00.55</span>
                                            </figure>
                                                <button class="videoPoster js-videoPoster"></button>
                                            </div>

                                         </div>
                                        </li>
                                    <li class="v-text ps-2 ms-2 pt-1">
                                        <h4 class="text-sm text-gray-2">Lorem Ipsum dolar eist</h4>
                                        <p class="text-xs text-gray-3 my-2">Lorem Ipsum dolar eist Lorem Ipsum dolar eist Lorem Ipsum dol...</p>
                                        <h5 class="text-xs text-gray-2">July 2,2021</h5>
                                    </li>
                                </ul>
                            </div>

                        </div>

                    </div>
                </form>
                </div>
                <div class="wtermarklogo">
                    <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
                </div>
                </div>
            </div>

        </div>
    </section>