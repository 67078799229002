<app-dashboard-header></app-dashboard-header>
<section class="accountSec main-body bg-gray-7 h-100">

    <div class="container">
        <div class="h-86vh br12 curve-bg tab-content bg-white page-shadow mt-4 fancy-scroll">

            <div class="tab-pane fade show active">
                <div class="content_area">
                    <div class="row px-4 download-section">
                        <!-- <div class="col-sm-12 col-md-6 col-lg-3">
                            <div class="download-box video-4k disable">
                                <figure>
                                    <i class="icon-4k"></i>
                                </figure>
                                <h4 class="text-xxl pb-2 weight-400 mt-4 pt-1">4k Video</h4>
                                <div class="text-wrap">
                                    <p class="text-xs text-gray-3 mb-0">
                                        Four times the resolution of an HD download. Better clarity and color
                                        resolution, ideal for large screens and
                                        professional level production
                                    </p>
                                </div>
                                <ul class="pb-4 my-2">
                                    <li class="resolution border-bottom">
                                        3840 x 2160
                                        <a href="javascript:void(0)"
                                            (click)="(this.videoManager.selectedVideo?.publish_status == 'draft')? confirmDownload(): DashboardHeaderComponent_DownloadVideo()">
                                            <img class="icon-svg" src="../../../../assets/images/svg/download.svg">
                                        </a>
                                    </li>
                                </ul>
                                <button class="btn btn-secondary btn-round-6 mt-4 download-4k " disabled
                                    (click)="(this.videoManager.selectedVideo?.publish_status == 'draft')? confirmDownload(): DashboardHeaderComponent_DownloadVideo()"
                                    style="opacity: 1;">
                                    <img class="btn-svg-icon me-2" src="../../../../assets/images/svg/download.svg">
                                    Download
                                </button>
                            </div>
                        </div> -->
                        <div class="col-sm-12 col-md-6 col-lg-3">
                            <div class="download-box video-hd">
                                <figure>
                                    <i class="icon-hd"></i>
                                </figure>
                                <h4 class="text-xxl pb-2 weight-400">HD Video</h4>
                                <div class="text-wrap">
                                    <p class="text-xs text-gray-3 mb-0">
                                        Higher resolution and quality than standard-definition, ideal for videos
                                        published online. Less crisp than a 4K viewing
                                        experience.
                                    </p>
                                </div>
                                <ul class="pb-4 my-2">
                                    <li class="resolution border-bottom">
                                        {{this.videoManager.selectedVideo.aspect_ratio == "16x9" ? '1920 x 1080' : this.videoManager.selectedVideo.aspect_ratio == "9x16" ? '1080 x 1920' : this.videoManager.selectedVideo.aspect_ratio == "1x1" ? '1080 x 1080' : '1080 x 1350'}}
                                        <a href="javascript:void(0)"
                                            (click)="(this.videoManager.selectedVideo?.publish_status == 'draft')? confirmDownload(): DashboardHeaderComponent_DownloadVideo()">
                                            <img class="icon-svg" src="../../../../assets/images/svg/download.svg">
                                        </a>
                                    </li>
                                </ul>
                                <button class="btn btn-secondary btn-round-6 download-hd"
                                    (click)="(this.videoManager.selectedVideo?.publish_status == 'draft')? confirmDownload(): DashboardHeaderComponent_DownloadVideo()"
                                    style="opacity: 1; margin-top: 32px!important;" >
                                    <img class="btn-svg-icon me-2" src="../../../../assets/images/svg/download.svg">
                                    Download
                                </button>
                            </div>
                        </div>
                        <!-- <div class="col-sm-12 col-md-6 col-lg-3">
                            <div class="download-box video-sd disable">
                                <figure>
                                    <i class="icon-sd"></i>
                                </figure>
                                <h4 class="text-xxl pb-2 weight-400">Standard Video</h4>
                                <div class="text-wrap">
                                    <p class="text-xs text-gray-3 mb-0">
                                        Lower quality than HD and 4K, but
                                        renders a smaller file size ideal for
                                        sending as an attachment.
                                    </p>
                                </div>
                                <ul class="pb-4 my-2">
                                    <li class="resolution border-bottom">
                                        1200 x 480
                                        <a href="javascript:void(0)"
                                            (click)="(this.videoManager.selectedVideo?.publish_status == 'draft')? confirmDownload(): DashboardHeaderComponent_DownloadVideo()">
                                            <img class="icon-svg" src="../../../../assets/images/svg/download.svg">
                                        </a>
                                    </li>
                                </ul>
                                <button class="btn btn-secondary btn-round-6 mt-4 download-sd " disabled
                                    (click)="(this.videoManager.selectedVideo?.publish_status == 'draft')? confirmDownload(): DashboardHeaderComponent_DownloadVideo()"
                                    style="opacity: 1;">
                                    <img class="btn-svg-icon me-2" src="../../../../assets/images/svg/download.svg">
                                    Download
                                </button>
                            </div>
                        </div> -->
                        <!-- <div class="col-sm-12 col-md-6 col-lg-3">
                            <div class="download-box video-social disable">
                                <figure><i class="icon-social"></i>
                                </figure>
                                <h4 class="text-xxl pb-2 weight-400">Social Video</h4>
                                <div class="text-wrap">
                                    <p class="text-xs text-gray-3 mb-0">
                                        Videos specially-curated based on the ideal quality and file size needed for
                                        each social platform.
                                    </p>
                                </div>
                                <div class="cus-select aspect-ratio">
                                    <div class=" formItm static_lable order_list">
                                        <div class="dropdown dropdownSelect">
                                            <button class="dropdown-toggle text-md dropdownSelectValid pb-0"
                                                type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span class="dropdownBtnText">
                                                    <a class="text-md aspect-item justify-content-around ps-2 resolution"
                                                        href="javascript:void(0)">
                                                        Facebook
                                                    </a>
                                                </span>
                                            </button>
                                            <ul class="dropdown-menu dropdownMenu social-list">
                                                <li class="p-0">
                                                    <a class="dropdown-item text-md">
                                                        Facebook
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-secondary btn-round-6 mb-4 download-social" disabled
                                    (click)="(this.videoManager.selectedVideo?.publish_status == 'draft')? confirmDownload(): DashboardHeaderComponent_DownloadVideo()"
                                    style="opacity: 1; margin-top: 34px!important;">
                                    <img class="btn-svg-icon me-2" src="../../../../assets/images/svg/download.svg">
                                    Download
                                </button>
                            </div>
                        </div> -->

                    </div>
                </div>
            </div>

            <div class="wtermarklogo">
                <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
            </div>

        </div>
    </div>

</section>
<div class="overlay"></div>


<nz-modal [(nzVisible)]="isConfirmDownload" nzCentered [nzWidth]="566" [nzFooter]=null [nzClosable]=false
    (nzOnCancel)="isConfirmDownload = false">

    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-body">
                <!-- <button type="button" class="btn-close cust-close closeRightBtn"
                    (click)="isConfirmDownload = false"></button> -->

                <h5>Confirm Video Download</h5>
                <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">Downloading this draft video will publish it and count
                    towards your plan's total published videos.
                </p>
                <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4 px-4"
                    (click)="downloadnPublish()">Download & Publish</a>
                <a href="javascript:void(0)" class="mt-3 text-gray-3 text-sm"
                    (click)="isConfirmDownload = false">Cancel</a>
            </div>
        </div>
    </div>
</nz-modal>
<!-- Modal pop-up End-->