import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfigManagerService } from '../../../Managers/config-manager.service';
import { CreateVideoManagerService } from '../../../Managers/create-video-manager.service';

import { video } from '../../../models/video';

import { cSessionService } from '../../../services/session.service';

import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { AccountManagerService } from './../../../Managers/account-manager.service';
import { OnboardingManagerService } from './../../../Managers/onboarding-manager.service';
import { ErrorService } from './../../../services/error.service';
import { BrandService } from '../../myAccount/manage-brand/service/BrandService';
import { VideoService } from 'src/app/services/dataServices/video.service';
import { LoaderService } from 'src/app/services/loader.service';
import { VideoDataStore } from 'src/app/stores/video.store';
import { LocationService } from 'src/app/shared/services/location.service';

declare var $: any;

@Component({
    selector: 'app-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit, OnDestroy {
    @ViewChild('close') close: ElementRef;
    sSessionID: string = '';
    sUserEmail: string = '';
    sVideoID: string = '';
    fOverviewForm: FormGroup;
    oSavedFormData: any;
    lVideoDurations = [
        { name: '6s', value: 6, checked: false, isEnabled: true },
        { name: '15s', value: 15, checked: false, isEnabled: true },
        { name: '30s', value: 30, checked: false, isEnabled: true },
        { name: '60s', value: 60, checked: false, isEnabled: true },
    ];
    lAspectRatio = ['(16:9)960x540'];
    sSelectedAspectRatio: any;
    videoAspectRation = '16x9';
    sDisplayResponseErrorMessage: string;
    bCreateVideoMode: boolean = true;
    sDisableEditModeToggle: string = 'false';
    oTotalCount: any;
    lLocales: any = [];
    lSelectedLocations: any = [];
    nCurrentPage = 1;
    lPagination: any = [];
    nPageSize: number = 100;
    bAuthorizationError: boolean = false;
    bLocales: boolean = false;
    totalSelectedLocals: any = [];
    formListStart = 0;

    submitFormSubscription: Subscription;
    brandList = [];
    selectedBrand = { brand_id: '', brand_name: '' };

    num_items: number = 10;
    num_skip = 0;
    currentPage = 1;
    listPages: number[] = [];
    defaultBrand: any;
    isOnlyHaveDefaultBrand = true;
    showOverViewForm = false;

    public AllLocaleSelected: boolean = false;
    defaultLocationFormField = undefined;
    order_by = 'name';
    sort_type = -1;
    videoAspectRatio = [
        {
            title: 'Landscape',
            icon: '../../../../assets/images/aspect ratio/Landscape.svg',
            ratio: '16:9',
            ratio_value: '16x9',
            isSelected: true,
            class: 'lanscape-size',
            socialIcon: [
                '../../../../assets/images/svg/youtube 2.svg',
                '../../../../assets/images/svg/facebook-icon.svg',
                '../../../../assets/images/svg/linkedin-icon.svg',
            ],
        },
        {
            title: 'Square',
            icon: '../../../../assets/images/aspect ratio/Square.svg',
            ratio_value: '1x1',
            ratio: '1:1',
            class: 'square-size',
            isSelected: false,
            socialIcon: ['../../../../assets/images/svg/instagram.svg'],
        },
        {
            title: 'Story/Reels',
            icon: '../../../../assets/images/aspect ratio/StoryReels.svg',
            ratio_value: '9x16',
            ratio: '9:16',
            class: 'reel-size',
            isSelected: false,
            socialIcon: [
                '../../../../assets/images/svg/facebook-icon.svg',
                '../../../../assets/images/svg/instagram.svg',
                '../../../../assets/images/svg/linkedin-icon.svg',
            ],
        },
        {
            title: 'Portrait',
            icon: '../../../../assets/images/aspect ratio/Portrait.svg',
            ratio: '4:5',
            class: 'portrait-size',
            ratio_value: '4x5',
            isSelected: false,
            socialIcon: ['../../../../assets/images/svg/instagram.svg'],
        },
    ];

    constructor(
        private oFormBuilder: FormBuilder,
        public configManager: ConfigManagerService,
        public videoManager: CreateVideoManagerService,
        private oSessionService: cSessionService,
        public onBoardingManager: OnboardingManagerService,
        private oRoutes: Router,
        private accountManager: AccountManagerService,
        public errorService: ErrorService,
        public dataservice: VideoService,
        private brandService: BrandService,
        public loader: LoaderService,
        public videoStore: VideoDataStore,
        private locationService : LocationService,
    ) {
        // this.brandService.getDefaultBrand().then((brand: any) => {
        //   this.defaultBrand = brand;
        // });

        this.loader.showLoader();
    }

    setVideoBrandSettings() {
        this.brandList = this.onBoardingManager.profile.brands;

        if (this.brandList != undefined && this.brandList.length > 0 && this.onBoardingManager.profile?.plan == 'enterprise') {
            this.isOnlyHaveDefaultBrand = false;
        }

        if (!this.brandList || this.brandList.length == 0) {
            this.brandList = [this.defaultBrand];
        }
        this.selectedBrand = this.brandList[0];
        this.formListStart = !this.isOnlyHaveDefaultBrand && this.brandList.length > 0 ? 1 : 0;

        if (this.videoManager.selectedVideo) {
            if (this.videoManager.selectedVideo.brand_id) {
                // this.selectedBrand.brand_id = this.videoManager.selectedVideo.brand_id;
                // this.selectedBrand.brand_name = this.videoManager.selectedVideo.brand_id;
                if (this.onBoardingManager.profile.brands && this.onBoardingManager.profile.brands.length > 0) {
                    const brandMatched = this.onBoardingManager.profile.brands.filter(
                        (item: any) => item.brand_id == this.videoManager.selectedVideo.brand_id,
                    );

                    if (brandMatched && brandMatched.length > 0) {
                        this.selectedBrand = brandMatched[0];
                    } else {
                        this.selectedBrand = this.defaultBrand;
                    }
                } else {
                    this.selectedBrand = this.defaultBrand;
                }
                this.formListStart = !this.isOnlyHaveDefaultBrand && this.brandList.length > 0 ? 1 : 0;
            }
            // this.videoManager.setEditVideo(this.sUserEmail,thiisTimeLineViews.sSessionID,this.videoManager.selectedVideo);
            if (
                this.videoManager.selectedVideo.thumb &&
                this.videoManager.selectedVideo.thumb != '' &&
                this.videoManager.selectedVideo.thumb.indexOf('//storage.') == -1
            ) {
                this.dataservice.getSignedURL(this.sUserEmail, this.sSessionID, this.videoManager.selectedVideo.thumb).then((result) => {
                    this.videoManager.selectedVideo.thumbnail = result['url'];
                    // console.log('checkres', result);
                });
            }
            this.loader.hideLoader();
            this.formListStart = !this.isOnlyHaveDefaultBrand && this.brandList.length > 0 ? 1 : 0;

            this.CreateVideoOverviewComponent_SetOverviewForm();
            this.lVideoDurations.forEach((ee) => {
                if (ee.value == this.videoManager.selectedVideo.duration) {
                    ee.isEnabled = true;
                } else {
                    ee.isEnabled = false;
                }
            });
        } else {
            this.CreateVideoOverviewComponent_InitializeOverviewForm();
        }
        this.loader.hideLoader();
        // console.log(' selected brand ', this.selectedBrand);
    }

    ngOnInit(): void {
        this.videoStore.clearGenerationInterval = true;

        this.sSessionID = this.oSessionService.SessionService_GetSessionId();
        this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();

        this.submitFormSubscription = this.videoManager.submitForm.subscribe((c) => {
            this.submitForm(c);
        });
        this.dataservice.firstTimeCreateVideoMixPixelApi({
            email: this.sUserEmail,
            session_id: this.sSessionID,
            event: 'create_video_landing',
            param: {
                user_type: this.onBoardingManager.profile.plan != 'enterprise' ? 'smb' : 'enterprise',
                video_type : 'normal'
            },
        });
        this.brandService
            .getDefaultBrand()
            .then((brand: any) => {
                this.defaultBrand = { brand_id: brand._id?.$oid ? brand._id.$oid : brand._id, brand_name: brand.name };
                this.showOverViewForm = true;
                this.setVideoBrandSettings();
            })
            .catch(() => {
                this.showOverViewForm = true;
                this.loader.hideLoader();
                this.setVideoBrandSettings();
            });

        if (this.videoManager.selectedVideo) {
        } else {
        }
        // this.brandList = this.onBoardingManager.profile.brands;
        // if (!this.brandList || this.brandList.length == 0) {
        //   this.brandService.getDefaultBrand().then((brand: any) => {
        //     this.brandList = [{ brand_id: brand?._id?.$oid, brand_name: brand?.name }];
        //     if (this.brandList.length > 0) {
        //       this.formListStart = (this.brandList.length > 1) ? 1 : 0 ;
        //       this.selectedBrand = this.brandList[0];
        //     }
        //     if(this.brandList && this.brandList.length > 1) {
        //       this.formListStart = 1;
        //     }

        //   });

        // } else {
        //   console.log("default brand ..", this.brandList );
        //   console.log("default this.selectedBrand ..", this.selectedBrand );
        //   if ( !this.videoManager.selectedVideo && this.brandList.length > 0) {
        //     this.selectedBrand = this.brandList[0];
        //     this.formListStart = (this.brandList.length > 1) ? 1 : 0 ;
        //   }

        //   if(this.brandList && this.brandList.length > 1){
        //     this.formListStart = 1;
        //   }
        // }

        if (!this.videoManager.selectedVideo?.aspect_ratio || this.videoManager.selectedVideo?.aspect_ratio == '') {
            this.sSelectedAspectRatio = this.videoAspectRatio[0];
        } else {
            this.videoAspectRatio.map((item, i) => {
                item.isSelected = false;
                if (item.ratio_value == this.videoManager.selectedVideo.aspect_ratio) {
                    this.sSelectedAspectRatio = item;
                    item.isSelected = true;
                }
            });
        }
    }
    ngOnDestroy() {
        this.submitFormSubscription.unsubscribe();

        if (this.videoManager.isEditMode) {
            this.updateVideoLocally();
        }
    }

    toggleLocation(event) {
        console.log(event);
        this.bLocales = !this.bLocales;
        if (this.bLocales) {
        } else {
            this.lSelectedLocations = [];
        }
    }

    resetSelectedBrand(brand) {
        this.selectedBrand = brand;
        console.log(this.selectedBrand);
    }

    CreateVideoOverviewComponent_SetOverviewForm() {
        this.brandList = this.onBoardingManager.profile.brands;

        this.fOverviewForm = this.oFormBuilder.group({
            session_id: [this.sSessionID],
            email: [this.sUserEmail],
            title: [this.videoManager.selectedVideo.title, [Validators.required]],
            brand: [this.selectedBrand, [Validators.required]],
            duration: [this.videoManager.selectedVideo.duration, [Validators.required]],
            // about: [this.videoManager.selectedVideo.about, [Validators.required]],
            about: '',
            aspect_ratio: ['(16:9)960x540'],
            video_id: [this.videoManager.selectedVideo.fpath],
        });
        // console.log('session', this.videoManager.selectedVideo.session_id);
    }

    CreateVideoOverviewComponent_InitializeOverviewForm() {
        this.fOverviewForm = this.oFormBuilder.group({
            session_id: [this.sSessionID],
            email: [this.sUserEmail],
            brand: [this.selectedBrand, [Validators.required]],
            title: ['', [Validators.required]],
            duration: [30, [Validators.required]],
            // about: ['', [Validators.required]],
            about: '',
            aspect_ratio: ['(16:9) 960x540'],
        });
    }

    async submitForm(submitFrom: string) {
        // console.log("Submit Called...");

        if (this.brandList && this.brandList.length > 0 && this.brandList.length == 1) {
            this.selectedBrand = this.brandList[0];
        }

        if (this.fOverviewForm.valid) {
            this.loader.showLoader();
            let vid = this.updateVideoLocally();
            await this.configManager.loadTemplatesSignedUrl(vid.duration, vid.aspect_ratio, this.sUserEmail, this.sSessionID, undefined);
            // console.log(vid);
            this.videoManager
                .createVideo(vid)
                .then((res) => {
                    vid['video_id'] = res['video_id'];
                    vid.fpath = res['video_id'];
                    this.videoManager.selectedVideo = vid;

                    if (!this.videoManager.isEditMode) {
                        this.oRoutes.navigateByUrl('/wizard/looknfeel');
                    } else {
                        this.oRoutes.navigateByUrl('/wizard/preview');
                    }
                })
                .catch((err) => {
                    this.errorService.handleError(err.errorMessage, err.errorType, true);
                    this.bAuthorizationError = false;

                    let oErrorMessage = JSON.parse(err)['errorType'];

                    if (oErrorMessage === 'AuthorizationError') {
                        this.bAuthorizationError = true;
                        this.sDisplayResponseErrorMessage =
                            'You have either exceeded your monthly video limit or not allowed to perform this operation';
                        $('#error-popup').modal('show');
                    } else {
                        this.sDisplayResponseErrorMessage = 'An internal error occurred. Please try again later.';
                        $('#error-popup').modal('show');
                    }
                    console.log(err);
                });
        } else {
            this.fOverviewForm.markAllAsTouched();
        }
    }

    private updateVideoLocally() {
        let vid = new video();
        if (this.videoManager.selectedVideo) {
            vid = this.videoManager.selectedVideo;
            vid['video_id'] = this.fOverviewForm.value.video_id;
        }

        vid.session_id = this.fOverviewForm.value.session_id;
        vid.email = this.fOverviewForm.value.email;
        vid.title = this.fOverviewForm.value.title;
        vid.duration = this.fOverviewForm.value.duration;
        vid.brand_id = this.selectedBrand.brand_id;
        vid.about = this.fOverviewForm.value.about;
        vid.aspect_ratio = this.videoAspectRation;
        vid.AllLocaleSelected = this.AllLocaleSelected;
        vid.locales = this.AllLocaleSelected ? [] : this.lSelectedLocations;
        vid.step ="overview";
        return vid;
    }

    CreateVideoOverviewComponent_SelectAspectRation(incommingAspectRatio) {
        this.sSelectedAspectRatio = incommingAspectRatio;
        this.videoAspectRation = incommingAspectRatio.ratio_value;
        // console.log('  this.sSelectedAspectRatio ', this.sSelectedAspectRatio);
        this.videoAspectRatio.map((item, i) => {
            item.isSelected = false;
            if (this.sSelectedAspectRatio == item) {
                item.isSelected = true;
                // this.videoAspectRatio[i].isSelected = true;
                this.videoAspectRatio[i] = item;
            }
        });
    }
    CreateVideoOverviewComponent_ToggleView(event) {
        if (event.target.checked) {
            this.oRoutes.navigateByUrl('/wizard/overview');
        } else {
            this.oRoutes.navigateByUrl('edit-video/timeline-view');
        }
    }
    CreateVideoOverviewComponent_CloseSidebar() {
        this.close.nativeElement.click();
    }
    // CreateVideoOverviewComponent_DrawPagination(nIncommingVisualCount: number) {
    //   this.lPagination = [];
    //   this.nPageSize = Math.ceil(nIncommingVisualCount / 10);
    //   for (let nPaginationIndex = 1; nPaginationIndex <= this.nPageSize; nPaginationIndex++) {
    //     this.lPagination.push(nPaginationIndex);
    //   }

    // }
    // CreateVideoOverviewComponent_fetchNextPage(incommingPage) {
    //   if (incommingPage !== this.nCurrentPage) {
    //     this.nCurrentPage = incommingPage;

    //     let num_items = 10;
    //     let num_skip = (incommingPage - 1) * 10;
    //     this.accountManager.getLocale(this.sUserEmail,this.sSessionID,num_items, num_skip).then(res=>{

    //       this.lLocales =   res['results'];

    //       this.lLocales.forEach(ele => {
    //         if(this.AllLocaleSelected)
    //         {
    //           ele.selected = true;
    //         }else{
    //         this.lSelectedLocations.forEach(ee=>{

    //           if(ele.id == ee.id)
    //           {
    //             ele.selected = true;
    //           }
    //         });
    //       }

    //       });

    //     });
    //   }
    // }
    OverViewComponent_LocalesCheck() {}

    visibleLocation = false;
    placementLocation: NzDrawerPlacement = 'right';
    openLocation(): void {
        this.visibleLocation = true;
    }
    closeLocation(): void {
        this.visibleLocation = false;
    }

    selectedLocale(event){

        this.AllLocaleSelected = event.AllLocaleSelected;
        if(this.AllLocaleSelected == true){
            this.lSelectedLocations = []
        }
        else{
            this.lSelectedLocations = event.lSelectedLocations;
        }
        console.log("chkeve2", this.lSelectedLocations);
        
        this.visibleLocation = false;
    }
}
