import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cSessionService } from 'src/app/services/session.service';
import { ProntoSettingService } from 'src/app/services/utils/pronto-setting.service';
import { VoiceDataStore } from 'src/app/stores/voice.store';
import { VoiceOverService } from '../../services/voice-over.service';

@Component({
  selector: 'app-voice-over-filter-search-wizard',
  templateUrl: './voice-over-filter-search-wizard.component.html',
  styleUrls: ['./voice-over-filter-search-wizard.component.scss']
})
export class VoiceOverFilterSearchWizardComponent implements OnInit {
  filterState = true;
  attributesFilter : '';
  attributesFilters : '';
  searchAttributes : any[] = [];
  constructor( @Inject(MAT_DIALOG_DATA) public data: any,
  private oSessionService: cSessionService,
  public prontoService :ProntoSettingService,
  public voiceService : VoiceOverService,
  private dialogRef: MatDialogRef<VoiceOverFilterSearchWizardComponent>,public voiceDataStore: VoiceDataStore,) { }
  showBrowseVoice = false;
  showBrowserComponent = false;
  ngOnInit(): void {
    if(this.data?.showBrowseVoice && this.data?.showBrowseVoice == true){
      this.showBrowseVoice = true;
      this.showBrowserComponent = true;
    }
  }

  close() {
    this.dialogRef.close();
  }
  setVoiceAttribute(filter){
    this.filterState = true;
    filter.isActive = !filter.isActive;
    this.voiceService.setVoiceAttribute(filter);
    this.attributesFilter = filter.name;
  }
  applyFilter(){
    this.searchAttributes = [];

    this.attributesFilters = this.attributesFilter;

    this.voiceDataStore.voiceAttributes.map(attributes =>{
      attributes.filters.map(filter =>{
        if(filter.isActive){
          this.searchAttributes.push(filter.name);
        }
      })
    });
    this.showBrowserComponent = true;
  }
  clearFilter(){
    this.attributesFilters = '';
    this.showBrowserComponent = false;
    this.voiceDataStore.voiceAttributes.map(attributes =>{
      attributes.filters.map(filter =>{
        filter.isActive = false;
      })
    })
  }
}
