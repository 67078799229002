<div [ngClass]="{
    'px-4': !isSlideout,
    'py-5': !isSlideout
  }">
  <h5 class="modal-title mb-3" *ngIf="!isSlideout" id="exampleModalLabel">
    Select from My Media Library
  </h5>
  <div class="dropdown dropdownBtn" *ngIf="!bHideMediaLibrarySearchOptions || !isSlideout">
    <button class="dropdown-toggle text-md" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      <span class="ms-2 dropdownBtnText">All Media Libraries</span>
    </button>
    <div class="dropdown-menu dropdown-menu-end dropdownMenu rounded-4 mt-1 w-100" id="menu-set">
      <div class="dropdown-item">
        <div class="formItm search-icon searchDropdown">
          <form *ngIf="fSearchLibraryForm" [formGroup]="fSearchLibraryForm">
            <input id="search-feild" type="search" formControlName="name" placeholder="Search your video" required="" />
            <label for="search-feild" class="text-sm">Search media library</label>
            <!-- <img class="icon-right svg-hover" width="16" height="16"  src="../assets/images/svg/search.svg" /> -->
          </form>
        </div>

        <div class="d-flex row py-2">
          <div class="col-lg-3" *ngFor="let media of filterLibraryName(this.mediaManager?.mediaLibs)"
            style="height: 55px; width: 33% !important">
            <label class="customRadio mb-2" style="display: inline-grid; grid-template-columns: 15px 1fr">
              <input type="radio" name="libraryAccessRadio" [checked]="media.name == sSelectedMediaLibrary" (change)="
                  MediaLibrarySharedComponent_ChangeMedialibrary($event, media)
                " [value]="media.name" />
              <span class="customRadioCheck"></span>
              <span class="customRadioText text-sm text-wrap" style="word-break: break-all !important">{{ media.name
                }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container mx-0 px-0 business-media" id="container-view">
    <div class="row filter-post" *ngIf="!this.mediaPopUpLoc">
      <div class="col-md-12 p-0" *ngIf="!videoOnly && !audioOnly">
        <ul class="breadcrumb-tabs nav mx-2">
          <li *ngFor="let option of lBreadcrumbOptions; let i = index" class="nav-item" role="presentation"
            (click)="MediaLibrarySharedComponent_SelectMediaType(option.filter)">
            <a class="nav-link" [class.active]="option.filter == sVisualType">{{
              option.name
              }}</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="row video-header mx-0" *ngIf="!bHideMediaLibrarySearchOptions">
      <ul class="container p-0 mb-1 d-flex justify-content-between">
        <li class="col-md-4 mb-2">
          <div class="formItm search-icon searchDropdown">
            <input id="search-feild" type="search" [(ngModel)]="search" value="" placeholder="Search your video"
              required="" data-filter />
            <label for="search-feild" class="text-sm">Search media</label>
            <img class="icon-right svg-hover" width="16" height="16" src="../assets/images/svg/search.svg"
              (click)="searchMediaLibrary()" />
          </div>
        </li>
        <!-- <li class="col-md-4 mb-2 d-flex justify-content-end" id="selectpreff">
                <div class="formItm down_arrow me-0 mt-4 text-end">

                    <select>
                        <option selected>Last Modified</option>
                        <option>Select 1</option>
                        <option>Select 2</option>
                        <option>Select 3</option>
                    </select>

                </div>
            </li> -->
        <!-- <li *ngIf="bDisplayMediaButtons" class="col-md-6 mb-0 text-end">
                <ul class="text-end d-block mt-3 pt-2">
                    <li class="pe-4 text-sm m-0">
                        <a  href="javascript:void(0)" class="text-gray-3" data-sidebar="#multiselectmedia" data-custom-select>Select Media</a>
                    </li>
                    <li class="m-0">
                        <button class="btn weight-600 text-xs btn btn-primary btn-round-6 px-3 py-2" data-bs-toggle="modal" data-bs-target="#uploadModal">
                            Add Media
                        </button>

                    </li>
                </ul>
            </li> -->
      </ul>
    </div>

    <div class="row grid select-media overflow-auto d-block" [ngStyle]="{
        height: isSlideout ? 'calc( 100vh - 694px)' : '324px',
        width: isSlideout ? '103%' : '',
        'overflow-y': isSlideout ? 'scroll' : ''
      }">


      <!-- <ng-container *ngIf="!bSelectMultipleVisuals"> -->
      <li style="padding-right: 7px; margin-top:3px" *ngFor="
          let visual of this.mediaManager.visuals | removeAudioData : (sVisualType != 'audio') ? true : false
        ; let i = index" [ngStyle]="{ 'margin-bottom': isSlideout ? '16px' : '' }">
        <section data-tags="Images" *ngIf="visual.visual_type == 'image'">
          <div class="video-outer" style="min-width: 130px !important"
            (click)="MediaLibrarySharedComponent_PushSelectedVisual(visual)"
            [ngClass]="visual.isSelected ? 'bgrid videoWrapperActive' : ''">
            <!-- <div class="pageloader media-loader-outer" style="width: 100%" *ngIf="visual.loadingThumbnail">
              <div id="loader-inner" class="media-loader-inner"></div>
            </div> -->
            <div class="video_wrapper video_wrapper_full js-videoWrapper">
              <figure class="video-baner">
                <div class="pageloader media-loader-outer lite" *ngIf="visual.loadingThumbnail">
                  <div id="loader-inner" style="width:50px; height:50 px" class="media-loader-inner"></div>
                </div>
                <img *ngIf="visual.thumbnail_signed_url" [src]="visual.thumbnail_signed_url" alt="video1" />
              </figure>
            </div>
            <p *ngIf="!isSlideout" style="cursor: pointer; padding-left: 5px">
              {{ returnFileName(visual?.visual_path.split("/").pop()) }}
            </p>
          </div>

          <div class="custom-radioButton d-none">
            <label [for]="visual.visual_path" (click)="MediaLibrarySharedComponent_PushSelectedVisual(visual)">
              <input [type]="bSelectionType == true ? 'radio' : 'checkbox'" name="radiobtn" [id]="visual.visual_path"
                class="check form-check-input" [checked]="visual.isSelected"
                (change)="MediaLibrarySharedComponent_PassSelectedVisual(false)" />
            </label>
          </div>
        </section>

        <section data-tags="Videos" *ngIf="visual.visual_type == 'clip' || visual.visual_type == 'video'">
          <div class="video-outer" style="min-width: 130px !important"
            (click)="MediaLibrarySharedComponent_PushSelectedVisual(visual)"
            [ngClass]="visual.isSelected ? 'bgrid videoWrapperActive' : ''">
            <!-- <div class="pageloader media-loader-outer" style="width: 100%" *ngIf="visual.loadingThumbnail">
              <div id="loader-inner" class="media-loader-inner"></div>
            </div> -->
            <div class="video_wrapper video_wrapper_full js-videoWrapper">
              <div class="pageloader media-loader-outer lite" *ngIf="visual.loadingThumbnail">
                <div id="loader-inner" style="width:50px; height:50 px" class="media-loader-inner"></div>
              </div>
              <video controls controlsList="nodownload noplaybackrate"
                class="videoIframe js-videoIframe play-video-id-{{i}}" [muted]="true" [src]="visual.signed_url"
                [id]="visual.fpath" [ngStyle]="{ 'z-index': visual.loadingThumbnail ? '-1' : '' }"></video>
              <figure class="video-baner figure-bg" *ngIf="!visual.isSelected"
                [ngClass]="visual.loadingThumbnail ? 'd-none' : ''">
                <img *ngIf="visual.thumbnail_signed_url" [src]="visual.thumbnail_signed_url" alt="video1" />
                <span class="video-duration">{{ visual.duration | secondsToTime }}</span>
              </figure>
              <button class="videoPoster js-videoPoster" (click)="PlayVideo(visual, 'play-video-id-'+i)"></button>
            </div>
            <p *ngIf="!isSlideout" style="cursor: pointer; padding-left: 5px">
              {{ returnFileName(visual?.visual_path.split("/").pop()) }}
            </p>
          </div>
          <div class="custom-radioButton d-none">
            <label [for]="visual.visual_path" (click)="MediaLibrarySharedComponent_PushSelectedVisual(visual)">
              <input [type]="bSelectionType == true ? 'radio' : 'checkbox'" name="radiobtn" [id]="visual.visual_path"
                class="check form-check-input" [checked]="visual.isSelected"
                (change)="MediaLibrarySharedComponent_PassSelectedVisual(false)" />
            </label>
          </div>
        </section>
        <section *ngIf="visual.visual_type == 'audio'">
          <div (click)="MediaLibrarySharedComponent_PushSelectedVisual(visual)"
            [ngClass]="visual.isSelected ? 'bgrid videoWrapperActive' : ''"
            class="video-outer audio_design media-content-inner" [ngStyle]="{
              border:
                visual.fpath == this.mediaManager.selectedVisual?.fpath
                  ? '2px solid #FF8369'
                  : '0px solid #FF8369'
            }">
            <div class="pageloader media-loader-outer" style="width: 100%" *ngIf="visual.loadingThumbnail">
              <div id="loader-inner" class="media-loader-inner"></div>
            </div>

            <div class="audio_wrapper pt-1">
              <a href="javascript:void(0)" class="muteBtn btn-hover-w voice-btn mb-2"
              (mouseover)="AudioSidePanelComponent_PlayAudio(visual?.signed_url)"
              (mouseout)="AudioSidePanelComponent_StopAudio(visual?.signed_url)" >
                
              <img src="../assets/images/microphone-off.svg" class="off"
              style="margin-top: 0px;margin-left: 4px;" width="12" height="11" />
          <img src="../assets/images/microphone.svg" class="on"
              style="margin-top: 0px;margin-left: 4px;" width="12" height="11" />
              </a>
              <figure class="mb-0">
                <img [src]="this.imageStaticThumb" alt="audio-line" />
              </figure>
            </div>
            <p style="cursor: pointer">
              {{ returnFileName(visual?.fpath?.split("/").pop()) }}
            </p>
            <!-- <p class="text-xs m-0" style="height: 5vh;">Open your<br>{{visual?.fpath.split('/').pop()}} }}</p> -->
            <span class="audio-time text-gray-3 text-xxs">00:{{ visual?.duration | number:'1.0-0' }}</span>
          </div>
          <div class="custom-radiobtn">
            <label>
              <input type="checkbox" class="check form-check-input" [checked]="visual.isSelected"
                (click)="MediaLibrarySharedComponent_PushSelectedVisual(visual)" />
            </label>
          </div>
        </section>
      </li>
    </div>

    <!-- pagination index -->

    <div class="row">
      <div class="col-sm-12 text-end"
        [hidden]="this.mediaManager?.visualsCount == 0 || this.mediaManager?.visualsTotalCount == 0">
        <mat-paginator #paginator style="margin-top: 15px" [length]="this.mediaManager?.visualsTotalCount"
          [pageSize]="nPageSize" [pageSizeOptions]="[8, 16, 25, 50]" (page)="loadPageEvent($event)"
          showFirstLastButtons></mat-paginator>
      </div>
    </div>

    <!-- <ul id="pagination" *ngIf="this.mediaManager.visuals.length>0">
        <li>
            <a href="javascript:void(0)" class="preview-page" (click)="nCurrentPage==1?'':MediaLibrarySharedComponent_FetchPage(nCurrentPage-1)">
                <svg width="6" height="10" viewBox="0 0 6 10" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M5.25063 0.63694C5.39531 0.491231 5.39531 0.25499 5.25063 0.109281C5.10596 -0.0364277 4.8714 -0.0364277 4.72673 0.109281L0.944888 3.91824C0.351705 4.51568 0.351703 5.48432 0.944887 6.08175L4.72673 9.89072C4.8714 10.0364 5.10596 10.0364 5.25064 9.89072C5.39531 9.74501 5.39531 9.50877 5.25064 9.36306L1.46879 5.5541C1.16495 5.24808 1.16495 4.75192 1.46879 4.4459L5.25063 0.63694Z"
                        fill="#7A7A7A" />
                </svg>
            </a>
        </li>
        <li *ngFor="let page of lPagination;index as i">
            <a href="javascript:void(0)" (click)="MediaLibrarySharedComponent_FetchPage(page)" [ngClass]="i+1==nCurrentPage?'add_color':''"
            >{{page}}</a>
        </li>
        <li>
            <a href="javascript:void(0)" class="next-page" (click)="nCurrentPage==lPagination.length?'':MediaLibrarySharedComponent_FetchPage(nCurrentPage+1)">
                <svg width="6" height="10" viewBox="0 0 6 10" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.749366 0.63694C0.604694 0.491231 0.604694 0.25499 0.749366 0.109281C0.894037 -0.0364277 1.1286 -0.0364277 1.27327 0.109281L5.05511 3.91824C5.6483 4.51568 5.6483 5.48432 5.05511 6.08175L1.27327 9.89072C1.1286 10.0364 0.894036 10.0364 0.749364 9.89072C0.604692 9.74501 0.604693 9.50877 0.749364 9.36306L4.53121 5.5541C4.83505 5.24808 4.83505 4.75192 4.53121 4.4459L0.749366 0.63694Z"
                        fill="white" />
                </svg>
            </a>
        </li>

    </ul> -->
    <div *ngIf="!bHideMediaLibrarySearchOptions">
      <button class="btn btn-md2 btn-secondary btn-round-6 mt-4 mb-2" href="javascript:void"
        [disabled]="lSelectedUserVisualsList.length == 0" (click)="MediaLibrarySharedComponent_PassSelectedVisual(true)"
        data-bs-dismiss="modal" aria-label="Close">
        Select
      </button>
    </div>
  </div>
</div>