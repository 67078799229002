
        <div class="h-85vh br12 curve-bg tab-content bg-white page-shadow  m-0 p-0" id="pills-tabContent">
            <div class="tab-pane fade show active">
                <div class="content_area p-0">
                    <div class="row m-0">

                        <div class="col-sm-12 col-md-12 col-lg-5 h-85vh ps-5 pt-4">
                            <div class="row ps-4 pt-3 mt-1">
                                <p class="text-lg text-gray-3 primaryFont"><span class="counts">1. </span> How many videos are looking to localize in this session?</p>
                                <div>

                                    <button class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 mb-3"
                                    data-bs-toggle="modal" data-bs-target="#uploadModal">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.37278 1.37278C2.36587 0.379685 3.85193 0 5.76744 0H10.2326C12.1481 0 13.6341 0.379685 14.6272 1.37278C15.6203 2.36587 16 3.85193 16 5.76744V10.2326C16 12.1481 15.6203 13.6341 14.6272 14.6272C13.6341 15.6203 12.1481 16 10.2326 16H5.76744C3.85193 16 2.36587 15.6203 1.37278 14.6272C0.379685 13.6341 0 12.1481 0 10.2326V5.76744C0 3.85193 0.379685 2.36587 1.37278 1.37278ZM2.16211 2.16211C1.48078 2.84343 1.11628 3.96203 1.11628 5.76744V10.2326C1.11628 12.038 1.48078 13.1566 2.16211 13.8379C2.84343 14.5192 3.96203 14.8837 5.76744 14.8837H10.2326C12.038 14.8837 13.1566 14.5192 13.8379 13.8379C14.5192 13.1566 14.8837 12.038 14.8837 10.2326V5.76744C14.8837 3.96203 14.5192 2.84343 13.8379 2.16211C13.1566 1.48078 12.038 1.11628 10.2326 1.11628H5.76744C3.96203 1.11628 2.84343 1.48078 2.16211 2.16211Z" fill="#FF8369"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.666992 4.33333C0.666992 4.14924 0.907699 4 1.20463 4H14.796C15.093 4 15.3337 4.14924 15.3337 4.33333C15.3337 4.51743 15.093 4.66667 14.796 4.66667H1.20463C0.907699 4.66667 0.666992 4.51743 0.666992 4.33333Z" fill="#FF8369"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.33366 0C5.70185 0 6.00033 0.246384 6.00033 0.550314V4.11635C6.00033 4.42028 5.70185 4.66667 5.33366 4.66667C4.96547 4.66667 4.66699 4.42028 4.66699 4.11635V0.550314C4.66699 0.246384 4.96547 0 5.33366 0Z" fill="#FF8369"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6667 0C11.0349 0 11.3333 0.265144 11.3333 0.592217V4.07445C11.3333 4.40152 11.0349 4.66667 10.6667 4.66667C10.2985 4.66667 10 4.40152 10 4.07445V0.592217C10 0.265144 10.2985 0 10.6667 0Z" fill="#FF8369"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.22493 8.46551C7.14028 8.51871 7.00036 8.68819 7.00036 9.13016V10.8697C7.00036 11.3116 7.14028 11.4811 7.22493 11.5343C7.30921 11.5873 7.51299 11.6345 7.86348 11.414L9.25064 10.5442C9.25075 10.5441 9.25054 10.5443 9.25064 10.5442C9.6008 10.3238 9.66631 10.1073 9.66631 9.99992C9.66631 9.8925 9.60111 9.67626 9.25095 9.45584C9.25085 9.45577 9.25105 9.4559 9.25095 9.45584L7.86348 8.58588C7.51299 8.36535 7.30921 8.41253 7.22493 8.46551ZM8.36435 7.64477C7.82794 7.30738 7.22492 7.20957 6.7241 7.52437C6.22354 7.839 6 8.45592 6 9.13016V10.8697C6 11.5439 6.22354 12.1608 6.7241 12.4755C7.22493 12.7903 7.82796 12.6925 8.36438 12.355C8.36427 12.3551 8.36448 12.355 8.36438 12.355L9.75186 11.4851C10.2884 11.1475 10.6667 10.6281 10.6667 9.99992C10.6667 9.37169 10.2884 8.85235 9.75186 8.51475L9.05762 8.07946L8.36435 7.64477C8.36447 7.64484 8.36424 7.6447 8.36435 7.64477Z" fill="#FF8369"/>
                                        </svg> 
                                         &nbsp;	&nbsp; Select From Media Library
                                </button>

                                </div>
                                <p class="text-lg text-gray-3 primaryFont">Maximum limit to select multiple videos is X</p>
                            </div>
                            
                    <div class="row ps-4 pt-3 mt-1">
                        <div class="row grid m-0 select-media" id="media-view">
                            <ng-container>
                                <li id="#selectmedia" class="media-content" style="list-style: none;">
                
                                    <!-- video -->
                                    <section>
                                        <div class="video-outer media-content-inner">
                                      
                                            <div class="video_wrapper video_wrapper_full js-videoWrapper p-0">
                                                <!-- <video controls controlsList="nodownload noplaybackrate" class="videoIframe js-videoIframe" [muted]="true" ></video> -->
                                              
                                              <figure class="video-baner">
                                                <img src="../../../../assets/images/videoTimeline/publishVideoImg.jpg" width="100%" height="100%">
                                                    <!-- <span class="video-duration">00:00</span> -->
                                                </figure> 
                                                <button class="videoPoster js-videoPoster"></button> 
                                            </div>
                                            
                                            <p style="cursor:pointer ;">video title </p>
                                        </div>
                                        <!-- <div class="custom-radiobtn">
                                            <label>
                                                <input type="checkbox" class="check form-check-input">
                                            </label>
                                        </div> -->
                                    </section>
                                </li>
                            </ng-container>
                        </div>
                    </div>
                        </div>


                        <div class="col-sm-12 col-md-12 col-lg-3"></div>

                        <div class="col-sm-12 col-md-12 col-lg-4 small-side pt-4">
                            <!-- <div class="right-sde-info enable-loc-div"> </div> -->
                        </div>


                        
                    </div>
                </div>
            </div>
            <div class="wtermarklogo">
                <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
            </div>
        </div>
