import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { VideoService } from '../dataServices/video.service';
import { environment } from 'src/environments/environment';
import { cSessionService } from '../session.service';

export interface BackButtonDataModel {
    visible?: boolean;
    text?: string;
}

@Injectable({
    providedIn: 'root',
})
export class ProntoSettingService {
    previousUrl: any;
    hideDropDownOnDocumentClick: Subject<boolean> = new Subject();
    videoDataView: Subject<any> = new Subject();
    showBrowserBackButtonDialog = false;
    showSapVideoTimeLineDurationBar = false;
    defaultFontList: any[] = [];
    systemAspects = [
        { value: 'square', ratio: '1:1', ratio_value: '1x1', class: 'square', selected: false },
        { value: 'story/reels', ratio: '9:16', ratio_value: '9x16', class: 'story-reels', selected: false },
        { value: 'Landscape', ratio: '16:9', ratio_value: '16x9', class: 'landscape', selected: false },
        { value: 'Portrait', ratio: '4:5', ratio_value: '4x5', class: 'portrait', selected: false },
    ];
    videoAspectRatioTabs = [
        { title: 'All', ratio: 'all', ratio_value: 'all', isSelected: true, class_name: 'all' },
        { title: 'Landscape', ratio: '16:9', ratio_value: '16x9', isSelected: false, class_name: 'landscape' },
        { title: 'Square', ratio_value: '1x1', ratio: '1:1', isSelected: false, class_name: 'square' },
        { title: 'Story/Reels', ratio_value: '9x16', ratio: '9:16', isSelected: false, class_name: 'reel' },
        { title: 'Portrait', ratio: '4:5', ratio_value: '4x5', isSelected: false, class_name: 'portrait' },
    ];
    public icons = {
        play_icon: '../assets/images/icons/myaccount/audio_loader.svg',
        checked_green: '../assets/images/icons/green-checkbox.svg',
        unchecked: '../assets/images/icons/round-check-box-off.png',
        checkbox_default_selected: '../assets/images/icons/myaccount/checkbox-checked.svg',
        checkbox_default: '../assets/images/icons/myaccount/checkbox-unchecked.svg',
        checkbox_green_mark: '../assets/images/icons/myaccount/checked-mark-green.svg',
        checkbox_off: '../assets/images/icons/myaccount/checked-gray-off.svg',
        checkbox_green: '../assets/images/icons/myaccount/checked-gray-green.svg',
        download_off: '../assets/images/icons/myaccount/download-icon-off.svg',
        download_hover: '../assets/images/icons/myaccount/download-icon-hover.svg',
        move_to_lib_hover: '../assets/images/icons/myaccount/move-to-library-icon-hover.svg',
        move_to_lib: '../assets/images/icons/myaccount/move-to-library-icon.svg',
        cross_off: '../assets/images/icons/myaccount/cross-square.svg',
        cross_hover: '../assets/images/icons/myaccount/cross-square-hover.svg',
        upload: '../assets/images/icons/myaccount/upload.svg',
        avatar: '../assets/images/icons/myaccount/avatar.svg',
        play_on: '../assets/images/icons/myaccount/play-orange.svg',
        play_off: '../assets/images/icons/myaccount/play-gray.svg',
        checkbox_blue_mark: '../assets/images/icons/myaccount/checkbox-checked.svg',
        cross: '../assets/images/icons/myaccount/cross.svg',
        green_check_white_bg: '../assets/images/icons/myaccount/green check with white bg.svg',
        unselected: '../assets/images/icons/myaccount/selected-off.svg',
        favorite_off: '../assets/images/icons/myaccount/favorite-off.svg',
        favorite_on: '../assets/images/icons/myaccount/favorite-on.svg',
        green_tick: '../assets/images/aspect ratio/grenn tick.svg',
    };

    public settings = {
        aspect_popup: { width: '240px', height: '310px', max_width: '240px', max_height: '310px' },
    };

    private backButtonDialogTextSubject = new Subject<BackButtonDataModel>();

    sVideoStatus: string;
    constructor(private videoService: VideoService, private http: HttpClient, private oSessionService: cSessionService) {}

    hideAllDropDown() {
        this.hideDropDownOnDocumentClick.next(true);
    }
    dropDownHideSubscribe() {
        return this.hideDropDownOnDocumentClick;
    }
    /*
     ----- Section BackBUttonDialog ------
  */

    emitBackButtonDialogTextSubject(value: BackButtonDataModel) {
        this.backButtonDialogTextSubject.next(value);
    }

    getBackButtonDialogTextSubject() {
        return this.backButtonDialogTextSubject.asObservable();
    }

    public closeBrowserBackButtonDialog() {
        this.backButtonDialogTextSubject.next({ visible: false, text: '' });
    }
    public openBrowserBackButtonDialog() {
        this.showBrowserBackButtonDialog = true;
    }

    public getAspectClassName(aspect) {
        let className = 'landscape';

        switch (aspect) {
            case '1x1':
                className = 'square';
                break;
            case '9x16':
                className = 'reel';
                break;
            case '4x5':
                className = 'portrait';
                break;
        }

        return className;
    }
    public getVideoPopupAspectCols(aspect) {
        let columnsVid = 4;

        switch (aspect) {
            case '16:9':
                columnsVid = 3;
                break;
            case '16x9':
                columnsVid = 3;
                break;
        }

        return columnsVid;
    }

    public transformLeft(transform) {
        if (transform != '' && transform.split('translate3d(').length > 1) {
            if (transform.split('translate3d(')[1].split(',').length > 1) {
                if (transform.split('translate3d(')[1].split(',')[0].split('px').length > 1) {
                    return transform.split('translate3d(')[1].split(',')[0].split('px')[0];
                }
            }
        }
        return 0;
    }

    public findVideoPlayerWithSameSrc(src) {
        let videoElement = document.querySelectorAll('video');
        let findSrc = false;
        videoElement.forEach((vid) => {
            if (vid.src == src) {
                // console.log(vid);
                findSrc = true;
                console.log('Video State ', vid.readyState);

                if (vid.readyState === 4) {
                    console.log(' Duration ', vid.duration);
                    // it's loaded
                }
            }
        });
        return findSrc;
    }

    public async getSignedUrl(email, session, path): Promise<string> {
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append('session_id', session);
            formData.append('email', email);
            formData.append('fpath', path);
            this.http
                .post(environment.API_HOST + 'signed_url_for_download', formData)
                .toPromise()
                .then((res) => {
                    resolve(res['url']);
                });
        });
    }

    loginInfo(): any {
        return {
            email: this.oSessionService.cSessionService_GetUserEmail(),
            session_id: this.oSessionService.SessionService_GetSessionId(),
        };
    }

    hex2bgrColor = (hex: any) => {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        return [b, g, r, 255];
    };

    componentToHex(c) {
        var hex = c.toString(16);
        return hex.length == 1 ? '0' + hex : hex;
    }
    rgbToHex(r, g, b) {
        return '#' + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
    }
    bgrToHex(color) {
        if (color?.length > 2) {
            return '#' + this.componentToHex(color[2]) + this.componentToHex(color[1]) + this.componentToHex(color[0]);
        } else {
            return '#ffffff';
        }
    }

    downloadCSV(data) {
        const csvContent = 'data:text/csv;charset=utf-8,' + data.map((row) => row.join(',')).join('\n');

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'media_paths.csv');
        document.body.appendChild(link);

        link.click(); // This will download the data file named "data.csv".
    }
}
