<div class="br12 curve-bg tab-content bg-gray-9 page-shadow m-0 p-0 " id="pills-tabContent">
    <div class="tab-pane fade show active">
        <div class="content_area p-0">

            <div class="row m-0">
                <div class="col-sm-12 col-md-12 col-lg-12 bg-white h-85vh ps-5 pt-4">
                    <div class="row ps-4">

                        <div class="col-sm-12">
                            <div class="formItm static_lable order_list mb-2">
                                <label for=""><span class="counts">3.</span>Add keywords to describe your video</label>
                                <div class="createTag_wrapp pt-2 mt-1">
                                    <figure class="my-1 mx-2">
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M11.8484 0.624023L8.09033 0.745273C7.50845 0.794023 6.97532 1.0359 6.56282 1.44839L1.15722 6.85399C0.454103 7.55711 0.454103 8.69655 1.15722 9.39959L4.59978 12.8422C4.93916 13.1815 5.39978 13.3753 5.86041 13.3753C6.32103 13.3753 6.78165 13.2053 7.12103 12.8422L12.5273 7.43591C12.9391 7.02404 13.1816 6.49028 13.2304 5.88467L13.3754 2.1509C13.3998 1.73903 13.2298 1.3509 12.9391 1.06028C12.6485 0.769657 12.2604 0.624025 11.8485 0.624025L11.8484 0.624023ZM9.73897 5.90898C8.84209 5.90898 8.11465 5.18148 8.11465 4.28466C8.11465 3.38785 8.84215 2.66034 9.73897 2.66034C10.6358 2.66034 11.3633 3.38785 11.3633 4.28466C11.3633 5.18148 10.6358 5.90898 9.73897 5.90898Z"
                                                fill="#7A7A7A" />
                                        </svg>
                                    </figure>
                                    <span class="tag mb-2" *ngFor="let keyterm of lKey_terms;let i = index;">{{keyterm}}
                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                            class="me-1 position-relative" width="10" height="10" viewBox="0 0 24 24"
                                            style=" fill:#7A7A7A; left: 5px;"
                                            (click)="CreateVideoLooknFeelComponent_RemoveTagListElements(i)">
                                            <path
                                                d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z">
                                            </path>
                                        </svg>
                                    </span>
                                    <div class="tagfield createTag_wrappAdd"
                                        [ngClass]="bDisplayAddInputField?'d-inline-block':'d-none'"><input
                                            class="add-Tag" maxlength="32" #AddInputTagElement
                                            onkeypress="return event.charCode != 32" (focusout)="AddTagInputField()"
                                            (keydown)="addTagOnKeyEnter($event.keyCode)" [(ngModel)]="inputValue"
                                            [ngModelOptions]="{standalone: true}" type="text"
                                            (change)="CreateVideoLooknFeelComponent_GetInputValue($event)"><input
                                            class="tag-btn" type="button" value="Add" [disabled]="!inputValue"
                                            (click)="AddTagInputField()" toggle-class="#createTag_wrapp">
                                    </div>
                                    <a *ngIf="!bDisplayAddInputField" href="javascript:void(0)"
                                        (click)="CreateVideoLooknFeelComponent_ToggleTagButtonAndAddInput()"
                                        class="text-nowrap text-sm text-primary-4 ms-2 createTag_wrappAddBtn"
                                        toggle-class="#createTag_wrapp">
                                        <svg class="me-1" width="12" height="12" viewBox="0 0 12 12" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M6.26087 0.391304C6.26087 0.175193 6.08568 0 5.86957 0C5.65345 0 5.47826 0.175193 5.47826 0.391304L5.47826 5.73913H0.391304C0.175193 5.73913 0 5.91432 0 6.13043C0 6.34655 0.175193 6.52174 0.391304 6.52174H5.47826L5.47826 11.6087C5.47826 11.8248 5.65345 12 5.86956 12C6.08568 12 6.26087 11.8248 6.26087 11.6087L6.26087 6.52174H11.6087C11.8248 6.52174 12 6.34655 12 6.13043C12 5.91432 11.8248 5.73913 11.6087 5.73913H6.26087L6.26087 0.391304Z"
                                                fill="#FF8369" />
                                        </svg>
                                        Add a Keyword
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <div class="formItm static_lable order_list mb-0">
                                <label for="">
                                    <span class="counts">4.</span>Upload your logo
                                </label>
                                <button (click)="openFileUploadModal('image_video_logo','Upload a Logo', 'logoUpload')"
                                    id="custom-button-svg"
                                    class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btn-md-same-pad btnShadow d-flex align-items-center mt-3">
                                    <span class="upload-icon"></span>
                                    Primary Logo
                                </button>
                                <span *ngIf="!this.isLogoSelected" class="error ms-0 ">Please upload
                                    or select a file</span>
                                <!-- Uploaded Logo file -->
                                <span *ngIf="bDisplayUploadLogoSection">
                                    <div class="media_list mt-2" *ngIf="oUploadedLogo">
                                        <ul>
                                            <li class="mb-3">
                                                <figure class="media_figure">
                                                    <span class="media">
                                                        <img [src]="oUploadedLogo.url? oUploadedLogo.url : oUploadedLogo.thumbnail_signed_url" alt="logo"></span>
                                                    <figcaption>
                                                        <div class="caption w-100">
                                                            <!-- <p class="text-xs title">{{oUploadedLogo.filename |
                                                                slice:0:30}}</p>
                                                            <p class="text-xs sub_title">
                                                                {{CreateVideoLooknFeelComponent_ReturnFileSize(oUploadedLogo.size)}}
                                                            </p> -->
                                                            <p *ngIf="oUploadedLogo?.name != undefined && oUploadedLogo?.name !=''"
                                                                class="text-xs title"
                                                                style="color: rgba(75, 76, 82, 0.876);">
                                                                {{oUploadedLogo?.name | slice:0:30}}</p>
                                                            <p *ngIf="oUploadedLogo?.size != undefined && oUploadedLogo?.size > 0 "
                                                                class="text-xs sub_title">{{ oUploadedLogo?.size |
                                                                formatFileSize:false}}
                                                            </p>
                                                        </div>
                                                    </figcaption>
                                                </figure>
                                            </li>
                                        </ul>
                                    </div>
                                </span>

                            </div>
                        </div>

                        <div class="col-sm-12">

                            <form [formGroup]="formGroup">
                                <div class="formItm static_lable order_list mb-3">
                                    <label for=""><span class="counts">5.</span>Apply primary logo at video start &
                                        end?</label>
                                    <!-- <p class="text-xs text-gray-6">5.   Apply primary logo at video start & end?. </p> -->
                                    <div class="videoimg_wrapp green-radio">
                                        <label (click)="togglePrimaryLogoAtVideoStart(true)">
                                            <img src="../assets/images/icons/green-checkbox.svg"
                                                *ngIf="applyPrimaryLogoAtVideoStart" />
                                            <img src="../assets/images/icons/round-check-box-off.png"
                                                *ngIf="!applyPrimaryLogoAtVideoStart" />
                                            <span class="ps-2">Yes</span>
                                        </label>
                                        <label class="ps-3" (click)="togglePrimaryLogoAtVideoStart(false)">
                                            <img src="../assets/images/icons/green-checkbox.svg"
                                                *ngIf="!applyPrimaryLogoAtVideoStart" />
                                            <img src="../assets/images/icons/round-check-box-off.png"
                                                *ngIf="applyPrimaryLogoAtVideoStart" />
                                            <span class="ps-2">No</span>
                                        </label>
                                    </div>
                                    <div>
                                        <button *ngIf="!applyPrimaryLogoAtVideoStart"
                                            (click)="openFileUploadModal('image_video_logo','Upload Secondary Logo', 'secondaryLogoUpload')"
                                            id="custom-button-svg"
                                            class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btn-md-same-pad btnShadow d-flex align-items-center mt-3">
                                            <span class="upload-icon"></span>
                                            Upload Secondary Logo
                                        </button>
                                        <span *ngIf="secondaryLogoFile != ''">
                                            <div class="media_list mt-2" *ngIf="secondaryLogoFile">
                                                <ul>
                                                    <li class="mb-3">
                                                        <figure class="media_figure">
                                                            <span class="media">
                                                                <img [src]="secondaryLogoFile.url? secondaryLogoFile.url : secondaryLogoFile.thumbnail_signed_url" alt="logo"></span>
                                                            <figcaption>
                                                                <div class="caption w-100">
                                                                    <p *ngIf="secondaryLogoFile?.name != undefined && secondaryLogoFile?.name !=''"
                                                                        class="text-xs title"
                                                                        style="color: rgba(75, 76, 82, 0.876);">
                                                                        {{secondaryLogoFile?.name | slice:0:30}}</p>
                                                                    <p *ngIf="secondaryLogoFile?.size != undefined && secondaryLogoFile?.size > 0 "
                                                                        class="text-xs sub_title">{{
                                                                        secondaryLogoFile?.size | formatFileSize:false}}
                                                                    </p>
                                                                </div>
                                                            </figcaption>
                                                        </figure>
                                                    </li>
                                                </ul>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>

                <!-- <div class="col-sm-12 col-md-12 col-lg-5 p-5">
                    <p class="text-gray-2 text-lg">Template Preview</p>
                    <div id="container-view">
                        <li>
                            <div class="video-outer look-feel-video">
                                <div id="TemplateThumbnail" class="video_wrapper video_wrapper_full js-videoWrapper">
                                    <span>
                                        <video controls loop #previewTemplate controlsList="nodownload noplaybackrate"
                                            class="videoIframe js-videoIframe"
                                            [src]="this.selectedTemplate?.preview_url" title=""
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen>
                                        </video>
                                    </span>
                                    <figure class="video-baner">
                                        <img [src]="this.selectedTemplate?.thumbnail | safe" alt="video">
                                    </figure>
                                    <button class="videoPoster js-videoPoster" (click)="PreviewTemplate()"></button>
                                </div>

                            </div>
                        </li>
                    </div>
                </div> -->
            </div>

        </div>
    </div>
    <div class="wtermarklogo">
        <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
    </div>
</div>

<!-- upload media -->
<!-- <div class="modal fade" id="uploadModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg drag-dropfile">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Upload a Logo</h5>
                <button type="button" #closeModal class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="form-group files">
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-device" role="tabpanel"
                        appDragDropFileUpload (fileDropped)="startLogoUploadProcess($event, 'dragdrop')"
                            aria-labelledby="pills-device-tab">

                            <div class="file-drop-area">

                                <span class="upload-icon m-auto"></span>
                                <span class="choose-file-button">Upload a File (0 files)</span>
                                <span class="text-line">Click to <font class="text-primary-3">browse</font>, or<br>
                                    drag & drop your file here</span>
                                <input #file class="file-input" type="file" accept="image/*"
                                    (change)="startLogoUploadProcess($event)">
                            </div>
                        </div>


                        <ul class="d-flex mb-3" id="pills-tab" role="tablist">
                            <li class="col" role="presentation">
                                <button (click)="file.click()" id="pills-device-tab"
                                    data-bs-toggle="pill" data-bs-target="#pills-device" type="button" role="tab"
                                    aria-controls="pills-device" aria-selected="true">
                                    <img src="../assets/images/business_Profile/device.png" alt="tab-icon">
                                    <span>My Device</span>
                                </button>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- error modal -->
<div class="modal fade" id="error-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog width-483 modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-body">
                <button type="button" class="btn-close cust-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <h5 class="mb-4">{{sDisplayResponseErrorMessage}}</h5>
                <div class="alert alert-danger p-3 mb-4 g-0 row rounded-1">
                    <div class="col-1 p-0">
                        <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                                <path
                                    d="M13.9993 3.47998C6.92399 3.47998 1.16602 9.20176 1.16602 16.2326C1.16602 23.2635 6.92399 29 13.9993 29C21.0747 29 26.8327 23.2782 26.8327 16.2473C26.8327 9.21646 21.0747 3.47998 13.9993 3.47998ZM15.1095 15.7619C15.1095 16.365 14.6062 16.8651 13.9993 16.8651C13.3925 16.8651 12.8892 16.365 12.8892 15.7619V8.40747C12.8892 7.80441 13.3925 7.3043 13.9993 7.3043C14.6062 7.3043 15.1095 7.80441 15.1095 8.40747V15.7619Z"
                                    fill="#FF8369"></path>
                                <path
                                    d="M17.7366 2.32H10.2647C9.74765 2.32 9.33398 1.808 9.33398 1.168C9.33398 0.528 9.74765 0 10.2647 0H17.7366C18.2537 0 18.6673 0.512 18.6673 1.152C18.6673 1.792 18.2537 2.32 17.7366 2.32Z"
                                    fill="#FF8369"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="28" height="29" fill="white"></rect>
                                </clipPath>
                            </defs>
                        </svg>


                    </div>
                    <div class="col-11 p-0">

                        <p class="text-sm text-gray-3 mb-0 ms-2">{{sDisplayResponseErrorMessage}}</p>
                    </div>
                </div>

                <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" data-bs-dismiss="modal"
                    routerLink="/dashboard/myvideos">Go Back</a>

            </div>
        </div>
    </div>
</div>
<!-- error modal end -->
<nz-modal [(nzVisible)]="showLogoUploadModal" nzCentered [nzWidth]="566" [nzFooter]=null [nzClosable]="false"
    [nzKeyboard]="false">

    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-body">
                <!-- <button type="button" class="btn-close cust-close closeRightBtn" (click)="hideUploadingPopUP()"></button> -->

                <h5>Logo File Uploading</h5>
                <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">Your Logo is uploading, Please wait.
                </p>
                <!-- <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" >Ok</a> -->

            </div>
        </div>
    </div>
</nz-modal>