import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MediaManagerService } from 'src/app/Managers/media-manager.service';
import { OnboardingManagerService } from 'src/app/Managers/onboarding-manager.service';
import { brand } from 'src/app/models/admin';
import { mediaFile } from 'src/app/models/media';
import { MyAccountService } from 'src/app/services/dataServices/my-account.service';
import { cSessionService } from 'src/app/services/session.service';
import { BrandService } from '../service/BrandService';
import { CreateVideoManagerService } from 'src/app/Managers/create-video-manager.service';
import { VideoService } from 'src/app/services/dataServices/video.service';

@Component({
  selector: 'app-manage-brands-business-details',
  templateUrl: './manage-brands-business-details.component.html',
  styleUrls: ['./manage-brands-business-details.component.scss']
})
export class ManageBrandsBusinessDetailsComponent implements OnInit {

  brandDetail: any = [];
  brandId: string;
  isEdit: boolean = false;
  public updateForm: FormGroup;
  updateFormSubmitted = false;
  logoFile: any;
  brandLogoPath = "";
  bDisplayErrorMessage: boolean = false;
  showDeleteBrandModal = false;
  defaultMediaLib: any;
  displayBrandLogo = false;
  verifyBrandLogoComplete = false;
  brandSocialNetworkConnection = {
    fb: false,
    linkedin: false,
    youtube: false,
    instagram : true
  };
  selectedFont : any;
  primaryColor = undefined;
  fontDefaultColor = "#ffffff";
  fontArray: any[] = [];
  secondary_colors: any[] = [];


  constructor(private adminBrandManager: MyAccountService, public router: Router,
    private route: ActivatedRoute, private updateFormGroupBuilder: FormBuilder,
    private mediaManger: MediaManagerService,
    public videoManager : CreateVideoManagerService,
    private oSessionService: cSessionService,
    private brandService : BrandService,
    public onboardingManger: OnboardingManagerService,
    private videoService : VideoService) { }

  ngOnInit(): void {

    this.brandId = this.route.snapshot.params["id"];
    this.InitializeJqueryCode();
    this.getBrandProfile();
    // this.brandDetail = this.adminBrandManager.brandDetail;
    // this.brandLogoPath = this.adminBrandManager.brandDetail.logo_file;
    // this.updateBrandAvatarPath(this.brandLogoPath);

    this.updateFormSubmitted = false;

    this.updateForm = this.updateFormGroupBuilder.group({
      name: this.brandDetail.name ? this.brandDetail.name :
        'Business Name',
      website: this.brandDetail.website ? this.brandDetail.website :
        'website',
      industry: this.brandDetail.industry ? this.brandDetail.industry :
        'Industry Name'
    });

  }
  getBrandProfile() {
    this.displayBrandLogo = false;
    let __this = this;
    this.adminBrandManager
      .getBrandProfile(this.brandId)
      .then((brand: brand) => {

        __this.brandDetail = brand;
        this.brandLogoPath = brand.logo_file;
        this.defaultMediaLib = (brand.media_libs && brand.media_libs.length > 0) ? brand.media_libs[0]?._id?.$oid : undefined;
        if(this.brandDetail.fonts){
          this.fontArray = this.brandDetail.fonts;
          if (this.fontArray && this.fontArray.length > 0) {
            let externalFontsStyleSheet = "";

            this.videoService
              .getAllSignedURLs({
                email: this.oSessionService.cSessionService_GetUserEmail(),
                session_id: this.oSessionService.SessionService_GetSessionId(),
                fpaths: this.fontArray,
              })
              .then((result: any) => {
                result.output.map(async (item: any) => {
                  // console.log(" item ", item);
                  // console.log(Object.values(item));
                  // console.log(Object.keys(item));

                  let fontPath = Object.keys(item)[0];
                  let fontSignedURLPath = Object.values(item)[0];

                  externalFontsStyleSheet += this.getFontFaceStyleCSS(
                    fontPath,
                    fontSignedURLPath
                  );
                });

                var head = document.getElementsByTagName("head")[0];
                let styleElement = document.createElement("style");
                styleElement.textContent = externalFontsStyleSheet;
                head.appendChild(styleElement);

                // console.log(" urls of brand fonts ", result);
              });
          }
        }
        if(this.brandDetail.primary_color){
          this.primaryColor = this.brandDetail.primary_color;
        }
        if(this.brandDetail.primary_font){
          this.selectedFont = this.brandDetail.primary_font;

        }
        console.log("Get brand called ....");
        if(this.brandDetail.secondary_colors && this.brandDetail.secondary_colors != '' && this.brandDetail.secondary_colors.length > 0){
          this.secondary_colors = [];
          this.brandDetail.secondary_colors.map((color, index ) => {
            this.secondary_colors.push({ id: index,color: color});
          });
        } else {
          this.secondary_colors = [];
          this.secondary_colors.push({ id: 0 ,color: this.videoManager.fontDefaultColor});
        }
        if (this.brandLogoPath && this.brandLogoPath != '') {
          this.updateBrandAvatarPath(this.brandLogoPath);
        } else {
          this.verifyBrandLogoComplete = true;
        }
        this.checkBrandFBConnected();
        this.checkBrandLinkedInConnected();
        this.checkInstagramConnected();
        this.checkBrandYouTubeConnected();
      });
  }

  InitializeJqueryCode() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.text = `
    Coloris({
      el: '.coloris',
      swatches: [
          '#264653',
          '#2a9d8f',
          '#e9c46a',
          '#f4a261',
          '#e76f51',
          '#d62828',
          '#023e8a',
          '#0077b6',
          '#0096c7',
          '#00b4d8',
          '#48cae4',
      ]
  });`;
    document.body.appendChild(chatScript);
  }

  loadBrandAccount() {
    this.router.navigate([`/account-setting/manage-brand/account/${this.brandId}`]);
  }
  loadBrandBusiness() {
    this.router.navigate([`/account-setting/manage-brand/${this.brandId}/business-details`]);
  }
  loadBrandMediaLibrary() {
    this.router.navigate([`/account-setting/manage-brand/${this.brandId}/media-libraries`]);
  }
  loadBrandMediaLibraryList() {
    this.router.navigate([`/account-setting/manage-brand/${this.brandId}/list-media-libraries`]);
  }

  editBusinessBrand() {
    this.isEdit = true;
  }
  cancelBusinessBrand() {
    this.isEdit = false;
  }
  updateBusinessBrand() {
    this.updateFormSubmitted = true;
    if (this.updateForm.invalid) {
      console.log("form error");
    }
    else {
      const brandInfo: brand = {
        _id: this.brandId,
        name: this.updateForm.controls["name"].value,
        website: this.updateForm.controls["website"].value,
        industry: this.updateForm.controls["industry"].value,
      };

      console.log("brandInfo", brandInfo);

      this.adminBrandManager.updateBrand(brandInfo).then((res: any) => {
        this.brandDetail = res['result'];
        console.log("brandInfo2", res['result']);
        console.log("brandInfo3", res);
      });
      this.isEdit = false;
    }
  }
  uploadBrandLogo(event) {
    let file = event.target.files[0];

    if (file) {
      let media = new mediaFile();
      media.file = file;
      media.name = file.name;

      if (this.defaultMediaLib) {

      }
    }
  }
  updateBrandAvatarPath(fPath) {
    const fpahArray = [fPath];
    console.log(fpahArray)
    this.mediaManger
      .getMediaThumbandURL(
        this.oSessionService.cSessionService_GetUserEmail(),
        this.oSessionService.SessionService_GetSessionId(),
        fpahArray
      )
      .then((resThumb: any) => {
        this.verifyBrandLogoComplete = true;
        if (resThumb && resThumb.output) {
          this.displayBrandLogo = true;
          this.brandLogoPath = resThumb.output[0][fpahArray[0]];
        }
      });
  }
  deleteBrandPic() {

    this.adminBrandManager.updateBrand({ _id: this.brandId, logo_file: '' }).then(() => {
      this.brandLogoPath = '';
      this.showDeleteBrandModal = false
    })
  }
  deleteBrandPicModal() {
    this.showDeleteBrandModal = true;
  }

  checkBrandFBConnected(): void {
    this.brandService
      .getFBAccessToken(this.brandId)
      .then((accessToken: any) => {
        if (accessToken.result) {
          this.brandSocialNetworkConnection.fb = true;
        } else {
          this.brandSocialNetworkConnection.fb = false;
        }
      });
  }
  checkBrandLinkedInConnected(): void {
    this.brandService
      .getLinkInAccessToken(this.brandId)
      .then((accessToken: any) => {
        if (accessToken.result) {
          this.brandSocialNetworkConnection.linkedin = true;
        } else {
          this.brandSocialNetworkConnection.linkedin = false;
        }
      });
  }
  checkInstagramConnected(): void {
    this.brandService
      .getInstagramAccessToken(this.brandId)
      .then((accessToken: any) => {
        if (accessToken.result) {
          this.brandSocialNetworkConnection.instagram = true;
        } else {
          this.brandSocialNetworkConnection.instagram = false;
        }
      });
  }
  checkBrandYouTubeConnected(): void {
    this.brandService
      .getYouTubeAccessToken(this.brandId)
      .then((accessToken: any) => {
        if (accessToken.result) {
          this.brandSocialNetworkConnection.youtube = true;
        } else {
          this.brandSocialNetworkConnection.youtube = false;
        }
      });
  }
setColorToProperty(event, type, index = undefined) {
        const fontColor = event.target?.defaultValue;
        if (type == 'primary') {
            this.brandDetail.primary_color = fontColor;
        } else {
            this.brandDetail.primary_color[index].color = fontColor;
        }
    }


  getFontFaceStyleCSS(fontPath, fontSignedURLPath) {
    // this.selectedFontSignedURL = fontSignedURLPath;
    let fontFamily = "";
    let path = fontPath.split(".");
    let fontExtension = path[path.length - 1];
    let pathName = fontPath.split("/");
    let fontFamilyName = pathName[pathName.length - 1]
      ? pathName[pathName.length - 1].split(".")[0]
      : pathName[pathName.length - 1];

    let fontFormate = fontExtension;
    if (
      fontFormate == "otf" ||
      fontFormate == "OTF" ||
      fontFormate == "ttf" ||
      fontFormate == "TTF"
    ) {
      fontFormate = "opentype";
    }
    console.log(" fontSignedURLPath ", fontSignedURLPath);
    return `
    @font-face {
      font-family: '${fontFamilyName}';
      font-style: normal;
      font-weight: 500;
      src: url('${fontSignedURLPath}') format('${fontFormate}');}`;
  }

  fontStyle(incommingFont) {
    let fontNameSpliter = incommingFont.split("/");
    let nameFont = fontNameSpliter[fontNameSpliter.length - 1];
    return nameFont.split(".")[0];
  }
}



