
import { Injectable } from "@angular/core";
import { video } from "./video";
import { visual } from "./media";
@Injectable({
    providedIn: 'root'
  })
export class ModifyVideo {
    public videoObj :any = [];
    public name : string;
    public duration : number;
    public keyword: string[];
    public template: [];
    public logo: any = [];
    public mediaList :any = [];
    public ownList :any = [];
    public url : string;
    // public addressObj : address;
    public line1?: string
    public line2?: string;
    public city?: string;
    public state?: string;
    public postal_code?: string;
    public country?: string;
    public phone : string;

    resetModifyVideo(){
        this.videoObj = '';
        this.name ='';
        this.duration = 0;
        this.keyword = [];
        this.template = [];
        this.logo = [];
        this.mediaList  = [];
        this.ownList=[];
        this.url = '';
        this.line1 = '';
        this.line2 = '';
        this.city = '';
        this.state = '';
        this.postal_code = '';
        this.country = '';
        this.phone = '';
    }
}
export class address
{
    line1?: string
    line2?: string;
    city?: string;
    state?: string;
    postal_code?: string;
    country?: string;
}

