  <div  class="col-md-12 border-start account-publish" >

                <div class="py-4 px-4 account-heading">
                    <div class="row">
                        <div class="col-md-8 px-4">
                            <h3 class="weight-700 text-gray-2">Published Videos</h3>
                            <p class="mx-0 my-0 text-sm">Manage all your published videos in one place for easy optimization</p>
                        </div>
                        <div class="col-md-4 text-end">

                        </div>
                    </div>
                </div>
                    <div class="accountSecInner flex-grow-1">

                            <div class="accountSecContent py-5">
                            <created-videos [numGridCol]="4"  [sInputVideoStatus]="'published'"  [title]="'published'" [myaccountPublished]=true ></created-videos>
                        </div>
                    </div>
            </div>

