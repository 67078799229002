import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'fileNameOnly' })
export class FileNameOnly implements PipeTransform {
    transform(value: string, size: number): string {
        if (value) {
            var fileName = value.split('/').pop();
            if (fileName.length > size) {
                let splitName = fileName.split('.');
                let filSplit = splitName[0];
                let ext = splitName.length > 1 ? '.' + splitName[splitName.length - 1] : '';
                fileName = filSplit.split('.')[0].substring(0, filSplit.length > size ? size : filSplit.length) + ext;
            }
            return fileName;
        } else {
            return value;
        }
    }
}
