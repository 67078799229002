<!-- error message -->
<section *ngIf="bDisplayErrorMsg  " class="accountSec main-body bg-gray-7 h-100 mt-2">
    <app-timeout-error [lInputErrorMessage]="sErrorMessage"></app-timeout-error>
</section>

<body class="{{sAddContentSlide}}" *ngIf="!bDisplayErrorMsg">
    <app-dashboard-header></app-dashboard-header>
    <main class="main">
        <section class="accountSec main-body bg-gray-7 h-100"
            [ngClass]="this.onboardingManager.profile.user_account_type && this.onboardingManager.profile.user_account_type !='smb'?'page':'page100'">

            <div class="container pr-30">
                <ul class="breadcrumb-tabs nav">
                    <li class="nav-item" role="presentation">
                        <a routerLink="dashboard-overview" class="nav-link active">Overview</a>
                    </li>
                    <!-- <li class="nav-item" role="presentation">
                        <a routerLink="/manage-comments" class="nav-link">Manage Comments</a>p
                    </li> -->
                </ul>
                <div class="h-87vh br12 curve-bg tab-content bg-white page-shadow" id="pills-tabContent">
                    <div class="tab-pane fade show active">
                        <div class="content_area">
                            <div class="container" id="container-view" class="video-preview-compoonent">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-5 ">
                                        <li class="ps-2 pe-2">
                                            <div class="video-outer video-overview"
                                                *ngIf="this.videoManager.selectedVideo"
                                                [ngClass]="{'landscape': this.videoManager.selectedVideo?.aspect_ratio === '16x9'}">

                                                <div id="TemplateThumbnail"
                                                    class="video_wrapper video_wrapper_full js-videoWrapper">
                                                    <!-- <div class="video-loading-status" *ngIf="this.videoManager.selectedVideo.status == undefined || this.videoManager.selectedVideo.status != 'success'">
                                                        <div class='loader'></div>
                                                      </div> -->
                                                    <video id="videoPreview"  controls controlsList="nodownload noplaybackrate" 
                                                        controlsList="nodownload noplaybackrate"
                                                        class="videoIframe js-videoIframe" width="620" height="350"
                                                        [src]=" this.videoManager.selectedVideo?.video_url">
                                                    </video>
                                                    <figure class="video-baner">
                                                        <img [src]=" this.videoManager.selectedVideo?.thumbnail"
                                                            alt="video">
                                                    </figure>
                                                    <button class="videoPoster js-videoPoster"
                                                        (click)="DashboardOverviewComponent_PlayVideo()"></button>
                                                </div>
                                            </div>

                                            <div class="my-3 tooltip_link"
                                                *ngIf="this.videoManager.selectedVideo?.schedule && this.showHeaderMenu">
                                                <p class="text-gray-3 text-lg">Schedule to publish on
                                                    <b class="text-sm"> {{
                                                        this.videoManager.selectedVideo?.schedule.coming_schedule |
                                                        date:'fullTime'}}</b>
                                                    &nbsp;
                                                    <a class="text-primary-4 text-lg" (click)="showModal()">update
                                                        schedule</a>
                                                </p>
                                                <!-- <a class=" text-xs text-gray-3">
                                                    <svg class="me-1" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M13.6569 13.6569C16.7811 10.5327 16.7811 5.46735 13.6569 2.34315C10.5327 -0.78105 5.46735 -0.78105 2.34315 2.34315C-0.78105 5.46732 -0.78105 10.5327 2.34315 13.6569C5.46732 16.7811 10.5327 16.7811 13.6569 13.6569ZM7.99922 5.6C7.5574 5.6 7.19922 5.24183 7.19922 4.8C7.19922 4.35818 7.5574 4 7.99922 4C8.44105 4 8.79922 4.35818 8.79922 4.8C8.79922 5.24183 8.44105 5.6 7.99922 5.6ZM7.99922 11.9999C7.5574 11.9999 7.19922 11.6418 7.19922 11.1999V7.19994C7.19922 6.75812 7.5574 6.39994 7.99922 6.39994C8.44105 6.39994 8.79922 6.75812 8.79922 7.19994V11.1999C8.79922 11.6418 8.44105 11.9999 7.99922 11.9999Z"
                                                            fill="#A39FAA" />
                                                    </svg>Preview Info
                                                    <span>
                                                        This is a low-resolution, watermarked preview of the video. The
                                                        quality of the final rendered video will be as you specify. Once you
                                                        complete the publishing process, you'll be able to download, view on
                                                        pronto, or share your video right from the platform.
                                                    </span>
                                                </a> -->

                                            </div>
                                        </li>
                                        <h3 *ngIf="this.onboardingManager.profile.user_status == 'confirmed_not_subscribed'"
                                            class="primaryFont weight-700 text-gray-2 ms-2 py-3">
                                            Upgrade now for easy, professional-grade video editing</h3>

                                        <button
                                            *ngIf="this.onboardingManager.profile.user_status  == 'confirmed_not_subscribed'"
                                            class="weight-normal btn btn-secondary btn-round-6 px-4 py-3 ms-2"
                                            (click)="goToSubscribe()">
                                            Upgrade Now
                                        </button>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-7">
                                        <h3 class="primaryFont weight-700 text-gray-2">{{
                                            this.videoManager.selectedVideo?.title}}</h3>

                                        <ul class="video_address ms-0 ps-0 mt-4 pt-1">

                                            <li class="d-block">
                                                <figure>
                                                    <img src="../../../../assets/images/svg/category.svg">
                                                </figure>
                                                <p>Category</p>
                                                <span class="pill">{{
                                                    this.videoManager.selectedVideo?.template_category}}</span>
                                            </li>
                                            <li class="d-block">
                                                <figure>
                                                    <img src="../../../../assets/images/svg/tags.svg">
                                                </figure>
                                                <p>Keywords</p>
                                                <span class="pill"
                                                    *ngFor="let tag of  this.videoManager.selectedVideo?.key_terms">{{tag}}</span>
                                            </li>
                                            <li class="d-block" *ngIf="sPublicVideoUrl && sPublicVideoUrl!=''">
                                                <figure>
                                                    <img src="../../../../assets/images/share.png" class="share-icon">
                                                </figure>
                                                <p>ShareLink</p>
                                                <!-- <span class="pill" *ngFor="let tag of  this.videoManager.selectedVideo?.key_terms">{{tag}}</span> -->
                                                <a href="{{sPublicVideoUrl}}" target="_blank"
                                                    class="text-primary-4">{{sPublicVideoUrl | slice:0:37}}</a>
                                                <button class="text-gray-2 text-xs align-items-center mx-3"
                                                    (click)="TimelineViewComponent_CopyToClipBoard()">
                                                    <img *ngIf="!this.copiedText || this.copiedText == ''"
                                                        src="../../../../assets/images/svg/copy.svg">
                                                    <mat-icon
                                                        *ngIf="this.copiedText && this.copiedText != ''">check_circle</mat-icon>
                                                    <span class="ms-2">Copy URL</span>
                                                </button>
                                            </li>
                                        </ul>

                                        <div class="accordian-section"
                                            *ngIf="this.videoManager.selectedVideo?.schedule && this.showHeaderMenu">
                                            <h3>Schedule</h3>
                                            <!-- <p>The video is scheduled for 12:45 PM, Every Monday in August 2021.</p> -->
                                            <div class="accodion-body">
                                                <nz-collapse nzAccordion [nzBordered]="false">
                                                    <nz-collapse-panel
                                                        *ngFor="let video of this.videoManager?.selectedVideo?.scheduleVideos;"
                                                        [nzHeader]="video.created | date:'h:mm a, MMMM d, y'"
                                                        [nzActive]="false" [nzExtra]="extraTpl" [ngStyle]="{
                                                background: '#FFFFFF',
                                                'border-radius': '6px',
                                                'margin-bottom': '24px',
                                                'border': '1px solid #F2F2F5'
                                              }">
                                                        <ng-template #extraTpl>
                                                            <!-- You can use stopPropagation if you don't want the panel to toggle -->
                                                            <img *ngIf="true"
                                                                src="../../../../assets/images/dashboardVideo/green tick.svg"
                                                                width="20" height="20">
                                                            <img *ngIf="false"
                                                                src="../../../../assets/images/dashboardVideo/schedule.svg"
                                                                width="20" height="20">
                                                        </ng-template>
                                                        <div class="accodion-inner">

                                                            <div class="video-outer"
                                                                style="border: 1px solid #EEEEEE;border-radius: 6px;">
                                                                <div class="video_wrapper video_wrapper_full js-videoWrapper"
                                                                    style="padding-top:225px">

                                                                    <video controls
                                                                        controlsList="nodownload noplaybackrate"
                                                                        class="videoIframe js-videoIframe"
                                                                        [muted]="true" [src]="video.video_url"
                                                                        [id]="video.fpath" style="height: 225px">
                                                                        <span
                                                                            class="video-duration mr-2">{{video.duration  | secondsToTime }}</span></video>

                                                                    <button class="videoPoster js-videoPoster"></button>
                                                                    <a href="javascript:void(0)"
                                                                        class=" text-sm text-gray-2 text-truncate"
                                                                        [title]="video.title ">{{video.title}}
                                                                    </a>
                                                                </div>
                                                            </div>

                                                            <!-- <div class="video-outer video-overview" *ngIf="this.videoManager.selectedVideo">
                                                    <div id="TemplateThumbnail" class="video_wrapper video_wrapper_full js-videoWrapper">
                                                        <video  id="videoPreview" controls disablepictureinpicture controlsList="nodownload noplaybackrate" class="videoIframe js-videoIframe" width="620" height="350" [src]=" this.videoManager.selectedVideo?.url">
                                                        </video>
                                                        <figure class="video-baner">
                                                            <img [src]=" this.videoManager.selectedVideo?.thumbnail" alt="video">
                                                        </figure>
                                                        <button class="videoPoster js-videoPoster" (click)="DashboardOverviewComponent_PlayVideo()"></button>
                                                    </div>
                                                </div> -->

                                                        </div>
                                                    </nz-collapse-panel>
                                                </nz-collapse>

                                                <!-- <nz-collapse nzAccordion>
                                            <nz-collapse-panel *ngFor="let panel of panels" [nzHeader]="panel.name" [nzActive]="panel.active">
                                              <p>{{ panel.name }} content</p>
                                            </nz-collapse-panel>
                                          </nz-collapse> -->

                                            </div>
                                        </div>




                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="wtermarklogo">
                        <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
                    </div>
                </div>
            </div>

        </section>
        <!-- <app-dashboard-sidepanels *ngIf="this.onboardingManager.profile.user_account_type && this.onboardingManager.profile.user_account_type !='smb'"></app-dashboard-sidepanels> -->
    </main>
    <div class="overlay"></div>
    <nz-modal [(nzVisible)]="isVisible" nzCentered [nzWidth]="1800" [nzBodyStyle]="{height:'94vh'}" [nzFooter]=null
        [nzClosable]=false (nzOnCancel)="handleCancel()">
        <app-scheduled-popup (click)="DashboardHeaderComponent_GetClickLocation($event)"
            [lInputClosePopup]="oClosePopup" (onClose)="handleCancel()"></app-scheduled-popup>
    </nz-modal>


</body>