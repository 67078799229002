<div class="py-4 px-4 ps-4 account-heading">
    <div class="row">
        <div class="col-md-8">
            <h3 class="weight-700 text-gray-2">Profile</h3>
            <p class="mx-0 my-0 text-sm">
                Add your business details and industry information to create a
                personalized experience
            </p>
        </div>
        <div class="col-md-4 text-end">
            <a *ngIf="bEditButton" href="javascript:void(0)" class="weight-normal btn btn-secondary btn-round-6 px-4 py-3" (click)="BrandIdentityComponent_EditForm()">Edit</a>

            <a *ngIf="bDisabledButtons && !bEditButton" class="text-gray-3 text-sm me-4" href="javascript:void(0)" (click)="BrandIdentityComponent_ResetView()">Cancel Edits</a>
            <a *ngIf="bDisabledButtons && !bEditButton" href="javascript:void(0)" class="weight-normal btn btn-secondary btn-round-6 px-4 py-3" (click)="update()">Save Changes</a>
        </div>
    </div>
</div>
<div class="pt-0 px-0 ps-4 d-flex flex-column">
    <div class="flex-grow-1">
        <div class="accountSecContent py-5">
            <form [formGroup]="fBrandIdentityForm" *ngIf="fBrandIdentityForm">
                <div class="row">
                    <div class="col-md-6">
                        <div class="loginSecItmForm pt-0" aria-labelledby="loginYourself">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="formItm static_lable order_list pt-0">
                                        <label class="primaryFont" for="new-business"><span class="counts">1.</span>What’s the name of your
                                            business?</label>
                                        <input [readonly]="!bDisabledButtons" class="secondaryFont" id="new-business" type="text" placeholder="Company ABC" formControlName="company_name" />
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="formItm static_lable order_list mb-2">
                                        <label class="primaryFont" for="aboutwebsite"><span class="counts">2.</span>What’s your website?</label>
                                        <p class="text-xs text-gray-3 pt-2 mb-1">
                                            We pull design inspiration from your website to improve
                                            the accuracy of your videos
                                        </p>
                                        <input [readonly]="!bDisabledButtons" class="secondaryFont" id="aboutwebsite" type="text" placeholder="www.example.com" formControlName="url" />
                                    </div>
                                </div>

                                <div class="col-sm-12">
                                    <div class="formItm static_lable order_list">
                                        <label class="primaryFont" for=""><span class="counts">3.</span>What industry are you
                                            in?<span class="ps-2" style="color: #d3d3d3">(Optional)</span></label>
                                        <p class="text-gray-3 text-xs">
                                            Knowing your industry will helps us choose relevant
                                            videos, images and content for your videos
                                        </p>
                                        <ul class="selct_business">
                                            <li class="icon-svg" *ngFor="let industry of listTopFiveIndustries" (click)="
                          bDisabledButtons
                            ? BrandIdentityComponent_PatchIndustryValueSideBar(
                                industry.name
                              )
                            : ''
                        " [ngStyle]="{
                          position: industry.name.includes('Food & Beverages')
                            ? 'relative'
                            : '',
                          top: industry.name.includes('Food & Beverages')
                            ? '16px'
                            : ''
                        }">
                                                <a href="javascript:void(0)" [ngClass]="
                            industry.name === sSelectedIndustry ? 'active' : ''
                          ">
                                                    <figure>
                                                        <!-- <svg width="27" height="24"
                                                                                    [attr.viewBox]="industry.viewBox"
                                                                                    fill="none"
                                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                                    <path [attr.d]="industry.path"
                                                                                        fill="#A39FAA" />
                                                                                </svg> -->
                                                        <img [src]="industry.icon" width="27" height="24" />
                                                    </figure>
                                                    <span [ngClass]="
                              industry.name.length > 13 ? 'industry-text' : ''
                            "  lang="de" class="text-center text-xs">{{ industry.name | titlecase }}</span>
                                                </a>
                                            </li>
                                            <li class="loadMore icon-svg">
                                                <a href="javascript:void(0)" (click)="openBrand()" [ngClass]="[
                            bDisabledButtons ? '' : '',
                            this.otherActive ? 'active' : ''
                          ]">
                                                    <figure>
                                                        <img src="../assets/images/icons/dots.svg" width="25" height="7" />
                                                    </figure>
                                                    <span class="text-center text-xs">Others</span>
                                                </a>
                                                <!-- <a href="javascript:void(0)" (click)="openBrand()"
                                                    [ngClass]="[!bDisabledButtons?'d-none':'', this.otherActive? 'active': '']">
                                                    <figure>
                                                        <svg width="25" height="7" viewBox="0 0 25 7" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M6.25 3.125C6.25 4.85089 4.85089 6.25 3.125 6.25C1.39911 6.25 0 4.85089 0 3.125C0 1.39911 1.39911 0 3.125 0C4.85089 0 6.25 1.39911 6.25 3.125ZM15.625 3.125C15.625 4.85089 14.2259 6.25 12.5 6.25C10.7741 6.25 9.375 4.85089 9.375 3.125C9.375 1.39911 10.7741 0 12.5 0C14.2259 0 15.625 1.39911 15.625 3.125ZM21.875 6.25C23.6009 6.25 25 4.85089 25 3.125C25 1.39911 23.6009 0 21.875 0C20.1491 0 18.75 1.39911 18.75 3.125C18.75 4.85089 20.1491 6.25 21.875 6.25Z"
                                                                fill="#A39FAA" />
                                                        </svg>
                                                    </figure>
                                                    <span class="text-center text-xs">Others</span>
                                                </a> -->
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 small-side offset-md-1">
                        <div class="w-info">
                            <p class="text-lg text-gray-2 mb-1">Logo & Colors</p>
                            <p class="text-xs text-gray-3">
                                Create a consistent Brand experience by adding your company or
                                brand logo to your profile
                            </p>
                        </div>

                        <div class="right-sde-info" style="margin-top: 30px">
                            <p class="text-md text-gray-2 mb-2 secondaryFont">Logo</p>
                            <button  class="icon-gray-hover btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btn-md-same-pad btnShadow" (click)="BrandIdentityComponent_UploadedFile()">
                                <img class="me-1" width="17" height="16"  class="" src="../../../../../assets/images/icons/upload-icon.svg" />
                                Upload
                                <!-- <input class="upload-file" type="file" accept="image/*" (change)="BrandIdentityComponent_UploadedFile($event)" /> -->
                            </button>
                            <!-- Uploaded Logo file -->
                            <div class="media_list mt-2" *ngIf="logoFile">
                                <ul>
                                    <li class="mb-3">
                                        <figure class="media_figure">
                                            <span class="media">
                                                <img [src]="logoFile.url" alt="logo" /></span>
                                            <figcaption>
                                                <div class="caption w-100">
                                                    <p class="text-xs title">
                                                        {{ logoFile.filename | slice : 0 : 30 }}
                                                    </p>
                                                    <p class="text-xs sub_title">
                                                        {{
                                                        BrandIdentityComponent_ReturnFileSize(
                                                        logoFile.size
                                                        )
                                                        }}
                                                    </p>
                                                </div>
                                            </figcaption>
                                        </figure>
                                    </li>
                                </ul>
                            </div>
                            <div class="theme-color mt-4">
                                <div class="d-flex">
                                    <ul class="m-0 ps-0 pe-4">
                                        <label class="d-block text-md text-gray-2 pb-2">Theme Color</label>
                                        <li>
                                            <input class="form-check-input coloris" type="checkbox" id="checkboxNoLabel"
                                            [value]=" primaryColor ? primaryColor : videoManager.fontDefaultColor "
                                            [(ngModel)]="primaryColor"
                                            [ngModelOptions]="{standalone: true}"
                                            (change)="setColorToProperty($event, 'primary')"
                                            aria-label="..." />
                                            <figure class="color-pic no-bg">
                                                <img class="color-picker" [ngClass]="{'white' : primaryColor != '#ffffff' && primaryColor != '#FFFFFF' && primaryColor != undefined}" *ngIf="!primaryColor " src="../../../../../assets/images/icons/color-picker.svg" />

                                                <img class="color-picker" [ngClass]="{'white' : primaryColor != '#ffffff' && primaryColor != '#FFFFFF' && primaryColor != undefined}" *ngIf="primaryColor " src="../../../../../assets/images/icons/check-icon.svg" />
                                            </figure>
                                        </li>
                                    </ul>
                                    <ul class="ms-2">
                                        <label class="d-block text-md pb-2">Font Colors</label>
                                        <li class="me-2" *ngFor="let secondaryClr of secondaryColor; let i = index">
                                            <button aria-labelledby="clr-open-label"></button>
                                            <input class="form-check-input coloris "
                                            [value]="secondaryClr?.color ? secondaryClr?.color : '#ffffff'"
                                            (change)="setColorToProperty($event, 'secondary', i)"
                                            type="checkbox" id="checkboxNoLabel" value="" aria-label="..." />
                                            <figure class="color-pic">
                                                <img class="color-picker" *ngIf="!secondaryClr?.color" [ngClass]="{'white' : secondaryClr?.color != '#ffffff' && secondaryClr?.color != '#FFFFFF' && secondaryClr?.color != undefined }"   src="../../../../../assets/images/icons/color-picker.svg" />

                                                <img class="color-picker" *ngIf="secondaryClr?.color" [ngClass]="{'white' : secondaryClr?.color != '#ffffff' && secondaryClr?.color != '#FFFFFF' && secondaryClr?.color != undefined }"  src="../../../../../assets/images/icons/check-icon.svg" />

                                            </figure>
                                        </li>
                                    </ul>
                                </div>
                                <!-- <ul class="m-0 ps-0 pe-4">
                                    <label class="d-block text-md text-gray-2 pb-3">Primary
                                        Color</label>
                                    <li >
                                        <input class="form-check-input coloris"    [ngClass]="{coloris: bDisabledButtons}" type="checkbox"
                                            [(ngModel)]="oPrimaryColor" [value]="oPrimaryColor"

                                            [ngModelOptions]="{standalone: true}"
                                            (change)="bDisabledButtons?BrandIdentityComponent_GetPrimaryColor($event):''"
                                            id="checkboxNoLabel" aria-label="...">
                                        <figure class="color-pic no-bg">
                                            <svg width="16" height="16" viewBox="0 0 16 16"
                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    [attr.d]="primaryColorIconPath"
                                                    [attr.fill]="fillProomaryColor" />
                                            </svg>
                                        </figure>
                                    </li>
                                </ul> -->

                                <!-- <ul class="ms-2">
                                    <label class="d-block text-md pb-3">Font Color</label>
                                    <li>
                                        <input class="form-check-input coloris" [ngClass]="{coloris: bDisabledButtons}"   type="checkbox"
                                            id="checkboxNoLabel" [(ngModel)]="oFontColor"
                                            [value]="oFontColor"
                                            [ngModelOptions]="{standalone: true}"
                                            (change)="bDisabledButtons?BrandIdentityComponent_GetFontColor($event):''"
                                            aria-label="...">
                                        <figure class="color-pic no-bg">
                                            <svg width="16" height="16" viewBox="0 0 16 16"
                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0)">

                            <path fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M15.2039 3.47326C15.3824 3.65373 15.3754 3.93959 15.1883 4.11176L6.0293 12.5419C5.84833 12.7084 5.56361 12.7083 5.3828 12.5416L0.81135 8.32653C0.624455 8.15421 0.617739 7.86834 0.796348 7.68802C0.974957 7.5077 1.27126 7.50122 1.45815 7.67355L5.70648 11.5907L14.5421 3.45822C14.7292 3.28605 15.0255 3.29279 15.2039 3.47326Z"
                                                        fill="white" />
                            <path fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        [attr.d]="fontColorIconPath"
                                                        [attr.fill]="fillFontColor" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0">
                                                        <rect width="16" height="16"
                                                            fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>

                                        </figure>
                                    </li>

                            <li>
                                                    <input class="form-check-input" type="checkbox" id="checkboxNoLabel"
                                                        value="" aria-label="...">
                                                    <figure class="color-pic bg-gray-6">
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0)">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                    d="M15.2039 3.47326C15.3824 3.65373 15.3754 3.93959 15.1883 4.11176L6.0293 12.5419C5.84833 12.7084 5.56361 12.7083 5.3828 12.5416L0.81135 8.32653C0.624455 8.15421 0.617739 7.86834 0.796348 7.68802C0.974957 7.5077 1.27126 7.50122 1.45815 7.67355L5.70648 11.5907L14.5421 3.45822C14.7292 3.28605 15.0255 3.29279 15.2039 3.47326Z"
                                                                    fill="white" />
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                    d="M14.0907 2.96762C14.5411 2.55307 15.2465 2.56804 15.678 3.00444C16.1227 3.45416 16.1038 4.17511 15.6398 4.60219L6.48078 13.0323C6.04449 13.4339 5.36684 13.4336 4.93088 13.0316L0.359438 8.81657C-0.104226 8.38906 -0.122348 7.66809 0.322706 7.21878C0.754571 6.78279 1.46002 6.76838 1.91006 7.18334L5.70688 10.6842L14.0907 2.96762ZM1.26349 7.83654L1.26326 7.83633Z"
                                                                    fill="white" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0">
                                                                    <rect width="16" height="16" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </figure>
                                                </li>
                            </ul> -->
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <!-- Display succcess -->
            <div *ngIf="bDisplaySuccessMessage" class="alert alert-success d-flex align-items-center alert-dismissible fade show col-md-6" role="alert">
                <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 0C6.26801 0 0 6.26801 0 14V15C0 22.732 6.26801 29 14 29V29C21.732 29 28 22.732 28 15V14C28 6.26801 21.732 0 14 0V0Z" fill="#00C599" />
                    <g>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M21.0045 9.94163C21.178 10.1234 21.1713 10.4112 20.9894 10.5846L12.0848 19.0732C11.9088 19.2409 11.632 19.2408 11.4562 19.0729L7.01176 14.8286C6.83006 14.6551 6.82353 14.3672 6.99718 14.1857C7.17082 14.0041 7.45889 13.9976 7.64059 14.1711L11.7709 18.1154L20.3611 9.92649C20.543 9.75313 20.8311 9.75991 21.0045 9.94163Z"
                            fill="#00C599" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M19.9219 9.43247C20.3598 9.01505 21.0456 9.03012 21.4652 9.46955C21.8975 9.9224 21.8792 10.6484 21.4281 11.0784L12.5234 19.5671C12.0992 19.9714 11.4404 19.9711 11.0166 19.5664L6.57212 15.322C6.12133 14.8916 6.10371 14.1656 6.53641 13.7132C6.95627 13.2741 7.64212 13.2596 8.07967 13.6775L11.771 17.2026L19.9219 9.43247ZM7.45106 14.3352L7.45084 14.335Z"
                            fill="white" />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="15.5556" height="16.1111" fill="white" transform="translate(6.22266 6.44434)" />
                        </clipPath>
                    </defs>
                </svg>
                <div>
                    {{ sDisplayResponseMessage }}
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.546609 15.7333C0.406624 15.7333 0.273304 15.68 0.166649 15.58C-0.0399958 15.3733 -0.0399958 15.0333 0.166649 14.8267L7.11925 7.86667L0.153317 0.906667C0.0533277 0.806667 0 0.673333 0 0.533333C0 0.393333 0.0533277 0.26 0.153317 0.16C0.259972 0.0533333 0.393292 0 0.533277 0C0.673262 0 0.806581 0.0533333 0.913237 0.153333L7.8725 7.11333L14.8251 0.153333C14.9251 0.0533333 15.0584 0 15.1984 0C15.3384 0 15.4717 0.0533333 15.5784 0.153333C15.785 0.36 15.785 0.7 15.5784 0.906667L8.62575 7.86667L15.5717 14.8133C15.6717 14.9133 15.725 15.0467 15.725 15.1867C15.725 15.3267 15.6717 15.46 15.5717 15.5667C15.4717 15.6733 15.3384 15.72 15.1917 15.72C15.0451 15.72 14.9184 15.6667 14.8118 15.5667L7.8725 8.62L0.919903 15.58C0.819913 15.68 0.686594 15.7333 0.546609 15.7333Z"
                                fill="#FF8369" />
                        </svg>
                    </button>
                </div>
            </div>

            <!-- Display Error -->
            <div *ngIf="bDisplayErrorMessage" class="alert alert-danger d-flex align-items-center alert-dismissible fade show col-md-6" role="alert">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M27.5683 21.8754L16.8998 3.08014C15.6241 0.839822 12.3899 0.839822 11.1141 3.08014L0.445544 21.8754C-0.830163 24.0854 0.788172 26.8545 3.33834 26.8545H24.6437C27.2254 26.8545 28.8121 24.1169 27.568 21.8754H27.5683ZM14.0387 7.56066C15.0337 7.56066 15.5318 8.46261 15.5318 10.2994C15.5318 11.2949 15.4079 12.6649 15.1273 14.4385C14.8478 16.2121 14.6607 17.3012 14.4736 17.6441C14.3497 17.8933 14.2245 18.0173 14.0387 18.0173C13.8212 18.0173 13.6657 17.9237 13.5721 17.7061C13.4166 17.4265 13.1991 16.4297 12.9185 14.7182C12.639 13.0066 12.5139 11.5126 12.4835 10.3298C12.4835 8.46383 13.012 7.56066 14.0387 7.56066V7.56066ZM15.1273 22.0627C14.8162 22.3739 14.4432 22.5295 14.0071 22.5295C13.5721 22.5295 13.1979 22.3739 12.8869 22.0627C12.5758 21.7516 12.4203 21.3784 12.4203 20.942C12.4203 20.5068 12.5758 20.1324 12.8869 19.8212C13.1979 19.51 13.5709 19.3544 14.0071 19.3544C14.4116 19.3544 14.7846 19.51 15.1273 19.8528C15.4699 20.1956 15.6254 20.5688 15.6254 20.9736C15.6242 21.3784 15.4383 21.7516 15.1273 22.0627Z"
                        fill="#842029" />
                </svg>
                <div>
                    {{ sDisplayResponseMessage }}
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.546609 15.7333C0.406624 15.7333 0.273304 15.68 0.166649 15.58C-0.0399958 15.3733 -0.0399958 15.0333 0.166649 14.8267L7.11925 7.86667L0.153317 0.906667C0.0533277 0.806667 0 0.673333 0 0.533333C0 0.393333 0.0533277 0.26 0.153317 0.16C0.259972 0.0533333 0.393292 0 0.533277 0C0.673262 0 0.806581 0.0533333 0.913237 0.153333L7.8725 7.11333L14.8251 0.153333C14.9251 0.0533333 15.0584 0 15.1984 0C15.3384 0 15.4717 0.0533333 15.5784 0.153333C15.785 0.36 15.785 0.7 15.5784 0.906667L8.62575 7.86667L15.5717 14.8133C15.6717 14.9133 15.725 15.0467 15.725 15.1867C15.725 15.3267 15.6717 15.46 15.5717 15.5667C15.4717 15.6733 15.3384 15.72 15.1917 15.72C15.0451 15.72 14.9184 15.6667 14.8118 15.5667L7.8725 8.62L0.919903 15.58C0.819913 15.68 0.686594 15.7333 0.546609 15.7333Z"
                                fill="#FF8369" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="overlay"></div>
<!-- sidebar -->
<nz-drawer [nzClosable]="false" [nzVisible]="visibleBrand" [nzPlacement]="placementBrand" (nzOnClose)="closeBrand()" [nzWidth]="478">
    <ng-container *nzDrawerContent>
        <nav style="padding: 24px;">
            <div class="sidebar-header pt-3 pb-3">
                <h3 class="text-gray-2">What industry are you in?</h3>
                <p class="text-gray-3 pe-4 fw-normal py-2">
                    Knowing your industry will help us pick relatable graphics, images and
                    content.
                </p>
                <div id="dismiss" class="pt-2" (click)="closeBrand()">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.19 0H5.81C2.17 0 0 2.17 0 5.81V14.18C0 17.83 2.17 20 5.81 20H14.18C17.82 20 19.99 17.83 19.99 14.19V5.81C20 2.17 17.83 0 14.19 0Z" fill="#F2F2F5"></path>
                        <path
                            d="M11.0599 10.0001L13.3599 7.70011C13.6499 7.41011 13.6499 6.93011 13.3599 6.64011C13.0699 6.35011 12.5899 6.35011 12.2999 6.64011L9.99986 8.94011L7.69986 6.64011C7.40986 6.35011 6.92986 6.35011 6.63986 6.64011C6.34986 6.93011 6.34986 7.41011 6.63986 7.70011L8.93986 10.0001L6.63986 12.3001C6.34986 12.5901 6.34986 13.0701 6.63986 13.3601C6.78986 13.5101 6.97986 13.5801 7.16986 13.5801C7.35986 13.5801 7.54986 13.5101 7.69986 13.3601L9.99986 11.0601L12.2999 13.3601C12.4499 13.5101 12.6399 13.5801 12.8299 13.5801C13.0199 13.5801 13.2099 13.5101 13.3599 13.3601C13.6499 13.0701 13.6499 12.5901 13.3599 12.3001L11.0599 10.0001Z"
                            fill="#A7ABB6"></path>
                    </svg>
                </div>
            </div>
            <div class="row brand-industry">
                <div class="col-md-12">
                    <ul class="selct_business selct_business1">
                        <div>
                            <ng-container>
                                <li class="icon-svg" *ngFor="
                    let industry of listTopIndustries.slice(
                      0,
                      limitIndustryList
                    )
                  " (click)="
                    BrandIdentityComponent_PatchIndustryValueSideBar(
                      industry.name
                    )
                  ">
                                    <a href="javascript:void(0)" [ngClass]="
                      industry.name === sSelectedIndustry ? 'active' : ''
                    ">
                                        <figure>
                                            <img [src]="industry.icon" width="27" height="24" />
                                        </figure>
                                        <span  lang="de" class="text-center text-xs industry-text">{{
                                            industry.name | titlecase
                                            }}</span>
                                    </a>
                                </li>
                            </ng-container>
                        </div>
                    </ul>
                </div>

                <div class="row" *ngIf="!bloadIndustries">
                    <div class="col-md-12">
                        <a href="javascript:void(0)" id="view-all" (click)="showAll()"><span>+</span> View All</a>
                    </div>
                </div>

                <div class="col-md-12">
                    <h6 class="text-gray-2 weight-700 mt-3 pt-4 border-top">
                        Didn’t find your industry?
                    </h6>
                    <p class="text-gray-3 text-xs pt-1 pe-4">
                        Type the name of your industry to help us find content that fits.
                    </p>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="formItm mb-4">
                                <input id="inputadd" type="text" [(ngModel)]="sNewIndustry" placeholder="Add your industry name" required="" />
                                <label for="inputadd" class="text-sm">Add your industry name</label>
                            </div>
                        </div>
                        <div class="col-md-4 add-new">
                            <button class="col-md-3 weight-normal btn btn-secondary btn-round-6 small-pad text-xs px-3" [disabled]="!sNewIndustry" (click)="BrandIdentityComponent_AddNewIndustry()">
                                Add New
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <ul class="p-0">
                <button class="weight-normal btn btn-primary btn-round-6 small-pad px-5 py-3" (click)="closeBrand()">
                    Save
                </button>
            </ul>
        </nav>
    </ng-container>
</nz-drawer>

<!-- Modal pop-up Start-->
<nz-modal [(nzVisible)]="isModalVisible" nzCentered [nzWidth]="566" [nzFooter]=null [nzClosable]=false (nzOnCancel)="hidePopUP()">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="btn-close cust-close closeRightBtn close-success" (click)="hidePopUP()"></button>

                <h5>{{ this.successMsg }}</h5>
                <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">
                    Your {{ this.successMsg == "Profile Logo" ? "logo" : "profile" }} has
                    updated successfully
                </p>
                <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" (click)="hidePopUP()">Ok</a>
                <!-- <a href="javascript:void(0)"
class="mt-3 text-gray-3 text-sm" (click)="updateMediaLibrary(mediaObj)">asa</a> -->
            </div>
        </div>
    </div>
</nz-modal>

<!-- Media Library Modal pop-up Start-->