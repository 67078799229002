<app-loader *ngIf="this.loader.loading"></app-loader>
<router-outlet *ngIf="!this.showSplashScreen"></router-outlet>
<app-splashscreen *ngIf="this.showSplashScreen"></app-splashscreen>
<nz-modal [(nzVisible)]="this.errorService.showError" nzCentered [nzWidth]="566" [nzFooter]="null" [nzClosable]="false"
  (nzOnCancel)="closeErrorModal()" style="z-index: 2000000000">
  <div class="modal-dialog modal-dialog-centered error-message-dialog"
    style="margin: auto; max-width: auto !important;">
    <div class="modal-content">
      <!--  <div class="modal-body">
          <button type="button" class="btn-close cust-close closeRightBtn" style="right: -15px !important;" (click)="closeErrorModal()"></button>

          <h5>{{this.errorService.errorTitle}}</h5>
          <p class="text-sm text-gray-3 pt-4 pb-4 mb-0">{{this.errorService.errorMessage}}</p>
          <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" (click)="closeErrorModal()">Ok</a>
           <a href="javascript:void(0)"
              (click)="LoginSecurityComponent_SignOutFromAllDevices()" class="mt-3 text-gray-3 text-sm">Sign
              out</a>
      </div>-->
      <div class="modal-body">
        <button type="button" class="btn-close cust-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="this.errorService.closeErrorDialogue();"></button>
        <h5 class="mb-4">{{ this.errorService.errorTitle }}</h5>
        <div class="alert alert-danger p-3 mb-4 g-0 row rounded-1">
          <div class="col-1 p-0">
            <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path d="M13.9993 3.47998C6.92399 3.47998 1.16602 9.20176 1.16602
                  16.2326C1.16602 23.2635 6.92399 29 13.9993 29C21.0747 29
                  26.8327 23.2782 26.8327 16.2473C26.8327 9.21646 21.0747
                  3.47998 13.9993 3.47998ZM15.1095 15.7619C15.1095 16.365
                  14.6062 16.8651 13.9993 16.8651C13.3925 16.8651 12.8892 16.365
                  12.8892 15.7619V8.40747C12.8892 7.80441 13.3925 7.3043 13.9993
                  7.3043C14.6062 7.3043 15.1095 7.80441 15.1095
                  8.40747V15.7619Z" fill="#FF8369"></path>
                <path d="M17.7366 2.32H10.2647C9.74765 2.32 9.33398 1.808 9.33398
                  1.168C9.33398 0.528 9.74765 0 10.2647 0H17.7366C18.2537 0
                  18.6673 0.512 18.6673 1.152C18.6673 1.792 18.2537 2.32 17.7366
                  2.32Z" fill="#FF8369"></path>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="28" height="29" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="col-11 p-0">
            <p class="text-lg h2 text-dark mb-0 ms-2" *ngIf="this.errorService.showReplaceIcon">
              File already exists in your media library
            </p>
            <p class="text-sm text-gray-3 mb-0 ms-2" *ngIf="this.errorService.showReplaceIcon">
              Select from library or rename and retry upload.
            </p>
            <p class="text-sm text-gray-3 mb-0 ms-2" *ngIf="!this.errorService.showReplaceIcon">
              {{ this.errorService.errorMessage }}
            </p>
          </div>
        </div>

        <a class="weight-normal btn btn-secondary btn-round-6 cust-pad"
          *ngIf="!this.errorService.showReplaceIcon && !this.errorService.showSubscriptionButton"
          data-bs-dismiss="modal" (click)="this.errorService?.errorMessage && this.errorService.errorTitle == 'Video Generation Failed'? goToStartOver(this.oSessionService?.cSessionService_GetGuestEmail() != ''? 'start over' : 'go back'): closeErrorModal()" [ngStyle]="{
          marginLeft:
          this.errorService.showSubscriptionButton &&
          this.meta_info?.metaInfo?.stripe_enabled
          ? '55px'
          : '5px'
          }">{{ this.errorService.errorTitle == 'Already Registered' ? 'Sign In' : this.errorService.errorTitle ==
          'Reset Password Error'? 'Sign Up' : this.errorService.errorTitle == 'Email Already in Use'? 'Sign In':
          this.errorService.errorTitle == 'Location Not Selected'? 'Select Location' : (this.errorService.errorTitle == 'Video Generation Failed' && this.oSessionService?.cSessionService_GetGuestEmail() != '')? 'Start Over' : this.errorService.errorTitle == 'Video Generation Failed'? 'Go Back' : this.errorService.buttonTitle }}</a>

        <a class="weight-normal btn btn-secondary btn-round-6 cust-pad" *ngIf="!this.errorService.displayContactATLeft && this.errorService.showSubscriptionButton &&
        this.meta_info?.metaInfo?.stripe_enabled" data-bs-dismiss="modal" (click)="goToSubscribe()" [ngStyle]="{
        marginLeft:
        this.errorService.showSubscriptionButton &&
        this.meta_info?.metaInfo?.stripe_enabled
        ? '5px'
        : '5px'
        }">Contact Support</a>

        <a class="weight-normal btn btn-primary btn-round-6 cust-pad " *ngIf="this.errorService.displayContactATLeft && this.errorService.showSubscriptionButton &&
        this.meta_info?.metaInfo?.stripe_enabled" data-bs-dismiss="modal" (click)="goToSubscribe()" [ngStyle]="{
        marginLeft:
        this.errorService.showSubscriptionButton &&
        this.meta_info?.metaInfo?.stripe_enabled
        ? '5px'
        : '5px'
        }">Contact Support</a>

        <!-- <a class="weight-normal btn btn-primary btn-round-6 cust-pad"
          style="margin-left: 25px" data-bs-dismiss="modal"
          (click)="goToSubscribe()" *ngIf="this.errorService.showSubscriptionButton
          &&
          this.meta_info?.metaInfo?.stripe_enabled">Contact Support</a> -->
        <a class="weight-normal btn btn-secondary btn-round-6 cust-pad" style="margin-left: 25px"
          data-bs-dismiss="modal" (click)="openMediaReplacementPopup(); closeErrorModal()"
          *ngIf="this.errorService.showReplaceIcon">Go to Media Library</a>
        <a class="text-gray-3 text-sm cust-pad" data-bs-dismiss="modal" *ngIf="this.errorService.showReplaceIcon
            || (this.errorService.showSubscriptionButton || this.errorService.showCancelBtn)
            && (this.meta_info?.metaInfo?.stripe_enabled)" (click)="this.errorService.closeErrorDialogue();" [ngStyle]="{
          marginLeft:
          this.errorService.showSubscriptionButton &&
          this.meta_info?.metaInfo?.stripe_enabled
          ? '25px'
          : '25px'
          }">Cancel</a>
        <a class="text-gray-3 text-sm cust-pad" data-bs-dismiss="modal"
          *ngIf="this.errorService.errorTitle == 'Already Registered'" (click)="forgotPassword()" [ngStyle]="{
            marginLeft:
            this.errorService.showSubscriptionButton &&
            this.meta_info?.metaInfo?.stripe_enabled
            ? '55px'
            : '25px'
            }">Forgot Password</a>
        <a class="text-gray-3 text-sm cust-pad" data-bs-dismiss="modal"
          *ngIf="this.errorService.errorTitle == 'Reset Password Error'"
          (click)="this.errorService.closeErrorDialogue();" [ngStyle]="{
              marginLeft:
              this.errorService.showSubscriptionButton &&
              this.meta_info?.metaInfo?.stripe_enabled
              ? '55px'
              : '25px'
              }">Dismiss</a>
        <!-- <a class="weight-normal btn btn-primary btn-round-6 cust-pad" style="margin-left: 25px" data-bs-dismiss="modal"
          (click)="goToSubscribe()" *ngIf="
            this.errorService.showSubscriptionButton &&
            this.meta_info?.metaInfo?.stripe_enabled
          ">Contact Support</a> -->
      </div>
    </div>
  </div>
</nz-modal>

<nz-modal *ngIf="this.planManager.planDialogue" [(nzVisible)]="this.planManager.planDialogue" [nzWidth]="1440"
  [nzBodyStyle]="{ height: '92.1vh', marginTop: '-65px' }" [nzFooter]="null" [nzClosable]="false"
  [nzMaskClosable]="false" (nzOnCancel)="closePlanModal()" style="z-index:
  2000000000">
  <!-- <button type="button" class="btn-close cust-close" data-bs-dismiss="modal" aria-label="Close" (click)="closePlanModal()"></button> -->
  <app-select-plan [showAsDialogue]="true"></app-select-plan>
</nz-modal>

<div class="success-message-box backPage" *ngIf="this.backButtonDialogSetting.visible">
  <div class="modal-dialog modal-dialog-centered w-100" style="padding-bottom:
    0px; margin-bottom: 0px">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" style="top: 0px !important" class="btn-close cust-close" data-bs-dismiss="modal"
          aria-label="Close" (click)="closeBackButtonDailog()"></button>
        <div class="modal-body loading-icon">
          <h5>Are you sure you want to
            leave this page?</h5>
          <div class="text-sm text-gray-3 pt-1 pb-4 mb-0">
            <span [ngClass]="{'noHead' : !this.successMessage.showHead}">{{this.backButtonDialogSetting.text}}</span>
            <div class="lds-default absolute-right" *ngIf="this.successMessage.showLoader"
              [ngClass]="{'noHead' : !this.successMessage.showHead}">
              <div></div>

            </div>
          </div>
        </div>
        <a class="weight-normal btn btn-secondary btn-round-6 cust-pad" style="margin-left: 25px"
          data-bs-dismiss="modal" (click)="closeBackButtonDailog()">Stay on this page</a>
        <a class="text-gray-3 text-sm cust-pad" data-bs-dismiss="modal" (click)="redirectToBackPage()" [ngStyle]="{
          marginLeft:
            this.errorService.showSubscriptionButton &&
            this.meta_info?.metaInfo?.stripe_enabled
              ? '55px'
              : '25px'
        }">Start over</a>
      </div>
    </div>
  </div>
</div>
<div class="success-message-box" *ngIf="this.successMessage.showSuccessModal">
  <div class="modal-dialog modal-dialog-centered w-100" style="padding-bottom:
      0px; margin-bottom: 0px">
    <div class="modal-content">
      <div class="modal-body loading-icon">
        <h5 *ngIf="this.successMessage.showHead">{{this.successMessage.title}}</h5>
        <div class="text-sm text-gray-3 pt-1 pb-4 mb-0">
          <span [ngClass]="{'noHead' : !this.successMessage.showHead}">{{this.successMessage.message}}</span>
          <div class="lds-default absolute-right" *ngIf="this.successMessage.showLoader"
            [ngClass]="{'noHead' : !this.successMessage.showHead}">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="success-message-box" *ngIf="this.successMessage.showCSVUploadResponseBox">
  <div class="modal-dialog modal-dialog-centered w-100" style="padding-bottom:
      0px; margin-bottom: 0px">
    <div class="modal-content">
      <div class="modal-body loading-icon">
        <h5 *ngIf="this.successMessage.showHead">{{this.successMessage.title}}</h5>
        <div class="text-sm text-gray-3 pt-1 pb-4 mb-0">
          <span [ngClass]="{'noHead' : !this.successMessage.showHead}">{{this.successMessage.message}}</span>
          <div class="lds-default absolute-right" *ngIf="this.successMessage.showLoader"
            [ngClass]="{'noHead' : !this.successMessage.showHead}">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- toast message -->

<nz-modal *ngIf="this.successMessage.showInfoToasterModal" [ngClass]="{'success-info-toaster' : true}"
  [(nzVisible)]="this.successMessage.showInfoToasterModal" [nzMask]="false" nzCentered [nzWidth]="580" [nzFooter]="null"
  [nzClosable]="false" style="z-index:2000000">

  <div *ngIf="this.successMessage.showInfoToasterModal"
    class=" alert alert-primary d-flex align-items-center alert-dismissible fade show mt-5 success-info-toaster-container success-toaster"
    role="alert">

    <img src="../assets/images/icons/alert-success.svg">
    <div class="ms-3 me-8">
      {{this.successMessage.infoToastMessage}}
      <div type="button" class="btn-close  cross-btn" style="margin-right: 10px !important;" data-bs-dismiss="alert"
        aria-label="Close" (click)="this.successMessage.showInfoToasterModal = false">
      </div>
    </div>
  </div>
</nz-modal>


<nz-modal [(nzVisible)]="this.mediaManager.showMediaReplacementPopup"
  *ngIf="this.mediaManager.showMediaReplacementPopup" nzCentered [nzWidth]="825" [nzFooter]=null [nzClosable]=true
  (nzOnCancel)="this.mediaManager.showMediaReplacementPopup= false">
  <app-media-library-popup [component]="this.mediaManager.replaceMediaTypeFromVisualPopup?.component"
    [mediaLoadType]="this.mediaManager.replaceMediaTypeFromVisualPopup?.type"
    (lEmittedFilesToUpload)="this.mediaManager.replaceMediaListener($event)" [mediaPopUpLoc]="'mediaPopUpLoc'"
    [isSingleSelected]="true">
  </app-media-library-popup>
</nz-modal>

<!-- contactus sidebar Modal Start-->
<nz-drawer [nzClosable]="false" [nzVisible]="visibleContactSupport" [nzPlacement]="placementContactSupport"
  (nzOnClose)="closeContactSupport()" [nzWidth]="478">
  <ng-container *nzDrawerContent>
    <nav id="contactus-sidebar" class="rightSideBar">
      <form>
        <div class="sidebar-header pt-3 pb-2">
          <h3 class="text-gray-2 mb-4">Contact Support</h3>
          <div class="closeRightSideBar pt-2" (click)="closeContactSupport()">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1_6)">
                <path d="M14.1971 0H5.8129C2.17108 0 0 2.17 0 5.81V14.18C0 17.83
                    2.17108 20 5.8129 20H14.1871C17.8289 20 20 17.83 20
                    14.19V5.81C20.01 2.17 17.8389 0 14.1971 0Z" fill="#F2F2F5" />
                <path d="M11.1852 9.9986L13.7568 7.42787C14.0811 7.10374 14.0811
                      6.56724 13.7568 6.2431C13.4326 5.91897 12.8959 5.91897
                      12.5716 6.2431L10 8.81383L7.42837 6.2431C7.10412 5.91897
                      6.56744 5.91897 6.24319 6.2431C5.91894 6.56724 5.91894
                      7.10374 6.24319 7.42787L8.81481 9.9986L6.24319
                      12.5693C5.91894 12.8935 5.91894 13.43 6.24319
                      13.7541C6.4109 13.9218 6.62334 14 6.83578 14C7.04822 14
                      7.26066 13.9218 7.42837 13.7541L10 11.1834L12.5716
                      13.7541C12.7393 13.9218 12.9518 14 13.1642 14C13.3767 14
                      13.5891 13.9218 13.7568 13.7541C14.0811 13.43 14.0811
                      12.8935 13.7568 12.5693L11.1852 9.9986Z" fill="#A7ABB6" />
              </g>
              <defs>
                <clipPath id="clip0_1_6">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <p class="text-gray-2 pt-6 pb-2 my-1 fs-6">Please fill in the
            form below and we will get back to you as soon as possible.
          </p>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">

            <div class="formItm">
              <input id="companyName" type="text" value="" placeholder="John Deo" name="textfeild" required>
              <label for="companyName" class="fs-6 fw-normal">Name</label>
            </div>
            <div class="formItm">
              <input id="loginEmail" type="email" value="" placeholder="John@email.com" name="email" required>
              <label for="loginEmail" class="fs-6 fw-normal">Email
                Address</label>

            </div>
            <div class="formItm down_arrow">
              <p class="fs-6 fw-normal text-gray-3 mb-0">Subject</p>
              <div class="dropdown dropdownSelect">
                <button class="dropdown-toggle text-md
                          dropdownSelectValid" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {{sSelectedSupportOption | titlecase}}
                </button>
                <ul class="dropdown-menu dropdownMenu">
                  <li *ngFor="let options of lSupportOptions" [class.active]="options == sSelectedSupportOption"
                    class="fs-6">
                    <a class="dropdown-item fs-6" href="javascript:void(0)">{{options.name}}</a>
                  </li>
                </ul>

              </div>

            </div>
            <div class="formItm">
              <input id="message" type="text" value="" placeholder="Message" name="message" required>
              <label for="message" class="fs-6 fw-normal">Your Message</label>
            </div>
            <button class="btn weight-600 text-xs
                      btn-outline-gray-radius text-gray-2 btn-md-same-pad mt-4
                      btn-upload">
              <img class="upload_icon" src="../assets/images/svg/upload.svg">
              Upload a File
              <input class="upload-file" type="file" name="myfile" />
            </button>
            <div *ngIf="true" class="alert alert-primary d-flex
                      align-items-center alert-dismissible fade show mt-5 mx-3" role="alert">
              <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 29C21.732 29 28 22.5081 28 14.5C28 6.49187
                          21.732 0 14 0C6.26801 0 0 6.49187 0 14.5C0 22.5081
                          6.26801 29 14 29Z" fill="#66AFD9" />
                <path d="M13.9992 17.0373C14.5732 17.0373 15.0492 16.5443
                            15.0492 15.9498V8.6998C15.0492 8.1053 14.5732 7.6123
                            13.9992 7.6123C13.4252 7.6123 12.9492 8.1053 12.9492
                            8.6998V15.9498C12.9492 16.5443 13.4252 17.0373
                            13.9992 17.0373Z" fill="white" />
                <path d="M15.2876 19.7492C15.2176 19.5752 15.1196
                              19.4157 14.9936 19.2707C14.8536 19.1402 14.6996
                              19.0387 14.5316 18.9662C14.1956 18.8212 13.8036
                              18.8212 13.4676 18.9662C13.2996 19.0387 13.1456
                              19.1402 13.0056 19.2707C12.8796 19.4157 12.7816
                              19.5752 12.7116 19.7492C12.6416 19.9232 12.5996
                              20.1117 12.5996 20.3002C12.5996 20.4887 12.6416
                              20.6772 12.7116 20.8512C12.7816 21.0397 12.8796
                              21.1847 13.0056 21.3297C13.1456 21.4602 13.2996
                              21.5617 13.4676 21.6342C13.6356 21.7067 13.8176
                              21.7502 13.9996 21.7502C14.1816 21.7502 14.3636
                              21.7067 14.5316 21.6342C14.6996 21.5617 14.8536
                              21.4602 14.9936 21.3297C15.1196 21.1847 15.2176
                              21.0397 15.2876 20.8512C15.3576 20.6772 15.3996
                              20.4887 15.3996 20.3002C15.3996 20.1117 15.3576
                              19.9232 15.2876 19.7492Z" fill="white" />
              </svg>
              <div class="me-8">
                Thank you for contacting support. <br>Our team
                will be in touch with you shortly.
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"><svg width="16"
                    height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.546609 15.7333C0.406624 15.7333
                                    0.273304 15.68 0.166649 15.58C-0.0399958
                                    15.3733 -0.0399958 15.0333 0.166649
                                    14.8267L7.11925 7.86667L0.153317
                                    0.906667C0.0533277 0.806667 0 0.673333 0
                                    0.533333C0 0.393333 0.0533277 0.26 0.153317
                                    0.16C0.259972 0.0533333 0.393292 0 0.533277
                                    0C0.673262 0 0.806581 0.0533333 0.913237
                                    0.153333L7.8725 7.11333L14.8251
                                    0.153333C14.9251 0.0533333 15.0584 0 15.1984
                                    0C15.3384 0 15.4717 0.0533333 15.5784
                                    0.153333C15.785 0.36 15.785 0.7 15.5784
                                    0.906667L8.62575 7.86667L15.5717
                                    14.8133C15.6717 14.9133 15.725 15.0467
                                    15.725 15.1867C15.725 15.3267 15.6717 15.46
                                    15.5717 15.5667C15.4717 15.6733 15.3384
                                    15.72 15.1917 15.72C15.0451 15.72 14.9184
                                    15.6667 14.8118 15.5667L7.8725 8.62L0.919903
                                    15.58C0.819913 15.68 0.686594 15.7333
                                    0.546609 15.7333Z" fill="#FF8369" />
                  </svg>
                </button>
              </div>
            </div>


          </div>
        </div>
        <ul class="sideNav-btn px-3">
          <button class="weight-normal btn btn-primary
                            btn-round-6 px-4 py-3">Submit</button>

        </ul>
      </form>
    </nav>

  </ng-container>
</nz-drawer>
<!-- contactus sidebar Modal end-->
<!-- <div class="showSapVideoTimeLineDurationBar"
style=""
*ngIf="prontoSettingService.showSapVideoTimeLineDurationBar"
  >

</div> -->

<!-- Mobile devices toast message -->


  <div *ngIf="this.isMobileDevice"
    class=" alert alert-primary align-items-center alert-dismissible fade show mt-5 success-toaster mobile-toaster d-block"
    role="alert">

    <img class="mt-140" src="../assets/images/icons/alert-success.svg">
    <div class="mobile-device-text me-8">
      Please note that our platform is not optimized for mobile devices. For the best user experience, we recommend
      using the web app on a desktop or laptop. Thank you.

    </div> <div type="button" class="btn-close cross-btn r-22"
    (click)="this.isMobileDevice = false">
  </div>
  </div>

    <!--Selection Confirmation Popup -->
    <nz-modal *ngIf="this.voiceStore.showSelectionConfirmation" [ngClass]="{'success-info-toaster' : true}"
      [(nzVisible)]="this.voiceStore.showSelectionConfirmation" [nzMask]="false" nzCentered [nzWidth]="580" [nzFooter]="null"
      [nzClosable]="false" style="z-index:4000000">
    <div class="modal-dialog modal-dialog-centered mx-4">
      <div class="modal-content">
        <div class="modal-body">
          <h5>{{ this.voiceStore.SelectionConfirmationtitle}}</h5>
          <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">
            {{ this.voiceStore.SelectionConfirmationmessage}}
          </p>
          <p class="text-sm text-gray-3 pt-1 pb-4 mb-0" *ngIf="this.voiceStore.SelectionConfirmationtitle == 'Confirm Your Voice Selection'">
            Unlock more options by upgrading your plan for enhanced creativity.
          </p>
          <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4 p-3" (click)="confirmVoiceSelection()"
          *ngIf="this.voiceStore.SelectionConfirmationtitle == 'Confirm Your Voice Selection'">Confirm Selection</a>
          <a *ngIf="this.onboardinManger.profile.plan =='business'" class="weight-normal btn btn-primary btn-round-6 cust-pad px-4 py-3"
            (click)="upgradeSelction()">Upgrade</a>
          <a class="text-gray-3 text-sm cust-pad ms-3" data-bs-dismiss="modal" (click)="this.voiceStore.showSelectionConfirmation = false">Cancel</a>
        </div>
      </div>
    </div>
    </nz-modal>
    <!-- csv location file upload -->
    <div class="progress-status-bar" *ngIf="this.successMessage.bottomBar && this.successMessage.bottomBar.show">
      <div class="close"></div>
      <div class="header"><p class="m-0 ps-2">{{this.successMessage.bottomBar.title}}</p>
      <img (click)="this.successMessage.resetBottomBar()" class="pe-2" src="../assets/images/canel.png">
      </div>
      <div class="processes">
          <div class="item">{{this.successMessage.bottomBar.message}} <span class="loading-icon">
            <div class="lds-default absolute-right">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          </span></div>
      </div>
    </div>