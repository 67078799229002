
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfigManagerService } from './../../Managers/config-manager.service';
import { CreateVideoManagerService } from './../../Managers/create-video-manager.service';
import { cDataService } from './../../services/data.service';
import { cSessionService } from './../../services/session.service';
import { cTemplateConfigService } from './../../services/template.service';
import { cWebSocketService } from './../../services/websocket.service';

@Component({
  selector: 'app-modify-video-voiceover-sidepanel',
  templateUrl: './modify-video-voiceover-sidepanel.component.html',
  styleUrls: ['./modify-video-voiceover-sidepanel.component.scss']
})
export class ModifyVideoVoiceoverSidepanelComponent implements OnInit {
  bUploadVoiceOverFile: boolean;
  oUploadedVoiceOverFile: any;
  nVoiceOverFileSize: number = 0;
  sSessionId: any;
  sUserEmail: any;
  sUploadedVoiceOverFileName: string = "No file choosen.";
  bShowTextToSpeechSection: boolean = true;
  sSelectedVoiceGender: string = "male";
  oUserVoiceOverList: any = [];
  oVoiceOverList: any = [];
  public sVoiceName: string = "";
  nSelectedVoiceIndex: number;
  fVoiceoverForm: FormGroup;
  oAudio = new Audio();
  oSavedFormData: any;
  sVideoId: string;
  // sEditVideoId: string;
  lBlockConfigurations: any = [];
  bLetProntoChoose: boolean = true;
  bDisplayMyMediaSection: boolean = false;
  bDisplayUploadSection: boolean = true;
  lFilestoUpload: any = [];
  lUploadedFiles: any = [];
  lUserSelectedVisuals: any = [];
  sResetTextOverLays: string;
  bAudioSidepanel: boolean = false;
  va_text: string = "va text";

  @Output() onClose = new EventEmitter<any>();
  @Output() oVoiceOverFileurl = new EventEmitter<string>();
  constructor(
    private oWebSocketService: cWebSocketService,
    private oSessionService: cSessionService,
    private oDataService: cDataService,
    private oFormBuilder: FormBuilder,
    private oTemplateConfigService: cTemplateConfigService,
    public videoManager: CreateVideoManagerService,
    public congifManager: ConfigManagerService
  ) { }

  async ngOnInit() {

    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();

    this.congifManager.voices.sort(this.sortAlphaNum);
    this.oUserVoiceOverList = this.congifManager.voices;
    if (!this.congifManager.voices) {
      await this.congifManager.getVoices(this.sUserEmail, this.sSessionId).then(c => {

        this.oUserVoiceOverList = this.congifManager.voices;
      });
    }

    this.va_text = this.videoManager?.selectedVideo?.vo_text;


    if (this.videoManager?.selectedVideo?.selected_voice) {
      this.sVoiceName = this.videoManager?.selectedVideo?.selected_voice

      if (this.congifManager.voices) {
        // console.log(this.congifManager.voices);
        this.congifManager.voices.forEach(element => {
          if (element.name == this.sVoiceName) {
            this.sSelectedVoiceGender = element.gender;

          }
        });
      }
    } else {
      this.sSelectedVoiceGender = 'male'
    }


    this.ContentComponent_SelectVoiceGender(this.sSelectedVoiceGender, 'create');
    this.sVoiceName = this.videoManager?.selectedVideo?.selected_voice;

  }

  ContentComponent_ToggleFileUploadType(nIncommingType: boolean) {
    this.bUploadVoiceOverFile = nIncommingType;
  }
  async ContentComponent_UploadedVoiceOverFile(event) {
    this.ContentComponent_UploadVoiceover();
    let file = event.target.files[0];
    if (file) {
      this.nVoiceOverFileSize = file.size;
      console.log("ContentComponent_UploadFile : File To Upload ==> ", file);
      var oFormData: any = new FormData();
      oFormData.append('session_id', this.sSessionId);
      oFormData.append('email', this.sUserEmail);
      oFormData.append('file', file);
      try {
        await this.oDataService.DataService_UploadUserVoiceOverFile(oFormData).subscribe((result: any) => {
          console.log("ContentComponent_UploadedVoiceOverFile : Response ==>", result);
          this.oUploadedVoiceOverFile = result;
          this.sUploadedVoiceOverFileName = result.filename;


        },
          (error) => {
            this.oUploadedVoiceOverFile = null;
            console.error("ContentComponent_UploadedVoiceOverFile : Error ==>", error);
          });
      }
      catch (error) {
        console.error("ContentComponent_UploadedVoiceOverFile : ERROR ==>", error);
      }
    }
  }
  ContentComponent_UploadVoiceover() {
    this.bShowTextToSpeechSection = false;
  }
  ContentComponent_TextToSpeech() {
    this.bShowTextToSpeechSection = true;
    this.nVoiceOverFileSize = 0;
    this.sUploadedVoiceOverFileName;
    this.ContentComponent_SelectVoiceGender('male', 'create');
  }
  ContentComponent_SelectVoiceGender(value: any, mode: string) {
    this.sSelectedVoiceGender = value;
    this.sVoiceName = "";
    this.oVoiceOverList = [];
    this.oUserVoiceOverList.forEach(element => {
      if (element.gender == value) {
        this.oVoiceOverList.push(element);
      }
    });

    console.log(" mode voice selected ", mode);
    if (mode === "create") {

      this.ContentComponent_SelectVoice(this.oVoiceOverList[2], 2);
    }
    else {
      const nVoiceIndex = this.oVoiceOverList.findIndex((element: any) => element.value === this.videoManager.selectedVideo.selected_voice);
      this.ContentComponent_SelectVoice(this.oVoiceOverList[nVoiceIndex], nVoiceIndex);
    }
  }
  ContentComponent_SelectVoice(select, index) {
    this.nSelectedVoiceIndex = index;
    this.sVoiceName = select?.name;
  }
  ContentComponent_ReturnFileSize(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  ContentComponent_PlayAudio(filepath) {
    let filename = filepath.replace(/^.*(\\|\/|\:)/, '');
    console.log(filepath);
    this.oAudio = new Audio();
    this.oAudio.src = filepath;
    this.oAudio.load();
    this.oAudio.play();
  }
  ContentComponent_StopAudio(filepath) {
    let filename = filepath.replace(/^.*(\\|\/|\:)/, '');
    console.log(filepath);
    if (this.oAudio.src.replace(/^.*(\\|\/|\:)/, '') == filename) {
      this.oAudio.pause();
      this.oAudio = new Audio();
      this.oAudio.src = filepath;
      this.oAudio.load();
    }
  }
  VideoTimelineView_SubmitfVoiceoverForm() {

    // console.log("voice", this.videoManager.selectedVideo);
    // console.log("bShowTextToSpeechSection", this.bShowTextToSpeechSection);
    if (this.bShowTextToSpeechSection) {
      this.videoManager.selectedVideo.vo_text = this.va_text;
      this.videoManager.selectedVideo.selected_voice = this.sVoiceName;
      this.videoManager.selectedVideo.voice_file = '';
      this.oVoiceOverFileurl.emit('')
    }
    else {
      // console.log("File Path", this.oUploadedVoiceOverFile.fpath);
      this.videoManager.selectedVideo.voice_file = this.oUploadedVoiceOverFile.fpath;
      this.videoManager.selectedVideo.selected_voice = '';
      this.videoManager.selectedVideo.vo_text = '';
      this.oVoiceOverFileurl.emit(this.oUploadedVoiceOverFile)
    }
    this.closeVoiceover();
  }

  closeVoiceover(): void {

    this.onClose.emit();
    //this.visibleMedia = false;
  }

  sortAlphaNum(a, b) {
    console.log(a);
    console.log(b);
    let reA = /[^a-zA-Z]/g;
    let reN = /[^0-9]/g;
    var aA = a.name.replace(reA, "");
    var bA = b.name.replace(reA, "");

    if (aA === bA) {
      var aN = parseInt(a.name.replace(reN, ""), 10);
      var bN = parseInt(b.name.replace(reN, ""), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  }
}
