import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { locale } from './../../models/account';


@Injectable({
  providedIn: 'root'
})
export class AccountsettingService {


    constructor(private http: HttpClient) { }

    updateUserPlan(email,session_id,plan)
    {

     return this.http.post(environment.API_HOST +'user_plan', {email:email,session_id:session_id,plan:plan} );
    }
    ManageBilling(email,session_id)
    {
      console.log("Redirection here ..", 'success_url='+environment.API_HOST+'/payment/success?true&session_id={CHECKOUT_SESSION_ID}&alias_id=${aliasId}')
      return this.http.get(environment.API_HOST +'manage_billing?email='+email+'&session_id='+session_id+'&success_url='+environment.API_HOST+'/payment/success?true&session_id={CHECKOUT_SESSION_ID}&alias_id=${aliasId}');//, {email:email,session_id:session_id} );

    // return this.http.post(environment.API_HOST +'manage_billing', {email:email,session_id:session_id} );
    }

    cancelSubscription(email,session_id)
    {

     return this.http.request('delete',environment.API_HOST +'user_plan',{body: {email:email,session_id:session_id}} );
    }
    cancelReason(email,password,session_id,reason,message)
    {

     return this.http.post(environment.API_HOST +'cancel_reason', {email:email,session_id:session_id,password:password,reason:reason,message:message} );
    }

   contactUs(email,userEmail,subject,message,name)
    {

     return this.http.post(environment.API_HOST +'contact_us', {email:email,user_email:userEmail,subject:subject,message:message,name:name} );
    }

    getLocales(email,session_id,num_items,num_skip,order_by,
      sort_type,
      search_term)
    {

     return this.http.get(environment.API_HOST +'locales?email='+email+"&session_id="+session_id +"&num_items="+num_items +"&num_skip="+num_skip+"&order_by="+order_by +"&sort_type="+sort_type +"&search_term="+search_term);
    }
   createLocales(email,session_id,locales)
    {

     return this.http.post(environment.API_HOST_ADMIN +'set_locales',{email:email,session_id:session_id,locales:locales} );
    }

    deleteLocales(email,session_id,locale)
    {

     return this.http.request('delete',environment.API_HOST +'locale/'+locale.id,{body: {email:email,session_id:session_id}} );
    }
    updateLocale(locale:locale)
    {

     return this.http.post(environment.API_HOST +'locale/'+locale.id,locale);
    }

    disconnectSocialPlatform(email,session_id,platform)
    {

     return this.http.post(environment.API_HOST +'disconnect_social_platform',{email:email, session_id:session_id,platform:platform});
    }

    getInvoices(email,session_id)
    {

     return this.http.get(environment.API_HOST +'billing_history?email='+email+"&session_id="+session_id);
    }


}
