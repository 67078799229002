<ul class="breadcrumb-tabs nav">
  <span
    *ngIf="
      !this.videoManger.previewMode &&
      !localiseExistingVideos &&
      !modifyVideo &&
      !this.modifyVideoManager.isPreview
    "
  >
    <li class="nav-item" role="presentation">
      <a
        routerLink="/wizard/overview"
        routerLinkActive="active"
        class="nav-link"
        >Overview</a
      >
    </li>
    <li class="nav-item" role="presentation">
      <a
        (click)="submitForm()"
        routerLink="/wizard/looknfeel"
        routerLinkActive="active"
        [class.disabled-breadcrumb]="!this.enableMenu"
        class="nav-link"
        >Look & Feel
      </a>
    </li>
    <li class="nav-item" role="presentation">
      <a
        (click)="submitForm()"
        routerLink="/wizard/content"
        routerLinkActive="active"
        [class.disabled-breadcrumb]="!this.enableMenu"
        class="nav-link"
        >Content</a
      >
    </li>

    <li class="nav-item" role="presentation" *ngIf="this.videoManger
      && (this.videoManger?.showAddressTabOnCreateVideo || this.videoManger.selectedVideo?.template_name == '')
      ">
      <a
        (click)="submitForm(false, 'contentforcedsave')"
        routerLink="/wizard/other-setting"
        routerLinkActive="active"
        [class.disabled-breadcrumb]="!this.enableMenu"
        class="nav-link"
        >Other Settings</a
      >
    </li>
  </span>

  <span
    *ngIf="
      this.videoManger?.previewMode &&
      this.videoManger?.isVideoGenerated &&
      !localiseExistingVideos &&
      !modifyVideo &&
      !this.modifyVideoManager?.isPreview
    "
    class="ml-2"
    style="margin-left: 20px"
  >
    <li class="nav-item" role="presentation">
      <a routerLink="/wizard/timeline-view" class="nav-link">Edit Video </a>
    </li>

    <!-- <li class="nav-item" role="presentation">
            <a href="javascript:void(0)" class="nav-link">Video Options</a>
        </li> -->
    <li class="nav-item" role="presentation">
      <a class="nav-link active">Preview</a>
    </li>
  </span>

  <span *ngIf="localiseExistingVideos && !modifyVideo">
    <li class="nav-item" role="presentation">
      <a
        routerLink="/localise-existing-videos/single-video"
        routerLinkActive="active"
        class="nav-link"
        >Single Video</a
      >
    </li>
    <!-- <li class="nav-item" role="presentation">
            <a routerLink="/localise-existing-videos/multiple-videos" routerLinkActive="active"  class="nav-link ">Multiple Videos</a>
        </li> -->
  </span>

  <span
    *ngIf="
      modifyVideo != undefined &&
      modifyVideo &&
      modifyVideoManager?.isPreview &&
      !localiseExistingVideos &&
      !videoManger?.mvPreviewMode
    "
  >
    <li class="nav-item" role="presentation">
      <a routerLink="/mv/overview" routerLinkActive="active" class="nav-link"
        >Overview</a
      >
    </li>
    <li class="nav-item" role="presentation">
      <a
      (click)="submitForm(false, 'lookandfeel')"
        routerLink="/mv/looknfeel"
        routerLinkActive="active"
        [class.disabled-breadcrumb]="!this.enableMenu"
        class="nav-link"
        >Look & Feel</a
      >
    </li>
    <li class="nav-item" role="presentation">
      <a
      (click)="submitForm(false, 'contentforcedsave')"
        routerLink="/mv/content"
        routerLinkActive="active"
        [class.disabled-breadcrumb]="!this.enableMenu"
        class="nav-link"
        >Content</a
      >
    </li>
    <li class="nav-item" role="presentation" *ngIf="this.videoManger  && this.videoManger?.showAddressTabOnCreateVideo">
      <a
      (click)="submitForm(false, 'contentforcedsave')"
        routerLink="/mv/other-setting"
        routerLinkActive="active"
        [class.disabled-breadcrumb]="!this.enableMenu"
        class="nav-link"
        >Other Settings</a
      >
    </li>
  </span>

  <span
    *ngIf="
      this.videoManger.mvPreviewMode &&
      !localiseExistingVideos &&
      !this.modifyVideoManager?.isPreview &&
      this.videoManger?.isVideoGenerated
    "
    class="ml-2"
    style="margin-left: 20px"
  >
    <li class="nav-item" role="presentation">
      <a routerLink="/mv/timeline-view" class="nav-link">Edit Video </a>
    </li>
    <li class="nav-item" role="presentation">
      <a class="nav-link active">Preview</a>
    </li>
  </span>
</ul>
