import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { computeDecimalDigest } from '@angular/compiler/src/i18n/digest';
import { Injectable } from '@angular/core';
import { profile, profileLite } from './../../models/onboarding';
import { environment } from '../../../environments/environment';
import { ApiURL } from 'src/app/conf/api-path';

@Injectable({
    providedIn: 'root',
})
export class OnboardingDataService {
    constructor(private http: HttpClient, private httpBackend: HttpBackend) {}

    signIn(email: string, password: string) {
        return this.http.post(environment.API_HOST + 'pronto_signin', { email: email, password: password });
    }

    signUp(profile: profile) {
        return this.http.post(environment.API_HOST + 'signup', profile);
    }

    updateBusinessProfile(profile: profile) {
        console.log(environment.API_HOST_ADMIN + ApiURL.admin.user.update);
        return this.http.post(environment.API_HOST + ApiURL.user.update, profile);
    }

    updateProfile(profile: profile) {
        return this.http.post(environment.API_HOST + 'signup', profile);
    }

    seletPayment(email, plan) {
        var success_url = `${environment.WS_HOST}/create-account/paywall-success?plan=${plan}&email=${email}&session_id={CHECKOUT_SESSION_ID}`;
        var failed_url = environment.WS_HOST + '/create-account/paywall-failed?plan=' + plan + '&email=' + email;
        var cancel_url = environment.WS_HOST + '/';

        var user_plan = {
            email: email,
            plan: plan,
            success_url: success_url,
            failed_url: failed_url,
            cancel_url: cancel_url,
        };
        return this.http.post(environment.API_HOST + 'subscribe', user_plan);
    }

    async getUserVideoCount(email, session_id) {
        return this.http.get(environment.API_HOST + 'videos_count?email=' + email + '&session_id=' + session_id).toPromise();
    }

    singOut(email, session_id) {
        return this.http.post(environment.API_HOST + 'pronto_signout', { email: email, session_id: session_id });
    }

    verifyCode(email, code) {
        return this.http.post(environment.API_HOST + 'verify_code', { email: email, code: code });
    }
    forgotPassword(email) {
        return this.http.post(environment.API_HOST + 'forgot_password', { email: email });
    }

    verifyResetPasswordCode(email, code): Promise<any> {
        return this.http.get(environment.API_HOST + `verify_reset_password?email=${email}&code=${code}`).toPromise();
    }
    updateForgotPassword(email, code, new_password) {
        return this.http.post(environment.API_HOST + 'update_forgot_password', { email: email, code: code, new_password: new_password });
    }

    signoutAll(email, session_id) {
        return this.http.post(environment.API_HOST + 'pronto_signout/all', { email: email, session_id: session_id });
    }
    signout(email, session_id) {
        return this.http.post(environment.API_HOST + 'pronto_signout', { email: email, session_id: session_id });
    }

    disconnectSocialPlatform(email, session_id, platform) {
        return this.http.post(environment.API_HOST + 'disconnect_social_platform', {
            email: email,
            session_id: session_id,
            platform: platform,
        });
    }
    session(email, auth_type: string = 'pronto') {
        return this.http.post(environment.API_HOST + 'session', { email: email, auth_type: auth_type });
    }

    guest(email) {
        return this.http.post(environment.API_HOST + 'guest', { email: email });
    }

    updatePassword(email, session_id, oldpassword, password, bypassInterceptor?) {
        console.log('old password', oldpassword);
        console.log('new password', password);
        if (bypassInterceptor) {
            return this.httpBackend.handle(
                new HttpRequest('POST', environment.API_HOST + 'update_password', {
                    email: email,
                    session_id: session_id,
                    password: password,
                    old_password: oldpassword,
                }),
            );
        }
        return this.http.post(environment.API_HOST + 'update_password', {
            email: email,
            session_id: session_id,
            password: password,
            old_password: oldpassword,
        });
    }

    getUserProfile(email, session_id, bypassInterceptor = false) {
        if (bypassInterceptor) {
            let headers = new Headers();
            headers.append('Content-Type', 'application/json');
            return this.httpBackend.handle(
                new HttpRequest('GET', environment.API_HOST + 'profile?email=' + email + '&session_id=' + session_id, { headers: headers }),
            );
        }
        return this.http.get(environment.API_HOST + 'profile?email=' + email + '&session_id=' + session_id);
    }

    updateUserProfile(profile: profile | profileLite) {
        return this.http.post(environment.API_HOST + 'update_profile', profile);
    }
    deleteUserProfile(email, session_id) {
        return this.http.request('delete', environment.API_HOST + 'profile', { body: { email: email, session_id: session_id } });
    }

    verifyEmail(email, code) {
        return this.httpBackend.handle(new HttpRequest('GET', environment.API_HOST + 'verify_email?email=' + email + '&code=' + code));
        // return this.http.get(environment.API_HOST + "verify_email?email=" + email+"&code="+code);
    }

    reVerifyEmail(email) {
        return this.http.post(environment.API_HOST + 'resend_verify_email', { email: email });
    }
}
