import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { user } from "src/app/models/admin";

import { environment } from "src/environments/environment";
import { brand } from "src/app/models/admin";
import { ApiURL } from "src/app/conf/api-path";

@Injectable({
  providedIn: "root",
})
export class MyAccountService {
  constructor(private http: HttpClient) {}
  public showGridDataLoader = false;
  getUsers(
    email: string,
    sessionId: string,
    offset: number,
    limit: number,
    sortBy = "created",
    asc = -1,
    search_by: string,
    search_term: string
  ) {
    const data = {
      offset,
      limit,
      sort_by: sortBy,
      asc: asc,
      search_by,
      search_term,
    };

    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.user.get, data)
      .toPromise();
  }

  addUsers(email: string, sessionId: string, user: user) {
    console.log(user);
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.user.add, user)
      .toPromise();
  }

  getUserProfile(_id: string) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.user.profile, { _id })
      .toPromise();
  }

  listUserBrands(user_id: string) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.user.brands, { user_id })
      .toPromise();
  }

  listBrandsUsers(brand_id: string) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.user.brands, { brand_id })
      .toPromise();
  }

  getBrands(email: string, sessionId: string, offset: number, limit: number) {
    return this.http
      .get(
        environment.API_HOST +
          "admin/brands?email=" +
          email +
          "&session_id=" +
          sessionId +
          "&num_skip=" +
          offset +
          "&num_items=" +
          limit
      )
      .toPromise();
  }

  deleteBrands(email: string, sessionId: string, brand: string) {
    return this.http
      .delete(
        environment.API_HOST +
          "admin/brand?email=" +
          email +
          "&session_id=" +
          sessionId +
          "&brand=" +
          brand
      )
      .toPromise();
  }

  userAssignBrand(
    email: string,
    sessionId: string,
    brand: string,
    userEmail: string
  ) {
    return this.http
      .post(environment.API_HOST + "admin/user_assign_brand", {
        email: email,
        session_id: sessionId,
        brand: brand,
        user_email: userEmail,
      })
      .toPromise();
  }

  userUnAssignBrand(
    email: string,
    sessionId: string,
    brand: string,
    userEmail: string
  ) {
    return this.http
      .post(environment.API_HOST + "admin/user_unassign_brand", {
        email: email,
        session_id: sessionId,
        brand: brand,
        user_email: userEmail,
      })
      .toPromise();
  }
  getListBrands(
    email: string,
    sessionId: string,
    offset: number,
    limit: number,
    sortBy = "created",
    asc = -1,
    search_by: string,
    search_term: string
  ) {
    const data = {
      offset,
      limit,
      sort_by: sortBy,
      asc: asc,
      search_by,
      search_term,
      email
    };

    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.getAll, data)
      .toPromise();
  }

  getListMediaLibraries(
    email: string,
    sessionId: string,
    offset: number,
    limit: number,
    sortBy = "created",
    asc = -1,
    search_by: string,
    search_term: string
  ) {
    const data = {
      offset,
      limit,
      sort_by: sortBy,
      asc: asc,
      search_by,
      search_term,
    };

    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.media.getAll, data)
      .toPromise();
  }

  addBrands(email: string, sessionId: string, brand: brand) {
    console.log(brand);
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.add, {
        ...brand,
        email: email,
        session_id: sessionId,
      })
      .toPromise();
  }

  updateUserPermission(permission: any) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.user.update, permission)
      .toPromise();
  }

  getBrandProfile(_id: string) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.getUserBrands, {
        _id,
      })
      .toPromise();
  }

  getMediaLibraries(
    email: string,
    sessionId: string,
    offset: number,
    limit: number,
    sortBy = "created",
    asc = -1,
    search_by: string,
    search_term: string
  ) {
    const data = {
      offset,
      limit,
      sort_by: sortBy,
      asc: asc,
      search_by,
      search_term,
    };

    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.media.getAll, data)
      .toPromise();
  }
  getUserMediaLibraries(email: string, search_term: string) {
    const data = {
      email,
      search_term,
    };

    return this.http
      .post(
        environment.API_HOST_ADMIN + ApiURL.admin.media.getUserMediaLib,
        data
      )
      .toPromise();
  }

  getMediaLibrayUsers(media_lib_id: string) {
    const data = {
      media_lib_id ,
    };

    return this.http
      .post(
        environment.API_HOST_ADMIN + ApiURL.admin.media.getUserMediaLib,
        data
      )
      .toPromise();
  }

  addUserBrand(brandId: any, userIds: string[]) {

    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.addUserBrand, {
        brand_ids: brandId,
        user_ids: userIds,
      })
      .toPromise();
  }

  addMediaLibray(mediaLibObj) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.media.add, {
        ...mediaLibObj,
      })
      .toPromise();
  }
  addUserMediaLibrary(mediaLibraryId: any, userIds: any[]) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.media.addUserMediaLib, {
        media_lib_ids: mediaLibraryId,
        user_ids: userIds,
      })
      .toPromise();
  }

  updateBrand(brand: brand) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.update, {
        ...brand,
      })
      .toPromise();
  }
  getIndustryList() {

    return this.http
    .post(environment.API_HOST_ADMIN + ApiURL.admin.industry.getAll, "")
    .toPromise();
  }
}
