<app-timeline-view-process *ngIf="bLoading" [bResCheck]="bResponse"></app-timeline-view-process>

<!-- main section -->
<section *ngIf="!bLoading && !bDisplayErrorMsg" class="accountSec main-body bg-gray-7 h-100">
    <div class="container" style="padding-top: 15px !important;">

        <div class="h-80vh br12 curve-bg tab-content bg-white page-shadow" id="pills-tabContent">
            <div class="tab-pane fade show active">

                <div class="content_area ps-5">
                    <div class="publishVideoRow">
                        <div class="publishVideoRowLeft">
                            <div class="video_wrapper video_wrapper_full js-videoWrapper ms-auto">
                                <!-- <video  #videoPreview controls disablepictureinpicture controlsList="nodownload noplaybackrate" class="videoIframe js-videoIframe" width="620" height="350"
                                    [src]=" this.videoManger.selectedVideo?.video_url">
                            </video>
                                <button class="videoPoster js-videoPoster" [ngClass]="hideButton?'d-none':''" (click)="PreviewComponent_PlayVideo()"
                                    style="background-image: url({{oGeneratedVideo.thumb}})"></button> -->

                                <video #videoPreview controls disablepictureinpicture
                                    controlsList="nodownload noplaybackrate" class="videoIframe js-videoIframe"
                                    [muted]="true" [src]="this.videoManger.selectedVideo?.video_url"></video>
                                <figure class="video-baner" [ngClass]="this.videoPlay? 'zind' : ''">
                                    <img height="100%" [src]="this.videoManger.selectedVideo?.thumbnail" alt="video1" />
                                </figure>
                                <button class="videoPoster js-videoPoster"
                                    (click)="PreviewComponent_PlayVideo()"></button>

                            </div>


                        </div>
                        <div class="publishVideoRowRight">

                            <h2 class="text-gray-2 mb-1">{{this.videoManger.selectedVideo.title}}</h2>
                            <p class="text-gray-3 text-sm fw-bold">Business Information</p>

                            <div class="d-flex mt-3" *ngIf="this.videoManger?.selectedVideo?.ci_dict?.url">
                                <img src="../../../../assets/images/Location.svg">
                                <p class="text-gray-3 text-sm ps-4 m-0 pt-3"><span class="fw-bold"> Website</span> <br>
                                    {{this.videoManger?.selectedVideo?.ci_dict?.url}}</p>
                            </div>
                            <div class="d-flex mt-3" *ngIf="this.videoManger?.selectedVideo?.ci_dict?.address">
                                <img src="../../../../assets/images/Address.svg">
                                <p class="text-gray-3 text-sm ps-4 m-0 pt-3"><span class="fw-bold"> Address</span>
                                    <br>{{this.videoManger?.selectedVideo?.ci_dict?.address?.line1 + ', ' +
                                    this.videoManger?.selectedVideo?.ci_dict?.address?.state + ' ' +
                                    this.videoManger?.selectedVideo?.ci_dict?.address?.country + ' ' +
                                    this.videoManger?.selectedVideo?.ci_dict?.address?.postal_code }}
                                </p>
                            </div>




                        </div>
                    </div>

                    <div class="col-md-6" style="margin-top: 30px; margin-left: 30px;">
                        <h4 class="text-gray-2 mb-1">Your first video draft is ready!</h4>
                        <p class="text-gray-3 text-lg mb-0"><span class="message-upgrade-account">Save, share,</span>
                            and <span class="message-upgrade-account">download</span> your watermarked video for free.
                        </p>
                        <p class="text-gray-3 text-lg fw-bold">Upgrade to <span class="message-upgrade-account">edit the
                                video </span>and access advanced features.</p>


                    </div>
                </div>
            </div>


            <div class="wtermarklogo">
                <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
            </div>
        </div>
    </div>
</section>
<!-- <div class="fixsidebar">
    <ul>
        <li>
            <a href="javascript:void(0);" (click)="openLocation()">
                <figure>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10 11.75C10.41 11.75 10.75 11.41 10.75 11V6C10.75 5.59 10.41 5.25 10 5.25C9.59 5.25 9.25 5.59 9.25 6V11C9.25 11.41 9.59 11.75 10 11.75ZM10.71 13.2899C10.8 13.3899 10.87 13.4999 10.92 13.6199C10.97 13.7399 11 13.8699 11 13.9999C11 14.1299 10.97 14.2599 10.92 14.3799C10.87 14.5099 10.8 14.6099 10.71 14.7099C10.61 14.7999 10.5 14.8699 10.38 14.9199C10.26 14.9699 10.13 14.9999 10 14.9999C9.87 14.9999 9.74 14.9699 9.62 14.9199C9.5 14.8699 9.39 14.7999 9.29 14.7099C9.2 14.6099 9.13 14.5099 9.08 14.3799C9.03 14.2599 9 14.1299 9 13.9999C9 13.8699 9.03 13.7399 9.08 13.6199C9.13 13.4999 9.2 13.3899 9.29 13.2899C9.39 13.1999 9.5 13.1299 9.62 13.0799C9.86 12.9799 10.14 12.9799 10.38 13.0799C10.5 13.1299 10.61 13.1999 10.71 13.2899Z" fill="#7A7A7A"/>
                    </svg>
                </figure>
                Overview
            </a>
        </li>




    </ul>
</div> -->

<nz-drawer [nzClosable]="false" [nzVisible]="visibleLocation" [nzPlacement]="placementLocation"
    (nzOnClose)="closeLocation()" [nzWidth]="478">
    <ng-container *nzDrawerContent>
        <nav class="pt-5">
            <div class="sidebar-header">
                <h3 class="text-gray-2 weight-400 text-lg ps-4">Preview Video</h3>
                <div class="closeRightSideBar pe-2" (click)="closeLocation()">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.409957 11.8C0.304968 11.8 0.204978 11.76 0.124987 11.685C-0.0299968 11.53 -0.0299968 11.275 0.124987 11.12L5.33943 5.9L0.114988 0.68C0.0399958 0.605 0 0.505 0 0.4C0 0.295 0.0399958 0.195 0.114988 0.12C0.194979 0.04 0.294969 0 0.399958 0C0.504947 0 0.604936 0.04 0.684927 0.115L5.90437 5.335L11.1188 0.115C11.1938 0.04 11.2938 0 11.3988 0C11.5038 0 11.6038 0.04 11.6838 0.115C11.8387 0.27 11.8387 0.525 11.6838 0.68L6.46931 5.9L11.6788 11.11C11.7538 11.185 11.7938 11.285 11.7938 11.39C11.7938 11.495 11.7538 11.595 11.6788 11.675C11.6038 11.755 11.5038 11.79 11.3938 11.79C11.2838 11.79 11.1888 11.75 11.1088 11.675L5.90437 6.465L0.689927 11.685C0.614935 11.76 0.514945 11.8 0.409957 11.8Z"
                                fill="#7A7A7A"></path>
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect width="11.8" height="11.8" fill="white"></rect>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
            <div class="side-content pt-0">
                <div class="video_wrapper video_wrapper_full js-videoWrapper">

                    <iframe class="videoIframe js-videoIframe" width="620" height="350"
                        src="https://www.youtube.com/embed/XULUBg_ZcAU" title="" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                    <button class="videoPoster js-videoPoster"></button>
                </div>

                <p class="text-md text-gray-3 mt-4 pt-2">Prontopia Tips & Tricks</p>
            </div>
        </nav>
    </ng-container>
</nz-drawer>


<div class="overlay sidebarOverlay"></div>
<div class="overlay sidebarOverlay2"></div>
<!-- error modal -->
<div class="modal fade" id="error-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog width-483 modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-body">
                <button type="button" class="btn-close cust-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <h5 class="mb-4">{{sErrorMessageTitle}}</h5>
                <div class="alert alert-danger p-3 mb-4 g-0 row rounded-1">
                    <div class="col-1 p-0">
                        <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                                <path
                                    d="M13.9993 3.47998C6.92399 3.47998 1.16602 9.20176 1.16602 16.2326C1.16602 23.2635 6.92399 29 13.9993 29C21.0747 29 26.8327 23.2782 26.8327 16.2473C26.8327 9.21646 21.0747 3.47998 13.9993 3.47998ZM15.1095 15.7619C15.1095 16.365 14.6062 16.8651 13.9993 16.8651C13.3925 16.8651 12.8892 16.365 12.8892 15.7619V8.40747C12.8892 7.80441 13.3925 7.3043 13.9993 7.3043C14.6062 7.3043 15.1095 7.80441 15.1095 8.40747V15.7619Z"
                                    fill="#FF8369"></path>
                                <path
                                    d="M17.7366 2.32H10.2647C9.74765 2.32 9.33398 1.808 9.33398 1.168C9.33398 0.528 9.74765 0 10.2647 0H17.7366C18.2537 0 18.6673 0.512 18.6673 1.152C18.6673 1.792 18.2537 2.32 17.7366 2.32Z"
                                    fill="#FF8369"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="28" height="29" fill="white"></rect>
                                </clipPath>
                            </defs>
                        </svg>


                    </div>
                    <div class="col-11 p-0">

                        <p class="text-sm text-gray-3 mb-0 ms-2">{{sErrorMessage}}</p>
                    </div>
                </div>

                <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" data-bs-dismiss="modal"
                    routerLink="/dashboard/myvideos">Go Back</a>

            </div>
        </div>
    </div>
</div>
<!-- error modal end -->