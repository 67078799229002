import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigManagerService } from 'src/app/Managers/config-manager.service';
import { OnboardingManagerService } from 'src/app/Managers/onboarding-manager.service';
import { cDataService } from 'src/app/services/data.service';
import { cSessionService } from 'src/app/services/session.service';



@Component({
  selector: 'app-account-sidebar',
  templateUrl: './account-sidebar.component.html',
  styleUrls: ['./account-sidebar.component.scss']
})
export class AccountSidebarComponent implements OnInit, OnDestroy {
  bDisplaySubscriptionToggler: boolean
  isEnterpriseUser: boolean = false;
  bLocales: boolean;
  isSubscriptionEnable: boolean = true;
  constructor(private oRouter: Router, private configManger: ConfigManagerService, public onboarding: OnboardingManagerService, private oDataService: cDataService, public oSessionService: cSessionService,) {
    this.isSubscriptionEnable = this.configManger.metaInfo.stripe_enabled;

    //  console.log("USer plan here ", this.onboarding?.profile?.plan )
    if (this.onboarding?.profile?.plan === "enterprise") {
      this.isEnterpriseUser = true;
    }
  }
  ngOnInit(): void {
    this.AccountSidebarComponent_LocalesCheck();
    this.oDataService.bDisplaySubscriptionToggler.subscribe(res => {
      this.bDisplaySubscriptionToggler = res
    })
  }

  ngOnDestroy() {
    this.oSessionService.isSidebarActive = true;
  }
  AccountSidebarComponent_LocalesCheck() {
    this.bLocales = (this.onboarding.profile.localization_enabled && this.onboarding.profile?.plan == 'enterprise') ? true : false;
  }

  sidebarBtn() {
    const sideBtn = document.getElementById('sidebar-left');
    sideBtn.classList.toggle('collapsed');

    if (this.oSessionService.isSidebarActive) {
      this.oSessionService.isSidebarActive = false;
    }
    else {

      this.oSessionService.isSidebarActive = true;
    }
  }
}
