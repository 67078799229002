import { Component, ElementRef, OnDestroy, OnInit, ViewChild,Input, Output, ChangeDetectorRef, } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CreateVideoManagerService } from 'src/app/Managers/create-video-manager.service';
import { MediaManagerService } from 'src/app/Managers/media-manager.service';
import { ModifyVideoManagerService } from 'src/app/Managers/modify-video-manager.service';
import { VideoManagerService } from 'src/app/Managers/video-manager.service';
import { ModifyVideo } from 'src/app/models/modify-video';
import { ErrorService } from 'src/app/services/error.service';
import { cSessionService } from 'src/app/services/session.service';
import { UploadFileDialogComponent } from 'src/app/shared/components/upload-file-dialog/upload-file-dialog.component';
import { mediaFile, visual } from '../../../../models/media';

@Component({
  selector: 'app-mv-content',
  templateUrl: './sap-content.component.html',
  styleUrls: ['./sap-content.component.scss']
})
export class SapContentComponent implements OnInit, OnDestroy {
  @ViewChild('closeModal') closeModal: ElementRef;
  @ViewChild('file', {static: false}) file: ElementRef;
  uploadModalHeaderText: string = "Upload Media";
  sSelectedTab: string = "video content";
  public oHighlightedTabs: any;

  bLetProntoChoose: boolean = true;
  bDisplayUploadSection: boolean = false;
  bUploadVoiceOverFile: boolean;
  lUploadedFiles: any = [];
  lFailedToUpload: any = [];
  bDisplayFailedToUploadSection: boolean = false;
  imageSrc: any;
  lUserSelectedVisuals: any = [];
  oInputVisualToRemove: any;
  bDisplayMyMediaSection: boolean = false;

  sDisplayResponseMessage: string;
  bDisplayErrorMessage: boolean = false;
  lFilestoUpload: any = [];
  nFileToUploadIndex: number = 0;
  lImagesList: any = [];
  lClipsList: any = [];
  sSessionId: any;
  sUserEmail: any;

  constructor(private sanitizer: DomSanitizer, public modifyVideoManager: ModifyVideoManagerService, private router: Router,public modifyVideoObj : ModifyVideo,
    private oSessionService: cSessionService,public errorService:ErrorService,
    public mediaManger:MediaManagerService,
    public videoManager: CreateVideoManagerService,
    public videoListingManager: VideoManagerService,
    private dialog: MatDialog,
    private cd : ChangeDetectorRef) { }

  sub: Subscription;
  sub2: Subscription;
  ngOnInit() {


    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();

    // this.lUserSelectedVisuals = this.modifyVideoObj.mediaList;
    // this.lUploadedFiles = this.modifyVideoObj.ownList;
    console.log("check", this.modifyVideoObj.mediaList);

    this.sub2 = this.videoManager.previousSubmit.subscribe(emit => {
      this.router.navigateByUrl('/mv/looknfeel');

    });

    this.sub = this.videoManager.submitForm.subscribe(c => {

      this.videoManager.selectedVideo.email = this.sUserEmail;
      this.videoManager.selectedVideo.session_id = this.sSessionId;

      this.submitForm(c);
    });
    console.log(this.modifyVideoObj, this.modifyVideoObj.name, this.modifyVideoObj.videoObj,
     this.modifyVideoObj.keyword, this.modifyVideoObj.logo);


    this.sSelectedTab = 'video content';
  }
  ngOnDestroy() {
    if(this.sub2)
    this.sub2.unsubscribe();
    if(this.sub)
    this.sub.unsubscribe();  if (this.videoManager.isEditMode) {
      this.updateContent();
    }
  }

  redirectToPreviousPage = false;
  submitForm(submitFrom: string) {
    if( submitFrom == 'lookandfeel' || submitFrom == 'noGenerate'){
      this.redirectToPreviousPage = true;
    } else {
      this.redirectToPreviousPage = false;
    }
    this.updateContent();

    console.log(' looknfeel ', submitFrom,  this.redirectToPreviousPage);
    this.videoManager.createVideo(this.videoManager.selectedVideo).then((res) => {
      if (res) {
          if(!this.redirectToPreviousPage){
            this.router.navigateByUrl('/mv/other-setting');
          } else {
            this.router.navigateByUrl('/mv/looknfeel');
          }
      }
    });
  }

  // submitForm(submitFrom: string) {
  //   this.updateContent();

  //    this.updateVideoLocally();
  //     console.log(this.videoManager.selectedVideo);
  //     if(!this.videoManager.selectedVideo.template_name || this.videoManager.selectedVideo.template_name == ''){
  //       this.videoManager.selectedVideo.template_name = "SAP";
  //     }

  //     this.videoManager
  //       .createVideo(this.videoManager.selectedVideo)
  //       .then((res) => {
  //         this.videoListingManager.selectedVideo =
  //           this.videoManager.selectedVideo;

  //           this.router.navigateByUrl('/mv/preview');
  //       });
  //   }


  private updateVideoLocally() {
    console.log("update locally");

      this.videoManager.selectedVideo.url = undefined;


      this.videoManager.selectedVideo.address = undefined;

    //   this.videoManager.selectedVideo.address.line1 =
    //     this.fOtherSettingForm.value.line1;
    //   this.videoManager.selectedVideo.address.line2 =
    //     this.fOtherSettingForm.value.line2 ? this.fOtherSettingForm.value.line2 : '';
    //   this.videoManager.selectedVideo.address.city =
    //     this.fOtherSettingForm.value.city;
    //   this.videoManager.selectedVideo.address.state =
    //     this.fOtherSettingForm.value.state;
    //   this.videoManager.selectedVideo.address.postal_code =
    //     this.fOtherSettingForm.value.postal_code;
    //   this.videoManager.selectedVideo.address.country =
    //     this.fOtherSettingForm.value.country;
    // } else {
      // if (this.videoManager.selectedVideo.ci_dict) {
        // this.videoManager.selectedVideo.ci_dict.address = undefined;
      // }
      this.videoManager.selectedVideo.address = undefined;
    // }

    // if (this.bPhoneToggle) {
    //   this.videoManager.selectedVideo.phone =
    //     this.fOtherSettingForm.value.phone;
    // }
  }

  private updateContent() {
    this.videoManager.selectedVideo.use_pronto_clips = this.bLetProntoChoose;

    this.lImagesList = [];
    this.lClipsList = [];
    if (this.bDisplayUploadSection) {
      for (
        let nVisualIndex = 0;
        nVisualIndex < this.lUploadedFiles.length;
        nVisualIndex++
      ) {
        const element = this.lUploadedFiles[nVisualIndex];
        const extension = element.filename.split(".").pop();
        if (
          extension === "png" ||
          extension === "jpg" ||
          extension === "jpeg"
        ) {
          this.lImagesList.push(element.fpath);
        } else {
          this.lClipsList.push(element.fpath);
        }
      }
    }
    if (this.bDisplayMyMediaSection) {
      for (
        let nVisualIndex = 0;
        nVisualIndex < this.lUserSelectedVisuals.length;
        nVisualIndex++
      ) {
        const element = this.lUserSelectedVisuals[nVisualIndex] as visual;
        const extension = element.visual_type;
        if (element.visual_type == "image") {
          this.lImagesList.push(element.visual_path);
        } else if (element.visual_type == "clip") {
          this.lClipsList.push(element.visual_path);
        }
      }
    }
    this.videoManager.selectedVideo.user_clips = this.lClipsList;

    this.videoManager.selectedVideo.user_images = this.lImagesList;
    this.videoManager.selectedVideo.status = "sucess";
  }


  ContentComponent_HighlightTabs() {
    if (this.oHighlightedTabs.overlays) {
      this.ContentComponent_CheckTextOverlaysTab();
    }
    if (this.oHighlightedTabs.voiceover) {
      this.ContentComponent_CheckVoiceOverTextTab();
    }
    if (this.oHighlightedTabs.content) {
      this.ContentComponent_CheckVideoContentTab()
    }
  }
  ContentComponent_CheckVideoContentTab() {
    this.sSelectedTab = "video content";
    let selectedTab = document.getElementById('Video-Content-tab');
    if (selectedTab.classList.contains('active')) {
      this.uploadModalHeaderText = "Upload Media";
    }
    else {
      this.uploadModalHeaderText = "Upload Media"
    }
    this.oHighlightedTabs.content = true;
  }
  ContentComponent_CheckTextOverlaysTab() {
    this.sSelectedTab = "text overlays";
  }
  ContentComponent_CheckVoiceOverTextTab() {
    this.sSelectedTab = "voiceover text";
    let selectedTab = document.getElementById('Video-Content-tab');
    if (selectedTab.classList.contains('active')) {
      this.uploadModalHeaderText = "Upload Media";
    }
    else {
      this.uploadModalHeaderText = "Upload Media"
    }
  }

  ContentComponent_ReturnFileSize(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  ContentComponent_RemoveFileFromList(sIncommingVisualPath: string) {
    console.log("income",sIncommingVisualPath);

    for (let nFiletoRemoveIndex = 0; nFiletoRemoveIndex < this.lUploadedFiles.length; nFiletoRemoveIndex++) {
      if (this.lUploadedFiles[nFiletoRemoveIndex].fpath === sIncommingVisualPath) {

        console.log('remove Index',nFiletoRemoveIndex);

      this.lUploadedFiles.splice(nFiletoRemoveIndex, 1);
      }
    }
  }
  ContentComponent_RemoveFailedFileFileFromList(sIncommingFileName: string) {
    for (let nFiletoRemoveIndex = 0; nFiletoRemoveIndex < this.lFailedToUpload.length; nFiletoRemoveIndex++) {
      if (this.lFailedToUpload[nFiletoRemoveIndex].name === sIncommingFileName) {
        this.lFailedToUpload.splice(nFiletoRemoveIndex, 1);
      }
    }
  }

  removeSelectedVisuals(oIncommingVisual: any) {

    let nVisualIndex = this.lUserSelectedVisuals.indexOf(oIncommingVisual);
    this.lUserSelectedVisuals.splice(nVisualIndex, 1);
    this.oInputVisualToRemove = oIncommingVisual;
  }
  ContentComponent_ToggleLetProntoChoose(event: any) {
    this.bLetProntoChoose = event.target.checked;

  }
  ContentComponent_ToggleFileUploadSection(event: any) {
    this.bDisplayUploadSection = event.target.checked;
  }
  ContentComponent_ToggleFileUploadType(nIncommingType: boolean) {
    this.bUploadVoiceOverFile = nIncommingType;
  }
  ContentComponent_ToggleFailedToUploadSection(event: any) {
    this.bDisplayFailedToUploadSection = event.target.checked;
  }

  ContentComponent_ToggleMyMediaLibrary(event: any) {
    this.bDisplayMyMediaSection = event.target.checked;

  }


  // ContentComponent_SelectFilesForUpload(event) {
  //   console.log("event", event)
  //   this.nFileToUploadIndex = 0;
  //   this.lFilestoUpload = event.target.files;
  //   for (let nFileUploadIndex = 0; nFileUploadIndex < this.lFilestoUpload.length; nFileUploadIndex++) {
  //     if (this.lFilestoUpload[nFileUploadIndex].type.indexOf('image') > -1 || this.lFilestoUpload[nFileUploadIndex].type.indexOf('video') > -1) {
  //       this.ContentComponent_UploadFile(this.lFilestoUpload[nFileUploadIndex]);
  //     }
  //     else {
  //       console.log("ContentComponent_SelectFilesForUpload : Please upload image/video file");
  //     }
  //   }
  // }
  // async ContentComponent_UploadFile(fileToUpload: any) {
  //   console.log("ContentComponent_UploadFile : File To Upload ==> ", fileToUpload);


  // let media = new mediaFile();
  // media.file = fileToUpload;
  // media.name = fileToUpload.name;

  //   try {
  //     await this.mediaManger.uploadMediaDefaultLibrary(this.sUserEmail,this.sSessionId,media).then((result: visual) => {
  //       console.log("ContentComponent_UploadFile : Response ==> ", result);
  //       this.lUploadedFiles.push({ filename: result.name, fpath: result.visual_path, thumb: result.thumbnail, url: result.url, size: this.lFilestoUpload[this.nFileToUploadIndex].size });
  //       this.nFileToUploadIndex++;
  //       this.closeModal.nativeElement.click();
  //     },
  //       (error) => {
  //         this.lFailedToUpload.push(fileToUpload);
  //         console.error("ContentComponent_UploadFile : Fialed list ==>", this.lFailedToUpload);
  //         console.error("ContentComponent_UploadFile : Error ==>", error);

  //         this.sDisplayResponseMessage = 'An error occurred while creating video. Please try again.';
  //         // $('#error-popup').modal("show");
  //         this.bDisplayErrorMessage = true;
  //         setTimeout(() => { this.bDisplayErrorMessage = false; }, 5000);
  //         this.closeModal.nativeElement.click();
  //       });
  //   }
  //   catch (error) {
  //     this.errorService.handleError(error.errorMessage,error.errorType,true);
  //     console.error("ContentComponent_UploadFile : ERROR ==>", error);
  //   }
  // }


//Update video logo
openFileUploadModal(uploadFor, heading, type ) {

  const dialogConfig = new MatDialogConfig();

  dialogConfig.disableClose = false;
  dialogConfig.autoFocus = true;
  dialogConfig.width = "100wv";
  dialogConfig.height = "100vh";
  dialogConfig.panelClass = "pronto-dialog";
  dialogConfig.closeOnNavigation = true;

  dialogConfig.data = {
    uploadFor: uploadFor,
    multipleUpload: false,
    heading: heading,
  };

  let dialogModal = this.dialog.open(UploadFileDialogComponent, dialogConfig);

  dialogModal.afterClosed().subscribe((data: any) => {
    if (data.status == "success") {
      const result = data.result[0];
      if(type === 'logoUpload') {
      } else {
        this.lUploadedFiles.push({ filename: result.name,
          fpath: result.visual_path,
          thumb: result.thumbnail,
          url: result.url,
          size: result.size });
        this.nFileToUploadIndex++;
      }

      this.cd.detectChanges();
    } else {

    }
  });
}

  readURL(file){

    this.imageSrc = URL.createObjectURL(file);
    return this.imageSrc;
  }
  sanitizeImageUrl(imageUrl: string): SafeUrl {
  return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

  mediaSelected(event) {
    this.lUserSelectedVisuals = event;
    console.log(this.lUserSelectedVisuals);
    this.visible = false;
  }
   // modal media library
   visible = false;
   showMediaModal() {
     this.visible = true;
   }
   closeMediaModal(): void {
     console.log('Button cancel clicked!');
     this.visible = false;
   }
   fileClick(){
    this.file.nativeElement.click();
   }
  }

