<app-header></app-header>
<section class="accountSec main-body bg-gray-7 h-100">

    <div class="container d-flex flex-column h-100">
        <ul class="breadcrumb my-3">
            <li><a href="#">Business Profile</a></li>
            <li>Users</li>
        </ul>
        <div class="accountSecInner br12 bg-white page-shadow h-82vh overflow-hidden" id="row-main">
                <app-account-sidebar class="col-md-3 pe-0 pb-0 border-end position-relative sidebar d-flex flex-column h-100"
                id="sidebar-left"></app-account-sidebar>
            <div class="col-md-9 ps-0 d-flex flex-column h-100" id="content">
                <div class="video-header row m-0 ms-3 px-4 pt-4">
                    <p class="h3 weight-600 text-gray-2 pb-1 px-0 col-lg-8 mb-0">
                        <a href="BusinessPlus_1_User.html" class="me-2">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.01519 15.1432C8.21658 15.3392 8.21658 15.657 8.01519 15.853C7.81379 16.049 7.48727 16.049 7.28588 15.853L0.55383 9.30125C-0.18461 8.58259 -0.18461 7.41741 0.55383 6.69874L7.28588 0.146999C7.48727 -0.0489997 7.81379 -0.0489997 8.01519 0.146999C8.21658 0.342998 8.21658 0.660776 8.01519 0.856774L1.28314 7.40852C1.2526 7.43824 1.22484 7.46964 1.19986 7.50241L15.4823 7.50241C15.7682 7.50241 16 7.72627 16 8.00242C16 8.27856 15.7682 8.50242 15.4823 8.50242L1.20358 8.50243C1.22759 8.53345 1.25411 8.56323 1.28314 8.59148L8.01519 15.1432Z"
                                    fill="#404243" />
                            </svg>
                        </a>
                        Harvey Ellen
                    </p>
                    <div class="ps-4 ms-2 mb-3">
                        <a href="#" class="text-xs text-gray-2">Admin</a> .
                        <a href="#" class="text-xs text-gray-3">Active</a>
                    </div>
                </div>
                <div class="container mx-0 ms-4 pe-0 ps-3 business-media" id="container-view">
                    <div class="row filter-post">
                        <div class="col-md-12 p-0 ps-3">
                            <ul class="breadcrumb-tabs nav m-0">
                                <li class="nav-item ps-0">
                                    <a href="javascript:void(0)" routerLink="/account-setting/user-account" class="nav-link">Account</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/account-setting/user-published-videos" href="javascript:void(0)" class="nav-link">Published Video</a>
                                </li>
                                <li class="nav-item">
                                    <a href="javascript:void(0)" routerLink="/account-setting/user-schedule-video" class="nav-link">Scheduled Video</a>
                                </li>
                                <li class="nav-item">
                                    <a href="javascript:void(0)" routerLink="/account-setting/user-media-libraries" class="nav-link active">Media
                                        Libraries</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="px-4  flex-grow-1">
                    <div class="container mx-0 ms-1 pe-0 ps-3 business-media d-flex flex-column h-100"
                        id="container-view">
                        <div class="row flex-grow-1 g-0 overflow-auto">
                            <div class="col-md-12 pt-3">

                                <div class="row filter-post">
                                    <p class="text-lg text-gray-2 px-4 py-2 mb-0">User Media Library</p>
                                    <div class="col-md-12">
                                        <ul class="breadcrumb-tabs nav mx-2">
                                            <li class="nav-item" role="presentation">
                                                <a data-filter class="nav-link active">All</a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a data-filter="Videos" class="nav-link">Videos</a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a data-filter="Audio" class="nav-link">Audio</a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a data-filter="Images" class="nav-link">Images</a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a data-filter="Icons" class="nav-link">Icons</a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a data-filter="Snippets" class="nav-link">Text Snippets</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="container video-header mx-0 px-3">
                                    <ul class="row p-0">
                                        <li class="col-md-4 mb-2">
                                            <div class="formItm search-icon searchDropdown">
                                                <input id="search-feild" type="search" value=""
                                                    placeholder="Search your video" required="" data-filter>
                                                <label for="search-feild" class="text-sm">Search media</label>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M0 7.27242C0 3.25597 3.25597 0 7.27242 0C11.2889 0 14.5448 3.25597 14.5448 7.27242C14.5448 9.11134 13.8623 10.7908 12.7368 12.0714L15.8623 15.1991C16.046 15.3829 16.0459 15.6809 15.862 15.8646C15.6782 16.0484 15.3802 16.0483 15.1965 15.8644L12.0712 12.737C10.7906 13.8624 9.11123 14.5448 7.27242 14.5448C3.25597 14.5448 0 11.2889 0 7.27242ZM7.27242 0.941228C3.7758 0.941228 0.941228 3.7758 0.941228 7.27242C0.941228 10.769 3.7758 13.6036 7.27242 13.6036C10.769 13.6036 13.6036 10.769 13.6036 7.27242C13.6036 3.7758 10.769 0.941228 7.27242 0.941228Z"
                                                        fill="#A39FAA"></path>
                                                </svg>


                                            </div>
                                        </li>
                                        <li class="col-md-3 mt-3 mb-0 pt-1 px-0" id="selectpreff">
                                            <!-- <div class="formItm down_arrow me-0">

                                                <select>
                                                    <option>Last Modified</option>
                                                    <option>Select 1</option>
                                                    <option>Select 2</option>
                                                    <option>Select 3</option>
                                                </select>

                                            </div> -->

                                            <div class="dropdown dropdownBtn">
                                                <button class="dropdown-toggle text-md" type="button" data-bs-toggle="dropdown" aria-expanded="true">
                                                    <span class="dropdownBtnText">Last Modified</span>
                                                </button>
                                                <ul class="dropdown-menu dropdown-menu-end dropdownMenu" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(-130px, 41px, 0px);" data-popper-placement="bottom-end">
                                                    <li><a class="dropdown-item text-md" href="#;">Select 1</a>
                                                    </li>
                                                    <li><a class="dropdown-item text-md" href="#;">Select 2</a>
                                                    </li>
                                                    <li><a class="dropdown-item text-md" href="#;">Select 3</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>

                                        <li class="col-md-5 mb-0 text-end">
                                            <ul class="text-start d-block mt-3 pt-2 ps-0">
                                                <li class="pe-4 text-sm m-0">
                                                    <a href="#" class="text-gray-3"
                                                        data-sidebar="#multiselectmedia">Select Media</a>
                                                </li>
                                                <li class="m-0">
                                                    <button
                                                        class="btn weight-600 text-xs btn btn-primary btn-round-6 px-4 py-2"
                                                        data-bs-toggle="modal" data-bs-target="#uploadModal">
                                                        Add Media
                                                    </button>

                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>

                                <div class="row grid n-width m-0">

                                    <li class="active" data-tags="Videos" data-sidebar="#selectmedia">
                                        <div class="video-outer">
                                            <div class="video_wrapper video_wrapper_full js-videoWrapper">
                                                <figure class="video-baner"><img
                                                        src="../assets/images/business_Profile/ve1.png" alt="video1">
                                                </figure>
                                            </div>
                                        </div>
                                        <div class="custom-radiobtn">
                                            <label>
                                                <input type="checkbox" class="check form-check-input" checked="">
                                            </label>
                                        </div>
                                    </li>
                                    <li data-tags="Videos">
                                        <div class="video-outer">
                                            <div class="video_wrapper video_wrapper_full js-videoWrapper">

                                                <iframe class="videoIframe js-videoIframe"
                                                    src="https://www.youtube.com/embed/XULUBg_ZcAU"
                                                    title="" frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowfullscreen></iframe>
                                                <figure class="video-baner"><img src="../assets/images/myVideos/v2.png"
                                                        alt="video1">
                                                    <span class="video-duration">00.55</span>
                                                </figure>
                                                <button class="videoPoster js-videoPoster"></button>
                                            </div>
                                        </div>
                                        <div class="custom-radiobtn">
                                            <label>
                                                <input type="checkbox" class="check form-check-input">
                                            </label>
                                        </div>
                                    </li>
                                    <li data-tags="Audio">
                                        <div class="video-outer audio_outer">
                                            <div class="audio_wrapper">
                                                <a href="#">
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M3.625 3C3.625 1.68789 4.68789 0.625 6 0.625C7.31211 0.625 8.375 1.68789 8.375 3V5.75C8.375 7.06211 7.31211 8.125 6 8.125C4.68789 8.125 3.625 7.06211 3.625 5.75V3ZM6 1.375C5.10211 1.375 4.375 2.10211 4.375 3V5.75C4.375 6.64789 5.10211 7.375 6 7.375C6.89789 7.375 7.625 6.64789 7.625 5.75V3C7.625 2.10211 6.89789 1.375 6 1.375ZM2.17505 4.44995C2.38216 4.44995 2.55005 4.61784 2.55005 4.82495V5.67495C2.55005 7.57784 4.09716 9.12495 6.00005 9.12495C7.90294 9.12495 9.45005 7.57784 9.45005 5.67495V4.82495C9.45005 4.61784 9.61794 4.44995 9.82505 4.44995C10.0322 4.44995 10.2 4.61784 10.2 4.82495V5.67495C10.2 7.86571 8.51691 9.66831 6.375 9.85839V11C6.375 11.2071 6.20711 11.375 6 11.375C5.79289 11.375 5.625 11.2071 5.625 11V9.85838C3.48314 9.66825 1.80005 7.86567 1.80005 5.67495V4.82495C1.80005 4.61784 1.96794 4.44995 2.17505 4.44995ZM5.43413 3.56714C5.80078 3.4327 6.19929 3.4327 6.56594 3.56714C6.76039 3.63843 6.97581 3.5386 7.04711 3.34415C7.11841 3.14971 7.01858 2.93428 6.82413 2.86298C6.29078 2.66742 5.70929 2.66742 5.17594 2.86298C4.98149 2.93428 4.88166 3.14971 4.95296 3.34415C5.02425 3.5386 5.23968 3.63843 5.43413 3.56714ZM6.30917 4.63748C6.10695 4.58406 5.89795 4.58406 5.69572 4.63748C5.49548 4.69037 5.29027 4.57092 5.23738 4.37068C5.18449 4.17045 5.30394 3.96524 5.50417 3.91235C5.83194 3.82577 6.17295 3.82577 6.50072 3.91235C6.70096 3.96524 6.8204 4.17045 6.76751 4.37068C6.71462 4.57092 6.50941 4.69037 6.30917 4.63748Z"
                                                            fill="#FF8369" />
                                                    </svg>
                                                </a>
                                            <figure class="mb-0">
                                                    <img src="../assets/images/business_Profile/audio-line.png"
                                                        alt="audio-line">
                                                </figure>
                                            </div>
                                            <p class="text-xs m-0">Open your<br>mindfullness.MP3</p>
                                            <span class="audio-time text-gray-3 text-xxs">00.55</span>
                                        </div>
                                        <div class="custom-radiobtn">
                                            <label>
                                                <input type="checkbox" class="check form-check-input">
                                            </label>
                                        </div>
                                    </li>
                                    <li data-tags="Videos">
                                        <div class="video-outer">
                                            <div class="video_wrapper video_wrapper_full js-videoWrapper">

                                                <iframe class="videoIframe js-videoIframe"
                                                    src="https://www.youtube.com/embed/XULUBg_ZcAU"
                                                    title="" frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowfullscreen></iframe>
                                                <figure class="video-baner"><img src="../assets/images/myVideos/v3.png"
                                                        alt="video1">
                                                    <span class="video-duration">00.55</span>
                                                </figure>
                                                <button class="videoPoster js-videoPoster"></button>
                                            </div>

                                        </div>
                                        <div class="custom-radiobtn">
                                            <label>
                                                <input type="checkbox" class="check form-check-input">
                                            </label>
                                        </div>
                                    </li>
                                    <li data-tags="Audio">
                                        <div class="video-outer">
                                            <div class="video_wrapper video_wrapper_full js-videoWrapper">
                                                <figure class="video-baner"><img
                                                        src="../assets/images/business_Profile/ve2.png" alt="video1">
                                                </figure>
                                            </div>

                                        </div>
                                    </li>


                                    <li data-tags="Audio">
                                        <div class="video-outer audio_outer">
                                            <div class="audio_wrapper">
                                                <a href="#">
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M3.625 3C3.625 1.68789 4.68789 0.625 6 0.625C7.31211 0.625 8.375 1.68789 8.375 3V5.75C8.375 7.06211 7.31211 8.125 6 8.125C4.68789 8.125 3.625 7.06211 3.625 5.75V3ZM6 1.375C5.10211 1.375 4.375 2.10211 4.375 3V5.75C4.375 6.64789 5.10211 7.375 6 7.375C6.89789 7.375 7.625 6.64789 7.625 5.75V3C7.625 2.10211 6.89789 1.375 6 1.375ZM2.17505 4.44995C2.38216 4.44995 2.55005 4.61784 2.55005 4.82495V5.67495C2.55005 7.57784 4.09716 9.12495 6.00005 9.12495C7.90294 9.12495 9.45005 7.57784 9.45005 5.67495V4.82495C9.45005 4.61784 9.61794 4.44995 9.82505 4.44995C10.0322 4.44995 10.2 4.61784 10.2 4.82495V5.67495C10.2 7.86571 8.51691 9.66831 6.375 9.85839V11C6.375 11.2071 6.20711 11.375 6 11.375C5.79289 11.375 5.625 11.2071 5.625 11V9.85838C3.48314 9.66825 1.80005 7.86567 1.80005 5.67495V4.82495C1.80005 4.61784 1.96794 4.44995 2.17505 4.44995ZM5.43413 3.56714C5.80078 3.4327 6.19929 3.4327 6.56594 3.56714C6.76039 3.63843 6.97581 3.5386 7.04711 3.34415C7.11841 3.14971 7.01858 2.93428 6.82413 2.86298C6.29078 2.66742 5.70929 2.66742 5.17594 2.86298C4.98149 2.93428 4.88166 3.14971 4.95296 3.34415C5.02425 3.5386 5.23968 3.63843 5.43413 3.56714ZM6.30917 4.63748C6.10695 4.58406 5.89795 4.58406 5.69572 4.63748C5.49548 4.69037 5.29027 4.57092 5.23738 4.37068C5.18449 4.17045 5.30394 3.96524 5.50417 3.91235C5.83194 3.82577 6.17295 3.82577 6.50072 3.91235C6.70096 3.96524 6.8204 4.17045 6.76751 4.37068C6.71462 4.57092 6.50941 4.69037 6.30917 4.63748Z"
                                                            fill="#FF8369" />
                                                    </svg>
                                                </a>
                                            <figure class="mb-0">
                                                    <img src="../assets/images/business_Profile/audio-line.png"
                                                        alt="audio-line">
                                                </figure>
                                            </div>
                                            <p class="text-xs m-0">Open your<br>mindfullness.MP3</p>
                                            <span class="audio-time text-gray-3 text-xxs">00.55</span>
                                        </div>
                                    </li>

                                    <li data-tags="Icons">
                                        <div class="video-outer">
                                            <div class="video_wrapper video_wrapper_full js-videoWrapper">

                                                <iframe class="videoIframe js-videoIframe"
                                                    src="https://www.youtube.com/embed/XULUBg_ZcAU"
                                                    title="" frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowfullscreen></iframe>
                                                <figure class="video-baner"><img src="../assets/images/myVideos/v3.png"
                                                        alt="video1">
                                                    <span class="video-duration">00.55</span>
                                                </figure>
                                                <button class="videoPoster js-videoPoster"></button>
                                            </div>

                                        </div>
                                    </li>
                                    <li data-tags="Snippets">
                                        <div class="video-outer">
                                            <div class="video_wrapper video_wrapper_full js-videoWrapper">

                                                <iframe class="videoIframe js-videoIframe"
                                                    src="https://www.youtube.com/embed/XULUBg_ZcAU"
                                                    title="" frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowfullscreen></iframe>
                                                <figure class="video-baner"><img src="../assets/images/myVideos/v4.png"
                                                        alt="video1">
                                                    <span class="video-duration">00.55</span>
                                                </figure>
                                                <button class="videoPoster js-videoPoster"></button>
                                            </div>

                                        </div>
                                    </li>
                                    <li data-tags="Audio">
                                        <div class="video-outer audio_outer">
                                            <div class="audio_wrapper">
                                                <a href="#">
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M3.625 3C3.625 1.68789 4.68789 0.625 6 0.625C7.31211 0.625 8.375 1.68789 8.375 3V5.75C8.375 7.06211 7.31211 8.125 6 8.125C4.68789 8.125 3.625 7.06211 3.625 5.75V3ZM6 1.375C5.10211 1.375 4.375 2.10211 4.375 3V5.75C4.375 6.64789 5.10211 7.375 6 7.375C6.89789 7.375 7.625 6.64789 7.625 5.75V3C7.625 2.10211 6.89789 1.375 6 1.375ZM2.17505 4.44995C2.38216 4.44995 2.55005 4.61784 2.55005 4.82495V5.67495C2.55005 7.57784 4.09716 9.12495 6.00005 9.12495C7.90294 9.12495 9.45005 7.57784 9.45005 5.67495V4.82495C9.45005 4.61784 9.61794 4.44995 9.82505 4.44995C10.0322 4.44995 10.2 4.61784 10.2 4.82495V5.67495C10.2 7.86571 8.51691 9.66831 6.375 9.85839V11C6.375 11.2071 6.20711 11.375 6 11.375C5.79289 11.375 5.625 11.2071 5.625 11V9.85838C3.48314 9.66825 1.80005 7.86567 1.80005 5.67495V4.82495C1.80005 4.61784 1.96794 4.44995 2.17505 4.44995ZM5.43413 3.56714C5.80078 3.4327 6.19929 3.4327 6.56594 3.56714C6.76039 3.63843 6.97581 3.5386 7.04711 3.34415C7.11841 3.14971 7.01858 2.93428 6.82413 2.86298C6.29078 2.66742 5.70929 2.66742 5.17594 2.86298C4.98149 2.93428 4.88166 3.14971 4.95296 3.34415C5.02425 3.5386 5.23968 3.63843 5.43413 3.56714ZM6.30917 4.63748C6.10695 4.58406 5.89795 4.58406 5.69572 4.63748C5.49548 4.69037 5.29027 4.57092 5.23738 4.37068C5.18449 4.17045 5.30394 3.96524 5.50417 3.91235C5.83194 3.82577 6.17295 3.82577 6.50072 3.91235C6.70096 3.96524 6.8204 4.17045 6.76751 4.37068C6.71462 4.57092 6.50941 4.69037 6.30917 4.63748Z"
                                                            fill="#FF8369" />
                                                    </svg>
                                                </a>
                                            <figure class="mb-0">
                                                    <img src="../assets/images/business_Profile/audio-line.png"
                                                        alt="audio-line">
                                                </figure>
                                            </div>
                                            <p class="text-xs m-0">Open your<br>mindfullness.MP3</p>
                                            <span class="audio-time text-gray-3 text-xxs">00.55</span>
                                        </div>
                                    </li>
                                    <li data-tags="Audio">
                                        <div class="video-outer">
                                            <div class="video_wrapper video_wrapper_full js-videoWrapper">
                                                <figure class="video-baner"><img
                                                        src="../assets/images/business_Profile/ve3.png" alt="video1">
                                                </figure>
                                            </div>

                                        </div>
                                    </li>
                                    <li data-tags="Icons">
                                        <div class="video-outer">
                                            <div class="video_wrapper video_wrapper_full js-videoWrapper">

                                                <iframe class="videoIframe js-videoIframe"
                                                    src="https://www.youtube.com/embed/XULUBg_ZcAU"
                                                    title="" frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowfullscreen></iframe>
                                                <figure class="video-baner"><img src="../assets/images/myVideos/v3.png"
                                                        alt="video1">
                                                    <span class="video-duration">00.55</span>
                                                </figure>
                                                <button class="videoPoster js-videoPoster"></button>
                                            </div>

                                        </div>
                                    </li>
                                    <li data-tags="Snippets">
                                        <div class="video-outer">
                                            <div class="video_wrapper video_wrapper_full js-videoWrapper">

                                                <iframe class="videoIframe js-videoIframe"
                                                    src="https://www.youtube.com/embed/XULUBg_ZcAU"
                                                    title="" frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowfullscreen></iframe>
                                                <figure class="video-baner"><img src="../assets/images/myVideos/v4.png"
                                                        alt="video1">
                                                    <span class="video-duration">00.55</span>
                                                </figure>
                                                <button class="videoPoster js-videoPoster"></button>
                                            </div>

                                        </div>
                                    </li>
                                    <li data-tags="Images">
                                        <div class="video-outer">
                                            <div class="video_wrapper video_wrapper_full js-videoWrapper">
                                                <figure class="video-baner"><img
                                                        src="../assets/images/business_Profile/ve2.png" alt="video1">
                                                </figure>
                                            </div>

                                        </div>
                                    </li>
                                    <li data-tags="Audio">
                                        <div class="video-outer audio_outer">
                                            <div class="audio_wrapper">
                                                <a href="#">
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M3.625 3C3.625 1.68789 4.68789 0.625 6 0.625C7.31211 0.625 8.375 1.68789 8.375 3V5.75C8.375 7.06211 7.31211 8.125 6 8.125C4.68789 8.125 3.625 7.06211 3.625 5.75V3ZM6 1.375C5.10211 1.375 4.375 2.10211 4.375 3V5.75C4.375 6.64789 5.10211 7.375 6 7.375C6.89789 7.375 7.625 6.64789 7.625 5.75V3C7.625 2.10211 6.89789 1.375 6 1.375ZM2.17505 4.44995C2.38216 4.44995 2.55005 4.61784 2.55005 4.82495V5.67495C2.55005 7.57784 4.09716 9.12495 6.00005 9.12495C7.90294 9.12495 9.45005 7.57784 9.45005 5.67495V4.82495C9.45005 4.61784 9.61794 4.44995 9.82505 4.44995C10.0322 4.44995 10.2 4.61784 10.2 4.82495V5.67495C10.2 7.86571 8.51691 9.66831 6.375 9.85839V11C6.375 11.2071 6.20711 11.375 6 11.375C5.79289 11.375 5.625 11.2071 5.625 11V9.85838C3.48314 9.66825 1.80005 7.86567 1.80005 5.67495V4.82495C1.80005 4.61784 1.96794 4.44995 2.17505 4.44995ZM5.43413 3.56714C5.80078 3.4327 6.19929 3.4327 6.56594 3.56714C6.76039 3.63843 6.97581 3.5386 7.04711 3.34415C7.11841 3.14971 7.01858 2.93428 6.82413 2.86298C6.29078 2.66742 5.70929 2.66742 5.17594 2.86298C4.98149 2.93428 4.88166 3.14971 4.95296 3.34415C5.02425 3.5386 5.23968 3.63843 5.43413 3.56714ZM6.30917 4.63748C6.10695 4.58406 5.89795 4.58406 5.69572 4.63748C5.49548 4.69037 5.29027 4.57092 5.23738 4.37068C5.18449 4.17045 5.30394 3.96524 5.50417 3.91235C5.83194 3.82577 6.17295 3.82577 6.50072 3.91235C6.70096 3.96524 6.8204 4.17045 6.76751 4.37068C6.71462 4.57092 6.50941 4.69037 6.30917 4.63748Z"
                                                            fill="#FF8369" />
                                                    </svg>
                                                </a>
                                            <figure class="mb-0">
                                                    <img src="../assets/images/business_Profile/audio-line.png"
                                                        alt="audio-line">
                                                </figure>
                                            </div>
                                            <p class="text-xs m-0">Open your<br>mindfullness.MP3</p>
                                            <span class="audio-time text-gray-3 text-xxs">00.55</span>
                                        </div>
                                    </li>

                                    <li data-tags="Images">
                                        <div class="video-outer">
                                            <div class="video_wrapper video_wrapper_full js-videoWrapper">

                                                <iframe class="videoIframe js-videoIframe"
                                                    src="https://www.youtube.com/embed/XULUBg_ZcAU"
                                                    title="" frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowfullscreen></iframe>
                                                <figure class="video-baner"><img src="../assets/images/myVideos/v2.png"
                                                        alt="video1">
                                                    <span class="video-duration">00.55</span>
                                                </figure>
                                                <button class="videoPoster js-videoPoster"></button>
                                            </div>

                                        </div>
                                    </li>
                                    <li data-tags="Icons">
                                        <div class="video-outer">
                                            <div class="video_wrapper video_wrapper_full js-videoWrapper">

                                                <iframe class="videoIframe js-videoIframe"
                                                    src="https://www.youtube.com/embed/XULUBg_ZcAU"
                                                    title="" frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowfullscreen></iframe>
                                                <figure class="video-baner"><img src="../assets/images/myVideos/v3.png"
                                                        alt="video1">
                                                    <span class="video-duration">00.55</span>
                                                </figure>
                                                <button class="videoPoster js-videoPoster"></button>
                                            </div>

                                        </div>
                                    </li>
                                    <li data-tags="Snippets">
                                        <div class="video-outer">
                                            <div class="video_wrapper video_wrapper_full js-videoWrapper">

                                                <iframe class="videoIframe js-videoIframe"
                                                    src="https://www.youtube.com/embed/XULUBg_ZcAU"
                                                    title="" frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowfullscreen></iframe>
                                                <figure class="video-baner"><img src="../assets/images/myVideos/v4.png"
                                                        alt="video1">
                                                    <span class="video-duration">00.55</span>
                                                </figure>
                                                <button class="videoPoster js-videoPoster"></button>
                                            </div>

                                        </div>
                                    </li>

                                    <li data-tags="Icons">
                                        <div class="video-outer">
                                            <div class="video_wrapper video_wrapper_full js-videoWrapper">

                                                <iframe class="videoIframe js-videoIframe"
                                                    src="https://www.youtube.com/embed/XULUBg_ZcAU"
                                                    title="" frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowfullscreen></iframe>
                                                <figure class="video-baner"><img src="../assets/images/myVideos/v3.png"
                                                        alt="video1">
                                                    <span class="video-duration">00.55</span>
                                                </figure>
                                                <button class="videoPoster js-videoPoster"></button>
                                            </div>

                                        </div>
                                    </li>
                                </div>

                                <ul id="pagination">

                                    <li>
                                        <a href="#" class="preview-page">
                                            <svg width="6" height="10" viewBox="0 0 6 10" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M5.25063 0.63694C5.39531 0.491231 5.39531 0.25499 5.25063 0.109281C5.10596 -0.0364277 4.8714 -0.0364277 4.72673 0.109281L0.944888 3.91824C0.351705 4.51568 0.351703 5.48432 0.944887 6.08175L4.72673 9.89072C4.8714 10.0364 5.10596 10.0364 5.25064 9.89072C5.39531 9.74501 5.39531 9.50877 5.25064 9.36306L1.46879 5.5541C1.16495 5.24808 1.16495 4.75192 1.46879 4.4459L5.25063 0.63694Z"
                                                    fill="#7A7A7A" />
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="weight-600 text-gray-2" href="#">1</a>
                                    </li>
                                    <li>
                                        <a href="#">2</a>
                                    </li>
                                    <li>
                                        <a href="#">3</a>
                                    </li>
                                    <li>
                                        <a href="#" class="next-page">
                                            <svg width="6" height="10" viewBox="0 0 6 10" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M0.749366 0.63694C0.604694 0.491231 0.604694 0.25499 0.749366 0.109281C0.894037 -0.0364277 1.1286 -0.0364277 1.27327 0.109281L5.05511 3.91824C5.6483 4.51568 5.6483 5.48432 5.05511 6.08175L1.27327 9.89072C1.1286 10.0364 0.894036 10.0364 0.749364 9.89072C0.604692 9.74501 0.604693 9.50877 0.749364 9.36306L4.53121 5.5541C4.83505 5.24808 4.83505 4.75192 4.53121 4.4459L0.749366 0.63694Z"
                                                    fill="white" />
                                            </svg>
                                        </a>
                                    </li>

                                </ul>

                            </div>



                            <div class="d-none col-md-4 bg-gray-9 pt-3">

                                    <div class="video-header row mx-0 my-0">
                                        <div class="text-lg text-gray-2 col-lg-12 mb-0 px-0 d-flex justify-content-between">
                                            <span class="py-2 text-xl mb-0 d-inline-block">Media Library Access</span>


                                            <div id="selectpreff">
                                                <!-- <div class="formItm down_arrow me-0">

                                                    <select>
                                                        <option>All Libraries</option>
                                                        <option>Selected</option>
                                                        <option>Unselected</option>

                                                    </select>

                                                </div> -->
                                                <div class="dropdown dropdownBtn">
                                                    <button class="dropdown-toggle text-md" type="button" data-bs-toggle="dropdown" aria-expanded="true">
                                                        <span class="dropdownBtnText">All Libraries</span>
                                                    </button>
                                                    <ul class="dropdown-menu dropdown-menu-end dropdownMenu" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(-130px, 41px, 0px);" data-popper-placement="bottom-end">
                                                        <li><a class="dropdown-item text-md" href="#;">Selected</a>
                                                        </li>
                                                        <li><a class="dropdown-item text-md" href="#;">Unselected</a>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="p-0">
                                            <div class="formItm search-icon searchDropdown">
                                                <input id="search-feild-1" type="search" value="" placeholder="Search your video" required="" data-filter="">
                                                <label for="search-feild-1" class="text-sm">Search media</label>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.27242C0 3.25597 3.25597 0 7.27242 0C11.2889 0 14.5448 3.25597 14.5448 7.27242C14.5448 9.11134 13.8623 10.7908 12.7368 12.0714L15.8623 15.1991C16.046 15.3829 16.0459 15.6809 15.862 15.8646C15.6782 16.0484 15.3802 16.0483 15.1965 15.8644L12.0712 12.737C10.7906 13.8624 9.11123 14.5448 7.27242 14.5448C3.25597 14.5448 0 11.2889 0 7.27242ZM7.27242 0.941228C3.7758 0.941228 0.941228 3.7758 0.941228 7.27242C0.941228 10.769 3.7758 13.6036 7.27242 13.6036C10.769 13.6036 13.6036 10.769 13.6036 7.27242C13.6036 3.7758 10.769 0.941228 7.27242 0.941228Z" fill="#A39FAA"></path>
                                                </svg>


                                            </div>
                                        </div>

                                    </div>
                                    <ul class="library-access n-width p-0 m-0 mt-4">
                                        <div class="part1">
                                        <li>
                                            <div class="form-switch custom-slidetoggle">
                                                <input class="form-check-input" type="checkbox" checked><label
                                                    class="text-md">Prontopia</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-switch custom-slidetoggle">
                                                <input class="form-check-input" type="checkbox"><label
                                                    class="text-md">Lakme Fashion</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-switch custom-slidetoggle">
                                                <input class="form-check-input" type="checkbox"><label
                                                    class="text-md">Subway</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-switch custom-slidetoggle">
                                                <input class="form-check-input" type="checkbox"><label
                                                    class="text-md">Fortis</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-switch custom-slidetoggle">
                                                <input class="form-check-input" type="checkbox" checked><label
                                                    class="text-md">Brand A</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-switch custom-slidetoggle">
                                                <input class="form-check-input" type="checkbox"><label
                                                    class="text-md">Brand B</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-switch custom-slidetoggle">
                                                <input class="form-check-input" type="checkbox"><label
                                                    class="text-md">Brand C</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-switch custom-slidetoggle">
                                                <input class="form-check-input" type="checkbox"><label
                                                    class="text-md">Brand D</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-switch custom-slidetoggle">
                                                <input class="form-check-input" type="checkbox" checked><label
                                                    class="text-md">Brand A</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-switch custom-slidetoggle">
                                                <input class="form-check-input" type="checkbox"><label
                                                    class="text-md">Brand B</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-switch custom-slidetoggle">
                                                <input class="form-check-input" type="checkbox"><label
                                                    class="text-md">Brand C</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-switch custom-slidetoggle">
                                                <input class="form-check-input" type="checkbox"><label
                                                    class="text-md">Brand D</label>
                                            </div>
                                        </li>
                                    </div>
                                    <div class="part2">
                                        <li>
                                            <div class="form-switch custom-slidetoggle">
                                                <input class="form-check-input" type="checkbox"><label
                                                    class="text-md">Brand C</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-switch custom-slidetoggle">
                                                <input class="form-check-input" type="checkbox"><label
                                                    class="text-md">Brand D</label>
                                            </div>
                                        </li>
                                    </div>
                                    </ul>


                                    <a href="javascript:void(0)" class="vall text-md text-primary-4">View All</a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>