<div class="py-4 px-4 account-heading" [ngClass]="
                    search_term != ''
                      ? 'py-5'
                      : ''
                    ">
    <div class="row">
        <div class="col-md-6 d-flex align-items-center pl-4">
            <h3 class="weight-700 text-gray-2">My Videos</h3>
        </div>
        <div class="col-md-6">
            <div class="d-flex">
                <div class="formItm search-icon relative">
                    <div>
                        <input type="text" value="" [(ngModel)]="title" (keyup.enter)="SearchVideo()"
                            placeholder="search" required>
                        <label class="text-sm" style="cursor:default;">Search your videos</label>
                        <img (click)="SearchVideo()" src="../../../../assets/images/svg/search.svg">
                    </div>

                    <div class="searchtem" *ngIf="search_term != ''" (click)="clearSearchTerm()">
                        <div>{{ search_term }}</div>
                        <mat-icon class="link">close</mat-icon>
                    </div>

                </div>


                <a id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"
                    style=" min-width:222px"
                    class="weight-normal btn btn-primary btn-round-6 md-btn mt-4 d-inline-block">
                    <img src="../../../../assets/images/svg/create video.svg">
                    Create a New Video<img src="../../../../assets/images/divider-solid-svgrepo-com.svg">
                    <img class="btn-svg-icon ms-1 white" src="../../../../assets/images/svg/down arrow.svg">
                </a>


                <ul class="dropdown-menu custom-dropdown" aria-labelledby="navbarDropdown"
                    style="      width: 226px;  transform: translate(1212px, 88px);">
                    <li><a class="dropdown-item" href="javascript:void(0)"
                            (click)="PublishedVideosComponent_NavigateToCreateVideo()">Create a New Video</a></li>
                    <li *ngIf="this.onboardingManager.showSAPVideo"><a class="dropdown-item" href="javascript:void(0)"
                            (click)="createNewModifiedVideo()">Customize a Video</a></li>
                    <li
                        *ngIf="this.onboardingManager.profile.localization_enabled && this.onboardingManager.profile.plan == 'enterprise' ">
                        <a class="dropdown-item" [routerLink]="['/localise-existing-videos']">Localize Existing
                            Videos</a></li>
                </ul>

            </div>

        </div>
    </div>
</div>
<created-videos [numGridCol]="4" [sInputVideoStatus]="'published'" [title]="'published'"
    class=" py-4 px-3"></created-videos>