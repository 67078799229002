<div class="aspect-ratio-popup">
    <div class="loading-bar" *ngIf="processingStart">
        <div class="loadingio-spinner-dual-ball-uej92sp9fy8">
            <div class="ldio-h0lmsosj14d">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
    <div class="heading" *ngIf="!hideHeader">Adjust Aspect Ratio</div>
    <div class="sub-heading">Select from the following options</div>
    <div class="separator"></div>
    <div class="blocks" *ngIf="videoAspectRatios && videoAspectRatios.length > 0">
        <div class="item" *ngFor="let aspectRatio of videoAspectRatios">
            <img [ngClass]="{'selected':  aspectRatio.selected == true}" class="icon"
                [src]="(aspectRatio.selected) ? this.prontoSetting.icons.checked_green : this.prontoSetting.icons.unchecked" />

            <div (click)="selectAspectRatio(aspectRatio)"
                [ngClass]="{'selected':  aspectRatio.selected == true, 'ratio' : true}">
                <div class="block">
                    <div class="{{aspectRatio.class}} item"></div>
                    <div class="title">{{aspectRatio.ratio}}</div>
                </div>
            </div>
            <div class="title-num">{{aspectRatio.value}}</div>
        </div>

    </div>
    <div class="item p-2 no-aspect-msg" *ngIf="!videoAspectRatios || videoAspectRatios.length == 0">
        You have already generated videos for available sizes.
    </div>
    <div class="pt-1 pb-1 button-bar ">
        <button [disabled]="!videoAspectRatios || videoAspectRatios.length == 0" class="btn-colored btn-secondary" (click)="initiateResizeProcess()"
        >Create</button>
        <button class="btn-colored btn-default" (click)="close()">Cancel</button>
    </div>
</div>