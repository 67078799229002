import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Renderer2, Inject } from '@angular/core';

@Component({
    selector: 'app-webplayer',
    templateUrl: './webplayer.component.html',
    styleUrls: ['./webplayer.component.scss'],
})
export class WebplayerComponent implements OnInit {
    constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2) {}
    runScript = false;
    showPlayer = false;

    ngOnInit(): void {
      console.log(" Call init method ./..");
      window['webPlayerLoad'] = (eventName, args) => {
        console.log('webPlayerLoad : ' + eventName + ' : ' + args);
      }

      window['glVideoPlayerInstance'] = {
        onLoad: window['webPlayerLoad'],
        isPlaying: 0,
        isMute: 0,
        meta: { // Meta data will be set when onLoad is called
            url: '',
            width: 0,
            height: 0,
            length: 0, // Total seconds
            frameCount: 0, // Total video frames
            frameRate: 0
        }
    };
      window['fireAngularEvent'] = (eventName, args) => {
        console.log('fireAngularEvent : ' + eventName + ' : ' + args);
      }

      console.log( "Call JS mehtod ", window['fireAngularEvent']());
      this.loadJsScript();
    }

    loadJsScript() {
      if(!this.isScriptLoaded("/assets/webplayer/TemplateData/UnityProgress.js")){
        const script = this.renderer.createElement('script');
        script.type = 'text/javascript';
        script.src = "/assets/webplayer/TemplateData/UnityProgress.js";
        this.renderer.appendChild(this.document.body, script);
      }
      if(!this.isScriptLoaded("/assets/webplayer/Build/UnityLoader.js")){
        const script = this.renderer.createElement('script');
        script.type = 'text/javascript';
        script.src = '/assets/webplayer/Build/UnityLoader.js';
        this.renderer.appendChild(this.document.body, script);
      }
      let __this = this;
      setTimeout( () =>{
        __this.runScript = true;
        console.log('Run script ..');
        __this.document
        const script = this.renderer.createElement('script');
        script.text = `
            var gameInstance = UnityLoader.instantiate("gameContainer", "/assets/webplayer/Build/Build.json", {onProgress: UnityProgress});
        `;
        this.renderer.appendChild(this.document.body, script);
      }, 2000);

        // const script = renderer.createElement('script');
        // script.type = 'text/javascript';
        // script.src = src;
        // renderer.appendChild(this.document.body, script);
        // return script;
    }

    isScriptLoaded(src) {
        return Boolean(document.querySelector('script[src="' + src + '"]'));
    }
}
