import { VideoDataStore } from './../../stores/video.store';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModifyVideoManagerService } from 'src/app/Managers/modify-video-manager.service';
import { VideoManagerService } from 'src/app/Managers/video-manager.service';
import { LoaderService } from 'src/app/services/loader.service';
import { cSessionService } from 'src/app/services/session.service';
import { CreateVideoManagerService } from '../..//Managers/create-video-manager.service';





@Component({
  selector: 'app-create-video-breadcrumb',
  templateUrl: './create-video-breadcrumb.component.html',
  styleUrls: ['./create-video-breadcrumb.component.scss']
})
export class CreateVideoBreadcrumbComponent implements OnInit {
 showMenu : string;
  sGetVideoId: string;
  public bDisplayTimelineBreadcrumb:boolean = false;
  @Input() enableMenu : boolean=false;
  @Input() localiseExistingVideos: boolean=false;
  @Input() modifyVideo : boolean=false;
  @Input() hideOtherSettingsTab : boolean=false;

  sSessionId: string;
  sUserEmail: string;
  sClassText = 'disabled-breadcrumb';
  modifyVideoManagerr: ModifyVideoManagerService
  constructor(private oRouter : Router , public videoManger:CreateVideoManagerService,
    public modifyVideoManager: ModifyVideoManagerService,
    public videoManager: VideoManagerService,
    public loader: LoaderService,
    public createVideoManger: CreateVideoManagerService,
    public videoDataStore: VideoDataStore,
    public oSessionService: cSessionService, ) {
    this.showMenu = this.oRouter.url
  }
  ngOnInit() {
    // this.modifyVideoManager.isModifyVideo = false;
    // if(!this.modifyVideoManager.isPreview ){
    //   this.modifyVideoManager.isPreview = false;
    // }
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();

  }
  modifyEditVideo(){
    this.modifyVideoManager.isPreview = false;
    this.modifyVideoManager.isModifyVideo = true;
    this.oRouter.navigateByUrl("/mv/timeline-view");
  }
  loadVideoDataAndRedirect() {
    // console.log("load data and navigate to next screen");
    this.loader.showLoader();
    this.createVideoManger.selectedVideo = this.videoManager.selectedVideo;
    this.createVideoManger.intiateVideoDataLoading(
      this.sUserEmail,
      this.sSessionId,
      this.videoManager.selectedVideo
    ).then((res) => {
      this.loader.hideLoader();
      // console.log( "**** rsponse of Promise ", res);
      // console.log("vid edit",  this.videoManager.selectedVideo);
      if ( this.videoManager.selectedVideo.sap_video_id &&  this.videoManager.selectedVideo.sap_video_id != "") {
        this.oRouter.navigate(["mv/timeline-view"]);
      } else {
        this.oRouter.navigate(["wizard/timeline-view"]);
      }

    }).catch( (e) =>{
      this.loader.hideLoader();
      if ( this.videoManager.selectedVideo.sap_video_id &&  this.videoManager.selectedVideo.sap_video_id != "") {
        this.oRouter.navigate(["mv/timeline-view"]);
      } else {
        this.oRouter.navigate(["wizard/timeline-view"]);
      }
    })


  }

  submitForm(generateVideo = false, heading = ""){
    let respStr = "noGenerate";

    if(heading == 'contentforcedsave'){
      respStr = "contentforcedsave";
    }
    if(heading == 'looknfeel'){
      respStr = "looknfeel";
    }
    this.videoManger.submitForm.emit(respStr);
  }

}
