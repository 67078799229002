<div class="voice-box p-2 mb-3">
    <div class="d-flex avatar-title">
        <img [src]="voice.avatar_url ? voice.avatar_url : pSetting.icons.avatar " class="avatar-user"
            [ngClass]="{'active': (voice.play || voice.selected)}" />
        <div class="title-details">
            <h2 class="mb-0">{{ voice.speaker_name }}</h2>
            <p class="m-0">
                <span *ngFor="let styleVoice of voice.speech_styles.slice(0,1); let i = index ">
                </span>
            </p>
        </div>
    </div>
    <div class="d-flex">
        <div class="audio-loader" *ngIf="showPlayer" style="position: relative">
            <!-- <ngx-circular-player  [source]="voice.speech_styles[0].sample_voice"></ngx-circular-player> -->
            <div class="" style="position: absolute; width : 37px; height: 35px; right: 5px; top: 10px;">
                <audio class="hide-it audio-player-{{voice._id.$oid}}" id="audio-player-{{voice._id.$oid}}"
                    controls="false">
                    <source [src]="voice.speech_styles[0].sample_voice" type="audio/mpeg">
                </audio>
                <div class="progress-bar-audio"
                    [style.background]="'radial-gradient(closest-side, white 75%, transparent 80% 100%),    conic-gradient(#FF8369 '+(((voice.current_time ? voice.current_time : 0)/(voice.duration ? voice.duration : 1)) * 100)+'%, #F2F2F5 0)'">

                    <i class="play-voice-icon p-icon sm link ms-1" *ngIf="!voice.play"
                        (click)="playAudio(voice._id.$oid, voice)"></i>
                    <i class="pause-voice-icon p-icon sm link ms" *ngIf="voice.play"
                        (click)="playAudio(voice._id.$oid, voice)"></i>
                </div>
            </div>
        </div>
        <div class="favourite-select">
            <img class="link" (click)="voiceSelectionConfirmationBox(voice)"
                [matTooltip]="voice.selected== true? '' :'Select this Avatar'" matTooltipClass="custom-tooltip"
                matTooltipPosition="left" height="15px"
                [src]="voice.selected== true? pSetting.icons.checked_green : pSetting.icons.unselected" />
            <img class="link" (click)="addToFavorite(voice)"
                [matTooltip]="voice.favorite== true? 'Remove from my Favorites' : 'Add to my Favorites'"
                matTooltipClass="custom-tooltip" matTooltipPosition="left" height="12px"
                [src]="voice.favorite == true? pSetting.icons.favorite_on : pSetting.icons.favorite_off" />
        </div>
    </div>
</div>