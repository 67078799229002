import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Event, Params, Router } from '@angular/router';
import { Config } from 'protractor';
import { Subscription } from 'rxjs';
import { ConfigManagerService } from './../../Managers/config-manager.service';
import { profile } from './../../models/onboarding';
import { ErrorService } from './../../services/error.service';
import { cSessionService } from './../../services/session.service';
import { UtilsService } from './../../services/utils.service';

import { FtCreateVideoManagerService } from './../../Managers/ft-create-video-manager.service';

import { OnboardingManagerService } from './../../Managers/onboarding-manager.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
    selector: 'app-ft-create-video-wizard-view',
    templateUrl: './ft-create-video-wizard-view.component.html',
    styleUrls: ['./ft-create-video-wizard-view.component.scss'],
})
export class FtCreateVideoWizardViewComponent implements OnInit, OnDestroy {
    showNext: boolean = false;
    showPrevious: boolean = false;
    sUserEmail = '';
    sSessionId: any;
    showCancel: boolean = false;
    showPreview: boolean = false;
    showSave: boolean = false;
    breadcrumActive: boolean = false;
    ftLogin: boolean = false;
    saveButtonTitle: string = 'Generate';
    guestEmail = "";
    guestSession = "";
    constructor(
        public videoManger: FtCreateVideoManagerService,
        private oSessionService: cSessionService,
        public router: Router,
        public onboardinManger: OnboardingManagerService,
        private location: Location,
        private config: ConfigManagerService,
        public errorService: ErrorService,
        private Utils_Service: UtilsService,
        private activatedRoute: ActivatedRoute,
        private loader : LoaderService
    ) {
        this.oSessionService.cSessionService_SetGuestSession('');
        this.oSessionService.cSessionService_SetGuestEmail('');
        this.oSessionService.cSessionService_SetUserEmail('');
    }

    sub: Subscription;

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe((params: Params) => {
            console.log('URL param', params);
            this.sUserEmail = params['email'];
            console.log('userEmail', this.sUserEmail);
            if (this.sUserEmail != '' && this.router.url.includes('/ft/overview')) {
                this.getStarted();
            } else {
                this.sUserEmail = this.oSessionService.cSessionService_GetGuestEmail();

                if (this.sUserEmail === '') {
                    this.router.navigate(['ft/login']);
                }
            }
        });
        this.UpdateMenu();
        this.sub = this.router.events.subscribe((event: Event) => {
            this.UpdateMenu();
        });
    }

    getStarted() {
        this.videoManger
            .guestUser(this.sUserEmail)
            .then((res) => {
                if (res['errorMessage']) {
                    if (res['errorMessage'] && res['errorMessage'].indexOf('already exists') > -1) {
                        this.errorService.handleError(
                            'This email is already associated with an account. Please sign in with your password or use a different email to continue.',
                            'Email Already in Use',
                            true,
                        );
                    } else {
                        this.errorService.handleError(res['errorMessage'], 'Error', true);
                    }
                    // this.errorService.handleError(res['errorMessage'], 'Error', true);
                    this.router.navigate(['ft/login']);
                } else {
                    this.sSessionId = res['guest_session_id'];

                    this.oSessionService.cSessionService_SetGuestEmail(this.sUserEmail);
                    this.oSessionService.cSessionService_SetGuestSession(this.sSessionId);

                    this.oSessionService.guestEmail = this.sUserEmail;

                    if (this.sSessionId !== '' && this.sUserEmail !== '') {


                        if (!this.onboardinManger.profile) {


                            this.getProfile(this.sUserEmail, this.sSessionId);
                        }
                    }
                    if (this.config.Templates.length === 0) {

                        this.loader.showLoader();
                        console.log(" check Allowed templatge *******", this.onboardinManger?.profile?.allowed_templates)
                        this.config.getTemplates2(this.sUserEmail, this.sSessionId, this.onboardinManger?.profile?.allowed_templates ? this.onboardinManger.profile.allowed_templates : []);
                    }
                }
            })
            .catch((err) => {
                console.log('error here ', err);
            });
    }
    ngOnDestroy(): void {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    async getProfile(email, session) {
        // console.log("onbtop");
        this.config.getVoices(this.sUserEmail, this.sSessionId).then();

        await this.onboardinManger.getUserProfile(email, session).then(async (res: profile) => {
            console.log("user profile", res?.allowed_templates);
            // TODO - have to remove this adter testing
            // await this.config.getTemplates(email, session, res.allowed_templates);
            await this.config.getTemplates2(this.sUserEmail, this.sSessionId, res.allowed_templates);
        });

        await this.onboardinManger.getUserVideosCount(email, session).then((count) => {});

        // console.log('Started4');
    }
    private UpdateMenu() {
        // console.log("isrouter" , this.router.isActive('/ft/overview', false));
       this.guestSession = this.oSessionService.cSessionService_GetGuestSession();
       this.guestEmail = this.oSessionService.cSessionService_GetGuestEmail();
        // console.log(this.router.url);

        if (this.router.url.includes('/ft/login')) {
            this.breadcrumActive = false;
            this.showSave = false;
            this.showCancel = false;
            this.showPrevious = false;
            this.videoManger.isEditMode = false;
            if (!this.videoManger.isEditMode) {
                this.ftLogin = true;
                this.showNext = false;
                this.showCancel = false;
            }
        }
        if (this.router.url.includes('/ft/overview')) {
            this.breadcrumActive = true;
            this.ftLogin = false;
            if (!this.videoManger.isEditMode) {
                this.showNext = true;
                this.showCancel = true;
                this.showPreview = false;
                this.showPrevious = false;
                this.ftLogin = false;
            } else {
                this.showNext = false;
                this.showCancel = true;
                this.showPreview = false;
                this.showPrevious = false;
                this.showSave = true;
                this.ftLogin = false;
            }
            this.saveButtonTitle = 'Generate';
        } else if (this.router.url.includes('/ft/looknfeel')) {
            this.breadcrumActive = true;
            this.ftLogin = false;
            this.saveButtonTitle = 'Generate';
            if (!this.videoManger.isEditMode) {
                this.showNext = true;
                this.showCancel = false;
                this.showPreview = false;
                this.showPrevious = true;
                this.ftLogin = false;
            } else {
                this.showNext = false;
                this.showCancel = true;
                this.showPreview = false;
                this.showPrevious = false;
                this.showSave = true;
                this.ftLogin = false;
            }
        } else if (this.router.url.includes('/ft/content')) {
            this.breadcrumActive = true;
            this.ftLogin = false;
            this.saveButtonTitle = 'Generate';
            if (!this.videoManger.isEditMode) {
                this.showNext = true;
                this.showCancel = false;
                this.showPreview = false;
                this.showPrevious = true;
                this.ftLogin = false;
            } else {
                this.showNext = false;
                this.showCancel = true;
                this.showPreview = false;
                this.showPrevious = false;
                this.showSave = true;
                this.ftLogin = false;
            }
        } else if (this.router.url.includes('/ft/other-setting')) {
            this.breadcrumActive = true;
            this.ftLogin = false;
            this.saveButtonTitle = 'Generate';
            if (!this.videoManger.isEditMode) {
                this.showNext = false;
                this.showCancel = false;
                this.showPreview = true;
                this.showPrevious = true;
                this.ftLogin = false;
            } else {
                this.showNext = false;
                this.showCancel = true;
                this.showPreview = false;
                this.showPrevious = false;
                this.showSave = true;
                this.ftLogin = false;
            }
        } else if (this.router.url.includes('/ft/preview')) {
            this.breadcrumActive = false;
            this.ftLogin = false;
            if (!this.videoManger.isEditMode) {
                this.showNext = false;
                this.showCancel = false;
                this.showPreview = false;
                this.showPrevious = false;
                this.showSave = false;
                this.saveButtonTitle = 'Back';
                this.ftLogin = false;
            } else {
                this.showNext = false;
                this.showCancel = false;
                this.showPreview = false;
                this.showPrevious = false;
                this.showSave = true;
                this.saveButtonTitle = 'Back';
                this.ftLogin = false;
            }
        }
    }
    back() {
        this.location.back();
    }
    next() {
        this.videoManger.submitForm.emit();
        this.UpdateMenu();
    }

    save() {
        this.videoManger.submitForm.emit('save');
        this.UpdateMenu();
    }

    previous() {
        this.videoManger.previousSubmit.emit();
        this.UpdateMenu();
    }
    cancel() {
        console.log('cancel');
        this.UpdateMenu();
        this.router.navigate(['/login']).then(() => {
            window.location.reload();
        });
    }

    downloadVideo() {
        console.log('email ', this.oSessionService.cSessionService_GetGuestEmail());
        console.log('session ', this.oSessionService.cSessionService_GetGuestSession());
        console.log(this.videoManger.selectedVideo);
        console.log(this.videoManger.selectedVideo.fpath);
        this.oSessionService.cSessionService_SetVideoId(this.videoManger.selectedVideo.fpath);
    }
}
