import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { cDataService } from './../../../services/data.service';
import { cEditVideoService } from './../../../services/edit-video.service';
import { cSessionService } from './../../../services/session.service';
import { cTemplateConfigService } from './../../../services/template.service';
import { cWebSocketService } from './../../../services/websocket.service';

@Component({
  selector: 'app-ftue-content',
  templateUrl: './ftue-content.component.html',
  styleUrls: ['./ftue-content.component.scss']
})
export class FtueContentComponent implements OnInit, OnDestroy {
  // video content
  @ViewChild('closeModal') closeModal: ElementRef;
  bLetProntoChoose : boolean = true;
  bDisplayMyMediaSection : boolean = false;
  bDisplayUploadSection : boolean = false;
  bUploadVoiceOverFile : boolean;
  lFilestoUpload : any = [];
  lUploadedFiles : any = [];
  lFailedToUpload : any = [];
  nFileToUploadIndex : number = 0;
  loading: boolean = false;
  spinner: string;
  oUploadedVoiceOverFile : any;
  sUploadedVoiceOverFileName : string = "No file choosen."
  nVoiceOverFileSize : number = 0;
  bDisplayFailedToUploadSection : boolean = false;
  fContentForm: FormGroup;
  sSelectedVoiceGender : string;
  sUploadedVoiceOverPlayableFile = new Audio();
  sSessionId: any;
  sUserEmail: any;
  sVideoId : any;
  bShowTextToSpeechSection: boolean = true;
  oUserVoiceOverList: any = [];
  oVoiceOverList : any = [];
  sVoiceName : string;
  oAudio = new Audio();
  nSelectedVoiceIndex : number;
  sDisplayResponseMessage : string;
  bDisplayErrorMessage : boolean = false;
  bSuccessMessage : boolean = false;
  oSavedFormData : any;
  lBlockConfigurations : any = [];
  sResetTextOverLays : string;
  sSelectedBlockID : string;
  lTextList : any = [];
  bCreateVideoMode : boolean = true;
  lUserSelectedVisuals : any = [];
  oInputVisualToRemove : any;
  lImagesList : any = [];
  lClipsList : any = [];
  sDisableEditModeToggle : string = "false";
  uploadModalHeaderText: string = "Upload Media";
  sSelectedTab : string = "video content";
  oHighlightedTabs : any;
  constructor
  (
    private oDataService : cDataService,
    private oWebSocketService : cWebSocketService,
    private oTemplateConfigService : cTemplateConfigService,
    private oSessionService: cSessionService,
    private oFormBuilder : FormBuilder,
    private oRouter : Router,
    private oEditVideoService : cEditVideoService
  )
  {
    let bGenerateVideo = this.oSessionService.cSessionService_GetGeneratedVideoCheck();
    if(bGenerateVideo)
    {
      this.bCreateVideoMode=false;
    }
    else
    {
      this.bCreateVideoMode=true;
    }
  }
  ngOnInit()
  {
    this.oWebSocketService.WebSocket_CreateWebSocket();
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    this.sVideoId = this.oSessionService.SessionService_GetVideoId();
    this.sResetTextOverLays = this.oSessionService.SessionService_GetResetTextOverlayCheck();
    this.oUserVoiceOverList = this.oSessionService.cSessionService_GetUserVoiceOverList();
    this.oHighlightedTabs = this.oSessionService.cSessionService_GetHighlightTabs();
    console.log("ContentComponent : User voice list ==> ", this.oUserVoiceOverList);
    //look for already visited tabs
    this.oHighlightedTabs = this.oSessionService.cSessionService_GetHighlightTabs();
    if(!this.oHighlightedTabs)
    {
      this.oSessionService.cSessionService_ResetHighlightedTabs();
      this.oHighlightedTabs = this.oSessionService.cSessionService_GetHighlightTabs();
    }
    this.ContentComponent_HighlightTabs();
    this.oSavedFormData = this.oSessionService.cSessionService_GetContentForm();

    if(this.oSavedFormData)
    {
      this.fContentForm = this.oFormBuilder.group(
      {
        session_id: this.oSavedFormData.session_id,
        email: this.oSavedFormData.email,
        vo_text : [this.oSavedFormData.vo_text],
        selected_voice : [this.oSavedFormData.selected_voice],
        pronto_clips : [this.oSavedFormData.pronto_clips],
        oUploadedVoiceOverFile : [this.oSavedFormData.oUploadedVoiceOverFile],
        nVoiceOverFileSize : [this.oSavedFormData.nVoiceOverFileSize],
        sGender : [this.oSavedFormData.sGender],
        lUploadedFiles : [this.oSavedFormData.lUploadedFiles],
        lUserSelectedVisuals : [this.oSavedFormData.lUserSelectedVisuals],
        bShowTextToSpeechToggle : this.oSavedFormData.bShowTextToSpeechToggle,
        bLetProntoChooseToggle : this.oSavedFormData.bLetProntoChooseToggle,
        bMyMediaLibraryToggle : this.oSavedFormData.bMyMediaLibraryToggle,
        bUploadSectionToggle : this.oSavedFormData.bUploadSectionToggle,
        lBlockConfig : [this.oSavedFormData.lBlockConfig],
        music_file : [this.oSavedFormData.music_file],
        cta : [this.oSavedFormData.cta]
      });
        this.bLetProntoChoose = this.oSavedFormData.bLetProntoChooseToggle;
        this.bDisplayMyMediaSection = this.oSavedFormData.bMyMediaLibraryToggle;
        this.bDisplayUploadSection = this.oSavedFormData.bUploadSectionToggle;
        this.bShowTextToSpeechSection = this.oSavedFormData.bShowTextToSpeechToggle;
        this.nVoiceOverFileSize = this.oSavedFormData.nVoiceOverFileSize;
        this.lUploadedFiles = this.oSavedFormData.lUploadedFiles;
        this.lUserSelectedVisuals = this.oSavedFormData.lUserSelectedVisuals;
        this.lBlockConfigurations=this.oSavedFormData.lBlockConfig;
        console.log("ContentComponent : BLOCK CONFIG ==> ",this.lBlockConfigurations);
        if(this.oSavedFormData.oUploadedVoiceOverFile)
        {
          this.sUploadedVoiceOverFileName = this.oSavedFormData.oUploadedVoiceOverFile.filename;
        }
        else
        {
          this.ContentComponent_SelectVoiceGender(this.oSavedFormData.sGender,'edit');
        }
    }
    else
    {
      this.fContentForm = this.oFormBuilder.group(
      {
        session_id: this.sSessionId,
        email: this.sUserEmail,
        vo_text : [''],
        selected_voice : [''],
        pronto_clips : [this.bLetProntoChoose],
        sGender : ['male'],
        oUploadedVoiceOverFile : [this.oUploadedVoiceOverFile],
        nVoiceOverFileSize : [0],
        lUploadedFiles : [this.lUploadedFiles],
        lUserSelectedVisuals : [this.lUserSelectedVisuals],
        bShowTextToSpeechToggle : this.bShowTextToSpeechSection,
        bLetProntoChooseToggle : this.bLetProntoChoose,
        bMyMediaLibraryToggle : this.bDisplayMyMediaSection,
        bUploadSectionToggle : this.bDisplayUploadSection,
        lBlockConfig : [[]],
        music_file : [''],
        cta : ['']
      });
      //by default male voice will be selected
      this.ContentComponent_SelectVoiceGender('male','create');
      const nSelectedDuraction = this.oSessionService.cSessionService_GetOverviewForm().duration;
      const sSelectedTemplateCategory = this.oSessionService.cSessionService_GetLooknFeelForm().template_name;
      this.lBlockConfigurations=this.oTemplateConfigService.TemplateConfigService_FetchTemplateBlocks(parseInt(nSelectedDuraction),sSelectedTemplateCategory);
      console.log("ContentComponent : BLOCK CONFIG ==> ",this.lBlockConfigurations);
      this.fContentForm.patchValue({lBlockConfig : this.lBlockConfigurations});
    }
    this.ContentComponent_InitializeJqueryCode();
    this.sDisableEditModeToggle = this.oSessionService.SessionService_GetResetTextOverlayCheck();


  }
  ContentComponent_HighlightTabs()
  {
    if(this.oHighlightedTabs.overlays)
    {
      this.ContentComponent_CheckTextOverlaysTab();
    }
    if(this.oHighlightedTabs.voiceover)
    {
      this.ContentComponent_CheckVoiceOverTextTab();
    }
    if(this.oHighlightedTabs.content)
    {
      this.ContentComponent_CheckVideoContentTab()
    }
  }
  FTUEContentComponent_NavigateToLogin()
  {
    this.oSessionService.cSessionService_ResetHighlightedTabs();
    localStorage.clear();
    this.oRouter.navigateByUrl('/ftue-login');
  }
  ContentComponent_CheckVideoContentTab()
  {
    this.sSelectedTab = "video content";
    let selectedTab = document.getElementById('Video-Content-tab');
    if(selectedTab.classList.contains('active'))
    {
      this.uploadModalHeaderText = "Upload Media";
    }
    else
    {
      this.uploadModalHeaderText = "Upload an Audio File"
    }
    this.oHighlightedTabs.content = true;
    this.oSessionService.cSessionService_SetHighlightTabs(this.oHighlightedTabs);
  }
  ContentComponent_CheckTextOverlaysTab()
  {
    this.sSelectedTab = "text overlays";
    this.oHighlightedTabs.overlays = true;
    this.oSessionService.cSessionService_SetHighlightTabs(this.oHighlightedTabs);
  }
  ContentComponent_CheckVoiceOverTextTab()
  {
    this.sSelectedTab = "voiceover text";
    let selectedTab = document.getElementById('Video-Content-tab');
    if(selectedTab.classList.contains('active'))
    {
      this.uploadModalHeaderText = "Upload Media";
    }
    else
    {
      this.uploadModalHeaderText = "Upload an Audio File"
    }
    this.oHighlightedTabs.voiceover = true;
    this.oSessionService.cSessionService_SetHighlightTabs(this.oHighlightedTabs);
  }
  ngOnDestroy()
  {
    this.oWebSocketService.WebSocket_Destroy();
  }

  ///////////////////////////// Create video request /////////////////////////////////
  ///////////////////////////// Create video request /////////////////////////////////
  ///////////////////////////// Create video request /////////////////////////////////
  ///////////////////////////// Create video request /////////////////////////////////
  CreateVideoContentComponent_SendContentFormRequest()
  {
    this.oHighlightedTabs = this.oSessionService.cSessionService_GetHighlightTabs();
    //send request if all tabs are visited
    if(this.oHighlightedTabs.overlays && this.oHighlightedTabs.voiceover)
    {
      this.ContentComponent_UpdateTextList();
      let oCreateVideoPayload =
      {
        session_id:  this.sSessionId,
        email: this.sUserEmail,
        video_id : this.sVideoId,
        user_images : [],
        user_clips : [],
        pronto_clips : this.bLetProntoChoose,
        text_list: this.lTextList ?  this.lTextList : [""],
        vo_text :'',
        selected_voice : '',
        voice_file : ''
      }
      this.lImagesList=[];
      this.lClipsList=[];
      //Extract user images and clips from uploaded images
      if(this.bDisplayUploadSection)
      {
        for (let nVisualIndex = 0; nVisualIndex < this.lUploadedFiles.length; nVisualIndex++)
        {
          const element = this.lUploadedFiles[nVisualIndex];
          const extension =  element.filename.split('.').pop();
          if(extension === "png" || extension === "jpg" || extension === "jpeg")
          {
            this.lImagesList.push(element.fpath);
          }
          else
          {
            this.lClipsList.push(element.fpath);
          }
        }
      }
      //Extract images and clips from user media
      if(this.bDisplayMyMediaSection)
      {
        for (let nVisualIndex = 0; nVisualIndex < this.lUserSelectedVisuals.length; nVisualIndex++)
        {
          const element = this.lUserSelectedVisuals[nVisualIndex];
          const extension =  element.name.split('.').pop();
          if(extension === "png" || extension === "jpg" || extension === "jpeg")
          {
            this.lImagesList.push(element.visual_path);
          }
          else
          {
            this.lClipsList.push(element.visual_path);
          }
        }
      }
      oCreateVideoPayload.user_clips = this.lClipsList;
      oCreateVideoPayload.user_images = this.lImagesList;
      //if user enters voice over text
      if(this.bShowTextToSpeechSection)
      {
        oCreateVideoPayload.vo_text = this.fContentForm.get('vo_text').value;
        oCreateVideoPayload.selected_voice = this.fContentForm.get('selected_voice').value;
        delete oCreateVideoPayload.voice_file;
      }
      //if user uploads voice over file
      else
      {
        const oVoiceOverFile = this.fContentForm.get('oUploadedVoiceOverFile').value;
        oCreateVideoPayload.voice_file = oVoiceOverFile.fpath;
        delete oCreateVideoPayload.vo_text;
        delete oCreateVideoPayload.selected_voice;
      }
      console.log("Payload ==> ", oCreateVideoPayload);
      this.oWebSocketService.WebSocket_RegisterEvent("user_video_result", this.CreateVideoContentComponent_HandleContentResponse);
      this.oWebSocketService.WebSocket_SendMessage("user_video_request", JSON.stringify(oCreateVideoPayload));
    }
    // else visit remaining tabs
    else
    {
      if(this.oHighlightedTabs.overlays)
      {
        this.sSelectedTab = "voiceover text";
        this.ContentComponent_CheckVoiceOverTextTab();
      }
      else
      {
        this.sSelectedTab = "text overlays";
        this.ContentComponent_CheckTextOverlaysTab();
      }
    }
  }
  CreateVideoContentComponent_HandleContentResponse = (InComingMessage) =>
  {
    if (JSON.parse(InComingMessage)["errorMessage"])
    {
      this.sDisplayResponseMessage = JSON.parse(InComingMessage)['errorMessage'];
      console.log("CreateVideoContentComponent_HandleContentResponse : Request Error ==>",InComingMessage)
      this.bDisplayErrorMessage = true;
      setTimeout(()=>{this.bDisplayErrorMessage = false;},5000);
    }
    else
    {
      const oResponse = JSON.parse(InComingMessage);
      this.oSessionService.cSessionService_SetResetTextOverlayCheck('false');
      console.log("CreateVideoContentComponent_HandleContentResponse : Request result response ==> ",oResponse);
      this.oSessionService.cSessionService_SetContentForm(this.fContentForm.value);
      if(this.bCreateVideoMode)
      {
        this.oSessionService.cSessionService_SetGeneratedVideoCheck(false);
        this.oRouter.navigateByUrl('/ftue-other-settings');
      }
      else
      {
        this.oSessionService.cSessionService_SetGeneratedVideoCheck(true);
        this.oSessionService.cSessionService_SetResetTextOverlayCheck(false);
        this.oRouter.navigateByUrl('ftue-overview');
      }
    }
  }
  CreateVideoContentComponent_UpdateVideo(bUpdateVideo : boolean)
  {
    console.log("Update video ==> ", bUpdateVideo);
    if(bUpdateVideo)
    {
      this.oSessionService.cSessionService_SetContentForm(this.fContentForm.value);
      const oEditVideoForm = this.oEditVideoService.EditVideoService_ReturnCombinedPayload();
      console.log("Updated Video Request : ==> ", oEditVideoForm);
      this.oWebSocketService.WebSocket_RegisterEvent("user_video_result", this.CreateVideoContentComponent_HandleContentResponse);
      this.oWebSocketService.WebSocket_SendMessage("user_video_request", JSON.stringify(oEditVideoForm));
    }
  }
  ///////////////////////////// VoiceOver Section /////////////////////////////////
  ///////////////////////////// VoiceOver Section /////////////////////////////////
  ///////////////////////////// VoiceOver Section /////////////////////////////////
  ///////////////////////////// VoiceOver Section /////////////////////////////////

  async ContentComponent_UploadedVoiceOverFile(event)
  {
    this.ContentComponent_UploadVoiceover();
    let file = event.target.files[0];
    this.nVoiceOverFileSize = file.size;
    console.log("ContentComponent_UploadFile : File To Upload ==> ", file);
    var oFormData: any = new FormData();
    oFormData.append('session_id',this.sSessionId);
    oFormData.append('email',this.sUserEmail);
    oFormData.append('file',file);
    try
      {
        await this.oDataService.DataService_UploadUserVoiceOverFile(oFormData).subscribe((result : any) =>
        {
        console.log("ContentComponent_UploadedVoiceOverFile : Response ==>", result);
        this.oUploadedVoiceOverFile = result;
        this.sUploadedVoiceOverFileName = result.filename;
        this.bSuccessMessage = true;
        setTimeout(()=>{this.bSuccessMessage = false;},5000);
        this.sDisplayResponseMessage = "file "+this.sUploadedVoiceOverFileName+" uploaded successfully.";
        this.closeModal.nativeElement.click();
        this.fContentForm.patchValue({oUploadedVoiceOverFile : this.oUploadedVoiceOverFile, nVoiceOverFileSize : this.nVoiceOverFileSize});
        },
        (error)=>
        {
          this.oUploadedVoiceOverFile=null;
          this.sDisplayResponseMessage = error.error.errorMessage;
          this.bDisplayErrorMessage = true;
          setTimeout(()=>{this.bDisplayErrorMessage = false;},5000);
          console.error("ContentComponent_UploadedVoiceOverFile : Error ==>", error);
          this.closeModal.nativeElement.click();
        });
      }
      catch (error)
      {
        console.error("ContentComponent_UploadedVoiceOverFile : ERROR ==>", error);
      }
  }
  ContentComponent_TextToSpeech()
  {
    this.bShowTextToSpeechSection = true;
    this.nVoiceOverFileSize=0;
    this.fContentForm.patchValue({nVoiceOverFileSize : this.nVoiceOverFileSize});
    this.sUploadedVoiceOverFileName;
    this.fContentForm.patchValue({bShowTextToSpeechToggle : this.bShowTextToSpeechSection});
    this.fContentForm.removeControl('oUploadedVoiceOverFile');
    this.fContentForm.addControl('vo_text', new FormControl("", [Validators.required]));
    this.fContentForm.setControl('vo_text', this.oFormBuilder.control(""));
    this.fContentForm.addControl('selected_voice', new FormControl('', [Validators.required]));
    this.ContentComponent_SelectVoiceGender('male','create');
  }
  ContentComponent_UploadVoiceover()
  {
    this.bShowTextToSpeechSection = false;
    this.fContentForm.patchValue({bShowTextToSpeechToggle : this.bShowTextToSpeechSection});
    this.fContentForm.removeControl('vo_text');
    this.fContentForm.removeControl('selected_voice');
    this.fContentForm.removeControl('sGender');
    this.fContentForm.addControl('oUploadedVoiceOverFile', new FormControl('', [Validators.required]));
  }
  ContentComponent_SelectVoiceGender(value : any ,mode : string)
  {
    this.sSelectedVoiceGender=value;
    this.sVoiceName="";
    this.oVoiceOverList = [];
    this.oUserVoiceOverList.forEach(element =>
    {
      if(element.gender == value)
      {
        this.oVoiceOverList.push(element);
      }
    });
    if(mode==="create")
    {
      this.ContentComponent_SelectVoice(this.oVoiceOverList[2],2);
    }
    else
    {
      const nVoiceIndex = this.oVoiceOverList.findIndex((element : any)=>element.value === this.oSavedFormData.selected_voice);
      this.ContentComponent_SelectVoice(this.oVoiceOverList[nVoiceIndex],nVoiceIndex);
    }
    this.fContentForm.addControl('sGender', new FormControl());
    this.fContentForm.setControl('sGender', this.oFormBuilder.control(value));
  }
  ContentComponent_SelectVoice(select,index)
  {
    this.nSelectedVoiceIndex = index;
    this.sVoiceName = select?.name;
    this.fContentForm.patchValue({selected_voice : select.value});
  }
  ContentComponent_PlayAudio(filepath)
  {
    let filename = filepath.replace(/^.*(\\|\/|\:)/, '');
    console.log(filepath);
    this.oAudio = new Audio();
    this.oAudio.src = filepath;
    this.oAudio.load();
    this.oAudio.play();
  }
  ContentComponent_StopAudio(filepath)
  {
    let filename = filepath.replace(/^.*(\\|\/|\:)/, '');
    console.log(filepath);
    if (this.oAudio.src.replace(/^.*(\\|\/|\:)/, '') == filename)
      {
      this.oAudio.pause();
      this.oAudio = new Audio();
      this.oAudio.src = filepath;
      this.oAudio.load();
      }
  }

  ///////////////////////////// Text overlay Section /////////////////////////////////
  ///////////////////////////// Text overlay Section /////////////////////////////////
  ///////////////////////////// Text overlay Section /////////////////////////////////
  ///////////////////////////// Text overlay Section /////////////////////////////////

  ContentComponent_HighlightSelectedBlock(sIncommingBlockID :string)
  {
   this.sSelectedBlockID=sIncommingBlockID;
  }
  ContentComponent_PopulateTextList(event : any, nIncommingOuterIndex : number,  nIncommingInnerIndex : number)
  {
    let sValue = event.target.value;
    this.lBlockConfigurations[nIncommingOuterIndex].lTextAreaDetails[nIncommingInnerIndex].value=sValue;
    console.log("ContentComponent_PopulateTextList At block {%s} with index {%s}",nIncommingOuterIndex,nIncommingInnerIndex);
    this.fContentForm.patchValue({lBlockConfig : this.lBlockConfigurations});
  }
  ContentComponent_UpdateTextList()
  {
    this.lTextList=[];
    this.lBlockConfigurations.forEach(element =>
    {
      element.lTextAreaDetails.forEach(element =>
      {
        this.lTextList.push(element.value);
      });
    });
    console.log("ContentComponent_UpdateTextList : Text List==> ",this.lTextList);
  }
  ///////////////////////////// Video content Section /////////////////////////////////
  ///////////////////////////// Video content Section /////////////////////////////////
  ///////////////////////////// Video content Section /////////////////////////////////
  ///////////////////////////// Video content Section /////////////////////////////////

  ContentComponent_ToggleLetProntoChoose(event : any)
  {
    this.bLetProntoChoose = event.target.checked;
    this.fContentForm.patchValue(
    {
      pronto_clips : this.bLetProntoChoose,
      bLetProntoChooseToggle : this.bLetProntoChoose
    });
  }
  ContentComponent_ToggleMyMediaLibrary(event : any)
  {
    this.bDisplayMyMediaSection = event.target.checked;
    this.fContentForm.patchValue({bMyMediaLibraryToggle : this.bDisplayMyMediaSection});
  }
  ContentComponent_ToggleFileUploadType(nIncommingType : boolean)
  {
    this.bUploadVoiceOverFile = nIncommingType;
  }
  ContentComponent_ToggleFileUploadSection(event : any)
  {
    this.bDisplayUploadSection = event.target.checked;
    this.fContentForm.patchValue({bUploadSectionToggle : this.bDisplayUploadSection});
  }
  ContentComponent_ToggleFailedToUploadSection(event : any)
  {
    this.bDisplayFailedToUploadSection = event.target.checked;
  }
  ContentComponent_RemoveFileFromList(sIncommingVisualPath : string)
  {
    for (let nFiletoRemoveIndex = 0; nFiletoRemoveIndex < this.lUploadedFiles.length; nFiletoRemoveIndex++) {
      if(this.lUploadedFiles[nFiletoRemoveIndex].fpath===sIncommingVisualPath)
      {
        this.lUploadedFiles.splice(nFiletoRemoveIndex,1);
      }
    }
  }
  ContentComponent_RemoveFailedFileFileFromList(sIncommingFileName : string)
  {
    for (let nFiletoRemoveIndex = 0; nFiletoRemoveIndex < this.lFailedToUpload.length; nFiletoRemoveIndex++) {
      if(this.lFailedToUpload[nFiletoRemoveIndex].name===sIncommingFileName)
      {
        this.lFailedToUpload.splice(nFiletoRemoveIndex,1);
      }
    }
  }
  ContentComponent_SelectFilesForUpload(event)
  {
    console.log("event",event)
    this.nFileToUploadIndex=0;
    this.lFilestoUpload = event.target.files;
    for(let nFileUploadIndex = 0; nFileUploadIndex<this.lFilestoUpload.length;nFileUploadIndex++)
    {
      if(this.lFilestoUpload[nFileUploadIndex].type.indexOf('image') > -1 || this.lFilestoUpload[nFileUploadIndex].type.indexOf('video') > -1)
      {
        this.ContentComponent_UploadFile(this.lFilestoUpload[nFileUploadIndex]);
      }
      else
      {
        console.log("ContentComponent_SelectFilesForUpload : Please upload image/video file");
      }
    }
  }
  async ContentComponent_UploadFile(fileToUpload: any)
  {
    console.log("ContentComponent_UploadFile : File To Upload ==> ", fileToUpload);
    var oFormData: any = new FormData();
    oFormData.append('session_id',this.sSessionId);
    oFormData.append('email',this.sUserEmail);
    oFormData.append('file',fileToUpload);
    try
      {
        await this.oDataService.DataService_PostUploadedVisuals(oFormData).subscribe((result : any) =>
        {
          console.log("ContentComponent_UploadFile : Response ==> ", result);
          this.lUploadedFiles.push({filename : result.filename, fpath : result.fpath,thumb : result.thumb, url : result.url, size : this.lFilestoUpload[this.nFileToUploadIndex].size});
          this.nFileToUploadIndex++;
          this.closeModal.nativeElement.click();
        },
        (error)=>
        {
          this.lFailedToUpload.push(fileToUpload);
          console.error("ContentComponent_UploadFile : Fialed list ==>", this.lFailedToUpload);
          console.error("ContentComponent_UploadFile : Error ==>", error);
          this.closeModal.nativeElement.click();
          // if(error.error.errorType=="AuthenticationError")
          // {
          //   localStorage.clear();
          //   this.oRouter.navigate(['']);
          // }
        });
      }
      catch (error)
      {
        console.error("ContentComponent_UploadFile : ERROR ==>", error);
      }
  }
  ContentComponent_ReturnFileSize(bytes, decimals = 2)
  {
    if (bytes === 0)
    {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  ContentComponent_lEmittedFilesToUpload(lIncommingFiles: any)
  {
    console.log("ContentComponent_lEmittedFilesToUpload : Selected files ==> ", lIncommingFiles);
    this.lUserSelectedVisuals= lIncommingFiles;
    this.fContentForm.patchValue({lUserSelectedVisuals : this.lUserSelectedVisuals});
  }
  ContentComponent_RemoveSelectedVisual(oIncommingVisual: any)
  {
    console.log("ContentComponent_RemoveSelectedVisual : Visual to remove ==> ", oIncommingVisual);
    let nVisualIndex = this.lUserSelectedVisuals.indexOf(oIncommingVisual);
    this.lUserSelectedVisuals.splice(nVisualIndex,1);
    this.oInputVisualToRemove = oIncommingVisual;
  }
  ContentComponent_ToggleView(event)
  {
    if(event.target.checked)
    {
      this.oRouter.navigateByUrl('/wizard/overview');
    }
    else
    {
      this.oRouter.navigateByUrl('edit-video/timeline-view');
    }
  }
  ContentComponent_InitializeJqueryCode() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.text = `var elSelectCustom = document.getElementsByClassName("js-selectCustom")[0];
    var elSelectCustomValue = elSelectCustom.children[0];
    var elSelectCustomOptions = elSelectCustom.children[1];
    var defaultLabel = elSelectCustomValue.getAttribute("data-value");

    // Listen for each custom option click
    Array.from(elSelectCustomOptions.children).forEach(function (elOption) {
      elOption.addEventListener("click", (e) => {
        // Update custom select text too
        elSelectCustomValue.textContent = e.target.textContent;
        // Close select
        elSelectCustom.classList.remove("isActive");
      });
    });

    // Toggle select on label click
    elSelectCustomValue.addEventListener("click", (e) => {
      elSelectCustom.classList.toggle("isActive");
    });

    // close the custom select when clicking outside.
    document.addEventListener("click", (e) => {
      const didClickedOutside = !elSelectCustom.contains(event.target);
      if (didClickedOutside) {
        elSelectCustom.classList.remove("isActive");
      }
    });


        // smooth Loaded  start
    document.addEventListener("DOMContentLoaded", function(){

    var scrollSpy = new bootstrap.ScrollSpy(document.body, {
    target: '#main_nav',
    })

    // add smooth animated scroll via javascript
    document.querySelectorAll('.scrollto').forEach(function(element){
    element.addEventListener('click', function(e) {
        e.preventDefault();
        var section_name = element.getAttribute('data-bs-target');
        var offset_num = document.querySelector(section_name).offsetTop;
          window.scrollTo({ top:(offset_num - 0), behavior:'smooth' });
    });
    })

    });
    // smooth Loaded  end

                    var selector = '#main_nav li a';

                      $(selector).on('click', function(){
                          $(selector).removeClass('active');
                          $(this).addClass('active');

                          $('.video-timeline').removeClass('active')
                          $($(this).attr("data-bs-target")).addClass('active')
                      });`;
    document.body.appendChild(chatScript);
  }
}