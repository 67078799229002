import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchVoiceOver',
})
export class SearchVoiceOverPipe implements PipeTransform {
    transform(list: any[], search: string): any[] {
        search = search.toLowerCase();
        if( search != undefined && search != ''){

            return  list.filter((item) => {
                let match = false;
                if (item.speech_styles && item.speech_styles.length > 0) {
                    if( item.speaker_name && item.speaker_name.toLowerCase().indexOf(search) > -1 ){
                        match = true;
                    } else {
                        match = item.speech_styles.some((speech) => {
                            return (
                                speech.regional_accent.toLowerCase().indexOf(search) > -1 ||
                                speech.style.toLowerCase().indexOf(search) > -1 ||
                                (speech.voice_characteristics && speech.voice_characteristics.some((chart) =>  chart.toLowerCase().indexOf(search) > -1))
                            );
                        });
                    }
                    if(match){
                        return item ;
                    }
                }
            });
        } else {
            return list;
        }

    }
}
