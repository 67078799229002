import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-pr-video-player',
  templateUrl: './pr-video-player.component.html',
  styleUrls: ['./pr-video-player.component.scss']
})
export class PrVideoPlayerComponent implements OnInit {

  @Input() styleForced: any;
  @Input() autoPlay: any = false;
  @ViewChild('videoController') videoController : ElementRef;
  constructor() { }

  ngOnInit(): void {
  }

  videoInfo;

  @Input() set video(value: any) {
    this.videoInfo = value;
    const __this = this;
    setTimeout(() =>{
      __this.loadListeners();
    }, 1000);

  }

  loadListeners(){
      if(this.videoController){
        this.videoController.nativeElement.addEventListener("play", this.playClip);
        this.videoController.nativeElement.addEventListener("pause", this.pauseClip);
      }
  }

  playClip(e){
    // console.log("Play video Called...");
  }

  pauseClip(e){
    // console.log("Pause video Called...");
  }
}
