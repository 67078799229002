import { StringMap } from "@angular/compiler/src/compiler_facade_interface";
import { address } from "./common";

export class user {
  name: string;
  session_id: string;
  email: string;
  videoCount: number = 0;
  draftVideoCount: number = 0;
  publishedVideoCount: number = 0;
  ScheduledVideoCount: number = 0;
  LocationVideoCount: number = 0;


  getVideoCount(): number {
    return this.draftVideoCount + this.publishedVideoCount + this.ScheduledVideoCount + this.LocationVideoCount;
  }
}

export class socialplateforms {
  facebook: boolean;
  google: boolean;
  hubspot: boolean;
  instagram: boolean;
  linkedin: boolean;
  microsoft: boolean;
  twitter: boolean;
  youtube: boolean;
}


export class profileLite {
  session_id?: string;
  email?: string;
  logo_file?: string;
  logo_file_url?: string;
  user_logo_file?: string | any;
  organization_id?: string | any;
  organization?: string | any;
  plan?: string;
}
export class profile {

  password: string;
  session_id: string;
  business_name: string;
  url: string;
  business_type: string;
  address: address;
  phone: string;
  email: string;
  color: string;
  font_color: string;
  logo_file: string;
  logo_file_url: string;
  greeting: string;
  mood: string;
  location: string;
  name: string;
  is_subscribed_to_email: any;
  key_terms: [];
  brands: [];
  industry: string;
  allowed_media_libs: [];
  allowed_templates: string[];
  connected_social_platforms: socialplateforms;
  created_at: string;
  first_name: string;
  last_name: string;
  localization_enabled: boolean;
  plan: string;
  status: string;
  vertical: string;
  company_name: string;
  is_onboarding: boolean = false;
  user_status: string;
  next_billing_date: string;
  user_account_type?: string;
  get_pronto_newsletter?: any;
  _id?: string;
  user_logo_file?: string;
  organization_id?: string | any;
  organization?: string | any;
  default_media_lib?: any;
  primary_color?: string;
  secondary_colors?: any[];
  video_counts?: any;
  first_time_login?: boolean;
  first_login?: boolean;
  favourite_voices?: any[];
  selected_voices?: any[];
}


export class users {
  _id?: string;
  email?: string;
  session_id?: string;
  user_email?: string;
  media_libs?: string[];
  user_role?: string[];
  first_name?: string;
  username?: string;
  last_name?: string;
  brands?: string[];
  permissions?: permission;
  public_videos?: number | 0;
  published_video_count?: number | 0;
  media_uploads?: number | 0;
  login?: boolean | false;
  created?: object | any;
  allow_download_videos?: string;
  allow_share_brand_account?: string;
  allow_share_company_account?: string;
  allow_share_personal_account?: string;
  subscribe_email_changes?: any;
  get_pronto_newsletter?: any;
  brand_count?: number | 0;
  logo_file?: string;
  status?: string;
  last_login?: any;
  plan?: string;
  is_subscribed_to_email?: any;
}
export class permission {
  company_accounts: boolean;
  brand_accounts: boolean;
  personal_accounts: boolean;
  download_access: boolean;
}