<section class="loginSec">
    <app-onboarding-aside-section class="loginSecItm loginSecLeft"></app-onboarding-aside-section>
    <div class="loginSecItm loginSecRight">

        <div class="loginSecItmContent-plane">
            <div class="d-lg-none pb-5">
                <a routerLink="/login">
                    <img src="../assets/images/logo-mobile.svg" alt="logo-mobile" width="120" />
                </a>
            </div>
            <a (click)="getStarted()">
                <img src="../assets/images/onBoarding/back-arrow.svg" alt="back-arrow" />
            </a>
            <div class="row">

                <div class="col-md-10">
                    <div class="mt-5">
                        <div class="dot bg-primary-10">
                            <svg width="20" height="14" viewBox="0 0 20 14" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M17.6133 0.709581C18.1763 0.1914 19.0581 0.210106 19.5975 0.755605C20.1533 1.31776 20.1298 2.21895 19.5498 2.7528L8.10098 13.2904C7.55561 13.7924 6.70856 13.7921 6.16361 13.2896L0.449299 8.02078C-0.130281 7.48638 -0.152933 6.58518 0.403384 6.02354C0.943216 5.47854 1.82502 5.46054 2.38758 5.97924L7.1336 10.3553L17.6133 0.709581ZM1.57937 6.79574L1.57908 6.79547Z"
                                    fill="white" />
                            </svg>
                        </div>
                    </div>
                    <h2 class="text-gray-1 mt-4">Payment Successful</h2>
                    <p class="text-sm mt-3 ms-0 pb-0 mb-0">
                        Congrats, your payment was Successful! if you ever need to update your subscription,
                        head to your business settings.
                    </p>
                </div>
            </div>
            <div class="loginSecItmForm pt-4" aria-labelledby="loginYourself">
                <div class="row">
                    <div class="col-xl-5">
                        <div class="my-4">
                            <div class="tableBoxed">
                                <table class="text-xs table  ">
                                    <thead>
                                        <tr>
                                            <th colspan="2">Your Subscription Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Subscription Plan</td>
                                            <td class="weight-600">{{ this.plan == 'business_plus' ? 'Agency': 'Business'}} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-5">
                        <div class="row g-0">
                            <div class="col">
                                <div class="mb-3">
                                    <a href="javascript:void(0)" (click)="getStarted()" class="btn btn-secondary btn-round-6 w-184">Get
                                        Started</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>