<div class="video-sec" #videoListContainer>
  <div class="row row-cols-md-2 g-0" [ngClass]="
      bPublishedEmptyScreenView
        ? 'justify-content-center'
        : 'justify-content-between'
    ">
    <div class="loginSecItmForm video-list-container pt-0" aria-labelledby="loginYourself"
      [style.height.px]="videoContainerHeight" [ngStyle]="{ 'height': videoContainerHeight}">
      <div *ngIf="this.displayCountSection" class="video-header row m-0 pt-2">
        <span class="pb-1 pt-1 col-md-6">
          <p class="text-lg text-gray-2">
            {{ title == 'Localize'? 'Localized': title | titlecase }} Videos ({{ this.videoCount }})
          </p>
        </span>
        <ul class="text-end d-block col-md-6 {{ customGridLayout }}" [ngClass]="search != ''? 't-10': ''">

          <li *ngIf="myaccountPublished">
            <form class="searchbox searchboxOpen" [formGroup]="fSearchVideo">
              <div class="formItm search-icon searchDropdown">
                <input id="search-feild" type="search" value="" formControlName="title" [(ngModel)]="searchTermText"
                  placeholder="Search for a video" required="" (keyup.enter)="searchPublishVideo()" />
                <label for="search-feild" class="text-sm" style="cursor: default">Search your videos</label>
                <img class="search" src="../../../assets/images/svg/search.svg" (click)="searchPublishVideo()">
              </div>
            </form>
            <div class="searchtem" *ngIf="search != ''" (click)="clearSearchTerm()">
              <div>{{ search }}</div>
              <mat-icon class="link">close</mat-icon>
            </div>
          </li>
          <li id="selectpreff">
            <div class="formItm down_arrow">
              <div class="dropdown dropdownBtn">
                <button class="dropdown-toggle text-md" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="dropdownBtnText"><strong>Sort by </strong>{{ sSelectedSortingCategory }}</span>
                </button>
                <ul class="dropdown-menu dropdown-menu-end dropdownMenu ddd">
                  <li *ngFor="let sort of lSortBy">
                    <a *ngIf="!( (searchAspectRatio != 'all' && searchAspectRatio != '') && sort.value == 'aspect_ratio') "
                      class="dropdown-item text-md" href="javascript:void(0)"
                      (click)="CreatedVideosComponent_SortVideos(sort)">{{ sort.name }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li class="layout-btn">
            <button (click)="gridView()" id="gridView" class="grid button-gl active">
              <img src="../../../assets/images/svg/grid view.svg" class="svg-view-icon">
            </button>
            <button (click)="listView()" id="listView" class="list button-gl">
              <img src="../../../assets/images/svg/list view.svg" class="svg-view-icon">
            </button>
          </li>
        </ul>
      </div>

      <div *ngIf="sInputVideoStatus != 'scheduled' && !this.dashboardPublished" class="row ps-3 mb-1 m-0"
        style="margin-top:-20px !important;">
        <ul class="breadcrumb-tabs nav caret-hide ratio-setting">

          <li role="presentation" *ngFor="let aspectRatio of this.pronto.videoAspectRatioTabs; let i = index "
            (click)="selectAspectRatio(aspectRatio.ratio_value)"
            class="nav-item ml-n2 pe-0 ps-1 aspect-item {{aspectRatio.class_name}}-tab">
            <a class="nav-link"
              [ngClass]="{'active' : (aspectRatio.ratio_value == searchAspectRatio) || (searchAspectRatio == '' && aspectRatio.ratio_value == 'all')}">
              <b *ngIf=" i > 0 " class="divider ms-1">__</b>
              <p class="mb-0 mt-1"> {{aspectRatio.title}} </p>
              <div class='box ratio-cel ps-2 ms-2  {{aspectRatio.class_name}}' *ngIf="aspectRatio.ratio != 'all'">
                <span>{{aspectRatio.ratio}}</span>
              </div>
            </a>
          </li>
        </ul>

      </div>
      <div *ngIf="sInputVideoStatus != 'scheduled'" [ngClass]="this.videoManager.videos?.length > 0 ? '' : 'd-none'"
        class="" id="container-view">
        <div class="row grid m-0" id="cardListing">
          <li *ngFor="let video of this.videoManager.videos; index as i" style="margin-bottom: 30px !important">
            <!-- <div class="video-loading-status" *ngIf="video.status == undefined || video.status != 'success'">
              <div class='loader'></div>
            </div> -->

            <div class="video-outer" [ngStyle]="{
                minHeight:
                   session.dashboardVideoListView
                    ? '67px'
                    : (heightThumbs+'px')
              }" [ngClass]="
              session.dashboardVideoListView ? 'h-67 list-grid-view' : ''
            ">
              <div class="pageloader media-loader-outer" *ngIf="video.loadingThumbUrl">
                <div id="loader-inner" class="media-loader-inner"></div>
              </div>

              <div class="video_wrapper video_wrapper_full js-videoWrapper" [ngClass]="{'videoWrapperActive' : sSelectedVideoId === video.fpath, 'h-50' : session.dashboardVideoListView}">
                <div class="video-tag-container" [ngClass]="
                session.dashboardVideoListView ? 'd-none' : ''">
                  <p *ngIf="
                      video.publish_status == 'draft' &&
                      !thumbListView &&
                      this.isLocations
                    " class="video-tag">
                    Localized
                  </p>
                  <p *ngIf="
                      video.publish_status == 'draft' &&
                      !thumbListView &&
                      !this.isLocations
                    " class="video-tag">
                    Draft
                  </p>
                  <p *ngIf="
                      video.publish_status == 'published' && !thumbListView
                    " class="video-tag">
                    Published
                  </p>
                </div>
                <div class="share-edit">
                  <button id="toggle-slow" class="showSingle" target="1">
                    <img src="../../../assets/images/svg/ellipsis.svg">
                  </button>
                  <div id="div1" class="targetDiv">
                    <div *ngIf="
                        video.publish_status != 'published' &&
                        this.isLocations == false
                      ">
                      <a href="javascript:void(0)" (click)="loadVideoDataAndRedirect(video)" matTooltip="Edit"
                        class="svg-icon" matTooltipClass="custom-tooltip" matTooltipPosition="right">
                        <img src="../../../assets/images/svg/edit-video.svg">
                      </a>
                    </div>
                    <br />
                    <a href="javascript:void(0)" (click)="navigateToShare(video)" matTooltip="Share" class="svg-icon"
                      matTooltipClass="custom-tooltip" matTooltipPosition="right">
                      <img src="../../../assets/images/svg/share-video.svg">
                    </a>
                    <br />
                    <a href="javascrip:void(0)" *ngIf="
                        video.publish_status != 'published' &&
                        this.isLocations == false
                      " data-bs-toggle="modal" data-bs-target="#duplicate-popup" (click)="selectDuplicateVideo(video)"
                      class="svg-icon" matTooltip="Duplicate" matTooltipClass="custom-tooltip"
                      matTooltipPosition="right">
                      <img src="../../../assets/images/svg/duplicate-video - Copy.svg">
                    </a>
                    <br />
                    <a href="javascrip:void(0)" *ngIf="video.template_name == 'Bold'"
                      (click)="(this.onboardingManager.profile.user_status  == 'confirmed_not_subscribed')? goToSubscribe(): openExpectRationModel($event,i)"
                      matTooltip="Adjust" matTooltipClass="custom-tooltip" matTooltipPosition="right"
                      class="svg-parent-hover">
                      <img src="../assets/images/icons/resize-video-icon.svg" width="16" height="16" />
                    </a>
                  </div>
                </div>
                <span class="video-duration" *ngIf="video.template_name == 'SAP' && video.sap_video_duration" [ngClass]="session.dashboardVideoListView ? 'd-none' : ''">{{
                  video.sap_video_duration +
                  this.ConfigManager.sapVideoDurationDiff |
                  secondsToTime }}</span>
                <span *ngIf="video.template_name != 'SAP'" class="video-duration" [ngClass]="session.dashboardVideoListView ? 'd-none' : ''">{{ video.duration | secondsToTime
                  }}</span>
                <app-pr-video-player *ngIf="video.show_player" [styleForced]="false" [autoPlay]="true"
                  [video]="video"></app-pr-video-player>
                <figure class="video-baner" style="min-width: 80px; min-height:50px" [style.height.px]="heightThumbs"
                  [style.width.px]="widthThumbs" [ngClass]="session.dashboardVideoListView ? 'list-height-wdith' : ''">
                  <img [ngClass]="!session.dashboardVideoListView ? 'auto' : 'list-height'" *ngIf="video?.thumbnail" [src]="video.thumbnail"
                    alt="{{video.title}}" />

                </figure>
                <button class="videoPoster js-videoPoster" (click)="CreatedVideosComponent_PlayVideo(video)"></button>
              </div>
              <div class="title-details">
            <div [ngClass]="
            !session.dashboardVideoListView ? 'd-flex justify-content-between mt-2' : 'mb-1'" [ngStyle]="{
              display: video.publish_status != 'draft'
                  ? 'flex': ''}" style="margin-top: -5px;">
                <a *ngIf="!video.edit_title " href="javascript:void(0)" (click)="navigateToOverview(video)"
                  class="text-sm p-1 m-0 text-gray-2 title-text truncate-line" [ngClass]="{'w-100': video.publish_status != 'draft'}" [title]="video.title">{{ (video.title.length>90)?
                  (video.title | slice:0:90)+'...':(video.title)}}
                </a>
                <a style="height:40px; width:40px; position: absolute; top: -10px; font-weight:600; display:none"
                  [routerLink]="['/webplayer']" [queryParams]="{page:'webplayer', id: getSafePath(video)}" >Web player</a>
                <input *ngIf="video.edit_title" href="javascript:void(0)" id="edit-video-{{i}}"
                  class="text-sm p-0 m-0 text-gray-2 border-0 pe-3 caret-orange" maxlength="90"
                  [ngClass]="session.dashboardVideoListView ? 'w-25' : ''" [(ngModel)]="video.title_temp"
                  (keydown)="updateTitle($event.keyCode, video)" />

                <span class="edit-title pointer ms-2 mt-1" *ngIf="video.publish_status == 'draft'"
                  [ngClass]="{'active': video.edit_title}" (click)="editTitle(video, i)">
                  <a matTooltip="Rename Video" matTooltipClass="custom-tooltip" matTooltipPosition="right">
                    <img src="../../../assets/images/svg/edit-video.svg" style="max-width:unset;"></a>
                </span>
              </div>
              <div [ngClass]="session.dashboardVideoListView ? 'd-flex' : 'd-none'">
                <div class="video-tag-container list-view-tag" [ngClass]="
                !session.dashboardVideoListView ? 'd-none' : ''">
                  <p *ngIf="
                      video.publish_status == 'draft' &&
                      this.isLocations
                    " class="video-tag">
                    Localized
                  </p>
                  <p *ngIf="
                      video.publish_status == 'draft' &&
                      !this.isLocations
                    " class="video-tag">
                    Draft
                  </p>
                  <p *ngIf="
                      video.publish_status == 'published'" class="video-tag">
                    Published
                  </p>
                </div>
                <span class="video-duration list-view-duration ms-2" *ngIf="video.template_name == 'SAP' && video.sap_video_duration">{{
                video.sap_video_duration +
                this.ConfigManager.sapVideoDurationDiff |
                secondsToTime }}</span>
              <span *ngIf="video.template_name != 'SAP'" class="video-duration list-view-duration ms-2">{{ video.duration | secondsToTime
                }}</span>
                <p class="ms-2 created-text">Created on {{ video.created | date}}</p>
              </div>

            </div>
              <div class="list-v-dis date-format">
                <!-- <p>Created on {{ video.created | date}}</p> -->
                <div class="share-edit me-5">
                  <button id="toggle-slow" class="showSingle" target="1">
                    <img src="../../../assets/images/svg/ellipsis.svg">
                  </button>
                  <div id="div1" class="targetDiv" style="position: absolute" [ngStyle]="{
                      right:
                        video.publish_status != 'published' &&
                        this.isLocations == false
                          ? '0px'
                          : '-80px'
                    }" [ngClass]="
                    video.template_name == 'Bold'? 'w-165': 'w-125'">

                    <a href="javascrip:void(0)" *ngIf="video.template_name == 'Bold'"
                      (click)="(this.onboardingManager.profile.user_status  == 'confirmed_not_subscribed')? goToSubscribe(): openExpectRationModel($event,i)"
                      matTooltip="Adjust" matTooltipClass="custom-tooltip" matTooltipPosition="above"
                      class="svg-parent-hover">
                      <img src="../assets/images/icons/resize-video-icon.svg" width="16" height="16" />
                    </a>
                    <br />
                    <a href="javascrip:void(0)" *ngIf="
                        video.publish_status != 'published' &&
                        this.isLocations == false
                      " data-bs-toggle="modal" data-bs-target="#duplicate-popup" (click)="selectDuplicateVideo(video)"
                      class="svg-icon" matTooltip="Duplicate" matTooltipClass="custom-tooltip"
                      matTooltipPosition="above">
                      <img src="../../../assets/images/svg/duplicate-video - Copy.svg">
                    </a>
                    <br />
                    <a href="javascript:void(0)" (click)="navigateToShare(video)" matTooltip="Share" class="svg-icon"
                      matTooltipClass="custom-tooltip" matTooltipPosition="above">
                      <img src="../../../assets/images/svg/share-video.svg">
                    </a>
                    <br />
                    <div *ngIf="
                        video.publish_status != 'published' &&
                        this.isLocations == false
                      ">
                      <a href="javascript:void(0)" (click)="loadVideoDataAndRedirect(video)
                      " class="svg-icon" matTooltip="Edit" matTooltipClass="custom-tooltip" matTooltipPosition="above">
                        <img src="../../../assets/images/svg/edit-video.svg">
                      </a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </div>
      </div>

      <div *ngIf="sInputVideoStatus == 'scheduled'" class="video-sec" id="container-view">
        <div class="row d-flex scheduled_title ms-0 me-0">
          <div class="col-xl-12 col-lg-12 col-md-12 ps-2 ms-1 py-0 side-content">
            <div class="video-title pe-4 mb-2">
              <div class="custom-switch dot-border-left customAccordion"
                *ngFor="let schedule of this.videoManager.schedulevideos">
                <div class="customAccordionAction text-gray-2 text-sm">
                  {{ schedule.date | date : "EEEE h:mm a, dd/MM/YYYY" }}
                  <span>1</span>
                </div>
                <div class="customAccordionBody">
                  <ul class="d-flex flex-wrap mb-0">
                    <li *ngFor="let video of schedule.videos; index as i">
                      <div class="pageloader media-loader-outer" *ngIf="video.loadingThumbUrl">
                        <div id="loader-inner" class="media-loader-inner"></div>
                      </div>

                      <div class="video-outer">
                        <div class="video_wrapper video_wrapper_full js-videoWrapper" [ngClass]="
                            sSelectedVideoId === video.fpath
                              ? 'videoWrapperActive'
                              : ''
                          ">
                          <div class="share-edit">
                            <button id="toggle-slow" class="showSingle" target="1">
                              <img src="../../../assets/images/svg/ellipsis.svg">
                            </button>
                            <div id="div1" class="targetDiv">
                              <a href="javascript:void(0)" (click)="navigateToShare(video)" class="svg-icon">
                                <img src="../../../assets/images/svg/share-video.svg">
                              </a>
                            </div>
                          </div>
                          <video controls controlsList="nodownload noplaybackrate" class="videoIframe js-videoIframe"
                            [muted]="true" [src]="video.url" [id]="video.fpath"></video>
                          <figure class="video-baner">
                            <img [src]="video.thumbnail" alt="{{video.title}}" />
                            <span class="video-duration"
                              *ngIf="video.template_name == 'SAP' && video.sap_video_duration">{{
                              video.sap_video_duration + this.ConfigManager.sapVideoDurationDiff | secondsToTime
                              }}</span>
                            <span *ngIf="video.template_name != 'SAP'" class="video-duration">{{ video.duration |
                              secondsToTime }}</span>
                          </figure>
                          <button class="videoPoster js-videoPoster" (click)="
                              CreatedVideosComponent_PlayVideo(video.fpath)
                            "></button>
                        </div>

                        <a href="javascript:void(0)" (click)="navigateToOverview(video)"
                          class="text-sm p-3 m-0 text-gray-2 d-block text-truncate asd" [title]="video.title">{{
                          (video.title.length>25)? (video.title | slice:0:25)+'...':(video.title)
                          }}</a>
                        <div class="list-v-dis">

                          <p>Created on {{ video.created }}</p>
                          <div class="share-edit">
                            <button id="toggle-slow" class="showSingle" target="1">
                              <img src="../../../assets/images/svg/ellipsis.svg">
                            </button>
                            <div id="div7" class="targetDiv">
                              <a routerLink="/dashboard/socialmedia">
                                <img src="../../../assets/images/svg/share-video.svg">
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- If drafts videos -->
    <div *ngIf="
    (sInputVideoStatus == 'draft' || this.isLocations == true) && this.videoManager.videos?.length == 0  && isDataLoaded
  " class="accountSecContent p-2 pt-0 d-flex flex-column">
      <!-- <p class="text-lg text-gray-2 pb-1 pt-1 col-lg-7">
        {{ title | titlecase }} Videos ({{ this.videoManager.videos.length }})
      </p> -->
      <div class="align-items-center justify-content-center d-flex flex-grow-1" id="pills-tabContent">
        <div class="text-center pb-4 custom-text-center" [ngStyle]="{
            left: this.oSessionService.isSidebarActive && (title == 'draft' || title == 'Localize')? '59%' : '51.4%'
          }">
          <h4 *ngIf="!this.dashboardPublished && isDataLoaded" class="text-gray-2 mb-0 mt-0 pb-4 pt-0">

            You don't have any  {{ title=="draft"?"drafts":"localized" }} video<span *ngIf="searchAspectRatio != '' && searchAspectRatio != 'all'"> for this aspect ratio</span>.
          </h4>
          <h4 *ngIf="this.dashboardPublished  && isDataLoaded" class="text-gray-2 mb-0 mt-0 pb-4 pt-0">
            You don't have any video in this aspect ratio.
          </h4>

          <a (click)="navigateToCreateVideo()"
            class="weight-normal btn btn-secondary btn-round-6 md-btn d-flex justify-content-center d-inline-block px-3">
            <img src="../../../assets/images/svg/create video.svg">
            <span class="py-2 ps-1 d-inline-block">Create a New Video</span></a>
        </div>
      </div>

      <div *ngIf="
          sInputVideoStatus == 'draft' &&
          this.videoManager.videos?.length == 0 &&
          !this.dashboardPublished
        " class="emptyWatermark" style="z-index: 1">
        <img src="../../../assets/images/draft.png" />
      </div>
      <div *ngIf="
          sInputVideoStatus == 'draft' &&
          this.videoManager.videos?.length == 0 &&
          this.dashboardPublished
        " class="emptyWatermark" style="z-index: 1">
        <img src="../../../assets/images/my video.png" />
      </div>
    </div>
    <!-- if published videos -->

    <div *ngIf=" this.videoManager.videos.length == 0 && sInputVideoStatus == 'published' && isDataLoaded
      " class="accountSecContent p-2 pt-0 d-flex flex-column">
      <!-- <p *ngIf="
          !bPublishedEmptyScreenView
        " class="text-lg text-gray-2 pb-1 pt-1 col-lg-7">
        {{ title | titlecase }} Videos ({{ this.videoManager.videos.length }})
      </p> -->
      <div class="align-items-center justify-content-center d-flex flex-grow-1" id="pills-tabContent">
        <div class="text-center pb-4 custom-text-center {{ addCustomClass }}" [ngStyle]="{
            left:
              this.oSessionService.isSidebarActive && !this.dashboardPublished
                ? '59%'
                : '51.4%'
          }">
          <h4 class="text-gray-2 mb-0 mt-0 pb-4 pt-0">
            <!-- You haven't {{ title }} any videos in this aspect ratio yet. -->
            You don't have any  {{ title }} video<span *ngIf="searchAspectRatio != '' && searchAspectRatio != 'all'"> for this aspect ratio</span>.
          </h4>

          <a (click)="navigateToCreateVideo()"
            class="weight-normal btn btn-secondary btn-round-6 md-btn d-flex justify-content-center d-inline-block px-3">
            <img src="../../../assets/images/svg/create video.svg">
            <span class="py-2 ps-1 d-inline-block">Create a New Video</span></a>
        </div>
      </div>
      <div *ngIf="
          this.videoManager.videos.length == 0 &&
          sInputVideoStatus == 'published'
        " class="emptyWatermark" style="z-index: 1">
        <img src="../../../assets/images/published videos.png" />
      </div>
    </div>
    <ul id="pagination" *ngIf="this.lPagination?.length > 1" class="media-pagination ps-4"
      style="padding-left: 60px !important">
      <li>
        <a href="javascript:void(0)" class="preview-page" (click)="nCurrentPage == 1 ? '' : loadPage(nCurrentPage - 1)">
          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M5.25063 0.63694C5.39531 0.491231 5.39531 0.25499 5.25063 0.109281C5.10596 -0.0364277 4.8714 -0.0364277 4.72673 0.109281L0.944888 3.91824C0.351705 4.51568 0.351703 5.48432 0.944887 6.08175L4.72673 9.89072C4.8714 10.0364 5.10596 10.0364 5.25064 9.89072C5.39531 9.74501 5.39531 9.50877 5.25064 9.36306L1.46879 5.5541C1.16495 5.24808 1.16495 4.75192 1.46879 4.4459L5.25063 0.63694Z"
              fill="#7A7A7A" />
          </svg>
        </a>
      </li>
      <li *ngFor="let page of lPagination; index as i">
        <a href="javascript:void(0)" (click)="loadPage(page)" [ngClass]="i + 1 == nCurrentPage ? 'add_color' : ''">{{
          page }}</a>
      </li>
      <li>
        <a href="javascript:void(0)" class="next-page" (click)="
            nCurrentPage == lPagination.length ? '' : loadPage(nCurrentPage + 1)
          ">
          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M0.749366 0.63694C0.604694 0.491231 0.604694 0.25499 0.749366 0.109281C0.894037 -0.0364277 1.1286 -0.0364277 1.27327 0.109281L5.05511 3.91824C5.6483 4.51568 5.6483 5.48432 5.05511 6.08175L1.27327 9.89072C1.1286 10.0364 0.894036 10.0364 0.749364 9.89072C0.604692 9.74501 0.604693 9.50877 0.749364 9.36306L4.53121 5.5541C4.83505 5.24808 4.83505 4.75192 4.53121 4.4459L0.749366 0.63694Z"
              fill="white" />
          </svg>
        </a>
      </li>
    </ul>
  </div>

  <!-- Modal pop-up Start-->
  <nz-modal [(nzVisible)]="duplicatePopup" nzCentered [nzWidth]="566" [nzFooter]=null [nzClosable]=false
    (nzOnCancel)="handleCancel()">

    <div class="modal-content">

      <div class="modal-body">
        <button type="button" class="btn-close cust-close closeRightBtn" (click)="handleCancel()"></button>

        <h5>
          Do you want to duplicate "{{ this.selectedDuplicateVideo?.title }}"
          video?
        </h5>
        <p class="text-sm text-gray-3 pt-1 pb-4">
          A copy of this will be saved in your drafts.
        </p>
        <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" (click)="duplicateVideo()">Duplicate</a>
        <a href="javascript:void(0)" (click)="handleCancel()" class="mt-3 text-gray-3 text-sm">Cancel</a>
      </div>
    </div>
  </nz-modal>

  <!-- Modal pop-up End-->


</div>