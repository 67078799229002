
        <div class="h-80vh br12 curve-bg tab-content bg-white page-shadow" id="pills-tabContent">
            <div class="tab-pane fade show active">

                <div class="content_area ps-5 ms-4">
                    <div class="row">
                        <form [formGroup]="fOtherSettingForm">
                            <div class="col-sm-12 col-md-12 col-lg-10">

                                <p class="text-md text-gray-3 mb-2 primaryFont">Select the information you want shown at the end of your video</p>
                                <div class="d-flex align-items-start check_toggle_content pb-2">
                                    <div class="check_toggle">
                                        <div class="form-switch toggle_switch">
                                            <input class="form-check-input website" type="checkbox"
                                                id="flexSwitchCheckChecked" [checked]="bURLToggle" (change)="OtherSettingComponent_URLToggle($event)">
                                        </div>
                                    </div>
                                    <div class="check_content w-100">
                                        <p class="text-md text-gray-1 pb-1 mb-0 primaryFont">Website</p>
                                        <div class="row website-div">
                                            <div class="col-sm-12 col-md-12 col-lg-5"  *ngIf="bURLToggle">
                                                <div class="formItm">
                                                    <input id="website-address " type="text" value="https://www."
                                                        placeholder="Enter website address" name="textfeild"
                                                        formControlName="url" required>

                                                    <label for="website-address" class="text-sm">URL</label>
                                                     <span *ngIf="this.fOtherSettingForm?.get('url').errors && this.fOtherSettingForm?.get('url').touched" class="error">This field is required with valid URL.</span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex align-items-start check_toggle_content pb-2">
                                    <div class="check_toggle">
                                        <div class="form-switch toggle_switch">
                                            <input class="form-check-input address" type="checkbox"
                                                id="flexSwitchCheckChecked" [checked]="bAddressToggle" (change)="OtherSettingComponent_AddressToggle($event)">
                                        </div>
                                    </div>
                                    <div class="check_content w-100">
                                        <p class="text-md text-gray-1 pb-1 mb-0 primaryFont">Address</p>
                                        <div class="row address-div"  *ngIf="bAddressToggle">
                                            <div class="col-sm-12 col-md-12 col-lg-5">
                                                <div class="formItm">
                                                    <input id="address1" type="text"  placeholder="line1"
                                                        name="textfeild" formControlName="line1" required>
                                                    <label for="address1" class="text-sm">Address</label>
                                       <span *ngIf="fOtherSettingForm?.get('line1').hasError('required') && this.fOtherSettingForm?.get('line1').touched" class="error">This field is required.</span>

                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-5">
                                                <div class="formItm">
                                                    <input id="address2" type="text"  placeholder="line2"
                                                        name="textfeild" formControlName="line2">
                                                    <label for="address2" class="text-sm">Apartment, suite, building, etc.</label>

                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-5">
                                                <div class="formItm">
                                                    <input id="city" type="text"  placeholder="city"
                                                        name="textfeild" formControlName="city" required >
                                                    <label for="city" class="text-sm">City</label>
                                 <span *ngIf="fOtherSettingForm?.get('city').hasError('required') && fOtherSettingForm?.get('city').touched" class="error">This field is required.</span>

                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-5">
                                                <div class="formItm">
                                                    <input id="State" type="text"  placeholder="State"
                                                        name="textfeild" formControlName="state" required >
                                                    <label for="State" class="text-sm">State</label>
                              <span *ngIf="fOtherSettingForm?.get('state').hasError('required') && fOtherSettingForm?.get('state').touched" class="error">This field is required.</span>

                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-5">
                                                <div class="formItm">
                                                    <input id="Zipcode" type="text"  placeholder="Postal Code"
                                                        name="textfeild" formControlName="postal_code" required >
                                                    <label for="Zipcode" class="text-sm">Zipcode</label>
                                          <span *ngIf="fOtherSettingForm?.get('postal_code').hasError('required') && fOtherSettingForm?.get('postal_code').touched" class="error">This field is required.</span>

                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-5">
                                                <div class="formItm">
                                                    <input id="Country" type="text"  placeholder="Country"
                                                        name="textfeild" formControlName="country" >
                                                    <label for="Country" class="text-sm">Country</label>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="d-flex align-items-start check_toggle_content">
                                    <div class="check_toggle">
                                        <div class="form-switch toggle_switch">
                                            <input class="form-check-input phone" type="checkbox"
                                                id="flexSwitchCheckChecked" (change)="OtherSettingComponent_PhoneToggle($event)" [checked]="bPhoneToggle">
                                        </div>
                                    </div>
                                    <div class="check_content w-100">
                                        <p class="text-md text-gray-1 pb-1 mb-0 primaryFont">Phone Number</p>
                                        <div class="row phone-div">
                                            <div class="col-sm-12 col-md-12 col-lg-5">

                                                <div class="formItm" *ngIf="bPhoneToggle">
                                                    <input id="phone-no" type="text"
                                                        placeholder="Enter your phone"  name="textfeild"
                                                        formControlName="phone">
                                                    <label for="phone-no" class="text-sm">Phone</label>
                                                     <span *ngIf="fOtherSettingForm?.controls['phone'].hasError('required') && fOtherSettingForm?.controls['phone'].touched" class="error">This field is required.</span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="wtermarklogo">
                <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
            </div>
        </div>


<!-- error modal -->
<div class="modal fade" id="error-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog width-483 modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-body">
                <button type="button" class="btn-close cust-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <h5 class="mb-4">{{sErrorMessage}}</h5>
                <div class="alert alert-danger p-3 mb-4 g-0 row rounded-1">
                    <div class="col-1 p-0">
                        <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                            <path d="M13.9993 3.47998C6.92399 3.47998 1.16602 9.20176 1.16602 16.2326C1.16602 23.2635 6.92399 29 13.9993 29C21.0747 29 26.8327 23.2782 26.8327 16.2473C26.8327 9.21646 21.0747 3.47998 13.9993 3.47998ZM15.1095 15.7619C15.1095 16.365 14.6062 16.8651 13.9993 16.8651C13.3925 16.8651 12.8892 16.365 12.8892 15.7619V8.40747C12.8892 7.80441 13.3925 7.3043 13.9993 7.3043C14.6062 7.3043 15.1095 7.80441 15.1095 8.40747V15.7619Z" fill="#FF8369"></path>
                            <path d="M17.7366 2.32H10.2647C9.74765 2.32 9.33398 1.808 9.33398 1.168C9.33398 0.528 9.74765 0 10.2647 0H17.7366C18.2537 0 18.6673 0.512 18.6673 1.152C18.6673 1.792 18.2537 2.32 17.7366 2.32Z" fill="#FF8369"></path>
                            </g>
                            <defs>
                            <clipPath id="clip0">
                            <rect width="28" height="29" fill="white"></rect>
                            </clipPath>
                            </defs>
                            </svg>


                    </div>
                    <div class="col-11 p-0">

                        <p class="text-sm text-gray-3 mb-0 ms-2">{{sErrorMessage}}</p>
                    </div>
                </div>

                <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" data-bs-dismiss="modal" routerLink="/dashboard/myvideos">Go Back</a>

            </div>
        </div>
    </div>
</div>
<!-- error modal end -->