import { error } from '@angular/compiler/src/util';
import { filter } from 'rxjs/operators';
import { voice } from './../../models/video';
import { cSessionService } from './../../services/session.service';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { ApiURL } from 'src/app/conf/api-path';
import { ProntoHttpService } from './pronto-http.service';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/services/loader.service';
import { OnboardingManagerService } from 'src/app/Managers/onboarding-manager.service';
import { VoiceDataStore } from 'src/app/stores/voice.store';
@Injectable({
    providedIn: 'root',
})
export class VoiceOverService implements OnInit{
    selectedVoiceList: any[];
    selectedFavoritesList: any[];
    sessionId = '';
    userEmail = '';

    constructor(
        private http: HttpClient,
        private cSessionService: cSessionService,
        private httpService: ProntoHttpService,
        private voiceDataStore: VoiceDataStore,
        public loader: LoaderService,
        public onboardinManger: OnboardingManagerService,
    ) {
       this.getUserLoginInfo();
    }
    ngOnInit(): void {
        this.getUserLoginInfo();
    }

    getUserLoginInfo(){
        this.sessionId = this.cSessionService.SessionService_GetSessionId();
        this.userEmail = this.cSessionService.cSessionService_GetUserEmail();
    }

    browseVoiceOver(filters: string[], search: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getUserLoginInfo();
            let data = {
                email: this.userEmail,
                session_id: this.sessionId,
            };
            this.httpService.post(environment.API_HOST + ApiURL.user.voice.getAll, data).then((res) => {
                if (res) {
                    this.voiceDataStore.browseVoiceList.premium.voices = [];
                    this.voiceDataStore.browseVoiceList.essentials.voices = [];
                    res.map((voice) => {
                        if (voice.license == 'premium') {
                            this.voiceDataStore.favoriteVoiceOverList.premium.voices.map((favvoice, i) => {
                                if (favvoice._id.$oid == voice._id.$oid) {
                                    voice.favorite = true;
                                }
                            });
                            this.voiceDataStore.selectedVoiceOverList.premium.voices.map((selectedvoice, i) => {
                                if (selectedvoice._id.$oid == voice._id.$oid) {
                                    voice.selected = true;
                                }
                            });
                            this.voiceDataStore.browseVoiceList.premium.voices.push(voice);
                        } else {
                            this.voiceDataStore.favoriteVoiceOverList.essentials.voices.map((favvoice, i) => {
                                if (favvoice._id.$oid == voice._id.$oid) {
                                    voice.favorite = true;
                                }
                            });
                            this.voiceDataStore.selectedVoiceOverList.essentials.voices.map((selectedvoice, i) => {
                                if (selectedvoice._id.$oid == voice._id.$oid) {
                                    voice.selected = true;
                                }
                            });
                            this.voiceDataStore.browseVoiceList.essentials.voices.push(voice);
                        }
                    });
                    let newList = this.voiceDataStore.browseVoiceList;
                    // this.loader.hideLoader();
                    resolve({ success: true, data: newList });
                } else {
                    // this.loader.hideLoader();
                    resolve({});
                }
            });
        });
    }

    compareVoicesWithBrows() { }

    makeFavorite(voiceid: any, value): Promise<any> {
        return new Promise((resolve, reject) => {
            let data = {
                email: this.userEmail,
                session_id: this.sessionId,
                voice_ids: voiceid,
            };
            this.httpService.post(environment.API_HOST + ApiURL.user.voice.setFavorite, data).then((res) => {
                if (res) {

                    resolve({ success: true });
                } else {
                    resolve({});
                }
            });

        });
    }

    selectVoice(voiceid: any): Promise<any> {
        return new Promise((resolve, reject) => {
            let data = {
                email: this.userEmail,
                session_id: this.sessionId,
                voice_ids: voiceid,
            };
            this.httpService.post(environment.API_HOST + ApiURL.user.voice.setSelection, data).then((res) => {
                if (res) {
                    let newList = this.voiceDataStore.selectedVoiceOverList?.essentials?.voices.concat(
                        this.voiceDataStore.selectedVoiceOverList?.premium?.voices,
                    );
                    this.voiceDataStore.resetVoiceBrowseSelection(this.voiceDataStore.lastSelectedVoiceId._id.$oid, undefined, true);
                    this.voiceDataStore.updateSelectedListObservable(newList);
                    resolve({ success: true });
                    // this.voiceDataStore.setVoiceLimits(this.onboardinManger?.profile?.plan);
                    this.voiceDataStore.reamingVoices = this.voiceDataStore.limitEssential - this.voiceDataStore.selectedVoiceOverList?.essentials?.voices.length;
                } else {
                    resolve({});
                }
            });
        });
    }
    unSelectVoice(voice: any): Promise<any> {
        return new Promise((resolve, reject) => {
            resolve({ success: true });
        });
    }
    getSelectedVoiceOverList(): Promise<any> {
        return new Promise((resolve, reject) => {
            let newList = this.voiceDataStore.selectedVoiceOverList?.essentials?.voices.concat(
                this.voiceDataStore.selectedVoiceOverList?.premium?.voices,
            );
            this.voiceDataStore.updateSelectedListObservable(newList);
            resolve({ success: true, data: newList });
        });
    }
    getFavoriteVoiceOverList(): Promise<any> {
        return new Promise((resolve, reject) => {
            let newList = this.voiceDataStore.favoriteVoiceOverList?.essentials?.voices.concat(
                this.voiceDataStore.favoriteVoiceOverList?.premium?.voices,
            );
            this.voiceDataStore.updateFavoritesListObservable(newList);
            resolve({ success: true, data: newList });
        });
    }

    setVoiceAttribute(filter: any): Promise<any> {
        return new Promise((resolve, reject) => {
            resolve({ success: true });
        });
    }

    getFavoriteVoiceList(): Promise<any> {
        return new Promise((resolve, reject) => {
            let newList = this.voiceDataStore.favoriteVoiceOverList;
            resolve({ success: true, data: newList });
        });
    }
    getSelectedVoiceList(): Promise<any> {
        return new Promise((resolve, reject) => {
            let newList = this.voiceDataStore.selectedVoiceOverList;
            resolve({ success: true, data: newList });
        });
    }
    generateTestToVoice(voiceid: any, IncomingText: any, location : string): Promise<any> {
        this.getUserLoginInfo();
        return new Promise((resolve, reject) => {
            let data = {
                email: this.userEmail,
                session_id: this.sessionId,
                speaker_id: voiceid,
                text: IncomingText,
                location : location
            };
            if(!location || location == ''){
                delete data.location;
            }
            this.httpService.post(environment.API_HOST + ApiURL.user.voice.textToVoice, data).then((response: any) => {
                if (response) {
                    resolve({ response });
                    this.loader.hideLoader();
                } else {
                    reject({error: response});
                    this.loader.hideLoader();
                }
            }).catch( ( error : any ) =>{
                this.loader.hideLoader();
                reject({message: error.error ? error.error : error});

            });
        });
    }

    searchVoiceByFilters(searchData): Promise<any> {
        return new Promise((resolve, reject) => {
            let data = {
                email: this.userEmail,
                session_id: this.sessionId,
            };
            this.voiceDataStore.searchedVoiceList.essentials.voices = [];
            this.voiceDataStore.searchedVoiceList.premium.voices = [];
            this.voiceDataStore.searchedVoiceList.essentials.voices = this.voiceDataStore.browseVoiceList.essentials.voices.filter(
                (item) => {
                    let match = false;
                    if (item.speech_styles && item.speech_styles.length > 0) {
                        match = item.speech_styles.some((speech) => {
                            return (
                                searchData.indexOf(speech.regional_accent) > -1 ||
                                searchData.indexOf(speech.style) > -1 ||
                                (speech.voice_characteristics &&
                                    speech.voice_characteristics.some((chart) => searchData.indexOf(chart) > -1))
                            );
                        });
                    }
                    if (match) {
                        return item;
                    }
                },
            );

            this.voiceDataStore.searchedVoiceList.premium.voices = this.voiceDataStore.browseVoiceList.premium.voices.filter((item) => {
                let match = false;
                if (item.speech_styles && item.speech_styles.length > 0) {
                    match = item.speech_styles.some((speech) => {
                        return (
                            searchData.indexOf(speech.regional_accent) > -1 ||
                            searchData.indexOf(speech.style) > -1 ||
                            (speech.voice_characteristics && speech.voice_characteristics.some((chart) => searchData.indexOf(chart) > -1))
                        );
                    });
                }

                if (match) {
                    return item;
                }
            });

            this.setFavandSelectedVoice(this.voiceDataStore.searchedVoiceList.essentials.voices);
            this.setFavandSelectedVoice(this.voiceDataStore.searchedVoiceList.premium.voices);

            // console.log(" Return now ", this.voiceDataStore.searchedVoiceList.essentials.voices);
            // console.log(" Return now premium", this.voiceDataStore.searchedVoiceList.premium.voices);
            resolve({});
        });
    }
    setFavandSelectedVoice(voiceList: any[]) {
        if (voiceList && voiceList.length > 0) {
            voiceList.map((item) => {
                let voiceFav = this.voiceDataStore.favoriteVoiceArray.some((fav) => {
                    return item.$id?.oid == fav.$id?.oid;
                });
                let voiceSel = this.voiceDataStore.SelectedVoiceArray.some((item) => {
                    // console.log('selected', item);
                });
                if (voiceFav) item.favorite = true;
                if (voiceSel) item.selected = true;
            });
        }
    }

    getSelectedVoiceFromList(list: any[]) {
        let newList = [];
        if (list && list.length > 0) {
            newList = list.filter((item) => {
                if (item.selected == true) {
                    return item;
                }
            });
        }
        return newList;
    }
    getFavoriteVoiceFromList(list: any[]) {
        let newList = [];
        if (list && list.length > 0) {
            newList = list.filter((item) => {
                if (item.favorite == true) {
                    return item;
                }
            });
        }
        return newList;
    }
    addNewItemInSelectedList(voice) {
        let listSelectedVoices = [];
        let indexVoice = this.voiceDataStore.getIndexOfSelected(voice._id.$oid);
        if (voice.license == 'premium') {
            if (!this.voiceDataStore.selectedVoiceOverList.premium.voices) {
                this.voiceDataStore.selectedVoiceOverList.premium.voices = [];
            }
            if(indexVoice.prem_id == -1 ){
                this.voiceDataStore.selectedVoiceOverList.premium.voices.push(voice);
            } else {
                this.voiceDataStore.selectedVoiceOverList.premium.voices[indexVoice.prem_id] = voice;
            }

        } else if (voice.license == 'essential') {
            if (!this.voiceDataStore.selectedVoiceOverList.essentials.voices) {
                this.voiceDataStore.selectedVoiceOverList.essentials.voices = [];
            }
            if(indexVoice.es_id == -1 ){
                this.voiceDataStore.selectedVoiceOverList.essentials.voices.push(voice);
            } else {
                this.voiceDataStore.selectedVoiceOverList.essentials.voices[indexVoice.prem_id] = voice;
            }
        }

        listSelectedVoices = listSelectedVoices.concat(
            this.getSelectedVoiceFromList(this.voiceDataStore.selectedVoiceOverList.essentials.voices),
        );
        listSelectedVoices = listSelectedVoices.concat(
            this.getSelectedVoiceFromList(this.voiceDataStore.selectedVoiceOverList.premium.voices),
        );
        let listVoiceIds = [];
        listSelectedVoices.map((item) => {
            if (!listVoiceIds.includes(item?._id?.$oid)) {
                listVoiceIds.push(item?._id?.$oid);
            }
        });
        this.selectVoice(listVoiceIds);
    }

    addNewItemInFavoriteList(voice) {
        let listFavoriteVoices = [];
        let listVoiceIds = [];
        let indexFav = this.voiceDataStore.getIndexOfFavorite(voice._id.$oid);
        if (!voice.favorite) {
            voice.favorite = !voice.favorite;

            if (voice.license == 'premium') {

                if (indexFav.prem_id > -1) {
                    this.voiceDataStore.favoriteVoiceOverList.premium.voices[indexFav.prem_id].favorite = true;
                } else {
                    this.voiceDataStore.pushNewFavorite(voice, "premium");
                }

            } else if (voice.license == 'essential') {

                if (indexFav.es_id > -1) {
                    this.voiceDataStore.favoriteVoiceOverList.essentials.voices[indexFav.es_id].favorite = true;
                } else {
                    this.voiceDataStore.pushNewFavorite(voice, "essential");
                }
            }
        }
        else {
            voice.favorite = !voice.favorite;

            if (indexFav.es_id > -1) {
                this.voiceDataStore.favoriteVoiceOverList.essentials.voices.splice(indexFav.es_id, 1);
            }

            if (indexFav.prem_id > -1) {
                this.voiceDataStore.favoriteVoiceOverList.premium.voices.splice(indexFav.prem_id, 1);
            }

        }
        listFavoriteVoices = listFavoriteVoices.concat(
            this.getFavoriteVoiceFromList(this.voiceDataStore.favoriteVoiceOverList.essentials.voices),
        );
        listFavoriteVoices = listFavoriteVoices.concat(
            this.getFavoriteVoiceFromList(this.voiceDataStore.favoriteVoiceOverList.premium.voices),
        );

        listFavoriteVoices.map((item) => {
            if (!listVoiceIds.includes(item?._id?.$oid)) {
                listVoiceIds.push(item?._id?.$oid);
            }
        });

        this.makeFavorite(listVoiceIds, voice.favorite).then((res) => {
            // Reset All Fvorite & Selected
            this.voiceDataStore.resetVoiceBrowseSelection(voice._id.$oid, voice.favorite, undefined);
            let newList = this.voiceDataStore.favoriteVoiceOverList?.essentials?.voices.concat(
                this.voiceDataStore.favoriteVoiceOverList?.premium?.voices,
            );

            this.voiceDataStore.updateFavoritesListObservable(newList);
        });
    }
    AddUserMedia(file, media_lib, brand_id, fpath) {
        const orgnization = this.onboardinManger.profile.organization_id;
        if (orgnization) {
            let formda = new FormData();
            formda.append('email', this.userEmail);
            // formda.append("session_id", session_id);
            formda.append('filename', file);
            formda.append('media_lib_id', media_lib);
            formda.append('brand_id', brand_id);
            formda.append('organization_id', orgnization);
            if (fpath) {
                formda.append('fpath', fpath);
            }
            return this.http.post(environment.API_HOST_ADMIN + 'add_user_media', formda).toPromise();
        } else {
            return new Promise((resolve, reject) => {
                console.log(' orgnaization not found ...');
                reject({ errorMessage: 'orgnaization not found' });
            });
        }
    }

    cropVoice(data : any) : Promise<any>{
        this.getUserLoginInfo();
        data.email = this.userEmail;
        data.session_id = this.sessionId;
        return this.http.post(environment.API_HOST + ApiURL.user.voice.crop_file, data).toPromise();
    }
    deleteVoiceOver(data : any) : Promise<any>{
        return this.http.post(environment.API_HOST + ApiURL.user.voice.delete_voice_over_file, data).toPromise();
    }
}
