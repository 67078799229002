import { Component, OnDestroy, OnInit } from '@angular/core';
import { OnboardingManagerService } from 'src/app/Managers/onboarding-manager.service';
import { SubscriptionplanManagerService } from 'src/app/Managers/subscriptionplan-manager.service';
import { VoiceDataStore } from 'src/app/stores/voice.store';

@Component({
  selector: 'app-my-account-voice-over',
  templateUrl: './my-account-voice-over.component.html',
  styleUrls: ['./my-account-voice-over.component.scss']
})
export class MyAccountVoiceOverComponent implements OnInit , OnDestroy  {

  selectedTab = "browse";
  constructor(
    public voiceStore: VoiceDataStore,
    public planManager: SubscriptionplanManagerService,
    public onboardinManger: OnboardingManagerService,) { }
  ngOnDestroy(): void {
    // throw new Error('Method not implemented.');
  }

  ngOnInit(): void {

  }

  updateSelectedTab(option : string){
   this.selectedTab = option;
  }
  upgradeSelction() {
      this.planManager.planDialogue = true
  }
}
