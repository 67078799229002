<div class="h-85vh br12 curve-bg tab-content bg-white page-shadow" id="pills-tabContent">
    <div class="tab-pane fade show active">

        <div class="content_area ps-5 ms-4">
            <div class="row">

                <form [formGroup]="fOtherSettingForm">


                    <div class="col-sm-4">
                        <div class="formItm static_lable order_list mb-2">
                            <label for=""><span class="counts">6.</span>Personalize your end screen with custom
                                tagline.</label>

                            <div class="video-timeline right-align-panel bg-none active tagline_field">
                                <div class="check_content video-text row video-bg-gray active-bg">

                                    <div class="col-12 custom-input row-multi-">
                                        <div class="formItm">
                                            <input formControlName="sapVideoTagLine" name="titleName" type="text"
                                                required placeholder="Your tagline" maxlength="40"><span
                                                class="text-limit ps-1">{{fOtherSettingForm.controls['sapVideoTagLine'].value.length}}/40
                                                characters</span>
                                            <span
                                                *ngIf="this.fOtherSettingForm?.get('sapVideoTagLine').errors && this.fOtherSettingForm?.get('sapVideoTagLine').touched"
                                                class="error">This field is required.</span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-10">
                        <div class="formItm static_lable order_list mb-2">
                            <label for=""><span class="counts">7.</span>Select the information you want shown at the end
                                of your video</label>
                        </div>
                        <p class="text-md text-gray-3 mb-2 primaryFont"></p>
                        <div class="d-flex align-items-start check_toggle_content pb-2">
                            <div class="check_toggle">
                                <div class="form-switch toggle_switch">
                                    <input class="form-check-input website" type="checkbox" id="flexSwitchCheckChecked"
                                        [checked]="bURLToggle" (change)="OtherSettingComponent_URLToggle($event)">
                                </div>
                            </div>
                            <div class="check_content w-100">
                                <p class="text-md text-gray-1 pb-1 mb-0 primaryFont">Website</p>
                                <div class="row website-div">
                                    <div class="col-sm-12 col-md-12 col-lg-5" *ngIf="bURLToggle">
                                        <div class="formItm">
                                            <input id="website-address " type="text" value="https://www."
                                                placeholder="Enter website address" name="textfeild"
                                                formControlName="url" required>

                                            <label for="website-address" class="text-sm">URL</label>
                                            <span
                                                *ngIf="this.fOtherSettingForm?.get('url').errors && this.fOtherSettingForm?.get('url').touched"
                                                class="error">This field is required with valid URL.</span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex align-items-start check_toggle_content pb-2">
                            <div class="check_toggle">
                                <div class="form-switch toggle_switch">
                                    <input class="form-check-input address" type="checkbox" id="flexSwitchCheckChecked"
                                        [checked]="bAddressToggle"
                                        (change)="OtherSettingComponent_AddressToggle($event)">
                                </div>
                            </div>
                            <div class="check_content w-100">
                                <p class="text-md text-gray-1 pb-1 mb-0 primaryFont">Address</p>
                                <div class="row address-div" *ngIf="bAddressToggle">
                                    <div class="col-sm-12 col-md-12 col-lg-5">
                                        <div class="formItm">
                                            <input id="address1" type="text" placeholder="line1" name="textfeild"
                                                formControlName="line1" required>
                                            <label for="address1" class="text-sm">Address</label>
                                            <span
                                                *ngIf="fOtherSettingForm?.get('line1').hasError('required') && this.fOtherSettingForm?.get('line1').touched"
                                                class="error">This field is required.</span>

                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-5">
                                        <div class="formItm">
                                            <input id="address2" type="text" placeholder="line2" name="textfeild"
                                                formControlName="line2">
                                            <label for="address2" class="text-sm">Apartment, suite, building,
                                                etc.</label>

                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-5 mt-2">
                                        <div class="formItm">
                                            <input id="city" type="text" placeholder="city" name="textfeild"
                                                formControlName="city" required>
                                            <label for="city" class="text-sm">City</label>
                                            <span
                                                *ngIf="fOtherSettingForm?.get('city').hasError('required') && fOtherSettingForm?.get('city').touched"
                                                class="error">This field is required.</span>

                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-5 mt-2">
                                        <div class="formItm">
                                            <input id="State" type="text" placeholder="State" name="textfeild"
                                                formControlName="state" required>
                                            <label for="State" class="text-sm">State</label>
                                            <span
                                                *ngIf="fOtherSettingForm?.get('state').hasError('required') && fOtherSettingForm?.get('state').touched"
                                                class="error">This field is required.</span>

                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-5 mt-2">
                                        <div class="formItm">
                                            <input id="Zipcode" type="text" placeholder="Postal Code" name="textfeild"
                                                formControlName="postal_code" required>
                                            <label for="Zipcode" class="text-sm">Zipcode</label>
                                            <span
                                                *ngIf="fOtherSettingForm?.get('postal_code').hasError('required') && fOtherSettingForm?.get('postal_code').touched"
                                                class="error">This field is required.</span>

                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-5 mt-2">
                                        <div class="formItm">
                                            <input id="Country" type="text" placeholder="Country" name="textfeild"
                                                formControlName="country">
                                            <label for="Country" class="text-sm">Country</label>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="d-flex align-items-start check_toggle_content">
                            <div class="check_toggle">
                                <div class="form-switch toggle_switch">
                                    <input class="form-check-input phone" type="checkbox" id="flexSwitchCheckChecked"
                                        [checked]="bPhoneToggle" (change)="OtherSettingComponent_PhoneToggle($event)">
                                </div>
                            </div>
                            <div class="check_content w-100">
                                <p class="text-md text-gray-1 pb-1 mb-0 primaryFont">Phone Number</p>
                                <div class="row phone-div">
                                    <div class="col-sm-12 col-md-12 col-lg-5" *ngIf="bPhoneToggle">

                                        <div class="formItm">
                                            <input id="phone-no" type="text" placeholder="Enter your phone"
                                                name="textfeild" formControlName="phone">
                                            <label for="phone-no" class="text-sm">Phone</label>
                                            <span
                                                *ngIf="fOtherSettingForm?.controls['phone'].hasError('required') && fOtherSettingForm?.controls['phone'].touched"
                                                class="error">This field is required.</span>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="wtermarklogo">
        <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
    </div>
</div>