import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { cEditVideoService } from './../../../services/edit-video.service';
import { cSessionService } from './../../../services/session.service';
import { cTemplateConfigService } from './../../../services/template.service';
import { cWebSocketService } from './../../../services/websocket.service';

@Component({
  selector: 'app-ftue-overview',
  templateUrl: './ftue-overview.component.html',
  styleUrls: ['./ftue-overview.component.scss']
})
export class FtueOverviewComponent implements OnInit {

  sSessionID : string = "";
  sUserEmail : string = "";
  sVideoID : string = "";
  // sEditVideoID : string = "";
  fOverviewForm : FormGroup;
  oSavedFormData : any;
  lVideoDurations : any = [{name : "6s",value : 6,checked : false},{name : "15s",value : 15,checked : false},{name : "30s",value : 30,checked : false},{name : "60s",value : 60,checked : false},]
  lAspectRatio = ["(16:9)960x540"];
  sSelectedAspectRatio = "(16:9)960x540";
  sDisplayResponseErrorMessage : string;
  bDisplayErrorMessage : boolean = false;
  bCreateVideoMode : boolean = true;
  showMenu : string;
  sDisableEditModeToggle : string = "false";

  constructor( private oRouter : Router,private oFormBuilder : FormBuilder,private oSessionService : cSessionService,private oWebSocketService : cWebSocketService,private oRoutes : Router, private oEditVideoService : cEditVideoService, private oTemplateConfigService : cTemplateConfigService)
  {
    this.showMenu = this.oRouter.url
    let bGenerateVideo = this.oSessionService.cSessionService_GetGeneratedVideoCheck();
    if(bGenerateVideo)
    {
      this.bCreateVideoMode=false;
    }
    else
    {
      this.bCreateVideoMode=true;
    }
  }

  ngOnInit(): void
  {
    // this.CreateVideoOverviewComponent_InitializeJqueryCode();
    this.oWebSocketService.WebSocket_CreateWebSocket();
    this.oWebSocketService.WebSocket_RegisterEvent("user_video_result", this.CreateVideoOverviewComponent_HandleOverviewResponse);
    this.sSessionID = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    this.sVideoID = this.oSessionService.SessionService_GetVideoId();
    // this.sEditVideoID = this.oSessionService.SessionService_GetEditVideoId();
    this.oSavedFormData = this.oSessionService.cSessionService_GetOverviewForm();
    this.DashboardComponent_FetchVoiceOverList();
    // console.log("CreateVideoOverviewComponent : OverView Form ==> ",this.oSavedFormData);
    if(this.oSavedFormData)
    {
    this.CreateVideoOverviewComponent_SetOverviewForm();
    }
    else
    {
      this.CreateVideoOverviewComponent_InitializeOverviewForm();
    }
    this.CreateVideoOverviewComponent_CheckForResetTextOverlays();
    this.sDisableEditModeToggle = this.oSessionService.SessionService_GetResetTextOverlayCheck();
  }
  ngOnDestroy()
  {
    this.oSessionService.cSessionService_SetOverviewForm(this.fOverviewForm.value);
    this.oWebSocketService.WebSocket_Destroy();
  }
  CreateVideoOverviewComponent_SetOverviewForm()
  {
    this.fOverviewForm = this.oFormBuilder.group(
      {
        session_id: [this.oSavedFormData.session_id],
        email: [this.oSavedFormData.email],
        title: [this.oSavedFormData.title, [Validators.required]],
        duration: [this.oSavedFormData.duration, [Validators.required]],
        about: [this.oSavedFormData.about,[Validators.required]],
        aspect_ratio: ['(16:9)960x540'],
        video_id : [this.sVideoID]
      }
    );
  }

  CreateVideoOverviewComponent_InitializeOverviewForm()
  {
    this.fOverviewForm = this.oFormBuilder.group(
      {
        session_id: this.sSessionID,
        email: this.sUserEmail,
        title: ['', [Validators.required]],
        duration: [30,[Validators.required]],
        about: ['',[Validators.required]],
        aspect_ratio: ['(16:9)960x540'],
      }
    );
  }
  CreateVideoOverviewComponent_CheckForResetTextOverlays()
  {
    this.fOverviewForm.get('duration').valueChanges.subscribe(duration =>
    {
      // console.log("Duration changed : ==> ", duration);
      let lsOverviewForm = this.oSessionService.cSessionService_GetOverviewForm();

      // console.log("step1"+lsOverviewForm);
      if(lsOverviewForm)
      {
        let lsContentForm = this.oSessionService.cSessionService_GetContentForm();
        // console.log("step2"+lsContentForm);
        if(lsContentForm)
        {
          this.oSessionService.cSessionService_SetResetTextOverlayCheck(true);
          this.sDisableEditModeToggle="true";
          const sSelectedTemplateName = this.oSessionService.cSessionService_GetLooknFeelForm().template_name;
          this.oTemplateConfigService.TemplateConfigService_UpdateTemplateBlocks(lsContentForm.lBlockConfig,duration,sSelectedTemplateName);
          // console.log("CreateVideoOverviewComponent_CheckForResetTextOverlays : Reset textOverlays ==> ",true);
        }
      }
    });
  }

  CreateVideoOverviewComponent_SendOverviewRequest()
  {
    // console.log(this.fOverviewForm)
    if(this.fOverviewForm.valid)
    {
      let oOverViewForm : any =
      {
        session_id: this.fOverviewForm.value.session_id,
        email: this.fOverviewForm.value.email,
        title: this.fOverviewForm.value.title,
        duration: this.fOverviewForm.value.duration,
        about: this.fOverviewForm.value.about,
        aspect_ratio: '16x9',
        video_id : this.fOverviewForm.value.video_id,
      }
      if(!this.sVideoID)
      {
        delete oOverViewForm.video_id;
      }
      this.CreateVideoOverviewComponent_CheckForResetTextOverlays();
      console.log("CreateVideoOverviewComponent_SendOverviewRequest : Overview Request => ", oOverViewForm);
      this.oWebSocketService.WebSocket_SendMessage("user_video_request", JSON.stringify(oOverViewForm));
    }
    else
    {
       this.fOverviewForm.markAllAsTouched()
    }
  }
  CreateVideoOverviewComponent_HandleOverviewResponse = (InComingMessage) =>
  {
    if (JSON.parse(InComingMessage)["errorMessage"])
    {
      this.sDisplayResponseErrorMessage = InComingMessage;
      console.log("CreateVideoOverviewComponent_HandleOverviewResponse : Error ==>",InComingMessage);
      this.bDisplayErrorMessage = true;
      setTimeout(()=>{this.bDisplayErrorMessage = false},5000);
    }
    else
    {
      const oResponse = JSON.parse(InComingMessage);
      this.oSessionService.cSessionService_SetVideoTitle(this.fOverviewForm.get('title').value);
      this.oSessionService.cSessionService_SetVideoDuration(this.fOverviewForm.get('duration').value);
      this.oSessionService.cSessionService_SetOverviewForm(this.fOverviewForm.value);
      console.log("CreateVideoOverviewComponent_HandleOverviewResponse : Response ==> ",oResponse);
      if(this.bCreateVideoMode)
      {
        this.oSessionService.cSessionService_SetVideoId(oResponse.video_id)
        this.oSessionService.cSessionService_SetGeneratedVideoCheck(false);
        this.oRoutes.navigateByUrl('/ftue-looknfeel');
      }
      else
      {
        // this.oTemplateConfigService.bSendGenerateVideoRequest.next(1);
        this.oSessionService.cSessionService_SetGeneratedVideoCheck(true);
        this.oRoutes.navigateByUrl('/ftue-overview');
      }
    }
  }
  CreateVideoOverviewComponent_UpdateVideo(bUpdateVideo : boolean)
  {
    if(this.fOverviewForm.valid)
    {
      console.log("Update video ==> ", bUpdateVideo);
      if(bUpdateVideo)
      {
        this.oSessionService.cSessionService_SetOverviewForm(this.fOverviewForm.value);
        const oEditVideoForm = this.oEditVideoService.EditVideoService_ReturnCombinedPayload();
        console.log("Updated Video Request : ==> ", oEditVideoForm);
        this.oWebSocketService.WebSocket_SendMessage("user_video_request", JSON.stringify(oEditVideoForm));
      }
    }
    else
    {
       this.fOverviewForm.markAllAsTouched()
    }
  }
  CreateVideoOverviewComponent_SelectAspectRation(value)
  {
    this.sSelectedAspectRatio = value;
  }
  CreateVideoOverviewComponent_ToggleView(event)
  {
    if(event.target.checked)
    {
      this.oRoutes.navigateByUrl('/wizard/overview');
    }
    else
    {
      this.oRoutes.navigateByUrl('edit-video/timeline-view');
    }
  }

    // Fetch Voice OverList Section
    DashboardComponent_FetchVoiceOverList()
    {
      const oVoiceOver =
        {
          email: this.sUserEmail,
          session_id: this.sSessionID,
        }
      this.oWebSocketService.WebSocket_RegisterEvent('vo_voices_list_result', this.DashboardComponent_HandleVoiceoverListResponse);
      this.oWebSocketService.WebSocket_SendMessage('vo_voices_list_request', JSON.stringify(oVoiceOver));
    }

    DashboardComponent_HandleVoiceoverListResponse = (InComingMessage) =>
    {
      console.log('ContentComponent_HandleVoiceoverListResponse => ', JSON.parse(InComingMessage));
      if (JSON.parse(InComingMessage)['errorMessage'])
      {
        //alert('Error ' + JSON.parse(InComingMessage)['errorMessage']);
        // this.responseStatus = 'Error ' + JSON.parse(InComingMessage)['errorMessage'];
      }
      else
      {
        //convert nested object into list
        let oGetVoiceOverObject = JSON.parse(InComingMessage);
        let oUserVoiceOverList: any = Object.entries(oGetVoiceOverObject);
        let sessionVoiceoverList = [];
        oUserVoiceOverList.forEach(element =>
          {
            let displaytext = element[0].split(/(\d+)/);
            let name = displaytext[0] + " " + displaytext[1];
            let sample_voice = element[1].sample_voice;
            let cps = element[1].cps;
            let gender = element[1].gender;
            let voice_name = element[1].voice_name;
            sessionVoiceoverList.push(
            {
              "name": name,
              "value": element[0],
              "sample_voice": sample_voice,
              "cps": cps,
              "gender": gender,
              "voice_name": voice_name
            });
          }
        );
        this.oSessionService.cSessionService_SetUserVoiceOverList(sessionVoiceoverList);
      }
    }
  FTUEOverviewComponent_NavigateToUserLogin()
  {
    console.log("Clear alll ...");
    localStorage.clear();
    this.oRouter.navigateByUrl('/login');
  }
  FTUEOverviewComponent_NavigateToLogin()
  {
    console.log("Clear alll ...");
    localStorage.clear();
    this.oRouter.navigateByUrl('/ftue-login');

  }
  CreateVideoOverviewComponent_InitializeJqueryCode()
  {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.text = `var elSelectCustom = document.getElementsByClassName("js-selectCustom")[0];
    var elSelectCustomValue = elSelectCustom.children[0];
    var elSelectCustomOptions = elSelectCustom.children[1];
    var defaultLabel = elSelectCustomValue.getAttribute("data-value");

    // Listen for each custom option click
    Array.from(elSelectCustomOptions.children).forEach(function (elOption) {
      elOption.addEventListener("click", (e) => {
        // Update custom select text too
        elSelectCustomValue.textContent = e.target.textContent;
        // Close select
        elSelectCustom.classList.remove("isActive");
      });
    });
    // Toggle select on label click
    elSelectCustomValue.addEventListener("click", (e) => {
    elSelectCustom.classList.toggle("isActive");
    });

    // close the custom select when clicking outside.
    document.addEventListener("click", (e) => {
    const didClickedOutside = !elSelectCustom.contains(event.target);
    if (didClickedOutside) {
    elSelectCustom.classList.remove("isActive");
    }
    });`;

    document.body.appendChild(chatScript);
  }

}
