import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ProntoHttpService {
    constructor(private http: HttpClient){

    }
    post = (url : string, data : any , _header = undefined, action = '') : Promise<any> =>{
        return this.http.post(url, data).toPromise();
    }
    get = (url : string, _header = undefined, action = '') : Promise<any> =>{
        return this.http.get(url).toPromise();
    }
}