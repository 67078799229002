<div class="upload-box" style="margin: auto; max-width: 1200px; z-index:10000; width: 100%">
    <div *ngIf="!showBrowserComponent" class="modal-content">
        <div class="modal-header pt-0 pb-0">
            <div class="col-md-12 d-flex justify-content-between">
                <h5 class="modal-title" id="exampleModalLabel">Voice Attributes(5)</h5>
                <div class="me-3">
                    <a class="text-gray-3 text-sm me-3" href="javascript:void(0)"
                        (click)="clearFilter()">Clear All</a>
                        <a  href="javascript:void(0)"
                            class="weight-normal btn btn-secondary btn-round-6 px-4 py-3"
                            (click)="applyFilter()">Apply</a></div>

            </div>
            <button type="button" (click)="close()" class="btn-close close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body pt-0">
            <div class="form-group files">
                <div *ngFor="let voiceAtt of voiceDataStore.voiceAttributes">
            <h3 class="text-lg text-gray-2 ms-2 mb-2" [ngClass]="voiceAtt.attributeName == 'REGIONAL ACCENTS' ? 'mt-2' : 'mt-5'">{{voiceAtt.attributeName}}</h3>
            <p>
                <span class="voice-filters" [ngClass]="{'active' : filter.isActive == true}"
                 (click)="setVoiceAttribute(filter)" *ngFor="let filter of voiceAtt.filters"
                    (mouseover)="filter.hover = true"
                    (mouseout)="filter.hover = false">
                <img *ngIf="filter.isActive == true" class="me-1" matTooltip="Select this Attribute" matTooltipPosition="left" height="15px"
                [src]="!filter.hover? prontoService.icons.green_check_white_bg : prontoService.icons.cross" />
                {{filter.name}}</span></p>
        </div>
            </div>
        </div>


    </div>

    <div *ngIf="this.showBrowserComponent" class="modal-content">
        <div class="modal-header pt-0 pb-0">
            <button type="button" (click)="close()" class="btn-close close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <app-browse-voice-over
        [isModel]="true" [showBrowseVoice]="showBrowseVoice"
        (showFilter)="showBrowserComponent = false" [searchAttributes]="this.searchAttributes"></app-browse-voice-over>
        <a *ngIf="this.attributesFilters"  href="javascript:void(0)"
        class="weight-normal btn btn-secondary btn-round-6 px-1 py-3 w-100 mx-auto"
        (click)="clearFilter(); this.showBrowserComponent = false;">Clear All</a>
    </div>
</div>