import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import { HttpResponse } from '@angular/common/http';
import { Router, RouterStateSnapshot } from '@angular/router';

import { map, catchError } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class Httpinterceptor implements HttpInterceptor {

  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    req = req.clone({
      setHeaders: {
       // "Permissions-Policy": "camera=*,geolocation=*,microphone=*,autoplay=*,fullscreen=*,picture-in-picture=*,sync-xhr=*,encrypted-media=*,oversized-images=*",
        //"Strict-Transport-Security": "max-age=31536000; includeSubdomains",
        //"X-Frame-Options": "SAMEORIGIN",
        //"X-Content-Type-Options": "nosniff",
       // "X-Xss-Protection": "1; mode=block",
        // "Content-Security-Policy": "script-src https: 'unsafe-inline' 'unsafe-eval';style-src https: 'unsafe-inline' 'unsafe-eval';img-src https: data:;font-src https: data:;",
        //"Access-Control-Allow-Credentials": "true",
        // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, AuthorizationY',

      //  'Access-Control-Allow-Methods':'*'

      "Access-Control-Allow-Origin": "*"


      }
    });

    return next.handle(req).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {

        //  console.log(event);
        if (event.status === 403) {

          this.router.navigateByUrl('/login');
        }
      }
      return event;
    }
    ), catchError((error: HttpErrorResponse) => {
      let errorMsg = '';
      if (error.error instanceof ErrorEvent) {
        console.log('This is client side error');
        errorMsg = `Error: ${error.error.message}`;
      } else {
        console.log('This is server side error');
        errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
        // this.router.navigate(['login'],{queryParams:{'redirectURL':this.state.url}});
        if (error.status == 403) {


          if (!this.router.url.split('?')[0].includes('/verify_reset_password') && !this.router.url.split('?')[0].includes('/login') && this.router.url.split('?')[0] != '/verify_email' && this.router.url.split('?')[0] != '/createAccount'&& this.router.url.split('?')[0]!= '/create-account/paywall-success' &&
          this.router.url.indexOf('dashboard/download-video') > -1 && this.router.url.split('?')[0]!= '/create-account/paywall-failed') {

            localStorage.clear();
            this.router.navigateByUrl('/login');
          }

        }
      }
      console.log(errorMsg);
      return throwError(error.error);
    }));
  }

}

