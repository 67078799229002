<div class="py-4 px-4 account-heading">
    <div class="row">
        <div class="col-md-7 pw-4">
            <h3 class="weight-700 text-gray-2">Media Libraries</h3>
            <p class="mx-0 my-0 text-sm">Effortlessly organize and access your media assets for efficient video
                production</p>
        </div>

        <div class="col-md-5 d-flex justify-content-end">
            <div class="formItm search-icon searchDropdown pt-3 search-option">
                <input id="search-feild" type="search" value="" [(ngModel)]="searchTermText"
                    placeholder="Search location" required="" (keyup.enter)="searchLibrary()" />
                <label for="search-feild" class="text-sm" style="cursor: default">Search Library</label>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" (click)="searchLibrary()"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0 7.27242C0 3.25597 3.25597 0 7.27242 0C11.2889 0 14.5448 3.25597 14.5448 7.27242C14.5448 9.11134 13.8623 10.7908 12.7368 12.0714L15.8623 15.1991C16.046 15.3829 16.0459 15.6809 15.862 15.8646C15.6782 16.0484 15.3802 16.0483 15.1965 15.8644L12.0712 12.737C10.7906 13.8624 9.11123 14.5448 7.27242 14.5448C3.25597 14.5448 0 11.2889 0 7.27242ZM7.27242 0.941228C3.7758 0.941228 0.941228 3.7758 0.941228 7.27242C0.941228 10.769 3.7758 13.6036 7.27242 13.6036C10.769 13.6036 13.6036 10.769 13.6036 7.27242C13.6036 3.7758 10.769 0.941228 7.27242 0.941228Z"
                        fill="#A39FAA"></path>
                </svg>
            </div>
            <button type="button"
                class="btn btn-secondary btn-round-6 d-inline-block small-pad ms-2 float-end mt-3 text-xs"
                (click)="openMedia()">
                Add library
            </button>

            <a href="javascript:void(0)" class="float-end filter-btn d-inline-block mt-3 ms-2 me-4"
                (click)="openFilter()">
                <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M4.16667 1.49996H5C5.46024 1.49996 5.83333 1.87306 5.83333 2.33329V2.7494V2.74998V2.75056V3.16663C5.83333 3.62686 5.46024 3.99996 5 3.99996H4.16667C3.70643 3.99996 3.33333 3.62686 3.33333 3.16663V2.75189L3.33334 2.74996L3.33333 2.74803V2.33329C3.33333 1.87306 3.70643 1.49996 4.16667 1.49996ZM6.66667 2.33329V2.33331H12.9167C13.1468 2.33331 13.3333 2.51986 13.3333 2.74998C13.3333 2.9801 13.1468 3.16665 12.9167 3.16665H6.66667C6.66666 4.08711 5.92047 4.83329 5 4.83329H4.16667C3.24619 4.83329 2.5 4.0871 2.5 3.16663H0.416672C0.186553 3.16663 5.26508e-06 2.98008 5.26508e-06 2.74996C4.47035e-06 2.51984 0.186553 2.33329 0.416671 2.33329H2.5C2.5 1.41282 3.24619 0.666626 4.16667 0.666626H5C5.92047 0.666626 6.66667 1.41282 6.66667 2.33329ZM10.8333 8.99996C10.8333 9.92043 10.0871 10.6666 9.16667 10.6666H8.33333C7.41287 10.6666 6.66668 9.92044 6.66667 8.99998H0.416667C0.186548 8.99998 0 8.81343 0 8.58331C0 8.35319 0.186548 8.16665 0.416667 8.16665H6.66667V8.16663C6.66667 7.24615 7.41286 6.49996 8.33333 6.49996H9.16667C10.0871 6.49996 10.8333 7.24615 10.8333 8.16663H12.9167C13.1468 8.16663 13.3333 8.35317 13.3333 8.58329C13.3333 8.81341 13.1468 8.99996 12.9167 8.99996H10.8333ZM7.5 8.58331V8.99996C7.5 9.4602 7.8731 9.83329 8.33333 9.83329H9.16667C9.6269 9.83329 10 9.4602 10 8.99996V8.58531C10 8.58463 10 8.58396 10 8.58329C10 8.58262 10 8.58195 10 8.58128V8.16663C10 7.70639 9.6269 7.33329 9.16667 7.33329H8.33333C7.8731 7.33329 7.5 7.70639 7.5 8.16663V8.58331Z"
                        fill="#404243"></path>
                </svg>
            </a>
        </div>
    </div>
</div>

<div class="accountSecInner flex-grow-1">
    <div class="accountSecContent">
        <div class="accountSecContent pb-2">
            <div class="data-table m-0 pt-4" style="overflow: auto; max-height: calc(100vh - 350px)">
                <div class="table-header row m-0">
                    <div *ngIf="this.mediaManger.mediaLibs?.length" class="col-sm-5 col-md-5 col-lg-5 p-0 px-4">
                        <p class="px-3 mb-0">
                            <span class="text-lg text-gray-2">Total Media Libraries </span>
                            <span class="badge rounded-pill bg-gray-3 ms-2 w-30 d-inline-block custom-badge text-xs">{{
                                this.totalMediaCount + this.mediaManger.mediaLibs.length }}
                            </span>
                        </p>
                    </div>
                </div>
                <table class="table overflow-auto tableCustom table-hover mt-4 mb-4">
                    <thead>
                        <tr>
                            <th scope="col" class="tableCheck" style="width: 10px !important">
                                <div class="custom-switch">
                                    <input type="checkbox" class="check form-check-input d-none" id="checkAll" />
                                </div>
                            </th>
                            <th scope="col" style="width: 26%">Library Name</th>
                            <th scope="col" style="width: 24%">Type</th>
                            <th scope="col" style="width: 24%">Items</th>
                            <th scope="col" style="width: 20%">Status</th>
                            <th scope="col" style="width: 4%" class="tableAction">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style="position: relative">
                            <td colspan="5">
                                <img (click)="toggleMyLib(true)" *ngIf="!collapseMyLib"
                                    src="../assets/images/icons/open_list.svg" class="collapse-icon" />
                                <img (click)="toggleMyLib(false)" *ngIf="collapseMyLib"
                                    src="../assets/images/icons/close_list.svg" class="collapse-icon" />
                                <div class="text-lg brandTitle">My Libraries</div>
                            </td>
                        </tr>
                        <tr *ngFor="let media of this.mediaManger.mediaLibs" class="data"
                            [ngClass]="{ 'hide-row': collapseMyLib, data: true }">
                            <td style="width: 20px !important" scope="row" class="tableCheck">
                                <div class="custom-switch">
                                    <input type="checkbox" class="check form-check-input" />
                                </div>
                            </td>
                            <td>
                                <a routerLink="/account-setting/media-libraries/media"
                                    (click)="UserMediaComponent_NavigateToMediaSection(media)">{{ media.name }}</a>
                            </td>
                            <td>Media Library</td>
                            <td>{{ media?.assets ? media.assets : 0 }}</td>
                            <td>{{ media.activation_status }}</td>
                            <td class="tableAction">
                                <a href="javascript:void(0)" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                                    <img width="24" height="6" src="../assets/images/icons/three-dots-elipses.svg" />
                                </a>
                                <ul class="dropdown-menu add_location page-shadow"
                                    aria-labelledby="dropdownMenuButton1">
                                    <li (click)="UserMediaComponent_NavigateToMediaSection(media)">Edit</li>
                                    <li *ngIf="media.activation_status == 'active' && !media.name.includes('default_lib')"
                                        (click)="showModal(media)">
                                        Deactivate
                                    </li>
                                    <li *ngIf="media.activation_status != 'active'" (click)="updateMediaLibrary(media)">
                                        Activate</li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>

                    <tbody *ngFor="let brand of brandList?.result">
                        <tr style="position: relative">
                            <td colspan="5">
                                <img (click)="collapseLibraries(brand, true)" *ngIf="!brand.collapse"
                                    src="../assets/images/icons/open_list.svg" class="collapse-icon" />
                                <img (click)="collapseLibraries(brand, false)" *ngIf="brand.collapse"
                                    src="../assets/images/icons/close_list.svg" class="collapse-icon" />
                                <div class="text-lg brandTitle">{{ brand.name }}</div>
                            </td>
                        </tr>
                        <tr *ngFor="let media of brand.mediaLibs"
                            [ngClass]="{ 'hide-row': brand.collapse, data: true }">
                            <td scope="row" class="tableCheck" style="width: 20px !important">
                                <div class="custom-switch">
                                    <input type="checkbox" class="check form-check-input" />
                                </div>
                            </td>
                            <td>
                                <a routerLink="/account-setting/media-libraries/media"
                                    (click)="UserMediaComponent_NavigateToMediaSection(media)">{{ media.name }}</a>
                            </td>
                            <td>Brand</td>
                            <td>{{ media.assets ? media.assets : 0 }}</td>
                            <td>{{ media.activation_status }}</td>
                            <td class="tableAction">
                                <a href="javascript:void(0)" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                                    <img width="24" height="6" src="../assets/images/icons/three-dots-elipses.svg" />
                                </a>
                                <ul class="dropdown-menu add_location page-shadow"
                                    aria-labelledby="dropdownMenuButton1">
                                    <li (click)="UserMediaComponent_NavigateToMediaSection(media)">Edit</li>
                                    <li *ngIf="media.activation_status == 'active' && !media.name.includes('default_lib')"
                                        (click)="showModal(media)">
                                        Deactivate
                                    </li>
                                    <li *ngIf="media.activation_status != 'active'" (click)="updateMediaLibrary(media)">
                                        Activate</li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="overlay sidebarOverlay"></div>

    <nz-drawer [nzClosable]="false" [nzVisible]="visibleMedia" [nzPlacement]="placementMedia" (nzOnClose)="closeMedia()"
        [nzWidth]="440">
        <ng-container *nzDrawerContent>
            <!-- add library -->

            <nav style="padding: 24px;">
                <div class="sidebar-header pt-3">
                    <h3 class="text-gray-2 weight-700">Add Library</h3>
                    <div class="closeRightSideBar pt-2" (click)="closeMedia()">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M14.1971 0H5.8129C2.17108 0 0 2.17 0 5.81V14.18C0 17.83 2.17108 20 5.8129 20H14.1871C17.8289 20 20 17.83 20 14.19V5.81C20.01 2.17 17.8389 0 14.1971 0Z"
                                fill="#F2F2F5" />
                            <path
                                d="M11.1852 9.9986L13.7568 7.42787C14.0811 7.10374 14.0811 6.56724 13.7568 6.2431C13.4326 5.91897 12.8959 5.91897 12.5716 6.2431L10 8.81383L7.42837 6.2431C7.10412 5.91897 6.56744 5.91897 6.24319 6.2431C5.91894 6.56724 5.91894 7.10374 6.24319 7.42787L8.81481 9.9986L6.24319 12.5693C5.91894 12.8935 5.91894 13.43 6.24319 13.7541C6.4109 13.9218 6.62334 14 6.83578 14C7.04822 14 7.26066 13.9218 7.42837 13.7541L10 11.1834L12.5716 13.7541C12.7393 13.9218 12.9518 14 13.1642 14C13.3767 14 13.5891 13.9218 13.7568 13.7541C14.0811 13.43 14.0811 12.8935 13.7568 12.5693L11.1852 9.9986Z"
                                fill="#A7ABB6" />
                        </svg>
                    </div>
                </div>
                <div class="sidebar-content pt-0 flex-grow-1 overflow-auto">
                    <form [formGroup]="fAddLibraryForm">
                        <div class="formItm">
                            <input id="lib_name" type="text" required placeholder="Library Name"
                                formControlName="lib_name" required />
                            <label for="lib_name" class="text-sm">Library Name</label>
                            <span *ngIf="
                                    this.fAddLibraryForm?.controls['lib_name']?.hasError('required') &&
                                    fAddLibraryForm?.controls['lib_name']?.touched
                                " class="error">Please enter your Library Name.</span>
                        </div>

                        <div class="formItm">
                            <input id="description" type="text" placeholder="Library Name" formControlName="description"
                                required />
                            <label for="description" class="text-sm">Description</label>
                            <span *ngIf="
                                    this.fAddLibraryForm?.controls['description']?.hasError('required') &&
                                    fAddLibraryForm?.controls['description']?.touched
                                " class="error">Please enter your description.</span>
                        </div>
                    </form>
                </div>
                <div class="side-footer mt-3">
                    <button class="btn btn-primary btn-round-6 d-inline-block small-pad px-4 closeSidepanel"
                        [disabled]="!this.fAddLibraryForm.valid" (click)="addMediaLib()">
                        Create Library
                    </button>
                    <!-- <button class="d-inline-block small-pad text-gray-2 font-weight-noral text-md ms-3">
            Deactivate User
        </button> -->
                </div>
            </nav>
        </ng-container>
    </nz-drawer>
    <!-- Add library sidbar end -->

    <!-- data filter  sidebar Start-->

    <nz-drawer [nzClosable]="false" [nzVisible]="visibleFilter" [nzPlacement]="placementFilter"
        (nzOnClose)="closeFilter()" [nzWidth]="440">
        <ng-container *nzDrawerContent>
            <nav class="rightSideBar sidebar-block" style="right: 0">
                <div class="sidebar-header">
                    <h3 class="text-gray-2 weight-700 d-flex align-items-center">
                        Filters
                        <a href="javascript:void(0)" class="text-xs text-primary-4 weight-normal ms-3"
                            (click)="clearAll()">Clear All</a>
                    </h3>
                    <div class="closeRightSideBar" (click)="closeFilter()">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M14.1971 0H5.8129C2.17108 0 0 2.17 0 5.81V14.18C0 17.83 2.17108 20 5.8129 20H14.1871C17.8289 20 20 17.83 20 14.19V5.81C20.01 2.17 17.8389 0 14.1971 0Z"
                                fill="#F2F2F5" />
                            <path
                                d="M11.1852 9.9986L13.7568 7.42787C14.0811 7.10374 14.0811 6.56724 13.7568 6.2431C13.4326 5.91897 12.8959 5.91897 12.5716 6.2431L10 8.81383L7.42837 6.2431C7.10412 5.91897 6.56744 5.91897 6.24319 6.2431C5.91894 6.56724 5.91894 7.10374 6.24319 7.42787L8.81481 9.9986L6.24319 12.5693C5.91894 12.8935 5.91894 13.43 6.24319 13.7541C6.4109 13.9218 6.62334 14 6.83578 14C7.04822 14 7.26066 13.9218 7.42837 13.7541L10 11.1834L12.5716 13.7541C12.7393 13.9218 12.9518 14 13.1642 14C13.3767 14 13.5891 13.9218 13.7568 13.7541C14.0811 13.43 14.0811 12.8935 13.7568 12.5693L11.1852 9.9986Z"
                                fill="#A7ABB6" />
                        </svg>
                    </div>
                </div>
                <div class="sidebar-content pt-0 flex-grow-1 overflow-auto" style="height: 160px">
                    <div class="custom-select-box mb-4">
                        <h3 class="text-gray-2 text-lg mb-2">Library Status</h3>
                        <div class="custom-switch">
                            <label>
                                <input type="checkbox" (change)="checkValueActive(isActive)" [(ngModel)]="isActive"
                                    class="check form-check-input" />
                                Active
                            </label>
                        </div>
                        <div class="custom-switch">
                            <label>
                                <input type="checkbox" (change)="checkValueDeactive(isDeactive)"
                                    [(ngModel)]="isDeactive" class="check form-check-input" />
                                Inactive
                            </label>
                        </div>
                    </div>
                </div>
                <div class="side-footer side-content d-flex align-items-center">
                    <button (click)="apply()"
                        class="btn btn-primary btn-round-6 d-inline-block small-pad px-4">Apply</button>
                </div>
            </nav>
        </ng-container>
    </nz-drawer>
    <!-- data filter sidebar end-->

    <!-- Modal pop-up Start-->
    <nz-modal [(nzVisible)]="isVisible" nzCentered [nzWidth]="566" [nzFooter]="null" [nzClosable]="false"
        (nzOnCancel)="handleCancel()">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="btn-close cust-close closeRightBtn" (click)="handleCancel()"></button>

                    <h5>Are you sure you want to deactivate this media library?</h5>
                    <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">You'll not be able to watch this Library again.</p>
                    <a href="javascript:void(0)"
                        class="text-sm weight-normal btn btn-secondary btn-round-6 cust-pad me-4"
                        (click)="handleCancel()">Cancel
                    </a>
                    <a class="mt-3 text-gray-3 text-sm" (click)="updateMediaLibrary(mediaObj)">Deactivate</a>
                </div>
            </div>
        </div>
    </nz-modal>
</div>