import { ProntoSettingService } from 'src/app/services/utils/pronto-setting.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigManagerService } from './../../Managers/config-manager.service';
import { OnboardingManagerService } from './../../Managers/onboarding-manager.service';
import { environment } from './../../../environments/environment';
import { cSessionService } from 'src/app/services/session.service';

@Component({
    selector: 'app-myvideos-sidebar',
    templateUrl: './myvideos-sidebar.component.html',
    styleUrls: ['./myvideos-sidebar.component.scss'],
})
export class MyvideosSidebarComponent implements OnInit, OnDestroy {
    showMenu: string;
    bLocales: boolean;

    constructor(
        private oRouter: Router,
        public configManger: ConfigManagerService,
        public onboarding: OnboardingManagerService,
        public oSessionService: cSessionService,
        public pronto: ProntoSettingService
    ) {
        this.showMenu = this.oRouter.url;
    }

    ngOnInit(): void {
        this.MyVideosComponent_LocalesCheck();
    }
    ngOnDestroy() {
        this.oSessionService.isSidebarActive = true;
    }
    MyVideosComponent_LocalesCheck() {
        this.bLocales = this.onboarding.profile.localization_enabled && this.onboarding.profile.plan == 'enterprise';
    }

    toggleSideBarNew() {
        var sidebarleft = document.getElementById('sidebar-left');
        sidebarleft.classList.toggle('collapsed');

        if (this.oSessionService.isSidebarActive) {
            this.oSessionService.isSidebarActive = false;
        } else {
            this.oSessionService.isSidebarActive = true;
        }
        let __this = this;
        setTimeout( ()=>{
          __this.pronto.videoDataView.next({ data: this.oSessionService.dashboardVideoListView });
        }, 300)

        console.log('isActive2', this.oSessionService.isSidebarActive);
    }
}
