<header class="header header-1 drop-shadow" [ngClass]="bFullHeader?'':'width-100'">
    <div class="container">
        <nav class="navbar navbar-expand-lg">
            <a routerLink="/dashboard/myvideos">
                <img width="166" src="../assets/images/logo-mobile.svg" alt="logo-mobile" />
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mainNav"
                aria-controls="mainNav" aria-expanded="false" aria-label="Toggle navigation"> <span
                    class="text-md">Menu</span>
            </button>
            <div class="collapse navbar-collapse " id="mainNav">

                <div class="d-flex justify-content-end align-items-center w-100">
                    <ul *ngIf="bDisplayHeaderOptions" class="navbar-nav main-nav mx-auto mb-2 mb-lg-0">

                        <li class="nav-item" [class.active]="showMenu==='/dashboard/myvideos' || showMenu==='/dashboard/scheduled-videos'">
                            <a class="nav-link" href="javascript:void(0)" (click)="NavigateToDashboard()">Dashboard</a>
                        </li>
                        <li class="nav-item" [class.active]="showMenu==='/my-videos/publishedVideo' || showMenu==='/my-videos/draftsVideo' || showMenu==='/my-videos/scheduledVideos'">
                            <a class="nav-link" routerLink="/my-videos">My Videos</a>
                        </li>
                        <li class="nav-item d-none" [class.active]="showMenu==='/reports/reach' || showMenu==='/reports/engagement' || showMenu==='/reports/audience'">
                            <a class="nav-link"  >Reports</a>
                        </li>
                    </ul>
                    <ul class="navbar-nav mb-2 mb-lg-0 pt-0 divader-left">
                        <li *ngIf="bDisplayUploadIcon" class="uploadicon">
                            <a href="javascript:void(0)" data-sidebar="#uploadmedia">
                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g  >
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M8.47648 9.27169C8.47641 9.54422 8.25514 9.76508 7.98227 9.76499C7.70939 9.76491 7.48824 9.54391 7.48831 9.27138L7.4902 1.90131L4.38519 5.31243C4.20163 5.51408 3.90403 5.51408 3.72047 5.31243C3.53691 5.11077 3.53691 4.78383 3.72047 4.58217L7.65867 0.255732C7.84222 0.054079 8.13983 0.054079 8.32339 0.255732L12.2616 4.58217C12.4451 4.78383 12.4451 5.11077 12.2616 5.31242C12.078 5.51408 11.7804 5.51408 11.5969 5.31242L8.47837 1.8865L8.47648 9.27169ZM0.598577 6.71753C0.871452 6.71753 1.09266 6.93846 1.09266 7.21098V8.44452C1.09266 11.9874 3.96839 14.8594 7.51577 14.8594H8.50591C12.0533 14.8594 14.929 11.9874 14.929 8.44452V7.21098C14.929 6.93846 15.1502 6.71753 15.4231 6.71753C15.696 6.71753 15.9172 6.93846 15.9172 7.21098V8.44452C15.9172 12.5324 12.599 15.8463 8.50591 15.8463H7.51577C3.42264 15.8463 0.104492 12.5324 0.104492 8.44452V7.21098C0.104492 6.93846 0.325701 6.71753 0.598577 6.71753Z"
                                            fill="#4F4F4F"></path>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0">
                                            <rect width="16.0013" height="15.9809" fill="white"></rect>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </a>
                        </li>

                        <li class="divader-left nav-item dropdown">
                            <a class="nav-link active text-sm text-gray-2 side_class ps-4" href="javascript:void(0)" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <!-- <img src="../../../assets/images/accountSetting/profile-sample2.jpeg" alt="ac-login-img" class="me-2" height="26px" width="26px"/> -->

                            <img *ngIf="!(this.oSessionService.userProfileLogoPath != undefined && this.oSessionService.userProfileLogoPath != '')" src="../../../assets/images/accountSetting/profile-sample2.jpeg" alt="ac-login-img" class="me-2" height="26px" width="26px"/>

                            <div class="logo-bg" *ngIf="this.oSessionService.userProfileLogoPath != undefined && this.oSessionService.userProfileLogoPath != ''"  [ngStyle]="{'background-image': 'url(&quot;' +  this.oSessionService.userProfileLogoPath + '&quot;)'}"> </div>
                                My Account
                                <svg class="ms-1" width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.509553 0.0874255C0.392986 -0.0291418 0.203993 -0.0291418 0.0874254 0.0874255C-0.0291418 0.203993 -0.0291418 0.392986 0.0874254 0.509553L3.1346 3.55672C3.61255 4.03467 4.38745 4.03467 4.8654 3.55672L7.91257 0.509553C8.02914 0.392986 8.02914 0.203993 7.91257 0.0874254C7.79601 -0.029142 7.60701 -0.0291416 7.49045 0.0874256L4.44328 3.1346C4.19846 3.37941 3.80154 3.37941 3.55672 3.1346L0.509553 0.0874255Z" fill="#7A7A7A"/>
                                    </svg>
                                    </a>
                                   <ul class="dropdown-menu custom-dropdown" aria-labelledby="navbarDropdown">
                                    <!-- <li><a class="dropdown-item" href="javascript:void(0)" (click)="NavigateToDashboard()">Dashboard</a></li> -->
                                    <li><a class="dropdown-item" [routerLink]="['/account-setting']">Account Settings</a></li>
                                 <!--   <li><a class="dropdown-item d-none" href="javascript:void(0)" (click)="NavigateToBusinessProfile()">Business Profile</a></li>-->
                                    <li><a class="dropdown-item" href="javascript:void(0)" (click)="LogOut()">Logout</a></li>
                                </ul>
                            </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</header>



<!-- <nav id="uploadmedia" class="rightSideBar right-0 sidebar-block">
    <div class="sidebar-header">
        <h6 class="text-gray-2 weight-700">Uploading 6/7 items</h6>
        <a href="#" class="del-media" data-bs-toggle="modal" data-bs-target="#delMedia-popup">
            <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M14.9809 7.60102C15.214 7.83249 15.592 7.83249 15.8251 7.60102C16.0583 7.36954 16.0583 6.99425 15.8251 6.76277L9.73081 0.711821C8.77491 -0.237272 7.22509 -0.237275 6.26919 0.71182L0.174851 6.76277C-0.0582836 6.99425 -0.0582836 7.36954 0.174851 7.60102C0.407986 7.83249 0.785971 7.83249 1.01911 7.60102L7.11345 1.55006C7.60308 1.06392 8.39692 1.06392 8.88655 1.55007L14.9809 7.60102Z"
                    fill="#7A7A7A" />
            </svg>
        </a>
        <div class="closeRightSideBar">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.409957 11.8C0.304968 11.8 0.204978 11.76 0.124987 11.685C-0.0299968 11.53 -0.0299968 11.275 0.124987 11.12L5.33943 5.9L0.114988 0.68C0.0399958 0.605 0 0.505 0 0.4C0 0.295 0.0399958 0.195 0.114988 0.12C0.194979 0.04 0.294969 0 0.399958 0C0.504947 0 0.604936 0.04 0.684927 0.115L5.90437 5.335L11.1188 0.115C11.1938 0.04 11.2938 0 11.3988 0C11.5038 0 11.6038 0.04 11.6838 0.115C11.8387 0.27 11.8387 0.525 11.6838 0.68L6.46931 5.9L11.6788 11.11C11.7538 11.185 11.7938 11.285 11.7938 11.39C11.7938 11.495 11.7538 11.595 11.6788 11.675C11.6038 11.755 11.5038 11.79 11.3938 11.79C11.2838 11.79 11.1888 11.75 11.1088 11.675L5.90437 6.465L0.689927 11.685C0.614935 11.76 0.514945 11.8 0.409957 11.8Z"
                        fill="#7A7A7A" />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="11.8" height="11.8" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    </div>

    <div class="sidebar-content">
        <div class="up-media">
            <div class="media-type">
                <img src="../assets/images/business_Profile/upload-img.png" alt="1">
            </div>
            <p class="media-title">Customer_success.png</p>
            <span class="file-size">21kb</span>
            <button class="btn-success btn"><svg width="10" height="8" viewBox="0 0 10 8" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M8.80667 0.854745C9.08816 0.595654 9.52906 0.605007 9.79875 0.877757C10.0767 1.15884 10.0649 1.60943 9.7749 1.87635L4.05049 7.14517C3.77781 7.39615 3.35428 7.39598 3.08181 7.14475L0.224653 4.51034C-0.0651365 4.24314 -0.0764627 3.79254 0.201696 3.51172C0.471612 3.23923 0.912515 3.23022 1.19379 3.48957L3.56681 5.67758L8.80667 0.854745ZM0.789687 3.89782L0.789544 3.89769L0.789687 3.89782Z"
                        fill="white" />
                </svg>
            </button>
        </div>
        <div class="up-media">
            <div class="media-type">
                <img src="../assets/images/business_Profile/upload-img.png" alt="1">
            </div>
            <p class="media-title">Customer_success.png</p>
            <span class="file-size">21kb</span>
            <button class="btn-success btn "><svg width="10" height="8" viewBox="0 0 10 8" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M8.80667 0.854745C9.08816 0.595654 9.52906 0.605007 9.79875 0.877757C10.0767 1.15884 10.0649 1.60943 9.7749 1.87635L4.05049 7.14517C3.77781 7.39615 3.35428 7.39598 3.08181 7.14475L0.224653 4.51034C-0.0651365 4.24314 -0.0764627 3.79254 0.201696 3.51172C0.471612 3.23923 0.912515 3.23022 1.19379 3.48957L3.56681 5.67758L8.80667 0.854745ZM0.789687 3.89782L0.789544 3.89769L0.789687 3.89782Z"
                        fill="white" />
                </svg>
            </button>
        </div>
        <div class="up-media">
            <div class="media-type">
                <img src="../assets/images/business_Profile/upload-img.png" alt="1">
            </div>
            <p class="media-title">Customer_success.png</p>
            <span class="file-size">21kb</span>
            <button class="btn-success btn"><svg width="10" height="8" viewBox="0 0 10 8" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M8.80667 0.854745C9.08816 0.595654 9.52906 0.605007 9.79875 0.877757C10.0767 1.15884 10.0649 1.60943 9.7749 1.87635L4.05049 7.14517C3.77781 7.39615 3.35428 7.39598 3.08181 7.14475L0.224653 4.51034C-0.0651365 4.24314 -0.0764627 3.79254 0.201696 3.51172C0.471612 3.23923 0.912515 3.23022 1.19379 3.48957L3.56681 5.67758L8.80667 0.854745ZM0.789687 3.89782L0.789544 3.89769L0.789687 3.89782Z"
                        fill="white" />
                </svg>
            </button>
        </div>
        <div class="up-media">
            <div class="media-type">
                <img src="../assets/images/business_Profile/upload-img.png" alt="1">
            </div>
            <p class="media-title">Customer_success.png</p>
            <span class="file-size">21kb</span>
            <button class="btn-danger btn"><svg width="10" height="10" viewBox="0 0 10 10" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.34163 9.83333C0.25414 9.83333 0.170815 9.8 0.104156 9.7375C-0.0249974 9.60833 -0.0249974 9.39583 0.104156 9.26667L4.44953 4.91667L0.0958232 0.566667C0.0333298 0.504167 0 0.420833 0 0.333333C0 0.245833 0.0333298 0.1625 0.0958232 0.1C0.162483 0.0333333 0.245807 0 0.333298 0C0.420789 0 0.504113 0.0333333 0.570773 0.0958333L4.92031 4.44583L9.26569 0.0958333C9.32818 0.0333333 9.4115 0 9.49899 0C9.58648 0 9.66981 0.0333333 9.73647 0.0958333C9.86562 0.225 9.86562 0.4375 9.73647 0.566667L5.3911 4.91667L9.7323 9.25833C9.7948 9.32083 9.82813 9.40417 9.82813 9.49167C9.82813 9.57917 9.7948 9.6625 9.7323 9.72917C9.66981 9.79583 9.58648 9.825 9.49483 9.825C9.40317 9.825 9.32401 9.79167 9.25735 9.72917L4.92031 5.3875L0.574939 9.7375C0.512446 9.8 0.429121 9.83333 0.34163 9.83333Z"
                        fill="white" />
                </svg>
            </button>
        </div>

        <div class="up-media">
            <div class="media-type">
                <img src="../assets/images/business_Profile/upload-img.png" alt="1">
            </div>
            <p class="media-title">Customer_success.png</p>
            <span class="file-size">21kb</span>
            <button class="btn-processing btn">
            </button>
        </div>

        <div class="up-media">
            <div class="media-type">
                <img src="../assets/images/business_Profile/upload-img.png" alt="1">
            </div>
            <p class="media-title">Customer_success.png</p>
            <span class="file-size">21kb</span>
            <button class="btn-processing btn">
            </button>
        </div>

        <div class="up-media">
            <div class="media-type">
                <img src="../assets/images/business_Profile/upload-img.png" alt="1">
            </div>
            <p class="media-title">Customer_success.png</p>
            <span class="file-size">21kb</span>
            <button class="btn-danger btn"><svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M9.6752 9.89998C9.6752 10.269 9.3692 10.575 9.0002 10.575C8.6312 10.575 8.3252 10.269 8.3252 9.89998V5.39998C8.3252 5.03098 8.6312 4.72498 9.0002 4.72498C9.3692 4.72498 9.6752 5.03098 9.6752 5.39998V9.89998ZM9.6391 11.961C9.7201 12.051 9.7831 12.15 9.8281 12.258C9.8731 12.366 9.9001 12.483 9.9001 12.6C9.9001 12.717 9.8731 12.834 9.8281 12.942C9.7831 13.059 9.7201 13.149 9.6391 13.239C9.5491 13.32 9.4501 13.383 9.3421 13.428C9.2341 13.473 9.1171 13.5 9.0001 13.5C8.8831 13.5 8.7661 13.473 8.6581 13.428C8.5501 13.383 8.4511 13.32 8.3611 13.239C8.2801 13.149 8.2171 13.059 8.1721 12.942C8.1271 12.834 8.1001 12.717 8.1001 12.6C8.1001 12.483 8.1271 12.366 8.1721 12.258C8.2171 12.15 8.2801 12.051 8.3611 11.961C8.4511 11.88 8.5501 11.817 8.6581 11.772C8.8741 11.682 9.1261 11.682 9.3421 11.772C9.4501 11.817 9.5491 11.88 9.6391 11.961Z"
                        fill="white" />
                </svg>
            </button>
        </div>

        <div class="up-media">
            <div class="media-type">
                <img src="../assets/images/business_Profile/upload-img.png" alt="1">
            </div>
            <p class="media-title">Customer_success.png</p>
            <span class="file-size">21kb</span>
            <button class="btn-danger btn"><svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M9.6752 9.89998C9.6752 10.269 9.3692 10.575 9.0002 10.575C8.6312 10.575 8.3252 10.269 8.3252 9.89998V5.39998C8.3252 5.03098 8.6312 4.72498 9.0002 4.72498C9.3692 4.72498 9.6752 5.03098 9.6752 5.39998V9.89998ZM9.6391 11.961C9.7201 12.051 9.7831 12.15 9.8281 12.258C9.8731 12.366 9.9001 12.483 9.9001 12.6C9.9001 12.717 9.8731 12.834 9.8281 12.942C9.7831 13.059 9.7201 13.149 9.6391 13.239C9.5491 13.32 9.4501 13.383 9.3421 13.428C9.2341 13.473 9.1171 13.5 9.0001 13.5C8.8831 13.5 8.7661 13.473 8.6581 13.428C8.5501 13.383 8.4511 13.32 8.3611 13.239C8.2801 13.149 8.2171 13.059 8.1721 12.942C8.1271 12.834 8.1001 12.717 8.1001 12.6C8.1001 12.483 8.1271 12.366 8.1721 12.258C8.2171 12.15 8.2801 12.051 8.3611 11.961C8.4511 11.88 8.5501 11.817 8.6581 11.772C8.8741 11.682 9.1261 11.682 9.3421 11.772C9.4501 11.817 9.5491 11.88 9.6391 11.961Z"
                        fill="white" />
                </svg>
            </button>
        </div>

    </div>
    <ul class="sideNav-btn px-3">
        <a href="" class="weight-normal text-primary-4 text-sm py-3">Cancel Upload</a>
    </ul>
</nav> -->