import { SuccessMessageModalService } from "src/app/services/success-message-modal.service";
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MediaManagerService } from "src/app/Managers/media-manager.service";
import { visual } from "src/app/models/admin";
import { mediaFile } from "src/app/models/media";
import { ErrorService } from "src/app/services/error.service";
import { cSessionService } from "src/app/services/session.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-upload-file-dialog",
  templateUrl: "./upload-file-dialog.component.html",
  styleUrls: ["./upload-file-dialog.component.scss"],
})
export class UploadFileDialogComponent implements OnInit {
  @ViewChild("videoValidator") videoValidator: ElementRef;
  @ViewChild("file") fileField: ElementRef;
  nFileNumber = 0;
  sFileName = "";
  oFiletoUpload: any;
  sSessionId = "";
  sUserEmail = "";
  duration = 0;
  size = 0;
  accept = "image/*";
  multipleUpload = false;
  uploadFor = "image";
  heading = "Upload File";
  dropBoxText = "";
  displaySuccessMessage = false;
  mediatypes: { image; video; audio; image_video_logo, image_video, image_all, all};
  uploadIntoMediaLibrary = false;
  selectedMediaLibrary = undefined;
  allowDuplicate = false;
  component = '';
  videoSizeLimit = true;
  acceptTypes = {
    image_all: "image/*",
    image: "image/*",
    image_video: "image/png, image/jpeg, image/jpg, video/mp3,video/mp4,video/avi,video/mpeg, video/mov, video/quicktime",
    image_video_logo: "image/png, image/jpeg, image/jpg",
    video: "video/mp3,video/mp4,video/avi,video/mpeg, video/mov, video/*, video/quicktime",
    video_own: "video/mp4, video/avi, video/mpeg, video/mov, video/*, video/quicktime",
    video_restricted: "video/mp4, video/mpeg, video/mov, video/quicktime",
    audio: "audio/mp3",
    all: "*/*",
  };


  errorTypeNotAllowed = {
    image_all: "You can only upload image file.",
    image: "You can only upload image file.",
    image_video: "We're sorry, the file you're trying to upload is not a supported format. Please upload an file (MP3, MP4, PNG, JPEG, MPG, AVI, MOV) to continue.",
    image_video_logo: "Oops! Looks like the logo you uploaded isn't in the right format. Please upload a PNG or JPG file to use as your logo.",
    video: "We're sorry, the file you're trying to upload is not a supported video format. Please upload a video file (MP4, MPG, AVI, MOV) to continue.",
    video_own: "We're sorry, the file you're trying to upload is not a supported video format. Please upload a video file (MP4, MPG, AVI, MOV) to continue.",
    video_restricted: "We're sorry, the file you're trying to upload is not a supported video format. Please upload a video file (MP4, MPG,  MOV) to continue.",
    audio: "We're sorry, the file you're trying to upload is not a supported audio format. Please upload an audio file (MP3) to continue.",
    invalid: "We're sorry, the file you're trying to upload is not a supported format. Please upload an file (MP3, MP4, PNG, JPEG, MPG, AVI, MOV) to continue.",
    all: "We're sorry, the file you're trying to upload is not a supported format. Please upload an file (MP3, MP4, PNG, JPEG, MOV) to continue.",
    // all: "You can only upload media file.",
  };
  errorTypeNotAllowedHeaders = {
    image_all: "Upload File Failed",
    image: "Upload File Failed",
    image_video: "Invalid File Type",
    image_video_logo: "Logo Format Error",
    video: "Invalid File Type",
    video_own: "Invalid File Type",
    video_restricted: "Invalid File Type",
    audio: "Invalid File Type",
    all : "Invalid File Type",
    invalid : "Invalid File Type"
  };

  allowedMediaTyps = ["image/jpg", "image/jpeg", "image/png", "audio/mp3", "audio/mpeg", "video/mp4", "video/mov", "video/avi","video/*","video/quicktime"];
  VideoManagerService: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private oSessionService: cSessionService,
    private dialogRef: MatDialogRef<UploadFileDialogComponent>,
    public mediaService: MediaManagerService,
    private errorMessage: ErrorService,
    public successMessage: SuccessMessageModalService,
    private cd: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) {
    this.sSessionId = this.oSessionService.SessionService_GetSessionId() ? this.oSessionService.SessionService_GetSessionId() : this.oSessionService.cSessionService_GetGuestSession();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail() ? this.oSessionService.cSessionService_GetUserEmail() : this.oSessionService.cSessionService_GetGuestEmail();
  }

  ngOnInit(): void {
    // this.openDialog();
    this.cd.detectChanges();
    if (this.data) {
      if (this.data.uploadFor) {
        this.uploadFor = this.data.uploadFor ? this.data.uploadFor : "image";
        this.accept = this.acceptTypes[this.data.uploadFor]
          ? this.acceptTypes[this.data.uploadFor]
          : "image/*";

          if(this.data.uploadFor == 'all'){
            this.uploadIntoMediaLibrary = true;
          }
          console.log(" Accept ", this.accept);
      }

      // console.log(" data here ", this.data);
      if(this.data.videoSizeLimit != undefined){
        this.videoSizeLimit = this.data.videoSizeLimit;
        // console.log(' set value ovideoSizeLimitf ', this.videoSizeLimit);
      }
      if (this.data.multipleUpload) {
        this.multipleUpload = this.data.multipleUpload
          ? this.data.multipleUpload
          : false;

          if(this.data.multipleUpload && this.data.multipleUpload == true ){
            this.fileField.nativeElement.setAttribute('multiple' , "multiple");
          }
      }


      if (this.data.heading) {
        this.heading = this.data.heading ? this.data.heading : false;
      }
      if (this.data.component) {
        this.component = this.data.component ? this.data.component : '';
      }
      if (this.data.allowDuplicate) {
        this.allowDuplicate = this.data.allowDuplicate ? this.data.allowDuplicate : false;
      }
      if (this.data.mediaLibraryId) {
        this.selectedMediaLibrary = this.data.mediaLibraryId ? this.data.mediaLibraryId : undefined;
      }

      if (this.data.dropBoxText) {
        this.dropBoxText = this.data.dropBoxText
          ? this.data.dropBoxText
          : false;
      }
    }
  }

  uploadFile($event) {}

  save() {
    this.dialogRef.close(" test here ");
  }

  close() {
    // console.log("Close modal..");
    this.clearModals();
    this.dialogRef.close();
  }

  initiateUploadProcess($event, section = "main") {
    // console.log("inti upload process called..");
    if (section == "dragdrop") {
      this.startUploadProcess($event);
    } else {
      this.startUploadProcess($event.target.files);
    }
  }

  startUploadProcess(files: any) {
    this.oFiletoUpload = files;
    {
      if (this.validateUploadFileType(files)) {
        this.successMessage.displayMessage(
          "Uploading file, please wait",
          "File upload",
          true,
          false
        );
        if (this.uploadFor == "video_own") {
          this.hasSound(files)
            .then((res) => {
              // console.log('Voice check ...',res);
              if (res.status == "success") {
                console.log("Upload start ...");
                this.uploadStart(files);
              }
            })
            .catch((err) => {
              this.clearModals();
              if(err.message){
                this.errorMessage.handleError(
                  "Video duration should be between 15 seconds and 2 minutes. Please upload a video with a duration within this range to continue.",
                  "Invalid Video Duration",
                  true
                );
              } else {
                this.errorMessage.handleError(
                  "Video does not have sound.",
                  "Upload Failed",
                  true,
                  false,
                  false
                );
              }

            });
        } else {
          this.uploadStart(files);
        }
      } else {

        this.close();
        this.fileField.nativeElement.value = "";
      }
    }
  }

  errorHandlerBody(error: any) {

  }

  clearModals() {
    this.successMessage.close();
    this.clearFileField();
    this.cd.detectChanges();
  }

  async hasSound(files): Promise<any> {
    let videoSizeLimit = this.videoSizeLimit;
    return new Promise<any>((resolve, reject) => {
      if(!videoSizeLimit){
        resolve({ status: "success" });
      }
      try {
        var videoTag = document.getElementById("myVideo");
        const __this = this;
        if(files[0].type.indexOf("avi") > - 1 ){
          resolve({ status: "success" });
        }

        if (files && files[0]) {
          var reader = new FileReader();
          var objectURL = URL.createObjectURL(files[0]);
          // objectURL
          try {
            reader.onload = function (e) {

              // __this.videoValidator.nativeElement.src = e.target.result;
              __this.videoValidator.nativeElement.src = objectURL;
              // console.log(" FILE LOADED ....");
              console.log( " -- ", e.target);


              __this.videoValidator.nativeElement.onloadedmetadata = function (
                eventLoad
              ) {
                const totalsec = __this.videoValidator.nativeElement.duration;
                console.log( "  videoSizeLimit ",  videoSizeLimit );
                if (!(totalsec >= 16 && totalsec <= 120) && videoSizeLimit) {
                  reject({ status: "error", message : "duration" });
                }
                setTimeout(function () {
                  try {
                    if (
                      eventLoad.target.webkitAudioDecodedByteCount != undefined
                    ) {
                      if (eventLoad.target.webkitAudioDecodedByteCount === 0) {
                        reject({ status: "error" });
                        this.clearModals();
                      } else {
                        resolve({ status: "success" });
                      }
                    }
                    if (
                      eventLoad.target.audioTracks != undefined &&
                      eventLoad.target.audioTracks.length > 0
                    ) {
                      if (eventLoad.target.audioTracks[0].id === 0) {
                        this.clearModals();
                        reject({ status: "error" });
                      } else {
                        resolve({ status: "success" });
                      }
                    }
                  } catch (e) {
                    resolve({ status: "error", e});
                  }
                }, 1500);
              };
            }.bind(this);
          } catch (err) {
            this.clearModals();
            reject({ status: "error", err });
          }
          reader.readAsDataURL(files[0]);
        }
      } catch (err) {
        reject({ status: "error", err });
      }
    });
  }

  clearFileField() {
    this.fileField.nativeElement.value = "";
  }

  visualMediaLoadingComplete(index: number) {
    this.mediaService.visuals[index].loadingThumbnail = false;
  }
  async uploadStart(fileToUpload: any) {
    console.log(" Upload start here ...");
    if(this.uploadIntoMediaLibrary){
      Array.from(fileToUpload).forEach((element: any) => {
        // console.log('Start upload file ...');
        let media = new mediaFile();
        media.file = element;
        media.name = element.name;
        media.status = 'uploading';
        media.uri = this.sanitizer.bypassSecurityTrustUrl(
          URL.createObjectURL(media.file)
        );
        const indexFile = this.mediaService.uploadMediaList.length;
        this.mediaService.uploadMediaList.push(media);

        this.mediaService
        .uploadMediaDefaultLibrary(this.sUserEmail, this.sSessionId, media, this.selectedMediaLibrary)
        .then((res: visual) => {

          this.mediaService.uploadMediaList[indexFile].status = "uploaded";
          this.mediaService.uploadMediaList[indexFile].loadingThumbnail = false;
          res.loadingThumbnail = false;
          res.fpath = res.visual_path;
          res.status = "uploaded";

          this.mediaService.visuals.push(res);
          this.cd.detectChanges()
        })
        .catch((error) => {
          this.generateErrorMessage(error);
         this.mediaService.uploadMediaList[indexFile].status = "error";
        });
      });

      this.dialogRef.close({
        result: ({status : "success"}),
        files: this.oFiletoUpload,
        status: "success",
      });
      this.clearModals();

    } else {
      // Upload Single file ...
      let media = new mediaFile();
      media.file = fileToUpload[0];
      media.name = fileToUpload[0].name;
      this.mediaService
        .uploadMediaDefaultLibrary(this.sUserEmail, this.sSessionId, media, this.selectedMediaLibrary)
        .then((res: visual) => {
          this.dialogRef.close({
            result: [res],
            files: this.oFiletoUpload,
            status: "success",
          });
          this.clearModals();
        })
        .catch((error) => {
          this.clearModals();
          this.generateErrorMessage(error);
          this.dialogRef.close({ files: undefined, status: "error", error });
        });
    }
  }

  generateErrorMessage(error: any) {
    let msgErr = error.errorMessage ? error.errorMessage : error.error;
    let isDuplicateIssue = false;
    let titleErrorBox = "File Upload Failed";
    let autoClose = true;
    if(msgErr && msgErr.indexOf("already exist") > - 1 && this.allowDuplicate){
      isDuplicateIssue = true;
      titleErrorBox = "Duplicate File Error";
      autoClose = false;
      if(this.uploadFor){
        let typeMediaList = (this.uploadFor.indexOf("image") > -1) ? "image" : (this.uploadFor.indexOf("video") > - 1 )  ? "clip" : "audio";
        this.mediaService.replaceMediaTypeFromVisualPopup = {type: typeMediaList, data : [], component : this.component};
      }
    }

    this.errorMessage.handleError(
      error.errorMessage ? error.errorMessage : error.error,
      titleErrorBox,
      true,
      autoClose,
      false,
      isDuplicateIssue
    );
  }

  showError() {
    this.clearModals();
    const errorText = this.errorTypeNotAllowed[this.uploadFor]
      ? this.errorTypeNotAllowed[this.uploadFor]
      : "Upload failed due to wrong file type.";
    const errorTitle = this.errorTypeNotAllowedHeaders[this.uploadFor]
      ? this.errorTypeNotAllowedHeaders[this.uploadFor]
      : "Upload File Failed.";

    if (errorText) {
      this.errorMessage.handleError(
        errorText,
        errorTitle,
        true,
        false,
        false
      );
    }
    this.clearModals();
  }

  validateUploadFileType(files): boolean {
    let isAllowed = true;
    let loopIndex = 0;
    console.log(this.uploadFor, files[0]);

    Array.from(files).forEach((element: any) => {
      console.log(" - :::- ", this.uploadFor == "video_restricted", (element.type.indexOf("avi") > -1 || element.type.indexOf("quicktime") > -1 ))
      // console.log(element);
      if (this.uploadFor == "image" && element.type.indexOf("image") == -1) {
        isAllowed = false;
      }
      if (
        this.uploadFor == "image_video_logo" &&
        !(element.type.indexOf("png") > -1
        || element.type.indexOf("jpg") > -1
        || element.type.indexOf("jpeg") > -1 )
      ) {
        isAllowed = false;
      }
      if (
        this.uploadFor == "video_own" &&
        !( element.type.indexOf("mp4") > -1
        // || element.type.indexOf("avi") > -1
        // || element.type.indexOf("mpeg") > -1
        // || element.type.indexOf("mpg") > -1
        || element.type.indexOf("quicktime") > -1
        || element.type.indexOf("mov") > -1)
      ) {
        isAllowed = false;
      }
      if ( this.uploadFor == "video_restricted" && (element.type.indexOf("avi") > -1 )){
        isAllowed = false;
      }
      if ( this.uploadFor == "all" && (element.type.indexOf("avi") > -1 )){
        isAllowed = false;
      }

      if (
        (this.uploadFor == "video" || this.uploadFor == "video-own") &&
        element.type.indexOf("video") == -1
      ) {
        isAllowed = false;
      }

      if (this.uploadFor == "audio" && element.type.indexOf("audio/") == -1) {
        isAllowed = false;
      }
      if (this.uploadFor == "image_video"
       && !(element.type.indexOf("image/") > -1
       || element.type.indexOf("video/") > -1
       || element.type.indexOf("mov") > -1
       || element.type.indexOf("quicktime") > -1
      //  || element.type.indexOf("mpeg") > -1
      //   || element.type.indexOf("mpg") > -1
      //  || element.type.indexOf("avi") > -1
       )) {
        isAllowed = false;
      }
      if (this.uploadFor == "all") {

        var isfound = this.mediaService.visuals.some((c: any) => {
          if(c.name == element.name){
            isfound = true;
          }
        });


        if(!isfound){
          if(this.allowedMediaTyps.indexOf(element.type) ==  -1){
            isAllowed = false;
          }
        } else {
          isAllowed = false;
          this.errorMessage.handleError(
            "File with this name already exists in current Library",
            "Upload File Failed",
            true,
            false,
            false
          );
        }
      }
      loopIndex++;
    });

    if (!isAllowed) this.showError();

    return isAllowed;
  }
}
