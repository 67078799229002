import {
  AfterViewInit, Component, ElementRef, OnDestroy, OnChanges, OnInit, HostListener, ViewChild,
  SimpleChanges, ChangeDetectorRef
} from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { block_config, content, video, sap_config, overlayclip, overlayTexts } from '../../../models/video';
import { NgWaveformComponent } from 'ng-waveform';
import { Subject, Subscription } from 'rxjs';
import { block_clips, clip } from '../../../models/video';
import { cSessionService } from 'src/app/services/session.service';
import { isTemplateRef } from 'ng-zorro-antd/core/util';
import { ModifyVideoManagerService } from '../../../Managers/modify-video-manager.service';
import { CreateVideoManagerService } from 'src/app/Managers/create-video-manager.service';
import { Router } from '@angular/router';
import { VideoService } from 'src/app/services/dataServices/video.service';
import { ConditionalExpr } from '@angular/compiler';
import { BrandService } from '../../myAccount/manage-brand/service/BrandService';
import { brand } from 'src/app/models/admin';


export class item {
  ts: number[] = [];
  width: number = 0;
  left: number = 0;
  clip: string;
  text: string[] = [];
}

declare var $: any;

@Component({
  selector: 'app-modify-video-timeline',
  templateUrl: './sap-video-timeline.component.html',
  styleUrls: ['./sap-video-timeline.component.scss']
})
export class ModifyVideoTimelineComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('videoClips', { static: false }) videoClips: ElementRef;
  @ViewChild('audioWaveform', { static: false }) audioWaveform: NgWaveformComponent;
  @ViewChild('voiceoverWaveform', { static: false }) voiceoverWaveform: NgWaveformComponent;
  @ViewChild('width1', { static: false }) width1: ElementRef;
  eventsSubject: Subject<void> = new Subject<void>()
  newclip_id = "media/general_medicine/videoblocks-medical-discussion-in-the-office-clinic-doctors-confer-in-the-office-of-the-clinic_rqgst_tqqn_1080__D_thumb.jpg";
  newClip_thumbnail = '';
  public enableOverlayAddClip = true;
  public enableOverlayAddText = true;
  scaleListWidth: number;


  lBlockConfigurations: block_config[] = [];

  clips: any[];
  overlayClips: any[] = [];
  texts: any[];
  lcta: any[];


  video: video;
  lScaleBreakPoints: any[] = [];
  bDisplayBlockSidePanel: boolean = false;
  oSelectedBlock: any;
  sSelectedVideoId: any;
  selectedBlock: any;
  sap: sap_config;
  currentIndex: number = 110;
  duration: number = 0;
  selectedTime: number;
  numbers: any;
  videoUrl: string = '';
  width: number = 0;
  textOverlaySection: boolean = false;
  videoOverlaySection: boolean = false;
  widthVidThumb = 20;
  newTextOverlay: overlayTexts;

  newOverlayClip: overlayclip;
  sSessionId = "";
  sUserEmail = "";
  // session: cSessionService;
  constructor(
    public session: cSessionService,
    private brandService: BrandService,
    private oRouter: Router, public videoManager: CreateVideoManagerService, public videoService: VideoService,
    private cd : ChangeDetectorRef
    // private cd : ChangeDetectorRef
  ) {

    this.numbers = Array(7).fill(0).map((x, i) => i);

    this.videoUrl = this.videoManager.selectedVideo?.sap_video_url;
  }


  sub: Subscription;
  async ngOnInit(): Promise<void> {
    this.video = this.videoManager.selectedVideo;
    this.session.oUploadedLogo = undefined;
    this.sSessionId = this.session.SessionService_GetSessionId();
    this.sUserEmail = this.session.cSessionService_GetUserEmail();
    this.videoService.getSignedURL(this.sUserEmail, this.sSessionId, this.newclip_id).then(res => {
      this.newClip_thumbnail = res['url'];
    });
    this.videoManager.videoBrand = undefined;
    if (this.videoManager?.selectedVideo?.brand_id) {
      this.brandService
        .getBrandProfile(this.videoManager?.selectedVideo?.brand_id)
        .then((brand: brand) => {
          this.videoManager.videoBrand = brand;
        });
    }
    this.sub = this.videoManager.submitForm.subscribe(c => {


      if (this.videoManager?.selectedVideo.email == undefined || !this.videoManager?.selectedVideo.session_id) {

        this.sSessionId = this.session.SessionService_GetSessionId();
        this.sUserEmail = this.session.cSessionService_GetUserEmail();
        this.videoManager.selectedVideo.email = this.sUserEmail;
        this.videoManager.selectedVideo.session_id = this.sSessionId;
      }

      if (this.videoManager.selectedVideo.font_color == undefined
        || !this.videoManager.selectedVideo.font_color
        || this.videoManager.selectedVideo.font_color == '') {
        delete this.videoManager.selectedVideo.font_color;
      }
      if (this.videoManager.selectedVideo.font_file == undefined
        || !this.videoManager.selectedVideo.font_file
        || this.videoManager.selectedVideo.font_file == '') {
        delete this.videoManager.selectedVideo.font_file;
      }

      this.videoManager.createVideo(this.videoManager.selectedVideo).then(res => {
        this.videoManager.selectedVideo['video_id'] = this.videoManager.selectedVideo?.fpath;
        this.oRouter.navigateByUrl('mv/preview');
      });
    });

    if (this.videoManager.isEditMode) {
      await this.videoManager.loadSelectedVideoData(this.sUserEmail, this.sSessionId).then(() => {
        this.initialize();
      });

      this.videoManager.selectedVideo.others_setting = {
        url: this.videoManager.selectedVideo.ci_dict.url != '' ? true : false,
        address: this.videoManager.selectedVideo.ci_dict.address ? true : false,
        phone: this.videoManager.selectedVideo.ci_dict.phone != '' ? true : false,
      };
    } else {
      this.initialize();
    }

    this.createScale();

  }

  private initialize() {

    this.duration = Math.floor(this.videoManager.selectedVideo.sap_video_duration + 8);// 8 SEC IS ADDED TO sap template generated video
    // this.videoManager.selectedVideo.duration = this.duration;
    this.sap = this.videoManager.selectedVideo?.sap_config;
    // console.log("  sap config", this.sap);
    this.refresh();
    this.refreshTexts();
    this.scaleListWidth = 216 * 5;
    let wid = this.width1.nativeElement.getBoundingClientRect().width - 100;
    this.width = wid;
    this.widthVidThumb = this.width;
  }



  changeIndex(event) {
    let wid = this.width1.nativeElement.getBoundingClientRect().width - 100;
    this.width = wid;
    this.currentIndex = event;
    this.selectedTime = event / (wid / this.duration);
    this.widthVidThumb = (this.width + 70) / (this.numbers.length);

  }
  fetchUrl(index: number) {
    let ts = Math.round(((this.duration + 6) / this.numbers.length) * index);
    if (ts == 0) {
      ts = 1;
    }

    return this.videoManager.selectedVideo.video_url + '#t=' + ts;
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.createScale();
    }, 100);

  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.initialize();
    setTimeout(() => {
      this.createScale();
    }, 300);
  }

  calWidth() {
    // console.log('width',this.width/(this.numbers.length))
    this.widthVidThumb = (this.width + 70) / (this.numbers.length);
    // this.cd.detectChanges();
    return (this.width + 70) / (this.numbers.length);
  }


  createScale() {
    this.lScaleBreakPoints = [];
    let nCurrentBreakPoint: number = 0;
    let dur = this.duration
    for (let i = 0; i <= dur; i++) {

      let wid = this.width1.nativeElement.getBoundingClientRect().width - 100;
      this.lScaleBreakPoints.push({ position: (wid / dur) * i, type: i % 5 == 0 ? i + 's' : '' });

    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.session.VideoSettingEditClip = false;
    this.session.visibleVoiceover = false;
    this.session.visibleTitle = false;
    this.session.visibleVideo = false;
    this.session.visibleAudio = false;
    this.session.visibleMedia = false;
    this.session.replacementClip = false;
    this.session.visibleTagline = false;
    this.videoManager.isEditMode = false;
  }





  VideoTimelineComponent_RemoveHighlightBlock(event) {
    this.oSelectedBlock = null;
    this.selectedBlock_text = null;
  }


  ReplaceText(oIncommingEmitedBlock: any) {
    if (oIncommingEmitedBlock) {
      this.selectedBlock_text = oIncommingEmitedBlock;

    }
    this.closeVideo();
    this.closeTitle();
  }

  RevertText() {


    this.closeTitle();
  }




  VideoTimelineComponent_EmitedBlockToSave(oIncommingEmitedBlock: any) {

    console.log('oIncommingEmitedBlock', oIncommingEmitedBlock)
    this.sap.clips.forEach(ele => {
      if (ele === this.oSelectedBlock) {

        ele.video_id = oIncommingEmitedBlock['replaceclip'];
        ele.video_thumbnail = oIncommingEmitedBlock['thumbnail'];


      }
    });



    this.closeVideo();
    this.closeTitle();
  }
  VideoTimelineComponent_EmitedBlockToRevert(oIncommingEmitedBlock: any,) {
    this.closeVideo();
    this.closeTitle();
  }

  removeClip(item, index) {
    this.sap.clips.splice(index, 1);
    this.refresh();
  }

  removeText(item, index) {
    this.sap.texts.splice(index, 1);
    this.refreshTexts();
  }

  openVideoSetting() {
    this.session.visibleVideoSettings = true;

    this.session.VideoSettingEditClip = false;
    this.session.visibleVoiceover = false;
    this.session.visibleAudio = false;
    this.session.visibleVideo = false;
    this.session.visibleTitle = false;
    this.session.visibleMedia = false;
    this.session.visiblePreviewVideo = false;
    this.session.visiblePreviewOverview = false;
    this.session.visibleAnalytics = false;
    this.session.replacementClip = false;
    this.session.visibleTagline = false;
  }


  closeVideoSettings(): void {
    this.session.visibleVideoSettings = false;
  }
  openVideo() {

  }
  closeVideo(): void {
    this.session.visibleVideo = false;

  }


  selectedBlock_text: any;
  selectedBlock_text_index: number;
  openTitle(block, index): void {

    this.selectedBlock_text = block;
    this.selectedBlock_text_index = index;

    this.session.visibleTitle = true;
    this.session.visibleVideo = false;
    this.session.visibleVoiceover = false;
    this.session.visibleAudio = false;
    this.session.VideoSettingEditClip = false;
    this.session.visibleMedia = false;

    this.session.visiblePreviewVideo = false;
    this.session.visiblePreviewOverview = false;
    this.session.visibleAnalytics = false;
    this.session.visibleVideoSettings = false;
    this.session.replacementClip = false;
    this.session.visibleTagline = false;
  }
  closeTitle(): void {
    this.session.visibleTitle = false;
  }

  openTagLineSidepanel(): void {
    this.session.visibleTagline = true;

    this.session.visibleTitle = false;
    this.session.visibleVideo = false;
    this.session.visibleVoiceover = false;
    this.session.visibleAudio = false;
    this.session.VideoSettingEditClip = false;
    this.session.visibleMedia = false;

    this.session.visiblePreviewVideo = false;
    this.session.visiblePreviewOverview = false;
    this.session.visibleAnalytics = false;
    this.session.visibleVideoSettings = false;
    this.session.replacementClip = false;
  }
  closeTagLineSidepanel(): void {
    this.session.visibleTagline = false;
  }
  openVideoSettingEditClip(): void {

    this.session.VideoSettingEditClip = true;
    this.session.visibleVoiceover = false;
    this.session.visibleAudio = false;
    this.session.visibleVideo = false;
    this.session.visibleTitle = false;
    this.session.visibleMedia = false;

    this.session.visiblePreviewVideo = false;
    this.session.visiblePreviewOverview = false;
    this.session.visibleAnalytics = false;
    this.session.visibleVideoSettings = false;
    this.session.replacementClip = false;
    this.session.visibleTagline = false;
  }
  closeVideoSettingEditClip(): void {
    this.session.VideoSettingEditClip = false;
  }



  openReplacementClip(oIncommingBlock: any, index) {
    this.oSelectedBlock = oIncommingBlock;
    this.bDisplayBlockSidePanel = true;
    this.session.replacementClip = true;
    this.session.VideoSettingEditClip = false;
    this.session.visibleVoiceover = false;
    this.session.visibleAudio = false;
    this.session.visibleVideo = false;
    this.session.visibleTitle = false;
    this.session.visibleMedia = false;

    this.session.visiblePreviewVideo = false;
    this.session.visiblePreviewOverview = false;
    this.session.visibleAnalytics = false;
    this.session.visibleVideoSettings = false;
    this.session.visibleTagline = false;
  }
  closeReplacementClip(): void {
    this.session.replacementClip = false;
  }


  saveTextOverlay() {
    // this.textOverlaySection = true;
    this.newTextOverlay = new overlayTexts();
    this.newTextOverlay.text.push('Title');
    this.newTextOverlay.text.push('Subtitle');
    this.newTextOverlay.ts = this.findEmptyTextBlockSpace([2, 6]);

    this.videoManager.selectedVideo.sap_config.texts.push(this.newTextOverlay);
    this.cd.detectChanges();
    this.textOverlaySection = false;
    this.refreshTexts();
  }
  closeAddTitle() {
    this.textOverlaySection = undefined;
    this.textOverlaySection = false;
  }

  saveVideoOverlay() {

    this.newOverlayClip = new overlayclip();
    this.newOverlayClip.video_id = this.newclip_id;
    this.newOverlayClip.video_thumbnail = this.newClip_thumbnail;
    this.newOverlayClip.ts = this.findEmptyVideoBlockSpace([2, 2]);
    this.videoManager.selectedVideo.sap_config.clips.push(this.newOverlayClip);
    this.videoOverlaySection = false;
    this.refresh();

  }
  findEmptyVideoBlockSpace(block) {

    this.videoManager.selectedVideo.sap_config.clips.forEach(ele => {
      if ((((block[0] + block[1]) >= ele.ts[0]) && (block[0] + block[1]) <= (ele.ts[0] + ele.ts[1])) || ((block[0] >= ele.ts[0]) && (block[0] <= (ele.ts[0] + ele.ts[1]))) || ((block[0] <= ele.ts[0]) && ((block[0] + block[1]) >= (ele.ts[0] + ele.ts[1])))) {
        block[0] = (ele.ts[0] + ele.ts[1]) + 1;
      }
    });

    return block
  }

  findEmptyTextBlockSpace(block) {

    this.videoManager.selectedVideo.sap_config.texts.forEach(ele => {
      if ((((block[0] + block[1]) >= ele.ts[0]) && (block[0] + block[1]) <= (ele.ts[0] + ele.ts[1])) || ((block[0] >= ele.ts[0]) && (block[0] <= (ele.ts[0] + ele.ts[1]))) || ((block[0] <= ele.ts[0]) && ((block[0] + block[1]) >= (ele.ts[0] + ele.ts[1])))) {
        block[0] = (ele.ts[0] + ele.ts[1]) + 1;
      }
    });

    return block
  }

  compare(a, b) {
    if (a.ts[0] < b.ts[0]) {
      return -1;
    }
    if (a.ts[0] > b.ts[0]) {
      return 1;
    }
    return 0;
  }

  checkforEmptySpace() {

    setTimeout(() => {
      this.checkEmptySpaceForClips();
    }, 0);
    setTimeout(() => {
      this.checkEmptySpaceForText();
    }, 0);



  }
  private checkEmptySpaceForText() {
    this.enableOverlayAddText = false;
    for (let i = 0; i < this.duration - 11; i++) {
      this.enableOverlayAddText = true;
      this.sap.texts.forEach(ele => {

        if ((ele.ts[0] >= i && ele.ts[0] <= (i + 6))) {
          this.enableOverlayAddText = false;
        }
      });
      if (this.enableOverlayAddText) {
        break;
      }

    }
  }

  private checkEmptySpaceForClips() {
    this.enableOverlayAddClip = false;
    for (let i = 0; i < this.duration - 11; i++) {
      this.enableOverlayAddClip = true;
      this.sap.clips.forEach(ele => {
        var t = i + 2;
        if (((ele.ts[0] > i && ele.ts[0] < t) || (ele.ts[1] > t))) {

          this.enableOverlayAddClip = false;
        }
      });
      if (this.enableOverlayAddClip) {


        break;
      }

    }
  }

  refreshTexts() {
    this.sap.texts.sort(this.compare);
    this.checkTextAddButon();
  }



  refresh() {
    this.sap.clips.sort(this.compare);
    this.checkClipAddButon();
  }
  private checkClipAddButon() {
    var d = 0;
    this.sap.clips.forEach(ele => {
      d += (ele.ts[0] - d) <= 3 ? (ele.ts[0] - d) : 0;
      d += (ele.ts[1]);

    });
    if ((d + 11) > this.duration) {
      this.enableOverlayAddClip = false;
    } else {
      this.enableOverlayAddClip = true;
    }
  }

  private checkTextAddButon() {
    var d = 0;
    this.sap.texts.forEach(ele => {

      d += (ele.ts[0] - d) <= 6 ? (ele.ts[0] - d) : 0;
      d += (ele.ts[1]);
    });

    if ((d + 11) > this.duration) {

      this.enableOverlayAddText = false;


    } else {
      this.enableOverlayAddText = true;
    }
  }

  closeAddVideo() {
    this.videoOverlaySection = false;
  }

  isItLastSapClip(index, totalClips) {

    if (index == (totalClips - 1)) {
      console.log(" open text pane ");
    }
    this.openTagLineSidepanel();
  }

}
