import { filter } from 'rxjs/operators';
import { voice } from '../../models/video';
import { cSessionService } from '../../services/session.service';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { ApiURL } from 'src/app/conf/api-path';
import { ProntoHttpService } from './pronto-http.service';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/services/loader.service';
import { OnboardingManagerService } from 'src/app/Managers/onboarding-manager.service';
import { VoiceDataStore } from 'src/app/stores/voice.store';
@Injectable({
    providedIn: 'root',
})
export class LocationService implements OnInit{
    selectedVoiceList: any[];
    selectedFavoritesList: any[];
    sessionId = '';
    userEmail = '';
    organizationId = '';

    constructor(
        private http: HttpClient,
        private cSessionService: cSessionService,
        private httpService: ProntoHttpService,
        private voiceDataStore: VoiceDataStore,
        public loader: LoaderService,
        public onboardinManger: OnboardingManagerService,
    ) {
       this.getUserLoginInfo();
    }

    getUserLoginInfo(){
        this.sessionId = this.cSessionService.SessionService_GetSessionId();
        this.userEmail = this.cSessionService.cSessionService_GetUserEmail();
        this.organizationId = this.cSessionService.getUserOrganization();

    }
    ngOnInit(): void {
        this.getUserLoginInfo();
    }

    getLocationOptions() {
        this.getUserLoginInfo();
        return this.http.post(environment.API_HOST_ADMIN + ApiURL.admin.user.get_location_fields,{ 'org_id': this.organizationId}).toPromise();
    }

    sveUpdateLocation(data) {
        return this.http.post(environment.API_HOST_ADMIN + ApiURL.admin.user.saveLocation ,data, { headers: { 'Content-Type': 'application/json'}}).toPromise();
    }

    getLocationTemplate(data) {
        return this.http.post(environment.API_HOST_ADMIN + ApiURL.admin.user.get_location_templates, data).toPromise();
    }
    getlocations(data) {
        return this.http.post(environment.API_HOST_ADMIN + ApiURL.admin.user.get_locations, data).toPromise();
    }
    deletelocations(data) {
        return this.http.post(environment.API_HOST_ADMIN + ApiURL.admin.user.del_location, data).toPromise();
    }
}
