import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resize-dragable-item',
  templateUrl: './resize-dragable-item.component.html',
  styleUrls: ['./resize-dragable-item.component.scss']
})
export class ResizeDragableItemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
