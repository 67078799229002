import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit, Renderer2 } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import * as CryptoJS from 'crypto-js';

@Component({
  selector: "app-paywall-payment-success",
  templateUrl: "./paywall-payment-success.component.html",
  styleUrls: ["./paywall-payment-success.component.scss"],
})
export class PaywallPaymentSuccessComponent implements OnInit {
  userEmail = "";
  constructor(private activatedRoute: ActivatedRoute,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.plan = params["plan"];
      this.userEmail =  params["email"];
    });
    // this.appendScript();
  }
  plan: string = "";
  ngOnInit(): void {}

  // TODO remove impact code
  // appendScript(){
  //   const key = "prontowebcryptosha1";
  //   // const sha1Key = "adsfasdf";
  //   const sha1Key = CryptoJS.AES.encrypt(this.userEmail, key).toString();

  //   let scriptImpact = this._renderer2.createElement('script');
  //   scriptImpact.type = `text/javascript`;
  //   scriptImpact.text = `(function(a,b,c,d,e,f,g){e['ire_o']=c;e[c]=e[c]||function(){(e[c].a=e[c].a||[]).push(arguments)};f=d.createElement(b);g=d.getElementsByTagName(b)[0];f.async=1;f.src=a;g.parentNode.insertBefore(f,g);})('https://utt.impactcdn.com/A3832971-c640-4f7e-b374-c0bd203b21221.js','script','ire',document,window);`;
  //   this._renderer2.appendChild(this._document.head, scriptImpact);
  //   const __this = this;
  //   setTimeout(() => {
  //     let script = __this._renderer2.createElement('script');
  //     script.type = `text/javascript`;
  //     script.text = `console.log("Setting user info...");ire('identify', {customerId: '${__this.userEmail}', customerEmail: '${sha1Key}'});`;
  //     __this._renderer2.appendChild(__this._document.body, script);

  //     let scriptSuccess = __this._renderer2.createElement('script');
  //     scriptSuccess.type = `text/javascript`;
  //     scriptSuccess.text = 'console.log("Sending order id....");ire("trackConversion",34706, {orderId: "n/a",customerId: "'+__this.userEmail+'",customerEmail: "'+sha1Key+'"},{verifySiteDefinitionMatch:true});';
  //     __this._renderer2.appendChild(__this._document.body, scriptSuccess);
  //   }, 2000);
  // }

  getStarted(){
    // window.location.href="/login"
    window.location.href="/dashboard/myvideos";
  }

}

