import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

import { cSessionService } from "../../../services/session.service";

import { CreateVideoManagerService } from "../../../Managers/create-video-manager.service";
import { address } from "../../../models/common";
import { VideoManagerService } from "../../../Managers/video-manager.service";
import { OnboardingManagerService } from "./../../../Managers/onboarding-manager.service";
declare var $: any;

@Component({
  selector: "app-other-setting",
  templateUrl: "./other-setting.component.html",
  styleUrls: ["./other-setting.component.scss"],
})
export class OtherSettingComponent implements OnInit, OnDestroy {
  sSessionId: string = "";
  sUserEmail: string = "";
  fOtherSettingForm: FormGroup;
  oSavedFormData: any;
  bDisplayErrorMsg: boolean = false;
  sErrorMessage: string;
  bURLToggle: boolean = true;
  bAddressToggle: boolean = false;
  bPhoneToggle: boolean = false;
  sVideoId: string;
  bCreateVideoMode: boolean = true;
  sDisableEditModeToggle: string = "false";
  oOverviewForm: any;
  oLooknFeelForm: any;
  isError: boolean = false;

  constructor(
    private oFormBuilder: FormBuilder,
    private oRouter: Router,
    private oSessionService: cSessionService,
    public videoManager: CreateVideoManagerService,
    public videoListingManager: VideoManagerService,
    private onBoardingManager: OnboardingManagerService
  ) {}
  sub: Subscription;
  sub2: Subscription;
  ngOnInit() {
    this.sub2 = this.videoManager.previousSubmit.subscribe((emit) => {
      this.oRouter.navigateByUrl("/wizard/content");
    });

    this.OtherSettingComponent_InitializeJqueryCode();
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    this.sub = this.videoManager.submitForm.subscribe((c : any) => {
      this.save(c);
    });

    this.OtherSettingComponent_InitializeOverviewForm();
  }
  ngOnDestroy() {
    if (this.sub2) this.sub2.unsubscribe();
    if (this.sub) this.sub.unsubscribe();
  }

  OtherSettingComponent_InitializeOverviewForm() {
    if (!this.videoManager.selectedVideo.address) {
      this.videoManager.selectedVideo.address = new address();
    }

    var url = this.onBoardingManager.profile.url;

    if (
      this.videoManager.selectedVideo.ci_dict?.url &&
      this.videoManager.selectedVideo.ci_dict?.url != ""
    ) {
      url = this.videoManager.selectedVideo.ci_dict?.url;
    }

    if(this.videoManager.selectedVideo.brand_id && this.videoManager.videoBrand && this.videoManager.videoBrand?.website ){
      url = this.videoManager.videoBrand?.website;
    }
    if (
      this.videoManager.selectedVideo.phone &&
      this.videoManager.selectedVideo.phone != ""
    ) {
      this.bPhoneToggle = true;
    }

    if (
      this.videoManager.selectedVideo.ci_dict &&
      this.videoManager.selectedVideo.ci_dict?.address?.line1
    ) {
      this.bAddressToggle = true;
    }
    this.fOtherSettingForm = this.oFormBuilder.group({
      session_id: this.sSessionId,
      email: this.sUserEmail,
      url: [
        url,
        [
          Validators.required,
          Validators.pattern(
            "(https?://)?([\\dA-Za-z.-]+)\\.([A-Za-z.]{2,6})[/\\w .-]*/?"
          ),
        ],
      ],
      line1: [this.videoManager.selectedVideo.ci_dict?.address?.line1],
      line2: [this.videoManager.selectedVideo.ci_dict?.address?.line2] ? [this.videoManager.selectedVideo.ci_dict?.address?.line2] : "",
      city: [this.videoManager.selectedVideo.ci_dict?.address?.city],
      state: [this.videoManager.selectedVideo.ci_dict?.address?.state],
      postal_code: [
        this.videoManager.selectedVideo.ci_dict?.address?.postal_code,
      ],
      country: [this.videoManager.selectedVideo.ci_dict?.address?.country] ? [this.videoManager.selectedVideo.ci_dict?.address?.country] : "",

      phone: [this.videoManager.selectedVideo.phone],
      bDisplayUrl: this.bURLToggle,
      bDisplayAddress: this.bAddressToggle,

      bDisplayPhone: this.bPhoneToggle,
    });
  }

  save(redirect = "generate") {
    if (!this.fOtherSettingForm.valid) {
      this.fOtherSettingForm.markAllAsTouched();

    } else {
      this.videoManager.selectedVideo.session_id =
      this.fOtherSettingForm.value.session_id;
    this.videoManager.selectedVideo.email =
      this.fOtherSettingForm.value.email;

    this.updateVideoLocally();
    this.videoManager.selectedVideo.step = 'other_settings';
    this.videoManager
      .createVideo(this.videoManager.selectedVideo)
      .then((res) => {
        if(redirect == "generate"){
          this.onBoardingManager.loggedUser.publishedVideoCount++;
          this.onBoardingManager.loggedUser.videoCount++;
          this.oSessionService.cSessionService_SetUserVideosCount(this.onBoardingManager.loggedUser.publishedVideoCount);
          this.videoListingManager.selectedVideo = this.videoManager.selectedVideo;
          this.oRouter.navigateByUrl("/wizard/preview");
        }
      });
    }
  }

  private updateVideoLocally() {

    if (this.bURLToggle) {
      this.videoManager.selectedVideo.url = this.fOtherSettingForm.value.url;
    }
    if (this.bAddressToggle) {
      this.videoManager.selectedVideo.address = new address();

      this.videoManager.selectedVideo.address.line1 =
        this.fOtherSettingForm.value.line1;
      this.videoManager.selectedVideo.address.line2 =
        this.fOtherSettingForm.value.line2 ? this.fOtherSettingForm.value.line2 : '';
      this.videoManager.selectedVideo.address.city =
        this.fOtherSettingForm.value.city;
      this.videoManager.selectedVideo.address.state =
        this.fOtherSettingForm.value.state;
      this.videoManager.selectedVideo.address.postal_code =
        this.fOtherSettingForm.value.postal_code;
      this.videoManager.selectedVideo.address.country =
        this.fOtherSettingForm.value.country ? this.fOtherSettingForm.value.country : '';
    } else {
      if (this.videoManager.selectedVideo.ci_dict) {
        this.videoManager.selectedVideo.ci_dict.address = undefined;
      }
      this.videoManager.selectedVideo.address = undefined;
    }

    if (this.bPhoneToggle) {
      this.videoManager.selectedVideo.phone =
        this.fOtherSettingForm.value.phone;
    }
  }

  OtherSettingComponent_PhoneToggle(event: any) {
    this.fOtherSettingForm.controls["phone"].setValue("");
    this.bPhoneToggle = event.target.checked;
    this.fOtherSettingForm.patchValue({ bDisplayPhone: this.bPhoneToggle });
    if (this.bPhoneToggle) {
      this.fOtherSettingForm.controls["phone"].setValidators([
        Validators.required,
        ,
      ]);
    } else {
      this.fOtherSettingForm.controls["phone"].clearValidators();
    }

    this.fOtherSettingForm.controls["phone"].updateValueAndValidity();
  }
  OtherSettingComponent_AddressToggle(event: any) {
    this.bAddressToggle = event.target.checked;
    this.fOtherSettingForm.patchValue({ bDisplayAddress: this.bAddressToggle });

    if (this.bAddressToggle) {
      this.fOtherSettingForm.controls["line1"].setValidators([
        Validators.required,
      ]);
      // this.fOtherSettingForm.controls["line2"].setValidators([
      //   Validators.required
      // ]);
      this.fOtherSettingForm.controls["city"].setValidators([
        Validators.required,
      ]);
      this.fOtherSettingForm.controls["state"].setValidators([
        Validators.required,
      ]);
      this.fOtherSettingForm.controls["postal_code"].setValidators([
        Validators.required,
      ]);
      // this.fOtherSettingForm.controls["country"].setValidators([]);
    } else {
      this.fOtherSettingForm.controls["line1"].clearValidators();
      // this.fOtherSettingForm.controls["line2"].clearValidators();
      this.fOtherSettingForm.controls["city"].clearValidators();
      this.fOtherSettingForm.controls["state"].clearValidators();
      this.fOtherSettingForm.controls["postal_code"].clearValidators();
      // this.fOtherSettingForm.controls["country"].clearValidators();
    }

    this.fOtherSettingForm.controls["line1"].updateValueAndValidity();

    // this.fOtherSettingForm.controls["line2"].updateValueAndValidity();
    this.fOtherSettingForm.controls["city"].updateValueAndValidity();
    this.fOtherSettingForm.controls["state"].updateValueAndValidity();
    this.fOtherSettingForm.controls["postal_code"].updateValueAndValidity();
    // this.fOtherSettingForm.controls["country"].updateValueAndValidity();
  }
  OtherSettingComponent_URLToggle(event: any) {
    this.bURLToggle = event.target.checked;
    this.fOtherSettingForm.patchValue({ bDisplayUrl: this.bURLToggle });

    if (this.bURLToggle) {

      this.fOtherSettingForm.controls["url"].setValidators([
        Validators.required,
        Validators.pattern(
          "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
        ),
      ]);
    } else {
      this.fOtherSettingForm.controls["url"].clearValidators();
    }

    this.fOtherSettingForm.controls["url"].updateValueAndValidity();
  }

  OtherSettingComponent_ToggleView(event) {
    if (event.target.checked) {
      this.oRouter.navigateByUrl("/wizard/overview");
    } else {
      this.oRouter.navigateByUrl("edit-video/timeline-view");
    }
  }
  OtherSettingComponent_InitializeJqueryCode() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.text = `{
        $(document).ready(function () {
          $('.address').change(function () {
              if (this.checked)
                  $('.address-div').fadeIn('slow');
              else
                  $('.address-div').fadeOut('slow');
          });

          $('.url').change(function () {
              if (this.checked)
                  $('.url-div').fadeIn('slow');
              else
                  $('.url-div').fadeOut('slow');

          });

          $('.phone').change(function () {
              if (this.checked)
                  $('.phone-div').fadeIn('slow');
              else
                  $('.phone-div').fadeOut('slow');

          });

      });
    }`;
    document.body.appendChild(chatScript);
  }

  _keyPress(event: any) {
    let newVal = this.fOtherSettingForm.controls["phone"].value.replace(
      /\D/g,
      ""
    );

    this.fOtherSettingForm.controls["phone"].setValue(newVal);
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
