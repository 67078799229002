import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CreateVideoManagerService } from '../..//Managers/create-video-manager.service';
import { FtCreateVideoManagerService } from 'src/app/Managers/ft-create-video-manager.service';





@Component({
  selector: 'app-ftue-create-video-breadcrumb',
  templateUrl: './ftue-create-video-breadcrumb.component.html',
  styleUrls: ['./ftue-create-video-breadcrumb.component.scss']
})
export class FtueCreateVideoBreadcrumbComponent implements OnInit {
  showMenu : string;
   sGetVideoId: string;
   public bDisplayTimelineBreadcrumb:boolean = false;
   @Input() enableMenu : boolean=false;
   sClassText = 'disabled-breadcrumb'
   constructor(private oRouter : Router ,
    public videoManger:CreateVideoManagerService,
    public ftVideoManager : FtCreateVideoManagerService ) {
     this.showMenu = this.oRouter.url
   }
   ngOnInit() {




   }

   submitForm(){
    this.ftVideoManager.submitForm.emit("noGenerate");
  }

 }
