import { OnboardingDataService } from './../../../services/dataServices/onboarding-data.service';
// import { voice } from './../../../models/video';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { cSessionService } from './../../../services/session.service';
import { AccountManagerService } from './../../../Managers/account-manager.service';
import { locale } from './../../../models/account';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { ErrorService } from './../../../services/error.service';
import { SuccessMessageModalService } from 'src/app/services/success-message-modal.service';
import { VoiceDataStore } from 'src/app/stores/voice.store';
import { DialogDataStore } from 'src/app/stores/dialogs.store';
import { LocationService } from 'src/app/shared/services/location.service';
import { ProntoSettingService } from 'src/app/services/utils/pronto-setting.service';
import { MediaManagerService } from 'src/app/Managers/media-manager.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { profile } from 'src/app/models/onboarding';
import { OnboardingManagerService } from 'src/app/Managers/onboarding-manager.service';
declare var $: any;
@Component({
    selector: 'app-locations',
    templateUrl: './locations.component.html',
    styleUrls: ['./locations.component.scss'],
})
export class LocationsComponent implements OnInit {
    @ViewChild('resetField') resetField: ElementRef;
    @ViewChild('close') close: ElementRef;
    @ViewChild('closeModal') closeModal: ElementRef;
    fSearchForm: FormGroup;
    public fAddLocationForm: FormGroup;
    fEditLocationForm: FormGroup;
    sSessionId: string = '';
    sUserEmail: string = '';
    lLocales: any = [];
    totalLocals: any;
    oTotalCount: any;
    oLocationName: any;
    nPageSize: number = 10;
    nCurrentPage = 1;
    public lPagination: any = [];
    oLocationId: any;
    linesR: any = [];
    lines: any = [];
    lCSVList: any = [];
    bCloseModal: boolean = false;
    showDeleteLocationModal = false;
    skipVideos = 0;
    showSearchBox = false;
    search_term = '';
    searchTermText = '';
    showSortByDD = false;
    order_by = 'name';
    sort_type = 1;
    incomingLocale: any;
    lUserSelectedVisuals: any = [];
    lUserSelectedAudio: any = [];
    public imageStaticThumb = '../assets/images/business_Profile/audio-line.png';
    public locationOptions = undefined;
    public locationViewOptions = undefined;
    public listLocations = [];
    public selectedLocationId = undefined;
    textListFields = [];
    destroy$: Subject<boolean> = new Subject<boolean>();
    selectedLocationItem = undefined;
    defaultLocationFormField = undefined;
    oAudio = new Audio();
    constructor(
        private oFormBuilder: FormBuilder,
        public accountManger: AccountManagerService,
        private oSessionService: cSessionService,
        private successModal: SuccessMessageModalService,
        public errorService: ErrorService,
        private voiceStore: VoiceDataStore,
        private dialogStore: DialogDataStore,
        private locationService: LocationService,
        private pronto: ProntoSettingService,
        public mediaManager: MediaManagerService,
        private cd: ChangeDetectorRef,
        private onboardinManger: OnboardingManagerService
    ) {
        this.mediaManager.replaceMediaFromVisualPopup.pipe(takeUntil(this.destroy$)).subscribe((data : any) => {

            this.mediaManager.showMediaReplacementPopup = false;
             if(this.mediaManager.replacedMediaComponent == 'selectFromMeidaGallery'){

                if (data && data.length > 0 ) {

                    // console.log("upload response ", data[0]);
                    this.selectedLocaleForUpload.media_obj = data[0];
                    this.selectedLocaleForUpload.value = this.selectedLocaleForUpload.media_obj.fpath;
                    this.selectedLocaleForUpload.speaker = '';
                    this.selectedLocaleForUpload.selection = 'upload';
                    console.log(this.selectedLocaleForUpload.media_obj);
                }
            }
        });
    }

    ngOnInit() {
        this.sSessionId = this.oSessionService.SessionService_GetSessionId();
        this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
        this.oSessionService.setAccountSettingHeaderName('Locations');
        this.locationService.getLocationOptions().then((res) => {
            this.defaultLocationFormField = res;
            this.locationOptions = res;
            this.locationViewOptions = res;
            this.locationViewOptions.map((item) => {
                if (item.order) {
                    this.textListFields.push(item.name);
                }
            });
        });
        this.InitializeAddLocationsForm();
        this.getLocals();
        // this.loadLocalsData();
        //     this.accountManger.getLocale(this.sUserEmail, this.sSessionId,this.nPageSize,0).then(res => {
        // this.oTotalCount = res['count'];
        // if(this.oTotalCount>this.nPageSize){
        //   this.totalLocals = this.nPageSize;
        // }

        // this.createPagination();
        //     }).catch(err => {

        //     });
    }

    updateLocationValue() {
        this.locationOptions.map((item) => {
            if (item.name == 'name' && item.error) {
                delete item['error'];
            }
        });
        let data = {};
        this.locationOptions
            .filter((item) => item.copy_of != undefined)
            .map((filItem) => {
                let valueParent = '';
                this.locationOptions.map((filed) => {
                    if (filed.name == filItem.copy_of) {
                        valueParent = filed.value;
                    }
                });
                filItem.value = valueParent;
            });
        let newData: any = {};
        let keysArray = [];
        let isNewRecord = true;

        let text_list = [];
        this.locationOptions.map((item) => {
            // console.log(" item name ", item.name);
            if (item.name == 'voiceover') {
                data[item.name] = item;
                newData[item.name] = 'test';
                keysArray.push(item.name);
            } else {
                // console.log(item);
                data[item.name] = item.value;
                if (item.order) {
                    text_list.push(item.value);
                }
            }
        });

        if (text_list) {
            data['text_list'] = text_list;
        }
        data['email'] = this.sUserEmail;
        if (this.selectedLocationId) {
            data['_id'] = this.selectedLocationId.$oid ? this.selectedLocationId.$oid : this.selectedLocationId;
            isNewRecord = false;
        }
        if (data['name'] === '' || !data['name']) {
            this.locationOptions.map((item) => {
                if (item.name == 'name') {
                    item['error'] = true;
                }
            });

            // this.errorService.handleError('Location Name is empty ', 'Save Location', true);
        } else {
            // console.log(' data ', data);
            this.locationService
                .sveUpdateLocation(data)
                .then((res) => {
                    this.visibleLocation = false;
                    if (isNewRecord && res['record']) {
                        this.listLocations.push(res['record']);
                        this.oTotalCount++;
                    } else {
                        if (res['record']) {
                            let indexLocale = this.listLocations.findIndex((item) => {
                                let compareId = item._id.$oid ? item._id.$oid : item._id;
                                return compareId == data['_id'];
                            });
                            this.listLocations[indexLocale] = res['record'];
                        }
                    }
                })
                .catch((error: any) => {
                    this.errorService.handleError(error.error ? error.error : error.toString(), 'Save Location', true);
                });
            this.visibleLocation = false;
        }

        // this.updateRecordList(data);
    }

    updateRecordList(data) {
        // console.log(' data form ', data);
        // console.log( " --- this.selectedLocationItem ", this.selectedLocationItem);
        let listLocale = Object.entries(this.selectedLocationItem);

        listLocale.map((item) => {
            // console.log(item);
            if (item && item.length > 0) {
                if (item[0] != 'created' && item[0] != 'owner' && item[0] != 'updated') {
                    item[1] = this.getValueOfKey(data, item[0]);
                }
            }
        });

        let indexLocale = this.listLocations.findIndex((item) => {
            let compareId = item._id.$oid ? item._id.$oid : item._id;
            return compareId == this.selectedLocationItem._id;
        });
        this.listLocations[indexLocale] = listLocale;
        this.cd.detectChanges();
    }

    getValueOfKey(data, key) {
        if (data[key] && data[key].value) {
            return data[key].value;
        } else {
            return data[key];
        }
    }

    // getLocals() {
    //     this.oTotalCount = 0;
    //     this.totalLocals = 0;
    //     this.accountManger
    //         .getLocale(
    //             this.sUserEmail,
    //             this.sSessionId,
    //             this.nPageSize,
    //             this.skipVideos,
    //             this.sort_type == -1 ? '-' + this.order_by : this.order_by,
    //             1,
    //             this.search_term,
    //         )
    //         .then((res) => {
    //             this.oTotalCount = res['total_count'];
    //             // if(this.oTotalCount>this.nPageSize){
    //             //   this.totalLocals = this.nPageSize;
    //             // }

    //             this.createPagination();
    //         })
    //         .catch((err) => {});
    // }

    createPagination() {
        this.lPagination = [];
        let pages = Math.ceil(this.oTotalCount / this.nPageSize);
        for (let nPaginationIndex = 1; nPaginationIndex <= pages; nPaginationIndex++) {
            this.lPagination.push(nPaginationIndex);
        }
        // console.log("Pagination List : ==> ",this.lPagination);
    }

    getLocals() {
        this.oTotalCount = 0;
        this.totalLocals = 0;

        const data = {
            search_by: 'name',
            search_term: this.search_term,
            offset: this.skipVideos,
            limit: this.nPageSize,
            sort_by: this.sort_type == -1 ? this.order_by : this.order_by,
            asc: this.sort_type,
            email: this.sUserEmail,
        };

        this.locationService.getlocations(data).then((res: any) => {
            if (res && res.result) {
                this.listLocations = res.result;
                this.oTotalCount = res['total_count'];
            }
        });
    }
    loadPage(pageNumber: number) {
        // if (pageNumber !== this.nCurrentPage) {
        this.nCurrentPage = pageNumber;
        this.skipVideos = (pageNumber - 1) * this.nPageSize;
        const data = {
            search_by: 'name',
            search_term: this.search_term,
            offset: this.skipVideos,
            limit: this.nPageSize,
            sort_by: this.sort_type == -1 ? this.order_by : this.order_by,
            asc: this.sort_type,
            email: this.sUserEmail,
        };

        this.locationService.getlocations(data).then((res: any) => {
            if (res && res.result) {
                this.listLocations = res.result;
                this.oTotalCount = res['total_count'];
            }
        });
        // }
    }

    loadPageEvent($event) {
        if ($event.pageSize != this.nPageSize) {
            this.nPageSize = $event.pageSize;
            this.loadPage(1);
        } else {
            this.loadPage($event.pageIndex + 1);
        }
    }
    deleteLocationModal(locale: any) {
        this.incomingLocale = locale;
        this.showDeleteLocationModal = true;
        this.selectedLocationItem = locale;
        console.log(this.selectedLocationItem);
    }
    deleteLocale() {
        this.showDeleteLocationModal = false;
        let idLocale = this.selectedLocationItem._id.$oid ? this.selectedLocationItem._id.$oid : this.selectedLocationItem._id;
        this.locationService
            .deletelocations({ _id: idLocale })
            .then((res) => {
                if (res) {
                    this.selectedLocationItem = undefined;
                    // this.getLocals();
                    let indexLocale = this.listLocations.findIndex((item) => {
                        let compareId = item._id.$oid ? item._id.$oid : item._id;
                        return compareId == idLocale;
                    });
                    this.listLocations.splice(indexLocale, 1);
                    this.oTotalCount = this.oTotalCount - 1 > -1 ? this.oTotalCount - 1 : 0;
                    this.totalLocals = this.totalLocals - 1 > -1 ? this.totalLocals - 1 : 0;
                    // this.totalLocals = this.totalLocals -1;
                    // if(this.totalLocals%this.nPageSize==0){

                    //   this.getLocals();
                    //   }
                }
            })
            .catch((err) => {
                this.errorService.handleError(err.errorMessage, err.errorType, true);
            });
    }

    addLocale() {
        if (this.fAddLocationForm.value.name != '') {
            let loc = {
                id: this.fAddLocationForm.value.id,
                // address: {
                //     city: this.fAddLocationForm.value.city,
                //     state: this.fAddLocationForm.value.state,
                //     line1: this.fAddLocationForm.value.line1,
                //     line2: this.fAddLocationForm.value.line2,
                //     postal_code: this.fAddLocationForm.value.postal_code,
                // },
                name: this.fAddLocationForm.value.name,
                // url: this.fAddLocationForm.value.url,
                // phone: this.fAddLocationForm.value.phone,
                eventDates: this.fAddLocationForm.value.eventDates,
                openHourStart: this.fAddLocationForm.value.openHourStart,
            } as locale;

            let locals = [loc];

            if (loc.id == '') {
                this.accountManger
                    .createLocale(this.sUserEmail, this.sSessionId, locals)
                    .then((res) => {
                        if (res) {
                            // this.totalLocals = this.totalLocals +1;
                            // if(this.totalLocals>=this.nPageSize){

                            // this.getLocals();
                            // }

                            this.order_by = 'created';
                            this.sort_type = -1;
                            this.getLocals();
                        }
                        this.InitializeAddLocationsForm();
                        this.closeLocation();
                    })
                    .catch((err) => {
                        this.errorService.handleError(err.errorMessage, err.errorType, true);
                    });
            } else {
                this.accountManger
                    .editLocale(this.sUserEmail, this.sSessionId, loc)
                    .then((res) => {
                        this.InitializeAddLocationsForm();
                        this.closeLocation();
                    })
                    .catch((err) => {
                        this.errorService.handleError(err.errorMessage, err.errorType, true);
                    });
            }
        } else {
            this.fAddLocationForm.markAllAsTouched();
        }
    }

    editLocation(locale: any) {
        // this.openLocation();
        // console.log("edit locale ", locale);
        this.loadLocationDataLoading = true;
        this.locationService.getLocationOptions().then((res) => {
            this.loadLocationDataLoading = false;
            this.locationOptions = res;

            this.visibleLocation = true;
            this.selectedLocationItem = locale;
            this.selectedLocationId = locale._id.$oid;

            this.locationOptions.map((item) => {
                if (item.name == 'name') {
                    item.value = locale.name;
                } else {
                    let valueItem = this.getLocationRow(locale, item.name, true);
                    // if (valueItem && valueItem != '') {
                    //     // let splitedVal = valueItem.split('<br />');
                    //     // item.value = splitedVal[0];
                    //     // if (splitedVal.length > 0) {
                    //     //     item.value2 = splitedVal[1];
                    //     // }
                    // } else {
                    item.value = valueItem;
                    // }
                    if (item.name == 'voiceover') {
                        let itemObj = locale[item.name];
                        item.text = itemObj['text'] ? itemObj['text'] : '';
                        item.artist_id =
                            itemObj['artist_id'] || itemObj['speaker'] || itemObj['voice_id']
                                ? itemObj['artist_id']
                                    ? itemObj['artist_id']
                                    : itemObj['voice_id'] ? itemObj['voice_id'] : itemObj['speaker']
                                : '';
                        item.narration = itemObj['narration'] ? itemObj['narration'] : '';
                        item.value = itemObj['value'] ? itemObj['value'] : '';
                        item.selection = itemObj['selection'] ? itemObj['selection'] : 'voiceover';
                        if (item && item.value && item.value != '') {
                            this.pronto.getSignedUrl(this.sUserEmail, this.sSessionId, item.value).then((res) => {
                                item.media_obj = {
                                    url: res,
                                    fpath: item.value,
                                };
                            });
                        }
                    }
                    if (item.name == 'logo' && item.value && item.value != '') {
                        this.pronto.getSignedUrl(this.sUserEmail, this.sSessionId, item.value).then((res) => {
                            item.media_obj = {
                                thumbnail: res,
                                fpath: item,
                            };
                        });
                    }
                }
                // console.log(" ITEM NOW ", item);

                this.cd.detectChanges();
            });
            // this.locationOptions();
        });
    }

    InitializeAddLocationsForm() {
        this.fAddLocationForm = this.oFormBuilder.group({
            id: [''],
            name: ['', [Validators.required]],
            city: [''],
            state: [''],
            line1: [''],
            line2: [''],
            postal_code: [''],
            url: [''],
            phone: [''],
            eventDates: [''],
            openHourStart: [''],
        });
    }

    LocationComponent_InitializeSearchLocationForm() {
        this.fSearchForm = this.oFormBuilder.group({ search: '' });
    }

    async LocationsComponent_ReadCSVFile(event): Promise<void> {
        this.lines = [];
        this.linesR = [];
        let file = event.target.files[0];
        this.handleCancel();

        // this.successModal.success('Your Location file is uploading, Please wait.', 'Uploading Location File', true, false);
        // console.log('LocationsComponent_ReadCSVFile : csv To read ==> ', file);
        let reader: FileReader = new FileReader();
        reader.readAsText(file);
        reader.onload = (e) => {
            let csv: any = reader.result;
            let allTextLines = [];
            allTextLines = csv.split(/\r|\n|\r/);

            // console.log("-------------" + allTextLines);
            let headers = allTextLines[0].split(';');
            let data = headers;
            for (let j = 0; j < headers.length; j++) {
                this.lines.push(data[j]);
            }
            // console.log('the keys ===>>>', this.lines);
            let arrl = allTextLines.length;
            let rows = [];
            for (let i = 1; i < arrl - 1; i++) {
                if (allTextLines[i] == undefined || allTextLines[i] == '') {
                    //  console.log(i, " NO REOCRDS ")
                } else {
                    if (allTextLines[i].split(';') != '') rows.push(allTextLines[i].split(';'));
                }

                // console.log('row-----' + arrl);
            }

            // console.log('rows---------',rows);
            for (let j = 0; j < arrl - 1; j++) {
                this.linesR.push(rows[j]);
            }
            // console.log('the data in rows ===>>>', this.linesR);
            this.LocationsComponent_CreateKeyValuePairs();
        };
    }
    escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    replaceAll(str, find, replace) {
        return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
    }
    LocationsComponent_CreateKeyValuePairs() {
        let lPayload = [];
        let lHeaders = [];
        lHeaders = this.lines[0].split(',');

        for (let nRowsIndex = 0; nRowsIndex < this.linesR.length; nRowsIndex++) {
            let obj = {};
            let nValueOfRow: any;
            if (this.linesR[nRowsIndex] != undefined) {
                nValueOfRow = this.linesR[nRowsIndex];
                nValueOfRow = nValueOfRow[0].split(',');
                if (nValueOfRow.length > 0 && nValueOfRow[0] != '' && nValueOfRow[0].trim() != '') {
                    for (let nFirstRowIndex = 0; nFirstRowIndex < nValueOfRow.length; nFirstRowIndex++) {
                        let jsonField = nValueOfRow[nFirstRowIndex].indexOf('__') > -1;
                        let newval = this.replaceAll(nValueOfRow[nFirstRowIndex], '__', ',');
                        // newval = newval.replaceAll("'", '"');
                        if (jsonField) {
                            obj[lHeaders[nFirstRowIndex]] = JSON.parse(newval);
                        } else {
                            obj[lHeaders[nFirstRowIndex]] = newval;
                        }
                    }
                }

                this.locationViewOptions.map((item) => {});
                if (this.textListFields && this.textListFields.length > 0) {
                    let textList = [];
                    this.textListFields.map((items) => {
                        textList.push(obj[items]);
                    });
                    obj['text_list'] = textList;
                }

                if (typeof obj == 'object' && obj['name'] != undefined) {
                    lPayload.push(obj);
                }
            }
        }

        this.LocationsComponent_SendAddCSVLocationRequest(lPayload);
    }

    //CSV file upload ...
    LocationsComponent_SendAddCSVLocationRequest(lIncommingCSVlist) {
        let body = {
            email: this.sUserEmail,
            session_id: this.sSessionId,
            locales: lIncommingCSVlist,
        };

        let completeTaskSubject = new Subject<any>();
        this.successModal.showCSVFileUploadBar({ message: 'Inprogress...', title: 'CSV File Upload Status' }, completeTaskSubject);
        this.accountManger
            .createLocale(this.sUserEmail, this.sSessionId, lIncommingCSVlist, false, completeTaskSubject, this.successModal)
            .then((res: any) => {
                this.InitializeAddLocationsForm();
                this.loadPage(1);
            })
            .catch((err) => {
                this.errorService.handleError(err.errorMessage ? err.errorMessage : err.toString(), err.errorType, true);
            });
        this.bCloseModal = true;
    }
    LocationsComponent_ResetField() {
        this.resetField.nativeElement.value = '';
    }

    visibleLocation = false;
    placementLocation: NzDrawerPlacement = 'right';
    openLocation(): void {
        this.selectedLocationId = undefined;
        this.selectedLocationItem = false;
        this.locationOptions = undefined;
    }
    loadLocationDataLoading = false;
     clearFields() {
        this.visibleLocation = true;
        this.loadLocationDataLoading = true;
        this.openLocation();
        this.onboardinManger
        .getUserProfile(this.sUserEmail, this.sSessionId).then( (res : profile) =>{
            this.onboardinManger.profile.favourite_voices = res.favourite_voices;
            this.onboardinManger.profile.selected_voices = res.selected_voices;
            this.voiceStore.updateUserVoicesFromProfile(this.onboardinManger?.profile, true);
            // console.log(" ----  voice over update ....");

        });
        this.locationService.getLocationOptions().then((res) => {
            this.locationOptions = res;
            this.cd.detectChanges();
            this.loadLocationDataLoading = false;
        });
    }
    closeLocation(): void {
        this.visibleLocation = false;
    }

    // modal Upload Location File
    isVisible = false;
    showModal(): void {
        this.isVisible = true;
    }
    handleCancel(): void {
        this.isVisible = false;
    }
    searchLocation() {
        this.skipVideos = 0;
        this.search_term = this.searchTermText;
        this.getLocals();
    }

    clearSearchTerm() {
        this.skipVideos = 0;
        this.search_term = '';
        this.searchTermText = '';
        this.getLocals();
    }

    sortBySearchResult(value) {
        this.skipVideos = 0;
        this.order_by = value;
        this.showSortByDD = false;
        this.hideSearchSortTypeDD(undefined);
        // this.sort_type = -1;
        this.getLocals();
    }
    sortTypeSearch(val: number) {
        this.sort_type = val;
        this.skipVideos = 0;
        this.getLocals();
        // this.loadPage(1);
    }

    openSearchSortTypeDD() {
        this.showSortByDD = this.showSortByDD ? false : true;
    }

    hideSearchSortTypeDD($event) {
        if (this.showSortByDD) {
            const __this = this;
            setTimeout(() => {
                __this.showSortByDD = false;
            }, 200);
        }
    }

    openVoiceEditor(item) {
        // console.log(' LOCATION HERE BEFORE OPEN ...', item);
        this.voiceStore.openVoiceOverPopUp(
            {
                ...item,
                isLocation: true,
                locationName : this.locationOptions.filter( (item) => (item.name == 'name'))[0].value
            },
            {},
            (obj) => {
                //  console.log('SAVE OBJ ', obj);
                if (!obj.type || obj.type == 'success') {
                    item.value = obj.fpath;
                    item.text = obj?.data?.text;
                    item.selection = obj?.data?.selection;
                    if (obj.data && obj.data.voices && obj.data.voices.length > 0) {
                        item.value = obj.data.voices[0].fpath;
                        item.artist_id = obj.data.voices[0].speaker ? obj.data.voices[0].speaker : '';
                        item.speaker = obj.data.voices[0].speaker ? obj.data.voices[0].speaker : '';
                        item.media_obj = obj.data.voices[0];
                        this.lUserSelectedAudio = obj.data.voices;
                        // console.log(' - Audio ', item.media_obj);
                    }
                } else {
                    // console.log("no change in data ", item);
                }
            },
        );
    }

    callBackUpload(item) {
        // console.log('dialog ..', item);
    }

    callBackUploadSubscribed(item) {
        // console.log('dialog subscribed...', item);
    }
    oUploadedLogo = undefined;

    // file upload version v2
    openFileUploadModal(item) {
        this.mediaManager.replacedMediaComponent = 'logoupload';
        // console.log(' Upload logo ...');
        this.dialogStore.openFileUploadModal(
            (data) => {
                console.log('Uploaded logo', data);
                if (data?.status == 'success' && this.mediaManager.replacedMediaComponent == 'logoupload') {
                    const uploadedFiles = data.result[0];
                    this.oSessionService.oUploadedLogo = uploadedFiles;
                    const res = data.result[0];
                    // console.log(data.result[0]);
                    item.media_obj = res;
                    item.value = uploadedFiles.visual_path;
                }
            },

            (data) => {
                console.log('Uploaded logo replace called ...', item, data);
                if (data && data.length > 0 && this.mediaManager.replacedMediaComponent == 'logoupload') {
                    item.media_obj = data[0];
                    this.visible = false;
                    // console.log(data[0]);
                    item.value = data[0].visual_path;
                }
            },
            undefined,
        );
    }
    ContentComponent_lEmittedFilesToUpload(lIncommingFiles: any) {
        // console.log('ContentComponent_lEmittedFilesToUpload : Selected files ==> ', lIncommingFiles);
        this.lUserSelectedVisuals = lIncommingFiles;
        if (lIncommingFiles && lIncommingFiles.length > 0) {
            this.oUploadedLogo = lIncommingFiles[0];
        }
        // console.log(this.oUploadedLogo);

        this.visible = false;
    }

    removeMediaFile(item) {
        this.lUserSelectedVisuals = null;
        item.media_obj = undefined;
        item.value = '';
    }
    // modal media library
    visible = false;

    showMediaModal(item) {
        this.visible = true;
    }
    closeMediaModal(): void {
        // console.log("Button cancel clicked!");
        this.visible = false;
    }
    EmittedAudioFiles(lIncommingFiles: any) {
        // console.log('ContentComponent_lEmittedFilesToUpload : Selected files ==> ', lIncommingFiles);
        this.lUserSelectedAudio = lIncommingFiles;

        this.visibleforAudio = false;
    }
    removeAudioFile(item) {
        this.lUserSelectedAudio = null;
        item.media_obj = undefined;
        item.value = '';
        item.text = '';
        item.voice = '';
    }
    // modal media library
    visibleforAudio = false;
    showAudioModal(item) {
        this.visibleforAudio = true;
    }
    closeAudioModal(): void {
        // console.log("Button cancel clicked!");
        this.visibleforAudio = false;
    }

    ContentComponent_ReturnFileSize(bytes, decimals = 2) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    getLocationRow(object, option, original, childField = undefined) {
        if (option == 'name') {
            return object.name;
        }
        let valueField = object;
        let valueFound = false;

        return this.locationMediaReturnCase(object, option, valueField, original, true);
    }

    locationMediaReturnCase(object, option, valueField, original, elseCase) {
        if (!original) {
            if (option == 'logo') {
                return object[option] && object[option] != '' ? 'Added' : '\xa0\xa0\xa0\xa0\xa0' + '-';
            }
            if (option == 'voiceover') {
                return object[option] && object[option].value && object[option].value != '' ? 'Added' : '\xa0\xa0\xa0\xa0\xa0' + '-';
                return !original ? 'No' : '';
            } else {
                // console.log("Obj data ", object[option] );
                return object && object[option] ? object[option] : '';
            }
        } else {
            if (option == 'logo') {
                return object[option] ? object[option] : '';
            } else if (option == 'voiceover') {
                if (original) {
                    let fieldP = object[option];
                    // console.log(fieldP);
                    return valueField && valueField.value != '' ? fieldP[option] : '';
                } else {
                    // console.log(' voiceover >>  2 :  ', original, valueField, object[option]);
                    return valueField && valueField.value != '' ? object[option].value : undefined;
                }
            } else {
                // console.log(' other field ', option, object[option]);
                return object[option];
            }
        }
    }

    selectedLocaleForUpload = undefined;
    showMediaLibrary(type: string, item) {
        this.mediaManager.showMediaReplacementPopup = true;
        this.mediaManager.replacedMediaComponent = 'selectFromMeidaGallery';
        this.mediaManager.replaceMediaTypeFromVisualPopup = { type: type, data: [], component: 'uploadfile' };
        this.selectedLocaleForUpload = item;
    }
    AudioSidePanelComponent_PlayAudio(oIncommingAudioUrl) {
        // console.log(oIncommingAudioUrl);
        this.oAudio = new Audio();
        this.oAudio.src = oIncommingAudioUrl;
        this.oAudio.load();
        this.oAudio.play();
    }
    AudioSidePanelComponent_StopAudio(oIncommingAudioUrl) {
        let filename = oIncommingAudioUrl.replace(/^.*(\\|\/|\:)/, '');
        if (this.oAudio.src.replace(/^.*(\\|\/|\:)/, '') == filename) {
            this.oAudio.pause();
            this.oAudio = new Audio();
            this.oAudio.src = oIncommingAudioUrl;
            this.oAudio.load();
            this.oAudio.pause();
        }
    }
    returnFpath(media) {
         if (media && media.fpath && media.fpath.value && media.fpath.value != '') {
            return this.returnFileName(media?.fpath?.value?.split('/').pop());
        }
        else if (media && media.visual_path && media.visual_path != '') {
            return this.returnFileName(media?.visual_path?.split('/').pop());
        }
        else {
            return this.returnFileName(media?.fpath?.split('/').pop());
        }
    }

    returnFileName(filename) {
        var split = filename?.split('.');
        var filename = split[0];
        var extension = split[1];
        if (filename.length > 15) {
            filename = filename.substring(0, 14);
        }
        var result = filename + '.' + extension;
        return result;
    }
}
