import { NgModule } from '@angular/core';
import { TimestampToDate } from './timestamp-to-date-convertor.pipe';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { FormatFileSizePipe } from './format-file-size.pipe';
import { FontFileNameTruncatePipe } from 'src/app/shared/pipes/font-file-name-truncate.pipe';
import { SearchVoiceOverPipe } from 'src/app/shared/pipes/search-voice-over.pipe';
import { SecondsToHours } from './seconds-to-hours.pipe';
import { MediaFileNamePipe } from './media-file-name.pipe';
import { FileNameOnly } from './file-name-only.pipe';
import { AspectRatioPipe } from './aspect-ratio.pipe';
import { LocationActiveFieldsPipe } from './location_display_fields_only.pipe';
import { RemoveCopyOfLocationFields } from './remove-copy-off-location-fields.pipe copy';

@NgModule({
    imports: [],
    declarations: [
        TimestampToDate,
        FormatFileSizePipe,
        FontFileNameTruncatePipe,
        SearchVoiceOverPipe,
        SecondsToHours,
        MediaFileNamePipe,
        FileNameOnly,
        AspectRatioPipe,
        LocationActiveFieldsPipe,
        RemoveCopyOfLocationFields
    ],
    exports: [
        TimestampToDate,
        FormatFileSizePipe,
        FontFileNameTruncatePipe,
        SearchVoiceOverPipe,
        SecondsToHours,
        MediaFileNamePipe,
        FileNameOnly,
        AspectRatioPipe,
        LocationActiveFieldsPipe,
        RemoveCopyOfLocationFields

    ]
  })
  export class DatesPipeModule{
    static forRoot(): ModuleWithProviders {
        return {
          ngModule: DatesPipeModule,
        };
      }
  }