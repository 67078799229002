<div class="showSapVideoTimeLineDurationBar"
    style="height: 100vh; position: absolute; background-color:#483d8f; opacity: 0.1; border-left:1px dashes #ddd;  border-right:1px dashes #ddd; z-index: 1; top : 40px"
    [style.width.px]="width" *ngIf="this.isHover || isMouseDrag"></div>
<div #box style="position: relative; border-radius: 5px; z-index: 3" class="resizable-draggable"
    [style.width.px]="width" [style.height.px]="height" [class.active]="status === 1 || status === 2"
    (mousedown)="setStatus($event, 2);" (window:mouseup)="setStatus($event, 0);" (mouseenter)="mouseEnter()"
    (window:mousedrag)="testDrag($event)" (window:mousemove)="testDrag($event)" (mouseleave)="mouseLeave()"
    [style.outline]="this.isHover || this.selected ? 'solid 1px #ff8369' : 'solid 1px #e6e6e6'">
    <ng-content></ng-content>
    <div class="timeline-item-overlay" *ngIf="this.isHover || this.selected"></div>

    <div class="resize-action" *ngIf="this.enableResizing && this.isHover" (mousedown)="setStatus($event, 1)"></div>

    <button *ngIf="this.isHover || this.selected" class="close" (click)="remove()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="8px" height="8px"
            style="margin-top: -10px; fill: white">
            <path
                d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z" />
        </svg>
    </button>

    <div>
        <svg class="svg-icon"
            style="width: 1em; display: none; height: 1em; vertical-align: middle; fill: currentColor; overflow: hidden"
            viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M384 64H256C220.66 64 192 92.66 192 128v128c0 35.34 28.66 64 64 64h128c35.34 0 64-28.66 64-64V128c0-35.34-28.66-64-64-64z m0 320H256c-35.34 0-64 28.66-64 64v128c0 35.34 28.66 64 64 64h128c35.34 0 64-28.66 64-64v-128c0-35.34-28.66-64-64-64z m0 320H256c-35.34 0-64 28.66-64 64v128c0 35.34 28.66 64 64 64h128c35.34 0 64-28.66 64-64v-128c0-35.34-28.66-64-64-64zM768 64h-128c-35.34 0-64 28.66-64 64v128c0 35.34 28.66 64 64 64h128c35.34 0 64-28.66 64-64V128c0-35.34-28.66-64-64-64z m0 320h-128c-35.34 0-64 28.66-64 64v128c0 35.34 28.66 64 64 64h128c35.34 0 64-28.66 64-64v-128c0-35.34-28.66-64-64-64z m0 320h-128c-35.34 0-64 28.66-64 64v128c0 35.34 28.66 64 64 64h128c35.34 0 64-28.66 64-64v-128c0-35.34-28.66-64-64-64z"
                fill="white" />
        </svg>
    </div>
</div>