<!-- <div (click)="openDialog()" style="color: #000"> OPEN UPLOAD BOX</div> -->
<nav style="z-index: 999;position: relative;height: calc(100vh - 146px);/* overflow: auto; */">
  <div class="main-content-modal fancy-scroll" style="height: 100%; overflow: auto; ">
    <div class="sidebar-header pb-3 ps-3" style="padding: 3px 20px">
      <h3 class="text-gray-2 weight-400 text-lg pt-2">
        Video Settings
        <p class="mb-0 mt-1 text-xs text-gray-3">Any edits will apply across all clips</p>
      </h3>
      <div class="closeRightSideBar pe-2" style="top: 0" (click)="closeVideo()">
        <img src="../assets/images/icons/close-icon.svg" width="12" height="12" />
      </div>
    </div>
    <div class="side-content pt-0 pb-0">
      <div class="video_wrapper my_video_class js-videoWrapper" style="height: 280px">
        <video #myVideoO controls disablepictureinpicture controlsList="nodownload noplaybackrate"
          style="max-height: 280px; max-width : auto ; margin: auto" class="videoIframe js-videoIframe" [muted]="true"
          [src]="this.video?.video_url" [id]="this.video?.fpath" [ngStyle]="{ opacity: figActive ? '0' : '1' }"></video>
        <figure class="video-baner" style="text-align: center; display: block"
          [ngStyle]="{ opacity: !figActive ? '0' : '1', display: !figActive ? 'none' : '' }">
          <img [src]="this.video?.thumbnail" alt="video1" style="max-height: 280px; max-width : auto ; margin: auto" />
          <span class="video-duration">00:{{ this.video?.duration }}</span>
        </figure>
        <button class="videoPoster js-videoPoster h-81 " (click)="TimelineViewSidePanelComponent_PlayVideo()"></button>
      </div>
    </div>
    <ul class="nav breadcrumb-tabs" id="myTab" role="tablist">
      <li class="nav-item pe-0" role="presentation">
        <button class="nav-link active tab-size" id="tab1-tab" data-bs-toggle="tab" data-bs-target="#tab1" type="button"
          role="tab" aria-controls="tab1" aria-selected="true">
          Logo
        </button>
      </li>
      <li class="nav-item px-0" role="presentation">
        <button class="nav-link tab-size" id="tab2-tab" data-bs-toggle="tab" data-bs-target="#tab2" type="button"
          role="tab" aria-controls="tab2" aria-selected="false">
          Fonts & Colors
        </button>
      </li>
      <li class="nav-item px-0" role="presentation">
        <button class="nav-link tab-size" id="tab3-tab" data-bs-toggle="tab" data-bs-target="#tab3" type="button"
          role="tab" aria-controls="tab3" aria-selected="false">
          Keywords
        </button>
      </li>
      <li class="nav-item px-0" role="presentation" *ngIf="this.allowedTemplate">
        <button class="nav-link tab-size" id="tab4-tab" data-bs-toggle="tab" data-bs-target="#tab4" type="button"
          role="tab" aria-controls="tab4" aria-selected="false">
          Other Settings
        </button>
      </li>
      <!--
        <li class="nav-item px-0" role="presentation">
            <button class="nav-link" id="tab3-tab" data-bs-toggle="tab" data-bs-target="#tab3" type="button" role="tab"
                aria-controls="tab3" aria-selected="false">Transitions</button>
        </li> -->
    </ul>

    <div class="side-content flex-grow-1 border-top">
      <div class="tab-content text-gray-3 text-xs" id="myTabContent">
        <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
          <p  *ngIf="brand_id != undefined && brand_id != ''" class="text-gray-3 text-xs">Upload your logo and brand colors to use in your videos.</p>
          <p  *ngIf="brand_id == undefined || brand_id == ''" class="text-gray-3 text-xs">Upload your logo to be featured in your videos</p>
          <div class="formItm static_lable order_list ms-0 pt-0">
            <h6>Logo</h6>
            <div _ngcontent-tlm-c308="" class="videoimg_wrapp green-radio pt-2">
              <label (click)="toggleVideoLogoUploadSection('own'); toggleLogoUploadSection($event, 'own')">
                <img
                  [src]="(selectedLogo == 'own') ? '../assets/images/icons/green-checkbox.svg' : '../assets/images/icons/round-check-box-off.png' "
                  class="ng-star-inserted">

                <span _ngcontent-tlm-c308="" class="ps-2">Upload</span>
              </label>
              <label (click)="toggleVideoLogoUploadSection('profile'); toggleLogoUploadSection($event, 'profile')"
                class="ps-3" *ngIf="brand_id == undefined || brand_id == ''">
                <img
                  [src]="(selectedLogo == 'profile') ? '../assets/images/icons/green-checkbox.svg' : '../assets/images/icons/round-check-box-off.png' "
                  class="ng-star-inserted">
                <span _ngcontent-tlm-c308="" class="ps-2">Use Profile Logo</span>
              </label>

              <label (click)="toggleVideoLogoUploadSection('brand'); toggleLogoUploadSection($event, 'brand')"
                class="ps-3" *ngIf="brand_id != undefined && brand_id != ''">
                <img
                  [src]="(selectedLogo == 'brand') ? '../assets/images/icons/green-checkbox.svg' : '../assets/images/icons/round-check-box-off.png' "
                  class="ng-star-inserted">
                <span _ngcontent-tlm-c308="" class="ps-2">Use Brand Logo</span>
              </label>
            </div>
            <!-- <div class="d-flex mt-1 mb-3">
            <div>
              <input class="custom-check" type="radio" name="upload-logo-profile" id="upload-logo-profile" value="own"
                [checked]="selectedLogo == 'own'"
                (change)="toggleVideoLogoUploadSection('own'); toggleLogoUploadSection($event, 'own')" />
              <label class="text-dark" for="upload-logo-profile" style="width: auto; margin: 2px 5px">Upload</label>
            </div>
            <div *ngIf="brand_id == undefined || brand_id == ''">
              <input class="custom-check ms-2" type="radio" name="logo-section" id="use-logo-profile"
                [checked]="selectedLogo == 'profile'"
                (change)="toggleVideoLogoUploadSection('profile'); toggleLogoUploadSection($event, 'profile')" />
              <label class="text-dark" for="use-logo" style="width: auto; margin: 2px 5px">Use profile logo</label>
            </div>
            <div *ngIf="brand_id != undefined && brand_id != ''">
              <input class="custom-check ms-2" type="radio" name="logo-section" id="use-logo-brand"
                [checked]="selectedLogo == 'brand'"
                (change)="toggleVideoLogoUploadSection('brand'); toggleLogoUploadSection($event, 'brand')" />
              <label class="text-dark" for="use-logo" style="width: auto; margin: 2px 5px">Use brand logo</label>
            </div>
          </div> -->
            <div *ngIf="selectedLogo == 'profile'">
              <div class="media_list mt-3" *ngIf="oLogoDetails && (oLogoDetails.url || oLogoDetails.thumbnail_signed_url)">
                <ul>
                  <li class="mb-3">
                    <figure class="media_figure">
                      <span class="media"> <img [src]="oLogoDetails.url? oLogoDetails.url : oLogoDetails.thumbnail_signed_url" alt="logo" /></span>
                      <figcaption>
                        <div class="caption w-100">
                          <p class="text-xs title">{{ oLogoDetails.filename | slice : 0 : 30 }}</p>
                          <p class="text-xs sub_title">
                            {{ sizeOfFile(oLogoDetails.size) }}
                          </p>
                        </div>
                      </figcaption>
                    </figure>
                  </li>
                </ul>
              </div>
              <div *ngIf="!oLogoDetails || (!oLogoDetails.url && !oLogoDetails.thumbnail_signed_url)" style=""
                class="alert alert-danger d-flex align-items-center alert-dismissible fade show mt-3" role="alert">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M27.5683 21.8754L16.8998 3.08014C15.6241 0.839822 12.3899 0.839822 11.1141 3.08014L0.445544 21.8754C-0.830163 24.0854 0.788172 26.8545 3.33834 26.8545H24.6437C27.2254 26.8545 28.8121 24.1169 27.568 21.8754H27.5683ZM14.0387 7.56066C15.0337 7.56066 15.5318 8.46261 15.5318 10.2994C15.5318 11.2949 15.4079 12.6649 15.1273 14.4385C14.8478 16.2121 14.6607 17.3012 14.4736 17.6441C14.3497 17.8933 14.2245 18.0173 14.0387 18.0173C13.8212 18.0173 13.6657 17.9237 13.5721 17.7061C13.4166 17.4265 13.1991 16.4297 12.9185 14.7182C12.639 13.0066 12.5139 11.5126 12.4835 10.3298C12.4835 8.46383 13.012 7.56066 14.0387 7.56066V7.56066ZM15.1273 22.0627C14.8162 22.3739 14.4432 22.5295 14.0071 22.5295C13.5721 22.5295 13.1979 22.3739 12.8869 22.0627C12.5758 21.7516 12.4203 21.3784 12.4203 20.942C12.4203 20.5068 12.5758 20.1324 12.8869 19.8212C13.1979 19.51 13.5709 19.3544 14.0071 19.3544C14.4116 19.3544 14.7846 19.51 15.1273 19.8528C15.4699 20.1956 15.6254 20.5688 15.6254 20.9736C15.6242 21.3784 15.4383 21.7516 15.1273 22.0627Z"
                    fill="#842029" />
                </svg>

                <div>
                  Please upload a profile logo
                  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.546609 15.7333C0.406624 15.7333 0.273304 15.68 0.166649 15.58C-0.0399958 15.3733 -0.0399958 15.0333 0.166649 14.8267L7.11925 7.86667L0.153317 0.906667C0.0533277 0.806667 0 0.673333 0 0.533333C0 0.393333 0.0533277 0.26 0.153317 0.16C0.259972 0.0533333 0.393292 0 0.533277 0C0.673262 0 0.806581 0.0533333 0.913237 0.153333L7.8725 7.11333L14.8251 0.153333C14.9251 0.0533333 15.0584 0 15.1984 0C15.3384 0 15.4717 0.0533333 15.5784 0.153333C15.785 0.36 15.785 0.7 15.5784 0.906667L8.62575 7.86667L15.5717 14.8133C15.6717 14.9133 15.725 15.0467 15.725 15.1867C15.725 15.3267 15.6717 15.46 15.5717 15.5667C15.4717 15.6733 15.3384 15.72 15.1917 15.72C15.0451 15.72 14.9184 15.6667 14.8118 15.5667L7.8725 8.62L0.919903 15.58C0.819913 15.68 0.686594 15.7333 0.546609 15.7333Z"
                        fill="#FF8369" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="selectedLogo == 'own'" class="mt-3">
              <!-- Show own Logo -->
              <span>
                <button id="custom-button-svg"
                  (click)="openFileUploadModal('image_video_logo','Upload a Logo', 'logoUpload')"
                  class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btn-md-same-pad btnShadow d-flex align-items-center">
                  <span class="upload-icon"></span>
                  Upload
                </button>
                <!-- Uploaded Logo file -->
                <div class="media_list mt-3"
                  *ngIf="this.oSessionService.oUploadedLogo && (this.oSessionService.oUploadedLogo.url || this.oSessionService.oUploadedLogo.thumbnail_signed_url)">
                  <ul>
                    <li class="mb-3">
                      <figure class="media_figure">
                        <span class="media"> <img [src]="this.oSessionService.oUploadedLogo.url? this.oSessionService.oUploadedLogo.url : this.oSessionService.oUploadedLogo.thumbnail_signed_url" alt="logo" /></span>
                        <figcaption>
                          <div class="caption w-100">
                            <!-- <p class="text-xs title">{{oUploadedLogo.filename | slice:0:30}}</p>
                                                    <p class="text-xs sub_title"> {{CreateVideoLooknFeelComponent_ReturnFileSize(nUploadedLogoSize)}}</p> -->
                            <p *ngIf="
                                                            this.oSessionService.oUploadedLogo?.name != undefined &&
                                                            this.oSessionService.oUploadedLogo?.name != ''
                                                        " class="text-xs title" style="color: rgba(75, 76, 82, 0.876)">
                              {{ this.oSessionService.oUploadedLogo?.name | slice : 0 : 30 }}
                            </p>
                            <p *ngIf="
                                                            this.oSessionService.oUploadedLogo?.size != undefined &&
                                                            this.oSessionService.oUploadedLogo?.size > 0
                                                        " class="text-xs sub_title">
                              {{ this.oSessionService.oUploadedLogo?.size | formatFileSize : false }}
                            </p>
                          </div>
                        </figcaption>
                      </figure>
                    </li>
                  </ul>
                </div>
              </span>
            </div>
            <div *ngIf="selectedLogo == 'brand'" class="mt-3">
              <!-- Show Brand Logo  -->
              <span *ngIf="this.videoManager.selectedVideo.brand_id != ''">
                <button id="custom-button-svg" (click)="openSelectBrandLogo()"
                  class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btn-md-same-pad btnShadow d-flex align-items-center pady-2">
                  Select
                </button>
              </span>
              <span *ngIf="brandUploadedSecondLogo && currentUploadedLogoSection">
                <div class="media_list mt-3" *ngIf="brandUploadedSecondLogo">
                  <ul>
                    <li class="mb-3">
                      <figure class="media_figure">
                        <span class="media"> <img [src]="brandUploadedSecondLogo.url? brandUploadedSecondLogo.url : brandUploadedSecondLogo.thumbnail_signed_url" alt="logo" /></span>
                        <figcaption>
                          <div class="caption w-100">
                            <p class="text-xs title">
                              {{ brandUploadedSecondLogo.filename | slice : 0 : 30 }}
                            </p>
                          </div>
                        </figcaption>
                      </figure>
                    </li>
                  </ul>
                </div>
              </span>
            </div>


            <div class="col-sm-12" *ngIf="this.isSapVideo">


              <div class="formItm static_lable mb-3">
                <label class="text-gray-3 text-xs" for="">Show primary logo at video start & end?</label>
                <!-- <p class="text-xs text-gray-6">5.   Apply primary logo at video start & end?. </p> -->
                <div class="videoimg_wrapp green-radio mt-3">
                  <label (click)="togglePrimaryLogoAtVideoStart(true)">
                    <img
                      [src]="(applyPrimaryLogoAtVideoStart) ? prontoSetting.icons.checked_green : prontoSetting.icons.unchecked" />

                    <span class="ps-2">Yes</span>
                  </label>
                  <label class="ps-3" (click)="togglePrimaryLogoAtVideoStart(false)">
                    <img
                      [src]="(!applyPrimaryLogoAtVideoStart) ? prontoSetting.icons.checked_green : prontoSetting.icons.unchecked" />
                    <span class="ps-2">No</span>
                  </label>
                </div>
                <div>
                  <button *ngIf="!applyPrimaryLogoAtVideoStart"
                    (click)="openFileUploadModal('image_video_logo','Upload Secondary Logo', 'secondaryLogoUpload')"
                    id="custom-button-svg"
                    class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btn-md-same-pad btnShadow d-flex align-items-center mt-3">
                    <span class="upload-icon"></span>
                    Upload Secondary Logo
                  </button>
                  <span *ngIf="secondaryLogoFile != '' && !applyPrimaryLogoAtVideoStart">
                    <div class="media_list mt-2" *ngIf="secondaryLogoFile">
                      <ul>
                        <li class="mb-3">
                          <figure class="media_figure">
                            <span class="media">
                              <img [src]="secondaryLogoFile.url" alt="logo"></span>
                            <figcaption>
                              <div class="caption w-100">
                                <p *ngIf="secondaryLogoFile?.name != undefined && secondaryLogoFile?.name !=''"
                                  class="text-xs title" style="color: rgba(75, 76, 82, 0.876);">
                                  {{secondaryLogoFile?.name
                                  | slice:0:30}}</p>
                                <p *ngIf="secondaryLogoFile?.size != undefined && secondaryLogoFile?.size > 0 "
                                  class="text-xs sub_title">{{ secondaryLogoFile?.size | formatFileSize:false}}
                                </p>
                              </div>
                            </figcaption>
                          </figure>
                        </li>
                      </ul>
                    </div>
                  </span>
                </div>
              </div>

            </div>

          </div>
        </div>
        <div class="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
          <pronto-system-fonts-dropdown [isPopup]="true" [selectedVideoFontFile]="selectedVideoFontFile"
            [brandFonts]="this.videoManager?.videoBrand?.fonts" (setFontFile)="assignFontToVideo($event)">
          </pronto-system-fonts-dropdown>
          <div class="theme-color d-flex mt-4">
            <ul class="ms-2 ps-0 pe-3">
              <label class="d-block text-md text-gray-2 pb-3">Theme Color</label>
              <li>
                <div class="clr-field"
                  [style.color]="videoThemeColor ? videoThemeColor : this.videoManager.themeDefaultColor">
                  <button aria-labelledby="clr-open-label"></button>
                  <input class="form-check-input coloris border" type="checkbox" id="checkboxNoLabel"
                    [(ngModel)]="videoThemeColor" [value]="videoThemeColor" [ngModelOptions]="{ standalone: true }"
                    (change)="setThemeColor($event)" value="" aria-label="..." />
                </div>
                <figure class="color-pic no-bg">
                  <img class="color-picker" src="../assets/images/icons/check-icon.svg"
                    [ngClass]="{'white': (videoThemeColor != '#ffffff' && videoThemeColor != '#FFFFFF' && videoThemeColor != undefined)}"
                    width="16" height="16" *ngIf="videoThemeColor " />
                  <img class="color-picker"
                    [ngClass]="{'white': (videoThemeColor != '#ffffff' && videoThemeColor != '#FFFFFF' && videoThemeColor != undefined)}"
                    src="../assets/images/icons/color-picker.svg" width="16" *ngIf="!videoThemeColor" height="16" />
                </figure>
              </li>
            </ul>
            <ul class="m-0 ps-0 pe-4">
              <label class="d-block text-md text-gray-2 pb-3">Font Color</label>
              <li>
                <div class="clr-field" [style.color]="oFontColor ? oFontColor : defaultVideoColor">
                  <button aria-labelledby="clr-open-label"></button>
                  <input class="form-check-input coloris" type="checkbox" id="checkboxNoLabel"
                    [value]="oFontColor ? oFontColor : defaultVideoColor" [(ngModel)]="oFontColor" [ngModelOptions]="{
                                          standalone: true
                                      }" (change)="setFontColor($event)" aria-label="..." />
                  <figure class="color-pic no-bg">
                    <img class="color-picker"
                      [ngClass]="{'white': oFontColor != '#ffffff' && oFontColor != '#FFFFFF' && oFontColor != undefined}"
                      *ngIf="!oFontColor " src="../assets/images/icons/color-picker.svg" />
                    <img class="color-picker"
                      [ngClass]="{'white': (oFontColor != '#ffffff' && oFontColor != '#FFFFFF' && oFontColor != undefined)}"
                      *ngIf="oFontColor" class="color-picker" src="../assets/images/icons/check-icon.svg" />
                  </figure>
                </div>
              </li>
            </ul>


          </div>
        </div>

        <div class="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="tab3-tab">
          <app-add-keywords (emitUpdateKeywords)="emitUpdateKeywords($event)"></app-add-keywords>
          <!-- <p class="text-gray-3 text-xs">Add or Edit Keywords to Update Clips</p>
          <div class="col-sm-12">
            <div class="formItm static_lable order_list mb-2 ms-0">
              <div class="createTag_wrapp pt-2 mt-1">
                <figure class="my-1 mx-2">
                  <img src="../../../assets/images/svg/keyword tag.svg">
                </figure>
                <span class="tag mb-2" *ngFor="let keyterm of lKey_terms;let i = index;">{{keyterm}}
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" class="me-1 position-relative" width="10"
                    height="10" viewBox="0 0 24 24" style=" fill:#7A7A7A; left: 5px;"
                    (click)="CreateVideoOverViewComponent_RemoveTagListElements(i)">
                    <path
                      d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z">
                    </path>
                  </svg>
                </span>
                <div class="tagfield createTag_wrappAdd" [ngClass]="bDisplayAddInputField?'d-inline-block':'d-none'">
                  <input class="add-Tag" maxlength="32" #AddInputTagElement onkeypress="return event.charCode != 32"
                    (focusout)="AddTagInputField()" (keydown)="addTagOnKeyEnter($event.keyCode)"
                    [(ngModel)]="inputValue" [ngModelOptions]="{standalone: true}" type="text"
                    (change)="CreateVideoOverViewComponent_GetInputValue($event)"><input class="tag-btn" type="button"
                    value="Add" [disabled]="!inputValue" (click)="AddTagInputField()" toggle-class="#createTag_wrapp">
                </div>
                <a *ngIf="!bDisplayAddInputField" href="javascript:void(0)"
                  (click)="CreateVideoOverViewComponent_ToggleTagButtonAndAddInput()"
                  class="text-nowrap text-sm text-primary-4 createTag_wrappAddBtn" toggle-class="#createTag_wrapp">
                  <svg class="me-1" width="12" height="12" viewBox="0 0 12 12" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6.26087 0.391304C6.26087 0.175193 6.08568 0 5.86957 0C5.65345 0 5.47826 0.175193 5.47826 0.391304L5.47826 5.73913H0.391304C0.175193 5.73913 0 5.91432 0 6.13043C0 6.34655 0.175193 6.52174 0.391304 6.52174H5.47826L5.47826 11.6087C5.47826 11.8248 5.65345 12 5.86956 12C6.08568 12 6.26087 11.8248 6.26087 11.6087L6.26087 6.52174H11.6087C11.8248 6.52174 12 6.34655 12 6.13043C12 5.91432 11.8248 5.73913 11.6087 5.73913H6.26087L6.26087 0.391304Z"
                      fill="#FF8369" />
                  </svg>
                  Add a Keyword
                </a>
              </div>
            </div>
          </div> -->
        </div>

        <div class="tab-pane fade" id="tab4" role="tabpanel" aria-labelledby="tab4-tab">
          <p class="text-gray-3 text-xs">Customize the content displayed at the end of your video.</p>
          <div>
            <form [formGroup]="fOtherSettingForm" #formOtherSettings>
              <div class="col-sm-12 col-md-12 col-lg-12 other-setting-form">
                <div class="d-flex align-items-start check_toggle_content">
                  <div class="check_toggle">
                    <div class="form-switch toggle_switch">
                      <input class="form-check-input website" type="checkbox" id="flexSwitchCheckChecked"
                        [checked]="otherSettings.urlToggle" (change)="toggeleOtherSettingsSection($event)">
                    </div>
                  </div>
                  <div class="check_content w-100">
                    <p class="text-md text-gray-1 pb-1 mb-0 primaryFont">Website</p>
                    <div class="row website-div mt-2 ml-60-inverse">
                      <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="otherSettings.urlToggle">
                        <div class="formItm">
                          <input id="website-address " type="text" value="https://www."
                            placeholder="Enter website address" name="textfeild" formControlName="url" required>

                          <label for="website-address" class="text-sm">URL</label>
                          <span
                            *ngIf="this.fOtherSettingForm?.get('url')?.errors && this.fOtherSettingForm?.get('url')?.touched"
                            class="error">This field is required with valid URL.</span>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-flex align-items-start check_toggle_content pt-3 mt-2">
                  <div class="check_toggle">
                    <div class="form-switch toggle_switch">
                      <input class="form-check-input address" type="checkbox" id="flexSwitchCheckChecked"
                        [checked]="otherSettings.addressToggle" (change)="toggleAddressComponent($event)">
                    </div>
                  </div>
                  <div class="check_content w-100">
                    <p class="text-md text-gray-1 pb-1 mb-0 primaryFont">Address</p>
                    <div class="row address-div mt-2 ml-60-inverse" *ngIf="otherSettings.addressToggle">
                      <div class="col-sm-12 col-md-12 col-lg-6">
                        <div class="formItm">
                          <input id="address1" type="text" placeholder="line1" name="textfeild" formControlName="line1"
                            required>
                          <label for="address1" class="text-sm">Address</label>
                          <span
                            *ngIf="fOtherSettingForm?.get('line1').hasError('required') && this.fOtherSettingForm?.get('line1').touched"
                            class="error">This field is required.</span>

                        </div>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-6">
                        <div class="formItm">
                          <input id="address2" type="text" placeholder="line2" name="textfeild" formControlName="line2"
                            >
                          <label for="address2" class="text-sm">Apartment, suite, etc.</label>

                        </div>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-6 mt-3">
                        <div class="formItm">
                          <input id="city" type="text" placeholder="city" name="textfeild" formControlName="city"
                            required>
                          <label for="city" class="text-sm mt-2">City</label>
                          <span
                            *ngIf="fOtherSettingForm?.get('city').hasError('required') && fOtherSettingForm?.get('city').touched"
                            class="error">This field is required.</span>

                        </div>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-6 mt-3">
                        <div class="formItm">
                          <input id="State" type="text" placeholder="State" name="textfeild" formControlName="state"
                            required>
                          <label for="State" class="text-sm mt-2">State</label>
                          <span
                            *ngIf="fOtherSettingForm?.get('state').hasError('required') && fOtherSettingForm?.get('state').touched"
                            class="error">This field is required.</span>

                        </div>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-6 mt-3">
                        <div class="formItm">
                          <input id="Zipcode" type="text" placeholder="Postal Code" name="textfeild"
                            formControlName="postal_code" required>
                          <label for="Zipcode" class="text-sm mt-2">Zipcode</label>
                          <span
                            *ngIf="fOtherSettingForm?.get('postal_code').hasError('required') && fOtherSettingForm?.get('postal_code').touched"
                            class="error">This field is required.</span>

                        </div>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-6 mt-3">
                        <div class="formItm">
                          <input id="Country" type="text" placeholder="Country" name="textfeild"
                            formControlName="country" >
                          <label for="Country" class="text-sm mt-2">Country</label>

                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="d-flex align-items-start check_toggle_content pt-3 mt-2">
                  <div class="check_toggle">
                    <div class="form-switch toggle_switch">
                      <input class="form-check-input phone" type="checkbox" id="flexSwitchCheckChecked"
                        [checked]="otherSettings.phoneToggle" (change)="togglePhoneComponent($event)">
                    </div>
                  </div>
                  <div class="check_content w-100">
                    <p class="text-md text-gray-1 pb-1 mb-0 primaryFont">Phone Number</p>
                    <div class="row phone-div mt-2  ml-60-inverse">
                      <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="otherSettings.phoneToggle">

                        <div class="formItm">
                          <input id="phone-no" type="text" placeholder="Enter your phone" name="textfeild"
                            formControlName="phone">
                          <label for="phone-no" class="text-sm">Phone</label>
                          <span
                            *ngIf="fOtherSettingForm?.controls['phone']?.hasError('required') && fOtherSettingForm?.controls['phone']?.touched"
                            class="error">This field is required.</span>

                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </form>
          </div>
          <!-- <p class="text-gray-3 text-xs">Select a transition to be used in the video</p>
        <div>
          <label class="customRadio mb-3">
            <input type="radio" name="videoTransition" />
            <span class="customRadioCheck"></span>
            <span class="customRadioText text-sm">Fade in</span>
          </label>
          <label class="customRadio mb-3">
            <input type="radio" name="videoTransition" />
            <span class="customRadioCheck"></span>
            <span class="customRadioText text-sm">Fade out</span>
          </label>
          <label class="customRadio mb-3">
            <input type="radio" name="videoTransition" />
            <span class="customRadioCheck"></span>
            <span class="customRadioText text-sm">Cross dissolve</span>
          </label>
          <label class="customRadio mb-3">
            <input type="radio" name="videoTransition" />
            <span class="customRadioCheck"></span>
            <span class="customRadioText text-sm">Slide Left</span>
          </label>
          <label class="customRadio mb-3">
            <input type="radio" name="videoTransition" />
            <span class="customRadioCheck"></span>
            <span class="customRadioText text-sm">Slide right</span>
          </label>
          <label class="customRadio mb-3">
            <input type="radio" name="videoTransition" />
            <span class="customRadioCheck"></span>
            <span class="customRadioText text-sm">Slide up</span>
          </label>
          <label class="customRadio mb-3">
            <input type="radio" name="videoTransition" />
            <span class="customRadioCheck"></span>
            <span class="customRadioText text-sm">Slide down</span>
          </label>
        </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="side-footer side-content d-flex align-items-center footer_class">
    <button class="btn btn-primary btn-round-6 d-inline-block small-pad px-4 closeSideBar"
      (click)="TimelineViewSidepanelsComponent_SaveLooknFeelForm()">
      Save
    </button>
    <div *ngIf="bRevertSettings">
      <button class="d-inline-block small-pad text-gray-2 font-weight-noral text-md ms-3 closeSideBar"
        (click)="TimelineViewSidepanelsComponent_RevertLooknFeelForm()">
        Revert to original
      </button>
    </div>
  </div>
</nav>
<!--
<div class="modal fade overly" id="uploadModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg drag-dropfile">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Upload a Logo</h5>
                <button type="button" #closeModal class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="form-group files">
                    <div class="tab-content" id="pills-tabContent">
                        <div appDragDropFileUpload (fileDropped)="startLogoUploadProcess($event, 'dragdrop')"  class="tab-pane fade show active" id="pills-device" role="tabpanel"
                            aria-labelledby="pills-device-tab">

                            <div class="file-drop-area">



                                <span class="upload-icon m-auto"></span>
                                <span class="choose-file-button">Upload a File (0 files)</span>
                                <span class="text-line">Click to <font class="text-primary-3">browse</font>, or<br>
                                    drag & drop your file here</span>

                                <input #file class="file-input" type="file" accept="image/*"  (change)="startLogoUploadProcess($event)">
                            </div>
                        </div>

                        <ul class="nav mb-3" id="pills-tab" role="tablist">
                            <li class="col nav-item" role="presentation">
                                <button class="nav-link" (click)="file.click()" id="pills-device-tab" data-bs-toggle="pill" data-bs-target="#pills-device" type="button" role="tab" aria-controls="pills-device" aria-selected="true">
                                    <img src="../assets/images/business_Profile/device.png" alt="tab-icon">
                                    <span>My Device</span>
                                </button>
                            </li>
                          </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- error modal end -->
<nz-modal [(nzVisible)]="showLogoUploadModal" nzCentered [nzWidth]="566" [nzFooter]="null" [nzClosable]="false"
  [nzKeyboard]="false">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <!-- <button type="button" class="btn-close cust-close closeRightBtn" (click)="hideUploadingPopUP()"></button> -->

        <h5>Logo File Uploading</h5>
        <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">Your Logo is uploading, Please wait.</p>
        <!-- <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" >Ok</a> -->
      </div>
    </div>
  </div>
</nz-modal>
<!-- Modal pop-up Start-->
<nz-modal [(nzVisible)]="this.showBrandLogoSelectionPopup" nzCentered [nzWidth]="895" [nzFooter]="null"
  [nzClosable]="true" (nzOnCancel)="closeSelectBrandLogo()">
  <app-brand-logo-list (onSelectBrandLogo)="onSelectBrandLogo($event)" *ngIf="this.showBrandLogoSelectionPopup"
    [brand_id]="this.brand_id">
  </app-brand-logo-list>
</nz-modal>