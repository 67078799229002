import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { OnboardingManagerService } from "./../../../Managers/onboarding-manager.service";
import { ErrorService } from "./../../../services/error.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  fResetPasswordForm: FormGroup;
  bDisplayErrorMsg: boolean = false;
  sErrorMessage: string;
  bDisplaySuccessMsg: boolean = false;
  sSuccessMsg: string;
  constructor(
    private oFormBuilder: FormBuilder,
    private onboardingManager: OnboardingManagerService,
    private oRouter: Router,
    public errorService: ErrorService,
    private routerParams: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.ResetPasswordComponent_InitializeResetPasswordForm();

  }
  ResetPasswordComponent_InitializeResetPasswordForm() {
    const emailAddress = this.routerParams.snapshot?.queryParams?.email;
    const sEmailRejex = /(^[\w-\.]+@([\w-]+\.)+[\w-]{2,14}$)/;
    // const sEmailRejex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    // const sEmailRejex = /^[a-zA-Z0-9.'+_-]+@[a-zA-Z0-9-]+\.(?:[a-zA-Z0-9-]+)*$/;
    this.fResetPasswordForm = this.oFormBuilder.group({
      email: [
        (emailAddress) ? emailAddress : '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(sEmailRejex)
        ]
      ],
    });
  }
  ResetPasswordComponent_SendResetPasswordRequest() {
    if (this.fResetPasswordForm.valid) {
      this.onboardingManager
        .forgotPassword(this.fResetPasswordForm.get("email").value)
        .then((_) => {
          this.oRouter.navigateByUrl("/verify-email-message");
        })
        .catch((err) => {
          this.errorService.handleError("The email entered is not associated with any account. Please try again with the correct email or sign up for a new account.", "Reset Password Error", true);

        });
    } else {
      this.fResetPasswordForm.markAllAsTouched();
    }
  }
}
