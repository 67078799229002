import { MyAccountService } from './../../../../services/dataServices/my-account.service';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MediaLibrary, user, UserList, UserProfile } from "src/app/models/admin";
import { mediaLib } from "src/app/models/media";
import { ErrorService } from "src/app/services/error.service";
import { LoaderService } from "src/app/services/loader.service";
import { cSessionService } from "src/app/services/session.service";
import { environment } from "src/environments/environment";
import { ApiURL } from 'src/app/conf/api-path';

@Injectable({
  providedIn: "root",
})

export class Userservice {
  profile : user;
  users: user[] = [];
  listUser : UserList = {
    offset: 0,
    limit: 10,
    count: 0,
    result: []
  };

  sessionId: string;
  email : string;
  public selectedUser: UserProfile;
  public selectedMediaId: MediaLibrary[];
  public selectedUserMediaEmail: string;
  public userMediaLibrariesList : MediaLibrary[];
  public selectedmedialib: mediaLib;

  constructor(
    private dataService: MyAccountService,
    private loader: LoaderService,
    private errorService: ErrorService,
    public oSessionService: cSessionService,
    private http: HttpClient
  ) {
    this.sessionId = this.oSessionService.SessionService_GetSessionId();
    this.email = this.oSessionService.cSessionService_GetUserEmail();
    
  }

  setUserHeaderBreadCrumps(profile : UserProfile) {
    this.oSessionService.setHeaderBreadcrumps("userProfile", profile);     
  }

  public getUsers(    
    offset: number,
    limit: number,
    sort : string,
    asc : number,
    search_term = "",
    search_by = "_id",
    showLoading : boolean = true
  ) {
    if(showLoading)
      this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {      
      this.dataService
        .getUsers(this.email, this.sessionId, offset, limit, sort, asc, search_by, search_term)
        .then((res) => {    
          if(showLoading)      
            this.loader.hideLoader();

          this.listUser = res as UserList;          
          resolve(this.listUser);
        })
        .catch((err) => {
          console.log("into catch...");
          this.loader.hideLoader();
          this.errorService.handleError(err.errorMessage, err.errorType, true);
          if(showLoading)
            this.loader.hideLoader();

          reject(err);
        });
    });
    return promise;
  }
  

  public addUser(user: user) {    
    this.loader.showLoader();    
    let promise = new Promise((resolve, reject) => {
      this.dataService
        .addUsers(this.email, this.sessionId, user)
        .then((res) => {
          this.loader.hideLoader();
          resolve(res);
        })
        .catch((err) => {
                    
          this.errorService.handleError(err, 'Add User', true);          
          this.loader.hideLoader();
          reject(err);
        });
    });

    return promise;
  }
  

  public updateUserPermission( permission : any) {
    this.loader.showLoader();    
    let promise = new Promise((resolve, reject) => {      
      this.dataService
        .updateUserPermission(permission)
        .then((res) => {         
          this.loader.hideLoader();          
          resolve(res)
        })
        .catch((err) => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);
          this.loader.hideLoader();
          reject(err);
        });
    });

    return promise;
  }

  public getUserProfile(    
    id : string,
    showLoading : boolean = false
  ) {
    if(showLoading)     
      this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {      
      this.dataService
        .getUserProfile(id)
        .then((res) => {    
          if(showLoading)     
            this.loader.hideLoader();          

          resolve(res)
        })
        .catch((err) => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);
          this.loader.hideLoader();
          reject(err);
        });
    });

    return promise;
  }

  public getUserBrandList(_id = "") {
    this.loader.showLoader();
    let userEmail = this.email;
    if(_id && _id!= '') {
      userEmail = _id;
    }
    let promise = new Promise((resolve, reject) => {
      this.dataService
        .listUserBrands(userEmail)
        .then((res) => {
          this.loader.hideLoader();
          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError(err, err, true);
          this.loader.hideLoader();
          reject(err);
        });
    });

    return promise;
  }

  public AssignBrandToUser(
    email: string,
    sessionId: string,
    brand: string,
    userEmail: string
  ) {
    this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {
      this.dataService
        .userAssignBrand(email, sessionId, brand, userEmail)
        .then((res) => {
          this.loader.hideLoader();

          // this.users = res as user[];
          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);
          this.loader.hideLoader();
          reject(err);
        });
    });

    return promise;
  }

  public unAssignBrandToUser(email : string, sessionId : string, brand : string, userEmail : string) {
    this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {
      this.dataService
        .userUnAssignBrand(email, sessionId, brand, userEmail)
        .then((res) => {
          this.loader.hideLoader();
          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);
          this.loader.hideLoader();
          reject(err);
        });
    });

    return promise;
  }


  public searchMediaLibrary( name : string) : Promise<mediaLib[]> {
    return  new Promise((resolve, reject) => {
      return resolve([])
    });    
  }

  public deleteBrand(email : string, sessionId : string, brand: string) {
    this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {
      this.dataService
        .deleteBrands(email, sessionId, brand)
        .then((res) => {
          this.loader.hideLoader();

          // this.users = res as user[];
          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);
          this.loader.hideLoader();
          reject(err);
        });
    });

    return promise;
  }

  updateUserBrands(
    user_id : any, brand_ids : string[]
  ) {
    var data = { user_ids : [user_id], brand_ids};
    return this.http
    .post(environment.API_HOST_ADMIN + ApiURL.admin.user.addBrands, data)
    .toPromise();
  }

  deActivateUserAccount(
    _id : any
  ) {    
    return this.http
    .post(environment.API_HOST_ADMIN + ApiURL.admin.user.update, { 'status' :'inactive', _id })
    .toPromise();
  }
  activateUserAccount(
    _id : any
  ) {    
    return this.http
    .post(environment.API_HOST_ADMIN + ApiURL.admin.user.update, { 'status' :'active', _id })
    .toPromise();
  }

  updateUserMediaLibs(
    user_id : any, mediaIds : string[]
  ) {
    var data = { user_ids : [user_id], 
      media_lib_ids : mediaIds };
    return this.http
    .post(environment.API_HOST_ADMIN + ApiURL.admin.user.addMediaLib, data)
    .toPromise();
  }

  updateUser(
    user : user
  ) {  
    return this.http
    .post(environment.API_HOST_ADMIN + ApiURL.admin.user.update, user)
    .toPromise();
  }

  deActivateUser(
    user : string
  ) {  
    return this.http
    .post(environment.API_HOST_ADMIN + ApiURL.admin.user.deActivate, { _id : user})
    .toPromise();
  }

  deleteAssignedBrand(
    _id : string
  ) {  
    return this.http
    .post(environment.API_HOST_ADMIN + ApiURL.admin.user.delete_user_brand, { _id : _id})
    .toPromise();
  }

  togglePageLoader(flag : boolean) {
    if(flag){
      this.loader.showLoader();
    } else {
      this.loader.hideLoader();
    }
    
  }

  updateUserLogo(
    user_id : any, logo_file : string
  ) {
    var data = { user_ids : [user_id], logo_file};
    return this.http
    .post(environment.API_HOST_ADMIN + ApiURL.admin.user.addBrands, data)
    .toPromise();
  }

  resetUserPermission(
    email : string
  ) {    
    return this.http
    .post(environment.API_HOST_ADMIN + ApiURL.admin.user.reset_user_password, {email})
    .toPromise();
  }
}
