import { address } from './../../../models/modify-video';
import { VideoDataStore } from 'src/app/stores/video.store';
// import { duration } from 'src/app/models/video';
import { SuccessMessageModalService } from "./../../../services/success-message-modal.service";
import { AfterViewInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { ElementRef } from "@angular/core";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { MediaManagerService } from "./../../../Managers/media-manager.service";
import { CanvasMod } from "./../../../models/canva";
import { mediaFile, visual } from "./../../../models/media";
import { duration } from "./../../../models/video";
import { ErrorService } from "./../../../services/error.service";
import { ConfigManagerService } from "./../../../Managers/config-manager.service";
import { CreateVideoManagerService } from "./../../../Managers/create-video-manager.service";
import { cDataService } from "./../../../services/data.service";
import { cSessionService } from "./../../../services/session.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { UploadFileDialogComponent } from "src/app/shared/components/upload-file-dialog/upload-file-dialog.component";
import { VideoManagerService } from "src/app/Managers/video-manager.service";
import { OnboardingManagerService } from "src/app/Managers/onboarding-manager.service";
import { takeUntil } from "rxjs/operators";

declare var $: any;

@Component({
  selector: "app-content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.scss"],
})
export class ContentComponent implements OnInit, AfterViewInit, OnDestroy {
  // video content

  @ViewChild("myCanvas", { static: false }) myCanvas: ElementRef =
    {} as ElementRef;
  @ViewChild("closeModal") closeModal: ElementRef;
  @ViewChild("durationInputBoxcontainer") durationInputBoxcontainer: ElementRef;
  bLetProntoChoose: boolean = true;
  bDisplayMyMediaSection: boolean = false;
  bDisplayUploadSection: boolean = false;
  bUploadVoiceOverFile: boolean;
  lFilestoUpload: any = [];
  lUploadedFiles: any = [];
  lFailedToUpload: any = [];
  nFileToUploadIndex: number = 0;
  loading: boolean = false;
  spinner: string;
  oUploadedVoiceOverFile: any;
  sUploadedVoiceOverFileName: string = "No file choosen.";
  public nVoiceOverFileSize: number = 0;
  bDisplayFailedToUploadSection: boolean = false;
  fContentForm: FormGroup;
  sSelectedVoiceGender: string;
  sUploadedVoiceOverPlayableFile = new Audio();
  sSessionId: any;
  sUserEmail: any;
  sVideoId: any;
  public bShowTextToSpeechSection: boolean = true;
  oUserVoiceOverList: any = [];
  oVoiceOverList: any = [];
  sVoiceName: string = "voice1";
  oAudio = new Audio();
  nSelectedVoiceIndex: number;
  sDisplayResponseMessage: string;
  errorBoxHeading = "Error Occured";
  bDisplayErrorMessage: boolean = false;
  bSuccessMessage: boolean = false;
  oSavedFormData: any;
  lBlockConfigurations: any = [];
  sResetTextOverLays: string;
  sSelectedBlockID: string;
  lTextList: any = [];
  bCreateVideoMode: boolean = true;
  lUserSelectedVisuals: any = [];
  oInputVisualToRemove: any;
  lImagesList: any = [];
  lClipsList: any = [];
  sDisableEditModeToggle: string = "false";
  uploadModalHeaderText: string = "Upload Media";
  sSelectedTab: string = "video content";
  allCapss: boolean = false;
  public oHighlightedTabs: any;
  imageSrc: any;
  public vo_text: string = "";
  valX = "";
  valY = "";
  valWidth = "";
  valHeight = "";

  context: any;
  wpx = "300px";
  hpx = "300px";
  canvasArray: any = [];
  index = 0;
  CANV = [
    { x: 10, y: 10, w: 200, h: 100 },
    { x: 10, y: 10, w: 250, h: 200 },
    { x: 10, y: 10, w: 280, h: 200 },
    { x: 10, y: 10, w: 200, h: 200 },
    { x: 10, y: 10, w: 150, h: 150 },
  ];
  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedDurationIndex = 0;
  selectedDurationBlock = undefined;
  selectedDurationVideoPoint = "";
  selectedListOfDurationFields = [];
  selectedListOfAutoFillFields = [];
  noClipPopup = false;
  constructor(
    private oDataService: cDataService,

    private oSessionService: cSessionService,
    private oFormBuilder: FormBuilder,
    private oRouter: Router,
    public videoManger: CreateVideoManagerService,
    public mediaManger: MediaManagerService,
    public configManager: ConfigManagerService,
    public errorService: ErrorService,
    private sanitizer: DomSanitizer,
    private successMessage: SuccessMessageModalService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    public videoListingManager: VideoManagerService,
    private onBoardingManager: OnboardingManagerService,
    private videoDataStore: VideoDataStore
  ) { }
  sub: Subscription;
  sub2: Subscription;
  ngOnChanges() {
    // console.log("Component Changed .....");
  }
  ngOnInit() {
    this.sub2 = this.videoManger.previousSubmit.subscribe((emit) => {
      this.oRouter.navigateByUrl("/wizard/looknfeel");
    });

    this.sub = this.videoManger.submitForm.subscribe((c) => {
      this.videoManger.selectedVideo.email = this.sUserEmail;
      this.videoManger.selectedVideo.session_id = this.sSessionId;
      // console.log(" SAV REPONSE ", c);
      if ((this.videoManger.selectedVideo?.key_terms?.length > 0) || (this.bLetProntoChoose) || (this.bDisplayMyMediaSection && (this.videoManger.selectedVideo.user_clips?.length > 0 || this.videoManger.selectedVideo.user_images?.length > 0 || this.lUserSelectedVisuals?.length > 0)) || (this.bDisplayUploadSection && (this.videoManger.selectedVideo.user_clips?.length > 0 || this.videoManger.selectedVideo.user_images?.length > 0 || this.lUploadedFiles?.length > 0))) {

        console.log(" SAVE DATA ... ", this.tabSelected);
        if (c == 'contentforcedsave') {
          // console.log("forced saved ....");

          this.savecontent();
          this.saveTextOverylay();
          this.savevoice();
        } else {
          // console.log("Normal tab saved ....");

          if (this.sSelectedTab == "video content") {

            this.videoManger.wizardCurrentTab = "videocontent";
            this.videoManger.wizardCurrentTab = "";

            this.savecontent();
            if (!c || c == "") {
              this.sSelectedTab = "text overlays";
              this.videoManger.wizardCurrentTab = "textoverlays";
            }
            this.tabSelected = this.sSelectedTab;

            //   this.oHighlightedTabs.overlays = true;
          } else if (this.sSelectedTab == "text overlays") {
            // console.log("Text overlay", this.sSelectedTab);
            // console.log("Text overlay c", c);
            if (!c || c == "") {
              // console.log("Text overlay", this.sSelectedTab);
              this.sSelectedTab = "voiceover text";
              this.videoManger.wizardCurrentTab = "voicetab";
              this.tabSelected = this.sSelectedTab;

            }
            this.saveTextOverylay();
          } else {

            // console.log("step2");
            this.savevoice();
          }
        }
      }
      else {
        console.log("chknoclip");
        this.noClipPopup = true;
      }

    });
    this.ContentComponent_InitializeJqueryCode();

    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();

    this.configManager.voices.sort(this.sortAlphaNum);
    this.ContentComponent_SelectVoiceGender("male");

    this.sSelectedTab = "video content";

    this.populateTextoverlay();
    this.setUpCanvasData();
  }
  setUpCanvasData() {
    if (
      this.videoManger != null &&
      this.videoManger.durations != null &&
      this.videoManger.durations.length > 0
    ) {
      for (let index = 0; index < this.videoManger.durations.length; index++) {
        var canvasVal = new CanvasMod();

        if (this.videoManger.durations[index].pos != null) {
          canvasVal.valX = this.videoManger.durations[index].pos[0] * 465;
          canvasVal.valY = this.videoManger.durations[index].pos[1] * 256;
        } else {
          canvasVal.valX = 100;
          canvasVal.valY = 100;
        }
        if (this.videoManger.durations[index].size != null) {
          canvasVal.valWidth = this.videoManger.durations[index].size[0] * 465;
          canvasVal.valHeight = this.videoManger.durations[index].size[1] * 256;
        } else {
          canvasVal.valWidth = 0;
          canvasVal.valHeight = 0;
        }
        canvasVal.id = this.videoManger.durations[index].id;
        // console.log("ValWidth:" +canvasVal.valWidth);
        // console.log("Valheight:" +canvasVal.valHeight);

        this.canvasArray.push(canvasVal);
      }
    }
  }

  async populateTextoverlay() {
    let i = 0;
    if (
      this.videoManger.selectedVideo &&
      this.videoManger.selectedVideo.text_list
    ) {
      this.videoManger.durations.forEach((dur) => {
        dur.textlist.forEach((txt) => {
          txt.value = this.videoManger.selectedVideo.text_list[i];
          i++;
        });
      });
    }

    this.vo_text = this.videoManger.selectedVideo.vo_text;
    if (this.videoManger.selectedVideo.selected_voice) {
      this.sVoiceName = this.videoManger.selectedVideo.selected_voice;
    }
    // console.log( " this.configManager.voices ", this.configManager.voices.length);
    if (!this.configManager.voices || this.configManager.voices.length == 0) {
      // console.log("Load voice data ...");
      await this.configManager
        .getVoices(this.sUserEmail, this.sSessionId)
        .then((c) => {
          // console.log(" Get list of voices in component.....");
        });
    }

    // console.log(this.configManager.voices);
    this.configManager.voices.forEach((element) => {
      if (element.name == this.sVoiceName) {
        this.sSelectedVoiceGender = element.gender;
      }
    });
    this.ContentComponent_SelectVoiceGender(this.sSelectedVoiceGender);
  }

  saveTextOverylay() {
    this.updateTextOverlay();
    this.videoManger.selectedVideo.step = 'text_overlays';
    this.videoManger.createVideo(this.videoManger.selectedVideo).then((res) => {
      this.videoManger.duration_fine_print_text_List = [];
      if (this.videoManger.isEditMode) {
        this.oRouter.navigateByUrl("/wizard/preview");
      } else {

      }
    });
    // console.log(" After save ", this.sSelectedTab);
  }
  getMultiLineFieldValue(parentIndex) {
    const childObj = this.videoManger.durations.filter(
      (element) => element.parentIndex == parentIndex
    );
    let fieldText = "";
    childObj.map((element: any, index) => {
      element.textlist.map((ele) => {
        if (this.allCapss) {
          ele.value = ele.value.toUpperCase();
        }
        // console.log(" --" ,childObj.length ,  index);
        if (ele.value != undefined && ele.value != '' && ele.value.trim() != '') {
          fieldText += ele.value + ((childObj.length != (index + 1)) ? "\n" : "");
        }
      });
    });

    return fieldText;
  }

  private updateTextOverlay() {
    this.lTextList = [];
    // console.log(" Update Text overlay ....");
    // console.log(" -- this.videoManger.durations " , this.videoManger.durations);
    const durationOriginalSort = this.videoManger.durations;
    durationOriginalSort.sort(
      (a: any, b: any) => a.originalSortOrder - b.originalSortOrder
    );
    // console.log(" --durationOriginalSort ", durationOriginalSort);
    durationOriginalSort.map((item: any) => {
      // console.log(" Item :: ", item);
      if (item.parentIndex && item.parentIndex != '') {
        if (item.partOfId) {
          let fieldText = "";
          const partOfTextItem = durationOriginalSort.filter((dur: any) => (dur.oid == item.partOfId));
          partOfTextItem.map((element: any) => {
            element.textlist.map((ele) => {
              if (this.allCapss) {
                ele.value = ele.value.toUpperCase();
              }
              fieldText += ele.value;
            });
          });


          item.textlist.map((ele) => {
            ele.value += fieldText + "";
          });
        }
      }

    });
    const excludeMultiLineChild = durationOriginalSort.filter((element) => {
      return element.parentIndex == undefined;
    });

    const finePrintsList = [];
    excludeMultiLineChild.forEach((element) => {
      if (element.isMultiLiner) {
        const fieldValue = this.getMultiLineFieldValue(element.oid);
        // console.log(" multi line text ...", fieldValue);
        if (fieldValue && fieldValue != '\n' && fieldValue != '') {
          this.lTextList.push(fieldValue);
        }
      } else {
        element.textlist.forEach((ele) => {
          if (this.allCapss) {
            ele.value = ele.value.toUpperCase();
          }
          // if(ele.value != ''){
          this.lTextList.push(ele.value ? ele.value : "");
          // }

        });
      }
    });
    // console.log(" --- this.lTextList -- ", this.lTextList);

    this.videoManger.duration_fine_print_text_List.forEach((element) => {
      // console.log('element', element.textlist);
      element.textlist.forEach((ele) => {
        // console.log(ele);
        if (this.allCapss) {
          ele.value = ele.value.toUpperCase();
        }
        finePrintsList.push(ele.value ? ele.value : "");
      });
    });
    // console.log("SELECTED CONTENT TEXT ", this.lTextList);
    this.videoManger.selectedVideo.text_list = this.lTextList;
    this.videoManger.selectedVideo.text_list = this.lTextList ? this.lTextList : [""],
      this.videoManger.selectedVideo.fineprints_list = finePrintsList;
  }

  reDefinedBGColorOfMiltiLineFields(textField) {
    if (textField.parentIndex) {
      const multiGroup = document.querySelectorAll(
        ".row-multi-" + textField.parentIndex
      );
      let loop = 0;
      multiGroup.forEach((item: any) => {
        // console.log(item.classList);
        if (
          item.classList.value &&
          item.classList.value.indexOf("multiliner-group") == -1
        ) {
          item.classList.value = item.classList.value + " multiliner-group";
          if (loop == 0) {
            item.classList.value += " first";
          }
          if (loop == multiGroup.length) {
            item.classList.value += " last";
          }
          loop++;
        }
        // console.log("--",item.classList);
      });
      const multiGroupLine = document.querySelectorAll(
        ".line-no-" + textField.parentIndex
      );
      let loopLines = 1;
      multiGroupLine.forEach((item: any) => {
        // console.log(item.classList);
        item.innerText = "Line " + loopLines;
        loopLines++;
      });

    }
  }
  removeReDefinedBGColorOfMiltiLineFields(textField) {
    if (textField.parentIndex) {
      document
        .querySelectorAll(".row-multi-" + textField.parentIndex)
        .forEach((item: any) => {
          // console.log(item.classList);
          if (
            item.classList.value &&
            item.classList.value.indexOf("multiliner-group") > -1
          ) {
            item.classList.value = item.classList.value
              .replace("multiliner-group", "")
              .replace("first", "")
              .replace("last", "");
          }
          // console.log(" -- ", item.classList);
        });
    }
  }
  savecontent() {
    this.updateContent();

    // console.log(this.videoManger.selectedVideo);
    this.videoManger.selectedVideo.step = 'video_content';
    this.videoManger.createVideo(this.videoManger.selectedVideo).then((res) => {
      if (this.videoManger.isEditMode) {
        this.oRouter.navigateByUrl("/wizard/preview");
      }
    });
  }
  generateVideo() {
    this.onBoardingManager.loggedUser.publishedVideoCount++;
    this.onBoardingManager.loggedUser.videoCount++;
    this.oSessionService.cSessionService_SetUserVideosCount(this.onBoardingManager.loggedUser.publishedVideoCount);
    this.videoListingManager.selectedVideo = this.videoManger.selectedVideo;
    this.oRouter.navigateByUrl("/wizard/preview");
  }

  private updateContent() {
    this.videoManger.selectedVideo.use_pronto_clips = this.bLetProntoChoose;

    this.lImagesList = [];
    this.lClipsList = [];
    if (this.bDisplayUploadSection) {
      for (
        let nVisualIndex = 0;
        nVisualIndex < this.lUploadedFiles.length;
        nVisualIndex++
      ) {
        const element = this.lUploadedFiles[nVisualIndex];
        const extension = element.filename.split(".").pop();
        if (
          extension === "png" ||
          extension === "jpg" ||
          extension === "jpeg"
        ) {
          this.lImagesList.push(element.fpath);
        } else {
          this.lClipsList.push(element.fpath);
        }
      }
    }
    if (this.bDisplayMyMediaSection) {
      for (
        let nVisualIndex = 0;
        nVisualIndex < this.lUserSelectedVisuals.length;
        nVisualIndex++
      ) {
        const element = this.lUserSelectedVisuals[nVisualIndex] as visual;
        const extension = element.visual_type;
        if (element.visual_type == "image") {
          this.lImagesList.push(element.visual_path);
        } else if (element.visual_type == "clip") {
          this.lClipsList.push(element.visual_path);
        }
      }
    }
    this.videoManger.selectedVideo.user_clips = this.lClipsList;
    this.videoManger.selectedVideo.user_images = this.lImagesList;
    if (
      !this.selectedDurationBlock &&
      this.videoManger.durations &&
      this.videoManger.durations.length > 0
    ) {
      this.selectedDurationBlock = this.videoManger.durations[0];
    }
    const __this = this;
    this.selectedDurationIndex = 0;
    setTimeout(() => {
      this.selectDurationblock(__this.selectedDurationBlock, 0);
    }, 500);
  }

  get videoData() {
    return this.videoManger.selectedVideo;
  }

  noVoiceSelected = false;
  savevoice(forceStay = false) {
    this.updateVoice();

    this.videoManger.selectedVideo.step = 'voice_over';
    this.noVoiceSelected = false;
    this.videoManger.createVideo(this.videoManger.selectedVideo).then((res) => {
      if (this.videoManger.isEditMode) {

      } else {
        let allowSave = true;
        // console.log(this.videoData);
        if (this.videoData.voice_wellsaid_selection == 'voiceover'
          && ((!this.videoData.vo_file || this.videoData.vo_file == '') && this.videoData.voiceGenSize > 0)) {
          allowSave = false;
          this.errorService.handleError(
            'Please select voiceover to proceed.',
            "No voice over selected",
            true,
            false,
            false
          );
          //this.noVoiceSelected = true;
        }
        if (allowSave) {
          if (this.videoDataStore.selectedTemplate && this.videoDataStore.selectedTemplate?.address_fields == false) {
            // console.log('GENERATE VIDEO .....');
            this.generateVideo();
          } else {
            if (!forceStay) {
              this.oRouter.navigateByUrl("/wizard/other-setting");
            }
          }
        } else {
          // console.log('Select Voice File ...');
        }

      }
    });
  }
  private updateVideo(attr: string, val: any | any[]) {
    this.videoManger.selectedVideo[attr] = val;
  }
  private updateVoice() {
    // console.log(" current selection ", this.videoManger.selectedVideo?.voice_wellsaid_selection);
    if (this.videoManger.selectedVideo?.voice_wellsaid_selection) {

      if (this.videoManger.selectedVideo?.voice_wellsaid_selection == 'voiceover') {
        if (this.videoManger.selectedVideo?.vo_file_url == undefined) {
          this.updateVideo('vo_text', undefined);
          this.updateVideo('vo_file', undefined);
          this.updateVideo('voice_wellsaid_id', undefined);
        }
        this.updateVideo('voice_file', undefined);
      }
      if (this.videoManger.selectedVideo?.voice_wellsaid_selection == 'upload') {
        this.updateVideo('vo_text', undefined);
        this.updateVideo('vo_file', undefined);
        this.updateVideo('voice_wellsaid_id', undefined);
      }
    }
  }

  ContentComponent_HighlightTabs() {
    if (this.oHighlightedTabs.overlays) {
      this.ContentComponent_CheckTextOverlaysTab();
    }
    if (this.oHighlightedTabs.voiceover) {
      this.ContentComponent_CheckVoiceOverTextTab();
    }
    if (this.oHighlightedTabs.content) {
      this.ContentComponent_CheckVideoContentTab();
    }
  }
  ContentComponent_NavigateToDashboard() {
    this.oSessionService.cSessionService_ResetHighlightedTabs();
    let videoCountCheck =
      this.oSessionService.cSessionService_GetUserVideosCount();
    if (videoCountCheck > 0) {
      this.oRouter.navigateByUrl("/dashboard/myvideos");
    } else {
      this.oRouter.navigateByUrl("/dashboard/first-time-user");
    }
  }
  ContentComponent_CheckVideoContentTab() {
    this.changeHeaderNavByForce("videoContent_tab");
    this.sSelectedTab = "video content";
    this.videoManger.wizardCurrentTab = "videoContent";
    let selectedTab = document.getElementById("Video-Content-tab");
    if (selectedTab.classList.contains("active")) {
      this.uploadModalHeaderText = "Upload Media";
    } else {
      this.uploadModalHeaderText = "Upload Media";
    }
    this.oHighlightedTabs.content = true;
    this.tabSelected = 'video content';
  }
  ContentComponent_CheckTextOverlaysTab() {
    this.changeHeaderNavByForce("textoverlay_tab");
    this.sSelectedTab = "text overlays";
    this.videoManger.wizardCurrentTab = "textoverlays";
    // console.log(" Selected block ... ", this.selectedDurationBlock);
    if (
      !this.selectedDurationBlock &&
      this.videoManger.durations &&
      this.videoManger.durations.length > 0
    ) {
      this.selectedDurationBlock = this.videoManger.durations[0];
    }
    const __this = this;
    this.selectedDurationIndex = 0;
    setTimeout(() => {
      this.selectDurationblock(__this.selectedDurationBlock, 0);
    }, 500);
    this.tabSelected = 'text overlays';
  }
  ContentComponent_CheckVoiceOverTextTab() {
    this.changeHeaderNavByForce("voicetab");
    this.sSelectedTab = "voiceover text";
    this.videoManger.wizardCurrentTab = "voice";
    let selectedTab = document.getElementById("Video-Content-tab");
    if (selectedTab.classList.contains("active")) {
      this.uploadModalHeaderText = "Upload Media";
    } else {
      this.uploadModalHeaderText = "Upload Media";
    }
    this.tabSelected = 'voicetab';
  }

  changeHeaderNavByForce(tab: string) {
    this.videoManger.changeHeaderNavByForce.emit({ "type": tab, action: "force" });
  }
  ngOnDestroy() {
    if (this.sub2) this.sub2.unsubscribe();
    if (this.sub) this.sub.unsubscribe();

    if (this.videoManger.isEditMode) {
      this.updateContent();
      this.updateTextOverlay();
      this.updateVoice();
    }
    if (this.intervalContentPreviewVideoPlay) {
      clearInterval(this.intervalContentPreviewVideoPlay);
    }
  }

  ///////////////////////////// Create video request /////////////////////////////////
  CreateVideoContentComponent_SendContentFormRequest() {
    this.oHighlightedTabs =
      this.oSessionService.cSessionService_GetHighlightTabs();
    //send request if all tabs are visited
    if (this.oHighlightedTabs.overlays && this.oHighlightedTabs.voiceover) {
      this.ContentComponent_UpdateTextList();
      let oCreateVideoPayload = {
        session_id: this.sSessionId,
        email: this.sUserEmail,
        video_id: this.sVideoId,
        user_images: [],
        user_clips: [],
        pronto_clips: this.bLetProntoChoose,
        text_list: this.lTextList ? this.lTextList : [""],
        vo_text: "",
        selected_voice: "",
        voice_file: "",
      };
      this.lImagesList = [];
      this.lClipsList = [];
      //Extract user images and clips from uploaded images
      if (this.bDisplayUploadSection) {
        for (
          let nVisualIndex = 0;
          nVisualIndex < this.lUploadedFiles.length;
          nVisualIndex++
        ) {
          const element = this.lUploadedFiles[nVisualIndex];
          const extension = element.filename.split(".").pop();
          if (
            extension === "png" ||
            extension === "jpg" ||
            extension === "jpeg"
          ) {
            this.lImagesList.push(element.fpath);
          } else {
            this.lClipsList.push(element.fpath);
          }
        }
      }
      //Extract images and clips from user media
      if (this.bDisplayMyMediaSection) {
        for (
          let nVisualIndex = 0;
          nVisualIndex < this.lUserSelectedVisuals.length;
          nVisualIndex++
        ) {
          const element = this.lUserSelectedVisuals[nVisualIndex];
          const extension = element.name.split(".").pop();
          if (
            extension === "png" ||
            extension === "jpg" ||
            extension === "jpeg"
          ) {
            this.lImagesList.push(element.visual_path);
          } else {
            this.lClipsList.push(element.visual_path);
          }
        }
      }
      oCreateVideoPayload.user_clips = this.lClipsList;
      oCreateVideoPayload.user_images = this.lImagesList;
      //if user enters voice over text
      if (this.bShowTextToSpeechSection) {
        oCreateVideoPayload.vo_text = this.fContentForm.get("vo_text").value;
        oCreateVideoPayload.selected_voice =
          this.fContentForm.get("selected_voice").value;
        delete oCreateVideoPayload.voice_file;
      }
      //if user uploads voice over file
      else {
        const oVoiceOverFile = this.fContentForm.get(
          "oUploadedVoiceOverFile"
        ).value;
        oCreateVideoPayload.voice_file = oVoiceOverFile.fpath;
        delete oCreateVideoPayload.vo_text;
        delete oCreateVideoPayload.selected_voice;
      }
      // console.log("Payload ==> ", oCreateVideoPayload);
    }
    // else visit remaining tabs
    else {
      if (this.oHighlightedTabs.overlays) {
        this.sSelectedTab = "voiceover text";
        this.ContentComponent_CheckVoiceOverTextTab();
      } else {
        this.sSelectedTab = "text overlays";
        this.ContentComponent_CheckTextOverlaysTab();
      }
    }
  }
  CreateVideoContentComponent_HandleContentResponse = (InComingMessage) => {
    if (JSON.parse(InComingMessage)["errorMessage"]) {
      this.errorBoxHeading = "Upload Failed";
      this.sDisplayResponseMessage =
        "An error occurred while creating video. Please try again.";
      console.log(
        "CreateVideoContentComponent_HandleContentResponse : Request Error ==>",
        InComingMessage
      );
      this.bDisplayErrorMessage = true;
      $("#error-popup").modal("show");
      setTimeout(() => {
        this.bDisplayErrorMessage = false;
      }, 5000);
    } else {
      const oResponse = JSON.parse(InComingMessage);
      this.oSessionService.cSessionService_SetResetTextOverlayCheck("false");
      console.log(
        "CreateVideoContentComponent_HandleContentResponse : Request result response ==> ",
        oResponse
      );
      this.oSessionService.cSessionService_SetContentForm(
        this.fContentForm.value
      );
      if (this.bCreateVideoMode) {
        this.oSessionService.cSessionService_SetGeneratedVideoCheck(false);
        this.oRouter.navigateByUrl("/wizard/other-setting");
      } else {
        this.oSessionService.cSessionService_SetGeneratedVideoCheck(true);
        this.oSessionService.cSessionService_SetResetTextOverlayCheck(false);
        this.oRouter.navigateByUrl("/dashboard/overview");
      }
    }
  };
  CreateVideoContentComponent_UpdateVideo(bUpdateVideo: boolean) { }

  ///////////////////////////// VoiceOver Section /////////////////////////////////

  async ContentComponent_UploadedVoiceOverFile(event) {
    let file = event.target.files[0];

    // var oFormData: any = new FormData();
    // oFormData.append("session_id", this.sSessionId);
    // oFormData.append("email", this.sUserEmail);
    // oFormData.append("file", file);
    this.successMessage.success(
      "Audio file is uploading, please wait",
      "File Upload",
      true,
      false
    );
    let media = new mediaFile();
    media.file = file;
    media.name = file.name;
    try {
      await this.mediaManger
        .uploadMediaDefaultLibrary(this.sUserEmail, this.sSessionId, media)
        .then(
          (result: any) => {
            this.ContentComponent_UploadVoiceover();
            this.nVoiceOverFileSize = file.size;
            this.successMessage.close();
            console.log(
              "ContentComponent_UploadedVoiceOverFile : Response ==>",
              result
            );
            this.oUploadedVoiceOverFile = result;
            this.sUploadedVoiceOverFileName = file.name;
            this.bSuccessMessage = true;
            setTimeout(() => {
              this.bSuccessMessage = false;
            }, 5000);
            this.errorBoxHeading = "Upload Failed";
            this.sDisplayResponseMessage =
              "file " +
              this.sUploadedVoiceOverFileName +
              " uploaded successfully.";
            this.closeModal.nativeElement.click();
            if (this.fContentForm?.patchValue) {
              this.fContentForm.patchValue({
                oUploadedVoiceOverFile: this.oUploadedVoiceOverFile,
                nVoiceOverFileSize: this.nVoiceOverFileSize,
              });
            }
          },
          (error) => {
            this.successMessage.close();
            this.errorService.handleError(
              error.errorMessage ? error.errorMessage : error.error,
              "Upload Failed",
              true,
              false,
              false
            );
            this.oUploadedVoiceOverFile = null;
            this.closeModal.nativeElement.click();
          }
        );
    } catch (error) {
      this.successMessage.close();
      this.errorService.handleError(
        error.errorMessage ? error.errorMessage : error.error,
        "Upload Failed",
        true,
        false,
        false
      );
    }
  }
  ContentComponent_TextToSpeech() {
    this.bShowTextToSpeechSection = true;
    this.nVoiceOverFileSize = 0;
    this.ContentComponent_SelectVoiceGender("male");
  }
  ContentComponent_UploadVoiceover() {
    this.bShowTextToSpeechSection = false;
  }
  ContentComponent_SelectVoiceGender(value: any) {
    this.sSelectedVoiceGender = value;
    this.sVoiceName = "";
    this.oVoiceOverList = [];
    if (this.configManager.voices) {
      // console.log(this.configManager.voices);
      this.configManager.voices.forEach((element) => {
        if (element.gender == value) {
          this.oVoiceOverList.push(element);
        }
      });

      this.sVoiceName = this.oVoiceOverList[0]?.name;
    }
    if (
      this.videoManger?.selectedVideo &&
      this.videoManger?.selectedVideo?.selected_voice
    ) {
      this.sVoiceName = this.videoManger.selectedVideo.selected_voice;
    }
  }
  ContentComponent_SelectVoice(select, index) {
    this.nSelectedVoiceIndex = index;
    this.sVoiceName = select?.name;
  }
  ContentComponent_PlayAudio(filepath) {
    let filename = filepath.replace(/^.*(\\|\/|\:)/, "");
    // console.log(filepath);
    this.oAudio = new Audio();
    this.oAudio.src = filepath;
    this.oAudio.load();
    this.oAudio.play();
  }
  ContentComponent_StopAudio(filepath) {
    let filename = filepath.replace(/^.*(\\|\/|\:)/, "");
    // console.log(filepath);
    if (this.oAudio.src.replace(/^.*(\\|\/|\:)/, "") == filename) {
      this.oAudio.pause();
      this.oAudio = new Audio();
      this.oAudio.src = filepath;
      this.oAudio.load();
    }
  }

  ///////////////////////////// Text overlay Section /////////////////////////////////
  selectedDuration: duration;
  eventListernerFocusOnDurationField(sIncommingBlockID: string, duration = 0) {
    this.sSelectedBlockID = sIncommingBlockID;
    this.selectedDuration = this.videoManger.durations.filter(
      (c) => c.id == sIncommingBlockID
    )[0];
    // console.log("SelectedDuration:", this.selectedDuration.id);
    for (let i = 0; i < this.canvasArray.length; i++) {
      if (this.selectedDuration && this.selectedDuration.id && this.selectedDuration.id == this.canvasArray[i].id) {
        // console.log("SelectedDurationIndex:", i);
        this.index = i;
        this.updateCanvas();
      }
    }
    if (duration) {
      // this.selectedDurationVideoPoint = this.videoManger.selectedVideo.video_preview_url+"#t="+ duration+","+ (duration + 1);
    }
  }
  ContentComponent_PopulateTextList(
    event: any,
    nIncommingOuterIndex: number,
    nIncommingInnerIndex: number
  ) {
    let sValue = event.target.value;
    this.lBlockConfigurations[nIncommingOuterIndex].lTextAreaDetails[
      nIncommingInnerIndex
    ].value = sValue;
    console.log(
      "ContentComponent_PopulateTextList At block {%s} with index {%s}",
      nIncommingOuterIndex,
      nIncommingInnerIndex
    );
  }
  ContentComponent_UpdateTextList() {
    this.lTextList = [];
    this.lBlockConfigurations.forEach((element) => {
      element.lTextAreaDetails.forEach((element) => {
        this.lTextList.push(element.value);
      });
    });
    console.log(
      "ContentComponent_UpdateTextList : Text List==> ",
      this.lTextList
    );
  }

  ///////////////////////////// Video content Section /////////////////////////////////

  ContentComponent_ToggleLetProntoChoose(event: any) {
    this.bLetProntoChoose = event.target.checked;
    this.fContentForm.patchValue({
      pronto_clips: this.bLetProntoChoose,
      bLetProntoChooseToggle: this.bLetProntoChoose,
    });
  }
  LetProntoChooseToggle(event: any) {
    this.bLetProntoChoose = event.target.checked;
  }

  ContentComponent_ToggleMyMediaLibrary(event: any) {
    this.bDisplayMyMediaSection = event.target.checked;
  }
  ContentComponent_ToggleFileUploadType(nIncommingType: boolean) {
    this.bUploadVoiceOverFile = nIncommingType;
  }
  ContentComponent_ToggleFileUploadSection(event: any) {
    this.bDisplayUploadSection = event.target.checked;
  }
  ContentComponent_ToggleFailedToUploadSection(event: any) {
    this.bDisplayFailedToUploadSection = event.target.checked;
  }
  ContentComponent_RemoveFileFromList(sIncommingVisualPath: string) {
    // console.log("income", sIncommingVisualPath);
    // console.log("income", this.lUploadedFiles);
    for (
      let nFiletoRemoveIndex = 0;
      nFiletoRemoveIndex < this.lUploadedFiles.length;
      nFiletoRemoveIndex++
    ) {
      if (
        this.lUploadedFiles[nFiletoRemoveIndex].fpath === sIncommingVisualPath
      ) {
        console.log("remove Index", nFiletoRemoveIndex);

        this.lUploadedFiles.splice(nFiletoRemoveIndex, 1);
      }
    }
    if (this.lUserSelectedVisuals.length == 0) {
      this.videoManger.selectedVideo.user_images = [];
      this.videoManger.selectedVideo.clips = [];
    }
  }
  ContentComponent_RemoveFailedFileFileFromList(sIncommingFileName: string) {
    for (
      let nFiletoRemoveIndex = 0;
      nFiletoRemoveIndex < this.lFailedToUpload.length;
      nFiletoRemoveIndex++
    ) {
      if (
        this.lFailedToUpload[nFiletoRemoveIndex].name === sIncommingFileName
      ) {
        this.lFailedToUpload.splice(nFiletoRemoveIndex, 1);
      }
    }
  }
  ContentComponent_SelectFilesForUpload(event) {
    // console.log("event", event);
    this.nFileToUploadIndex = 0;
    this.lFilestoUpload = event.target.files;
    for (
      let nFileUploadIndex = 0;
      nFileUploadIndex < this.lFilestoUpload.length;
      nFileUploadIndex++
    ) {
      if (
        this.lFilestoUpload[nFileUploadIndex].type.indexOf("image") > -1 ||
        this.lFilestoUpload[nFileUploadIndex].type.indexOf("video") > -1
      ) {
        this.ContentComponent_UploadFile(this.lFilestoUpload[nFileUploadIndex]);
      } else {
        this.errorService.handleError(
          "Please choose audio file",
          "File upload",
          true
        );
      }
    }
  }
  async ContentComponent_UploadFile(fileToUpload: any) {
    let media = new mediaFile();
    media.file = fileToUpload;
    media.name = fileToUpload.name;
    this.successMessage.info("File upload in progress.", "File upload", true);
    this.closeModal.nativeElement.click();
    try {
      await this.mediaManger
        .uploadMediaDefaultLibrary(this.sUserEmail, this.sSessionId, media)
        .then(
          (result: visual) => {
            this.successMessage.close();
            this.lUploadedFiles.push({
              filename: result.name,
              fpath: result.visual_path,
              thumb: result.thumbnail,
              url: result.url,
              size: this.lFilestoUpload[this.nFileToUploadIndex].size,
            });
            this.nFileToUploadIndex++;
            this.closeModal.nativeElement.click();
          },
          (error) => {
            this.successMessage.close();
            this.errorService.handleError(
              error.errorMessage ? error.errorMessage : error.error,
              error.errorType,
              true
            );
            this.lFailedToUpload.push(fileToUpload);

            // this.errorBoxHeading = 'Upload Failed';
            // this.sDisplayResponseMessage = error.errorMessage
            //   ? error.errorMessage
            //   : error.error;

            // // $("#error-popup").modal("show");
            // this.bDisplayErrorMessage = true;
            // setTimeout(() => {
            //   this.bDisplayErrorMessage = false;
            // }, 5000);
          }
        )
        .catch((error) => {
          this.successMessage.close();
          this.errorService.handleError(
            error.errorMessage,
            error.errorType,
            true
          );
        });
    } catch (error) {
      this.errorService.handleError(error.errorMessage, error.errorType, true);
    }
  }
  ContentComponent_ReturnFileSize(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  ContentComponent_lEmittedFilesToUpload(lIncommingFiles: any) {
    // console.log(
    //   "ContentComponent_lEmittedFilesToUpload : Selected files ==> ",
    //   lIncommingFiles
    // );
    this.lUserSelectedVisuals = lIncommingFiles;
    this.fContentForm.patchValue({
      lUserSelectedVisuals: this.lUserSelectedVisuals,
    });
  }
  removeSelectedVisuals(oIncommingVisual: any) {
    let nVisualIndex = this.lUserSelectedVisuals.indexOf(oIncommingVisual);
    this.lUserSelectedVisuals.splice(nVisualIndex, 1);
    this.oInputVisualToRemove = oIncommingVisual;
    if (this.lUserSelectedVisuals.length == 0) {
      this.videoManger.selectedVideo.user_images = [];
      this.videoManger.selectedVideo.clips = [];
    }
  }
  ContentComponent_ToggleView(event) {
    if (event.target.checked) {
      this.oRouter.navigateByUrl("/wizard/overview");
    } else {
      this.oRouter.navigateByUrl("edit-video/timeline-view");
    }
  }
  ContentComponent_InitializeJqueryCode() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.text = `var elSelectCustom = document.getElementsByClassName("js-selectCustom")[0];
     var elSelectCustomValue = elSelectCustom.children[0];
     var elSelectCustomOptions = elSelectCustom.children[1];
     var defaultLabel = elSelectCustomValue.getAttribute("data-value");

     // Listen for each custom option click
     Array.from(elSelectCustomOptions.children).forEach(function (elOption) {
       elOption.addEventListener("click", (e) => {
         // Update custom select text too
         elSelectCustomValue.textContent = e.target.textContent;
         // Close select
         elSelectCustom.classList.remove("isActive");
       });
     });

     // Toggle select on label click
     elSelectCustomValue.addEventListener("click", (e) => {
       elSelectCustom.classList.toggle("isActive");
     });

     // close the custom select when clicking outside.
     document.addEventListener("click", (e) => {
       const didClickedOutside = !elSelectCustom.contains(event.target);
       if (didClickedOutside) {
         elSelectCustom.classList.remove("isActive");
       }
     });


         // smooth Loaded  start
     document.addEventListener("DOMContentLoaded", function(){

     var scrollSpy = new bootstrap.ScrollSpy(document.body, {
     target: '#main_nav',
     })

     // add smooth animated scroll via javascript
     document.querySelectorAll('.scrollto').forEach(function(element){
     element.addEventListener('click', function(e) {
         e.preventDefault();
         var section_name = element.getAttribute('data-bs-target');
         var offset_num = document.querySelector(section_name).offsetTop;
           window.scrollTo({ top:(offset_num - 0), behavior:'smooth' });
     });
     })

     });
     // smooth Loaded  end

                     var selector = '#main_nav li a';

                       $(selector).on('click', function(){
                           $(selector).removeClass('active');
                           $(this).addClass('active');

                           $('.video-timeline').removeClass('active')
                           $($(this).attr("data-bs-target")).addClass('active')
                       });`;
    document?.body?.appendChild(chatScript);
  }
  mediaSelected(mediaList) {
    mediaList.map((media) => {
      if (this.isMediaAlreadySelected(media)) {
        this.lUserSelectedVisuals.push(media);
      }
    });

    this.visible = false;
  }

  isMediaAlreadySelected(media) {
    const isExisit = this.lUserSelectedVisuals.findIndex(
      (item) => media.visual_path === item.visual_path
    );
    return isExisit == -1 ? true : false;
  }
  // modal media library
  visible = false;
  showMediaModal() {
    this.visible = true;
  }
  handleMediaOk(): void {
    // console.log("Button ok clicked!");
    this.visible = true;
  }
  closeMediaModal(): void {
    // console.log("Button cancel clicked!");
    this.visible = false;
  }

  sortAlphaNum(a, b) {
    // console.log(a);
    // console.log(b);
    let reA = /[^a-zA-Z]/g;
    let reN = /[^0-9]/g;
    var aA = a.name.replace(reA, "");
    var bA = b.name.replace(reA, "");

    if (aA === bA) {
      var aN = parseInt(a.name.replace(reN, ""), 10);
      var bN = parseInt(b.name.replace(reN, ""), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  }
  //below both functions is for showing thumbnail of img(local file), while uploading failed
  readURL(file) {
    this.imageSrc = URL.createObjectURL(file);
    return this.imageSrc;
  }
  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

  allCaps(event) {
    if (event.target.checked) {
      this.videoManger.durations.forEach((element) => {
        element.textlist.forEach((el) => {
          el.value = el.value.toUpperCase();
        });
      });
    } else {
      this.videoManger.durations.forEach((element) => {
        element.textlist.forEach((el) => {
          el.value = el.value.toLowerCase();
        });
      });
    }
  }

  ngAfterViewInit(): void {
    this.updateCanvas();

    var sIncommingBlockID = this.videoManger.durations[this.index].id;
    this.sSelectedBlockID = sIncommingBlockID;
    this.selectDurationblock(this.videoManger.durations[this.index], 0);
    this.selectedDurationBlock = this.videoManger.durations[this.index];
    this.selectedDuration = this.videoManger.durations.filter(
      (c) => c.id == sIncommingBlockID
    )[0];
  }
  updateCanvas() {
    this.valX = this.canvasArray[this.index].valX + "px";
    this.valY = this.canvasArray[this.index].valY + "px";
    this.valWidth = this.canvasArray[this.index].valWidth + "px";
    this.valHeight = this.canvasArray[this.index].valHeight + "px";
    // console.log("valx", this.canvasArray[this.index].valX);
    // console.log("valy", this.canvasArray[this.index].valY);
    // console.log("valw", this.canvasArray[this.index].valWidth);
    // console.log("valh", this.canvasArray[this.index].valHeight);
  }
  nextcanva() {
    if (this.index != this.canvasArray.length - 1) {
      this.index++;
      var sIncommingBlockID = this.videoManger.durations[this.index].id;
      this.sSelectedBlockID = sIncommingBlockID;
      this.selectedDuration = this.videoManger.durations.filter(
        (c) => c.id == sIncommingBlockID
      )[0];
      this.updateCanvas();
    }
  }

  precanva() {
    if (this.index != 0) {
      this.index--;
      var sIncommingBlockID = this.videoManger.durations[this.index].id;
      this.sSelectedBlockID = sIncommingBlockID;
      this.selectedDuration = this.videoManger.durations.filter(
        (c) => c.id == sIncommingBlockID
      )[0];
      this.updateCanvas();
    }
  }
  durationFieldChange($event, blockId) {
    console.log(blockId);
    console.log($event);
  }

  // Upload image or video
  openFileUploadModal(uploadFileType, allowDuplicate = false) {
    const heading = "Upload Media";

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "100wv";
    dialogConfig.height = "100vh";
    dialogConfig.panelClass = "pronto-dialog";
    dialogConfig.closeOnNavigation = true;

    dialogConfig.data = {
      uploadFor: uploadFileType,
      multipleUpload: false,
      allowDuplicate: allowDuplicate,
      dropBoxText: "",
      heading: heading,
      mediaLibraryId: ((this.videoManger.selectedVideo?.brand_id
        && this.videoManger.videoBrand?.media_libs
        && this.videoManger.videoBrand?.media_libs.length > 0)
        ? this.videoManger.videoBrand?.media_libs[0]._id?.$oid : undefined)
    };

    if (!dialogConfig.data.mediaLibraryId) {
      delete dialogConfig.data.mediaLibraryId;
    }

    let dialogModal = this.dialog.open(UploadFileDialogComponent, dialogConfig);

    dialogModal.afterClosed().subscribe((data: any) => {
      if (data.status == "success") {
        const result = data.result[0];
        this.successMessage.close();
        if (uploadFileType == "audio") {
          this.ContentComponent_UploadVoiceover();
          this.nVoiceOverFileSize = result.size;
          this.successMessage.close();
          this.oUploadedVoiceOverFile = result;
          this.sUploadedVoiceOverFileName = result.name;

          this.bShowTextToSpeechSection = false;
          this.updateVoice();

          if (this.fContentForm?.patchValue) {
            this.fContentForm.patchValue({
              oUploadedVoiceOverFile: this.oUploadedVoiceOverFile,
              nVoiceOverFileSize: this.nVoiceOverFileSize,
            });
          }
        } else {
          this.lUploadedFiles.push({
            filename: result.name,
            fpath: result.visual_path,
            thumb: result.thumbnail,
            url: result.url,
            size: result.size,
          });
          this.nFileToUploadIndex++;
        }

        this.cd.detectChanges();
      } else {
      }
    });
    this.mediaManger.replaceMediaFromVisualPopup.pipe(takeUntil(this.destroy$)).subscribe((item: any) => {

      if (item && item.length > 0 && item[0].visual_type == 'audio') {
        let result = item[0];
        this.ContentComponent_UploadVoiceover();
        this.nVoiceOverFileSize = result.size;
        this.successMessage.close();
        this.oUploadedVoiceOverFile = result;
        this.sUploadedVoiceOverFileName = result.name;
        this.videoManger.selectedVideo.voice_file = result.fpath;
        this.bShowTextToSpeechSection = false;
        this.updateVoice();

        if (this.fContentForm?.patchValue) {
          this.fContentForm.patchValue({
            oUploadedVoiceOverFile: this.oUploadedVoiceOverFile,
            nVoiceOverFileSize: this.nVoiceOverFileSize,
          });
        }
      }
      this.mediaManger.showMediaReplacementPopup = false;
      this.cd.detectChanges();
    })
  }

  checkAutoFillField(duration) {
    this.selectedListOfAutoFillFields = [];
    this.selectedListOfAutoFillFields =
      this.videoManger.duration_fine_print_text_List.filter((item) => {
        return (
          item.duration >= Math.floor(duration) &&
          item.duration < Math.floor(duration) + 1
        );
      });
  }

  intervalContentPreviewVideoPlay = undefined;
  selectDurationblock(block: any, index: number): void {
    if (this.intervalContentPreviewVideoPlay) {
      clearInterval(this.intervalContentPreviewVideoPlay);
    }
    // console.log(" Duration : on load ",this.videoManger.durations);
    // console.log(" Block Selected  : on load ",block);
    this.selectedListOfDurationFields = [];
    this.selectedDurationIndex = index;
    this.sSelectedBlockID = block;
    this.selectedDurationBlock = block;
    this.selectedDuration = this.videoManger.durations.filter(
      (c) => c.id == block.id
    )[0];

    this.selectedListOfDurationFields = this.videoManger.durations.filter(
      (item) => {
        return (
          item.duration >= Math.floor(block.duration) &&
          item.duration < Math.floor(block.duration) + 1 &&
          !item.isMultiLiner && !item.partOfId
        );
      }
    );
    // console.log("  Loading Fields List ", this.selectedListOfDurationFields);

    this.checkAutoFillField(block.duration);
    // this.selectedDurationVideoPoint = this.videoManger.selectedVideo.video_preview_url+"#t="+ this.selectedListOfDurationFields[0].duration;
    // console.log(" this.videoManger.selectedVideo ", this.videoManger.selectedVideo)
    // console.log(" this.videoManger.selectedVideo.video_preview_url ", this.videoManger.selectedVideo.video_preview_url)
    this.selectedDurationVideoPoint =
      this.videoManger.selectedVideo.video_preview_url;
    // console.log(
    //   " this.videoManger.selectedVideo ",
    //   this.videoManger.selectedVideo.duration
    // );
    let videoEle: any = document.querySelector(".content-section-player");
    let endTime = 0;
    if (videoEle) {
      videoEle.currentTime = this.selectedListOfDurationFields[0].duration;
      endTime = this.animationDuration(
        this.selectedListOfDurationFields[0].duration
      );
      videoEle.endTime = endTime;
      // videoEle.mute();
      // console.log("animation time ", videoEle.currentTime, videoEle.endTime);
      let __this = this;
      let intervalLength = 2000;
      let diffDur = endTime - this.selectedListOfDurationFields[0].duration;
      if (diffDur > 1 && diffDur <= 2) {
        intervalLength = 2600;
      }
      if (diffDur > 2) {
        intervalLength = 4000;
      }
      this.intervalContentPreviewVideoPlay = setInterval(function () {
        videoEle.pause();
        // console.lo           // Pause video
        videoEle.currentTime = __this.selectedListOfDurationFields[0].duration;
        videoEle.endTime = endTime;
        videoEle.muted = true;
        videoEle.playbackRate = 0.66;
        videoEle.play();
        videoEle.muted = true;
      }, intervalLength);
    }

    if (this.durationInputBoxcontainer.nativeElement) {
      const durationBox =
        this.durationInputBoxcontainer.nativeElement.querySelector(
          "video-timeline-outer"
        );
      if (
        this.durationInputBoxcontainer.nativeElement.querySelector(
          ".video-timeline-outer"
        ) &&
        this.durationInputBoxcontainer.nativeElement
          .querySelector(".video-timeline-outer")
          .querySelectorAll("input").length > 0
      ) {
        const __this = this;
        setTimeout(() => {
          __this.durationInputBoxcontainer.nativeElement
            .querySelector(".video-timeline-outer")
            .querySelectorAll("input")[1]
            .focus();
        }, 300);
      }
    }
  }

  animationDuration(currentTime: number): number {
    let durationLength = 2.5;
    const greaterInd: any[] = this.videoManger.durations.filter(
      (item: any) => item.durationMin > currentTime
    );

    if (greaterInd.length > 0) {
      durationLength = greaterInd[0].durationMin - currentTime;
    } else {
      durationLength = currentTime + 0.9;
      durationLength =
        this.videoManger.selectedVideo.duration - currentTime > 1
          ? durationLength
          : 1;
    }
    durationLength = durationLength > 3 ? 2.9 : durationLength - 0.1;
    currentTime = currentTime + durationLength;
    return currentTime;
  }

  // ContentComponent_HighlightSelectedBlock(sIncommingBlockID: string) {
  //   this.sSelectedBlockID = sIncommingBlockID;
  //   this.selectedDuration = this.videoManger.durations.filter(
  //     (c) => c.id == sIncommingBlockID
  //   )[0];
  //   console.log("SelectedDuration:", this.selectedDuration.id);
  //   for (let i = 0; i < this.canvasArray.length; i++) {
  //     if (this.selectedDuration.id == this.canvasArray[i].id) {
  //       console.log("SelectedDurationIndex:", i);
  //       this.index = i;
  //       this.updateCanvas();
  //     }
  //   }
  // }

  updateVoiceRecords($event) {
    console.log(" voice info ", $event);
  }

  tabSelected = 'video content'
  currentTab() {

    if (this.tabSelected == 'voicetab') {

    } else if (this.tabSelected == 'text overlays') {

    } else if (this.tabSelected == 'video content') {

    }
  }

  submitWizard(tab) {
    // console.log(" tab value /", this.tabSelected);
    if (this.tabSelected == "video content") {
      // console.log("save video contents ..");
      // this.videoManger.wizardCurrentTab = "videocontent";
      // this.videoManger.wizardCurrentTab = ""
      this.savecontent();
    } else if (this.tabSelected == "text overlays") {
      // console.log("save text overlays ..");
      this.saveTextOverylay();
    } else if (this.tabSelected == "voicetab") {
      // console.log("save voice ..");
      this.savevoice(true);
    }
    this.tabSelected = tab;
    // console.log(" tab value end /", this.tabSelected);
  }
}
