<app-edit-video-header></app-edit-video-header>
<section class="accountSec main-body bg-gray-7 h-100 width-72">

    <div class="container">

        <app-edit-video-breadcrumb></app-edit-video-breadcrumb>
        <div class="h-80vh br12  tab-content bg-white page-shadow d-flex flex-column flex-grow-1 position-relative"
            id="pills-tabContent">
            <div class="tab-pane d-flex flex-column flex-grow-1 fade show active">

                <div class="content_area pb-0 pt-3 border-bottom">
                    <ul class="breadcrumb-tabs nav ms-5 ps-2">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="Video-Content-tab" data-bs-toggle="pill"
                                data-bs-target="#Video-Content" type="button" role="tab" aria-controls="Video-Content"
                                aria-selected="false">Seattle, WA</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="Text-Overlays-tab" data-bs-toggle="pill"
                                data-bs-target="#Text-Overlays" type="button" role="tab" aria-controls="Text-Overlays"
                                aria-selected="false">Houston,TX</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="Voiceover-text-tab" data-bs-toggle="pill"
                                data-bs-target="#Voiceover-text" type="button" role="tab" aria-controls="Voiceover-text"
                                aria-selected="true">Denver, CO</button>
                        </li>
                    </ul>
                </div>
                <div class="tab-content d-flex flex-column flex-grow-1">
                    <div class="tab-pane fade active show d-flex flex-column flex-grow-1" id="Video-Content"
                        role="tabpanel" aria-labelledby="Video-Content-tab">
                        <div class="content_area d-flex flex-column flex-grow-1 p-0 ps-5">
                            <div class="row flex-grow-1 m-0">
                                <div class="pt-5 col-sm-12 col-md-12 col-lg-5 ps-5">
                                    <span class="text-lg text-gray-3 d-block mb-4"><svg class="me-1" class="white"
                                            width="24" height="18" viewBox="0 0 24 18" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <rect width="24" height="18" rx="6" fill="#FF8369" />
                                            <path
                                                d="M8.66699 12.0838C8.66699 12.6029 9.04444 12.8154 9.50594 12.5565L14.9875 9.47257C15.449 9.21305 15.449 8.78797 14.9875 8.52845L9.50594 5.44425C9.04441 5.18473 8.66699 5.39699 8.66699 5.91689V12.0838Z"
                                                fill="white" />
                                        </svg>
                                        Video Preview</span>
                                    <div class="video_wrapper video_wrapper_full js-videoWrapper">
                                        <iframe class="videoIframe js-videoIframe" width="620" height="350"
                                            src="https://www.youtube.com/embed/XULUBg_ZcAU" title=""
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen></iframe>
                                        <button class="videoPoster js-videoPoster"></button>
                                    </div>
                                    <h5 class="primaryFont weight-700 text-gray-1 pt-3 pb-1">Family Chiropractic Center
                                        of Richmod</h5>
                                    <p class="text-gray-2 text-sm pt-1 mb-0">4620, Pheasant Ridge Road, Bucks, 324634
                                        <a class="text-gray-2 d-block"
                                            href="https://www.example.com">https://www.example.com</a>
                                    </p>
                                </div>
                                <div class="pt-5 bg-gray-11 col-sm-12 col-md-12 col-lg-7 ps-5 curve-bg">

                                    <span class="text-lg text-gray-3 d-block mb-4"><svg class="me-1" width="21"
                                            height="15" viewBox="0 0 21 15" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M18.868 7.14H15.22L14.548 9H13.396L16.42 0.684H17.68L20.692 9H19.54L18.868 7.14ZM18.556 6.252L17.044 2.028L15.532 6.252H18.556Z"
                                                fill="#483D8F"></path>
                                            <path
                                                d="M5.4 1.28H9.04L14.4 15H11.24L10.26 12.38H4.02L3.04 15H0.04L5.4 1.28ZM9.34 9.96L8.04 6.48C7.88 6.05333 7.59333 5.15333 7.18 3.78H7.08C6.66667 5.15333 6.38 6.05333 6.22 6.48L4.92 9.96H9.34Z"
                                                fill="#FF8369"></path>
                                        </svg>Text Overlays</span>

                                    <div class="video-text">
                                        <div class="d-flex align-items-start check_toggle_content">
                                            <div class="check_toggle video-time">
                                                <span>5s</span>
                                            </div>
                                            <div class="check_content custom-input w-100">
                                                <h6 class="py-1 text-gray-2">Dolor ipsum dolor sit amet</h6>
                                                <p class="text-gray-3 text-xs mb-0 pt-1">Lorem ipsum dolor sit amet,
                                                    consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                                    labore</p>
                                            </div>

                                        </div>
                                        <div class="d-flex align-items-start check_toggle_content">
                                            <div class="check_toggle video-time">
                                                <span>20s</span>
                                            </div>
                                            <div class="check_content custom-input w-100">
                                                <h6 class="py-1 text-gray-2">Dolor ipsum dolor sit amet</h6>
                                                <p class="text-gray-3 text-xs mb-0 pt-1">Lorem ipsum dolor sit amet,
                                                    consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                                    labore</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div class="tab-pane fade" id="Text-Overlays" role="tabpanel" aria-labelledby="Text-Overlays-tab">

                    </div>
                    <div class="tab-pane fade" id="Voiceover-text" role="tabpanel" aria-labelledby="Voiceover-text-tab">
                    </div>
                </div>
            </div>
            <div class="wtermarklogo">
                <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
            </div>
        </div>
    </div>
</section>
<app-edit-video-sidepanels></app-edit-video-sidepanels>
<div class="overlay"></div>