<div *ngIf="!searchAttributes && !isModel" class="row filter-post ms-0">
    <div class="col-md-12 p-0 mid-library">
        <ul class="breadcrumb-tabs nav mx-2">
            <li *ngFor="let option of voiceDataStore.breadcrumbOptions; let i = index" class="nav-item" role="presentation" (click)="updateSelectedTab(option.filter)">
                <a class="nav-link" [class.active]="option.filter == selectedTab">{{ option.name }}</a>
            </li>
        </ul>
    </div>
</div>

<div class="account-heading">
    <div class="row">
        <div class="col-md-7 pw-4">
            <p class="text-lg text-gray-2 ms-2 mt-2">Choose a Voice Avatar</p>
        </div>

        <div class="col-md-5 d-flex justify-content-end">
            <p class="d-flex filter">
                <span class="mt-4"> FILTERS(0)</span>
                <a href="javascript:void(0)" (click)="openFilterSearchModal()" matTooltip="Filter" matTooltipClass="custom-tooltip" matTooltipPosition="above" class="float-end filter-btn d-inline-block mt-3 ms-2 me-4">
                    <img src="../../../../assets/images/svg/filter.svg" />
                </a>
            </p>
            <div class="formItm search-icon searchDropdown pt-2 search-option">
                <form *ngIf="!isModel">
                    <img *ngIf="!searchVoice" src="../../../../assets/images/svg/search.svg" />
                    <div *ngIf="searchVoice" (click)="clearSearch()"><i matTooltip="Delete" matTooltipClass="custom-tooltip"
                            matTooltipPosition="above" class="p-icon sm icon-cross-round link"></i></div>
                    <input id="search-feild"  [(ngModel)]="searchVoice" [ngModelOptions]="{
                        standalone: true
                    }" type="search" value="" placeholder="Search location" required="" />

                    <label for="search-feild" class="text-sm" style="cursor: default">Search Names, Styles, Attributes</label>
                </form>
                <form *ngIf="isModel">
                    <img *ngIf="!searchVoice" src="../../../../assets/images/svg/search.svg" />
                <div *ngIf="searchVoice" (click)="clearSearch()"><i matTooltip="Delete" matTooltipClass="custom-tooltip"
                        matTooltipPosition="above" class="p-icon sm icon-cross-round link"></i></div>
                    <input id="search-feild2" [(ngModel)]="searchVoice" [ngModelOptions]="{
                        standalone: true
                    }" type="search" value="" placeholder="Search location" required="" />

                    <label for="search-feild2" class="text-sm" style="cursor: default">Search Names, Styles, Attributes</label>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="weight-600 spinner text-center" *ngIf="loader == true">
    <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
<nz-collapse [nzGhost]="true" nzAccordion class="pt-0" *ngIf="loader == false">
    <nz-collapse-panel *ngFor="let multvoice of this.voiceOverList" [nzHeader]="header"
     [nzActive]="multvoice?.isActive" [nzDisabled]="multvoice?.disabled">
        <ng-template #header context="multvoice" >
            <p class="voice-header p-0 m-0">
                {{ multvoice.header }}
                <span class="accord-pill">{{ multvoice.headerDetails }}</span>
            </p>
        </ng-template>
        <div  *ngIf="!multvoice.disabled && multvoice?.voices?.length > 0" class="row fancy-scroll" style="max-height: calc(100vh - 578px); overflow: auto">
            <div class="col-md-4" *ngFor="let voice of multvoice?.voices | searchVoiceOver : searchVoice">
                <app-voice-over-single-item [voice]="voice"></app-voice-over-single-item>
            </div>
        </div>
        <div *ngIf="multvoice?.voices?.length == 0" class="row">
            <p>No Voice Available</p>
        </div>
    </nz-collapse-panel>
</nz-collapse>