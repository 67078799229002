import { Component, OnDestroy, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { cIntercomService } from './../../../services/intercom.service';
import { cSessionService } from './../../../services/session.service';
declare var $: any;


@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  constructor(private oCookieService : CookieService,private oSessionService : cSessionService) { }
  sSessionId : any;
  ngOnInit() {  
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    const myInterval = setInterval(function(){
      $('#Redirect_to_Intercom').trigger('click');
    }, 2000);
    setTimeout(() => {
      clearInterval(myInterval);
      if(!this.sSessionId)
      {
        console.log("If Condiotion");
        let intercomScript1 = document.getElementById('intercom1');
        let intercomScript2 = document.getElementById('intercom2');
        if(intercomScript1)intercomScript1.remove();
        if(intercomScript2)intercomScript2.remove();
        console.log("Delete all cookies ...");
        this.oCookieService.deleteAll();
      }
    }, 3000);
    if(!this.sSessionId)
    {
      console.log("Delete all cookies ...");
      this.oCookieService.deleteAll();
    }
  }
}
