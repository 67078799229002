import { Pipe, PipeTransform } from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
 */
@Pipe({ name: 'locationActiveFieldsPipe' })
export class LocationActiveFieldsPipe implements PipeTransform {
    transform(data: any[] , fields : any[]): any[] {
        let returnObbj = [];
        if (data && fields) {
            fields.map((field : any) =>{
                data.map( (item) =>{
                    if(field.name == item.key && field.display != undefined && field.display == 'true'){
                        returnObbj.push(item);
                      }
                })
              });
              return returnObbj;
        } else {
            return [];
        }
    }
}
