<app-header></app-header>
<section class="accountSec main-body bg-gray-7 h-100">
    <form>
        <div class="container">
            <ul class="breadcrumb my-3">
                <li><a href="#">Business Profile</a></li>
                <li>Media Library</li>
            </ul>
            <div class="accountSecInner br12 bg-white page-shadow h-82vh overflow-hidden" id="row-main">
                <app-business-sidebar
                    class="col-md-3 pe-0 pb-0 border-end position-relative sidebar d-flex flex-column h-100"
                    id="sidebar-left"></app-business-sidebar>
                <div class="col-md-9 border-start ps-0" id="content">
                    <div class="py-4 px-4 account-heading border-bottom-0">
                        <div class="row">
                            <div class="col-md-8">
                                <p class="text-lg text-gray-2 pb-1 px-0 col-lg-6 mb-0">
                                    <a routerLink="/business-profile/user-media" class="me-2">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M8.01519 15.1432C8.21658 15.3392 8.21658 15.657 8.01519 15.853C7.81379 16.049 7.48727 16.049 7.28588 15.853L0.55383 9.30125C-0.18461 8.58259 -0.18461 7.41741 0.55383 6.69874L7.28588 0.146999C7.48727 -0.0489997 7.81379 -0.0489997 8.01519 0.146999C8.21658 0.342998 8.21658 0.660776 8.01519 0.856774L1.28314 7.40852C1.2526 7.43824 1.22484 7.46964 1.19986 7.50241L15.4823 7.50241C15.7682 7.50241 16 7.72627 16 8.00242C16 8.27856 15.7682 8.50242 15.4823 8.50242L1.20358 8.50243C1.22759 8.53345 1.25411 8.56323 1.28314 8.59148L8.01519 15.1432Z"
                                                fill="#404243"></path>
                                        </svg>
                                    </a>
                                    {{sSelectedMediaLibrary}}
                                </p>
                            </div>
                            <div class="col-md-4 text-end custom-media-select">
                                <ul class="text-end d-block px-0 mb-0">
                                    <li class="pe-4 text-sm">
                                        <a class="text-gray-3" id="multiselectmediacheck"
                                            data-sidebar="#multiselectmedia"
                                            (click)="MediaLibrarySharedComponent_ToggleMultiSelection()"
                                            data-custom-select>Select Media</a>
                                    </li>
                                    <li>
                                        <button class="btn weight-600 text-xs btn btn-primary btn-round-6 px-3 py-2"
                                            data-bs-toggle="modal" data-bs-target="#uploadModal">
                                            Add Media
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <app-media-library-shared [bSelectMultipleVisuals]="bSelectMultipleVisuals"
                            [sInputVisualPath]="sInputVisualPath" [lInputVisualsToDelete]="lInputVisualsToDelete"
                            (lEmittedFilesToUpload)="MediaLibraryComponent_UploadFiles($event)">
                        </app-media-library-shared>
                    </div>
                </div>
            </div>
        </div>
    </form>
</section>
<div class="modal fade" id="uploadModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg drag-dropfile">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Upload a Video or Audio File</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="form-group files">
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-device" role="tabpanel"
                            aria-labelledby="pills-device-tab">
                            <div class="file-drop-area">
                                <figure>
                                    <svg width="23" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M8.47616 9.28091C8.47609 9.55343 8.25483 9.77429 7.98195 9.77421C7.70907 9.77412 7.48792 9.55313 7.48799 9.2806L7.48988 1.91053L4.38487 5.32164C4.20131 5.52329 3.90371 5.52329 3.72015 5.32164C3.5366 5.11999 3.5366 4.79304 3.72015 4.59139L7.65835 0.264948C7.84191 0.0632953 8.13951 0.0632953 8.32307 0.264948L12.2613 4.59139C12.4448 4.79304 12.4448 5.11999 12.2613 5.32164C12.0777 5.52329 11.7801 5.52329 11.5965 5.32164L8.47805 1.89571L8.47616 9.28091ZM0.59826 6.72675C0.871136 6.72675 1.09235 6.94767 1.09235 7.2202V8.45374C1.09235 11.9966 3.96807 14.8687 7.51545 14.8687H8.50559C12.053 14.8687 14.9287 11.9966 14.9287 8.45374V7.2202C14.9287 6.94767 15.1499 6.72675 15.4228 6.72675C15.6957 6.72675 15.9169 6.94767 15.9169 7.2202V8.45374C15.9169 12.5417 12.5987 15.8556 8.50559 15.8556H7.51545C3.42232 15.8556 0.104176 12.5417 0.104176 8.45374V7.2202C0.104176 6.94767 0.325385 6.72675 0.59826 6.72675Z"
                                                fill="#7A7A7A" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="16.0013" height="15.9809" fill="white"
                                                    transform="translate(0 0.00958252)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </figure>
                                <span class="choose-file-button">Upload a File (Device)</span>
                                <span class="text-line">Click to <font class="text-primary-3">browse</font>, or<br>
                                    drag & drop your file here</span>
                                <span class="file-message">No file selected</span> <input #file class="file-input" type="file"
                                    multiple>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-drive" role="tabpanel" aria-labelledby="pills-drive-tab">
                            <div class="file-drop-area">
                                <figure>
                                    <svg width="23" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M8.47616 9.28091C8.47609 9.55343 8.25483 9.77429 7.98195 9.77421C7.70907 9.77412 7.48792 9.55313 7.48799 9.2806L7.48988 1.91053L4.38487 5.32164C4.20131 5.52329 3.90371 5.52329 3.72015 5.32164C3.5366 5.11999 3.5366 4.79304 3.72015 4.59139L7.65835 0.264948C7.84191 0.0632953 8.13951 0.0632953 8.32307 0.264948L12.2613 4.59139C12.4448 4.79304 12.4448 5.11999 12.2613 5.32164C12.0777 5.52329 11.7801 5.52329 11.5965 5.32164L8.47805 1.89571L8.47616 9.28091ZM0.59826 6.72675C0.871136 6.72675 1.09235 6.94767 1.09235 7.2202V8.45374C1.09235 11.9966 3.96807 14.8687 7.51545 14.8687H8.50559C12.053 14.8687 14.9287 11.9966 14.9287 8.45374V7.2202C14.9287 6.94767 15.1499 6.72675 15.4228 6.72675C15.6957 6.72675 15.9169 6.94767 15.9169 7.2202V8.45374C15.9169 12.5417 12.5987 15.8556 8.50559 15.8556H7.51545C3.42232 15.8556 0.104176 12.5417 0.104176 8.45374V7.2202C0.104176 6.94767 0.325385 6.72675 0.59826 6.72675Z"
                                                fill="#7A7A7A" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="16.0013" height="15.9809" fill="white"
                                                    transform="translate(0 0.00958252)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </figure>
                                <span class="choose-file-button">Upload a File (Drive)</span>
                                <span class="text-line">Click to <font class="text-primary-3">browse</font>, or<br>
                                    drag & drop your file here</span>
                                <span class="file-message">No file selected</span> <input class="file-input" type="file"
                                    multiple>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-facebook" role="tabpanel"
                            aria-labelledby="pills-facebook-tab">
                            <div class="file-drop-area">
                                <figure>
                                    <svg width="23" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M8.47616 9.28091C8.47609 9.55343 8.25483 9.77429 7.98195 9.77421C7.70907 9.77412 7.48792 9.55313 7.48799 9.2806L7.48988 1.91053L4.38487 5.32164C4.20131 5.52329 3.90371 5.52329 3.72015 5.32164C3.5366 5.11999 3.5366 4.79304 3.72015 4.59139L7.65835 0.264948C7.84191 0.0632953 8.13951 0.0632953 8.32307 0.264948L12.2613 4.59139C12.4448 4.79304 12.4448 5.11999 12.2613 5.32164C12.0777 5.52329 11.7801 5.52329 11.5965 5.32164L8.47805 1.89571L8.47616 9.28091ZM0.59826 6.72675C0.871136 6.72675 1.09235 6.94767 1.09235 7.2202V8.45374C1.09235 11.9966 3.96807 14.8687 7.51545 14.8687H8.50559C12.053 14.8687 14.9287 11.9966 14.9287 8.45374V7.2202C14.9287 6.94767 15.1499 6.72675 15.4228 6.72675C15.6957 6.72675 15.9169 6.94767 15.9169 7.2202V8.45374C15.9169 12.5417 12.5987 15.8556 8.50559 15.8556H7.51545C3.42232 15.8556 0.104176 12.5417 0.104176 8.45374V7.2202C0.104176 6.94767 0.325385 6.72675 0.59826 6.72675Z"
                                                fill="#7A7A7A" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="16.0013" height="15.9809" fill="white"
                                                    transform="translate(0 0.00958252)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </figure>
                                <span class="choose-file-button">Upload a File (Facebook)</span>
                                <span class="text-line">Click to <font class="text-primary-3">browse</font>, or<br>
                                    drag & drop your file here</span>
                                <span class="file-message">No file selected</span> <input class="file-input" type="file"
                                    multiple>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-dropbox" role="tabpanel"
                            aria-labelledby="pills-dropbox-tab">
                            <div class="file-drop-area">
                                <figure>
                                    <svg width="23" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M8.47616 9.28091C8.47609 9.55343 8.25483 9.77429 7.98195 9.77421C7.70907 9.77412 7.48792 9.55313 7.48799 9.2806L7.48988 1.91053L4.38487 5.32164C4.20131 5.52329 3.90371 5.52329 3.72015 5.32164C3.5366 5.11999 3.5366 4.79304 3.72015 4.59139L7.65835 0.264948C7.84191 0.0632953 8.13951 0.0632953 8.32307 0.264948L12.2613 4.59139C12.4448 4.79304 12.4448 5.11999 12.2613 5.32164C12.0777 5.52329 11.7801 5.52329 11.5965 5.32164L8.47805 1.89571L8.47616 9.28091ZM0.59826 6.72675C0.871136 6.72675 1.09235 6.94767 1.09235 7.2202V8.45374C1.09235 11.9966 3.96807 14.8687 7.51545 14.8687H8.50559C12.053 14.8687 14.9287 11.9966 14.9287 8.45374V7.2202C14.9287 6.94767 15.1499 6.72675 15.4228 6.72675C15.6957 6.72675 15.9169 6.94767 15.9169 7.2202V8.45374C15.9169 12.5417 12.5987 15.8556 8.50559 15.8556H7.51545C3.42232 15.8556 0.104176 12.5417 0.104176 8.45374V7.2202C0.104176 6.94767 0.325385 6.72675 0.59826 6.72675Z"
                                                fill="#7A7A7A" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="16.0013" height="15.9809" fill="white"
                                                    transform="translate(0 0.00958252)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </figure>
                                <span class="choose-file-button">Upload a File (Dropbox)</span>
                                <span class="text-line">Click to <font class="text-primary-3">browse</font>, or<br>
                                    drag & drop your file here</span>
                                <span class="file-message">No file selected</span> <input class="file-input" type="file"
                                    multiple>
                            </div>
                        </div>
                        <ul class="nav mb-3" id="pills-tab" role="tablist">
                            <li class="col nav-item" role="presentation">
                                <button class="nav-link" (click)="file.click()" id="pills-device-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-device" type="button" role="tab" aria-controls="pills-device"
                                    aria-selected="true">
                                    <img src="../assets/images/business_Profile/device.png" alt="tab-icon">
                                    <span>My Device</span></button>
                            </li>
                            <li class="col nav-item" role="presentation">
                                <button class="nav-link" id="pills-drive-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-drive" type="button" role="tab" aria-controls="pills-drive"
                                    aria-selected="false">
                                    <img src="../assets/images/business_Profile/drive.png" alt="tab-icon">
                                    <span>Google Drive</span></button>
                            </li>
                            <li class="col nav-item" role="presentation">
                                <button class="nav-link" id="pills-facebook-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-facebook" type="button" role="tab"
                                    aria-controls="pills-facebook" aria-selected="false">
                                    <img src="../assets/images/business_Profile/facebook.png" alt="tab-icon">
                                    <span>Facebook</span></button>
                            </li>
                            <li class="col nav-item" role="presentation">
                                <button class="nav-link" id="pills-dropbox-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-dropbox" type="button" role="tab"
                                    aria-controls="pills-dropbox" aria-selected="false">
                                    <img src="../assets/images/business_Profile/dropbox.png" alt="tab-icon">
                                    <span>Dropbox</span></button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Media upload  sidebar Modal Start-->
<nav id="uploadmedia" class="rightSideBar right-0 sidebar-block">
    <div class="sidebar-header">
        <h6 class="text-gray-2 weight-700">Uploading {{nCurrentFileIndex}}/{{lFilesToUploadList.length}} items</h6>
        <a href="javscript:void(0)" class="del-media" data-bs-toggle="modal" data-bs-target="#delbulkMedia">
            <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M14.9809 7.60102C15.214 7.83249 15.592 7.83249 15.8251 7.60102C16.0583 7.36954 16.0583 6.99425 15.8251 6.76277L9.73081 0.711821C8.77491 -0.237272 7.22509 -0.237275 6.26919 0.71182L0.174851 6.76277C-0.0582836 6.99425 -0.0582836 7.36954 0.174851 7.60102C0.407986 7.83249 0.785971 7.83249 1.01911 7.60102L7.11345 1.55006C7.60308 1.06392 8.39692 1.06392 8.88655 1.55007L14.9809 7.60102Z"
                    fill="#7A7A7A" />
            </svg>
        </a>
        <div class="closeRightSideBar">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1_6)">
                    <path
                        d="M14.1971 0H5.8129C2.17108 0 0 2.17 0 5.81V14.18C0 17.83 2.17108 20 5.8129 20H14.1871C17.8289 20 20 17.83 20 14.19V5.81C20.01 2.17 17.8389 0 14.1971 0Z"
                        fill="#F2F2F5" />
                    <path
                        d="M11.1852 9.9986L13.7568 7.42787C14.0811 7.10374 14.0811 6.56724 13.7568 6.2431C13.4326 5.91897 12.8959 5.91897 12.5716 6.2431L10 8.81383L7.42837 6.2431C7.10412 5.91897 6.56744 5.91897 6.24319 6.2431C5.91894 6.56724 5.91894 7.10374 6.24319 7.42787L8.81481 9.9986L6.24319 12.5693C5.91894 12.8935 5.91894 13.43 6.24319 13.7541C6.4109 13.9218 6.62334 14 6.83578 14C7.04822 14 7.26066 13.9218 7.42837 13.7541L10 11.1834L12.5716 13.7541C12.7393 13.9218 12.9518 14 13.1642 14C13.3767 14 13.5891 13.9218 13.7568 13.7541C14.0811 13.43 14.0811 12.8935 13.7568 12.5693L11.1852 9.9986Z"
                        fill="#A7ABB6" />
                </g>
                <defs>
                    <clipPath id="clip0_1_6">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    </div>
    <div class="sidebar-content">
        <div class="up-media" *ngFor="let file of lFilesToUploadList;">
            <div class="media-type" *ngIf="file.type == 'video/mp4'">
                <video controlsList="nodownload noplaybackrate" class="Videos_uploading" [muted]="true" src="{{file.url}}"></video>
            </div>
            <div class="media-type" *ngIf="file.type != 'video/mp4'">
                <img class="uploading_files" src="{{file.url}}" alt="1">
            </div>
            <p class="media-title">{{file.name | slice:0:22}}</p>
            <span class="file-size">{{MediaLibraryComponent_ReturnFileSize(file.size)}}</span>
            <button class="btn-success btn" *ngIf="file.status==='complete'">
                <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M8.80667 0.854745C9.08816 0.595654 9.52906 0.605007 9.79875 0.877757C10.0767 1.15884 10.0649 1.60943 9.7749 1.87635L4.05049 7.14517C3.77781 7.39615 3.35428 7.39598 3.08181 7.14475L0.224653 4.51034C-0.0651365 4.24314 -0.0764627 3.79254 0.201696 3.51172C0.471612 3.23923 0.912515 3.23022 1.19379 3.48957L3.56681 5.67758L8.80667 0.854745ZM0.789687 3.89782L0.789544 3.89769L0.789687 3.89782Z"
                        fill="white" />
                </svg>
            </button>
            <button class="btn-danger btn" *ngIf="file.status==='failed'">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.34163 9.83333C0.25414 9.83333 0.170815 9.8 0.104156 9.7375C-0.0249974 9.60833 -0.0249974 9.39583 0.104156 9.26667L4.44953 4.91667L0.0958232 0.566667C0.0333298 0.504167 0 0.420833 0 0.333333C0 0.245833 0.0333298 0.1625 0.0958232 0.1C0.162483 0.0333333 0.245807 0 0.333298 0C0.420789 0 0.504113 0.0333333 0.570773 0.0958333L4.92031 4.44583L9.26569 0.0958333C9.32818 0.0333333 9.4115 0 9.49899 0C9.58648 0 9.66981 0.0333333 9.73647 0.0958333C9.86562 0.225 9.86562 0.4375 9.73647 0.566667L5.3911 4.91667L9.7323 9.25833C9.7948 9.32083 9.82813 9.40417 9.82813 9.49167C9.82813 9.57917 9.7948 9.6625 9.7323 9.72917C9.66981 9.79583 9.58648 9.825 9.49483 9.825C9.40317 9.825 9.32401 9.79167 9.25735 9.72917L4.92031 5.3875L0.574939 9.7375C0.512446 9.8 0.429121 9.83333 0.34163 9.83333Z"
                        fill="white" />
                </svg>
            </button>
            <button class="btn-processing btn" *ngIf="file.status==='progress'"></button>
        </div>
    </div>
    <ul class="sideNav-btn px-3">
        <a href="javascript:void(0)" class="weight-normal text-primary-4 text-sm py-3"
            (click)="MediaLibraryComponent_CancelUpload()">Cancel Upload</a>
    </ul>
</nav>
<!-- media upload sidebar Modal end-->
<!-- Delete pop-up Start-->
<div class="modal fade" id="delbulkMedia" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="btn-close cust-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <h5>Are you sure you want to delete the selected media files?</h5>
                <p class="text-sm text-gray-3 pt-1 pb-4">The files will be permanently deleted. You can't undo this
                    action.</p>
                <a href="javascript:void(0)" id="sidebarCollapse"
                    class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" data-bs-dismiss="modal">Cancel</a>
                <a class="mt-3 text-gray-3 text-sm" href="javascript:void(0)" data-bs-dismiss="modal"
                    (click)="MediaLibraryComponent_DeleteVisual()">Delete</a>
            </div>
        </div>
    </div>
</div>
<!-- Delete pop-up End-->
<div class="overlay sidebarOverlay"></div>
<!-- <app-business-profile-sidepanels></app-business-profile-sidepanels> -->