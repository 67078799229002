import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { brand } from 'src/app/models/admin';
import { mediaLib } from 'src/app/models/media';
import { Userservice } from '../../users/service/user-service';
import { BrandService } from '../service/BrandService';

@Component({
  selector: 'app-manage-brand-media-libraries',
  templateUrl: './manage-brand-media-libraries.component.html',
  styleUrls: ['./manage-brand-media-libraries.component.scss']
})
export class ManageBrandMediaLibrariesComponent implements OnInit {

  showMediaLibraryAccessDrawer = true;
  // placementUser: NzDrawerPlacement = "right";
  brandId: string;
  mediaLibId: string;
  brandDetail: any = [];
  brandMediaId = "";
  mediaLibraryDetail: mediaLib = {
    name: '',
    lib_id: '',
    activation_status: '',
    total_items: 0,
    library_access: ''
  };
  constructor(
    private brandService: BrandService,
    public router: Router,
    private route: ActivatedRoute,
    public userService: Userservice
  ) {}

  ngOnInit(): void {
    this.brandId = this.route.snapshot.params["id"];
    this.mediaLibId = this.route.snapshot.params["mediaLibId"];
    this.brandDetail = this.brandService.brandDetail;
    this.getBrandProfile();
    this.getMediaLibDetail();
  }

  getBrandProfile() {
    let __this = this;
    this.brandService
      .getBrandProfile(this.brandId)
      .then((brand: brand) => {
        __this.brandDetail = brand;
        __this.brandService.selectedBrandProfile = brand;
        const mediaList = brand.media_libs.filter((item: any) => {
          return (item._id.$oid = this.mediaLibId);
        });
        if(mediaList && mediaList.length > 0) {
          __this.userService.selectedMediaId = [mediaList[0]];
        }
        console.log(__this.userService.selectedMediaId);
        __this.brandMediaId = this.mediaLibId;
      });
  }

  loadBrandAccount() {
    this.router.navigate([
      `/account-setting/manage-brand/account/${this.brandId}`,
    ]);
  }
  loadBrandBusiness() {
    this.router.navigate([
      `/account-setting/manage-brand/${this.brandId}/business-details`,
    ]);
  }
  loadBrandMediaLibrary() {
    this.router.navigate([
      `/account-setting/manage-brand/${this.brandId}/list-media-libraries`,
    ]);
  }
  getMediaLibDetail() {
    let __this = this;
    this.brandService
      .getMediaLibraryProfile(this.mediaLibId)
      .then((mediaLibData: any) => {
        __this.mediaLibraryDetail = mediaLibData;
        console.log(" __this.mediaLibraryDetail ", __this.mediaLibraryDetail);
      });
  }

}
