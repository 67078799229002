import {
  brandList,
  MediaLibraryResponse,
  SearchData,
  UserDataInfo,
  UserList,
} from "./../../../../models/admin";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { brand, user } from "src/app/models/admin";
// import { user, UserList, UserProfile } from "src/app/models/admin";
import { mediaLib } from "src/app/models/media";
import { ErrorService } from "src/app/services/error.service";
import { LoaderService } from "src/app/services/loader.service";
import { cSessionService } from "src/app/services/session.service";
import { environment } from "src/environments/environment";
import { ApiURL } from "src/app/conf/api-path";
import { MyAccountService } from "src/app/services/dataServices/my-account.service";
import { MediaService } from "src/app/services/dataServices/media.service";

@Injectable({
  providedIn: "root",
})
export class BrandService {
  updateBrandDetails = (brand: any): Promise<any> => {
    return this.http
      .post(environment.API_HOST_ADMIN + "/add_brand_media_lib", brand)
      .toPromise();
  };
  listUser: UserList;

  addBrandMediaLibrary(mediaLibraryId: any, brandId: any) {
    return this.http
      .post(environment.API_HOST_ADMIN + "/add_brand_media_lib", {
        media_lib_ids: mediaLibraryId,
        brand_id: brandId,
      })
      .toPromise();
  }

  sessionId: any;
  email: any;
  selectedUser: user;
  public brandDetail: brand = {};
  public brandList: brandList[] = [];
  selectedBrandProfile: brand;
  public topFiveInustries = [
    "Healthcare",
    "Automotive",
    "Retail",
    "Real Estate",
    "Advertising",
  ];
  public topEightInustries = [
    "Healthcare",
    "Automotive",
    "Technology",
    "Professional Services",
    "Food and Beverages",
    "Real Estate",
    "Hair and Beauty",
    "Retail",
  ];

  constructor(
    private errorService: ErrorService,
    private loader: LoaderService,
    private http: HttpClient,
    private myAccountSettingService: MyAccountService,
    public oSessionService: cSessionService,
    private mediaService : MediaService
  ) {
    this.sessionId = this.oSessionService.SessionService_GetSessionId();
    this.email = this.oSessionService.cSessionService_GetUserEmail();
  }

  public getUsers(
    offset: number,
    limit: number,
    sort: string,
    asc: number,
    search_term = "",
    search_by = "_id",
    showLoading: boolean = true
  ) {
    if (showLoading) this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {
      this.myAccountSettingService
        .getUsers(
          this.email,
          this.sessionId,
          offset,
          limit,
          sort,
          asc,
          search_by,
          search_term
        )
        .then((res) => {
          if (showLoading) this.loader.hideLoader();

          this.listUser = res as UserList;
          resolve(this.listUser);
        })
        .catch((err) => {
          console.log("into catch...");
          this.loader.hideLoader();
          this.errorService.handleError(err.errorMessage, err.errorType, true);
          if (showLoading) this.loader.hideLoader();

          reject(err);
        });
    });

    return promise;
  }
  public addBrand(brand: brand) {
    this.loader.showLoader();
    let promise = new Promise((resolve, reject) => {
      this.myAccountSettingService
        .addBrands(this.email, this.sessionId, brand)
        .then((res) => {
          this.loader.hideLoader();

          // let brandLst = new brandList();
          // brandLst._id = brand._id;
          // brandLst.name = brand.name
          // brandLst.website = brand.website;
          // brandLst.industry = brand.industry;
          // brandLst.users = brand.users;
          // brandLst.public_videos = brand.public_videos;
          // brandLst.media_uploads = brand.media_uploads;
          // this.brandList.push(brandLst);

          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError("Here is ErrorMessage", "Error", true);
          this.loader.hideLoader();
          reject(err);
        });
    });

    return promise;
  }

  public getListBrand(
    email: string,
    sessionId: string,
    offset: number,
    limit: number,
    sort: string,
    asc: number,
    search_term = "",
    search_by = "_id",
    showLoader = true
  ) {
    if (showLoader) this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {
      this.myAccountSettingService
        .getListBrands(
          email,
          sessionId,
          offset,
          limit,
          sort,
          asc,
          search_by,
          search_term
        )
        .then((res) => {
          if (showLoader) this.loader.hideLoader();
          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);

          if (showLoader) this.loader.hideLoader();
          reject(err);
        });
    });

    return promise;
  }

  public searchUser(name: string): Promise<UserDataInfo[]> {
    return new Promise((resolve, reject) => {
      console.log("userList3", name);
      return resolve([
        { id: "1", name: "Sharjeel bhai " },
        { id: "2", name: "Wasim bhai " },
        { id: "3", name: "muneeb " },
      ]);
    });
  }

  public getBrandProfile(id: string, showLoader = true) {
    if (showLoader) this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {
      console.log(id);
      this.myAccountSettingService
        .getBrandProfile(id)
        .then((res: brand) => {
          this.brandDetail = res;
          if (showLoader) this.loader.hideLoader();

          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);

          if (showLoader) this.loader.hideLoader();

          reject(err);
        });
    });

    return promise;
  }

  addUserBrand(brandId: any, userIds: any, showLoader = true) {
    if (showLoader) this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {
      this.myAccountSettingService
        .addUserBrand(brandId, userIds)
        .then((res) => {
          if (showLoader) this.loader.hideLoader();

          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);

          console.log("addUserBrandErr", err);
          if (showLoader) this.loader.hideLoader();

          reject(err);
        });
    });

    return promise;
  }

  getBrandUsers(brandId: any, showLoader = true) {
    if (showLoader) this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {
      this.myAccountSettingService
        .listBrandsUsers(brandId)
        .then((res) => {
          if (showLoader) this.loader.hideLoader();
          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);
          if (showLoader) this.loader.hideLoader();
          reject(err);
        });
    });

    return promise;
  }

  public updateBrand(brand: brand) {
    this.loader.showLoader();
    let promise = new Promise((resolve, reject) => {
      this.myAccountSettingService
        .updateBrand(brand)
        .then((res) => {
          this.loader.hideLoader();
          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError("Here is ErrorMessage", "Error", true);
          this.loader.hideLoader();
          reject(err);
        });
    });
    return promise;
  }

  deleteBrand(_id: string) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.delete, { _id })
      .toPromise();
  }

  deleteAssignedUser(_id: string) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.user.delete_user_brand, {
        _id: _id,
      })
      .toPromise();
  }
  getBrandMediaLibList(brand_id: string) {
    return this.http
      .post(
        environment.API_HOST_ADMIN + ApiURL.admin.media.list_brand_media_libs,
        { brand_id }
      )
      .toPromise();
  }
  getUserBrandMediaLibList(data) {
    return this.http
      .post(
        environment.API_HOST_ADMIN + ApiURL.admin.media.list_brand_media_libs,
        data
      )
      .toPromise();
  }
  getBrandMediaLibListSearch(search: SearchData) {
    return this.http
      .post(
        environment.API_HOST_ADMIN + ApiURL.admin.media.list_brand_media_libs,
        search
      )
      .toPromise();
  }
  getBrandVisuals(email, visualType, medilibId, offset = 0, limit = 18, sortBy = 'fpath'): Promise<unknown> {
    let data = {
      email: email,
      visual_type: visualType,
      limit: limit,
      offset: offset,
      asc: -1,
      sort_by: sortBy,
      visual_id: "",
      search_by:"name",
      media_lib_ids: medilibId && medilibId != "" ? medilibId : [],
    }

    let promise = new Promise((resolve, reject) => {
        this.mediaService
            .getBrandVisuals(data)
            .toPromise()
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });

    return promise;
}
  public getListMedia(
    email: string,
    sessionId: string,
    offset: number,
    limit: number,
    sort: string,
    asc: number,
    search_term = "",
    search_by = "_id",
    showLoader = true
  ) {
    if (showLoader) this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {
      this.myAccountSettingService
        .getMediaLibraries(
          email,
          sessionId,
          offset,
          limit,
          sort,
          asc,
          search_by,
          search_term
        )
        .then((res) => {
          if (showLoader) this.loader.hideLoader();
          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);

          if (showLoader) this.loader.hideLoader();
          reject(err);
        });
    });

    return promise;
  }

  public getListUserMediaLibrary(
    email: string,
    search = "",
    showLoader = true
  ) {
    if (showLoader) this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {
      this.myAccountSettingService
        .getUserMediaLibraries(email, search)
        .then((res) => {
          if (showLoader) {
            this.loader.hideLoader();
          }
          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);

          if (showLoader) {
            this.loader.hideLoader();
          }

          reject(err);
        });
    });

    return promise;
  }

  //   public searchUser( name : string) : Promise<UserDataInfo[]> {
  //     return  new Promise((resolve, reject) => {

  //       console.log("userList3", name);
  //       return resolve([
  //         { id: "1", name : "Sharjeel bhai "},
  //         { id: "2", name : "Wasim bhai "},
  //         { id: "3", name : "muneeb "},
  //       ])
  //     });
  //   }

  //   public getBrandProfile(id : string, showLoader= true)
  //   {
  //   if(showLoader)
  //   this.loader.showLoader();

  //     let promise = new Promise((resolve, reject) =>{

  //       this.dataService.getBrandProfile(id)
  //         .then((res: brand) => {
  //           this.brandDetail = res;
  //           if(showLoader)
  //             this.loader.hideLoader();

  //             console.log("getbrand",JSON.stringify(res));

  //           resolve(res);
  //         })
  //         .catch((err) => {
  //           this.errorService.handleError(err.errorMessage, err.errorType, true);

  //           if(showLoader)
  //             this.loader.hideLoader();
  //           reject(err);
  //         });
  //     });

  //     return promise;
  //   }

  addMediaLibray(mediaLibObj: MediaLibraryResponse, showLoader = true) {
    if (showLoader) this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {
      this.myAccountSettingService
        .addMediaLibray(mediaLibObj)
        .then((res) => {
          if (showLoader) {
            this.loader.hideLoader();
          }

          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError(err, "Add Media Library", true);

          if (showLoader) {
            this.loader.hideLoader();
          }

          reject(err);
        });
    });

    return promise;
  }

  addUserMediaLibrary(mediaLibraryId: any, userIds: any, showLoader = true) {
    if (showLoader) this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {
      this.myAccountSettingService
        .addUserMediaLibrary(mediaLibraryId, userIds)
        .then((res) => {
          if (showLoader) this.loader.hideLoader();
          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);

          if (showLoader) this.loader.hideLoader();

          reject(err);
        });
    });

    return promise;
  }

  getMediaLibraryUsers(media_lib_id: string) {
    var data = { media_lib_id };
    return this.http
      .post(
        environment.API_HOST_ADMIN + ApiURL.admin.media.get_media_lib_users,
        data
      )
      .toPromise();
  }

  deleteMediaLibraryUsers(_id: string) {
    return this.http
      .post(
        environment.API_HOST_ADMIN + ApiURL.admin.media.del_media_lib_users,
        { _id }
      )
      .toPromise();
  }

  async getMediaLibraryProfile(_id: string) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.media.get, { _id })
      .toPromise();
  }
  updateMediaLibDetails = (brand: any): Promise<any> => {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.media.update, brand)
      .toPromise();
  };
  updateMediaLibAccess = (_id: any, library_access: any): Promise<any> => {
    return this.http
      .post(
        environment.API_HOST_ADMIN + ApiURL.admin.media.update,
        _id,
        library_access
      )
      .toPromise();
  };

  public getListPublishedVideos(search: SearchData) {
    console.log("Search data ", search);
    return this.http
      .post(
        environment.API_HOST_ADMIN + ApiURL.admin.brand.list_published_videos,
        search
      )
      .toPromise();
  }
  public getDefaultBrand() {
    return this.http
      .get(environment.API_HOST_ADMIN + ApiURL.admin.brand.default_brand)
      .toPromise();
  }
  public getIndustryList() {
    this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {
      this.myAccountSettingService
        .getIndustryList()
        .then((res) => {
          this.loader.hideLoader();
          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);
          this.loader.hideLoader();
          reject(err);
        });
    });

    return promise;
  }

  updateFBAccessToken(access_token: string, brand_id: any) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.fbAccessToken, {
        access_token,
        brand_id,
      })
      .toPromise();
  }
  getFBAccessToken(brand_id: any) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.getfbAccessToken, {
        brand_id,
      })
      .toPromise();
  }
  getLinkInAccessToken(brand_id: any) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.getLinkedInAccessToken, {
        brand_id,
      })
      .toPromise();
  }
  getInstagramAccessToken(brand_id: any) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.getInstagramAccessToken, {
        brand_id,
      })
      .toPromise();
  }
  deleteFBAccessToken(brand_id: any) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.deletefbAccessToken, {
        brand_id,
      })
      .toPromise();
  }
  getYouTubeAccessToken(brand_id: any) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.getyouTubeAccessToken, {
        brand_id,
      })
      .toPromise();
  }
  shareVideoOnFacebook(data : any) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.sharefbAccessToken, {
        brand_id : data.brand_id, message : data.message, video_url : data.video_url
      })
      .toPromise();
  }a
  shareVideoOnInstagram(data : any) {
    return this.http
      .post(environment.API_HOST + ApiURL.admin.brand.shareVideoInstagramUpload, data)
      .toPromise();
  }
  shareVideoOnYoutube(data : any) {
    console.log(' api dcada', data);
    return this.http
      .get(data)
      .toPromise();
    // return this.http
    //   .post(environment.API_HOST + ApiURL.admin.brand.shareYouTubeAccessToken, {
    //     brand_id : data.brand_id, title : data.message, video_url : data.video_url
    //   })
    //   .toPromise();
  }

  getDefaultFontLists() {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.getAllFont,{})
      .toPromise();
  }
}
