import { brand, brandList } from './../../../../models/admin';
import { Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { cSessionService } from 'src/app/services/session.service';
import { SuccessMessageModalService } from 'src/app/services/success-message-modal.service';
import { BrandService } from '../service/BrandService';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Userservice } from '../../users/service/user-service';


@Component({
  selector: 'app-manage-brand',
  templateUrl: './manage-brand.component.html',
  styleUrls: ['./manage-brand.component.scss']
})
export class ManageBrandComponent implements OnInit {

  showAddNewBrand = false;
  placementUser: NzDrawerPlacement = "right";
  selectedAddUsers: string[] = [];
  showFilterUserDrawer = false;
  userList: any = [];
  modelUserSearchChanged = new Subject<string>();
  isUserSearchLoading: boolean = false;
  searchUserText: string = "";
  addBrandFormSubmitted = false;
  public addBrandForm: FormGroup;
  listPages: number[] = [];
  showSearchBox = false;
  searchTerm = "";
  searchTermText = "";
  showSortByDD = false;
  searchBy = "created";

  limit = 20;
  offset = 0;
  currentPage = 1;
  totalPages = 1;

  brandList: brandList = {
    offset: 0,
    limit: this.limit,
    count: 0,
    result: [],
  };
  sessionId: string;
  email: string;
  userListLoopLimit = 3;
  isDeleteModalVisible = false;
  selectedBrand : brand;
  sortType = -1;

  constructor(
    private brandService : BrandService,
    public oSessionService: cSessionService,
    private addBrandFormBuilder: FormBuilder,
    private userService: Userservice,
    // private brandService: brandServiceService,
    public router: Router, 
    private successMessageService: SuccessMessageModalService
  ) {
    this.sessionId = this.oSessionService.SessionService_GetSessionId();
    this.email = this.oSessionService.cSessionService_GetUserEmail();
    this.oSessionService.setAccountSettingHeaderName("Assigned Brands");
    
    
  }


  populatePagination() {
    if (this.brandList && this.brandList.total_count > 0) {
      const maxPage = Math.ceil(this.brandList?.total_count / this.limit) || 1;      
      if (maxPage > 1) {
        this.listPages = Array.from({ length: maxPage }, (_, i) => i + 1);        
      } else {
        this.listPages = [1];
      }
    }
  }

  reloadBrandList() {
    let __this = this;
    this.brandService
      .getListBrand(
        this.email,
        this.sessionId,
        this.offset,
        this.limit,
        this.searchBy,
        this.sortType,
        this.searchTerm,
        'name',
        true
      )
      .then(function (brandList: brandList) {
        __this.brandList = brandList;

        // __this.populatePagination();
      });
  }

  loadPage(page: number)  {
    if( page < 1 ) {
      page = 1;
    } else {
      if( page > this.listPages.length ) {
        page = 1;
      }
      this.brandList.result = [];
      this.currentPage = page;
      this.offset = page == 1 ? 0 : (page - 1) * this.limit - 1;
      this.reloadBrandList();      
    }    
  }
  ngAfterViewInit(): void {
    this.reloadBrandList();

    // TODO autocomplete Brands change trigger
    this.modelUserSearchChanged
      .pipe(debounceTime(100), distinctUntilChanged())
      .subscribe(async (value) => {
        if (value && value != "" && value.length > 1) {
          this.userList = await this.userService
            .getUsers(0, 30, "created", -1, value, "_id", false)
            .then((resList) => {
              return (this.userList = resList);
            });
          this.isUserSearchLoading = false;
          return this.userList;
        } else {
          return [];
        }
      });
  }

  ngOnInit(): void {}

  openAddNewBrandDrawer(): void {
    this.addBrandFormSubmitted = false;
    this.addBrandForm = this.addBrandFormBuilder.group({
      name: "",
      website: "",
      industry: "",
      users: "",
      searchUserText: "",
    });
    this.selectedAddUsers = [];
    this.showAddNewBrand = true;
  }
  closeAddNewBrandDrawer(): void {
    this.showAddNewBrand = false;
  }

  setAddUsers(user: string): void {
    if (this.selectedAddUsers.indexOf(user) === -1) {
      this.selectedAddUsers.push(user);
    }

    this.addBrandForm.controls["searchUserText"].setValue("");
  }

  removeAddUsers(user: string): void {
    var indexUsers = this.selectedAddUsers.indexOf(user);
    if (indexUsers > -1) {
      this.selectedAddUsers.splice(indexUsers, 1);
    }
  }

  async addNewBrand(): Promise<any> {
    console.log("add user called");
    this.addBrandFormSubmitted = true;
    if (this.addBrandForm.invalid) {
      // TODO show Error notification
      console.log("form values r not valid ..");
    } else {
      // TODO add brand
      const brandInfo: brand = {
        // _id: this.addBrandForm.controls["email"].value,
        name: this.addBrandForm.controls["name"].value,
        website: this.addBrandForm.controls["website"].value,
        industry: this.addBrandForm.controls["industry"].value,
        users: this.selectedAddUsers.length > 0 ? this.selectedAddUsers : [],
      };
      await this.brandService.addBrand(brandInfo).then(async (res: any) => {
        await this.addUserBrand(res.id.$oid);
        this.successMessageService.display("Brand has been created.", "Add Brand", true, true);
        this.loadNewlyAddedBrand(res.id.$oid);
      });
      this.showAddNewBrand = false;
    }
  }

  async addUserBrand(brandId: string) {
    let userIds = this.selectedAddUsers;
    return await this.brandService.addUserBrand([brandId], userIds).then((res) => {});
  }

  loadNewlyAddedBrand(brandId) {
    this.brandService.getBrandProfile(brandId).then((brand: brand) => {
      this.brandList.result.unshift(brand);
      ++this.brandList.count;
      ++this.brandList.total_count;
    });
  }

  loadBrandProfile(id: any) {
    console.log("blist-id", id);
    this.router.navigate(["/account-setting/manage-brand/", id.$oid, "business-details"]);
  }

  //Filter User drawer
  openFilterUserDrawer(): void {
    this.showFilterUserDrawer = true;
  }
  closeFilterUserDrawer(): void {
    this.showFilterUserDrawer = false;
  }

  searchBrand() {
    this.offset = 0;
    this.searchTerm = this.searchTermText;
    this.reloadBrandList();
  }

  clearSearchTerm() {
    this.offset = 0;
    this.searchTerm = "";
    this.searchTermText = "";
    this.reloadBrandList();
  }

  sortBySearchResult(value) {
    this.offset = 0;
    this.searchBy = value;
    this.showSortByDD = false;
    this.hideSearchSortTypeDD(undefined);
    this.sortType = -1;
    this.reloadBrandList();
  }

  openSearchSortTypeDD() {
    this.showSortByDD = this.showSortByDD ? false : true;
  }

  hideSearchSortTypeDD($event) {
    if (this.showSortByDD) {
      const __this = this;
      setTimeout(() => {
        __this.showSortByDD = false;
      }, 200);
    }
  }
  
  viewAllSelectedUsers(val){
    this.userListLoopLimit = val;
  }

  handleCancel(){
    this.isDeleteModalVisible = false;
  }


  async deleteBrand(){
      const response = await this.brandService.deleteBrand(this.selectedBrand?._id?.$oid);
      if(response){
        const indexRecrod = this.brandList?.result.findIndex(
          (item) => item?._id?.$oid == this.selectedBrand?._id?.$oid
        );
        this.successMessageService.display("Brand has been deleted.", "Delete Brand", true, true);
        if (indexRecrod > -1) {
          this.isDeleteModalVisible = false;
          this.brandList.result.splice(indexRecrod, 1);
          --this.brandList.total_count;
          --this.brandList.count;
        }
      }    
  }

  sortTypeSearch(val : number){    
    this.sortType = val;
    this.reloadBrandList();
  }

  
  loadPageEvent($event){
    if($event.pageSize != this.limit){
      this.limit = $event.pageSize;
      this.loadPage(1);
    } else {
      this.loadPage($event.pageIndex + 1); 
    }    
  }
}
