<div class="px-5 pt-1 brandLogoList">
  <h5 class="text-lg text-gray-2 mt-4">Select from brand logos</h5>

  <section class="mt-2 d-inline-block" [ngClass]="brandLogoPath.length != 0?'d-grid':''">
    <div *ngIf="dataLoaded && brandLogoPath.length == 0">No logo available for this brand.</div>
    <figure class="my-3 me-2 brandFigure px-2" *ngFor="let brand of brandLogoPath"
      [ngClass]="{ active: brand.isSelected == true }" (click)="selectLogo(brand)">
      <img [src]="brand.url" class="brand_logo" />
      <p class="text-xm ps-1 clear font-brand-logo" *ngIf="brand.isSelected == true">
        Selected Logo
      </p>
    </figure>
  </section>
  <div class="border-top mt-5 me-5">
    <button type="submit" class="weight-normal btn btn-secondary btn-round-6 px-5 py-3 my-5"
      (click)="selectBrandLogoEmitterTrigger()">
      Select
    </button>
  </div>
</div>