import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'removeAudioData'
})
export class RemoveAudioFromVisualData implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  transform(visuals : any[] = [], flag : boolean = true) {

    if(visuals.length > 0 ) {
      return visuals.filter((item: any) => {
        if(flag) {
          return item.visual_type !== 'audio';
        } else {
          return item;
        }
      });
    } else {
      return visuals
    }
  }
}