import { brand } from 'src/app/models/admin';
import { BrandService } from 'src/app/components/myAccount/manage-brand/service/BrandService';
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { VideoManagerService } from "../../../Managers/video-manager.service";
import { cSessionService } from "../../../services/session.service";
import { Location } from "@angular/common";
import { ErrorService } from "./../../../services/error.service";
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from "src/app/services/loader.service";
import { ConfigManagerService } from "src/app/Managers/config-manager.service";

@Component({
  selector: "app-timeline-view",
  templateUrl: "./timeline-view.component.html",
  styleUrls: ["./timeline-view.component.scss"],
})
export class TimelineViewComponent implements OnInit {
  @ViewChild("videoPreview") videoPreview: ElementRef;
  bDisplayProntoShare: boolean = false;
  bDisplayProntoDownload: boolean = true;
  sVideoTitle: string = "";
  sVideoDuration: string = "";
  sUserEmail: string;
  sSessionId: string;
  sVideoId: string;
  oGeneratedVideo: any;
  oPublicUrl: string;
  oVideoDetails: any;
  sPublicVideoUrl: string;
  oClosePopup: any = 0;
  bExpandReach: boolean = false;
  copiedText = "";
  paramsObject: any;
  statusResult: string;
  Sap_duration: any;
  videoBrand: any;
  constructor(
    public oSessionService: cSessionService,
    private _location: Location,
    private oRouter: Router,
    public videoManager: VideoManagerService,
    public ConfigManager: ConfigManagerService,
    public errorService: ErrorService, private route: ActivatedRoute, public loader: LoaderService,
    private brandService: BrandService
  ) { }

  async ngOnInit() {
    this.oGeneratedVideo = this.videoManager.selectedVideo;
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();

    if (!this.videoManager.selectedVideo || this.videoManager.selectedVideo == undefined || this.videoManager.selectedVideo.video_id == '') {


      this.route.queryParamMap
        .subscribe((params) => {
          this.paramsObject = { ...params.keys, ...params };
          console.log(this.paramsObject);
          this.sVideoId = this.paramsObject.params.video_id;
          if (this.statusResult != '') {
            this.TimelineViewComponent_DisplayProntoShare();
          }
        });
      if (this.sVideoId && this.sVideoId != '') {
        this.loader.showLoader();
        await this.videoManager
          .getVideoById(this.sUserEmail, this.sSessionId, this.sVideoId)
          .then((res) => {
            this.oVideoDetails = res;
            this.videoManager.selectedVideo = res;
            this.loader.hideLoader();
            this.statusResult = this.paramsObject.params.result;
          });
        setTimeout(() => { this.statusResult = ''; }, 5000);
      }
      else {
        this.sVideoId = this.oSessionService.SessionService_GetVideoId();
        this.loader.showLoader();
        await this.videoManager
          .getVideoById(this.sUserEmail, this.sSessionId, this.sVideoId)
          .then((res) => {
            this.loader.hideLoader();
            this.oVideoDetails = res;
            this.videoManager.selectedVideo = res;
            setTimeout(() => { this.statusResult = ''; }, 5000);
          });
      }
    }

    if (this.videoManager?.selectedVideo?.brand_id) {
      console.log("Find video Brand .......");
      this.brandService
        .getBrandProfile(this.videoManager?.selectedVideo?.brand_id)
        .then((brand: brand) => {
          console.log(" VIDEO BRAND HERE ", brand);
          this.videoManager.selectedVideo.brand = brand;
        });

    }

    this.sVideoId = this.videoManager.selectedVideo?.fpath;
    // console.log("Published Video full info ", this.videoManager.selectedVideo);
    // console.log("public url", this.videoManager.selectedVideo);
    this.oPublicUrl = this.videoManager.selectedVideo.public_url;
    this.sVideoTitle = this.videoManager.selectedVideo.title;
    if (this.videoManager.selectedVideo.sap_video_duration != null) {
      this.Sap_duration = this.videoManager.selectedVideo.sap_video_duration + this.ConfigManager.sapVideoDurationDiff;
    }

    this.sVideoDuration = this.Sap_duration != null ? this.Sap_duration.toString() : this.videoManager.selectedVideo.duration.toString();
    this.oVideoDetails = this.videoManager.selectedVideo;
    this.sPublicVideoUrl = this.videoManager.selectedVideo.public_url;
  }
  ngOnDestroy() {
    this.oSessionService.bDisplaySharingErrorMessage = false;
    this.oSessionService.bDisplaySharingSuccessMessage = false;
  }


  TimelineViewComponent_CopyToClipBoard() {
    this.copiedText = this.oPublicUrl;
    navigator.clipboard.writeText(encodeURI(this.oPublicUrl));
    console.log((encodeURI(this.oPublicUrl)));
  }
  TimelineViewComponent_DisplayProntoShare() {
    this.bDisplayProntoShare = true;
    this.bDisplayProntoDownload = false;
  }
  TimelineViewComponent_DisplayProntoDownload() {
    this.bDisplayProntoDownload = true;
    this.bDisplayProntoShare = false;
  }
  TimelineViewComponent_PlayVideo() {
    this.videoPreview.nativeElement.play();
  }
  TimelineViewComponent_ResetVideo() {
    this.videoPreview.nativeElement.load();
    var element = document.getElementById("TemplateThumbnail");
    element.classList.remove("videoWrapperActive");
  }

  unPublishVideo() {
    this.videoManager
      .updateVideoStatus(
        this.sUserEmail,
        this.sSessionId,
        this.videoManager.selectedVideo.fpath,
        "draft"
      )
      .then((res) => {
        this.oRouter.navigate(["/my-videos/draftsVideo"]);
      })
      .catch((err) => {
        if (err.errorMessage.includes('Monthly quota')) {
          this.errorService.handleError(err.errorMessage, 'Monthly Quota Exceeded', true, false, true);
        }
        this.errorService.handleError(err.errorMessage, err.errorType, true);
      });
  }

  deleteVideo() {
    this.videoManager
      .deleteVideo(
        this.sUserEmail,
        this.sSessionId,
        this.videoManager.selectedVideo.fpath
      )
      .then((res) => {
        this.oRouter.navigate(["/my-videos/draftsVideo"]);
      })
      .catch((err) => {
        this.errorService.handleError(err.errorMessage, err.errorType, true);
      });
  }

  TimelineViewComponent_NavigateToDashboard() {
    this.oRouter.navigate(["my-videos/draftsVideo"]);
  }
  TimelineViewComponent_GetClickLocation(event) {
    this.oClosePopup = 0;
    if (event.clientX <= 65) {
      this.oClosePopup = 1;
      setTimeout(() => {
        this.oClosePopup = 0;
      }, 200);
    }
  }

  isVisible = false;
  showModal(): void {
    this.isVisible = true;
  }
  handleOk(): void {
    console.log("Button ok clicked!");
    this.isVisible = true;
  }
  handleCancel(): void {
    console.log("Button cancel clicked!");
    this.isVisible = false;
  }

  checktag() {
    console.log(this.videoManager.selectedVideo?.key_terms, "checktag");
  }
}
