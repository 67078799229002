import { interval } from 'rxjs';
import { ProntoSettingService } from 'src/app/services/utils/pronto-setting.service';
import { preview } from './../../models/template';
import { element } from 'protractor';
import { duration } from 'src/app/models/video';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, Inject, AfterViewInit, OnDestroy, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cSessionService } from 'src/app/services/session.service';
import { VideoDataStore } from 'src/app/stores/video.store';

@Component({
    selector: 'app-media-editor-popup',
    templateUrl: './media-editor-popup.component.html',
    styleUrls: ['./media-editor-popup.component.scss'],
})
export class MediaEditorPopupComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() type: string;
    media: any;
    @Input() options: any;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<MediaEditorPopupComponent>,
        private videoStore: VideoDataStore,
        private renderer: Renderer2,
        private settings: ProntoSettingService,
    ) {}
    @ViewChild('mediaEditorPlayer', { static: false }) mediaEditorPlayer: ElementRef;
    @ViewChild('mediaEditorPlayerClone', { static: false }) mediaEditorPlayerClone: ElementRef<HTMLVideoElement>;
    @ViewChild('cloneVideoObj', { static: false }) cloneVideoObj: ElementRef;
    @ViewChild('durationbar', { static: false }) durationbar: ElementRef;
    @ViewChild('imgContainer', { static: false }) imgContainer: ElementRef;
    // @ViewChild('pointerScroll', { static: false }) pointerScroll: ElementRef;
    @ViewChild('slider', { static: false }) slider: ElementRef;
    listThumbs: string[] = [];
    maxAllowedClipLength = 6;
    widthDurationBar = 100;
    playerCurTime = 0;
    clipCurrentTime = 0;
    clipLeft = 0;
    previewPlayerCurrentTime = 0;
    previewPlayerEndTime = 0 + this.maxAllowedClipLength;
    loadSlider = false;
    dragPosition = { x: 0, y: 0 };
    playerInfo = {
        player: {
            current_time: 0,
        },
        clip: {
            current_time: 0,
            clip_time: 0,
            clip_left: 0,
            duration_width: 100,
            duration: 0,
            thumb_width: 96,
            second_per_pixel: 1,
            selector_width: 1,
        },
        pointer: {
            left: 0,
            seconds: 0,
        },
    };

    timeScaleBreakPoints: any;
    timeScaleLoaded = false;
    zoomLevel = 100;
    zoomRangeField: any = 100;
    videoFrameUrl = '';
    playerProgressRangeValue: number = 0;
    previewPlayerInterval = undefined;
    loadingFirstTime = true;
    ngOnDestroy(): void {
        // this.mediaEditorPlayer.nativeElement.src = "";
        // throw new Error('Method not implemented.');
    }
    ngOnInit() {
        // console.log(" oninit called...", this.data.media);
        this.media = this.data.media;
        // this.mediaEditorPlayer.nativeElement.setAttribute('src', this.media.url);
        let __this = this;
        // console.log('Is clip already loaded ', this.settings.findVideoPlayerWithSameSrc(this.media.url));
        setTimeout(() => {
            __this.reLoadPreviewPlayer(true);
        }, 1000);

        // console.log('..component init Called ');
    }

    revertChanges(reload = true) {
        this.media = undefined;
        this.media = this.data.media;

        console.log(this.media);

        if (this.media.ts && this.media.ts.length > 1) {
            this.maxAllowedClipLength = this.media.ts[1] + this.media.ts[0] - this.media.ts[0];
        }
        let __this = this;
        // setTimeout(() => {

        this.mediaEditorPlayer.nativeElement.canplaythrough = (event) => {
           // console.log(' -- Meta Data loaded ....');
        };
        this.loadingFirstTime = this.settings.findVideoPlayerWithSameSrc(this.media.url);
        let intervalLoading =  setInterval( () =>{
            //console.log(  this.mediaEditorPlayer.nativeElement.readyState );
            if(this.mediaEditorPlayer.nativeElement.readyState == 4){
                this.calculatePostion();
                clearInterval(intervalLoading);
            }

        }, 100);

        // if(this.loadingFirstTime ){
        //     this.mediaEditorPlayer.nativeElement.onloadeddata = (event) => {
        //         // console.log(" PLayer loaded fully ...");
        //         // this.loadSlider = true;
        //         this.calculatePostion();
        //         // this.mediaEditorPlayer.nativeElement.onloadeddata = undefined;
        //     };
        //      if (!this.media.replaceClip) {
        //     //    console.log("Replace clip here ...");
        //     //     // this.loadSlider = true;
        //          this.calculatePostion();
        //     }
        // } else {
        //     this.calculatePostion();
        // }
        // if (reload || this.loadingFirstTime) {
        //     // console.log(' reload true called');
        //     this.mediaEditorPlayer.nativeElement.onloadeddata = (event) => {
        //         // console.log(" PLayer loaded fully ...");
        //         // this.loadSlider = true;
        //         this.calculatePostion();
        //         // this.mediaEditorPlayer.nativeElement.onloadeddata = undefined;
        //     };
        //     if (!this.media.replaceClip) {
        //         // console.log("Replace clip here ...");
        //         // this.loadSlider = true;
        //         this.calculatePostion();
        //     }
        // } else {
        //     // console.log(" PLayer loaded fully ...");
        //     // this.loadSlider = true;
        //     this.calculatePostion();
        //     // this.mediaEditorPlayer.nativeElement.onloadeddata = undefined;
        // }

        this.loadEventListeners();
        // }, 100);
    }
    ngAfterViewInit(): void {
        // console.log("After init called..");
        let __this = this;
        setTimeout(() => {
            __this.revertChanges();
        }, 1000);
    }

    loadEventListeners() {
        let __this = this;
        this.mediaEditorPlayer.nativeElement.addEventListener('play', () => {
            this.mediaEditorPlayer.nativeElement.currentTime = this.playerInfo.pointer.seconds;
            this.previewPlayerEventListenerPlay(__this);
        });
        this.mediaEditorPlayer.nativeElement.addEventListener('pause', () => {
            this.previewPlayerEventListenerPause(__this);
        });
        this.mediaEditorPlayer.nativeElement.addEventListener('ended', () => {
            this.previewPlayerEventListenerStop(__this);
        });
    }

    updateProgressBar() {
        this.playerProgressRangeValue = this.playerProgressRangeValue + 100 / this.maxAllowedClipLength / 4;
    }
    playPreviewPlayer() {
        this.mediaEditorPlayer.nativeElement.play();
        this.playerProgressRangeValue = this.mediaEditorPlayer.nativeElement.currentTime;
    }
    previewPlayerEventListenerPlay(obj) {
        let __this = obj;
        this.previewPlayerInterval = setInterval(() => {
            // console.log('Play called ...', __this);
            if (__this.mediaEditorPlayer.nativeElement.readyState >= 3) {
                // console.log("playing frame : ", __this.mediaEditorPlayer.nativeElement.currentTime, __this.previewPlayerEndTime, __this.previewPlayerEndTime > __this.mediaEditorPlayer.nativeElement.currentTime);
                // console.log("playing time ... ", __this.mediaEditorPlayer.nativeElement.currentTime, __this.previewPlayerEndTime, " play time slider ", Math.round(this.playerInfo.pointer.seconds));
                if (__this.previewPlayerEndTime < __this.mediaEditorPlayer.nativeElement.currentTime) {
                    // console.log(".. Clear interval ...");
                    __this.mediaEditorPlayer.nativeElement.pause();
                    clearInterval(__this.previewPlayerInterval);
                } else {
                    __this.updateProgressBar();
                }
            }
        }, 250);
    }
    previewPlayerEventListenerPause(obj) {
        let __this = obj;
        clearInterval(__this.previewPlayerInterval);
        __this.playerProgressRangeValue = 0;
    }
    previewPlayerEventListenerStop(obj) {
        // console.log("Stop interval called...");
        let __this = obj;

        clearInterval(__this.previewPlayerInterval);
        __this.mediaEditorPlayer.nativeElement.currentTime = this.playerInfo.clip.clip_time;
        __this.playerProgressRangeValue = 0;
    }

    async calculatePostion() {
        this.playerInfo.clip.duration = Math.round(this.mediaEditorPlayer.nativeElement.duration);
        this.media.duration = Math.round(this.mediaEditorPlayer.nativeElement.duration);
        let expectedWith = this.playerInfo.clip.duration * 96;
        if (expectedWith > 2000) {
            this.playerInfo.clip.duration_width = 2000;
        } else if (expectedWith < this.durationbar.nativeElement.offsetWidth) {
            if (expectedWith < this.durationbar.nativeElement.offsetWidth) {
                this.playerInfo.clip.duration_width = expectedWith;
            } else {
                this.playerInfo.clip.duration_width = this.durationbar.nativeElement.offsetWidth;
            }
        } else {
            this.playerInfo.clip.duration_width = this.playerInfo.clip.duration * 96;
        }

        this.playerInfo.clip.second_per_pixel = this.playerInfo.clip.duration_width / this.playerInfo.clip.duration;
        this.playerInfo.clip.thumb_width = this.playerInfo.clip.second_per_pixel;
        this.playerInfo.clip.clip_left = this.media.start_point * this.playerInfo.clip.second_per_pixel;
        this.dragPosition.x = this.playerInfo.clip.clip_left;
        this.playerInfo.pointer.seconds = this.media.start_point;
        this.playerInfo.pointer.left = this.playerInfo.clip.clip_left;

        this.playerInfo.clip.selector_width = this.maxAllowedClipLength * this.playerInfo.clip.second_per_pixel;

        // console.log(' Checking Thumbs of Clips ....');
        var duration = this.playerInfo.clip.duration;
        var i = 0;
        var __this = this;
        this.timeScaleLoaded = true;
        this.listThumbs = [];
        for (let j = 0; j < duration; j++) {
            this.listThumbs.push('../assets/images/icons/video-spinner-sm.svg');
        }
        for (let k = 0; k < duration; k++) {
            // console.log(' -- generate Thumbs ');
            await this.generateThumnailv2(k);
            if (k == 0) {
                __this.loadSlider = true;
                this.loadSlider = true;
            }
        }
    }

    afterDragMovement(x, y) {
        this.playerInfo.pointer.seconds = Math.round(x / this.playerInfo.clip.second_per_pixel);
        this.playerInfo.clip.clip_time = this.playerInfo.pointer.seconds;
        this.reLoadPreviewPlayer(false);
        this.mediaEditorPlayer.nativeElement.pause();
    }

    reLoadPreviewPlayer(firstLoad = false) {
        // console.log(this.data.media);
        if (!this.media.start_time) {
            this.media.start_time = 0;
        }

        if (firstLoad) {
            this.previewPlayerCurrentTime = this.media.start_time;
            this.playerInfo.pointer.seconds = this.media.start_time;
            this.previewPlayerEndTime = this.media.start_point + this.maxAllowedClipLength;
            this.videoFrameUrl = this.media.url;
            // console.log(' load first time ..');
            // this.mediaEditorPlayer.nativeElement.setAttribute('src', this.videoFrameUrl);
        } else {
            // console.log( " Now the value is ", this.playerInfo );
            this.previewPlayerCurrentTime = Math.round(this.playerInfo.pointer.seconds);
            this.previewPlayerEndTime = this.playerInfo.pointer.seconds + this.maxAllowedClipLength;
            // this.previewPlayerEndTime = this.playerInfo.pointer.seconds + this.maxAllowedClipLength
            // this.videoFrameUrl = `${this.media.url}#t=${this.playerInfo.pointer.seconds},${
            //     this.playerInfo.pointer.seconds + this.maxAllowedClipLength
            // }`;
        }

        // console.log("setting current time of player ", this.previewPlayerCurrentTime);
        if (this.mediaEditorPlayer && this.mediaEditorPlayer.nativeElement) {
            // this.mediaEditorPlayer.nativeElement.setAttribute('src', this.videoFrameUrl);
            // console.log( " math round ", this.previewPlayerCurrentTime ,parseInt(this.previewPlayerCurrentTime+"", 10));
            if (this.previewPlayerCurrentTime) {
                this.mediaEditorPlayer.nativeElement.currentTime = parseInt(this.previewPlayerCurrentTime + '', 10);
            } else {
                this.mediaEditorPlayer.nativeElement.currentTime = 0;
            }
            this.mediaEditorPlayer.nativeElement.muted = true;
            // this.mediaEditorPlayer.nativeElement.play();
            this.mediaEditorPlayer.nativeElement.pause();
        }
    }

    updateCurrentPostion($event) {
        var position = $event.target.getBoundingClientRect();
        var x = position.left;
        var y = position.top;
        this.afterDragMovement(x, y);
    }

    dragStart = false;
    prevDragPosition = undefined;
    dragMoveSlider($event: any) {
        let { x, y } = $event.source.getFreeDragPosition();
        // console.log(
        //     x,
        //     this.slider.nativeElement.offsetWidth,
        //     x + this.slider.nativeElement.offsetWidth,
        //     this.imgContainer.nativeElement.offsetWidth,
        // );
        // console.log(x, y);
        // console.log("Transform ", this.slider.nativeElement.style);
        // console.log("Transform ", this.slider.nativeElement.style.transform);
        let transformPos = this.settings.transformLeft(this.slider.nativeElement.style.transform);
        // console.log( this.playerInfo.clip.selector_width , x,  this.imgContainer.nativeElement.offsetWidth)
        if (x + this.playerInfo.clip.selector_width >= this.imgContainer.nativeElement.offsetWidth) {
            // console.log("Stop propogation ", $event);
            if ($event && $event.source) {
                // console.log(" dont move it ");
                $event.event.stopPropagation();
                this.slider.nativeElement.style.transform = this.prevDragPosition;
                $event.event.stopImmediatePropagation();
                // $event.source.stopImmediatePropagation();
            }
        } else {
            this.prevDragPosition = this.slider.nativeElement.style.transform;
            this.afterDragMovement(x, y);
        }
    }

    dragStarted($event) {}
    dragEnded($event) {}
    dragSliderEnds($event) {}

    generateThumnailv2(i: any): Promise<boolean> {
        // console.log('into promise ... GEn thumb ', i);
        return new Promise((resolve, reject) => {
            // console.log('checking thumbs', i);
            //generate thumbnail URL data
            let canvas = this.renderer.createElement('canvas');
            let context = canvas.getContext('2d');
            let videoElm = this.mediaEditorPlayerClone.nativeElement;
            // console.log(this.mediaEditorPlayerClone.nativeElement);
            let __this = this;
            let className = `videoClone-` + i;
            videoElm.currentTime = i + 1;
            videoElm.muted = true;
            if (videoElm) {
                videoElm.play();
            }

            try {
                videoElm.onplaying = function () {
                    videoElm.pause();
                    setTimeout(() => {
                        // console.log('Loading Video player ...', i);
                        // videoElm.onloadeddata = (event) => {

                        // console.log(' Generating Thumb Img');
                        context.drawImage(videoElm, 0, 0, 300, 150);
                        var dataURL = canvas.toDataURL();
                        // console.log(__this.listThumbs.length);
                        __this.listThumbs[i] = dataURL;
                        // var img = new Image();
                        // img.onload = function () {
                        //     console.log('"onload" fires asynchronously even when cached:: ', i);
                        // };
                        // img.src = canvas.toDataURL();
                        // console.log('cached : ', img.complete, img.naturalWidth);
                        resolve(true);
                        // };
                    }, 10);
                };
            } catch (err) {
                resolve(true);
            }
        });
    }

    updateZoomLevel(increase) {
        if (increase) {
            if (this.zoomRangeField < 100) {
                ++this.zoomRangeField;
                this.reloadZoomSettings(this.zoomRangeField);
            }
        } else {
            if (this.zoomRangeField > 1) {
                --this.zoomRangeField;
                this.reloadZoomSettings(this.zoomRangeField);
            }
        }
    }
    updateZoom($event) {
        // console.log('Value here ', this.zoomRangeField, 100 - this.zoomRangeField, this.zoomRangeField - (100 - this.zoomRangeField));
        // this.zoomLevel = $event.value * 10;
        // let zoomNew  = (100 - this.zoomRangeField);
        this.reloadZoomSettings(this.zoomRangeField);
    }

    getValueOfPercent(value, percent) {
        return (value * percent) / 100;
    }
    reloadZoomSettings(zoomLevel) {
        let expectedWith = this.playerInfo.clip.duration * 96;
        if (expectedWith > 2000) {
            this.playerInfo.clip.duration_width = 2000;
        } else if (expectedWith < this.durationbar.nativeElement.offsetWidth) {
            this.playerInfo.clip.duration_width = this.durationbar.nativeElement.offsetWidth;
        } else {
            this.playerInfo.clip.duration_width = this.playerInfo.clip.duration * 96;
        }

        this.playerInfo.clip.duration_width = this.getValueOfPercent(this.playerInfo.clip.duration_width, zoomLevel);

        this.playerInfo.clip.second_per_pixel = this.playerInfo.clip.duration_width / this.playerInfo.clip.duration;
        this.playerInfo.clip.thumb_width = this.playerInfo.clip.second_per_pixel;
        this.playerInfo.clip.clip_left = this.media.start_point * this.playerInfo.clip.second_per_pixel;
        this.dragPosition.x = this.playerInfo.clip.clip_left;
        this.playerInfo.pointer.seconds = this.media.start_point;
        this.playerInfo.pointer.left = this.playerInfo.clip.clip_left;
        // this.pointerScroll.nativeElement.style.left = this.playerInfo.clip.clip_left;
        this.playerInfo.clip.selector_width = this.maxAllowedClipLength * this.playerInfo.clip.second_per_pixel;
        // this.slider.nativeElement.width = `${this.playerInfo.clip.selector_width}px`;
        if (this.slider && this.slider.nativeElement) {
            this.slider.nativeElement.style.transform = `translate3d(${this.playerInfo.clip.clip_left}px, 0px, 0px)`;
        }
    }

    closeEditor() {
        this.dialogRef.close();
    }

    update() {
        this.dialogRef.close({ type: 'success', data: { stauts: 'save' } });
    }
    close() {
        this.dialogRef.close({ type: 'success', data: {} });
    }

    dragOver() {
        // console.log('---- ', this.playerInfo.clip.clip_time, this.playerInfo.clip.second_per_pixel);
    }

    changePosition(event) {
        // console.log(event);
        // this.dragPosition = {x: this.dragPosition.x + 50, y: this.dragPosition.y + 50};
    }

    updateVideo() {
        console.log('Called it ..', { start: this.previewPlayerCurrentTime, end: this.previewPlayerEndTime });
        this.dialogRef.close({ type: 'success', data: { startfrom: this.previewPlayerCurrentTime } });
    }
}
