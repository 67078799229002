<app-loader *ngIf="bDisplayLoader"></app-loader>
<header class="header header-1 drop-shadow" [ngClass]="bFullHeader?'':'width-100'">
    <div class="container">
        <nav class="navbar navbar-expand-lg">
            <div class="d-flex w-100">
                <div class="col-4 d-flex align-items-center">
                    <div class="back_cta"> <a href="javascript:void(0)"
                            (click)="DashboardHeaderComponent_NavigateToDashboard()">
                            <img src="../assets/images/back-arrow.svg" alt="back-arrow">
                        </a></div>
                    <h5 class="text-gray-1 pt-3">{{this.videoManager.selectedVideo?.title}}
                        <span
                            class="d-block text-xs text-gray-3 secondaryFont weight-400">{{this.videoManager.selectedVideo?.publish_status==='published'?'Published':'Created'}}
                            {{this.videoManager.selectedVideo?.created | date}}</span>
                    </h5>
                </div>
                <div class="col text-end pt-2">

                    <ul class="d-inline-block dashboard-nav" *ngIf="!isTryNowFlow"
                        [class.border-right]="bDisplaySchedularBtn">
                        <li *ngIf="this.videoManager.selectedVideo?.template_name == 'Bold'">
                            <a class="side-item" href="javascript:void(0)"
                                (click)="(this.onboardingManager.profile.user_status  == 'confirmed_not_subscribed')? goToSubscribe(): openExpectRationModel($event)"
                                *ngIf="bIsPublish">
                                <img class="icon-svg" src="../assets/images/icons/resize-video-icon.svg" width="16"
                                    height="16" />

                                Adjust
                            </a>
                        </li>
                        <li *ngIf="this.pronto.sVideoStatus=='draft' && this.isEdit">
                            <a class="side-item" href="javascript:void(0)" (click)="editVideo()" *ngIf="bIsPublish">
                                <img class="icon-svg" src="../../../assets/images/svg/edit.svg">

                                Edit
                            </a>
                        </li>
                        <li>
                            <a class="side-item" href="javascript:void(0)" (click)="delVideo()">
                                <img class="icon-svg" src="../../../assets/images/svg/delete.svg">
                                Delete
                            </a>

                        </li>
                        <li>
                            <!-- <a routerLink="/dashboard/download" [class.active]="showMenu === '/dashboard/download'"> -->

                            <a class="side-item" routerLink="/dashboard/download">
                                <img class="icon-svg" src="../../../assets/images/svg/download.svg">
                                Download
                            </a>
                        </li>
                        <li>
                            <!-- <a routerLink="/dashboard/socialmedia" [class.active]="showMenu === '/dashboard/socialmedia'"> -->

                            <a class="side-item" routerLink="/dashboard/socialmedia">
                                <img class="icon-svg" src="../../../assets/images/svg/share.svg">
                                Share
                            </a>
                        </li>
                    </ul>
                    <a *ngIf="bDisplaySchedularBtn && !isTryNowFlow" href="javascript:void(0)"
                        (click)="PublishUnpublis()"
                        class="btn text-xs btn-outline-gray-radius text-gray-2 small-pad btn-icon">
                        <img class="svg-btn-icon me-1" *ngIf="this.pronto.sVideoStatus==='published'"
                            src="../../../assets/images/svg/unpublished.svg">
                        <img class="svg-btn-icon me-1" *ngIf="this.pronto.sVideoStatus==='draft'"
                            src="../../../assets/images/svg/publish now.svg">
                        {{this.pronto.sVideoStatus==='published'?' Unpublish':'Publish'}}
                    </a>
                    <a *ngIf="bDisplaySchedularBtn && !isTryNowFlow" href="javascript:void(0)" (click)="showModal()"
                        class=" btn btn-primary btn-round-6 d-inline-block small-pad ms-2"><svg class="me-1" width="16"
                            height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0 8C0 12.413 3.58698 16 8 16C12.413 16 16 12.413 16 8C16 3.58698 12.413 0 8 0C3.58698 0 0 3.58698 0 8ZM1.11628 8C1.11628 4.20465 4.20465 1.11628 8 1.11628C11.7953 1.11628 14.8837 4.20465 14.8837 8C14.8837 11.7953 11.7953 14.8837 8 14.8837C4.20465 14.8837 1.11628 11.7953 1.11628 8ZM10.5186 10.5867C10.6047 10.6449 10.6979 10.6667 10.7912 10.6667C10.9705 10.6667 11.1499 10.5722 11.2575 10.3977C11.4081 10.136 11.3292 9.80153 11.071 9.64886L8.84713 8.30389C8.62475 8.17303 8.40954 7.78771 8.40954 7.52599V4.54526C8.40954 4.24718 8.16564 4 7.87152 4C7.5774 4 7.3335 4.24718 7.3335 4.54526V7.52599C7.3335 8.17303 7.74239 8.90731 8.29476 9.24173L10.5186 10.5867Z"
                                fill="#FF8369" />
                        </svg>
                        Schedule Video</a>
                </div>
            </div>
        </nav>
    </div>
</header>
<!-- TimeOut Error -->
<!-- <div *ngIf="bAlert" class=" alert alert-danger d-flex align-items-center alert-dismissible fade show  col-md-2 m-4" role="alert">
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none"  xmlns="http://www.w3.org/2000/svg">
            <path d="M27.5683 21.8754L16.8998 3.08014C15.6241 0.839822 12.3899 0.839822 11.1141 3.08014L0.445544 21.8754C-0.830163 24.0854 0.788172 26.8545 3.33834 26.8545H24.6437C27.2254 26.8545 28.8121 24.1169 27.568 21.8754H27.5683ZM14.0387 7.56066C15.0337 7.56066 15.5318 8.46261 15.5318 10.2994C15.5318 11.2949 15.4079 12.6649 15.1273 14.4385C14.8478 16.2121 14.6607 17.3012 14.4736 17.6441C14.3497 17.8933 14.2245 18.0173 14.0387 18.0173C13.8212 18.0173 13.6657 17.9237 13.5721 17.7061C13.4166 17.4265 13.1991 16.4297 12.9185 14.7182C12.639 13.0066 12.5139 11.5126 12.4835 10.3298C12.4835 8.46383 13.012 7.56066 14.0387 7.56066V7.56066ZM15.1273 22.0627C14.8162 22.3739 14.4432 22.5295 14.0071 22.5295C13.5721 22.5295 13.1979 22.3739 12.8869 22.0627C12.5758 21.7516 12.4203 21.3784 12.4203 20.942C12.4203 20.5068 12.5758 20.1324 12.8869 19.8212C13.1979 19.51 13.5709 19.3544 14.0071 19.3544C14.4116 19.3544 14.7846 19.51 15.1273 19.8528C15.4699 20.1956 15.6254 20.5688 15.6254 20.9736C15.6242 21.3784 15.4383 21.7516 15.1273 22.0627Z" fill="#842029" />
        </svg>
        <div>
            Prontopia is experiencing network issues right now. We’re working on fixing this as soon as possible. Please refresh or try again later.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"  d="M0.546609 15.7333C0.406624 15.7333 0.273304 15.68 0.166649 15.58C-0.0399958 15.3733 -0.0399958 15.0333 0.166649 14.8267L7.11925 7.86667L0.153317 0.906667C0.0533277 0.806667 0 0.673333 0 0.533333C0 0.393333 0.0533277 0.26 0.153317 0.16C0.259972 0.0533333 0.393292 0 0.533277 0C0.673262 0 0.806581 0.0533333 0.913237 0.153333L7.8725 7.11333L14.8251 0.153333C14.9251 0.0533333 15.0584 0 15.1984 0C15.3384 0 15.4717 0.0533333 15.5784 0.153333C15.785 0.36 15.785 0.7 15.5784 0.906667L8.62575 7.86667L15.5717 14.8133C15.6717 14.9133 15.725 15.0467 15.725 15.1867C15.725 15.3267 15.6717 15.46 15.5717 15.5667C15.4717 15.6733 15.3384 15.72 15.1917 15.72C15.0451 15.72 14.9184 15.6667 14.8118 15.5667L7.8725 8.62L0.919903 15.58C0.819913 15.68 0.686594 15.7333 0.546609 15.7333Z" fill="#FF8369" />
                </svg>
            </button>
        </div>
    </div>  -->
<!-- Timeout Error End  -->

<!-- autoCreateModal -->
<!-- <div class="modal fade modalCustom" id="autoCreateModal" tabindex="-1" aria-labelledby="exampleModalLabel"
aria-hidden="true"> -->
<nz-modal [(nzVisible)]="isVisible" nzCentered [nzWidth]="1800" [nzFooter]=null [nzClosable]=false
    (nzOnCancel)="handleCancel()">
    <app-scheduled-popup (click)="DashboardHeaderComponent_GetClickLocation($event)" [lInputClosePopup]="oClosePopup"
        (onClose)="handleCancel()"></app-scheduled-popup>
</nz-modal>
<!-- </div> -->
<!-- Modal pop-up Start-->
<nz-modal [(nzVisible)]="delPopup" nzCentered [nzWidth]="566" [nzFooter]=null [nzClosable]=false
    (nzOnCancel)="handleCancel()">

    <div class="modal-content">

        <div class="modal-body">
            <button type="button" class="btn-close cust-close closeRightBtn" (click)="handleCancel()"></button>

            <h5>Are you sure you want to delete this Video?</h5>
            <p class="text-sm text-gray-3 pt-1 pb-4">The video will be permanently deleted from your account.
            </p>
            <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" (click)="handleCancel()">Cancel</a>
            <a href="javascript:void(0)" (click)="deleteVideo()" class="mt-3 text-gray-3 text-sm">Delete</a>
        </div>
    </div>
</nz-modal>

<!-- Modal pop-up End-->



<!-- Modal pop-up Start-->

<nz-modal [(nzVisible)]="unpublishPopup" nzCentered [nzWidth]="566" [nzFooter]=null [nzClosable]=false
    (nzOnCancel)="handleCancel()">

    <!-- <div class="modal fade" *ngIf="unpublishPopup">
        <div class="modal-dialog modal-dialog-centered"> -->
    <div class="modal-content">

        <div class="modal-body p-4">
            <h5>Are you sure you want to {{this.pronto.sVideoStatus==='published'?' unpublish':'publish'}} this Video.
            </h5>
            <p *ngIf="this.pronto.sVideoStatus==='draft'" class="text-sm text-gray-3 pt-1 pb-4">This video will be
                published and
                moved out of your drafts.</p>
            <p *ngIf="this.pronto.sVideoStatus==='published'" class="text-sm text-gray-3 pt-1 pb-4">You'll be able to
                edit or
                republish it from your drafts.</p>

            <div *ngIf="this.pronto.sVideoStatus==='draft'">
                <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" data-bs-dismiss="modal"
                    (click)="DashboardHeaderComponent_TogglePublishStatus()">Publish</a>
                <a class="mt-3 text-gray-3 text-sm" (click)="handleCancel()">Cancel</a>
            </div>
            <div *ngIf="this.pronto.sVideoStatus!='draft'">
                <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" data-bs-dismiss="modal"
                    (click)="handleCancel()">Cancel</a>
                <a (click)="DashboardHeaderComponent_TogglePublishStatus()"
                    class="mt-3 text-gray-3 text-sm">{{this.pronto.sVideoStatus==='published'?'
                    Unpublish':'Publish'}}</a>
            </div>
        </div>
    </div>
    <!-- </div>
    </div> -->
</nz-modal>
<!-- Modal pop-up End-->