import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CreateVideoManagerService } from 'src/app/Managers/create-video-manager.service';
import { ModifyVideoManagerService } from 'src/app/Managers/modify-video-manager.service';
import { VideoManagerService } from 'src/app/Managers/video-manager.service';
import { address, ModifyVideo } from 'src/app/models/modify-video';
import { cSessionService } from 'src/app/services/session.service';
import { FormsModule } from '@angular/forms';
import { ErrorService } from 'src/app/services/error.service';
import { OnboardingManagerService } from 'src/app/Managers/onboarding-manager.service';

@Component({
  selector: 'app-mv-other-setting',
  templateUrl: './mv-other-setting.component.html',
  styleUrls: ['./mv-other-setting.component.scss']
})
export class MvOtherSettingComponent implements OnInit, OnDestroy {

  sSessionId: string = "";
  sUserEmail: string = "";
  fOtherSettingForm: FormGroup;
  bURLToggle: boolean = true;
  bAddressToggle: boolean = false;
  bPhoneToggle: boolean = false;
  public sapVideoTagLine : string = "";

  constructor(public modifyVideoObj : ModifyVideo, private oFormBuilder: FormBuilder, public modifyVideoManager: ModifyVideoManagerService, private router: Router,
    public videoManager: CreateVideoManagerService,
    private oSessionService: cSessionService,
    private onBoardingManager: OnboardingManagerService,
    public errorService: ErrorService,
    public videoListingManager: VideoManagerService,) { }

  sub: Subscription;
  sub2: Subscription;
  ngOnInit() {


    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    this.sub2 = this.videoManager.previousSubmit.subscribe(emit => {
      this.previous();
    });
    if(this.videoManager.selectedVideo.sap_tag_line && this.videoManager.selectedVideo.sap_tag_line != ''){
      this.sapVideoTagLine = this.videoManager.selectedVideo.sap_tag_line
    }
    this.sub = this.videoManager.submitForm.subscribe(emit => {
      this.submitForm(emit);
    });
    this.OtherSettingComponent_InitializeOverviewForm();
  }

  ngOnDestroy() {
    if (this.sub2)
      this.sub2.unsubscribe();
    if (this.sub)
      this.sub.unsubscribe();
  }

  previous(){
    this.modifyVideoObj.url = this.fOtherSettingForm.controls['url'].value;
    this.modifyVideoObj.phone = this.fOtherSettingForm.controls['phone'].value;

    this.router.navigateByUrl('/mv/content');
  }
  submitForm(submitFrom: string) {
    if ((this.fOtherSettingForm?.controls['sapVideoTagLine'].value == '') || !this.fOtherSettingForm.valid)
    {
      this.fOtherSettingForm.markAllAsTouched();
    }
    else{
      this.videoManager.selectedVideo.session_id =
        this.fOtherSettingForm.value.session_id;
      this.videoManager.selectedVideo.email =
        this.fOtherSettingForm.value.email;
        this.updateVideoLocally();
      console.log(this.videoManager.selectedVideo);
      this.videoManager
        .createVideo(this.videoManager.selectedVideo)
        .then((res) => {
          this.videoListingManager.selectedVideo =
            this.videoManager.selectedVideo;

            this.router.navigateByUrl('/mv/preview');
        });

    }
  }

  private updateVideoLocally() {
    console.log("update locally");
    if (this.bURLToggle) {
      this.videoManager.selectedVideo.url = this.fOtherSettingForm.value.url;
    }
    if (this.bAddressToggle) {
      this.videoManager.selectedVideo.address = new address();

      this.videoManager.selectedVideo.address.line1 =
        this.fOtherSettingForm.value.line1;
      this.videoManager.selectedVideo.address.line2 =
        this.fOtherSettingForm.value.line2 ? this.fOtherSettingForm.value.line2 : '';
      this.videoManager.selectedVideo.address.city =
        this.fOtherSettingForm.value.city;
      this.videoManager.selectedVideo.address.state =
        this.fOtherSettingForm.value.state;
      this.videoManager.selectedVideo.address.postal_code =
        this.fOtherSettingForm.value.postal_code;
      this.videoManager.selectedVideo.address.country =
        this.fOtherSettingForm.value.country ? this.fOtherSettingForm.value.country : undefined;
    } else {
      if (this.videoManager.selectedVideo.ci_dict) {
        this.videoManager.selectedVideo.ci_dict.address = undefined;
      }
      this.videoManager.selectedVideo.address = undefined;
    }

    if(this.fOtherSettingForm.value.sapVideoTagLine && this.fOtherSettingForm.value.sapVideoTagLine !=''){
      this.videoManager.selectedVideo.sap_tag_line = this.fOtherSettingForm.value.sapVideoTagLine;
    } else {
      delete this.videoManager.selectedVideo.sap_tag_line;
    }

    if (this.bPhoneToggle) {
      this.videoManager.selectedVideo.phone =
        this.fOtherSettingForm.value.phone;
    }
  }



  OtherSettingComponent_InitializeOverviewForm() {
    var url = this.onBoardingManager.profile.url;

    if (
      this.videoManager.selectedVideo.ci_dict?.url &&
      this.videoManager.selectedVideo.ci_dict?.url != ""
    ) {
      url = this.videoManager.selectedVideo.ci_dict?.url;
    }

    if(this.videoManager.selectedVideo.brand_id && this.videoManager.videoBrand && this.videoManager.videoBrand?.website ){
      url = this.videoManager.videoBrand?.website;
    }
    this.fOtherSettingForm = this.oFormBuilder.group(
      {

        session_id: this.sSessionId,
        email: this.sUserEmail,

        url: [
          url,
          [
            Validators.required,
            Validators.pattern(
              "(https?://)?([\\dA-Za-z.-]+)\\.([A-Za-z.]{2,6})[/\\w .-]*/?"
            ),
          ],
        ],
        line1: '',
        line2: '',
        city:  '',
        state: '',
        postal_code: '',
        country:  '',
        phone: '',
        bDisplayUrl: this.bURLToggle,
        bDisplayAddress: this.bAddressToggle,
        bDisplayPhone: this.bPhoneToggle,
        sapVideoTagLine : this.sapVideoTagLine
      });
  }

  OtherSettingComponent_PhoneToggle(event: any) {

    this.fOtherSettingForm?.controls['phone']?.setValue('');
    this.bPhoneToggle = event.target.checked;
    this.fOtherSettingForm.patchValue({ bDisplayPhone: this.bPhoneToggle });
    if (this.bPhoneToggle) {


      this.fOtherSettingForm.controls['phone'].setValidators([
        Validators.required,
        ,

      ]);
    } else {
      this.fOtherSettingForm.controls['phone'].clearValidators();
    }


     this.fOtherSettingForm.controls['phone'].updateValueAndValidity();




  }
  OtherSettingComponent_AddressToggle(event: any) {
    this.bAddressToggle = event.target.checked;
    this.fOtherSettingForm?.patchValue({ bDisplayAddress: this.bAddressToggle });

    if (this.bAddressToggle) {


      this.fOtherSettingForm.controls['line1'].setValidators([
        Validators.required
      ]);


      // this.fOtherSettingForm.controls['line2'].setValidators([

      // ]);
      this.fOtherSettingForm.controls['city'].setValidators([
        Validators.required
      ]);
      this.fOtherSettingForm.controls['state'].setValidators([
        Validators.required
      ]);
      this.fOtherSettingForm.controls['postal_code'].setValidators([
        Validators.required
      ]);
      // this.fOtherSettingForm.controls['country'].setValidators([

      // ]);


    } else {
      this.fOtherSettingForm?.controls['line1'].clearValidators();
      console.log('***********************');
      // this.fOtherSettingForm?.controls['line2'].clearValidators();
      this.fOtherSettingForm?.controls['city'].clearValidators();
      this.fOtherSettingForm?.controls['state'].clearValidators();
      this.fOtherSettingForm?.controls['postal_code'].clearValidators();
      // this.fOtherSettingForm?.controls['country'].clearValidators();


    }


    this.fOtherSettingForm?.controls['line1'].updateValueAndValidity();
    console.log('***********************');
    //  this.fOtherSettingForm?.controls['line2'].updateValueAndValidity();
     this.fOtherSettingForm?.controls['city'].updateValueAndValidity();
    this.fOtherSettingForm?.controls['state'].updateValueAndValidity();
   this.fOtherSettingForm?.controls['postal_code'].updateValueAndValidity();
  //  this.fOtherSettingForm?.controls['country'].updateValueAndValidity();






  }

  OtherSettingComponent_URLToggle(event: any) {
    this.bURLToggle = event.target.checked;
    this.fOtherSettingForm.patchValue({ bDisplayUrl: this.bURLToggle });
    console.log(this.bURLToggle)
    if (this.bURLToggle) {
      console.log(this.fOtherSettingForm.controls["url"]);

      this.fOtherSettingForm.controls["url"].setValidators([
        Validators.required,

        Validators.pattern(
          "(https?://)?([\\dA-Za-z.-]+)\\.([A-Za-z.]{2,6})[/\\w .-]*/?"
          ),
      ]);
    } else {
      this.fOtherSettingForm.controls["url"].clearValidators();
    }

    console.log(this.fOtherSettingForm.controls["url"]);
    this.fOtherSettingForm.controls["url"].updateValueAndValidity();
  }

}
