
import { Pipe, PipeTransform } from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: 'timestampToDate'})
export class TimestampToDate implements PipeTransform {

  toTime(timestamp = 0) {
    var date = new Date(timestamp * 1000);
    return date.toISOString().substring(0, 10);
 }

  transform(value: number,): string {
    return this.toTime(value);
  }
}