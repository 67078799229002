<!-- <app-dashboard-sidepanels *ngIf="this.onboardingManager.profile.user_account_type && this.onboardingManager.profile.user_account_type !='smb'"></app-dashboard-sidepanels> -->
<app-header></app-header>
<section class="dashboard main-body bg-gray-7 h-100 "
    [ngClass]="this.onboardingManager.profile.user_account_type && this.onboardingManager.profile.user_account_type !='smb'?'':''">
    <div class="container pr-30">
        <div class=" br12 bg-white page-shadow curve-bg content-panel-height">
            <div class="py-4 px-4 pb-0 account-heading">
                <div class="row">
                    <div class="col-md-5">
                        <h2 class="weight-700 text-gray-2">What will you <span class="text-primary-3">create</span>
                            today? </h2>
                        <p class="mx-0 my-0 text-xl primaryFont text-gray-3">Manage and create new videos.</p>
                    </div>
                    <div class="col-xl-7 col-lg-7 col-md-7">
                        <div class="text-end d-block">
                            <!-- <a href="javascript:void(0)" id="mr-2"
                                class="weight-500 btn btn-outline-gray-radius md-btn mt-4 d-inline-block me-3 text-gray-2 text-xs mr-2 btnShadow"><svg
                                    width="17" height="16" viewBox="0 0 17 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M1.33402 0.833313C1.61019 0.833313 1.83406 1.05717 1.83406 1.33331V12.6666C1.83406 13.4972 2.50359 14.1666 3.33418 14.1666H14.6684C14.9446 14.1666 15.1685 14.3905 15.1685 14.6666C15.1685 14.9428 14.9446 15.1666 14.6684 15.1666H3.33418C1.95126 15.1666 0.833984 14.0495 0.833984 12.6666V1.33331C0.833984 1.05717 1.05786 0.833313 1.33402 0.833313Z"
                                        fill="#7A7A7A" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M14.3269 4.28691C14.5366 4.46655 14.561 4.7822 14.3813 4.99191L11.3144 8.57191L11.3131 8.57344C10.617 9.37934 9.38031 9.42621 8.62757 8.67353L7.99204 8.03805C7.65486 7.69678 7.09344 7.71624 6.77332 8.08609C6.77312 8.08632 6.77292 8.08656 6.77272 8.08679L3.71384 11.6585C3.53421 11.8683 3.21855 11.8927 3.00879 11.7131C2.79902 11.5335 2.7746 11.2178 2.95423 11.0081L6.01447 7.43476L6.01583 7.43317C6.70887 6.63077 7.94618 6.57011 8.70232 7.33405C8.7027 7.33444 8.70309 7.33484 8.70348 7.33523L9.33473 7.96642C9.67522 8.30688 10.2382 8.28728 10.5557 7.92045C10.5558 7.92024 10.556 7.92003 10.5562 7.91982L13.6218 4.34136C13.8014 4.13164 14.1171 4.10726 14.3269 4.28691Z"
                                        fill="#7A7A7A" />
                                </svg>
                              View Analytics </a> -->



                            <!-- <a *ngIf="!this.onboardingManager.profile.localization_enabled" (click)="createNewVideo()"
                              class="weight-normal btn btn-primary btn-round-6 md-btn mt-4 d-inline-block"><svg
                                  width="17" height="16" viewBox="0 0 17 16" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M8.68363 4.98126C7.46518 4.17 5.83312 5.04234 5.83312 6.50486V9.51855C5.83312 10.9811 7.46518 11.8534 8.68363 11.0421L10.9468 9.53531C12.0354 8.8105 12.0354 7.21291 10.9468 6.4881L8.68363 4.98126ZM6.83319 6.50486C6.83319 5.84008 7.57504 5.44356 8.12888 5.81232L10.392 7.31916C10.8868 7.64862 10.8868 8.3748 10.392 8.70425L8.12888 10.2111C7.57504 10.5799 6.83319 10.1833 6.83319 9.51855V6.50486Z"
                                      fill="white" />
                                  <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M8.49999 15.9904C12.9186 15.9904 16.5006 12.413 16.5006 7.99999C16.5006 3.58699 12.9186 0.009552 8.49999 0.009552C4.08137 0.009552 0.499359 3.58699 0.499359 7.99999C0.499359 12.413 4.08137 15.9904 8.49999 15.9904ZM8.49999 14.9916C12.3663 14.9916 15.5005 11.8614 15.5005 7.99999C15.5005 4.13862 12.3663 1.00836 8.49999 1.00836C4.63369 1.00836 1.49944 4.13862 1.49944 7.99999C1.49944 11.8614 4.63369 14.9916 8.49999 14.9916Z"
                                      fill="white" />
                              </svg>
                              Create a New Video</a> -->
                            <a href="javascript:void(0)" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                                aria-expanded="false" style="padding: 10px; min-width:215px"
                                class="weight-normal btn btn-primary btn-round-6 md-btn mt-4 d-inline-block dropdown">

                                <img class="me-2" src="../../../../assets/images/svg/create video.svg">
                                Create a New Video
                                <img src="../../../../assets/images/divider-solid-svgrepo-com.svg">
                                <img class="btn-svg-icon" src="../../../../assets/images/svg/down arrow.svg">
                            </a>
                            <ul class="dropdown-menu custom-dropdown" aria-labelledby="navbarDropdown"
                                style="      width: 226px;  transform: translate(1212px, 88px);">
                                <li><a class="dropdown-item" href="javascript:void(0)" (click)="createNewVideo()">Create
                                        a New Video</a></li>
                                <li *ngIf="this.onboardingManager.showSAPVideo"><a class="dropdown-item"
                                        href="javascript:void(0)" (click)="createNewModifiedVideo()">Customize a Video</a></li>
                                <li
                                    *ngIf="this.onboardingManager.profile.localization_enabled && this.onboardingManager.profile.plan == 'enterprise' ">
                                    <a class="dropdown-item" [routerLink]="['/localise-existing-videos']">Localize
                                        Existing Videos</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="accountSecInner h-100">
                <div class="accountSecContent video-dashboard">
                    <div class="row row-cols-md-2 g-0 justify-content-between">
                        <div class="loginSecItmForm pt-0" aria-labelledby="loginYourself">
                            <div class="video-header row m-0">
                                <app-dashboard-breadcrumb class="col-lg-3"></app-dashboard-breadcrumb>
                                <ul class="text-end d-block col-lg-9" [ngClass]="
                                search_term != ''
                                  ? 'paddingb-5'
                                  : ''
                                ">
                                    <li class="layout-btn mt-3">
                                        <button (click)="gridView()" class="grid button-gl active">
                                            <img class="svg-icon" src="../../../../assets/images/svg/grid view.svg">
                                        </button>
                                        <button (click)="listView()" class="list button-gl">
                                            <img class="svg-icon" src="../../../../assets/images/svg/list view.svg">
                                        </button>
                                    </li>
                                    <li id="selectpreff">
                                        <div class="formItm down_arrow pt-3">

                                            <div class="dropdown dropdownBtn dropdown-pronto">
                                                <button class="dropdown-toggle text-md" type="button"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span class="dropdownBtnText"><strong>Sort by </strong>
                                                        {{sSelectedSortingCategory}}</span>
                                                </button>
                                                <ul class="dropdown-menu dropdown-menu-end dropdownMenu">
                                                    <li *ngFor="let sort of lSortBy" class="custom-check"
                                                        (click)="DashboardMyvideoComponent_SortVideos(sort)">

                                                        <a *ngIf="!(searchAspectRatio != 'all' && sort.value == 'aspect_ratio') "
                                                            class="dropdown-item text-md"
                                                            href="javascript:void(0)">{{sort.name}}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="pe-3">

                                        <div class="formItm search-icon relative">
                                            <div>
                                                <input type="text" id="search-feild" value="" [(ngModel)]="title"
                                                    (keyup.enter)="SearchVideo()" placeholder="Email Address" required>
                                                <label class="text-sm" for="search-feild" style="cursor:default;">Search
                                                    your
                                                    videos</label>
                                                <img (click)="SearchVideo()"
                                                    src="../../../../assets/images/svg/search.svg">
                                            </div>

                                            <div class="searchtem" *ngIf="search_term != ''"
                                                (click)="clearSearchTerm()">
                                                <div>{{ search_term }}</div>
                                                <mat-icon class="link">close</mat-icon>
                                            </div>

                                        </div>

                                    </li>
                                </ul>
                            </div>
                            <div class="row ps-4 mb-1 m-0">
                                <ul class="breadcrumb-tabs nav caret-hide ratio-setting">

                                    <li role="presentation"
                                        *ngFor="let aspectRatio of this.pronto.videoAspectRatioTabs; let i = index "
                                        (click)="selectAspectRatio(aspectRatio.ratio_value)"
                                        class="nav-item ml-n2 px-0 aspect-item {{aspectRatio.class_name}}-tab">
                                        <a class="nav-link"
                                            [ngClass]="{'active' : (aspectRatio.ratio_value == searchAspectRatio) || (searchAspectRatio == '' && aspectRatio.ratio_value == 'all')}">
                                            <b *ngIf=" i > 0 " class="divider">__</b>
                                            <p class="mb-0 mt-1"> {{aspectRatio.title}} </p>
                                            <div class='box ratio-cel ps-2  {{aspectRatio.class_name}}'
                                                *ngIf="aspectRatio.ratio != 'all'">
                                                <span>{{aspectRatio.ratio}}</span>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <created-videos [aspectRatio]="searchAspectRatio" [numGridCol]="5"
                                [status]="'published,draft'" [orderBySelected]="orderBySearchText"
                                [sInputVideoStatus]="'draft'" [dashboardPublished]="true" [displayCountSection]="false"
                                [title]="'published'"
                                (bEmittedDisplayLoader)="DashboardMyvideoComponent_HideLoader($event)"></created-videos>
                        </div>
                    </div>
                </div>
                <div class="wtermarklogo">
                    <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
                </div>
            </div>
        </div>
    </div>
</section>
<div class="overlay"></div>