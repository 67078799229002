import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionplanManagerService {

  planDialogue:boolean = false;
  constructor() { }

  showPlanDialogue()
  {
    console.log("SHOW SUBSCRIPTIN PLAN FROM MANAGER ..");
  this.planDialogue = true;


  }

  closePlanDialogue()
  {
    this.planDialogue = false;
  }


}
