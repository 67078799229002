import { SafeUrl } from "@angular/platform-browser";

export class visual {
  isSelected?: boolean = false;
  ext?: string;
  key_terms?: string[] = [];
  media_lib?: string | any;
  name?: string;
  size?: number;
  thumb?: string;
  duration?: string;
  videos_used_in?: [];
  visual_path?: string;
  used_in?: string;
  checked?: boolean;
  fpath?: string;

  visual_type?: string;
  library?: string;
  assetType?: string; //pronoto| user
  url?: string;
  thumbnail?: string;
  loadingThumbnail?: boolean = true;
  loadingUrl?: boolean = true;
  created?: date;
  object_surl? : string;
  thumb_surl? : string;
  signed_url? : string;
  thumbnail_path ? : string;
  thumbnail_signed_url ? : string;
}
export class date {
  $date?: string;
}
export class mediaLib {
  name: string;
  lib_id?: string;
  _id?: any;
  library_access?: string;
  activation_status?: string;
  total_items?: number;
  owner_type?: string;
  owner?: string;
  assets? : number;
}

export class mediaFile {
  status: any;
  file: File;
  name: string;
  error: string;
  uri: SafeUrl;
  _id?: string;
  loadingThumbnail? : boolean | string
}

export class clip {
  clip_path: string;
  clip_url: string;
  type: string = "clip;";
  loading: boolean;
}
