<div class="sap-timeline-container br12 tab-content bg-white page-shadow pb-0 timline" style="overflow: hidden"
    id="pills-tabContent" appMousepositiontrack [duration]="this.duration">
    <div class="tab-pane fade show active">
        <div class="content_area pb-0 pt-4">
            <!-- titles -->
            <div class="timelineViewRow">

                <div class="timelineViewRowTitle edit-icons">
                    <button class="d-flex auto">

                        <figure><img class="mb-1" src="../../../../assets/images/svg/texticons.svg"></figure>
                        <span class="ms-2 text-lg text-gray-2">Titles</span>
                    </button>
                    <a href="javascript:void(0)" data-sidebar="#videoSettingMultipleVideosEditTitle" data-custom-select>
                    </a>
                </div>
                <div class="timelineViewRowLayer custom-video-timeline" style="position: relative">
                    <div style="position: relative; height: 50px; width: 100%; border-radius: 5px" appMousepositiontrack
                        (currentIndex)="changeIndex($event)" *ngIf="this.sap && this.sap?.texts"
                        [duration]="this.duration">
                        <app-timeline-item [height]="47" class="p-absolute" [duration]="this.duration"
                            *ngFor="let item of this.sap?.texts; index as i" [id]="i"
                            [selected]="selectedBlock_text == item" (removeItem)="removeText(item, i)"
                            (refreshTimeLine)="refresh()" [items]="this.sap?.texts" [width]="item.width"
                            [style.left.px]="item.left" [item]="item" [left]="item.left" [top]="0">
                            <div class="text">
                                {{ item.text[0] | changeTitleOfVideoText :'Title text' : 'Title' }}
                            </div>

                            <a href="javascript:void(0)" class="filter-btn title-filter-btn"
                                style="left: -17px; z-index: 11" data-sidebar="#videoSettingMultipleVideosEditTitle"
                                data-custom-select (click)="openTitle(item, i)">
                                <svg class="filter-icon" width="14" height="11" viewBox="0 0 14 11" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path class="icon" fill-rule="evenodd" clip-rule="evenodd"
                                        d="M4.16667 1.49996H5C5.46024 1.49996 5.83333 1.87306 5.83333 2.33329V2.7494V2.74998V2.75056V3.16663C5.83333 3.62686 5.46024 3.99996 5 3.99996H4.16667C3.70643 3.99996 3.33333 3.62686 3.33333 3.16663V2.75189L3.33334 2.74996L3.33333 2.74803V2.33329C3.33333 1.87306 3.70643 1.49996 4.16667 1.49996ZM6.66667 2.33329V2.33331H12.9167C13.1468 2.33331 13.3333 2.51986 13.3333 2.74998C13.3333 2.9801 13.1468 3.16665 12.9167 3.16665H6.66667C6.66666 4.08711 5.92047 4.83329 5 4.83329H4.16667C3.24619 4.83329 2.5 4.0871 2.5 3.16663H0.416672C0.186553 3.16663 5.26508e-06 2.98008 5.26508e-06 2.74996C4.47035e-06 2.51984 0.186553 2.33329 0.416671 2.33329H2.5C2.5 1.41282 3.24619 0.666626 4.16667 0.666626H5C5.92047 0.666626 6.66667 1.41282 6.66667 2.33329ZM10.8333 8.99996C10.8333 9.92043 10.0871 10.6666 9.16667 10.6666H8.33333C7.41287 10.6666 6.66668 9.92044 6.66667 8.99998H0.416667C0.186548 8.99998 0 8.81343 0 8.58331C0 8.35319 0.186548 8.16665 0.416667 8.16665H6.66667V8.16663C6.66667 7.24615 7.41286 6.49996 8.33333 6.49996H9.16667C10.0871 6.49996 10.8333 7.24615 10.8333 8.16663H12.9167C13.1468 8.16663 13.3333 8.35317 13.3333 8.58329C13.3333 8.81341 13.1468 8.99996 12.9167 8.99996H10.8333ZM7.5 8.58331V8.99996C7.5 9.4602 7.8731 9.83329 8.33333 9.83329H9.16667C9.6269 9.83329 10 9.4602 10 8.99996V8.58531C10 8.58463 10 8.58396 10 8.58329C10 8.58262 10 8.58195 10 8.58128V8.16663C10 7.70639 9.6269 7.33329 9.16667 7.33329H8.33333C7.8731 7.33329 7.5 7.70639 7.5 8.16663V8.58331Z"
                                        fill="#404243"></path>
                                </svg>
                            </a>
                        </app-timeline-item>
                    </div>

                    <button *ngIf="!this.textOverlaySection && this.enableOverlayAddText" type="button"
                        (click)="saveTextOverlay()" class="btn btn-sm btn-secondary btn-round-6 addbutton">
                        <img loading="lazy" src="../../../../assets/images/plus-sign-svgrepo-com.svg"
                            style="filter: invert(1)" height="10px" />
                    </button>
                    <div class="overlay-section" *ngIf="this.textOverlaySection"
                        style="position: absolute; height: 90px; width: 100%" appMousepositiontrack
                        (currentIndex)="changeIndex($event)" [duration]="this.duration">
                        <app-timeline-item [height]="50" class="p-ab"
                            style="top: 20%; background-color: wheat; padding-right: 3px" [duration]="this.duration"
                            [width]="this.newTextOverlay.width" [items]="this.sap?.texts"
                            (refreshTimeLine)="refreshTexts()" [style.left.px]="this.newTextOverlay.left"
                            [item]="this.newTextOverlay" [left]="this.newTextOverlay.left" [top]="0">
                            <div class="text">{{ this.newTextOverlay.text[0] }}</div>

                            <a href="javascript:void(0)" class="filter-btn title-filter-btn"
                                style="left: -17px; z-index: 11" data-sidebar="#videoSettingMultipleVideosEditTitle"
                                data-custom-select (click)="openTitle(this.newTextOverlay, 0)">
                                <svg class="filter-icon" width="14" height="11" viewBox="0 0 14 11" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path class="icon" fill-rule="evenodd" clip-rule="evenodd"
                                        d="M4.16667 1.49996H5C5.46024 1.49996 5.83333 1.87306 5.83333 2.33329V2.7494V2.74998V2.75056V3.16663C5.83333 3.62686 5.46024 3.99996 5 3.99996H4.16667C3.70643 3.99996 3.33333 3.62686 3.33333 3.16663V2.75189L3.33334 2.74996L3.33333 2.74803V2.33329C3.33333 1.87306 3.70643 1.49996 4.16667 1.49996ZM6.66667 2.33329V2.33331H12.9167C13.1468 2.33331 13.3333 2.51986 13.3333 2.74998C13.3333 2.9801 13.1468 3.16665 12.9167 3.16665H6.66667C6.66666 4.08711 5.92047 4.83329 5 4.83329H4.16667C3.24619 4.83329 2.5 4.0871 2.5 3.16663H0.416672C0.186553 3.16663 5.26508e-06 2.98008 5.26508e-06 2.74996C4.47035e-06 2.51984 0.186553 2.33329 0.416671 2.33329H2.5C2.5 1.41282 3.24619 0.666626 4.16667 0.666626H5C5.92047 0.666626 6.66667 1.41282 6.66667 2.33329ZM10.8333 8.99996C10.8333 9.92043 10.0871 10.6666 9.16667 10.6666H8.33333C7.41287 10.6666 6.66668 9.92044 6.66667 8.99998H0.416667C0.186548 8.99998 0 8.81343 0 8.58331C0 8.35319 0.186548 8.16665 0.416667 8.16665H6.66667V8.16663C6.66667 7.24615 7.41286 6.49996 8.33333 6.49996H9.16667C10.0871 6.49996 10.8333 7.24615 10.8333 8.16663H12.9167C13.1468 8.16663 13.3333 8.35317 13.3333 8.58329C13.3333 8.81341 13.1468 8.99996 12.9167 8.99996H10.8333ZM7.5 8.58331V8.99996C7.5 9.4602 7.8731 9.83329 8.33333 9.83329H9.16667C9.6269 9.83329 10 9.4602 10 8.99996V8.58531C10 8.58463 10 8.58396 10 8.58329C10 8.58262 10 8.58195 10 8.58128V8.16663C10 7.70639 9.6269 7.33329 9.16667 7.33329H8.33333C7.8731 7.33329 7.5 7.70639 7.5 8.16663V8.58331Z"
                                        fill="#404243"></path>
                                </svg>
                            </a>
                        </app-timeline-item>
                    </div>
                </div>
            </div>

            <!-- video Overlay-->
            <div class="timelineViewRow">
                <div class="timelineViewRowTitle edit-icons">
                    <button (click)="openVideo()" class="d-flex auto">
                        <!-- <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M2.73019 15.717L2.73015 15.717C1.91575 15.2469 1.41016 14.3724 1.41016 13.42V6.58003C1.41016 5.63982 1.914 4.76335 2.73193 4.28199L8.67016 0.853019C8.67017 0.853011 8.67018 0.853004 8.6702 0.852996C9.48442 0.382949 10.4943 0.381514 11.3212 0.853616C11.3215 0.853784 11.3218 0.853953 11.3221 0.854122L17.2568 4.28107C17.2573 4.28136 17.2578 4.28165 17.2583 4.28195C18.077 4.76374 18.5801 5.63067 18.5801 6.58003V13.42C18.5801 14.3602 18.0763 15.2366 17.2585 15.718C17.2579 15.7183 17.2574 15.7186 17.2568 15.719L11.3202 19.147C11.3202 19.147 11.3201 19.147 11.3201 19.147C10.5059 19.6171 9.49612 19.6185 8.66924 19.1465C8.66891 19.1463 8.66857 19.1461 8.66824 19.1459L2.73019 15.717Z"
                                fill="#F2F2F5" stroke="#F2F2F5" />
                            <path
                                d="M7.75 9.9999V8.79989C7.75 7.25989 8.84001 6.62993 10.17 7.39993L11.21 7.9999L12.25 8.59988C13.58 9.36988 13.58 10.6299 12.25 11.3999L11.21 11.9999L10.17 12.5999C8.84001 13.3699 7.75 12.7399 7.75 11.1999V9.9999Z"
                                fill="#A7ABB6" />
                        </svg> -->
                        <figure><img class="mb-1" src="../../../../assets/images/svg/video icon.svg"></figure>
                        <span class="ms-2 text-lg text-gray-2">Video Overlay</span>
                    </button>
                    <button (click)="openVideo()" *ngIf="false">
                        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M5 1H6C6.55228 1 7 1.44772 7 2V2.49933L7 2.50002L7 2.50072V3C7 3.55228 6.55228 4 6 4H5C4.44772 4 4 3.55228 4 3V2.50231L4.00001 2.5L4 2.49769V2C4 1.44772 4.44772 1 5 1ZM8 2V2.00002H15.5C15.7761 2.00002 16 2.22388 16 2.50002C16 2.77617 15.7761 3.00002 15.5 3.00002H8C7.99999 4.10458 7.10456 5 6 5H5C3.89543 5 3 4.10457 3 3H0.500006C0.223863 3 6.31809e-06 2.77614 6.31809e-06 2.5C5.36442e-06 2.22386 0.223863 2 0.500005 2H3C3 0.895431 3.89543 0 5 0H6C7.10457 0 8 0.89543 8 2ZM13 10C13 11.1046 12.1046 12 11 12H10C8.89544 12 8.00001 11.1046 8 10H0.5C0.223858 10 0 9.77617 0 9.50002C0 9.22388 0.223858 9.00002 0.5 9.00002H8V9C8 7.89543 8.89543 7 10 7H11C12.1046 7 13 7.89543 13 9H15.5C15.7761 9 16 9.22386 16 9.5C16 9.77614 15.7761 10 15.5 10H13ZM9 9.50002V10C9 10.5523 9.44772 11 10 11H11C11.5523 11 12 10.5523 12 10V9.50242C12 9.50161 12 9.50081 12 9.5C12 9.49919 12 9.49839 12 9.49758V9C12 8.44771 11.5523 8 11 8H10C9.44771 8 9 8.44772 9 9V9.50002Z"
                                fill="#7A7A7A" />
                        </svg>
                    </button>
                </div>

                <div class="timelineViewRowLayer custom-video-timeline" style="position: relative">
                    <div style="position: relative; height: 120px; width: 100%;" appMousepositiontrack
                        (currentIndex)="changeIndex($event)" [duration]="this.duration">
                        <app-timeline-item [height]="120" class="p-absolute" [selected]="oSelectedBlock == item"
                            [duration]="this.duration" *ngFor="let item of this.sap?.clips; index as i"
                            [enableResizing]="true" [id]="i" [width]="item.width" [style.left.px]="item.left"
                            (removeItem)="removeClip(item, i)" [item]="item" [items]="this.sap?.clips"
                            [left]="item.left" [top]="0">
                            <img loading="lazy" *ngIf="item.video_thumbnail" [src]="item.video_thumbnail" [height]="120"
                                [width]="item.width" class="unselectable" />

                            <a href="javascript:void(0)" class="filter-btn title-filter-btn"
                                style="right: 8px; top: 5%; z-index: 11"
                                data-sidebar="#videoSettingMultipleVideosEditTitle" data-custom-select
                                (click)="openReplacementClip(item, i)" nz-popover [nzPopoverContent]="contentTemplate"
                                nzPopoverPlacement="bottom">
                                <svg class="filter-icon" width="14" height="11" viewBox="0 0 14 11" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path class="icon" fill-rule="evenodd" clip-rule="evenodd"
                                        d="M4.16667 1.49996H5C5.46024 1.49996 5.83333 1.87306 5.83333 2.33329V2.7494V2.74998V2.75056V3.16663C5.83333 3.62686 5.46024 3.99996 5 3.99996H4.16667C3.70643 3.99996 3.33333 3.62686 3.33333 3.16663V2.75189L3.33334 2.74996L3.33333 2.74803V2.33329C3.33333 1.87306 3.70643 1.49996 4.16667 1.49996ZM6.66667 2.33329V2.33331H12.9167C13.1468 2.33331 13.3333 2.51986 13.3333 2.74998C13.3333 2.9801 13.1468 3.16665 12.9167 3.16665H6.66667C6.66666 4.08711 5.92047 4.83329 5 4.83329H4.16667C3.24619 4.83329 2.5 4.0871 2.5 3.16663H0.416672C0.186553 3.16663 5.26508e-06 2.98008 5.26508e-06 2.74996C4.47035e-06 2.51984 0.186553 2.33329 0.416671 2.33329H2.5C2.5 1.41282 3.24619 0.666626 4.16667 0.666626H5C5.92047 0.666626 6.66667 1.41282 6.66667 2.33329ZM10.8333 8.99996C10.8333 9.92043 10.0871 10.6666 9.16667 10.6666H8.33333C7.41287 10.6666 6.66668 9.92044 6.66667 8.99998H0.416667C0.186548 8.99998 0 8.81343 0 8.58331C0 8.35319 0.186548 8.16665 0.416667 8.16665H6.66667V8.16663C6.66667 7.24615 7.41286 6.49996 8.33333 6.49996H9.16667C10.0871 6.49996 10.8333 7.24615 10.8333 8.16663H12.9167C13.1468 8.16663 13.3333 8.35317 13.3333 8.58329C13.3333 8.81341 13.1468 8.99996 12.9167 8.99996H10.8333ZM7.5 8.58331V8.99996C7.5 9.4602 7.8731 9.83329 8.33333 9.83329H9.16667C9.6269 9.83329 10 9.4602 10 8.99996V8.58531C10 8.58463 10 8.58396 10 8.58329C10 8.58262 10 8.58195 10 8.58128V8.16663C10 7.70639 9.6269 7.33329 9.16667 7.33329H8.33333C7.8731 7.33329 7.5 7.70639 7.5 8.16663V8.58331Z"
                                        fill="#404243"></path>
                                </svg>
                            </a>

                            <ng-template #contentTemplate>
                                <img loading="lazy" *ngIf="item.video_thumbnail" [src]="item.video_thumbnail"
                                    height="100" width="100" class="unselectable" />
                            </ng-template>
                        </app-timeline-item>
                    </div>
                    <button *ngIf="!this.videoOverlaySection && this.enableOverlayAddClip" type="button"
                        (click)="saveVideoOverlay()" class="btn btn-sm btn-secondary btn-round-6 addbutton">
                        <img src="../../../../assets/images/plus-sign-svgrepo-com.svg" loading="lazy"
                            style="filter: invert(1)" height="10px" />
                    </button>

                    <div class="overlay-section" *ngIf="this.videoOverlaySection"
                        style="position: absolute; height: 130px; width: 100%" appMousepositiontrack
                        (currentIndex)="changeIndex($event)" [duration]="this.duration">
                        <app-timeline-item [height]="120" class="p-ab" [enableResizing]="true"
                            style="top: 5%; background-color: wheat" [duration]="this.duration"
                            [width]="this.newOverlayClip.width" [style.left.px]="this.newOverlayClip.left"
                            [item]="this.newOverlayClip" [items]="this.sap?.clips" [top]="0">
                            <img style="pointer-events: none"
                                *ngIf="this.newOverlayClip?.video_thumbnail && this.newOverlayClip?.video_thumbnail != ''"
                                [src]="this.newOverlayClip.video_thumbnail" [height]="120"
                                [width]="this.newOverlayClip.width" class="unselectable" />

                            <a href="javascript:void(0)" class="filter-btn title-filter-btn"
                                style="right: 8px; top: 5%; z-index: 11"
                                data-sidebar="#videoSettingMultipleVideosEditTitle" data-custom-select
                                (click)="openReplacementClip(this.newOverlayClip, 0)">
                                <svg class="filter-icon" width="14" height="11" viewBox="0 0 14 11" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path class="icon" fill-rule="evenodd" clip-rule="evenodd"
                                        d="M4.16667 1.49996H5C5.46024 1.49996 5.83333 1.87306 5.83333 2.33329V2.7494V2.74998V2.75056V3.16663C5.83333 3.62686 5.46024 3.99996 5 3.99996H4.16667C3.70643 3.99996 3.33333 3.62686 3.33333 3.16663V2.75189L3.33334 2.74996L3.33333 2.74803V2.33329C3.33333 1.87306 3.70643 1.49996 4.16667 1.49996ZM6.66667 2.33329V2.33331H12.9167C13.1468 2.33331 13.3333 2.51986 13.3333 2.74998C13.3333 2.9801 13.1468 3.16665 12.9167 3.16665H6.66667C6.66666 4.08711 5.92047 4.83329 5 4.83329H4.16667C3.24619 4.83329 2.5 4.0871 2.5 3.16663H0.416672C0.186553 3.16663 5.26508e-06 2.98008 5.26508e-06 2.74996C4.47035e-06 2.51984 0.186553 2.33329 0.416671 2.33329H2.5C2.5 1.41282 3.24619 0.666626 4.16667 0.666626H5C5.92047 0.666626 6.66667 1.41282 6.66667 2.33329ZM10.8333 8.99996C10.8333 9.92043 10.0871 10.6666 9.16667 10.6666H8.33333C7.41287 10.6666 6.66668 9.92044 6.66667 8.99998H0.416667C0.186548 8.99998 0 8.81343 0 8.58331C0 8.35319 0.186548 8.16665 0.416667 8.16665H6.66667V8.16663C6.66667 7.24615 7.41286 6.49996 8.33333 6.49996H9.16667C10.0871 6.49996 10.8333 7.24615 10.8333 8.16663H12.9167C13.1468 8.16663 13.3333 8.35317 13.3333 8.58329C13.3333 8.81341 13.1468 8.99996 12.9167 8.99996H10.8333ZM7.5 8.58331V8.99996C7.5 9.4602 7.8731 9.83329 8.33333 9.83329H9.16667C9.6269 9.83329 10 9.4602 10 8.99996V8.58531C10 8.58463 10 8.58396 10 8.58329C10 8.58262 10 8.58195 10 8.58128V8.16663C10 7.70639 9.6269 7.33329 9.16667 7.33329H8.33333C7.8731 7.33329 7.5 7.70639 7.5 8.16663V8.58331Z"
                                        fill="#404243"></path>
                                </svg>
                            </a>
                        </app-timeline-item>
                    </div>
                </div>
            </div>

            <!-- video -->
            <div class="timelineViewRow">
                <div class="timelineViewRowTitle edit-icons pointer" (click)="openVideoSetting()"
                    matTooltip="Edit Video Setting" matTooltipClass="custom-tooltip" matTooltipPosition="right">
                    <button class="d-flex">
                        <!-- <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M2.73019 15.717L2.73015 15.717C1.91575 15.2469 1.41016 14.3724 1.41016 13.42V6.58003C1.41016 5.63982 1.914 4.76335 2.73193 4.28199L8.67016 0.853019C8.67017 0.853011 8.67018 0.853004 8.6702 0.852996C9.48442 0.382949 10.4943 0.381514 11.3212 0.853616C11.3215 0.853784 11.3218 0.853953 11.3221 0.854122L17.2568 4.28107C17.2573 4.28136 17.2578 4.28165 17.2583 4.28195C18.077 4.76374 18.5801 5.63067 18.5801 6.58003V13.42C18.5801 14.3602 18.0763 15.2366 17.2585 15.718C17.2579 15.7183 17.2574 15.7186 17.2568 15.719L11.3202 19.147C11.3202 19.147 11.3201 19.147 11.3201 19.147C10.5059 19.6171 9.49612 19.6185 8.66924 19.1465C8.66891 19.1463 8.66857 19.1461 8.66824 19.1459L2.73019 15.717Z"
                                fill="#F2F2F5" stroke="#F2F2F5" />
                            <path
                                d="M7.75 9.9999V8.79989C7.75 7.25989 8.84001 6.62993 10.17 7.39993L11.21 7.9999L12.25 8.59988C13.58 9.36988 13.58 10.6299 12.25 11.3999L11.21 11.9999L10.17 12.5999C8.84001 13.3699 7.75 12.7399 7.75 11.1999V9.9999Z"
                                fill="#A7ABB6" />
                        </svg> -->
                        <figure><img class="mb-1" src="../../../../assets/images/svg/video icon.svg"></figure>
                        <span class="ms-2 text-lg text-gray-2">Video</span>
                    </button>
                    <button *ngIf="true" class="filter-btn video-filter-btn inherit mt-0">
                        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M5 1H6C6.55228 1 7 1.44772 7 2V2.49933L7 2.50002L7 2.50072V3C7 3.55228 6.55228 4 6 4H5C4.44772 4 4 3.55228 4 3V2.50231L4.00001 2.5L4 2.49769V2C4 1.44772 4.44772 1 5 1ZM8 2V2.00002H15.5C15.7761 2.00002 16 2.22388 16 2.50002C16 2.77617 15.7761 3.00002 15.5 3.00002H8C7.99999 4.10458 7.10456 5 6 5H5C3.89543 5 3 4.10457 3 3H0.500006C0.223863 3 6.31809e-06 2.77614 6.31809e-06 2.5C5.36442e-06 2.22386 0.223863 2 0.500005 2H3C3 0.895431 3.89543 0 5 0H6C7.10457 0 8 0.89543 8 2ZM13 10C13 11.1046 12.1046 12 11 12H10C8.89544 12 8.00001 11.1046 8 10H0.5C0.223858 10 0 9.77617 0 9.50002C0 9.22388 0.223858 9.00002 0.5 9.00002H8V9C8 7.89543 8.89543 7 10 7H11C12.1046 7 13 7.89543 13 9H15.5C15.7761 9 16 9.22386 16 9.5C16 9.77614 15.7761 10 15.5 10H13ZM9 9.50002V10C9 10.5523 9.44772 11 10 11H11C11.5523 11 12 10.5523 12 10V9.50242C12 9.50161 12 9.50081 12 9.5C12 9.49919 12 9.49839 12 9.49758V9C12 8.44771 11.5523 8 11 8H10C9.44771 8 9 8.44772 9 9V9.50002Z"
                                fill="#7A7A7A" />
                        </svg>
                    </button>
                </div>

                <div class="timelineViewRowLayer custom-video-timeline" style="display: flex; padding-right: 87px"
                    appMousepositiontrack (currentIndex)="changeIndex($event)" [duration]="this.duration">
                    <div *ngFor="let nBreakpoint of numbers; index as i" class="example-list"
                        style="width: 100%; height: 120px; position: relative;" [style.width.px]="widthVidThumb">
                        <!-- <img [src]="this.sap.clips[0].video_thumbnail" class="unselectable"> -->

                        <video poster="../assets/images/icons/video-spinner-sm.svg" id="{{ 'videoPreview' + i }}"
                            [style.width.px]="widthVidThumb" [src]="fetchUrl(i)" preload class="unselectable"></video>
                        <a *ngIf="i == numbers.length-1" href="javascript:void(0)" class="filter-btn title-filter-btn"
                            style="right: 8px; top: 5%; z-index: 11" data-sidebar="#videoSettingMultipleVideosEditTitle"
                            data-custom-select (click)="isItLastSapClip(i, numbers.length)">
                            <svg class="filter-icon" width="14" height="11" viewBox="0 0 14 11" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path class="icon" fill-rule="evenodd" clip-rule="evenodd"
                                    d="M4.16667 1.49996H5C5.46024 1.49996 5.83333 1.87306 5.83333 2.33329V2.7494V2.74998V2.75056V3.16663C5.83333 3.62686 5.46024 3.99996 5 3.99996H4.16667C3.70643 3.99996 3.33333 3.62686 3.33333 3.16663V2.75189L3.33334 2.74996L3.33333 2.74803V2.33329C3.33333 1.87306 3.70643 1.49996 4.16667 1.49996ZM6.66667 2.33329V2.33331H12.9167C13.1468 2.33331 13.3333 2.51986 13.3333 2.74998C13.3333 2.9801 13.1468 3.16665 12.9167 3.16665H6.66667C6.66666 4.08711 5.92047 4.83329 5 4.83329H4.16667C3.24619 4.83329 2.5 4.0871 2.5 3.16663H0.416672C0.186553 3.16663 5.26508e-06 2.98008 5.26508e-06 2.74996C4.47035e-06 2.51984 0.186553 2.33329 0.416671 2.33329H2.5C2.5 1.41282 3.24619 0.666626 4.16667 0.666626H5C5.92047 0.666626 6.66667 1.41282 6.66667 2.33329ZM10.8333 8.99996C10.8333 9.92043 10.0871 10.6666 9.16667 10.6666H8.33333C7.41287 10.6666 6.66668 9.92044 6.66667 8.99998H0.416667C0.186548 8.99998 0 8.81343 0 8.58331C0 8.35319 0.186548 8.16665 0.416667 8.16665H6.66667V8.16663C6.66667 7.24615 7.41286 6.49996 8.33333 6.49996H9.16667C10.0871 6.49996 10.8333 7.24615 10.8333 8.16663H12.9167C13.1468 8.16663 13.3333 8.35317 13.3333 8.58329C13.3333 8.81341 13.1468 8.99996 12.9167 8.99996H10.8333ZM7.5 8.58331V8.99996C7.5 9.4602 7.8731 9.83329 8.33333 9.83329H9.16667C9.6269 9.83329 10 9.4602 10 8.99996V8.58531C10 8.58463 10 8.58396 10 8.58329C10 8.58262 10 8.58195 10 8.58128V8.16663C10 7.70639 9.6269 7.33329 9.16667 7.33329H8.33333C7.8731 7.33329 7.5 7.70639 7.5 8.16663V8.58331Z"
                                    fill="#404243"></path>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>

            <!--gap layer -->

            <div class="timelineViewRow">
                <div class="timelineViewRowTitle"></div>
                <div class="timelineViewRowLayer custom-video-timeline" appMousepositiontrack
                    (currentIndex)="changeIndex($event)" [duration]="this.duration"></div>
            </div>



            <!-- scale -->
            <!-- <div class="timelineViewRow timeScaleSec"> -->
            <div class="bottom-ruler"
                style="width: calc(100vw - 135px);height: 120px;position: absolute;bottom: 0px;left: 41px;">
                <div class="timelineViewRow">
                    <div class="timelineViewRowTitle" style="border-right: none; width: 225px !important"></div>
                    <div id="width1" #width1 style="padding-top: 70px; max-width: calc( 100vw - 140px)"
                        class="timelineViewRowLayer custom-video-timeline" appMousepositiontrack
                        (currentIndex)="changeIndex($event)" [duration]="this.duration">
                        <div style="position: absolute" *ngIf="currentIndex >= 0 && currentIndex <= this.width + 10"
                            [style.margin-left.px]="currentIndex - 1">
                            <div class="triangle-up"></div>
                            <div class="timetitle-top">
                                <div style="color: white; font-size: small; margin-left: 0px">
                                    <div class="time-sec">{{ selectedTime | number : '1.0-0' }} s</div>
                                </div>
                            </div>
                            <div class="time-line"></div>

                            <div class="timetitle">
                                <div style="color: white;font-size: small;margin-left: 0px;padding-top: 2px;">
                                    <div class="time-sec">{{ selectedTime | number : '1.0-0' }} s</div>
                                </div>
                            </div>

                            <div class="triangle-down"></div>

                        </div>
                        <div style="position: absolute; margin-bottom: 1px"
                            *ngFor="let nBreakpoint of lScaleBreakPoints" [style.margin-left.px]="nBreakpoint.position">
                            <div style="display: flex" *ngIf="nBreakpoint.type != ''">
                                <div style="border: 1px solid lightgray; height: 25px; width: 1px"></div>
                                <sup style="margin-top: 2px">{{ nBreakpoint.type }}</sup>
                            </div>
                            <div *ngIf="nBreakpoint.type == ''"
                                style="border: 1px solid lightgray; height: 15px; margin-bottom: -10px"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-modify-video-timeline-view-sidepanels></app-modify-video-timeline-view-sidepanels>

<!-- -----------------------Video SidePanel Start----------------------- -->
<nav class="rightSideBar right-0 sidebar-block" style="overflow-y: auto !important"
    [ngClass]="session.visibleVideo == true ? 'timeline-sidebar' : ''">
    <app-modify-video-setting-sidepanel (onClose)="closeVideo()"
        [video]="this.video"></app-modify-video-setting-sidepanel>
</nav>

<!-- -----------------------titles SidePanel Start----------------------- -->
<nav class="rightSideBar right-0 sidebar-block" *ngIf="session.visibleTitle == true" style="overflow-y: auto !important"
    [ngClass]="session.visibleTitle == true ? 'timeline-sidebar' : ''">
    <app-modify-video-text-sidepanel [selectedBlock]="this.selectedBlock_text"
        [selectedBlockIndex]="this.selectedBlock_text_index" (oEmittedBlockToSave)="ReplaceText($event)"
        (oEmittedBlockToRevert)="RevertText()"
        (oEmittedRemoveHighlightBlock)="VideoTimelineComponent_RemoveHighlightBlock($event)" (onClose)="closeTitle()">
    </app-modify-video-text-sidepanel>
</nav>
<!-- -----------------------titles SidePanel End----------------------- -->


<!-- -----------------------tagline SidePanel Start----------------------- -->
<nav class="rightSideBar right-0 sidebar-block" style="overflow-y: auto !important"
    [ngClass]="session.visibleTagline == true ? 'timeline-sidebar' : ''">
    <app-sap-tagline-sidepanel (onCloseTagline)="closeTagLineSidepanel()">
    </app-sap-tagline-sidepanel>
</nav>
<!-- -----------------------tagline SidePanel End----------------------- -->

<!-- -----------------------VoiceOver SidePanel Start----------------------- -->

<!-- -----------------------VoiceOver SidePanel End----------------------- -->

<!-- -----------------------Audio SidePanel Start----------------------- -->

<!-- -----------------------Audio SidePanel End----------------------- -->

<!-- Replacement clip starts -->
<nav class="rightSideBar right-0 sidebar-block" style="width: 485px !important; overflow-y: auto"
    [ngClass]="session.replacementClip == true ? 'timeline-sidebar' : ''">
    >
    <app-modify-video-replacement-clip-sidepanel *ngIf="this.session.replacementClip"
        [oInputSelectedBlock]="oSelectedBlock" (oEmittedBlockToSave)="VideoTimelineComponent_EmitedBlockToSave($event)"
        (oEmittedBlockToRevert)="VideoTimelineComponent_EmitedBlockToRevert($event)"
        (oEmittedRemoveHighlightBlock)="VideoTimelineComponent_RemoveHighlightBlock($event)"
        (onClose)="closeReplacementClip()">
    </app-modify-video-replacement-clip-sidepanel>
</nav>
<!-- Replacement clip end -->