<section class="loginSec">
    <app-onboarding-aside-section class="loginSecItm loginSecLeft"></app-onboarding-aside-section>
    <div class="loginSecItm loginSecRight">
        <div class="loginSecItmTop loginSecItmTop-right">
            <div class="text-end text-xs text-gray-3">
                Not a creator yet? <a routerLink="/createAccount"
                    class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 mr-2">Create an account</a>
                <button routerLink="/ft/login" class="btn btn-md btn-secondary btn-round-6 py-2">Try Now</button>
            </div>
        </div>
        <div class="loginSecItmContent">
            <div class="d-lg-none pb-5">
                <a href="/login">
                    <img src="../assets/images/logo-mobile.svg" alt="logo-mobile" width="120" />
                </a>
            </div>
            <h1 class="text-gray-1">Sign In</h1>
            <div class="loginSecItmForm" aria-labelledby="loginYourself">
                <form name="registration" [formGroup]="fLoginForm">
                    <div class="row mb-4">
                        <div class="col-md-6">
                            <div class="formItm formItm-input">

                                <input id="email" type="email" placeholder="Email Address" formControlName="email"
                                    required="">
                                <label for="email" class="text-sm">Email Address</label>
                                <span
                                    *ngIf="fLoginForm.controls['email'].hasError('required') && fLoginForm.controls['email'].touched"
                                    class="error mt-3">Please enter your email address.</span>
                                <span
                                    *ngIf="fLoginForm.controls['email'].hasError('pattern') && fLoginForm.controls['email'].touched"
                                    class="error">Please enter a valid email address.</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="formItm formItmPasswordView mb-4">
                                <button type="button">
                                    <svg *ngIf="!bDisplayPassword"
                                        (click)="this.bDisplayPassword = !this.bDisplayPassword" class="showpass"
                                        width="16" height="16" viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M1.09878 8.77804C0.967072 8.61999 0.967072 8.39041 1.09878 8.23235C4.69029 3.92255 11.3097 3.92255 14.9012 8.23235C15.0329 8.39041 15.0329 8.61999 14.9012 8.77804C11.3097 13.0879 4.69029 13.0879 1.09878 8.77804ZM0.330563 7.59217C4.32187 2.80261 11.6781 2.80261 15.6694 7.59217C16.1102 8.12107 16.1102 8.88933 15.6694 9.41823C11.6781 14.2078 4.32187 14.2078 0.330562 9.41823C-0.110188 8.88933 -0.110187 8.12107 0.330563 7.59217ZM9.52909 8.5052C9.52909 9.34969 8.8445 10.0343 8 10.0343C7.15551 10.0343 6.47092 9.34969 6.47092 8.5052C6.47092 7.6607 7.15551 6.97611 8 6.97611C8.8445 6.97611 9.52909 7.6607 9.52909 8.5052ZM10.5291 8.5052C10.5291 9.90197 9.39678 11.0343 8 11.0343C6.60323 11.0343 5.47092 9.90197 5.47092 8.5052C5.47092 7.10842 6.60323 5.97611 8 5.97611C9.39678 5.97611 10.5291 7.10842 10.5291 8.5052Z"
                                            fill="#BABCBE" />
                                    </svg>

                                    <svg *ngIf="bDisplayPassword"
                                        (click)="this.bDisplayPassword = !this.bDisplayPassword" width="16" height="16"
                                        viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M1.09878 8.77804C0.967072 8.61999 0.967072 8.39041 1.09878 8.23235C4.69029 3.92255 11.3097 3.92255 14.9012 8.23235C15.0329 8.39041 15.0329 8.61999 14.9012 8.77804C11.3097 13.0879 4.69029 13.0879 1.09878 8.77804ZM0.330563 7.59217C4.32187 2.80261 11.6781 2.80261 15.6694 7.59217C16.1102 8.12107 16.1102 8.88933 15.6694 9.41823C11.6781 14.2078 4.32187 14.2078 0.330562 9.41823C-0.110188 8.88933 -0.110187 8.12107 0.330563 7.59217ZM9.52909 8.5052C9.52909 9.34969 8.8445 10.0343 8 10.0343C7.15551 10.0343 6.47092 9.34969 6.47092 8.5052C6.47092 7.6607 7.15551 6.97611 8 6.97611C8.8445 6.97611 9.52909 7.6607 9.52909 8.5052ZM10.5291 8.5052C10.5291 9.90197 9.39678 11.0343 8 11.0343C6.60323 11.0343 5.47092 9.90197 5.47092 8.5052C5.47092 7.10842 6.60323 5.97611 8 5.97611C9.39678 5.97611 10.5291 7.10842 10.5291 8.5052Z"
                                            fill="#BABCBE" />
                                        <line x1="13.3536" y1="2.35355" x2="2.35355" y2="13.3536" stroke="#BABCBE" />
                                    </svg>

                                </button>

                                <input id="inputPassword" [type]="bDisplayPassword?'text':'password'" value=""
                                    required="" placeholder="Password" formControlName="password">
                                <label for="inputPassword" class="text-sm">Password</label>
                                <span *ngIf="fLoginForm.controls['password'].hasError('required')" class="error">Please
                                    enter your password.</span>

                                <!-- <span *ngIf="fLoginForm.controls['password'].hasError('pattern') && fLoginForm.controls['password'].dirty" class="error" style="    bottom: -38px;
                                    ">Passwords must be at least 8 characters long and contain at least one each of uppercase and lowercase alphabets, one numeral and one special character</span> -->

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">


                            <div class="row g-0">
                                <div class="col">
                                    <div class="my-3">
                                        <button class="btn btn-secondary btn-round-6"
                                            (click)="LoginComponent_SendLoginRequest()">Sign In</button>
                                        <a class="text-gray-2 text-xs ms-5" routerLink="/forgot-password">Trouble
                                            Signing In?</a>
                                    </div>
                                </div>
                                <div class="d-none alert alert-danger d-flex align-items-center alert-dismissible fade show"
                                    role="alert">
                                    <svg width="28" height="29" viewBox="0 0 28 29" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                            <path
                                                d="M13.9993 3.47998C6.92399 3.47998 1.16602 9.20176 1.16602 16.2326C1.16602 23.2635 6.92399 29 13.9993 29C21.0747 29 26.8327 23.2782 26.8327 16.2473C26.8327 9.21646 21.0747 3.47998 13.9993 3.47998ZM15.1095 15.7619C15.1095 16.365 14.6062 16.8651 13.9993 16.8651C13.3925 16.8651 12.8892 16.365 12.8892 15.7619V8.40747C12.8892 7.80441 13.3925 7.3043 13.9993 7.3043C14.6062 7.3043 15.1095 7.80441 15.1095 8.40747V15.7619Z"
                                                fill="#FF8369" />
                                            <path
                                                d="M17.7366 2.32H10.2647C9.74765 2.32 9.33398 1.808 9.33398 1.168C9.33398 0.528 9.74765 0 10.2647 0H17.7366C18.2537 0 18.6673 0.512 18.6673 1.152C18.6673 1.792 18.2537 2.32 17.7366 2.32Z"
                                                fill="#FF8369" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="28" height="29" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <div>
                                        Your video is being processed. We'll alert you once the process is complete.
                                        <button type="button" class="btn-close" data-bs-dismiss="alert"
                                            aria-label="Close"><svg width="16" height="16" viewBox="0 0 16 16"
                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M0.546609 15.7333C0.406624 15.7333 0.273304 15.68 0.166649 15.58C-0.0399958 15.3733 -0.0399958 15.0333 0.166649 14.8267L7.11925 7.86667L0.153317 0.906667C0.0533277 0.806667 0 0.673333 0 0.533333C0 0.393333 0.0533277 0.26 0.153317 0.16C0.259972 0.0533333 0.393292 0 0.533277 0C0.673262 0 0.806581 0.0533333 0.913237 0.153333L7.8725 7.11333L14.8251 0.153333C14.9251 0.0533333 15.0584 0 15.1984 0C15.3384 0 15.4717 0.0533333 15.5784 0.153333C15.785 0.36 15.785 0.7 15.5784 0.906667L8.62575 7.86667L15.5717 14.8133C15.6717 14.9133 15.725 15.0467 15.725 15.1867C15.725 15.3267 15.6717 15.46 15.5717 15.5667C15.4717 15.6733 15.3384 15.72 15.1917 15.72C15.0451 15.72 14.9184 15.6667 14.8118 15.5667L7.8725 8.62L0.919903 15.58C0.819913 15.68 0.686594 15.7333 0.546609 15.7333Z"
                                                    fill="#FF8369" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div class="d-none alert alert-success d-flex align-items-center alert-dismissible fade show"
                                    role="alert">
                                    <svg width="28" height="29" viewBox="0 0 28 29" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14 0C6.26801 0 0 6.26801 0 14V15C0 22.732 6.26801 29 14 29V29C21.732 29 28 22.732 28 15V14C28 6.26801 21.732 0 14 0V0Z"
                                            fill="#00C599" />
                                        <g clip-path="url(#clip0)">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M21.0045 9.94163C21.178 10.1234 21.1713 10.4112 20.9894 10.5846L12.0848 19.0732C11.9088 19.2409 11.632 19.2408 11.4562 19.0729L7.01176 14.8286C6.83006 14.6551 6.82353 14.3672 6.99718 14.1857C7.17082 14.0041 7.45889 13.9976 7.64059 14.1711L11.7709 18.1154L20.3611 9.92649C20.543 9.75313 20.8311 9.75991 21.0045 9.94163Z"
                                                fill="#00C599" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M19.9219 9.43247C20.3598 9.01505 21.0456 9.03012 21.4652 9.46955C21.8975 9.9224 21.8792 10.6484 21.4281 11.0784L12.5234 19.5671C12.0992 19.9714 11.4404 19.9711 11.0166 19.5664L6.57212 15.322C6.12133 14.8916 6.10371 14.1656 6.53641 13.7132C6.95627 13.2741 7.64212 13.2596 8.07967 13.6775L11.771 17.2026L19.9219 9.43247ZM7.45106 14.3352L7.45084 14.335Z"
                                                fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="15.5556" height="16.1111" fill="white"
                                                    transform="translate(6.22266 6.44434)" />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                    <div>
                                        A successful Toast.
                                        <button type="button" class="btn-close" data-bs-dismiss="alert"
                                            aria-label="Close"><svg width="16" height="16" viewBox="0 0 16 16"
                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M0.546609 15.7333C0.406624 15.7333 0.273304 15.68 0.166649 15.58C-0.0399958 15.3733 -0.0399958 15.0333 0.166649 14.8267L7.11925 7.86667L0.153317 0.906667C0.0533277 0.806667 0 0.673333 0 0.533333C0 0.393333 0.0533277 0.26 0.153317 0.16C0.259972 0.0533333 0.393292 0 0.533277 0C0.673262 0 0.806581 0.0533333 0.913237 0.153333L7.8725 7.11333L14.8251 0.153333C14.9251 0.0533333 15.0584 0 15.1984 0C15.3384 0 15.4717 0.0533333 15.5784 0.153333C15.785 0.36 15.785 0.7 15.5784 0.906667L8.62575 7.86667L15.5717 14.8133C15.6717 14.9133 15.725 15.0467 15.725 15.1867C15.725 15.3267 15.6717 15.46 15.5717 15.5667C15.4717 15.6733 15.3384 15.72 15.1917 15.72C15.0451 15.72 14.9184 15.6667 14.8118 15.5667L7.8725 8.62L0.919903 15.58C0.819913 15.68 0.686594 15.7333 0.546609 15.7333Z"
                                                    fill="#FF8369" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div class="d-none alert alert-primary d-flex align-items-center alert-dismissible fade show"
                                    role="alert">
                                    <svg width="28" height="29" viewBox="0 0 28 29" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14 29C21.732 29 28 22.5081 28 14.5C28 6.49187 21.732 0 14 0C6.26801 0 0 6.49187 0 14.5C0 22.5081 6.26801 29 14 29Z"
                                            fill="#66AFD9" />
                                        <path
                                            d="M13.9992 17.0373C14.5732 17.0373 15.0492 16.5443 15.0492 15.9498V8.6998C15.0492 8.1053 14.5732 7.6123 13.9992 7.6123C13.4252 7.6123 12.9492 8.1053 12.9492 8.6998V15.9498C12.9492 16.5443 13.4252 17.0373 13.9992 17.0373Z"
                                            fill="white" />
                                        <path
                                            d="M15.2876 19.7492C15.2176 19.5752 15.1196 19.4157 14.9936 19.2707C14.8536 19.1402 14.6996 19.0387 14.5316 18.9662C14.1956 18.8212 13.8036 18.8212 13.4676 18.9662C13.2996 19.0387 13.1456 19.1402 13.0056 19.2707C12.8796 19.4157 12.7816 19.5752 12.7116 19.7492C12.6416 19.9232 12.5996 20.1117 12.5996 20.3002C12.5996 20.4887 12.6416 20.6772 12.7116 20.8512C12.7816 21.0397 12.8796 21.1847 13.0056 21.3297C13.1456 21.4602 13.2996 21.5617 13.4676 21.6342C13.6356 21.7067 13.8176 21.7502 13.9996 21.7502C14.1816 21.7502 14.3636 21.7067 14.5316 21.6342C14.6996 21.5617 14.8536 21.4602 14.9936 21.3297C15.1196 21.1847 15.2176 21.0397 15.2876 20.8512C15.3576 20.6772 15.3996 20.4887 15.3996 20.3002C15.3996 20.1117 15.3576 19.9232 15.2876 19.7492Z"
                                            fill="white" />
                                    </svg>
                                    <div>
                                        A Informational toast
                                        <button type="button" class="btn-close" data-bs-dismiss="alert"
                                            aria-label="Close"><svg width="16" height="16" viewBox="0 0 16 16"
                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M0.546609 15.7333C0.406624 15.7333 0.273304 15.68 0.166649 15.58C-0.0399958 15.3733 -0.0399958 15.0333 0.166649 14.8267L7.11925 7.86667L0.153317 0.906667C0.0533277 0.806667 0 0.673333 0 0.533333C0 0.393333 0.0533277 0.26 0.153317 0.16C0.259972 0.0533333 0.393292 0 0.533277 0C0.673262 0 0.806581 0.0533333 0.913237 0.153333L7.8725 7.11333L14.8251 0.153333C14.9251 0.0533333 15.0584 0 15.1984 0C15.3384 0 15.4717 0.0533333 15.5784 0.153333C15.785 0.36 15.785 0.7 15.5784 0.906667L8.62575 7.86667L15.5717 14.8133C15.6717 14.9133 15.725 15.0467 15.725 15.1867C15.725 15.3267 15.6717 15.46 15.5717 15.5667C15.4717 15.6733 15.3384 15.72 15.1917 15.72C15.0451 15.72 14.9184 15.6667 14.8118 15.5667L7.8725 8.62L0.919903 15.58C0.819913 15.68 0.686594 15.7333 0.546609 15.7333Z"
                                                    fill="#FF8369" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div class="mt-5 d-none">
                        <p class="text-gray-2 text-xs"> Or sign in with a social account</p>
                        <ul class="socialLoginIcons">

                            <li>
                                <a href="javascript:void(0)"
                                    (click)="LoginComponent_CreateSigninWithSocialMediaChannelsLink('facebook')"
                                    class="btn-round btn btn-outline-gray">
                                    <img src="../assets/images/onBoarding/facebook.svg" alt="facebook"
                                        class="facebook-svg" />
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)"
                                    (click)="LoginComponent_CreateSigninWithSocialMediaChannelsLink('google')"
                                    class="btn-round btn btn-outline-gray">
                                    <img src="../assets/images/onBoarding/Google.svg" alt="Google" class="google-svg" />
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)"
                                    (click)="LoginComponent_CreateSigninWithSocialMediaChannelsLink('linkedin')"
                                    class="btn-round btn btn-outline-gray">
                                    <img src="../assets/images/onBoarding/Linkedin.svg" alt="Linkedin"
                                        class="linkedin-svg" />
                                </a>
                            </li>
                        </ul>
                    </div>

                </form>
            </div>
        </div>
        <div class="loginSecItmBottom d-flex align-items-center text-md-end " id="Redirect_to_Intercom">
            <div class="privacy-terms-links text-start text-lg-end text-xs text-md-end ps-md-0 ps-md-0">
                <img src="../assets/images/onBoarding/help-icon.svg" alt="help-icon" />
                <p><a class="text-gray-2" href="javascript:void(0)">Need Support?</a><br>
                    We're here to help.</p>
            </div>

        </div>
    </div>
</section>
<!-- error modal -->
<div class="modal fade" id="error-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog width-483 modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-body">
                <button type="button" class="btn-close cust-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <h5 class="mb-4">{{sErrorMessage}}</h5>
                <div class="alert alert-danger p-3 mb-4 g-0 row rounded-1">
                    <div class="col-1 p-0">
                        <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                                <path
                                    d="M13.9993 3.47998C6.92399 3.47998 1.16602 9.20176 1.16602 16.2326C1.16602 23.2635 6.92399 29 13.9993 29C21.0747 29 26.8327 23.2782 26.8327 16.2473C26.8327 9.21646 21.0747 3.47998 13.9993 3.47998ZM15.1095 15.7619C15.1095 16.365 14.6062 16.8651 13.9993 16.8651C13.3925 16.8651 12.8892 16.365 12.8892 15.7619V8.40747C12.8892 7.80441 13.3925 7.3043 13.9993 7.3043C14.6062 7.3043 15.1095 7.80441 15.1095 8.40747V15.7619Z"
                                    fill="#FF8369"></path>
                                <path
                                    d="M17.7366 2.32H10.2647C9.74765 2.32 9.33398 1.808 9.33398 1.168C9.33398 0.528 9.74765 0 10.2647 0H17.7366C18.2537 0 18.6673 0.512 18.6673 1.152C18.6673 1.792 18.2537 2.32 17.7366 2.32Z"
                                    fill="#FF8369"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="28" height="29" fill="white"></rect>
                                </clipPath>
                            </defs>
                        </svg>


                    </div>
                    <div class="col-11 p-0">

                        <p class="text-sm text-gray-3 mb-0 ms-2">{{sErrorMessage}}</p>
                    </div>
                </div>

                <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" data-bs-dismiss="modal"
                    routerLink="/login">Go Back</a>

            </div>
        </div>
    </div>
</div>