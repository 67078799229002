import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  ActivatedRoute,
  NavigationStart,
  Params,
  Router,
} from "@angular/router";
import { NzDrawerPlacement } from "ng-zorro-antd/drawer";
import { brand, UserList } from "src/app/models/admin";
import { Subject } from "rxjs/internal/Subject";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BrandService } from "../service/BrandService";
import { cSessionService } from "src/app/services/session.service";
import { Userservice } from "../../users/service/user-service";
import { SuccessMessageModalService } from "src/app/services/success-message-modal.service";

@Component({
  selector: "app-manage-brand-account",
  templateUrl: "./manage-brand-account.component.html",
  styleUrls: ["./manage-brand-account.component.scss"],
})
export class ManageBrandAccountComponent implements OnInit {
  showAssignNewUserDrawer = false;
  placementUser: NzDrawerPlacement = "right";
  brandId: string;
  userId: any;
  brandDetail: brand = {};
  public assignUserForm: FormGroup;
  public updateBrandDetailForm: FormGroup;
  userList: any = [];
  modelUserSearchChanged = new Subject<string>();
  isUserSearchLoading: boolean = false;
  brandUserList: any = [];
  limit = 100;
  offset = 0;
  brandName = "";
  errorBrandEmptry = false;
  selectedUser : any;
  constructor(
    private userService: Userservice,
    private assignUserFormBuilder: FormBuilder,
    private updateBrandDetailFormBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,    
    private brandService : BrandService,
    public oSessionService: cSessionService,
    private successMessageService : SuccessMessageModalService,
  ) { 
    this.oSessionService.setAccountSettingHeaderName("Assigned Brands");
  }

  ngAfterViewInit(): void {
    
    
    this.modelUserSearchChanged.pipe(debounceTime(200), distinctUntilChanged()).subscribe(async (value) => {
      if(value && value != '' && value.length > 1){
      this.userList = await this.userService
      .getUsers(this.offset, this.limit, "created", -1, value, "_id", false).then((resList)=>{
      return this.userList = resList;
      });

      this.isUserSearchLoading = false;
      return this.userList;
    }
      else{
        return [];
      }
    });

  }
  ngOnInit(): void {
    this.brandId = this.route.snapshot.params["id"];
    
    this.getBrandProfile();

    this.getBrandUserList();
  }

  getBrandProfile() {
    let __this = this;
    this.brandService
      .getBrandProfile(this.brandId)
      .then((brand: brand) => {        
        __this.brandDetail = brand;
        __this.userService.selectedMediaId = (brand.media_libs) ? brand.media_libs : undefined;
        __this.brandService.selectedBrandProfile = brand;    
        __this.updateBrandDetailForm = this.updateBrandDetailFormBuilder.group({
          name: brand.name
        });
        __this.brandName = brand.name;
      });
  }
  // add assign new user drawer
  openAssignNewUserDrawer(): void {
    // if (this.userList.length == 0) {
    //   this.getUserList();
    // }
    this.assignUserForm = this.assignUserFormBuilder.group({
      brand: "",
      user: "",
      searchUserText:""
    });   
    this.showAssignNewUserDrawer = true;
  }
  closeAssignNewUserDrawer(): void {
    this.showAssignNewUserDrawer = false;
  }

  getUserId(id: any) {
    this.userId = id;
    console.log("userId", this.userId);
  }
  assignUserBrand() {
    if (this.userId !== "") {
      this.brandService
        .addUserBrand([this.brandId], [this.userId], false)
        .then((response : any) => {
          if (response) {
      
            if(response && response.records && response.records.length > 0) {
              
              response.records.map( (record) =>{     
                console.log(record);
                console.log(record.user._id);
                this.userService.getUserProfile(record.user._id, false).then((brandDetail : any) => {                    
    
                  this.brandUserList.unshift({_id : record.user_brand_id, brand : brandDetail});                  
                });
              })
              
            } else {
              // this.assignBrandSubmit = false;
              // this.showAssignNewBrandDrawer = false;
              // this.errorService.handleError(response.result, "info", true);
            }


            this.getBrandUserList();
          }
        });

    }
    this.showAssignNewUserDrawer = false;
  }
  getBrandUserList() {
    this.brandService
      .getBrandUsers(this.brandId, false)
      .then((resList) => {
        this.brandUserList = resList["records"];        
      });
  }
  
  loadBrandMediaLibraryList() {
    this.router.navigate([`/account-setting/manage-brand/${this.brandId}/list-media-libraries`]);
  }
  loadBrandMediaLibrary() {
    this.router.navigate([`/account-setting/manage-brand/${this.brandId}/media-libraries`]);
  }
  loadBrandBusiness() {
    this.router.navigate([`/account-setting/manage-brand/${this.brandId}/business-details`]);
  }

  deleteAssignedUser(id : string){
      this.brandService.deleteAssignedUser(id).then((res) => {        
        const indexRecrod = this.brandUserList.findIndex( (user) => user._id.$oid == id );
        if (indexRecrod > -1) {                
          this.successMessageService.display("User has been deleted.", "Delete User", true , true); 
          this.brandUserList.splice(indexRecrod, 1);        
        }
      });    
  }

  saveBrandDetail(){
    const brand = {
      _id: this.brandDetail._id.$oid,
      name : this.brandName
    };
    if( this.brandName != '') {
      this.brandService.updateBrandDetails(brand).then( (res) => {
        this.brandDetail.name = this.brandName;
      });
    } else {
      this.errorBrandEmptry = true;
    }
    
  }
  // getUserList() {
  //   let __this = this;
  //   this.userService
  //     .getUsers(this.offset, this.limit, "created", -1, "", "_id")
  //     .then((list: UserList) => {
  //       console.log("userList", JSON.stringify(list));

  //       __this.userList = list['result'];
  //     });
  // }
  showDeleteUserModal = false;

  showDeleteUserModel(User : string){
    this.selectedUser = User;
    this.showDeleteUserModal = true;
  }

  deleteUser(){
    this.showDeleteUserModal = false;
    this.deleteAssignedUser(this.selectedUser);
  }
}
