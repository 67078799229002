<div class="weight-600 spinner text-center" style="
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #eee;
        opacity: 0.6;
        margin-left: -24px;
        margin-top: -25px;
        z-index: 1000;
    " *ngIf="!timeScaleLoaded">
    <div class="lds-ellipsis" style="margin-top: 300px">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
<div class="media-editor-popup">
    <div class="heading mb-4">
        <span class="bold">Easy Trim &nbsp; &nbsp;|</span>
        <span class="lite ms-2">Drag the highlighted portion within the defined <span class="orange">{{ maxAllowedClipLength.toFixed(2) }} second</span> span for best
            results.
        </span>
        <img class="close-editor" src="../../../assets/images/svg/close-square.svg" (click)="closeEditor()">
    </div>
    <div class="preview" *ngIf="media.type == 'video'">
        <div style="width: max-content; margin: auto">
            <div class="title">
                <div class="text">Scene 1</div>
                <div class="seconds" *ngIf="playerInfo.clip.duration">{{ maxAllowedClipLength.toFixed(2) }}</div>
                <div class="btn link" (click)="revertChanges(false)">Reset</div>
                <div class="btn btn-primary link" (click)="updateVideo()">Save</div>
            </div>
            <video muted constrols="false" crossorigin="anonymous | use-credentials" #mediaEditorPlayer [src]="media.url"></video>
            <div class="player" style="display: grid; grid-template-columns: 23px auto 100px" *ngIf="timeScaleLoaded">
                <div class="play-icon-purple" style="margin-top:6px" (click)="playPreviewPlayer()"><i class="icon play-icon-large"></i></div>
                <div class="progress-slider">
                    <input style="width: 100%" type="range" [min]="0" [max]="100" class="range-field" [value]="playerProgressRangeValue" />
                </div>
                <div class="timer time-text">
                    {{ this.playerInfo.pointer.seconds | secondsToHoursTime }} / {{ (this.playerInfo.pointer.seconds + maxAllowedClipLength) | secondsToHoursTime}}
                </div>
            </div>
        </div>
    </div>
    <div [ngClass]="{ 'd-none': !timeScaleLoaded }" style="position: relative; overflow: hidden; padding-top: 10px; margin-left: 90px; margin-right: 90px" #durationbar class="video-top-duration-boundary">
        <!-- <div class="pointer"  [style.left.px]="this.playerInfo.clip.clip_left" #pointerScroll  >
      <div class="second">{{playerInfo.pointer.seconds}}</div>
    </div> -->
        <div style="width: 100%;     background: #Fff; border:0 px solid #dcdcdc;">

            <div class="layer-bg-slider"></div>
            <div class="thumbs video-duration-boundary fency-scroll-horizontal">


                <!-- <div class="preview-thumb" style=""></div> -->

                <div #imgContainer class="video-image-thumbs">

                    <div class="view checkingview" *ngFor="let thumb of listThumbs" [style.width.px]="playerInfo.clip.thumb_width" [style.min-width.px]="playerInfo.clip.thumb_width" [ngStyle]="{'background-image': 'url(' + thumb + ')'}">
                    </div>
                    <!-- <img *ngFor="let thumb of listThumbs" [src]="thumb" [style.width.px]="playerInfo.clip.thumb_width" style="height: auto" /> -->
                </div>
                <div class="slider" *ngIf="loadSlider" #slider [style.width.px]="this.playerInfo.clip.selector_width" cdkDragBoundary=".video-duration-boundary" [cdkDragFreeDragPosition]="dragPosition" cdkDrag cdkDragLockAxis="x" (cdkDragMoved)="dragMoveSlider($event)"
                    (cdkDragEnded)="dragEnded($event)" (cdkDragStarted)="dragStarted($event)" (cdkDragReleased)="dragSliderEnds($event)" (mouseleave)="dragOver()">
                    <div class="left-corner corner"></div>
                    <div class="right-corner corner"></div>
                    <div class="second">{{ playerInfo.pointer.seconds }}</div>
                    <div class="second-end">{{ (playerInfo.pointer.seconds + maxAllowedClipLength ).toFixed(2) }}</div>
                    <!-- <div class="border-sec"></div> -->
                    <!-- <div class="border-sec-end"></div> -->
                </div>
            </div>
        </div>
        <!-- <div *ngIf="timeScaleLoaded" class="video-time-duration-bar">
      <ul class="timeScale d-flex justify-content-between" style="margin-bottom: -66px" [ngStyle]="{ width: (playerInfo.clip.duration_width) + 'px' }" *ngIf="timeScaleBreakPoints">
        <ng-container *ngFor="let nBreakpoint of timeScaleBreakPoints; index as nBreakPointIndex">
          <li>
            <sup>{{ nBreakpoint.breakpoint }}s</sup>
            <ng-container *ngFor="let partition of nBreakpoint.breakPointPartition">
              <span></span>
            </ng-container>
          </li>
        </ng-container>
      </ul>
    </div> -->
    </div>
</div>
<div class="bottom-controls" style="" *ngIf="timeScaleLoaded">
    <div></div>
    <div class="inputs">

        <label class="time-clock-icon" style=" background-position: center;"></label>
        <label class="" style=" ">Start</label>
        <input type="text" readonly class="" [value]="playerInfo.pointer.seconds | secondsToHoursTime" />
        <label class="" style="padding-left: 18px !important; border-left: 1px solid #ccc; ">End</label>
        <input type="text" readonly [value]="playerInfo.pointer.seconds + maxAllowedClipLength | secondsToHoursTime" />
        <label class="time-clock-icon" style="    padding-right: 21px; background-position: center;"></label>
    </div>
    <div style=" padding-top: 3px;">
        <div class="range-slider">
            <img class="link" style="    margin-top: 1px;" (click)="updateZoomLevel(false)" src="../../assets/images/icons/minus-icon.svg" />
            <input type="range" class="range-field" id="points" name="points" [(ngModel)]="zoomRangeField" min="40" max="100" (change)="updateZoom($event)" />
            <img class="link" (click)="updateZoomLevel(true)" src="../../assets/images/icons/plus-icon.svg" />
        </div>
    </div>
</div>

<div class="cloneVideoObj" #cloneVideoObj style="display: none"></div>
<video controlslist="nodownload noplaybackrate" muted style="display: none" crossorigin="anonymous | use-credentials" #mediaEditorPlayerClone height="100" width="100" [src]="media?.url"></video>