<section class="loginSec">
    <app-onboarding-aside-section class="loginSecItm loginSecLeft"></app-onboarding-aside-section>
    <div class="loginSecItm loginSecRight">
        <div class="loginSecItmContent-plane">
            <div class="d-lg-none pb-5">
                <a routerLink="/login">
                    <img src="../../../assets/images/logo-mobile.svg" alt="logo-mobile" width="120" />
                </a>
            </div>
            <!-- <a routerLink="/forgot-password">
                <img src="../../../assets/images/onBoarding/back-arrow.svg" alt="back-arrow" />
            </a> -->
            <h2 class="text-gray-1 mt-5">Welcome {{this.onboardingManager.profile.name
                }}, </h2>
            <!-- <h4 class="message-create-account">A confirmation email is coming your way. In the meantime, tell us about your business.</h4> -->
            <h5 class="text-muted">Your email address has been verified. Please continue with the sign-up process to
                start using our product.</h5>
            <p class="text-sm mt-3 ms-1 pb-0 mb-0">Please tell us
                about your
                business.</p>
            <div class="loginSecItmForm mt-3" aria-labelledby="loginYourself">
                <form [formGroup]="fUserProfileForm" (ngSubmit)="save()">
                    <h4 class="text-gray-1 mb-4">Business Information</h4>
                    <div class="row mb-4">
                        <div class="col-md-5">
                            <div class="formItm">
                                <input class="text-gray-2" id="yourBusiness" type="text" value="Web Design"
                                    placeholder="Web Design" required formControlName="company_name">
                                <label for="yourBusiness" class="text-sm">What's the name of your business?</label>
                                <span
                                    *ngIf="fUserProfileForm.controls['company_name'].hasError('required') && fUserProfileForm.controls['company_name'].touched"
                                    class="error">Please enter your business name.</span>

                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="formItm formItmPasswordView mb-4">
                                <button type="button">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M10.9198 4.57378L6.57851 6.36483C6.53032 6.38441 6.48654 6.41344 6.44976 6.45023C6.41298 6.48701 6.38394 6.53078 6.36436 6.57897L4.57332 10.9203C4.54683 10.9921 4.54009 11.0698 4.55378 11.1451C4.56748 11.2204 4.60112 11.2907 4.65119 11.3486C4.72308 11.422 4.82104 11.4639 4.92374 11.4654L5.07948 11.4265L9.42082 9.63543C9.46901 9.61585 9.51279 9.58681 9.54957 9.55003C9.58635 9.51325 9.61539 9.46947 9.63497 9.42128L11.426 5.07995C11.4543 5.00919 11.4613 4.93168 11.4459 4.85701C11.4306 4.78235 11.3937 4.71383 11.3399 4.65994C11.286 4.60605 11.2174 4.56916 11.1428 4.55384C11.0681 4.53853 10.9906 4.54546 10.9198 4.57378ZM8.97306 8.97352L5.64405 10.3557L7.02627 7.02673L10.3553 5.64451L8.97306 8.97352Z"
                                            fill="#A39FAA" />
                                        <path
                                            d="M13.6652 2.33485C12.159 0.839302 10.1225 0 8 0C5.87745 0 3.84102 0.839302 2.33485 2.33485C0.839302 3.84102 0 5.87745 0 8C0 10.1225 0.839302 12.159 2.33485 13.6652C3.84102 15.1607 5.87745 16 8 16C10.1225 16 12.159 15.1607 13.6652 13.6652C15.1607 12.159 16 10.1225 16 8C16 5.87745 15.1607 3.84102 13.6652 2.33485V2.33485ZM13.1201 13.1201C12.1085 14.1265 10.8215 14.8107 9.42146 15.0865C8.02142 15.3622 6.57108 15.217 5.25345 14.6693C3.93583 14.1216 2.80998 13.1958 2.01797 12.0088C1.22596 10.8219 0.803281 9.42693 0.803281 8C0.803281 6.57307 1.22596 5.17811 2.01797 3.99115C2.80998 2.8042 3.93583 1.87844 5.25345 1.33071C6.57108 0.782977 8.02142 0.637813 9.42146 0.913536C10.8215 1.18926 12.1085 1.87351 13.1201 2.87995C14.4777 4.23803 15.2403 6.07971 15.2403 8C15.2403 9.92029 14.4777 11.762 13.1201 13.1201V13.1201Z"
                                            fill="#A39FAA" />
                                    </svg>
                                </button>
                                <input id="youBased" type="text" value="Denver, CO" placeholder="Where are you Based?"
                                    required formControlName="location">
                                <label for="youBased" class="text-sm">Where are you based?</label>
                                <span
                                    *ngIf="fUserProfileForm.controls['location'].hasError('required') && fUserProfileForm.controls['location'].touched"
                                    class="error">Please enter your location.</span>

                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="formItm">
                                <input id="website-address" type="text" value="" placeholder="Enter website address"
                                    required name="textfeild" formControlName="url">
                                <label for="website-address" class="text-sm">Enter your website </label>
                                <span
                                    *ngIf="fUserProfileForm.controls['url'].hasError('required') && fUserProfileForm.controls['url'].touched"
                                    class="error">Please enter website address.</span>
                            </div>
                        </div>
                    </div>



                    <div class="row">
                        <div class="col-md-12">
                            <h4 class="text-gray-1 mb-1 ps-2">What industry are you in?<span
                                    style="color: #d3d3d3;">(Optional)</span></h4>
                            <p class="text-gray-3 text-xs">Knowing your industry helps us choose relevant clips for your
                                videos</p>
                            <ul class="selct_business">
                                <li class="icon-svg" *ngFor="let industry of listTopFiveIndustries"
                                    (click)="bDisabledButtons ? CreateAccountCompanyComponent_PatchIndustryValue(industry.name):''"
                                    [ngStyle]="{'position': industry.name.includes('Food & Beverages')  ? 'relative' : '', 'top': industry.name.includes('Food & Beverages') ? '16px' : ''}">
                                    <a href="javascript:void(0)"
                                        [ngClass]="industry.name===sSelectedIndustry?'active':''">
                                        <figure>
                                            <img [src]="industry.icon" width="27" height="24" />
                                        </figure>
                                        <span  lang="de" [ngClass]="industry.name.length>13 ? 'industry-text':''"
                                            class="text-center text-xs">{{industry.name | titlecase}}</span>
                                    </a>
                                </li>
                                <!-- <li  *ngFor="let industry of industries.slice(0,5);"
                                    (click)="CreateAccountCompanyComponent_PatchIndustryValue(industry.name)">
                                    <a href="javascript:void(0)"
                                        [ngClass]="industry.name===sSelectedIndustry?'active':''">
                                        <figure>
                                            <svg width="27" height="24" [attr.viewBox]="industry.viewBox" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path [attr.d]="industry.path" fill="#A39FAA" />
                                            </svg>
                                        </figure>
                                        <span [ngClass]="industry.name.length>12 ? 'industry-text':''" class="text-center text-xs">{{industry.name | titlecase}}</span>
                                    </a>
                                </li> -->
                                <li class="loadMore icon-svg">
                                    
                                    <a href="javascript:void(0)" (click)="openBrand()" [ngClass]="[
                                    bDisabledButtons ? '' : '',
                                    this.otherActive ? 'active' : ''
                                  ]">
                                                            <figure>
                                                                <img src="../assets/images/icons/dots.svg" width="25" height="7" />
                                                            </figure>
                                                            <span class="text-center text-xs">Others</span>
                                                        </a>
                                    <!-- <a href="javascript:void(0)" id="sidebarCollapse" class="no-focus">
                                        <figure>
                                            <svg width="25" height="7" viewBox="0 0 25 7" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M6.25 3.125C6.25 4.85089 4.85089 6.25 3.125 6.25C1.39911 6.25 0 4.85089 0 3.125C0 1.39911 1.39911 0 3.125 0C4.85089 0 6.25 1.39911 6.25 3.125ZM15.625 3.125C15.625 4.85089 14.2259 6.25 12.5 6.25C10.7741 6.25 9.375 4.85089 9.375 3.125C9.375 1.39911 10.7741 0 12.5 0C14.2259 0 15.625 1.39911 15.625 3.125ZM21.875 6.25C23.6009 6.25 25 4.85089 25 3.125C25 1.39911 23.6009 0 21.875 0C20.1491 0 18.75 1.39911 18.75 3.125C18.75 4.85089 20.1491 6.25 21.875 6.25Z"
                                                    fill="#A39FAA" />
                                            </svg>
                                        </figure>
                                        <span class="text-center text-xs">Others</span>
                                    </a> -->
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-md-5">
                            <div class="row g-0">
                                <div class="col">

                                    <div *ngIf="bDisplayErrorMsg"
                                        class=" alert alert-danger d-flex align-items-center alert-dismissible fade show"
                                        role="alert">
                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M27.5683 21.8754L16.8998 3.08014C15.6241 0.839822 12.3899 0.839822 11.1141 3.08014L0.445544 21.8754C-0.830163 24.0854 0.788172 26.8545 3.33834 26.8545H24.6437C27.2254 26.8545 28.8121 24.1169 27.568 21.8754H27.5683ZM14.0387 7.56066C15.0337 7.56066 15.5318 8.46261 15.5318 10.2994C15.5318 11.2949 15.4079 12.6649 15.1273 14.4385C14.8478 16.2121 14.6607 17.3012 14.4736 17.6441C14.3497 17.8933 14.2245 18.0173 14.0387 18.0173C13.8212 18.0173 13.6657 17.9237 13.5721 17.7061C13.4166 17.4265 13.1991 16.4297 12.9185 14.7182C12.639 13.0066 12.5139 11.5126 12.4835 10.3298C12.4835 8.46383 13.012 7.56066 14.0387 7.56066V7.56066ZM15.1273 22.0627C14.8162 22.3739 14.4432 22.5295 14.0071 22.5295C13.5721 22.5295 13.1979 22.3739 12.8869 22.0627C12.5758 21.7516 12.4203 21.3784 12.4203 20.942C12.4203 20.5068 12.5758 20.1324 12.8869 19.8212C13.1979 19.51 13.5709 19.3544 14.0071 19.3544C14.4116 19.3544 14.7846 19.51 15.1273 19.8528C15.4699 20.1956 15.6254 20.5688 15.6254 20.9736C15.6242 21.3784 15.4383 21.7516 15.1273 22.0627Z"
                                                fill="#842029" />
                                        </svg>
                                        <div>
                                            {{sErrorMessage}}
                                            <button type="button" class="btn-close" data-bs-dismiss="alert"
                                                aria-label="Close"><svg width="16" height="16" viewBox="0 0 16 16"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M0.546609 15.7333C0.406624 15.7333 0.273304 15.68 0.166649 15.58C-0.0399958 15.3733 -0.0399958 15.0333 0.166649 14.8267L7.11925 7.86667L0.153317 0.906667C0.0533277 0.806667 0 0.673333 0 0.533333C0 0.393333 0.0533277 0.26 0.153317 0.16C0.259972 0.0533333 0.393292 0 0.533277 0C0.673262 0 0.806581 0.0533333 0.913237 0.153333L7.8725 7.11333L14.8251 0.153333C14.9251 0.0533333 15.0584 0 15.1984 0C15.3384 0 15.4717 0.0533333 15.5784 0.153333C15.785 0.36 15.785 0.7 15.5784 0.906667L8.62575 7.86667L15.5717 14.8133C15.6717 14.9133 15.725 15.0467 15.725 15.1867C15.725 15.3267 15.6717 15.46 15.5717 15.5667C15.4717 15.6733 15.3384 15.72 15.1917 15.72C15.0451 15.72 14.9184 15.6667 14.8118 15.5667L7.8725 8.62L0.919903 15.58C0.819913 15.68 0.686594 15.7333 0.546609 15.7333Z"
                                                        fill="#FF8369" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <button class="btn btn-md btn-secondary btn-round-6 py-2"
                                            style="padding: 11px 30px;" type="submit">Save</button>
                                        <button (click)="navigateUserToDashBoard()"
                                            class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2"
                                            style="margin-left:20px;">I'll Do it Later</button>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<nav id="sidebar" class="right-0" *ngIf="this.visibleBrand == true">
    <div class="sidebar-header pb-1 px-4 mx-1">
        <h3 class="text-gray-2 weight-700">What industry are you in?</h3>
        <p class="text-gray-3 text-xs pt-2 pe-4">Knowing your industry helps us choose relevant clips for your
            videos.</p>
        <div id="dismiss" (click)="closeBrand()">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14.19 0H5.81C2.17 0 0 2.17 0 5.81V14.18C0 17.83 2.17 20 5.81 20H14.18C17.82 20 19.99 17.83 19.99 14.19V5.81C20 2.17 17.83 0 14.19 0Z"
                    fill="#F2F2F5"></path>
                <path
                    d="M11.0599 10.0001L13.3599 7.70011C13.6499 7.41011 13.6499 6.93011 13.3599 6.64011C13.0699 6.35011 12.5899 6.35011 12.2999 6.64011L9.99986 8.94011L7.69986 6.64011C7.40986 6.35011 6.92986 6.35011 6.63986 6.64011C6.34986 6.93011 6.34986 7.41011 6.63986 7.70011L8.93986 10.0001L6.63986 12.3001C6.34986 12.5901 6.34986 13.0701 6.63986 13.3601C6.78986 13.5101 6.97986 13.5801 7.16986 13.5801C7.35986 13.5801 7.54986 13.5101 7.69986 13.3601L9.99986 11.0601L12.2999 13.3601C12.4499 13.5101 12.6399 13.5801 12.8299 13.5801C13.0199 13.5801 13.2099 13.5101 13.3599 13.3601C13.6499 13.0701 13.6499 12.5901 13.3599 12.3001L11.0599 10.0001Z"
                    fill="#A7ABB6"></path>
            </svg>
        </div>
    </div>
    <div class="row row mt-2 mx-0 px-3" style="max-height: calc(100vh - 220px) !important;
    overflow: auto !important;">
        <div class="col-md-12 ">
            <ul class="selct_business selct_business1">
                <div>
                    <ng-container>
                        <li class="icon-svg" *ngFor="let industry of listTopIndustries.slice(0, limitIndustryList)"
                            (click)="CreateAccountCompanyComponent_PatchIndustryValue(industry.name)">
                            <a href="javascript:void(0)" [ngClass]="industry.name === sSelectedIndustry ? 'active': ''">
                                <figure>
                                    <img [src]="industry.icon" width="27" height="24" />
                                </figure>
                                <span  lang="de" class="text-center text-xs industry-text">{{industry.name |
                                    titlecase}}</span>
                            </a>
                        </li>
                    </ng-container>
                </div>


            </ul>
        </div>
        <div class="row" *ngIf="!bloadIndustries">
            <div class="col-md-12">
                <a href="javascript:void(0)" id="view-all" (click)="showAll()"><span>+</span> View All</a>
            </div>
        </div>
        <div class="col-md-12">
            <h6 class="text-gray-2 weight-700 mt-3 pt-4 border-top">Didn’t find your industry?</h6>
            <p class="text-gray-3 text-xs pt-1 pe-4">Type the name of your industry to help us find content that fits.
            </p>
            <div class="row">
                <div class="col-md-8">
                    <div class="formItm mb-4">

                        <input id="inputadd" class="p-0" type="text" [(ngModel)]="sNewIndustry"
                            placeholder="Add your industry name">
                        <label for="inputadd" class="text-sm ">Type your industry </label>
                    </div>
                </div>
                <div class="col-md-4 add-new">
                    <button class="col-md-3 weight-normal btn btn-secondary btn-round-6 small-pad text-xs px-3"
                        [disabled]="!sNewIndustry" (click)="CreateAccountCompanyComponent_AddNewIndustry()">Add
                        New</button>
                </div>
            </div>

            <!-- <div class="row">
                <div class="col-md-8">
                <div class="formItm mb-4">

                    <input id="inputadd" type="text" value="" placeholder="Add your industry name" [(ngModel)]="sNewIndustry" required>
                    <label for="inputadd" class="text-sm">Add your industry name</label>
                </div>
            </div>
            <div class="col-md-4 add-new">
                <button class="col-md-3 weight-normal btn btn-secondary btn-round-6 small-pad text-xs px-3"
                 [disabled]="!sNewIndustry" (click)="CreateAccountCompanyComponent_AddNewIndustry()">Add New</button>
            </div>
            </div> -->
        </div>
    </div>
    <ul class="sideNav-btn px-3">
        <button class="weight-normal btn btn-primary btn-round-6 small-pad px-5 py-3"
            id="close-industry-panel" (click)="closeBrand()">Save</button>
    </ul>
</nav>
<div class="overlay"></div>