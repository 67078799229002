<div>
    <button (click)="toggleSideBarNew()"
        class="btn weight-600 text-xs btn btn-primary rounded-circle toggle-sidebar-left position-absolute"
        id="sidebar-btn">
        <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M5.70076 0.764328C5.87437 0.589477 5.87437 0.305988 5.70076 0.131137C5.52716 -0.0437139 5.24568 -0.0437139 5.07208 0.131137L0.533865 4.70189C-0.177955 5.41882 -0.177956 6.58118 0.533865 7.29811L5.07208 11.8689C5.24569 12.0437 5.52716 12.0437 5.70076 11.8689C5.87437 11.694 5.87437 11.4105 5.70076 11.2357L1.16255 6.66491C0.79794 6.29769 0.79794 5.70231 1.16255 5.33508L5.70076 0.764328Z"
                fill="white" />
        </svg>

    </button>

    <ul class="accountnav text-xl mt-4 admin-nav pt-6 px-0 pb-5" id="accountnav">


        <li class="m-0">
            <a class="side-item" [routerLink]="['publishedVideo']" routerLinkActive="active">
                <img class="icon-svg" src="../../../assets/images/svg/publish now.svg">
                Published Videos</a>
        </li>
        <li class="m-0">
            <a class="side-item" [routerLink]="['scheduledVideos']" routerLinkActive="active">
                <img class="icon-svg" src="../../../assets/images/svg/scheduled.svg">


                Scheduled Videos</a>
        </li>
        <li class="m-0">
            <a class="side-item" [routerLink]="['draftsVideo']" routerLinkActive="active">

                <img class="icon-svg" src="../../../assets/images/svg/draft.svg">
                Drafts</a>
        </li>
        <li *ngIf="this.bLocales" class="m-0">
            <a class="side-item" [routerLink]="['locationVideos']" routerLinkActive="active">
                <img class="icon-svg" src="../../../assets/images/svg/location.svg">
                Localized Videos</a>
        </li>
    </ul>
</div>