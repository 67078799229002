import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'mediaFileName'})
export class MediaFileNamePipe implements PipeTransform {

    getName(fileName = "") {
        let name = fileName;
        if(fileName.indexOf("/") > -1 ){
            name = fileName.split('/').pop();
        }

        var splitt = name?.split('.');
        let nameFile = name;
        let extension = "";
        if(splitt?.length > 0){
            nameFile = splitt[0];
            extension = splitt[1];
        }

        if (nameFile?.length > 15) {
            nameFile = nameFile?.substring(0, 15);

        }
        var result = nameFile + '.' + extension;
        return  result;
 }

  transform(value: any): string {
    return this.getName(value);
  }
}