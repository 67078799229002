import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { OnboardingManagerService } from "./../../Managers/onboarding-manager.service";
import { cIntercomService } from "./../../services/intercom.service";
import { cSessionService } from "./../../services/session.service";
import { environment } from "../../../environments/environment";
import { MediaManagerService } from "src/app/Managers/media-manager.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  showMenu: string = "";
  bFullHeader: boolean = false;
  bDisplayUploadIcon: boolean = false;
  bDisplayHeaderOptions: boolean = true;
  constructor(
    private oRouter: Router,
    private oIntercomService: cIntercomService,
    public oSessionService: cSessionService,
    public onboardingManager: OnboardingManagerService,
    private mediaManger: MediaManagerService
  ) {
    this.showMenu = oRouter.url;
    if (
      this.oRouter.url === "/business-profile/brand-identity" ||
      this.oRouter.url === "/business-profile/user-media" ||
      this.oRouter.url === "/business-profile/customers-experience" ||
      this.oRouter.url === "/business-profile/social-channels" ||
      this.oRouter.url === "/my-videos/publishedVideo" ||
      this.oRouter.url === "/my-videos/draftsVideo" ||
      // this.oRouter.url === "/my-videos/locationVideos" ||
      this.oRouter.url === "/my-videos/scheduledVideos" ||
      this.oRouter.url === "/reports/reach" ||
      this.oRouter.url === "/reports/engagement" ||
      this.oRouter.url === "/reports/audience" ||
      this.oRouter.url === "/business-profile/user" ||
      this.oRouter.url === "/business-profile/business-plus/manage-libraries" ||
      this.oRouter.url === "/business-profile/account" ||
      this.oRouter.url === "/business-profile/business-plus/published-video" ||
      this.oRouter.url === "/business-profile/media-library" ||
      this.oRouter.url === "/business-profile/brands" ||
      this.oRouter.url === "/business-profile/brands/account" ||
      this.oRouter.url === "/business-profile/brands/business-details" ||
      this.oRouter.url === "/business-profile/brands/published-video" ||
      this.oRouter.url === "/business-profile/brands/media-library" ||
      this.oRouter.url === "/my-account" ||
      this.oRouter.url === "/loginSecurity" ||
      this.oRouter.url === "/billing" ||
      this.oRouter.url === "/subscription" ||
      this.oRouter.url === "/business-profile/franchesees/media-library" ||
      this.oRouter.url === "/business-profile/franchesees/published-video" ||
      this.oRouter.url === "/business-profile/franchesees/account" ||
      this.oRouter.url === "/business-profile/franchesees" ||
      this.oRouter.url === "/business-profile/enterprise-media" ||
      this.oRouter.url === "/business-profile/enterprise-media-library" ||
      this.oRouter.url === "/business-profile/business-plus/account" ||
      this.oRouter.url === "/dashboard/first-time-user" ||
      this.oRouter.url === "/account-setting/profile" ||
      this.oRouter.url === "/account-setting/storage-details" ||
      this.oRouter.url === "/account-setting/social-channels" ||
      this.oRouter.url === "/account-setting/media-libraries" ||
      this.oRouter.url === "/account-setting/media-libraries/media" ||
      this.oRouter.url === "/account-setting/published-videos" ||
      this.oRouter.url === "/account-setting/locations" ||
      this.oRouter.url === "/account-setting/user-media-libraries" ||
      this.oRouter.url === "/account-setting/user-published-videos" ||
      this.oRouter.url === "/account-setting/users" ||
      this.oRouter.url === "/account-setting/user-schedule-video" ||
      this.oRouter.url === "/account-setting/user-account" ||
      this.oRouter.url === "/account-setting/media-platforms" ||
      this.oRouter.url === "/account-setting/manage-brand"
    ) {
      this.bFullHeader = true;
    }
    if (this.oRouter.url === "/business-profile/media-library") {
      this.bDisplayUploadIcon = true;
    }
    if (this.oRouter.url === "/dashboard/first-time-user") {
      this.bDisplayHeaderOptions = false;
    }
  }

  ngOnInit(): void {
    //Progress indication will be implemented later on
    // this.oDataService.oBulkUploadCount.subscribe(data=>
    // {
    //   console.log("HeaderComponent : Bulk Status ==> ", data);
    // });
    if (this.onboardingManager.profile?.user_logo_file) {
      const fpahArray = [this.onboardingManager.profile?.user_logo_file];
      if (fpahArray && fpahArray != null && fpahArray.length > 0) {
        this.mediaManger
          .getMediaThumbandURL(
            this.oSessionService.cSessionService_GetUserEmail(),
            this.oSessionService.SessionService_GetSessionId(),
            fpahArray
          )
          .then((resThumb: any) => {
            if (resThumb && resThumb.output) {
              this.oSessionService.userProfileLogoPath =
                resThumb.output[0][fpahArray[0]];
            }
          });
      }
    }
  }
  NavigateToBusinessProfile() {
    if (environment.business_plan === "enterprise_agency") {
      this.oRouter.navigateByUrl("/business-profile/brands");
    } else {
      this.oRouter.navigateByUrl("/business-profile/brand-identity");
    }
  }
  NavigateToDashboard() {
    let videoCountCheck = this.onboardingManager.loggedUser.getVideoCount();
    if (videoCountCheck > 0) {
      this.oRouter.navigateByUrl("/dashboard/myvideos");
      console.log("if blockkk");
    } else if (videoCountCheck == 0) {
      this.oRouter.navigateByUrl("/dashboard/first-time-user");
      console.log("else blockk");
    }
  }
  LogOut() {
    localStorage.clear();
    this.oIntercomService.cIntercomService_RemoveLoggedInUserIntercomCredentials();
  }
}
