import { Component,OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { cSessionService } from './../../../services/session.service';
import { cWebSocketService } from './../../../services/websocket.service';
import { AccountManagerService } from 'src/app/Managers/account-manager.service';
import { ErrorService } from 'src/app/services/error.service';
declare var $ : any;
@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {

  sSessionId: string;
  sUserEmail: any;
  fBillingForm: FormGroup;
  getBillingUrl: any;
  sDisplayBillingErrorMessage : string;
  bDisplayErrorMessage : boolean = false;
  bStripeIntegrationSuccess : boolean = false;
  invoices: any;
  noBilling = false;
  constructor(
    private oFormBuilder: FormBuilder,
    private oSessionService: cSessionService,
    private oWebSocketService: cWebSocketService,
    private oActivatedRoute : ActivatedRoute,public accountManger: AccountManagerService, public errorService:ErrorService
  ) { }

  ngOnInit() {
    
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    this.getInvoices();

  }

  
  getInvoices(){
    this.accountManger.getInovices(this.sUserEmail, this.sSessionId).then(res => {
        this.invoices = res['list'];
          }).catch(err => {
            
        // this.errorService.handleError(err.errorMessage,err.errorType,true);
        this.noBilling = true;
      
          });
}
downloadInvoice(incommingURL){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', incommingURL);
    document.body.appendChild(link);
    link.click();
    link.remove();

 }

}