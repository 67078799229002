import { Component, OnInit } from '@angular/core';
import { cSessionService } from './../../../services/session.service';
import { cWebSocketService } from './../../../services/websocket.service';
import { cDataService } from './../../../services/data.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { OnboardingManagerService } from '../../../Managers/onboarding-manager.service';
import { AccountManagerService } from './../../../Managers/account-manager.service';
import { ErrorService } from './../../../services/error.service';
import { HttpParams } from '@angular/common/http';
import { cClientService } from 'src/app/services/client.service';
import { environment } from 'src/environments/environment';
import { VideoManagerService } from 'src/app/Managers/video-manager.service';
import { BrandService } from '../../myAccount/manage-brand/service/BrandService';
import { LoaderService } from 'src/app/services/loader.service';
declare var $: any;

@Component({
    selector: 'app-timeline-view-pronto-share',
    templateUrl: './timeline-view-pronto-share.component.html',
    styleUrls: ['./timeline-view-pronto-share.component.scss'],
})
export class TimelineViewProntoShareComponent implements OnInit {
    DisconnectSocialForm: FormGroup;
    connected_social_platforms: any;
    oSocialChannel: string;
    oUserName: any;
    sDisplayDisconnectErrorMessage: string;
    bDisplayErrorMessage: boolean = false;
    sSessionId: string;
    sUserEmail: string;
    pages: any = [];
    nIncomingPage: any;
    oVideoDetails: any;
    sDisplayResponseMessage: string;
    bDisplaySuccessMessage: boolean = false;
    oSocialPlatformValue: any = '';
    return_url: string;

    socialMediaName: string;
    inCommingSocialMedia: string;
    disconnectMediaPopup = false;
    sVideoId = '';
    isBrandVideo = false;
    brandDetail: any;
    brandSocialNetworkConnection = {
        fb: false,
        linkedin: false,
        youtube: false,
        instagram: false,
    };
    statusResult = '';

    constructor(
        private oSessionService: cSessionService,
        private oFormBuilder: FormBuilder,
        private oClientService: cClientService,
        private oDataService: cDataService,
        private accountManager: AccountManagerService,
        private onboardingManger: OnboardingManagerService,
        public videoManager: VideoManagerService,
        public errorService: ErrorService,
        public brandService: BrandService,
        public loader: LoaderService,
    ) {}

    async ngOnInit() {
        this.sSessionId = this.oSessionService.SessionService_GetSessionId();
        this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();

        if (!this.onboardingManger.profile) {
            await this.onboardingManger.getUserProfile(this.sUserEmail, this.sSessionId).then((res) => {
                this.connected_social_platforms = this.onboardingManger.profile.connected_social_platforms;
            });
        } else {
            this.connected_social_platforms = this.onboardingManger.profile.connected_social_platforms;
        }

        console.log(' this.connected_social_platforms ', this.connected_social_platforms);
        if (!this.videoManager.selectedVideo) {
            let sVideoId = this.oSessionService.SessionService_GetVideoId();
            this.sVideoId = sVideoId;
            await this.videoManager.getVideoById(this.sUserEmail, this.sSessionId, sVideoId).then((res) => {
                this.oVideoDetails = res;
                this.videoManager.selectedVideo = res;
            });
        } else {
            this.sVideoId = this.videoManager.selectedVideo.fpath;
        }

        await this.loadVideoInformation(this.sVideoId);
        this.oVideoDetails = this.videoManager.selectedVideo;

        this.return_url = environment.WS_HOST + '/published-video/timeline-view?video_id=' + this.videoManager.selectedVideo.fpath;

        this.DisconnectSocialForm = this.oFormBuilder.group({
            platform: '',
        });
    }

    checkBrandFBConnected(): void {
        this.brandService.getFBAccessToken(this.videoManager.selectedVideo.brand_id).then((accessToken: any) => {
            if (accessToken.result) {
                this.brandSocialNetworkConnection.fb = true;
            } else {
                this.brandSocialNetworkConnection.fb = false;
            }
        });
    }
    checkBrandInstagramConnected(): void {
        this.brandService.getInstagramAccessToken(this.videoManager.selectedVideo.brand_id).then((accessToken: any) => {
            if (accessToken.result) {
                this.brandSocialNetworkConnection.instagram = true;
                this.connected_social_platforms.instagram = true;
            } else {
                this.brandSocialNetworkConnection.instagram = false;
                this.connected_social_platforms.instagram = false;
            }
        });
    }

    checkBrandLinkedInConnected(): void {
        this.brandService.getLinkInAccessToken(this.videoManager.selectedVideo.brand_id).then((accessToken: any) => {
            if (accessToken.result) {
                this.brandSocialNetworkConnection.linkedin = true;
                this.connected_social_platforms.linkedin = true;
            } else {
                this.brandSocialNetworkConnection.linkedin = false;
                this.connected_social_platforms.linkedin = false;
            }
        });
    }
    checkBrandYouTubeConnected(): void {
        this.brandService.getYouTubeAccessToken(this.videoManager.selectedVideo.brand_id).then((accessToken: any) => {
            if (accessToken.result) {
                this.brandSocialNetworkConnection.youtube = true;
            } else {
                this.brandSocialNetworkConnection.youtube = false;
            }
        });
    }
    async loadVideoInformation(videoId): Promise<any> {
        return new Promise(async (resolve, reject) => {
            this.sVideoId = videoId;
            await this.videoManager
                .getVideoById(this.sUserEmail, this.sSessionId, this.sVideoId)
                .then(async (res: any) => {
                    this.oVideoDetails = res;
                    this.videoManager.selectedVideo = res;

                    if (this.videoManager.selectedVideo.brand_id && this.onboardingManger.profile.plan == 'enterprise') {
                        console.log(' IS BRAND USER ');
                        this.isBrandVideo = true;
                        this.checkBrandFBConnected();
                        this.checkBrandYouTubeConnected();
                        this.checkBrandLinkedInConnected();
                        this.checkBrandInstagramConnected();
                    }
                    await this.videoManager.getVideoSignedURL(this.sUserEmail, this.sSessionId, res).then((data) => {
                        this.videoManager.selectedVideo.video_url = data.url;
                    });
                    if (this.videoManager.selectedVideo.brand_id) {
                        this.brandService.getBrandProfile(this.videoManager.selectedVideo.brand_id, true).then((profile) => {
                            this.brandDetail = profile;
                        });
                    }
                    setTimeout(() => {
                        this.statusResult = '';
                    }, 2000);
                    resolve(res);
                })
                .catch((err) => {
                    reject(undefined);
                });
        });
    }
    getSocialConnectionMessage(isConnected: boolean, network: string): string {
        let brandName = this.brandDetail?.name ? this.brandDetail.name : '';
        return this.oDataService.getSocialConnectionMessage(isConnected, network, this.oVideoDetails?.brand_id, brandName);
    }
    shareToInstagram() {
        if (this.brandSocialNetworkConnection.instagram) {
            this.loader.showLoader();

            this.bDisplayErrorMessage = false;
            console.log(this.videoManager.selectedVideo);
            const data = {
                video_id: this.videoManager.selectedVideo.fpath,
                message: this.videoManager.selectedVideo.title,
                brand_id: this.videoManager.selectedVideo.brand_id,
            };
            if (data.video_id) {
                this.sDisplayResponseMessage = '';
                this.brandService
                    .shareVideoOnInstagram(data)
                    .then((response: any) => {
                        this.bDisplaySuccessMessage = true;
                        this.sDisplayResponseMessage = response.result ? response.result : 'Instagram Brand video shared successfully';
                        this.loader.hideLoader();
                    })
                    .catch((err: any) => {
                        this.loader.hideLoader();
                        this.bDisplayErrorMessage = true;
                        this.sDisplayResponseMessage = err.error ? err.error : 'Error sharing video on Instagram.';
                    });
            } else {
                this.loader.hideLoader();
                this.bDisplayErrorMessage = true;
                this.sDisplayResponseMessage = 'Error sharing video on Instagram.';
            }
        }
    }
    shareToFacebook() {
        if (this.brandSocialNetworkConnection.fb) {
            this.loader.showLoader();

            this.bDisplayErrorMessage = false;
            const data = {
                video_url: this.videoManager.selectedVideo.video_url,
                message: this.videoManager.selectedVideo.title,
                brand_id: this.videoManager.selectedVideo.brand_id,
            };
            this.sDisplayResponseMessage = '';
            this.brandService
                .shareVideoOnFacebook(data)
                .then((response: any) => {
                    this.bDisplaySuccessMessage = true;
                    this.sDisplayResponseMessage = response.result ? response.result : 'Facebook Brand video shared successfully';
                    this.loader.hideLoader();
                })
                .catch((err: any) => {
                    this.loader.hideLoader();
                    this.bDisplayErrorMessage = true;
                    this.sDisplayResponseMessage = err.error ? err.error : 'Error sharing video on Facebook.';
                });
        }
    }

    shareToYoutube() {
        this.TimelineViewProntoShareComponent_ShareVideoOnSocialChannel('google');
        // if (this.brandSocialNetworkConnection.youtube) {
        //   this.loader.showLoader();

        //   this.bDisplayErrorMessage = false;
        //   const data = {
        //     video_url: this.videoManager.selectedVideo.video_url,
        //     message: "This is my video",
        //     brand_id: this.videoManager.selectedVideo.brand_id,
        //   };

        //   this.sDisplayResponseMessage = "";
        //   this.brandService
        //     .shareVideoOnYoutube(data)
        //     .then((response : any) => {
        //       this.bDisplaySuccessMessage = true;
        //       this.sDisplayResponseMessage = response.result ? response.result : 'Brand video shared successfully';
        //       this.loader.hideLoader();
        //     })
        //     .catch((err: any) => {
        //       this.loader.hideLoader();
        //       this.bDisplayErrorMessage = true;
        //       this.sDisplayResponseMessage = err.error
        //         ? err.error
        //         : "Error sharing video on Youtube.";
        //     });
        // }
    }

    TimelineViewProntoShareComponent_ShareVideoOnSocialChannel(value: any) {
        if (value == 'google' && this.connected_social_platforms.google) {
            $('#share-modal-Youtube').modal('show');
        } else if (value == 'facebook' && this.connected_social_platforms.facebook) {
            $('#share-modal').modal('show');
            this.pages = [];
            let params = new HttpParams();
            params = params.set('email', this.sUserEmail);
            params = params.set('session_id', this.sSessionId);
            let url = cDataService.faceBookPages + '?' + params.toString();
            try {
                this.oDataService.DataService_GetFacebookPages(url).subscribe(
                    (oResponse) => {
                        console.log('pages', oResponse.pages);
                        this.pages = oResponse.pages;
                        if (this.pages) {
                            this.nIncomingPage = this.pages[0];
                        }
                        console.log('CustomEditVideo_FacebookChange : Reponse ==> ', oResponse);
                    },
                    (error) => {
                        if (error.url) {
                            console.log('CustomEditVideo_FacebookChange : Error ==> ', error);
                            window.location.assign(error.url);
                        } else {
                            console.log('CustomEditVideo_FacebookChange : Error ==> ', error);
                        }
                    },
                );
            } catch (oError) {
                console.log('CustomEditVideo_FacebookInstaChange: exception:');
                console.log(oError);
            }
        } else if (value == 'linkedin' && this.connected_social_platforms.linkedin) {
            $('#share-modal-linkedin').modal('show');
        }
    }

    TimelineViewProntoShareComponent_SocialMediaChannelsLink(oData: any, event) {
        event.stopPropagation();
        let sURL: string;
        if (oData == 'google') {
            sURL = this.oDataService.googleJoinLink + '?' + 'email=' + this.sUserEmail + '&session_id=' + this.sSessionId;
        } else if (oData == 'facebook') {
            sURL = this.oDataService.faceBookJoinLink + '?' + 'email=' + this.sUserEmail + '&session_id=' + this.sSessionId;
        } else if (oData == 'linkedin') {
            sURL = this.oDataService.linkedInJoinLink + '?' + 'email=' + this.sUserEmail + '&session_id=' + this.sSessionId;
        }
        this.oSessionService.cSessionService_SetRedirectURL(4);
        this.oSessionService.cSessionService_SetVideoId(this.videoManager.selectedVideo.fpath);
        this.oSessionService.cSessionService_SetVideoUrl(this.videoManager.selectedVideo.video_url);
        location.assign(sURL);
    }

    TimelineViewProntoShareComponent_DisconnectSocialChannel(event) {
        this.disconnectMediaPopup = false;
        let value = this.inCommingSocialMedia;
        event.stopPropagation();
        this.accountManager
            .disconnectSocialPlatform(this.onboardingManger.loggedUser.email, this.onboardingManger.loggedUser.session_id, value)
            .then((res) => {
                switch (value) {
                    case 'google': {
                        this.onboardingManger.profile.connected_social_platforms.google = false;
                        break;
                    }
                    case 'facebook': {
                        this.onboardingManger.profile.connected_social_platforms.facebook = false;
                        break;
                    }
                    case 'linkedin': {
                        this.onboardingManger.profile.connected_social_platforms.linkedin = false;
                        break;
                    }
                    default:
                        break;
                }
            })
            .catch((err) => {
                this.errorService.handleError(err.errorMessage, err.errorType, true);
                this.sDisplayDisconnectErrorMessage = err['errorMessage'];
                this.bDisplayErrorMessage = true;
                setTimeout(() => {
                    this.bDisplayErrorMessage = false;
                }, 5000);
            });
    }

    DashboardSocialMediaComponent_SelectPageForShare(nIncomingPage) {
        this.nIncomingPage = nIncomingPage;
    }
    DashboardSocialMediaComponent_ShareVideo() {
        try {
            console.log('video to share' + this.oVideoDetails);
            let params = new HttpParams();
            params = params.set('email', this.sUserEmail);
            params = params.set('session_id', this.sSessionId);
            params = params.set('title', encodeURIComponent(this.oVideoDetails.title));
            params = params.set('return_url', encodeURIComponent(this.return_url));
            params = params.set('video_id', encodeURIComponent(this.oVideoDetails.fpath));

            params = params.set('description', encodeURIComponent(this.oVideoDetails.about));
            let pageId = this.nIncomingPage.id;
            let selectedPage: any;
            this.pages.forEach(function (value) {
                if (value.id == pageId) {
                    selectedPage = value;
                }
            });
            let reqBody = {
                page: {
                    access_token: selectedPage.access_token,
                    id: selectedPage.id,
                    name: selectedPage.name,
                },
                video_id: this.oVideoDetails.fpath,
                title: this.oVideoDetails.title,
                description: this.oVideoDetails.about,
            };
            let facebookInstaParams = new HttpParams();
            facebookInstaParams = params.set('email', this.sUserEmail);
            params = facebookInstaParams.set('session_id', this.sSessionId);

            console.log('step1', cDataService.faceBookUpload + '?' + facebookInstaParams.toString());
            let facebookUrl = cDataService.faceBookUpload + '?' + facebookInstaParams.toString();
            this.oClientService.ClientService_PostVideoToFacebookPage(facebookUrl, reqBody).subscribe(
                (oResponse) => {
                    console.log('response from facebook video upload ==>', oResponse);
                    this.bDisplaySuccessMessage = true;
                    this.oSessionService.bDisplaySharingSuccessMessage = true;
                    this.sDisplayResponseMessage = oResponse.message;
                    setTimeout(() => {
                        this.bDisplaySuccessMessage = false;
                    }, 5000);
                },
                (error) => {
                    this.oSessionService.bDisplaySharingErrorMessage = true;
                    this.bDisplayErrorMessage = true;
                    this.sDisplayResponseMessage = error.error.errorMessage;
                    setTimeout(() => {
                        this.bDisplayErrorMessage = false;
                    }, 5000);
                },
            );
        } catch (oError) {
            console.log('CustomEditVideo_PostVideo: exception:');
            console.log(oError);
            this.bDisplayErrorMessage = true;
            this.sDisplayResponseMessage = oError;
            setTimeout(() => {
                this.bDisplayErrorMessage = false;
            }, 5000);
        }
    }

    // Youtube Section
    DashboardSocialMediaComponent_ShareVideoOnYoutube() {
        const youTubeUploadBody = {
            category: '22',
        };
        let params = new HttpParams();
        let apiURL = cDataService.youTubeUpload;
        if (this.oVideoDetails.brand_id && this.onboardingManger.profile.plan == 'enterprise') {
            console.log('is brand clip');
            apiURL = cDataService.youTubeBrandUpload;
            params = params.set('brand_id', this.oVideoDetails.brand_id);
        }
        params = params.set('email', this.sUserEmail);
        params = params.set('session_id', this.sSessionId);
        params = params.set('title', encodeURIComponent(this.oVideoDetails.title));
        params = params.set('video_id', encodeURIComponent(this.oVideoDetails.fpath));
        params = params.set('return_url', encodeURIComponent(this.return_url));
        params = params.set('description', encodeURIComponent(this.oVideoDetails.about));
        let url = '';

        let keyTermCombination = this.oVideoDetails.key_terms.join(',');
        console.log('Incoming Key Terms ==>>>>', keyTermCombination);
        params = params.set('category', encodeURIComponent(youTubeUploadBody.category));
        params = params.set('keywords', encodeURIComponent(keyTermCombination));
        params = params.set('privacyStatus', encodeURIComponent('public'));
        url = apiURL + '?' + params.toString();
        console.log('YouTube URl====>', url);

        this.oSessionService.cSessionService_SetRedirectURL(4);
        window.open(url, '_self');
    }
    // Linkedin Section
    DashboardSocialMediaComponent_ShareVideoOnLinkedIn() {
        let params = new HttpParams();
        params = params.set('email', this.sUserEmail);
        params = params.set('session_id', this.sSessionId);
        params = params.set('title', encodeURIComponent(this.oVideoDetails.title));
        params = params.set('return_url', encodeURIComponent(this.return_url));
        params = params.set('video_id', encodeURIComponent(this.oVideoDetails.fpath));
        params = params.set('description', encodeURIComponent(this.oVideoDetails.about));
        let url = '';

        params = params.set('privacyStatus', encodeURIComponent('public'));
        url = cDataService.LinkedInUpload + '?' + params.toString();
        if (this.isBrandVideo && this.connected_social_platforms.linkedin) {
            params = params.set('brand_id', this.oVideoDetails.brand_id);
            url = cDataService.linkedInBrandUpload + '?' + params.toString();
        }

        console.log('LinkedIn URL : ==> ', url);

        this.oSessionService.cSessionService_SetRedirectURL(4);
        window.open(url);
    }

    isDisconnectModal(data: string): void {
        this.disconnectMediaPopup = true;
        if (data == 'google') {
            this.socialMediaName = 'Youtube';
        } else {
            this.socialMediaName = data;
        }
        this.inCommingSocialMedia = data;
    }

    closeActivateModal(): void {
        this.disconnectMediaPopup = false;
    }
}
