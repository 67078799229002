<!-- <app-header></app-header>
<section class="accountSec main-body bg-gray-7 h-100">
  <div class="container d-flex flex-column h-100">
    <ul class="breadcrumb my-3">
      <li><a href="#">Manage Account</a></li>
      <li>Manage Brand</li>
    </ul>
    <div
      class="accountSecInner br12 bg-white page-shadow h-82vh overflow-hidden"
      id="row-main"
    >
      <app-account-sidebar
        class="col-md-3 pe-0 pb-0 border-end position-relative sidebar d-flex flex-column h-100"
        id="sidebar-left"
      ></app-account-sidebar>
      <div class="col-md-9 ps-0 d-flex flex-column h-100" id="content"> -->

<div class="video-header row m-0 ms-2 px-4 pt-4 ps-0">
    <p class="h3 weight-600 text-gray-2 pb-1 px-0 col-lg-8 mb-0">
        <a href="javascript:void(0)" routerLink="/account-setting/manage-brand" class="me-2">
            <img src="../../../../../assets/images/svg/back-arrow-with-tail.svg" />
        </a>
        {{ this.brandDetail.name && this.brandDetail.name != '' ? this.brandDetail.name : 'Business Name' }}
    </p>
</div>
<form [formGroup]="updateForm">
    <div class="container ps-0 mx-0 ms-3 business-media" id="container-view">
        <div class="row filter-post account-heading">
            <div class="col-md-6 p-0">
                <ul class="breadcrumb-tabs nav m-0">
                    <!-- <li class="nav-item ps-0">
            <a
              href="javascript:void(0)"
              (click)="loadBrandAccount()"
              class="nav-link"
              >Account</a
            >
          </li> -->
                    <li class="nav-item">
                        <a (click)="loadBrandBusiness()" href="javascript:void(0)" class="nav-link active">Business
                            Details</a>
                    </li>
                    <li class="nav-item">
                        <a [routerLink]="['/account-setting/manage-brand/', brandId, 'published-videos']"
                            class="nav-link">Published Video</a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" (click)="loadBrandMediaLibraryList()" class="nav-link">Media
                            Libraries</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="px-3 flex-grow-1">
        <div class="" id="container-view">
            <form [formGroup]="updateForm">
                <div class="row p-0 m-0">
                    <div class="col-md-12">
                        <p class="text-lg text-gray-2 px-0 mx-0 my-3 pb-2"></p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="formItm">
                            <input id="Website-Name" type="text" [disabled]="!this.isEdit" *ngIf="!this.isEdit"
                                value="{{ this.brandDetail.website ? this.brandDetail.website : 'website Name' }}"
                                placeholder="lakme.in/fashion" name="textfeild" required />

                            <input id="Website-Name" type="text" formControlName="website" *ngIf="this.isEdit"
                                placeholder="lakme.in/fashion" name="textfeild" required />
                            <label for="Website-Name" class="text-sm">Website</label>
                            <span *ngIf="
                                    updateForm?.controls['website']?.hasError('required') &&
                                    this.isEdit &&
                                    (updateForm?.controls['website']?.touched || updateFormSubmitted)
                                " class="error">
                                Please enter website.</span>
                        </div>
                        <div class="business-logo mb-2 mt-4">
                            <label class="d-block text-md text-gray-2 pb-3">Brand Logo</label>
                            <figure class="brandFigure" *ngIf="displayBrandLogo">
                                <!-- [ngStyle]="{'background-image': 'url(' + brandLogoPath + ')'}"  -->
                                <img *ngIf="brandLogoPath && brandLogoPath != ''" src="{{ brandLogoPath }}"
                                    width="140px" height="70px" />
                                <img *ngIf="!brandLogoPath || brandLogoPath == ''"
                                    src="../assets/images/business_Profile/dummy-logo.png" alt="dummy" />
                            </figure>
                            <div *ngIf="!brandLogoPath || brandLogoPath == ''" class="logo-not-found">
                                <div class="content">No logo <br />available</div>
                            </div>
                            <p *ngIf="!brandLogoPath || brandLogoPath == ''"
                                class="text-sm mt-1 d-block text-primary-4 d-inline-block">
                                Your admin has not uploaded a logo yet. Please reach out to them.
                            </p>
                        </div>
                        <!-- <div class=" mb-2 mt-4">

                <label class="d-block text-lg text-gray-2 pb-2">Brand Font</label>
            </div> -->
                    </div>
                    <div class="col-md-6 ps-5">
                        <ul class="selct_business">
                            <p class="text-lg text-gray-2 px-0 mx-0 pt-2 mb-3">Industry</p>
                            <p>
                                <a href="javascripr:void(0)" class="">
                                    <figure class="bg-primary-4 px-auto py-auto"
                                        style="width: 52px; height: 46px; padding-left: 12px; padding-top: 8px; border-radius: 4px">
                                        <img *ngIf="!this.brandDetail?.industry_icon"
                                            src="../../../../../assets/images/designtools.png" />
                                        <img class="industry-icon" *ngIf="this.brandDetail?.industry_icon"
                                            [src]="this.brandDetail?.industry_icon" />
                                    </figure>
                                    <span *ngIf="!this.isEdit" class="text-center text-xs text-gray-2">{{
                                        this.brandDetail.industry ? this.brandDetail.industry : 'Industry Name'
                                        }}</span>
                                </a>
                            </p>
                            <div class="formItm" *ngIf="this.isEdit">
                                <input id="industry-Name" type="text" formControlName="industry" name="textfeild"
                                    required placeholder="industry" />
                                <label for="industry-Name" class="text-sm">Industry Name</label>

                                <span *ngIf="
                                        updateForm?.controls['industry']?.hasError('required') &&
                                        this.isEdit &&
                                        (updateForm?.controls['industry']?.touched || updateFormSubmitted)
                                    " class="error">
                                    Please enter industry.</span>
                            </div>
                        </ul>
                        <div class="theme-color mt-4">
                            <label class="d-block text-md text-gray-2 pb-2">Brand Colors</label>
                            <div class="d-flex">
                                <ul class="m-0 ps-0 pe-4">
                                    <label class="d-block text-md text-gray-2 pb-2">Theme Color</label>

                                    <li aria-readonly="true">
                                        <div class="clr-field"
                                            [style.color]="this.primaryColor ? this.primaryColor : fontDefaultColor">
                                            <button aria-labelledby="clr-open-label"></button>
                                            <input class="form-check-input primary-color" type="checkbox"
                                                id="checkboxNoLabel"
                                                [value]="this.primaryColor ? this.primaryColor : fontDefaultColor" />
                                            <figure class="color-pic no-bg">
                                                <img [ngClass]="{
                                                        white:
                                                            this.primaryColor != '#ffffff' &&
                                                            this.primaryColor != '#FFFFFF' &&
                                                            this.primaryColor != undefined
                                                    }" class="shadow-svg"
                                                    src="../../../../../assets/images/icons/check-icon.svg" />
                                            </figure>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="ms-2">
                                    <label class="d-block text-md pb-2">Font Colors </label>
                                    <li class="pe-2" *ngFor="let colorSeconds of secondary_colors">
                                        <div class="clr-field" style="height: 32px; width: 32px"
                                            [style.color]="colorSeconds.color ? colorSeconds.color : videoManager.fontDefaultColor">
                                            <button _ngcontent-hce-c315="" aria-labelledby="clr-open-label"></button>
                                            <input class="form-check-input m-1" type="checkbox" id="checkboxNoLabel"
                                                [value]="colorSeconds.color ? colorSeconds.color : fontDefaultColor"
                                                aria-label="..." aria-label="..." />
                                            <figure class="color-pic">
                                                <img [ngClass]="{
                                                        white:
                                                            colorSeconds.color != '#ffffff' &&
                                                            colorSeconds.color != '#FFFFFF' &&
                                                            colorSeconds.color != undefined
                                                    }" src="../../../../../assets/images/icons/check-icon.svg" />
                                            </figure>
                                        </div>
                                    </li>

                                    <!-- <li>
                    <input class="form-check-input" type="checkbox" id="checkboxNoLabel"
                    [value]=" colorSeconds ? colorSeconds : fontDefaultColor " aria-label="..." />
                    <figure class="color-pic bg-gray-6">
                      <img src="../../../../../assets/images/icons/check-icon.svg">
                    </figure>
                  </li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <label class="d-block text-md text-gray-2 pb-3">Brand Fonts</label>
                    <div class="col-md-6" *ngIf="this.fontArray.length > 0">
                        <div class="font-box" *ngFor="let font of fontArray"
                            style=" font-family:'{{ fontStyle(font) }}'"
                            [ngClass]="{ 'active-font': this.selectedFont == font }">
                            {{ font | fontNameTruncate : 55 }}
                        </div>
                    </div>

                    <div *ngIf="!this.fontArray || this.fontArray.length == 0" class="col-md-6 font-not-found">
                        <p class="content">NO FONT CHOOSEN</p>
                    </div>
                    <p *ngIf="!this.fontArray || this.fontArray.length == 0"
                        class="text-sm mt-1 d-block text-primary-4 d-inline-block">
                        Font not set for this brand. Contact admin.
                    </p>
                </div>
                <div class="row p-0 m-0 mt-3 mb-1">
                    <div class="col-sm-12 col-md-12 col-lg-12 ps-0">
                        <p *ngIf="brandSocialNetworkConnection.fb || brandSocialNetworkConnection.youtube"
                            class="text-lg text-gray-2 pt-3 m-0">
                            Connected Social Account
                        </p>
                        <p *ngIf="brandSocialNetworkConnection.fb || brandSocialNetworkConnection.youtube"
                            class="text-xs text-gray-3 py-1">
                            Services that you use to Connected with social Account
                        </p>

                        <div class="row mt-4 accountSec">
                            <div class="col-md-5" *ngIf="brandSocialNetworkConnection.fb">
                                <div class="social-channels-hover mb-2">
                                    <div class="d-flex select-channels">
                                        <img *ngIf="brandSocialNetworkConnection.fb" width="40px"
                                            src="../assets/images/dashboardVideo/Facebook.png" alt="sc" />
                                        <p class="px-3" *ngIf="brandSocialNetworkConnection.fb">
                                            <b class="d-block">Facebook
                                                <img *ngIf="brandSocialNetworkConnection.fb" width="12px" height="12px"
                                                    src="../assets/images/dashboardVideo/verifyCheck.png"
                                                    alt="sc" /></b>
                                            Your Facebook account is connected to
                                            <a href="javascript:void(0)" class="text-primary-1">
                                                {{
                                                this.brandDetail.name && this.brandDetail.name != ''
                                                ? this.brandDetail.name
                                                : 'Business Name'
                                                }}</a>
                                        </p>
                                        <p class="px-3 text-danger d-none" *ngIf="!brandSocialNetworkConnection.fb">
                                            <b class="d-block">Facebook </b>
                                            Brand Facebook account is not connected.
                                            <a href="javascript:void(0)" class="text-primary-1">
                                                {{
                                                this.brandDetail.name && this.brandDetail.name != ''
                                                ? this.brandDetail.name
                                                : 'Business Name'
                                                }}</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5" *ngIf="brandSocialNetworkConnection.youtube">
                                <div class="social-channels-hover mb-2">
                                    <div class="d-flex select-channels social-channels-hover col-lg-12">
                                        <img *ngIf="brandSocialNetworkConnection.youtube" width="40" height="40"
                                            src="../assets/images/dashboardVideo/Youtube.png" />
                                        <p class="px-3">
                                            <b *ngIf="brandSocialNetworkConnection.youtube" class="d-block">Youtube
                                                <img width="12px" height="12px"
                                                    *ngIf="brandSocialNetworkConnection.youtube"
                                                    src="../assets/images/dashboardVideo/verifyCheck.png"
                                                    alt="sc" /></b>
                                            <span class="text-xs text-gray-3 "
                                                *ngIf="brandSocialNetworkConnection.youtube">Your Youtube account is
                                                connected to
                                                <a href="javascript:void(0)" class="text-primary-1">
                                                    {{ this.brandDetail.name ? this.brandDetail.name : 'Brand Name'
                                                    }}</a>
                                            </span>
                                            <span class="text-xs d-block text-danger d-none"
                                                *ngIf="!brandSocialNetworkConnection.youtube">Your Youtube account is
                                                not connected to <a href="javascript:void(0)" class="text-primary-1">
                                                    {{ this.brandDetail.name ? this.brandDetail.name : 'Brand Name'
                                                    }}</a>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5" *ngIf="brandSocialNetworkConnection.instagram">
                                <div class="social-channels-hover mb-2">
                                    <div class="d-flex select-channels">
                                        <img width="40px" height="40px"
                                            src="../assets/images/dashboardVideo/Instagram.png" alt="sc" />
                                        <p class="px-3">
                                            <b class="d-block">Instagram<img width="12px" height="12px"
                                                    src="../assets/images/dashboardVideo/verifyCheck.png"
                                                    alt="sc" /></b>
                                            Your Instagram account is connected to {{ this.brandDetail.name ?
                                            this.brandDetail.name : 'Brand Name' }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5" *ngIf="brandSocialNetworkConnection.linkedin">
                                <div class="social-channels-hover mb-2">
                                    <div class="d-flex select-channels">
                                        <img *ngIf="brandSocialNetworkConnection.linkedin" width="40px"
                                            src="../assets/images/icons/linkedin.png" alt="sc" />
                                        <p class="px-3" *ngIf="brandSocialNetworkConnection.linkedin">
                                            <b class="d-block">LinkedIn
                                                <img *ngIf="brandSocialNetworkConnection.linkedin" width="12px"
                                                    height="12px" src="../assets/images/dashboardVideo/verifyCheck.png"
                                                    alt="sc" /></b>
                                            Your LinkedIn account is connected to
                                            <a href="javascript:void(0)" class="text-primary-1">
                                                {{
                                                this.brandDetail.name && this.brandDetail.name != ''
                                                ? this.brandDetail.name
                                                : 'Business Name'
                                                }}</a>
                                        </p>
                                        <p class="px-3 text-danger d-none"
                                            *ngIf="!brandSocialNetworkConnection.linkedin">
                                            <b class="d-block">LinkedIn </b>
                                            Brand LinkedIn account is not connected.
                                            <a href="javascript:void(0)" class="text-primary-1">
                                                {{
                                                this.brandDetail.name && this.brandDetail.name != ''
                                                ? this.brandDetail.name
                                                : 'Business Name'
                                                }}</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="row p-0 mx-0 my-3 pt-3 border-top">
                <div class="col-md-12"></div>
            </div>
        </div>
    </div>
</form>