<div class="py-4 px-2 account-heading">
    <div class="row">
        <div class="col-md-8">
            <h3 class="weight-700 text-gray-2">Account Overview</h3>
            <p class="mx-0 my-0 text-sm">Keep your business information up to date for the best results.</p>
        </div>
        <div class="col-md-4 text-end">
            <a *ngIf="bEditButton" href="javascript:void(0)"
                class="weight-normal btn btn-secondary btn-round-6 px-4 py-3"
                (click)="MyAccountComponent_EditForm()">Edit</a>
            <a *ngIf="bDisabledButtons" class="text-gray-3 text-sm me-4" href="javascript:void(0)"
                (click)="MyAccountComponent_ResetView()">Cancel Edits</a>
            <a *ngIf="bDisabledButtons" href="javascript:void(0)"
                class="weight-normal btn btn-secondary btn-round-6 px-4 py-3 "
                (click)="MyAccountComponent_SendProfileUpdateRequest()">Save Changes</a>
        </div>
    </div>
</div>



<div class="accountSecInner flex-grow-1">
    <div class="accountSecContent py-5">
        <div class="row row-cols-md-2 g-0 justify-content-between">
            <!-- Alert for Disconnect Social channel Error Message start -->
            <div *ngIf="bDisplayErrorMessage"
                class=" alert alert-danger d-flex align-items-center alert-dismissible fade show" role="alert">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M27.5683 21.8754L16.8998 3.08014C15.6241 0.839822 12.3899 0.839822 11.1141 3.08014L0.445544 21.8754C-0.830163 24.0854 0.788172 26.8545 3.33834 26.8545H24.6437C27.2254 26.8545 28.8121 24.1169 27.568 21.8754H27.5683ZM14.0387 7.56066C15.0337 7.56066 15.5318 8.46261 15.5318 10.2994C15.5318 11.2949 15.4079 12.6649 15.1273 14.4385C14.8478 16.2121 14.6607 17.3012 14.4736 17.6441C14.3497 17.8933 14.2245 18.0173 14.0387 18.0173C13.8212 18.0173 13.6657 17.9237 13.5721 17.7061C13.4166 17.4265 13.1991 16.4297 12.9185 14.7182C12.639 13.0066 12.5139 11.5126 12.4835 10.3298C12.4835 8.46383 13.012 7.56066 14.0387 7.56066V7.56066ZM15.1273 22.0627C14.8162 22.3739 14.4432 22.5295 14.0071 22.5295C13.5721 22.5295 13.1979 22.3739 12.8869 22.0627C12.5758 21.7516 12.4203 21.3784 12.4203 20.942C12.4203 20.5068 12.5758 20.1324 12.8869 19.8212C13.1979 19.51 13.5709 19.3544 14.0071 19.3544C14.4116 19.3544 14.7846 19.51 15.1273 19.8528C15.4699 20.1956 15.6254 20.5688 15.6254 20.9736C15.6242 21.3784 15.4383 21.7516 15.1273 22.0627Z"
                        fill="#842029" />
                </svg>
                <div>
                    {{sDisplayDisconnectErrorMessage}}
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"><svg width="16"
                            height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.546609 15.7333C0.406624 15.7333 0.273304 15.68 0.166649 15.58C-0.0399958 15.3733 -0.0399958 15.0333 0.166649 14.8267L7.11925 7.86667L0.153317 0.906667C0.0533277 0.806667 0 0.673333 0 0.533333C0 0.393333 0.0533277 0.26 0.153317 0.16C0.259972 0.0533333 0.393292 0 0.533277 0C0.673262 0 0.806581 0.0533333 0.913237 0.153333L7.8725 7.11333L14.8251 0.153333C14.9251 0.0533333 15.0584 0 15.1984 0C15.3384 0 15.4717 0.0533333 15.5784 0.153333C15.785 0.36 15.785 0.7 15.5784 0.906667L8.62575 7.86667L15.5717 14.8133C15.6717 14.9133 15.725 15.0467 15.725 15.1867C15.725 15.3267 15.6717 15.46 15.5717 15.5667C15.4717 15.6733 15.3384 15.72 15.1917 15.72C15.0451 15.72 14.9184 15.6667 14.8118 15.5667L7.8725 8.62L0.919903 15.58C0.819913 15.68 0.686594 15.7333 0.546609 15.7333Z"
                                fill="#FF8369" />
                        </svg>
                    </button>
                </div>
            </div>
            <!-- Alert for Disconnect Social channel Error Message End -->
            <div class="loginSecItmForm pt-0" aria-labelledby="loginYourself">
                <p class="text-lg text-gray-2 pb-1">Account Overview</p>
                <form [formGroup]="fUserProfileForm">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="right-sde-info">

                                <div *ngIf="userLogoPath == undefined || userLogoPath == ''" class="logo-bg">
                                    <div><mat-icon class="person">person</mat-icon>
                                        <mat-icon class="upload-new" (click)="file.click()">camera_alt</mat-icon>
                                    </div>
                                    <div class="upload-img"> <button class="upload-file" #file
                                            (click)="uploadUserLogo()" ></button></div>
                                </div>
                                <div class="logo-bg" *ngIf="userLogoPath != undefined && userLogoPath != ''"
                                    [ngStyle]="{'background-image': 'url(&quot;' +  userLogoPath + '&quot;)'}">
                                    <div class="main-mat"><mat-icon class="upload-new"
                                            (click)="file.click()">edit</mat-icon></div>
                                    <div class="upload-img"> <button class="upload-file" #file
                                            (click)="uploadUserLogo()" ></button></div>
                                </div>
                                <p *ngIf="userLogoPath != undefined && userLogoPath != ''"
                                    class="text-danger mt-2 ms-4"> <a (click)="deleteProfilePicModal()">
                                        <img src="../../../../assets/images/Delete.svg" width="18px" height="16px">
                                        Remove</a></p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="formItm">
                                <input id="companyName" type="text" placeholder="John Smith" formControlName="name"
                                    [readonly]="!bDisabledButtons" />
                                <label for="companyName" class="text-sm">Name</label>
                                <span *ngIf="
                                                        fUserProfileForm.controls['name'].hasError('required') &&
                                                        fUserProfileForm.controls['name'].touched
                                                      " class="error">Enter a name.</span>
                            </div>
                            <div class="formItm">
                                <input id="email" type="email" formControlName="email" placeholder="john@example.com"
                                    required readonly />
                                <label for="email" class="text-sm">Email Address</label>
                            </div>
                        </div>
                        <div class="col-md-4 ms-5">
                            <p class="text-lg text-gray-2 pb-0 mb-2">
                                Email Preferences
                            </p>
                            <p *ngIf="bDisplaySubscriptionButton" class="text-xs text-gray-3 pb-1">
                                You're currently subscribed to our email list.
                            </p>
                            <p *ngIf="!bDisplaySubscriptionButton" class="text-xs text-gray-3 pb-1">
                                Subscribe to our email list to get product updates, tips,
                                and deals.
                            </p>

                            <div class="form-check mb-2 tableCustom">
                                <div class="tableCheck">
                                    <input *ngIf="fUserProfileForm?.controls['get_pronto_newsletter'].value == true"
                                        checked="checked" (change)="updateProfile()"
                                        class="form-check-input shadow-none" type="checkbox"
                                        formControlName="get_pronto_newsletter">
                                    <input *ngIf="!fUserProfileForm?.controls['get_pronto_newsletter'].value"
                                        (change)="updateProfile()" class="form-check-input shadow-none" type="checkbox"
                                        formControlName="get_pronto_newsletter">
                                </div>
                                <label class="form-check-label text-sm text-gray-1 mt-1 ms-2" for="flexCheckDefault">
                                    Prontopia Newsletter
                                </label>
                            </div>
                            <button class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 mt-3"
                                [disabled]="!bDisabledButtons" (click)="toggleSubscription()">
                                {{
                                this.onboardingManger.profile?.is_subscribed_to_email ==
                                0
                                ? "Subscribe"
                                : "Unsubscribe"
                                }}
                            </button>
                        </div>
                    </div>
                    <!-- <div class="row">
                                                <div class="col-md-5">
                                                    <div class="formItm">
                                                        <input id="companyName" type="text"
                                                            placeholder="John Smith" formControlName="name" [readonly]="!bDisabledButtons">
                                                        <label for="companyName" class="text-sm">Name</label>
                                                        <span *ngIf="fUserProfileForm.controls['name'].hasError('required') && fUserProfileForm.controls['name'].touched" class="error">Enter a name.</span>
                                                    </div>
                                                </div>
                                                <div class="col-md-5">
                                                    <div class="formItm">
                                                        <input id="email" type="email" formControlName="email"
                                                            placeholder="john@example.com"  required readonly>
                                                        <label for="email" class="text-sm">Email Address</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-10 px-4">
                                                    <div class="row mt-4 ac-overview">
                                                        <div class="col-md-10 px-0">
                                                            <p class="text-lg text-gray-2 pb-0 mb-2">Email Preferences</p>
                                                            <p *ngIf="bDisplaySubscriptionButton" class="text-xs text-gray-3 pb-1">You're currently subscribed to our email list.</p>
                                                            <p *ngIf="!bDisplaySubscriptionButton" class="text-xs text-gray-3 pb-1">Subscribe to our email list to get product updates, tips, and deals.</p>
                                                        </div>
                                                        <div class="col-md-2 text-end px-0 pe-4"  >
                                                            <button class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2" [disabled]="!bDisabledButtons" (click)="toggleSubscription()">{{ this.onboardingManger.profile?.is_subscribed_to_email == 1 ? 'Unsubscribe': 'Subscribe'}}</button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div> -->
                </form>
                <div class="row mt-4 pt-3">
                    <div class="col-md-10">
                        <p class="text-lg text-gray-2 pb-0 mb-2">Sign in with Social Accounts</p>
                        <p class="text-xs text-gray-3 pb-1">Connect your social accounts to sign in to Prontopia</p>
                    </div>
                    <div class="col-md-5">
                        <div class=" social-channels-hover social-box">
                            <img src="../assets/images/accountSetting/Facebook.svg" alt="connect-facebook"
                                class="connect-facebook" />
                            <p class="text-md text-gray-3 mb-0">
                                Facebook
                                <span class="text-xs text-primary-1 d-block">{{ this.onboardingManger.profile?.name
                                    }}</span>
                            </p>
                            <a *ngIf="!this.onboardingManger.profile?.connected_social_platforms.facebook"
                                href="javascript:void(0)" (click)="MyAccountComponent_ConnectSocialChannels('facebook')"
                                class="btn btn-danger weight-600 text-xs btn-outline-gray-radius text-white  mt-1 ml-1">Connect
                                with Facebook</a>
                            <a *ngIf="this.onboardingManger.profile?.connected_social_platforms.facebook"
                                href="javascript:void(0)"
                                (click)="MyAccountComponent_DisconnectSocialChannel('facebook')"
                                class="btn btn-white weight-600 text-xs btn-outline-gray-radius text-gray-2 mt-1">Disconnect</a>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class=" social-channels-hover social-box">
                            <img src="../assets/images/onBoarding/Google.svg" alt="Google" class="connect-facebook"
                                width="30" />
                            <p class="text-md text-gray-3 mb-0">
                                Google
                                <span
                                    class="text-xs text-primary-1 d-block">{{this.onboardingManger.profile?.name}}</span>
                            </p>
                            <a *ngIf="!this.onboardingManger.profile?.connected_social_platforms.google"
                                href="javascript:void(0)" (click)="MyAccountComponent_ConnectSocialChannels('google')"
                                class="btn btn-danger weight-600 text-xs btn-outline-gray-radius text-white mt-1 ml-1">Connect
                                with Google</a>
                            <a *ngIf="this.onboardingManger.profile?.connected_social_platforms.google"
                                href="javascript:void(0)" (click)="MyAccountComponent_DisconnectSocialChannel('google')"
                                class="btn btn-white weight-600 text-xs btn-outline-gray-radius text-gray-2 mt-1">Disconnect</a>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class=" social-channels-hover social-box mt-3">
                            <img src="../assets/images/onBoarding/Linkedin.svg" alt="Linkedin" width="30" />
                            <p class="text-md text-gray-3 mb-0">
                                LinkedIn
                                <span
                                    class="text-xs text-primary-1 d-block">{{this.onboardingManger.profile?.name}}</span>
                            </p>
                            <a *ngIf="!this.onboardingManger.profile?.connected_social_platforms.linkedin"
                                href="javascript:void(0)" (click)="MyAccountComponent_ConnectSocialChannels('linkedin')"
                                class="btn btn-danger weight-600 text-xs btn-outline-gray-radius text-white mt-1 ml-1">Connect
                                with LinkedIn</a>
                            <a *ngIf="this.onboardingManger.profile?.connected_social_platforms.linkedin"
                                href="javascript:void(0)"
                                (click)="MyAccountComponent_DisconnectSocialChannel('linkedin')"
                                class="btn btn-white weight-600 text-xs btn-outline-gray-radius text-gray-2 mt-1">Disconnect</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<!-- </div> -->




<!-- Disconnect Channel Modal -->
<!-- <div class="modal fade" id="disconnect" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Are you sure want to disconnect {{oSocialChannel | uppercase}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body text-lg">
                    <form [formGroup]="DisconnectSocialForm">
                        <input class="form-Control disconnect-form" type="text" readonly formControlName="platform">
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-primary rounded btn-sm-same-pad" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary rounded btn-sm-same-pad" data-bs-dismiss="modal" (click)="MyAccountComponent_DisconnectSocialChannel()">Disconnect</button>
                </div>
            </div>
        </div>
    </div> -->
<!-- Modal pop-up Start-->
<div class="modal fade" id="subscription-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-body">
                <button type="button" class="btn-close cust-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <h5>Are you sure you want to unsubscribe from our emails?</h5>
                <p class="text-sm text-gray-3 pt-1 pb-4">You'll no longer be sent email updates from Pronto, including
                    product releases, deal reminders, and feature tips.</p>
                <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" data-bs-dismiss="modal">Cancel</a>
                <a href="javascript:void(0)" id="sidebarCollapse" class="mt-3 text-gray-3 text-sm"
                    data-bs-dismiss="modal"
                    (click)="this.onboardingManger.profile.is_subscribed_to_email = 0">Unsubscribe</a>
            </div>
        </div>
    </div>
</div>
<!-- Modal pop-up End-->

<!-- delete profile pic Modal pop-up Start-->
<div class="modal fade" id="DeleteProfilepic-popup" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="btn-close cust-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <h5>Delete profile picture</h5>
                <p class="text-sm text-gray-3 pt-1 pb-4">
                    Are you sure you want to delete your profile picture?
                </p>
                <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" data-bs-dismiss="modal"
                    (click)="deleteProfilePic()">Delete</a>
                <a href="javascript:void(0)" id="sidebarCollapse" class="mt-3 text-gray-3 text-sm"
                    data-bs-dismiss="modal">Cancel</a>
            </div>
        </div>
    </div>
</div>
<!--delete profile pic Modal pop-up End-->