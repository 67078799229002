import { ProntoSettingService } from 'src/app/services/utils/pronto-setting.service';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreateVideoManagerService } from './../../Managers/create-video-manager.service';
import { MediaManagerService } from './../../Managers/media-manager.service';
import { OnboardingManagerService } from './../../Managers/onboarding-manager.service';
import { mediaFile, visual } from './../../models/media';
import { video } from './../../models/video';
import { cDataService } from './../../services/data.service';
import { cEditVideoService } from './../../services/edit-video.service';
import { cSessionService } from './../../services/session.service';
import { ErrorService } from 'src/app/services/error.service';
import { VideoService } from 'src/app/services/dataServices/video.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UploadFileDialogComponent } from '../components/upload-file-dialog/upload-file-dialog.component';
import { ConfigManagerService } from 'src/app/Managers/config-manager.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MediaService } from 'src/app/services/dataServices/media.service';
import { address } from 'src/app/models/common';
@Component({
    selector: 'app-video-sidepanel',
    templateUrl: './video-sidepanel.component.html',
    styleUrls: ['./video-sidepanel.component.scss'],
})
export class VideoSidepanelComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    @Output() onClose = new EventEmitter<any>();
    @Output() updateVideoSettings = new EventEmitter<any>();
    @ViewChild('closeModal') closeModal: ElementRef;
    @ViewChild('AddInputTagElement') AddInputTagElement: ElementRef;
    @ViewChild('myVideoO') myVideoO: ElementRef;
    @ViewChild('formOtherSettings') formOtherSettings: ElementRef;
    @Input() video;
    videoThumbUrl: any;
    oVideo: any;
    // oUploadedLogo: any;
    nUploadedLogoSize: number = 0;
    sUploadedLogoName: string = 'No file choosen.';
    sSessionID: string = '';
    sUserEmail: string = '';
    fLooknFeelForm: FormGroup;
    bDisplayUploadLogoSection: boolean = true;
    bRevertSettings: boolean = true;
    sDisplayResponseErrorMessage: string;
    bDisplayErrorMessage: boolean = false;
    figActive: boolean = true;
    showLogoUploadModal = false;
    oPrimaryColor = '';
    videoThemeColor = undefined;
    oFontColor = undefined;
    primaryColor = undefined;
    brand_id: string;
    isSapVideo = false;
    fOtherSettingForm: FormGroup;
    secondaryColor = [
        { index: 1, color: '#ffffff' },
        { index: 2, color: undefined },
    ];
    bDisplayBrandUploadLogoSection = false;
    currentUploadedLogoSection = 'own';
    brandUploadedSecondLogo: any;
    sUploadedSecondLogoName: any;
    showBrandLogoSelectionPopup: boolean = false;
    bSecondLogo: any;
    nUploadedSecondLogoSize: any;
    oUploadedSecondLogo: any;
    oLogoDetails: any;
    defaultFonts = [];
    otherFontArray = [];
    selectedVideoFontFile = '';
    brandFonts = [];
    defaultVideoColor = '';
    isFontSelected = false;
    internalFontsStyleSheet = '';
    selectedLogo = 'own';
    reSelectedVideoLogo: any;
    otherSettings = {
        urlToggle: false,
        addressToggle: false,
        phoneToggle: false,
    };
    lKey_terms: any = [];
    bDisplayAddInputField: boolean = false;
    inputValue: string = '';
    applyPrimaryLogoAtVideoStart = true;
    public sapVideoTagLine: string = '';
    videoMainLogo: any;
    allowedTemplate = true;
    constructor(
        public videoManager: CreateVideoManagerService,
        private oEditVideoService: cEditVideoService,
        private onBoardingManager: OnboardingManagerService,
        private oDataService: cDataService,
        public oSessionService: cSessionService,
        public videoService: VideoService,
        private mediaManager: MediaManagerService,
        private errorMessage: ErrorService,
        private dialog: MatDialog,
        private configManager: ConfigManagerService,
        private cd: ChangeDetectorRef,
        private mediaService: MediaService,
        private oFormBuilder: FormBuilder,
        public prontoSetting: ProntoSettingService,
    ) {}

    async ngOnInit() {
        console.log(' -- Load Video Data ..... ', this.videoManager.selectedVideo);
        this.sSessionID = this.oSessionService.SessionService_GetSessionId();
        this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
        this.initOtherSettingFormData();
        // if(this.videoManager?.selectedVideo?.logo_img ){
        //     this.oSessionService.oUploadedLogo =
        //     await this.oEditVideoService.EditVideoService_ReturnFileDetails(
        //         this.videoManager?.selectedVideo?.logo_img
        //     );
        // }
        if (this.videoManager.selectedVideo.font_file) {
            this.selectedVideoFontFile = this.videoManager.selectedVideo.font_file;
        }
        if (
            this.videoManager.selectedVideo.template_name == 'Soleil' ||
            this.videoManager.selectedVideo.template_name == 'Regalia' ||
            this.videoManager.selectedVideo.template_name == 'Contempo' ||
            this.videoManager.selectedVideo.template_name == 'Fresh'
        ) {
            this.allowedTemplate = false;
        }
        if (this.videoManager.selectedVideo.sap_video_id != undefined && this.videoManager.selectedVideo.sap_video_id != '') {
            this.isSapVideo = true;
        }
        if (this.isSapVideo && !this.videoManager.selectedVideo.logo_type) {
            // this.videoManager.selectedVideo.logo_type = 'own';
            this.selectedLogo = 'own';
        } else {
            this.selectedLogo = this.videoManager.selectedVideo.logo_type;
        }

        this.oLogoDetails = this.oEditVideoService.EditVideoService_ReturnFileDetails(this.onBoardingManager.profile.logo_file);

        this.loadSecondaryLogo();
        this.lKey_terms = this.videoManager.selectedVideo.key_terms;
        if (this.videoManager.selectedVideo.sap_tag_line && this.videoManager.selectedVideo.sap_tag_line != '') {
            this.sapVideoTagLine = this.videoManager.selectedVideo.sap_tag_line;
        }
        this.defaultVideoColor = this.videoManager.fontDefaultColor;
        // const logo_image_signed_url =
        //     this.videoManager?.selectedVideo?.logo_img && this.videoManager?.selectedVideo?.logo_img_url
        //         ? this.videoManager?.selectedVideo?.logo_img_url
        //         : '';
        // if (logo_image_signed_url != '') {
        //     // this.setVideoLogo({ fpath: this.videoManager?.selectedVideo?.logo_img, url: logo_image_signed_url });
        //     // this.oSessionService.oUploadedLogo = { url: logo_image_signed_url };
        // }

        if (this.videoManager?.selectedVideo?.logo_img && this.videoManager?.selectedVideo?.logo_img != '') {
            this.videoManager.selectedVideo.logo_img_url = undefined;
            this.videoService
                .getSignedURL(this.sUserEmail, this.sSessionID, this.videoManager?.selectedVideo?.logo_img)
                .then((res) => {
                    this.videoManager.selectedVideo.logo_img_url = res['url'];
                    this.setVideoLogo({
                        fpath: this.videoManager?.selectedVideo?.logo_img,
                        visual_path: this.videoManager?.selectedVideo?.logo_img,
                        url: this.videoManager.selectedVideo.logo_img_url,
                    });
                    // this.oSessionService.oUploadedLogo = {
                    //   url: this.videoManager.selectedVideo.logo_img_url,
                    // };
                })
                .catch((err) => {});
        }

        if (this.videoManager?.selectedVideo?.logo_img && this.videoManager?.selectedVideo?.logo_img != '') {
            this.setVideoLogo({ fpath: this.videoManager?.selectedVideo?.logo_img, url: this.videoManager.selectedVideo.logo_img_url });
            // this.oSessionService.oUploadedLogo = {
            //   url: this.videoManager.selectedVideo.logo_img_url,
            // };
        }

        if (!this.videoManager.selectedVideo.brand_id) {
            if (this.onBoardingManager.profile.logo_file && this.onBoardingManager.profile.logo_file != '') {
                this.oLogoDetails = await this.oEditVideoService.EditVideoService_ReturnFileDetails(
                    this.onBoardingManager.profile.logo_file,
                );
            }
        }
        this.initLogoType();

        // else {
        //   if (this.onBoardingManager.profile.logo_file && this.onBoardingManager.profile.logo_file != '') {
        //     this.oSessionService.oUploadedLogo =
        //       await this.oEditVideoService.EditVideoService_ReturnFileDetails(
        //         this.onBoardingManager.profile.logo_file
        //       );
        //   }
        // }
        setTimeout((): void => {
            this.initializeColorPicker();
        }, 2000);
        this.brand_id = this.videoManager.selectedVideo.brand_id;

        if (this.videoManager.selectedVideo.font_color) {
            this.oPrimaryColor = this.videoManager.selectedVideo.font_color;
            this.oFontColor = this.videoManager.selectedVideo.font_color;
            this.isFontSelected = true;
        }
        if (this.videoManager.selectedVideo.theme_colors && this.videoManager.selectedVideo.theme_colors.length > 0) {
            this.videoThemeColor = this.videoManager.selectedVideo.theme_colors[0];
        }

        this.brandFonts = this.videoManager?.videoBrand?.fonts ? this.videoManager?.videoBrand?.fonts : [];
        if (this.brandFonts && this.brandFonts.length > 0) {
            let externalFontsStyleSheet = '';
            this.videoService
                .getAllSignedURLs({
                    email: this.sUserEmail,
                    session_id: this.sSessionID,
                    fpaths: this.brandFonts,
                })
                .then((result: any) => {
                    result.output.map(async (item: any) => {
                        // console.log(" item ", item);
                        // console.log(Object.values(item));
                        // console.log(Object.keys(item));

                        let fontPath = Object.keys(item)[0];
                        let fontSignedURLPath = Object.values(item)[0];

                        externalFontsStyleSheet += this.getFontFaceStyleCSS(fontPath, fontSignedURLPath);
                    });

                    var head = document.getElementsByTagName('head')[0];
                    let styleElement = document.createElement('style');
                    styleElement.textContent = externalFontsStyleSheet;
                    head.appendChild(styleElement);

                    // console.log(" urls of brand fonts ", result);
                });
        }
        // this.getDefaultFonts();
    }

    loadSecondaryLogo() {
        if (this.isSapVideo) {
            if (this.videoManager.selectedVideo.sap_secondary_logo) {
                this.mediaService
                    .getSignedURL(this.sUserEmail, this.sSessionID, this.videoManager.selectedVideo.sap_secondary_logo)
                    .then(async (resp) => {
                        this.applyPrimaryLogoAtVideoStart = false;
                        this.secondaryLogoFile = resp;
                    });
            } else {
                this.secondaryLogoFile = this.oSessionService.oUploadedLogo;
                this.setSapVideoLogo;
            }
        }
    }

    initializeColorPicker() {
        let colorPickerScript = document.createElement('script');
        colorPickerScript.type = 'text/javascript';
        colorPickerScript.text = `
$(document).ready(function() {
  $(window).keydown(function(event){
    if(event.keyCode == 13) {
      event.preventDefault();
      return false;
    }
  });
});
Coloris({
  el: '.coloris',
  swatches: [
      '#264653',
      '#2a9d8f',
      '#e9c46a',
      '#f4a261',
      '#e76f51',
      '#d62828',
      '#023e8a',
      '#0077b6',
      '#0096c7',
      '#00b4d8',
      '#48cae4',
  ]
});

    function appendColorPanel(colors){
      console.log("Color panel called");
    }
`;

        document.body.appendChild(colorPickerScript);
        const listBrandColors = [];
        if (this.videoManager?.videoBrand?.primary_color) {
            listBrandColors.push(this.videoManager?.videoBrand?.primary_color);
        }
        if (this.videoManager?.videoBrand?.secondary_colors) {
            this.videoManager?.videoBrand?.secondary_colors.map((color) => {
                if (listBrandColors.indexOf(color) === -1) {
                    listBrandColors.push(color);
                }
            });
        }
        let strDD = '';
        if (listBrandColors && listBrandColors.length > 0) {
            strDD += '<div class="headingm">Brand Colors</div><div>';
        }

        // $("#clr-picker").prependTo("#content")
        setTimeout(() => {
            listBrandColors.map((color, index) => {
                strDD +=
                    '<button id="clr-swatch-' +
                    index +
                    '" aria-labelledby="clr-swatch-label clr-swatch-' +
                    index +
                    '" style="color: ' +
                    color +
                    ';">' +
                    color +
                    '</button>';
            });
            if (listBrandColors && listBrandColors.length > 0) {
                strDD += '</div><div class="headingm">Other Colors </div>';
            }

            let innerHTMLParent = document.querySelector('#clr-swatches').innerHTML;
            document.querySelector('#clr-swatches').innerHTML = strDD + innerHTMLParent;
        }, 500);
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
    CreateVideoLooknFeelComponent_ToggleLogoSection(event: any) {
        let value = event.target.value;
        if (value === 'upload-logo') {
            this.bDisplayUploadLogoSection = true;
        } else {
            this.bDisplayUploadLogoSection = false;
        }
    }
    getFontFaceStyleCSS(fontPath, fontSignedURLPath) {
        // this.selectedFontSignedURL = fontSignedURLPath;
        let fontFamily = '';
        let path = fontPath.split('.');
        let fontExtension = path[path.length - 1];
        let pathName = fontPath.split('/');
        let fontFamilyName = pathName[pathName.length - 1] ? pathName[pathName.length - 1].split('.')[0] : pathName[pathName.length - 1];

        let fontFormate = fontExtension;
        if (fontFormate == 'otf' || fontFormate == 'OTF' || fontFormate == 'ttf' || fontFormate == 'TTF') {
            fontFormate = 'opentype';
        }
        return `
    @font-face {
      font-family: '${fontFamilyName}';
      font-style: normal;
      font-weight: 500;
      src: url('${fontSignedURLPath}') format('${fontFormate}');}`;
    }
    closeVideo(): void {
        this.onClose.emit();
        //this.visibleMedia = false;
    }

    updateTimeLineVideo(){
        console.log(" EMIT UPDATE VIDEO ");
        this.updateVideoSettings.emit();
    }
    logoRadioButtonTab = 1;

    TimelineViewSidePanelComponent_PauseVideoOnClose() {}
    async TimelineViewSidepanelsComponent_RevertLooknFeelForm() {
        // console.log("Logo image Revert logo ");
        // this.oSessionService.oUploadedLogo = "";
        // if (
        //   this.videoManager?.selectedVideo?.logo_img &&
        //   this.videoManager?.selectedVideo?.logo_img != ""
        // ) {
        //   this.oSessionService.oUploadedLogo = {
        //     url: this.videoManager?.selectedVideo?.logo_img_url,
        //   };
        // } else {
        //   if (this.onBoardingManager.profile.logo_file) {
        //     this.oSessionService.oUploadedLogo =
        //       await this.oEditVideoService.EditVideoService_ReturnFileDetails(
        //         this.onBoardingManager.profile.logo_file
        //       );
        //   }
        // }

        // let logo = "";
        // if (logo == "") this.videoManager.selectedVideo.logo_img = logo;
        this.closeVideo();
    }
    TimelineViewSidePanelComponent_PlayVideo() {
        this.myVideoO.nativeElement.play();
        this.figActive = false;
    }

    updateVideoLocally() {
        console.log(this.otherSettings.urlToggle, this.otherSettings.addressToggle, this.otherSettings.phoneToggle);
        if (!this.videoManager.selectedVideo.others_setting) {
            this.videoManager.selectedVideo.others_setting = {
                url: false,
                address: false,
                phone: false,
            };
            console.log(this.videoManager.selectedVideo);
            // this.videoManager.selectedVideo.others_setting.url = true;
            // this.videoManager.selectedVideo.others_setting.address = true;
            // this.videoManager.selectedVideo.others_setting.phone = true;
        }
        if (this.otherSettings.urlToggle) {
            this.videoManager.selectedVideo.url = this.fOtherSettingForm.value.url;
            this.videoManager.selectedVideo.ci_dict.url = this.videoManager.selectedVideo.url;
            this.videoManager.selectedVideo.others_setting.url = true;
        } else {
            this.otherSettings.urlToggle = false;
            this.videoManager.selectedVideo.others_setting.url = false;
            // this.videoManager.selectedVideo.url = "";

            // this.videoManager.selectedVideo.url = undefined;
            // this.videoManager.selectedVideo.ci_dict.url = undefined;
        }
        if (this.otherSettings.addressToggle) {
            if (this.videoManager.selectedVideo.address == undefined) {
                this.videoManager.selectedVideo.address = new address();
            }

            this.videoManager.selectedVideo.address.line1 = this.fOtherSettingForm.value.line1;
            this.videoManager.selectedVideo.address.line2 = this.fOtherSettingForm.value.line2 ? this.fOtherSettingForm.value.line2 : '';
            this.videoManager.selectedVideo.address.city = this.fOtherSettingForm.value.city;
            this.videoManager.selectedVideo.address.state = this.fOtherSettingForm.value.state;
            this.videoManager.selectedVideo.address.postal_code = this.fOtherSettingForm.value.postal_code;
            this.videoManager.selectedVideo.address.country = this.fOtherSettingForm.value.country;

            this.videoManager.selectedVideo.ci_dict.address = this.videoManager.selectedVideo.address;
            this.videoManager.selectedVideo.others_setting.address = true;
            console.log('Address Fileds ', this.videoManager.selectedVideo.address, this.videoManager.selectedVideo.ci_dict.address);
        } else {
            // this.videoManager.selectedVideo.address = undefined;
            this.otherSettings.addressToggle = false;
            this.videoManager.selectedVideo.others_setting.address = false;
        }

        if (this.otherSettings.phoneToggle) {
            this.videoManager.selectedVideo.phone = this.fOtherSettingForm.value.phone;
            this.videoManager.selectedVideo.ci_dict.phone = this.videoManager.selectedVideo.phone;
            this.videoManager.selectedVideo.others_setting.phone = true;
        } else {
            this.videoManager.selectedVideo.others_setting.phone = false;
            // this.videoManager.selectedVideo.phone = undefined;
            // this.videoManager.selectedVideo.ci_dict.phone = undefined;
            this.otherSettings.phoneToggle = false;
        }

        console.log(' Other settings fields >>>', this.videoManager.selectedVideo);
        // if (this.fOtherSettingForm.value.sapVideoTagLine && this.fOtherSettingForm.value.sapVideoTagLine != '') {
        //     this.videoManager.selectedVideo.sap_tag_line = this.fOtherSettingForm.value.sapVideoTagLine;
        // } else {
        //     delete this.videoManager.selectedVideo.sap_tag_line;
        // }
        this.updateTimeLineVideo();
    }
    async TimelineViewSidepanelsComponent_SaveLooknFeelForm() {
        // this.fOtherSettingForm.markAsUntouched();
        this.formOtherSettings.nativeElement.blur();
        this.videoManager.selectedVideo.key_terms = this.lKey_terms;
        this.saveSapSecondaryLogo();
        this.fOtherSettingForm.markAsUntouched();
        console.log(" this.fOtherSettingForm?.controls['url'].validator ", this.fOtherSettingForm?.controls['url']);
        // this.fOtherSettingForm.markAsUntouched();
        if (
            this.otherSettings.urlToggle &&
            this.fOtherSettingForm?.controls['url'] &&
            this.fOtherSettingForm?.controls['url'].value == ''
        ) {
            this.fOtherSettingForm.markAllAsTouched();
        } else if (
            this.otherSettings.phoneToggle &&
            (!this.fOtherSettingForm?.controls['phone'] ||
                this.fOtherSettingForm?.controls['phone']?.value == undefined ||
                this.fOtherSettingForm?.controls['phone']?.value == '' ||
                this.fOtherSettingForm?.controls['phone']?.value == '')
        ) {
            console.log(' phone ISSUe ...');
            this.fOtherSettingForm.markAllAsTouched();
        } else if (
            this.otherSettings.addressToggle &&
            (!this.fOtherSettingForm?.controls['line1'] ||
                this.fOtherSettingForm?.controls['line1'].value == '' ||
                this.fOtherSettingForm?.controls['line1'].value == undefined ||
                !this.fOtherSettingForm?.controls['city'].value ||
                this.fOtherSettingForm?.controls['city'].value == '' ||
                this.fOtherSettingForm?.controls['city'].value == undefined ||
                !this.fOtherSettingForm?.controls['state'] ||
                this.fOtherSettingForm?.controls['state'].value == '' ||
                this.fOtherSettingForm?.controls['state'].value == undefined ||
                !this.fOtherSettingForm?.controls['postal_code'] ||
                this.fOtherSettingForm?.controls['postal_code'].value == undefined ||
                this.fOtherSettingForm?.controls['postal_code'].value == '')
        ) {
            console.log(' address issue MArk all as touched .. >>>>>');
            this.fOtherSettingForm.markAllAsTouched();
        }
        //  else if (!this.fOtherSettingForm.valid && ( !this.otherSettings.urlToggle)) {
        //     // To test invalid Fields .....
        //     for (const name in this.fOtherSettingForm.controls) {
        //         if (this.fOtherSettingForm.controls[name].invalid) {
        //             console.log( "invalid  name ", name);
        //         }
        //     }
        //     // console.log(this.fOtherSettingForm)
        //     // console.log(" state  ", this.fOtherSettingForm?.controls['state']);
        //     // console.log( " postal_code ", this.fOtherSettingForm?.controls['postal_code']);

        //     // console.log(' other else issue  ISSUe ...');
        //     this.fOtherSettingForm.markAllAsTouched();
        // }
        else {

            const pattern =
            /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;


            let startProcess = true;
                if(this.fOtherSettingForm?.controls['url'] && this.fOtherSettingForm?.controls['url'].value != '' && this.otherSettings.urlToggle){
                   let matchCase =  pattern.test(this.fOtherSettingForm?.controls['url'].value );
                    console.log(" url match case ", matchCase);
                    if(!matchCase){
                        startProcess = false;
                    }
                    this.fOtherSettingForm.markAllAsTouched();
                }

                if(startProcess){
                    this.videoManager.selectedVideo.session_id = this.fOtherSettingForm.value.session_id;
                    this.videoManager.selectedVideo.email = this.fOtherSettingForm.value.email;

                    this.updateVideoLocally();

                    this.videoManager.selectedVideo.logo_type = this.selectedLogo;
                    this.updateSelectedLogo();
                    this.videoManager.selectedVideo.logo_img = this.reSelectedVideoLogo?.fpath
                        ? this.reSelectedVideoLogo?.fpath
                        : this.reSelectedVideoLogo?.visual_path;
                    console.log(this.videoManager.selectedVideo.logo_img);
                    if (this.oPrimaryColor) {
                        this.videoManager.selectedVideo.font_color = this.oPrimaryColor;
                    }
                    if (this.oFontColor) {
                        this.videoManager.selectedVideo.font_color = this.oFontColor;
                    }
                    if (this.videoThemeColor) {
                        this.videoManager.selectedVideo.theme_colors = [this.videoThemeColor];
                    }

                    // this.returnSelectedLogo();

                    if (this.selectedVideoFontFile) {
                        this.videoManager.selectedVideo.font_file = this.selectedVideoFontFile;
                    }
                }
        }
    }

    saveSapSecondaryLogo() {
        if (this.isSapVideo) {
            if (this.applyPrimaryLogoAtVideoStart) {
                this.videoManager.selectedVideo.sap_secondary_logo = undefined;
                if (this.selectedLogo == 'own') {
                    this.videoManager.selectedVideo.sap_secondary_logo = this.oSessionService.oUploadedLogo.visual_path
                        ? this.oSessionService.oUploadedLogo.visual_path
                        : this.oSessionService.oUploadedLogo.fpath;
                } else {
                    this.videoManager.selectedVideo.sap_secondary_logo = this.oLogoDetails.fpath;
                }
            } else {
                if (this.secondaryLogoFile && this.isSapVideo) {
                    this.videoManager.selectedVideo.sap_secondary_logo = this.secondaryLogoFile.fpath
                        ? this.secondaryLogoFile.fpath
                        : this.secondaryLogoFile.visual_path;
                } else {
                    if (this.oSessionService.oUploadedLogo && this.oSessionService.oUploadedLogo.visual_path) {
                        this.videoManager.selectedVideo.sap_secondary_logo = this.oSessionService.oUploadedLogo.visual_path;
                    }
                }
            }
        }

        console.log(this.videoManager.selectedVideo);
    }
    returnSecondLogo() {
        let logo_path;
        if (this.bSecondLogo) {
            logo_path = this.oUploadedSecondLogo ? this.oUploadedSecondLogo.visual_path : '';
        }
        return logo_path;
    }
    returnSelectedLogo() {
        let logo_path;
        if (this.currentUploadedLogoSection == 'brand') {
            return this.videoManager.selectedVideo.logo_img;
        } else {
            if (this.oSessionService.oUploadedLogo) {
                logo_path = this.oSessionService.oUploadedLogo ? this.oSessionService.oUploadedLogo.visual_path : '';
            } else {
                logo_path = this.oLogoDetails ? this.oLogoDetails.fpath : '';
            }
            return logo_path;
        }
    }
    sizeOfFile(bytes, decimals = 2) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        if (!sizes || !sizes[i]) {
            return '';
        }
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    updateSelectedLogo() {
        if (this.selectedLogo == 'own') {
            if (this.oSessionService.oUploadedLogo && this.oSessionService.oUploadedLogo.url) {
                this.reSelectedVideoLogo = this.oSessionService.oUploadedLogo;
            }
        }
        if (this.selectedLogo == 'profile') {
            this.reSelectedVideoLogo = this.oLogoDetails;
        }
        if (this.selectedLogo == 'brand') {
            if (this.brandUploadedSecondLogo && this.brandUploadedSecondLogo.url) {
                this.reSelectedVideoLogo = this.brandUploadedSecondLogo;
            }
        }
    }
    toggleVideoLogoUploadSection(type: string) {
        this.currentUploadedLogoSection = type;
        this.selectedLogo = type;
        this.logoRadioButtonTab = type == 'profile' ? 2 : type == 'brand' ? 3 : 1;
        if (type == 'brand' && this.brandUploadedSecondLogo && this.brandUploadedSecondLogo.fpath) {
            // this.videoManager.selectedVideo.logo_img = this.brandUploadedSecondLogo.fpath;
            if (this.brandUploadedSecondLogo && this.brandUploadedSecondLogo.url) {
                // this.videoManager.selectedVideo.logo_img_url = this.brandUploadedSecondLogo.url;
            }
        }
        // if (type == 'profile' && this.oLogoDetails && this.oLogoDetails.fpath) {
        //     //this.videoManager.selectedVideo.logo_img = this.brandUploadedSecondLogo.fpath;

        //     if (this.brandUploadedSecondLogo && this.brandUploadedSecondLogo.url) {
        //         this.videoManager.selectedVideo.logo_img_url = this.brandUploadedSecondLogo.url;
        //     }
        // } else {
        //     if (this.oSessionService.oUploadedLogo && type == 'own') {
        //         this.videoManager.selectedVideo.logo_img = this.oSessionService.oUploadedLogo.visual_path;

        //         if (this.brandUploadedSecondLogo && this.brandUploadedSecondLogo.url) {
        //             this.videoManager.selectedVideo.logo_img_url = this.brandUploadedSecondLogo.url;
        //         }
        //     }
        // }
    }

    currentLogoTabSelected = 'upload-logo';
    async toggleLogoUploadSection(event: any, type = '') {
        let value = event.target.value;
        this.bDisplayUploadLogoSection = false;
        this.bDisplayBrandUploadLogoSection = false;
        this.currentLogoTabSelected = value;
        if (value === 'upload-logo') {
            this.bDisplayUploadLogoSection = true;
        } else {
            if (type == 'brand') {
                this.bDisplayBrandUploadLogoSection = true;
            } else {
                if (this.onBoardingManager.profile.logo_file) {
                    this.currentLogoTabSelected = 'profile';
                    // this.oSessionService.oUploadedLogo =
                    //   await this.oEditVideoService.EditVideoService_ReturnFileDetails(
                    //     this.onBoardingManager.profile.logo_file
                    //   );
                } else {
                    this.bDisplayUploadLogoSection = false;
                }
            }
        }
    }
    TimelineViewSidePanelComponent_ReturnFileSize(bytes, decimals = 2) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    // startLogoUploadProcess($event, section = "main") {
    //   console.log($event);
    //   if (section == "dragdrop") {
    //     this.uploadLogoFile($event);
    //   } else {
    //     this.uploadLogoFile($event.target.files);
    //   }
    // }

    // async uploadLogoFile(files) {
    //   let file = files[0];
    //   if (
    //     file.type &&
    //     !(
    //       file.type.indexOf("jpeg") > -1 ||
    //       file.type.indexOf("jpg") > -1 ||
    //       file.type.indexOf("png") > -1
    //     )
    //   ) {
    //     this.errorMessage.handleError(
    //       "Only jpeg, jpeg and png files are allowed",
    //       "Logo Upload Failed",
    //       true,
    //       false,
    //       true
    //     );
    //   } else {
    //     this.nUploadedLogoSize = file.size;
    //     console.log(
    //       "CreateVideoLooknFeelComponent_UploadFile : File To Upload ==> ",
    //       file
    //     );

    //     let media = new mediaFile();
    //     media.file = file;
    //     media.name = file.name;
    //     this.showLogoUploadModal = true;
    //     this.closeModal.nativeElement.click();
    //     try{
    //     await this.mediaManager
    //       .uploadMediaDefaultLibrary(this.sUserEmail, this.sSessionID, media)
    //       .then((res: visual) => {
    //         console.log(res);
    //         this.oSessionService.oUploadedLogo = res;
    //         // this.oSessionService.oUploadedLogo =  { filename: res.name, url: res.url, visual_path: res.visual_path};

    //         this.showLogoUploadModal = false;

    //         this.sUploadedLogoName = file.name;
    //       })
    //       .catch((error) => {
    //         this.oSessionService.oUploadedLogo = null;
    //         this.showLogoUploadModal = false;
    //         this.sDisplayResponseErrorMessage = error.error.errorMessage;
    //         this.bDisplayErrorMessage = true;
    //         this.errorMessage.handleError(
    //           error.error.errorMessage ? error.error.errorMessage : error.error,
    //           "Upload File",
    //           true
    //         );
    //         this.closeModal.nativeElement.click();
    //         setTimeout(() => {
    //           this.bDisplayErrorMessage = false;
    //         }, 5000);
    //       });
    //     }catch(error) {
    //       this.errorMessage.handleError(
    //         error.error.errorMessage ? error.error.errorMessage : error.error,
    //         "Upload File",
    //         true
    //       );
    //     }
    //   }

    //   /*
    //       var oFormData: any = new FormData();
    //       oFormData.append('session_id', this.sSessionID);
    //       oFormData.append('email', this.sUserEmail);
    //       oFormData.append('file', file);
    //       oFormData.append("logo", true);

    //       try {
    //         await this.oDataService.DataService_PostUploadedVisuals(oFormData).subscribe((result: any) => {
    //           console.log("CreateVideoLooknFeelComponent_UploadedLogoFile : Response ==>", result);
    //           this.oUploadedLogo = result;
    //           this.sUploadedLogoName = result.filename;
    //           //this.fLooknFeelForm.patchValue({oUploadedLogo : this.oUploadedLogo, nUploadedLogoSize : this.nUploadedLogoSize});
    //           this.closeModal.nativeElement.click();
    //         },
    //           (error) => {
    //             console.error("CreateVideoLooknFeelComponent_UploadedLogoFile : Error ==>", error);
    //             this.oUploadedLogo = null;
    //             this.sDisplayResponseErrorMessage = error.error.errorMessage;
    //             this.bDisplayErrorMessage = true;
    //             this.closeModal.nativeElement.click();
    //             setTimeout(() => { this.bDisplayErrorMessage = false; }, 5000);
    //           });
    //       }
    //       catch (error) {
    //         console.error("CreateVideoLooknFeelComponent_UploadedLogoFile : ERROR ==>", error);
    //       }*/
    // }
    setThemeColor(event) {
        this.videoThemeColor = event.target.value;
    }
    setFontColor(event) {
        this.oFontColor = event.target.value;
        // const fontColor = event.target.value;
        // console.log("Font Color ==>", fontColor);
        // this.oFontColor = fontColor;
        // color.color = event.target.value;
        // console.log("Selected cvolor ", color);
    }
    secondaryLogoFile = undefined;
    openFileUploadModal(uploadFor = 'image_video_logo', heading = 'Upload a Logo', type = 'logoUpload') {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '100wv';
        dialogConfig.height = '100vh';
        dialogConfig.panelClass = 'pronto-dialog';
        dialogConfig.closeOnNavigation = true;

        dialogConfig.data = {
            uploadFor: uploadFor,
            multipleUpload: false,
            // accept: "video/mp4, video/avi",
            dropBoxText: '',
            showSuccessBox: true,
            heading: heading,
            allowDuplicate: true,
            component: type,
            mediaLibraryId:
                this.videoManager.selectedVideo?.brand_id &&
                this.videoManager.videoBrand?.media_libs &&
                this.videoManager.videoBrand?.media_libs.length > 0
                    ? this.videoManager.videoBrand?.media_libs[0]._id?.$oid
                    : undefined,
        };
        let showUploadModal = true;

        if (this.videoManager.selectedVideo.brand_id && !dialogConfig.data.mediaLibraryId) {
            showUploadModal = false;
        }
        if (showUploadModal) {
            let dialogModal = this.dialog.open(UploadFileDialogComponent, dialogConfig);

            dialogModal.afterClosed().subscribe((data: any) => {
                if (data.type == 'success' || data.status == 'success') {
                    if (type == 'logoUpload') {
                        this.selectedLogo = 'own';
                        this.oSessionService.oUploadedLogo = data.result[0];
                        this.reSelectedVideoLogo = data.result[0];

                        this.setVideoLogo(data.result[0]);
                    } else {
                        this.secondaryLogoFile = data.result[0];
                        this.reSelectedVideoLogo = this.oSessionService.oUploadedLogo;
                        // this.videoManager.selectedVideo.sap_secondary_logo = data.result[0].fpath;
                        this.sUploadedLogoName = data.result[0].name;
                    }
                } else {
                    //this.errorMessage.handleError('Media Library does not exist', 'Logo Update', true, false, true);
                }
            });
        }
        this.mediaManager.replaceMediaFromVisualPopup.pipe(takeUntil(this.destroy$)).subscribe((item: any) => {
            if (item && item.length > 0) {
                if (item.component && item.component == 'logoUpload') {
                    this.selectedLogo = 'own';
                    this.oSessionService.oUploadedLogo = item[0];
                    this.sUploadedLogoName = this.oSessionService.oUploadedLogo.fpath;
                    this.showLogoUploadModal = false;
                    this.reSelectedVideoLogo = item[0];
                    this.setVideoLogo(item[0]);
                } else {
                    this.secondaryLogoFile = item[0];
                    this.reSelectedVideoLogo = this.oSessionService.oUploadedLogo;
                    // this.videoManager.selectedVideo.sap_secondary_logo = item[0].fpath;
                    this.sUploadedLogoName = item[0].name;
                }
            }
            this.mediaManager.showMediaReplacementPopup = false;
            this.cd.detectChanges();
        });
    }

    assignFontToVideo($event) {
        this.selectedVideoFontFile = $event?.file;
    }
    openSelectBrandLogo() {
        this.showBrandLogoSelectionPopup = true;
    }
    closeSelectBrandLogo() {
        this.showBrandLogoSelectionPopup = false;
    }

    brandLogoFile = undefined;
    onSelectBrandLogo($event) {
        this.brandLogoFile = $event;
        // console.log(' brandLogoFile ', this.brandLogoFile);
        this.closeSelectBrandLogo();
        if ($event && $event.fpath) {
            this.brandUploadedSecondLogo = $event;
            this.setVideoLogo($event);
            this.oLogoDetails = $event;
            // this.videoManager.selectedVideo.logo_img = this.brandUploadedSecondLogo.fpath;
            // this.videoManager.selectedVideo.logo_img_url = this.brandUploadedSecondLogo.url;
        }
    }

    getDefaultFonts() {
        this.defaultFonts = [];
        if (this.videoService.otherSystemFonts) {
            this.defaultFonts = this.videoService.otherSystemFonts;
            this.otherFontArray = this.videoService.otherSystemFonts;
            this.loadOtherFontStyles();
        } else {
            this.mediaService.getDefaultFontLists().then((list: any) => {
                this.defaultFonts = list.result;
                this.otherFontArray = list.result;
                this.videoService.otherSystemFonts = list.result;
                this.loadOtherFontStyles();
            });
        }
    }

    loadOtherFontStyles() {
        console.log('Load other font styles....');
        if (this.otherFontArray && this.otherFontArray.length > 0) {
            console.log('Start creating other font style');
            this.internalFontsStyleSheet = '';
            let loopIndex = 0;
            let continueLoop = true;
            let fontGeneration = setInterval(() => {
                console.log(loopIndex, ' > = ', this.otherFontArray.length);
                if (loopIndex >= this.otherFontArray.length) {
                    continueLoop = false;
                    console.log('Clear loop');
                    clearInterval(fontGeneration);
                } else {
                    this.loadFontData(loopIndex, 40);
                }
                loopIndex += 40;
            }, 500);
        }
    }
    selectVideoFont(font) {
        this.selectedVideoFontFile = font;
    }

    fontStyle(incommingFont) {
        let fontNameSpliter = incommingFont.split('/');
        let nameFont = fontNameSpliter[fontNameSpliter.length - 1];
        return nameFont.split('.')[0];
    }

    loadFontData(index, limit) {
        var filterArray = [];
        for (let i = index; i < index + limit; i++) {
            if (this.otherFontArray.length >= i) {
                filterArray.push(this.otherFontArray[i]);
            }
        }
        let internalFontsStyleSheet = '';
        filterArray.map((item: any) => {
            // console.log(item)
            if (item) {
                let fontPath = item.name;
                let fontSignedURLPath = item.signed_url;
                internalFontsStyleSheet += this.getFontFaceStyleCSS(fontPath, fontSignedURLPath);
            }
        });
        var head = document.getElementsByTagName('head')[0];
        let styleElement = document.createElement('style');
        styleElement.textContent = internalFontsStyleSheet;
        head.appendChild(styleElement);
    }
    CreateVideoOverViewComponent_RemoveTagListElements(index) {
        this.lKey_terms.splice(index, 1);
        this.videoManager.selectedVideo.key_terms = this.lKey_terms;
    }

    CreateVideoOverViewComponent_FocusoutFromInput() {
        if (this.inputValue !== '') {
            if (!this.lKey_terms) this.lKey_terms = [];
            this.lKey_terms.push(this.inputValue);
            this.videoManager.selectedVideo.key_terms = this.lKey_terms;
        }
        setTimeout(() => {
            this.bDisplayAddInputField = false;
        }, 100);
    }
    AddTagInputField() {
        this.bDisplayAddInputField = false;
        if (!this.lKey_terms) this.lKey_terms = [];

        let tagIndex = this.lKey_terms.findIndex((item: string) => {
            return item.toLowerCase() == this.inputValue.trim().toLowerCase();
        });

        if (tagIndex == -1 && this.inputValue.trim() != '') {
            this.lKey_terms.push(this.inputValue.trim());
        }
        setTimeout(() => {
            this.bDisplayAddInputField = false;
        }, 100);

        this.inputValue = '';
    }
    addTagOnKeyEnter(keycode) {
        if ((keycode == 13 && this.inputValue != '') || (keycode == 188 && this.inputValue != '')) {
            this.AddTagInputField();
        }
    }

    CreateVideoOverViewComponent_ToggleTagButtonAndAddInput() {
        this.inputValue = '';
        setTimeout(() => this.AddInputTagElement.nativeElement.focus());
        this.bDisplayAddInputField = true;
    }
    CreateVideoOverViewComponent_GetInputValue(event) {
        this.inputValue = event.target.value;
        console.log('Input Value ==>', this.inputValue);
    }

    initOtherSettingFormData() {
        // if (!this.videoManager.selectedVideo.address) {
        //     this.videoManager.selectedVideo.address = new address();
        // }
        this.otherSettings.urlToggle = false;
        this.otherSettings.addressToggle = false;
        this.otherSettings.phoneToggle = false;

        var url = this.onBoardingManager.profile.url;
        if (this.videoManager.selectedVideo.url && this.videoManager.selectedVideo.url != '') {
            url = this.videoManager.selectedVideo.url;
            if (url != '') {
                this.otherSettings.urlToggle = true;
            }
        } else if (this.videoManager.selectedVideo.ci_dict?.url && this.videoManager.selectedVideo.ci_dict?.url != '') {
            url = this.videoManager.selectedVideo.ci_dict?.url;
            if (url != '') {
                this.otherSettings.urlToggle = true;
            }
        }
        if (this.videoManager.selectedVideo.phone && this.videoManager.selectedVideo.phone != '') {
            this.fOtherSettingForm?.controls['phone'].setValue(this.videoManager.selectedVideo.phone);
            this.otherSettings.phoneToggle = true;
            // console.log('phn1', this.videoManager.selectedVideo.phone);
            // console.log('phn2', this.fOtherSettingForm.value.phone);
        } else if (this.videoManager.selectedVideo.ci_dict.phone && this.videoManager.selectedVideo.ci_dict.phone != '') {
            this.fOtherSettingForm?.controls['phone'].setValue(this.videoManager.selectedVideo.ci_dict?.phone);
            this.otherSettings.phoneToggle = true;
        }

        console.log(' this.videoManager.selectedVideo.address ----- ', this.videoManager.selectedVideo.address);
        if (
            this.videoManager.selectedVideo.address &&
            this.videoManager.selectedVideo?.address?.line1 &&
            this.videoManager.selectedVideo?.address?.line1 != ''
        ) {
            this.fOtherSettingForm?.controls['line1'].setValue(this.videoManager.selectedVideo.address.line1);
            this.fOtherSettingForm?.controls['line2'].setValue(
                this.videoManager.selectedVideo.address.line2 ? this.videoManager.selectedVideo.address.line2 : '',
            );
            this.fOtherSettingForm?.controls['city'].setValue(this.videoManager.selectedVideo.address.city);
            this.fOtherSettingForm?.controls['state'].setValue(this.videoManager.selectedVideo.address.state);
            this.fOtherSettingForm?.controls['postal_code'].setValue(this.videoManager.selectedVideo.address.postal_code);
            this.fOtherSettingForm?.controls['country'].setValue(this.videoManager.selectedVideo.address.country);
            this.otherSettings.addressToggle = true;
        } else if (
            this.videoManager.selectedVideo.ci_dict &&
            this.videoManager.selectedVideo.ci_dict?.address?.line1 &&
            this.videoManager.selectedVideo.ci_dict?.address?.line1 != ''
        ) {
            this.fOtherSettingForm?.controls['line1'].setValue(this.videoManager.selectedVideo.ci_dict?.address.line1);
            this.fOtherSettingForm?.controls['line2'].setValue(
                this.videoManager.selectedVideo.ci_dict?.address.line2 ? this.videoManager.selectedVideo.ci_dict?.address.line2 : '',
            );
            this.fOtherSettingForm?.controls['city'].setValue(this.videoManager.selectedVideo.ci_dict?.address.city);
            this.fOtherSettingForm?.controls['state'].setValue(this.videoManager.selectedVideo.ci_dict?.address.state);
            this.fOtherSettingForm?.controls['postal_code'].setValue(this.videoManager.selectedVideo.ci_dict?.address.postal_code);
            this.fOtherSettingForm?.controls['country'].setValue(this.videoManager.selectedVideo.ci_dict?.address.country);
            this.otherSettings.addressToggle = true;
        }
        this.fOtherSettingForm = this.oFormBuilder.group({
            session_id: this.sSessionID,
            email: this.sUserEmail,
            url: [url, [Validators.required, Validators.pattern('(https?://)?([\\dA-Za-z.-]+)\\.([A-Za-z.]{2,6})[/\\w .-]*/?')]],
            line1: [this.videoManager.selectedVideo.ci_dict?.address?.line1]
                ? [this.videoManager.selectedVideo.ci_dict?.address?.line1]
                : [this.videoManager.selectedVideo.address.line1]
                ? [this.videoManager.selectedVideo.address.line1]
                : '',
            line2: [this.videoManager.selectedVideo.ci_dict?.address?.line2]
                ? [this.videoManager.selectedVideo.ci_dict?.address?.line2]
                : '',
            city: [this.videoManager.selectedVideo.ci_dict?.address?.city] ? [this.videoManager.selectedVideo.ci_dict?.address?.city] : '',
            state: [this.videoManager.selectedVideo.ci_dict?.address?.state]
                ? [this.videoManager.selectedVideo.ci_dict?.address?.state]
                : '',
            postal_code: [this.videoManager.selectedVideo.ci_dict?.address?.postal_code]
                ? [this.videoManager.selectedVideo.ci_dict?.address?.postal_code]
                : '',
            country: [this.videoManager.selectedVideo.ci_dict?.address?.country]
                ? [this.videoManager.selectedVideo.ci_dict?.address?.country]
                : '',
            phone: [this.videoManager.selectedVideo.ci_dict?.phone]
                ? [this.videoManager.selectedVideo.ci_dict?.phone]
                : [this.videoManager.selectedVideo.phone]
                ? [this.videoManager.selectedVideo.phone]
                : '',
            bDisplayUrl: this.otherSettings.urlToggle,
            bDisplayAddress: this.otherSettings.addressToggle,
            bDisplayPhone: this.otherSettings.phoneToggle,
            sapVideoTagLine: this.sapVideoTagLine,
        });
        // console.log('phn3', this.videoManager.selectedVideo.phone);
        // console.log('phn4', this.fOtherSettingForm.value.phone);
    }
    toggeleOtherSettingsSection(event: any) {
        this.otherSettings.urlToggle = event.target.checked;
        this.fOtherSettingForm.patchValue({ bDisplayUrl: this.otherSettings.urlToggle });

        if (this.otherSettings.urlToggle) {
            console.log(this.fOtherSettingForm.controls['url']);

            this.fOtherSettingForm.controls['url'].setValidators([
                Validators.required,
                Validators.pattern('(https?://)?([\\dA-Za-z.-]+)\\.([A-Za-z.]{2,6})[/\\w .-]*/?'),
            ]);
        } else {
            this.fOtherSettingForm.controls['url'].clearValidators();
        }

        this.fOtherSettingForm.controls['url'].updateValueAndValidity();
    }
    toggleAddressComponent(event: any) {
        this.fOtherSettingForm.markAsUntouched();
        this.otherSettings.addressToggle = event.target.checked;
        this.fOtherSettingForm?.patchValue({ bDisplayAddress: this.otherSettings.addressToggle });

        if (this.otherSettings.addressToggle) {
            this.fOtherSettingForm.controls['line1'].setValidators([Validators.required]);

            // this.fOtherSettingForm.controls['line2'].setValidators([

            // ]);
            this.fOtherSettingForm.controls['city'].setValidators([Validators.required]);
            this.fOtherSettingForm.controls['state'].setValidators([Validators.required]);
            this.fOtherSettingForm.controls['postal_code'].setValidators([Validators.required]);
            // this.fOtherSettingForm.controls['country'].setValidators([]);
        }
    }
    togglePhoneComponent(event: any) {
        // this.fOtherSettingForm?.controls['phone']?.setValue('');
        this.otherSettings.phoneToggle = event.target.checked;
        this.fOtherSettingForm.patchValue({ bDisplayPhone: this.otherSettings.phoneToggle });
        if (this.otherSettings.phoneToggle) {
            this.fOtherSettingForm.controls['phone'].setValidators([Validators.required, ,]);
        } else {
            this.fOtherSettingForm.controls['phone'].clearValidators();
        }

        this.fOtherSettingForm.controls['phone'].updateValueAndValidity();
    }
    initLogoType() {
        // console.log('   :::::::: Init Logo Type ', this.selectedLogo, this.isSapVideo);
        if (this.videoManager.selectedVideo.logo_type) {
            if (this.videoManager.selectedVideo.logo_type == 'profile') {
                this.selectedLogo = 'profile';
            } else if (this.videoManager.selectedVideo.logo_type == 'brand') {
                this.selectedLogo = 'brand';
            } else {
                this.selectedLogo = 'own';
                this.videoMainLogo = {
                    fpath: this.videoManager.selectedVideo.logo_img,
                    visual_path: this.videoManager.selectedVideo.logo_img,
                    url: this.videoManager.selectedVideo.logo_img_url,
                };
                this.oSessionService.oUploadedLogo = this.videoMainLogo;
                this.reSelectedVideoLogo = this.videoMainLogo;
            }
        } else {
            this.selectedLogo = 'profile';
            if (this.isSapVideo) {
                this.selectedLogo = 'own';
                this.reSelectedVideoLogo = this.oSessionService.oUploadedLogo;
            }
        }
        console.log('   :::::::: Init Logo Type ', this.selectedLogo, this.isSapVideo);
        this.cd.detectChanges();
    }
    setVideoLogo(logo: any) {
        // this.videoManager.selectedVideo.logo_type);
        // this.oSessionService.oUploadedLogo = logo;

        if (this.selectedLogo === 'profile') {
            this.oLogoDetails = logo;
        } else if (this.selectedLogo === 'brand') {
            this.brandUploadedSecondLogo = logo;
        } else {
            this.selectedLogo = 'own';
            this.oSessionService.oUploadedLogo = logo;
        }
        this.reSelectedVideoLogo = logo;
    }

    saveVideoLogo() {}
    setSapVideoLogo(logo: any, type: string) {
        if (type === 'profile') {
            this.oLogoDetails = logo;
        } else if (type === 'brand') {
            this.brandUploadedSecondLogo = logo;
        } else {
            this.selectedLogo = 'own';
            this.oSessionService.oUploadedLogo = logo;
        }
        this.reSelectedVideoLogo = logo;
    }
    togglePrimaryLogoAtVideoStart(val) {
        this.applyPrimaryLogoAtVideoStart = val;
    }
    emitUpdateKeywords($event) {
        console.log($event);
        this.lKey_terms = $event;
    }
}
