<!-- <iframe *ngIf="newLoaded == true && urlPlayerStatic && urlPlayerStatic !=''" style="width: 100%; height:100%; min-height:700px; min-width: 600px;" [src]="urlPlayerStatic | safe" frameborder="0" >

</iframe> -->
<div class="webgl-content" style="margin:auto">
    <div id="unityContainer" style="width: 640px !important; height: 480px !important; margin:auto"></div>
    <div class="footer">
      <div class="webgl-logo"></div>
      <div class="fullscreen" onclick="gameInstance.SetFullscreen(1)"></div>
      <div class="title">WebPlayer</div>
    </div>
  </div>

<button (click)="loadPlayer()" class="btn btn-primary ">Load video </button>
<button (click)="showPLayerMedia(true)" class="btn btn-primary ">Local</button>
<button (click)="showPLayerMedia(false)" class="btn btn-default" style="color: black">Dev Files</button>