<app-header></app-header>
<section class="accountSec main-body bg-gray-7 h-100">

    <div class="container d-flex flex-column h-100">
        <ul class="breadcrumb my-3">
            <li><a href="#">Business Profile</a></li>
            <li>Users</li>
        </ul>
        <div class="accountSecInner br12 bg-white page-shadow h-82vh overflow-hidden" id="row-main">
                <app-account-sidebar class="col-md-3 pe-0 pb-0 border-end position-relative sidebar d-flex flex-column h-100"
                id="sidebar-left"></app-account-sidebar>
            <div class="col-md-9 ps-0 d-flex flex-column h-100" id="content">
                <div class="video-header row m-0 ms-3 px-4 pt-4">
                    <p class="h3 weight-600 text-gray-2 pb-1 px-0 col-lg-8 mb-0">
                        <a href="BusinessPlus_1_User.html" class="me-2">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.01519 15.1432C8.21658 15.3392 8.21658 15.657 8.01519 15.853C7.81379 16.049 7.48727 16.049 7.28588 15.853L0.55383 9.30125C-0.18461 8.58259 -0.18461 7.41741 0.55383 6.69874L7.28588 0.146999C7.48727 -0.0489997 7.81379 -0.0489997 8.01519 0.146999C8.21658 0.342998 8.21658 0.660776 8.01519 0.856774L1.28314 7.40852C1.2526 7.43824 1.22484 7.46964 1.19986 7.50241L15.4823 7.50241C15.7682 7.50241 16 7.72627 16 8.00242C16 8.27856 15.7682 8.50242 15.4823 8.50242L1.20358 8.50243C1.22759 8.53345 1.25411 8.56323 1.28314 8.59148L8.01519 15.1432Z"
                                    fill="#404243" />
                            </svg>
                        </a>
                        Harvey Ellen
                    </p>
                    <div class="ps-4 ms-2 mb-3">
                        <a href="#" class="text-xs text-gray-2">Admin</a> .
                        <a href="#" class="text-xs text-gray-3">Active</a>
                    </div>
                </div>
                <div class="container mx-0 ms-4 pe-0 ps-3 business-media" id="container-view">
                    <div class="row filter-post">
                        <div class="col-md-12 p-0 ps-3">
                            <ul class="breadcrumb-tabs nav m-0">
                                <li class="nav-item ps-0">
                                    <a href="javascript:void(0)" routerLink="/account-setting/user-account" class="nav-link active">Account</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/account-setting/user-published-videos" href="javascript:void(0)" class="nav-link">Published Video</a>
                                </li>
                                <li class="nav-item">
                                    <a href="javascript:void(0)" routerLink="/account-setting/user-schedule-video" class="nav-link">Scheduled Video</a>
                                </li>
                                <li class="nav-item">
                                    <a href="javascript:void(0)" routerLink="/account-setting/user-media-libraries" class="nav-link ">Media
                                        Libraries</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="px-4  flex-grow-1">
                    <div class="container mx-0 ms-1 pe-0 ps-3 business-media d-flex flex-column h-100"
                        id="container-view">
                        <div class="py-4 mt-3">
                            <div class="row row-cols-md-2 g-0 justify-content-between">
                                <div class="loginSecItmForm pt-0" aria-labelledby="loginYourself">
                                    <form>
                                        <div class="row">
                                            <div class="col-md-5">
                                                <div class="formItm">
                                                    <input id="companyName" type="text" value="John Smith"
                                                        placeholder="John Smith" name="textfeild" required>
                                                    <label for="companyName" class="text-sm">User Name</label>
                                                </div>
                                            </div>

                                            <div class="offset-md-1 col-md-6">
                                                <div class="formItm">
                                                    <input id="email" name="email" type="email"
                                                        placeholder="Email Address" value="Si@ja.net" required>
                                                    <label for="email" class="text-sm">Email Address</label>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="formItm down_arrow pt-0">
                                                    <p class="text-md text-gray-3 mb-0">Select Role</p>
                                                    <div class="dropdown dropdownSelect">
                                                        <button class="dropdown-toggle text-md" type="button"
                                                            data-bs-toggle="dropdown" aria-expanded="false">
                                                            Admin
                                                        </button>
                                                        <ul class="dropdown-menu dropdownMenu">
                                                            <li class="selected"><a class="dropdown-item text-md"
                                                                    href="#;">Admin</a></li>
                                                            <li class="selected"><a class="dropdown-item text-md"
                                                                    href="#;">Billing</a></li>
                                                            <li class="selected"><a class="dropdown-item text-md"
                                                                    href="#;">Creator</a></li>
                                                            <li class="selected"><a class="dropdown-item text-md"
                                                                    href="#;">Viewer</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 px-4 ">
                                                <div class="row ac-overview">
                                                    <div class="col-md-12 px-0">
                                                        <p class="text-lg text-gray-2 pb-0 mb-2">Email Preferences</p>
                                                        <p class="text-xs text-gray-3 pb-1">You're currently subscribed
                                                            to our email list.</p>
                                                        <div class="form-check mb-3 tableCustom">
                                                            <div class="tableCheck">
                                                                <input
                                                                    class="form-check-input bg-primary-1 shadow-none "
                                                                    type="checkbox" checked value=""
                                                                    id="flexCheckDefault">
                                                            </div>
                                                            <label class="form-check-label text-sm text-gray-1 mt-1"
                                                                for="flexCheckDefault">
                                                                Account Changes
                                                            </label>
                                                        </div>
                                                        <div class="form-check mb-3 tableCustom">
                                                            <div class="tableCheck">
                                                                <input
                                                                    class="form-check-input  bg-primary-1 shadow-none "
                                                                    type="checkbox" checked value=""
                                                                    id="flexCheckDefault">
                                                            </div>
                                                            <label class="form-check-label text-sm text-gray-1 mt-1"
                                                                for="flexCheckDefault">
                                                                Prontopia Newsletter
                                                            </label>
                                                        </div>
                                                        <div class="my-4 pb-2">
                                                            <a href="#"
                                                                class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btnShadow"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#subscription-popup">Unsubscribe</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 ac-overview mt-4 w-75">
                                                <p class="text-lg text-black weight-600 pb-0 mb-2">Archive Account</p>
                                                <p class="text-xs text-gray-3 pb-1">This will deactivate the user's
                                                    account and the user will no longer be able to access his account.
                                                </p>
                                            </div>
                                            <div class="col-md-12 ac-overview mt-4 w-75">
                                                <p class="text-lg text-black weight-600 pb-0 mb-2">Reset Password</p>
                                                <p class="text-xs text-gray-3 pb-1">This will create a reset password
                                                    link for the user.</p>
                                            </div>
                                            <div>
                                                <p class="text-lg text-black weight-600 mt-4 mb-0 weight-normal">
                                                    Permissions</p>
                                                <span class="text-xs text-gray-3 d-inline-block mt-1 mb-3">share on
                                                    social accounts</span>
                                                <div class="d-flex justify-content-start">
                                                    <div class="form-check mb-2 tableCustom w-auto me-4">
                                                        <div class="tableCheck">
                                                            <input class="form-check-input  shadow-none "
                                                                type="checkbox" value="" id="flexCheckDefault">
                                                        </div>
                                                        <label class="form-check-label text-sm text-gray-1 mt-1"
                                                            for="flexCheckDefault">
                                                            Company Accounts
                                                        </label>
                                                    </div>
                                                    <div class="form-check mb-2 tableCustom w-auto me-4">
                                                        <div class="tableCheck">
                                                            <input class="form-check-input  shadow-none "
                                                                type="checkbox" value="" id="flexCheckDefault">
                                                        </div>
                                                        <label class="form-check-label text-sm text-gray-1 mt-1"
                                                            for="flexCheckDefault">
                                                            Brand Accounts
                                                        </label>
                                                    </div>
                                                    <div class="form-check mb-2 tableCustom w-auto me-4">
                                                        <div class="tableCheck">
                                                            <input class="form-check-input  shadow-none "
                                                                type="checkbox" value="" id="flexCheckDefault">
                                                        </div>
                                                        <label class="form-check-label text-sm text-gray-1 mt-1"
                                                            for="flexCheckDefault">
                                                            Personal Accounts
                                                        </label>
                                                    </div>
                                                </div>
                                                <span class="text-xs text-gray-1 my-3 d-inline-block">Downloads</span>
                                                <div class="form-check mb-4 tableCustom">
                                                    <div class="tableCheck">
                                                        <input class="form-check-input  shadow-none " type="checkbox"
                                                            value="" id="flexCheckDefault">
                                                    </div>
                                                    <label class="form-check-label text-sm text-gray-1 mt-1"
                                                        for="flexCheckDefault">
                                                        Download Video Access
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>