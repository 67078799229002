<div class="video-header row m-0 ms-2 px-4 pt-4 ps-0">
  <p class="h3 weight-600 text-gray-2 pb-1 px-0 col-lg-8 mb-0">
    <a href="javascript:void(0)" routerLink="/account-setting/manage-brand" class="me-2">
      <img src="../../../../../assets/images/svg/back-arrow-with-tail.svg">
    </a>
    <span>{{this.brandDetail.name? this.brandDetail.name: "Brand Name"}}</span>

  </p>
</div>
<div class="container mx-0 ps-0 ms-3 business-media" id="container-view">
  <div class="row filter-post">
    <div class="col-md-12 p-0">
      <ul class="breadcrumb-tabs nav m-0">
        <!-- <li class="nav-item ps-0">
                  <a href="javascript:void(0)" class="nav-link " (click)="loadBrandAccount()"
                    >Account</a
                  >
                </li> -->
        <li class="nav-item">
          <a href="javascript:void(0)" (click)="loadBrandBusiness()" class="nav-link">Business Details</a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/account-setting/manage-brand/', brandId, 'published-videos']" class="nav-link ">Published
            Video</a>
        </li>
        <li class="nav-item">
          <a href="javascript:void(0)" (click)="loadBrandMediaLibraryList()" class="nav-link active">Media Libraries</a>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="row video-header mx-0">
  <ul class="container p-0 mb-1 d-flex align-items-center">
    <li class="col-md-4 mb-2 pe-3">
      <div class="formItm search-icon searchDropdown">
        <input id="search-feild" type="search" value="" [(ngModel)]="searchTermText" placeholder="Search Media Library"
          required="" (keyup.enter)="searchBrand()" />
        <label for="search-feild" class="text-sm" style="cursor: default">Search Media Library</label>
        <img src="../../../../../assets/images/svg/search.svg">
      </div>
      <div class="searchtem" *ngIf="searchTerm != ''" (click)="clearSearchTerm()">
        <div>{{ searchTerm }}</div>
        <mat-icon class="link">close</mat-icon>
      </div>
    </li>
    <li class="col-md-2 mb-2 ps-2" id="selectpreff">
      <div class="formItm down_arrow me-1 mt-4 search-sort-panel">
        <div class="dropdown dropdownBtn">
          <button class="dropdown-toggle text-md" type="button" (click)="openSearchSortTypeDD()"
            (blur)="hideSearchSortTypeDD($event)">
            <span class="dropdownBtnText"><strong>Sort by </strong>
              {{ searchBy == "created" ? "Created" : "" }}
              {{ searchBy == "_id" ? "Email" : "" }}
              {{ searchBy == "name" ? "Name" : "" }}
            </span>
          </button>
          <ul class="dropdown-menu dropdown-menu-end dropdownMenu" [ngClass]="{ showDropDown: showSortByDD }">
            <li>
              <a class="dropdown-item text-md" href="javascript:void(0)" (click)="sortBySearchResult('name')">Name
              </a>
            </li>
            <li>
              <a class="dropdown-item text-md" (click)="sortBySearchResult('created')">Last Created
              </a>
            </li>
          </ul>
        </div>
        <div class="sort-arrows pos-right">
          <!-- <mat-icon *ngIf="sortType == -1" (click)="sortTypeSearch(1)"
              >download</mat-icon
            >
            <mat-icon *ngIf="sortType == 1" (click)="sortTypeSearch(-1)"
              >upload</mat-icon> -->

          <img src="../../../../../assets/images/DataSort-1.svg" height="25px" *ngIf="sortType == -1"
            (click)="sortTypeSearch(1)">
          <img src="../../../../../assets/images/DataSort-3.svg" height="25px" *ngIf="sortType == 1"
            (click)="sortTypeSearch(-1)">
        </div>
        <!-- <select>
                        <option selected>Last Modified</option>
                    </select> -->
      </div>
    </li>
  </ul>
</div>
<div class="flex-grow-1 overflow-auto">
  <div class="my-3">
    <p>
      <span class="text-lg">Total Media Libraries: </span>
      <b class="weight-600"> {{this.brandMediaLibList?.length? this.brandMediaLibList?.length : '0'}}</b>

    </p>
  </div>
  <div class="overflow-auto overflow-table brandmedlist">
    <table class="table tableCustom table-hover mb-0">
      <thead>
        <tr>
          <th scope="col" class="tableCheck">
            <div class="custom-switch">
              <input type="checkbox" class="check form-check-input" id="checkAll" />
            </div>
          </th>
          <th scope="col" class="uname-width">Library Name</th>
          <th scope="col">Users</th>
          <th scope="col">Assets</th>
          <th scope="col">Status</th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let media of this.brandMediaLibList" (click)="loadBrandMediaLib(media)">
          <td scope="row" class="tableCheck">
            <div class="custom-switch">
              <input type="checkbox" class="check form-check-input" />
            </div>
          </td>
          <td>

            <a class="b_userName" (click)="loadBrandMediaLib(media)" href="javascript:void(0)">{{media.name}}</a>
          </td>
          <td>{{media.users? media.users: "-"}}</td>
          <td>{{media?.assets ? media?.assets: "0"}}</td>
          <td>{{media.activation_status? media.activation_status: '-'}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<!-- <div class="row" *ngIf="this.brandMediaLibList?.length > 0">
  <div class="col-sm-12 text-end">
    <mat-paginator
    style="margin-top: 15px;"
    pageIndex="currentPage"
    [length]="brandMediaLibList?.length"
    [pageSize]="limit"
    [pageSizeOptions]="[15, 20, 25, 50]"
    (page)="loadPageEvent($event)"
    showFirstLastButtons
  ></mat-paginator>
  </div>
</div> -->

<!-- Modal pop-up Start-->
<nz-modal [(nzVisible)]="isDeleteModalVisible" nzCentered [nzWidth]="566" [nzFooter]=null [nzClosable]=false
  (nzOnCancel)="handleCancel()">

  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">

      <div class="modal-body">
        <button type="button" class="btn-close cust-close closeRightBtn" (click)="handleCancel()"></button>

        <h5>Are you sure you want to Delete this Brand's Media Library?</h5>
        <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">You'll not be able to watch this Media Library again.
        </p>
        <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" (click)="handleCancel()">Cancel</a>
        <a href="javascript:void(0)" class="mt-3 text-gray-3 text-sm">Delete</a>
      </div>
    </div>
  </div>
</nz-modal>



<!-- Add brand media library (Admin) sidbar end -->