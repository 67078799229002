<nav class="flex-dir">
    <div class="sidebar-header ps-3 pb-3" style="padding: 3px 20px">
        <h3 class="text-gray-2 weight-400 text-lg pt-2">Edit Title
            <p class="mb-0 mt-1 text-xs text-gray-3">These settings apply to single block</p>
        </h3>
        <div class="closeRightSideBar pe-2" style="top: 0;" (click)="DismissSidePanel()" (click)="closeTitle()">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.409957 11.8C0.304968 11.8 0.204978 11.76 0.124987 11.685C-0.0299968 11.53 -0.0299968 11.275 0.124987 11.12L5.33943 5.9L0.114988 0.68C0.0399958 0.605 0 0.505 0 0.4C0 0.295 0.0399958 0.195 0.114988 0.12C0.194979 0.04 0.294969 0 0.399958 0C0.504947 0 0.604936 0.04 0.684927 0.115L5.90437 5.335L11.1188 0.115C11.1938 0.04 11.2938 0 11.3988 0C11.5038 0 11.6038 0.04 11.6838 0.115C11.8387 0.27 11.8387 0.525 11.6838 0.68L6.46931 5.9L11.6788 11.11C11.7538 11.185 11.7938 11.285 11.7938 11.39C11.7938 11.495 11.7538 11.595 11.6788 11.675C11.6038 11.755 11.5038 11.79 11.3938 11.79C11.2838 11.79 11.1888 11.75 11.1088 11.675L5.90437 6.465L0.689927 11.685C0.614935 11.76 0.514945 11.8 0.409957 11.8Z"
                        fill="#7A7A7A"></path>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="11.8" height="11.8" fill="white"></rect>
                    </clipPath>
                </defs>
            </svg>

        </div>
    </div>
    <div class="side-content pt-0 pb-0">
        <div class="video_wrapper video_wrapper_full js-videoWrapper" *ngIf="this.videoManager?.selectedVideo?.video_url !==''">
            <video #myVideoO *ngIf="this.videoUrl" controls disablepictureinpicture controlsList="nodownload noplaybackrate" class="videoIframe js-videoIframe" [muted]="true" [src]="this.videoUrl" [currentTime]="this.currentTime[0]" (timeupdate)="onTimeUpdate($event)"
                [id]="this.videoManager?.selectedVideo?.fpath">
            </video>
            <button class="videoPoster js-videoPoster myvid" (click)="playvideo()"></button>
            <nz-slider [nzMin]="0" style="width:100%; position: absolute; bottom:6px" nzRange [(ngModel)]="this.currentTime" [nzMax]="this.videoManager.selectedVideo?.duration" [nzIncluded]="true"></nz-slider>

        </div>
    </div>
    <ul class="nav breadcrumb-tabs" id="myTab" role="tablist">
        <li *ngFor="let tab of lSidePanelBlockTabsOptions" class="nav-item pe-0" role="presentation" (click)="TextSidepanelComponent_SelectedTab(tab)">
            <button class="nav-link" [class.active]="ActiveSelectedTabValue == tab.value" id="{{tab.value}}" data-bs-toggle="tab" type="button" role="tab" aria-controls="tab1" aria-selected="true">{{tab.name}}</button>
        </li>
    </ul>
    <div class="side-content flex-grow-1 border-top" style="padding: 15px 22px">
        <div class="tab-content text-gray-3 text-xs" id="myTabContent">
            <div *ngIf="ActiveSelectedTabValue == 'tab2'">
                <!-- <div class="col-sm-12 col-md-12 col-lg-12 custom-input">

                    <div class="formItm formItmPad">

                        <input id="titleName" type="text" [disabled]=" this.selectedBlockContent?.text.length>39" [(ngModel)]="this.text" placeholder="Title" class="" [maxLength]="maxTextLength">
                        <span class="titles-numbers">1</span>
                        <span class="length-numbers">{{this.text.length}}/{{maxTextLength}}</span>
                    </div>
                    <div class="text-danger" style="
                    margin-top: -20px;
                " *ngIf="this.text.length == 0">
                        This field is required.
                    </div>

                </div> -->

                <div class="col-sm-12 col-md-12 col-lg-12 custom-input" *ngFor="let content of textFields; let i = index ; trackBy:trackByFn">

                    <div class="formItm formItmPad">

                        <input id="titleName" type="text"
                        [(ngModel)]="textFields[i]" placeholder="Title" class="" [maxLength]="maxTextLength">
                        <span class="titles-numbers">{{i+1}}</span>
                        <span class="length-numbers">{{content.length}}/{{maxTextLength}}</span>
                    </div>
                    <!-- <div class="text-danger" style="
                    margin-top: -20px;
                " *ngIf="content.length == 0">
                        This field is required.
                    </div> -->

                </div>
            </div>
            <div *ngIf="ActiveSelectedTabValue == 'ta'">
                <div class="side-content p-2 pt-0 flex-grow-1">

                    <label for="" class="text-gray-2 mt-4 text-md">Edit Elements</label>
                    <p class="text-gray-3 text-xs mb-3">
                        Please select the element you want to edit.
                    </p>
                </div>
            </div>
            <div *ngIf="ActiveSelectedTabValue == 'tab3'">
                <pronto-system-fonts-dropdown [isPopup]="true" [selectedVideoFontFile]="selectedVideoFontFile"
                    [brandFonts]="this.videoManager?.videoBrand?.fonts" (setFontFile)="assignFontToVideo($event)">
                </pronto-system-fonts-dropdown>
                <div class="theme-color d-flex mt-4">
                    <ul class="m-0 ps-0 pe-4">
                        <label class="d-block text-md text-gray-2 pb-3">Font Color</label>
                        <li>
                            <div class="clr-field" [style.color]="oFontColor ? oFontColor : defaultVideoColor">
                                <button aria-labelledby="clr-open-label"></button>
                                <input class="form-check-input coloris" type="checkbox" id="checkboxNoLabel"
                                    [value]="oFontColor ? oFontColor : defaultVideoColor" [(ngModel)]="oFontColor" [ngModelOptions]="{
                                                      standalone: true
                                                  }" (change)="setFontColor($event)" aria-label="..." />
                                <figure class="color-pic no-bg">
                                    <img class="color-picker"
                                        [ngClass]="{'white': oFontColor != '#ffffff' && oFontColor != '#FFFFFF' && oFontColor != undefined}"
                                        *ngIf="!oFontColor " src="../assets/images/icons/color-picker.svg" />
                                    <img class="color-picker"
                                        [ngClass]="{'white': (oFontColor != '#ffffff' && oFontColor != '#FFFFFF' && oFontColor != undefined)}"
                                        *ngIf="oFontColor" class="color-picker" src="../assets/images/icons/check-icon.svg" />
                                </figure>
                            </div>
                        </li>
                    </ul>
            
            
                </div>
            </div>

        </div>
    </div>
    <div class="side-footer side-content d-flex align-items-center">
        <button class=" btn btn-primary btn-round-6 d-inline-block small-pad px-4 dismissSidepanel" (click)="VideoTimelineComponent_SaveEdits()">Replace</button>
        <button class="d-inline-block small-pad text-gray-3 font-weight-noral text-md ms-3 dismissSidepanel" (click)="VideoTimelineComponent_RevertChanges()">
            Revert to original
        </button>

    </div>
</nav>