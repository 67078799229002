import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import { cSessionService } from 'src/app/services/session.service';
import { VideoManagerService } from '../../../Managers/video-manager.service';
import { ErrorService } from './../../../services/error.service';

@Component({
    selector: 'app-timeline-view-pronto',
    templateUrl: './timeline-view-pronto.component.html',
    styleUrls: ['./timeline-view-pronto.component.scss'],
})
export class TimelineViewProntoComponent implements OnInit {
    oGeneratedVideo: any;
    sVideoId: string;
    sUserEmail: string;
    sSessionId: string;

    constructor(
        public videoManager: VideoManagerService,
        public errorService: ErrorService,
        public loader: LoaderService,
        public oSessionService: cSessionService,
    ) {}

    ngOnInit(): void {
        this.sSessionId = this.oSessionService.SessionService_GetSessionId();
        this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
        this.oGeneratedVideo = this.videoManager.selectedVideo;
    }
    async TimelineViewProntoComponent_DownloadVideo() {
        if (
            !this.videoManager.selectedVideo ||
            this.videoManager.selectedVideo == undefined ||
            this.videoManager.selectedVideo.video_id == ''
        ) {
            this.sVideoId = this.oSessionService.SessionService_GetVideoId();
            await this.videoManager.getVideoById(this.sUserEmail, this.sSessionId, this.sVideoId).then((res) => {
                this.videoManager.selectedVideo = res;
            });
        }
        if (!this.videoManager.selectedVideo.video_url) {
            this.videoManager.selectedVideo.video_url = this.oSessionService.SessionService_GetVideoUrl();
        }
        console.log('video' + this.videoManager.selectedVideo);
        let videoName = this.videoManager.selectedVideo?.title + ".mp4";;
        this.loader.showLoader();
        fetch(this.videoManager.selectedVideo?.video_url)
            .then((response) => response.blob())
            .then((blob) => {
                this.loader.hideLoader();
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                // link.download = "pronto.mp4";
                link.download = videoName && videoName != '' ? videoName : 'pronto.mp4';
                link.click();
            })
            .catch((error) => {
                this.loader.hideLoader();
                console.error('TimelineViewProntoComponent_DownloadVideo : ==>', error);
            });
    }
}
