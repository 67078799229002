<nav style="position: relative; height: 100%">
    <div class="sidebar-header ps-3 pb-3" style="padding: 3px 20px">
        <h3 class="text-gray-2 weight-400 text-lg pt-2">
            Edit Background Music
            <p class="mb-0 mt-1 text-xs text-gray-3">Hover to preview a sound, and select to add it to your video.</p>
        </h3>
        <div class="closeRightSideBar pe-2" style="top: 0;" (click)="closeAudio()">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.409957 11.8C0.304968 11.8 0.204978 11.76 0.124987 11.685C-0.0299968 11.53 -0.0299968 11.275 0.124987 11.12L5.33943 5.9L0.114988 0.68C0.0399958 0.605 0 0.505 0 0.4C0 0.295 0.0399958 0.195 0.114988 0.12C0.194979 0.04 0.294969 0 0.399958 0C0.504947 0 0.604936 0.04 0.684927 0.115L5.90437 5.335L11.1188 0.115C11.1938 0.04 11.2938 0 11.3988 0C11.5038 0 11.6038 0.04 11.6838 0.115C11.8387 0.27 11.8387 0.525 11.6838 0.68L6.46931 5.9L11.6788 11.11C11.7538 11.185 11.7938 11.285 11.7938 11.39C11.7938 11.495 11.7538 11.595 11.6788 11.675C11.6038 11.755 11.5038 11.79 11.3938 11.79C11.2838 11.79 11.1888 11.75 11.1088 11.675L5.90437 6.465L0.689927 11.685C0.614935 11.76 0.514945 11.8 0.409957 11.8Z"
                        fill="#7A7A7A"></path>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="11.8" height="11.8" fill="white"></rect>
                    </clipPath>
                </defs>
            </svg>
        </div>
    </div>
    <div class="side-content flex-grow-1 pt-0">
        <div class="replaceVideoTop">
            <div class="replaceVideoTopAction d-flex justify-content-between align-items-center">
                <div class="radio_select_wrapp2">
                    <label class="radio_select" *ngFor="let option of lBreadcrumbOptions ; let i = index;"><input
                            type="radio" name="filterEditClip1" class="radio_input_element"
                            [checked]="option.filter==oVisualType" (click)="changeTab(option.filter)">
                        <div class="radio_caption" style="padding: 5px !important;">
                            <span *ngIf="option.filter == 'pronto'" style="font-family: 'poppins';">{{option.name}} </span>
                            <span *ngIf="option.filter == 'myassets'" style="font-family: 'poppins';">
                        {{(this.onBoarding?.profile?.user_account_type != 'enterprise') ? 'My Library'
                        : 'Brand Library' }}</span></div>
                    </label>
                    <label id="selectpreff">
                        <div class="formItm down_arrow pt-0 pb-0 mb-0" style="width:133px">
                            <div class="dropdown dropdownBtn dropdown-pronto" *ngIf="oVisualType == 'pronto'">
                                <button class="dropdown-toggle text-md" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span class="dropdownBtnText text-capitalize" style="font-family: 'poppins'; font-size: 11px;">Sort by
                                        {{(!selectedGenre || selectedGenre == 'All' || selectedGenre == '')? 'Genre' : selectedGenre}}</span>
                                </button>
                                <ul  style="max-height: 300px; overflow-y: auto;" class="dropdown-menu dropdown-menu-end dropdownMenu fancy-scroll">
                                    <li class="custom-check"
                                        (click)="selectGenre('')">
                                        <a class="dropdown-item text-md text-capitalize" href="javascript:void(0)" [ngClass]="'' == selectedGenre?'bold':''">All</a></li>
                                    <li *ngFor="let genre of genreList" class="custom-check"
                                        (click)="selectGenre(genre)">

                                        <a class="dropdown-item text-md text-capitalize" href="javascript:void(0)" [ngClass]="genre == selectedGenre?'bold':''">{{genre}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </label>
                    <img *ngIf="!this.bTurnOff" class="mt-2 ms-0" height="19px" width="19px" matTooltip="Turn Off Music" matTooltipClass="custom-tooltip" matTooltipPosition="right" (click)="turnOffMusic(true)" style="margin-left: 8px !important;"
                       src="../../../assets/images/svg/speaker.svg" />
                    <img *ngIf="this.bTurnOff" class="mt-2 ms-0" height="19px" width="19px" matTooltip="Turn On Music" matTooltipClass="custom-tooltip" matTooltipPosition="right" (click)="turnOffMusic(false)" style="margin-left: 8px !important;" src="../../../assets/images/svg/mute .svg" />

                        <button class="ms-2" (click)="openFileUploadModal()" matTooltip="Upload" matTooltipClass="custom-tooltip"
                            matTooltipPosition="above">
                            <img src="../assets/images/upload.svg" style="margin-top:-3px" width="17" height="16" class="svg-hover" />
                        </button>
                </div>
            </div>
        </div>
        <div class="" style="height: calc( 100vh - 331px );">
            <div class="weight-600 spinner text-center w-100 ReplaceClipSpinner" *ngIf="this.isGenre == true">
                <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div *ngIf="this.isGenre == false" class="row row-cols-2 replaceVideoRow pronto-asset-audio">
                <div *ngFor="let visual of  this.lUserVisualsList; let i = index" style="position: relative;">
                    <div class="audioCard">
                        <div class="audioCardTop">
                            <button class="muteBtn btn-hover-w"
                                (mouseover)="AudioSidePanelComponent_PlayAudio(visual.url)"
                                (mouseout)="AudioSidePanelComponent_StopAudio(visual.url)">
                                <img src="../assets/images/microphone-off.svg" class="off"
                                    style="margin-top: 0px;margin-left: 4px;" width="14" height="13" />
                                <img src="../assets/images/microphone.svg" class="on"
                                    style="margin-top: 0px;margin-left: 4px;" width="14" height="13" />
                            </button>
                            <div class="audioCardTopWave" (mouseover)="AudioSidePanelComponent_PlayAudio(visual.url)"
                                (mouseout)="AudioSidePanelComponent_StopAudio(visual.url)">
                                <img src="../assets/images/audioWave.png" alt="audioWave" />
                            </div>
                        </div>
                        <div class="audioCardBody">
                            <p class="text-xs m-0 text-gray-2">{{visual?.visual_path | fileNameOnly:22}}</p>
                            <p *ngIf="visual?.name === ''" class="text-xs m-0 text-gray-2">Audio file.</p>
                        </div>
                        <div class="audioCardFooter">
                            <p class="m-0 text-xxs text-gray-6">{{ (visual.duration) ? (visual.duration | secondsToTime) : (0 | secondsToTime)}}</p>
                        </div>
                    </div>
                    <div class="custom-radiobtn">
                        <label>
                            <input type="radio" class="check form-check-input radioButton" [checked]="visual.isSelected"
                                (click)="changeVisual(i,visual)">
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Pagination -->
    <ul id="pagination" class="ps-3">
        <li>
            <a href="javascript:void(0)" class="preview-page"
                (click)="nCurrentPage==1?'':MediaLibrarySharedComponent_FetchPage(nCurrentPage-1)">
                <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M5.25063 0.63694C5.39531 0.491231 5.39531 0.25499 5.25063 0.109281C5.10596 -0.0364277 4.8714 -0.0364277 4.72673 0.109281L0.944888 3.91824C0.351705 4.51568 0.351703 5.48432 0.944887 6.08175L4.72673 9.89072C4.8714 10.0364 5.10596 10.0364 5.25064 9.89072C5.39531 9.74501 5.39531 9.50877 5.25064 9.36306L1.46879 5.5541C1.16495 5.24808 1.16495 4.75192 1.46879 4.4459L5.25063 0.63694Z"
                        fill="#7A7A7A" />
                </svg>
            </a>
        </li>
        <li *ngFor="let page of lPagination;index as i">
            <a href="javascript:void(0)" (click)="MediaLibrarySharedComponent_FetchPage(page)"
                [ngClass]="i+1==nCurrentPage?'add_color':''">{{page}}</a>
        </li>
        <li>
            <a href="javascript:void(0)" class="next-page"
                (click)="nCurrentPage==lPagination.length?'':MediaLibrarySharedComponent_FetchPage(nCurrentPage+1)">
                <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.749366 0.63694C0.604694 0.491231 0.604694 0.25499 0.749366 0.109281C0.894037 -0.0364277 1.1286 -0.0364277 1.27327 0.109281L5.05511 3.91824C5.6483 4.51568 5.6483 5.48432 5.05511 6.08175L1.27327 9.89072C1.1286 10.0364 0.894036 10.0364 0.749364 9.89072C0.604692 9.74501 0.604693 9.50877 0.749364 9.36306L4.53121 5.5541C4.83505 5.24808 4.83505 4.75192 4.53121 4.4459L0.749366 0.63694Z"
                        fill="white" />
                </svg>
            </a>
        </li>

    </ul>
    <div class="side-footer side-content d-flex align-items-center">
        <button class=" btn btn-primary btn-round-6 d-inline-block small-pad px-4 closeSideBar"
            (click)="AudioSidePanelComponent_SaveMusicFileForVideo()">Replace</button>
        <button *ngIf="bSavedNotClicked" class="d-inline-block small-pad text-gray-3 font-weight-noral text-md ms-3"
            (click)="AudioSidePanelComponent_RevertMusicFile()">
            Revert to original
        </button>
    </div>


</nav>

<!-- Modal pop-up Start-->
<div class="modal" id="uploadModalAudio" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg drag-dropfile">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Upload a Audio File</h5>
                <button type="button" #closeModal class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="form-group files">
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-device" role="tabpanel"
                            aria-labelledby="pills-device-tab">

                            <div class="file-drop-area">
                                <figure>
                                    <svg width="23" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M8.47616 9.28091C8.47609 9.55343 8.25483 9.77429 7.98195 9.77421C7.70907 9.77412 7.48792 9.55313 7.48799 9.2806L7.48988 1.91053L4.38487 5.32164C4.20131 5.52329 3.90371 5.52329 3.72015 5.32164C3.5366 5.11999 3.5366 4.79304 3.72015 4.59139L7.65835 0.264948C7.84191 0.0632953 8.13951 0.0632953 8.32307 0.264948L12.2613 4.59139C12.4448 4.79304 12.4448 5.11999 12.2613 5.32164C12.0777 5.52329 11.7801 5.52329 11.5965 5.32164L8.47805 1.89571L8.47616 9.28091ZM0.59826 6.72675C0.871136 6.72675 1.09235 6.94767 1.09235 7.2202V8.45374C1.09235 11.9966 3.96807 14.8687 7.51545 14.8687H8.50559C12.053 14.8687 14.9287 11.9966 14.9287 8.45374V7.2202C14.9287 6.94767 15.1499 6.72675 15.4228 6.72675C15.6957 6.72675 15.9169 6.94767 15.9169 7.2202V8.45374C15.9169 12.5417 12.5987 15.8556 8.50559 15.8556H7.51545C3.42232 15.8556 0.104176 12.5417 0.104176 8.45374V7.2202C0.104176 6.94767 0.325385 6.72675 0.59826 6.72675Z"
                                                fill="#7A7A7A" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="16.0013" height="15.9809" fill="white"
                                                    transform="translate(0 0.00958252)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </figure>
                                <span class="choose-file-button">Upload a File (Device)</span>
                                <span class="text-line">Click to <font class="text-primary-3">browse</font>, or<br>
                                    drag & drop your file here</span>
                                <span *ngIf="sUploadedVoiceOverFileName"
                                    class="file-message">{{sUploadedVoiceOverFileName}} file selected</span>
                                <input #file class="file-input" type="file" accept="audio/mp3"
                                    (change)="ContentComponent_UploadedVoiceOverFile($event)">
                            </div>
                        </div>
                        <ul class="nav mb-3" id="pills-tab" role="tablist">
                            <li class="col nav-item" role="presentation">
                                <button class="nav-link" (click)="file.click()" id="pills-device-tab"
                                    data-bs-toggle="pill" data-bs-target="#pills-device" type="button" role="tab"
                                    aria-controls="pills-device" aria-selected="true">
                                    <img src="../assets/images/business_Profile/device.png" alt="tab-icon">
                                    <span>My Device</span></button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal pop-up End-->