import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CreateVideoManagerService } from 'src/app/Managers/create-video-manager.service';
import { content, overlayTexts } from 'src/app/models/video';

@Component({
  selector: 'app-sap-tagline-sidepanel',
  templateUrl: './sap-tagline-sidepanel.component.html',
  styleUrls: ['./sap-tagline-sidepanel.component.scss']
})
export class SapTaglineSidepanelComponent implements OnInit {
  @Input() selectedBlock: overlayTexts = new overlayTexts();
  // @Output() oEmittedBlockToSave = new EventEmitter();
  // @Output() oEmittedBlockToRevert = new EventEmitter();
  // @Output() oEmittedRemoveHighlightBlock = new EventEmitter();
  @Output() onCloseTagline = new EventEmitter<any>();
  @ViewChild('myVideoO') myVideoO: ElementRef;
  selectedBlockCopy: content;
  sUserEmail: string;
  sSessionId: string;
  sVideoId: string;
  videoUrl: string;
  tagline = '';
  taglinePlaceholder = 'titles-numbers';
  public text: string[] = [];
  ActiveSelectedTabValue: string = "tab2";
  lSidePanelBlockTabsOptions: { name: string; value: string; }[];

  constructor(public videoManager: CreateVideoManagerService,) { }

  ngOnInit(): void {
    this.videoUrl = this.videoManager.selectedVideo?.video_url;
    if (this.videoManager.selectedVideo?.sap_tag_line && this.videoManager.selectedVideo?.sap_tag_line != '') {
      this.tagline = this.videoManager.selectedVideo?.sap_tag_line;
    }
    this.ActiveSelectedTabValue = "tab2"
    this.lSidePanelBlockTabsOptions = [
      { name: 'Edit Text', value: "tab2" },
    ]
  }
  TextSidepanelComponent_SelectedTab(tab) {
    this.ActiveSelectedTabValue = tab.value
  }
  //save edits
  VideoTimelineComponent_SaveEdits() {
    if (this.tagline && this.tagline.length >= 3) {
      this.videoManager.selectedVideo.sap_tag_line = this.tagline;
      this.closeTagline();
    }

  }
  //revert changes
  VideoTimelineComponent_RevertChanges() {
    // this.closeTagline();
  }
  closeTagline(): void {

    this.onCloseTagline.emit();
  }

  TimelineViewSidePanelComponent_PlayVideo()
  {
    this.myVideoO.nativeElement.play();
  }
}
