
<style>
  .webgl-content * {border: 0; margin: 0; padding: 0}
.webgl-content {position: absolute; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%);}

.webgl-content .logo, .progress {position: absolute; left: 50%; top: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%);}
.webgl-content .logo {background: url('progressLogo.Light.png') no-repeat center / contain; width: 154px; height: 130px;}
.webgl-content .progress {height: 18px; width: 141px; margin-top: 90px;}
.webgl-content .progress .empty {background: url('progressEmpty.Light.png') no-repeat right / cover; float: right; width: 100%; height: 100%; display: inline-block;}
.webgl-content .progress .full {background: url('progressFull.Light.png') no-repeat left / cover; float: left; width: 0%; height: 100%; display: inline-block;}

.webgl-content .logo.Dark {background-image: url('progressLogo.Dark.png');}
.webgl-content .progress.Dark .empty {background-image: url('progressEmpty.Dark.png');}
.webgl-content .progress.Dark .full {background-image: url('progressFull.Dark.png');}

.webgl-content .footer {margin-top: 5px; height: 38px; line-height: 38px; font-family: Helvetica, Verdana, Arial, sans-serif; font-size: 18px;}
.webgl-content .footer .webgl-logo, .title, .fullscreen {height: 100%; display: inline-block; background: transparent center no-repeat;}
.webgl-content .footer .webgl-logo {background-image: url('webgl-logo.png'); width: 204px; float: left;}
.webgl-content .footer .title {margin-right: 10px; float: right;}
.webgl-content .footer .fullscreen {background-image: url('fullscreen.png'); width: 38px; float: right;}
canvas{ height: 600 !important}
.webgl-content canvas, .webgl-content .canvas{
        height: 600px !important;
    }
</style>
    <!-- <link rel="stylesheet" href="/assets/webplayer/TemplateData/style.css"> -->


    <div class="webgl-content">
      <div id="gameContainer" style="width: 960px; height: 600px"></div>
      <div class="footer">
        <div class="webgl-logo"></div>
        <div class="fullscreen" onclick="gameInstance.SetFullscreen(1)"></div>
        <div class="title">WebPlayer</div>
      </div>
    </div>


