import { BackButtonDataModel, ProntoSettingService } from './services/utils/pronto-setting.service';
import { environment } from 'src/environments/environment';
import { error } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Params, Router } from '@angular/router';
import '@stripe/stripe-js';
import * as moment from 'moment';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { CookieService } from 'ngx-cookie-service';
import { AccountManagerService } from './Managers/account-manager.service';
import { ConfigManagerService } from './Managers/config-manager.service';
import { MediaManagerService } from './Managers/media-manager.service';
import { OnboardingManagerService } from './Managers/onboarding-manager.service';
import { SubscriptionplanManagerService } from './Managers/subscriptionplan-manager.service';
import { VideoManagerService } from './Managers/video-manager.service';
import { profile, user } from './models/onboarding';
import { video } from './models/video';
import { ErrorService } from './services/error.service';
import { LoaderService } from './services/loader.service';
import { cSessionService } from './services/session.service';
import { SuccessMessageModalService } from './services/success-message-modal.service';
import { VoiceDataStore } from './stores/voice.store';
import { VoiceOverService } from './shared/services/voice-over.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'prontoV2';
    sessionId: any;
    email: any;
    guestEmail: any;
    showSuccessModal: boolean = false;
    showSplashScreen: boolean = true;
    sSelectedSupportOption: any = 'Technical Support';
    lSupportOptions: any = [
        { name: 'Technical Support', value: 'Technical Support' },
        { name: 'Customer Support', value: 'Customer Support' },
        { name: 'Account/Billing inquiry', value: 'Account/Billing inquiry' },
        { name: 'Feedback', value: 'Feedback' },
        { name: 'Other', value: 'Other' },
    ];
    isMobileDevice = false;
    public backButtonDialogSetting = { visible: false, text: '' };

    // csv file uplaod status bar
    csvFileUploadBar = false;
    csvUploadMessage = '';

    constructor(
        public meta_info: ConfigManagerService,
        public loader: LoaderService,
        public subscriptionManager: SubscriptionplanManagerService,
        public videoManager: VideoManagerService,
        public accountManager: AccountManagerService,
        public oSessionService: cSessionService,
        public onboardinManger: OnboardingManagerService,
        public router: Router,
        public planManager: SubscriptionplanManagerService,
        public errorService: ErrorService,
        private activatedRoute: ActivatedRoute,
        private cookieService: CookieService,
        public successMessage: SuccessMessageModalService,
        public mediaManager: MediaManagerService,
        public prontoSettingService: ProntoSettingService,
        public voiceStore: VoiceDataStore,
        public voiceService: VoiceOverService,
    ) {
        window.addEventListener('click', this.clickDocument, true);
    }

    clickDocument = (event: any): void => {
        this.prontoSettingService.hideAllDropDown();
    };

    setCookie(name: string, val: string) {
        const date = new Date();
        const value = val;

        // Set it expire in 7 days
        date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);

        // Set it

        // console.log("expires="+date.toUTCString());
        document.cookie = name + '=' + value + '; expires=' + date.toUTCString() + '; path=/';
        // console.log(document.cookie);
    }

    //  getCookie(name: string) {
    //     const value = "; " + document.cookie;
    //     const parts = value.split("; " + name + "=");

    //     if (parts.length == 2) {
    //         return parts.pop().split(";").shift();
    //     }
    // }
    async ngOnInit() {
        this.sessionId = this.oSessionService.SessionService_GetSessionId();
        this.email = this.oSessionService.cSessionService_GetUserEmail();
        this.guestEmail = this.oSessionService.cSessionService_GetGuestEmail();
        console.log('App component loaded ... ');
        try {
            if (this.email && this.sessionId) {
                await this.onboardinManger
                    .getUserProfile(this.email, this.sessionId)
                    .then(async (res: profile) => {
                        console.log('%c<<<<<< User Profile >>>>>', 'color: red; font-size: 22px');
                        console.log('Profile>>:', res);
                        this.oSessionService.setUserOrganization(res.organization_id);
                        this.onboardinManger.profile.organization_id = res.organization_id;
                        this.onboardinManger.profile.organization = res.organization;
                        this.onboardinManger.profile.user_account_type = res.plan;
                        this.onboardinManger.profile.plan = res.plan;
                        this.onboardinManger.profile.user_status = res.user_status;
                        this.onboardinManger.showSAPVideo = false;
                        this.onboardinManger.profile.favourite_voices = res.favourite_voices;
                        this.onboardinManger.profile.selected_voices = res.selected_voices;
                        if (this.onboardinManger.profile.plan == 'enterprise') {
                            if (environment.SHOW_SAP) {
                                this.onboardinManger.showSAPVideo = true;
                            }
                        } else {
                            if (environment.SHOW_SAP && environment.SHOW_SAP_FOR_SMB) {
                                this.onboardinManger.showSAPVideo = true;
                            }
                        }

                        //SHOW SAP ENABLED BY DEFAULT
                        this.onboardinManger.showSAPVideo = true;
                        this.voiceStore.updateUserVoicesFromProfile(this.onboardinManger?.profile);
                        console.log(`%c --IS SAP VIDEO ENABLED -> ${this.onboardinManger.showSAPVideo}`, 'Font-size: 16px; color: green');
                    })
                    .catch((error) => {
                        console.log('Called Failed', error);
                        localStorage.clear();
                        this.router.navigateByUrl('/login');
                    });
            }
        } catch (err) {
            console.log('Called Failed', err);
            localStorage.clear();
            this.router.navigateByUrl('/login');
        }

        await this.meta_info
            .getMetaInfo()
            .then(async (res) => {
                if (this.sessionId && this.email) {
                    this.meta_info.getVoices(this.email, this.sessionId).then();

                    await this.onboardinManger
                        .getUserProfile(this.email, this.sessionId, true)
                        .then((res: any) => {
                            this.onboardinManger.profile.user_account_type = res.plan;

                            this.meta_info.getTemplates2(this.email, this.sessionId, this.onboardinManger.profile.allowed_templates);

                            var route = this.router.url.split('?')[0];
                            console.log( " --- this.router.url ", this.router.url);
                            if (
                                this.router.url.split('?')[0] == '/' ||
                                this.router.url.split('?')[0] == '/login' ||
                                this.router.url.split('?')[0] == '/dashboard/myvideos' ||
                                this.router.url.split('?')[0] == '/dashboard/first-time-user'
                            ) {
                                if (
                                    this.onboardinManger.profile.user_status == 'confirmed_not_subscribed' ||
                                    this.onboardinManger.profile.user_status == 'blocked'
                                ) {
                                    console.log('SHOW PLAN DIALOGG.......');
                                    this.loader.hideLoader();
                                    if (this.router.url.split('?')[0] != '/createAccountCompany') {
                                        this.subscriptionManager.showPlanDialogue();
                                    }
                                    //this.router.navigate(['/create-account/select-plan']);
                                } else {
                                    if (this.onboardinManger.profile.user_status == 'canceled') {
                                        var date = new Date(this.onboardinManger.profile.next_billing_date);
                                        var date2 = moment(new Date(this.onboardinManger.profile.next_billing_date));
                                        var tempDate = moment(new Date());

                                        const diffDays = date2.diff(tempDate, 'days');
                                        if (diffDays > 0) {
                                            let errorMessage = 'Your subscription expires in ' + diffDays + ' days';
                                            setTimeout(() => {
                                                this.errorService.handleError(errorMessage, 'Subscription', true, false, true);
                                            }, 3000);
                                        } else {
                                            this.loader.hideLoader();
                                            if (this.meta_info.metaInfo.stripe_enabled) {
                                                this.router.navigate(['/create-account/select-plan']);
                                            }
                                        }
                                    }
                                }

                                this.checkforredirect();
                            }
                        })
                        .catch((err) => {
                            //   this.router.navigate(['login']);
                        });
                } else {
                    console.log('not logged in ', this.router.url);
                    this.verifyImpactCookie();
                    if (
                        this.router.url.split('?')[0] != '/verify_reset_password' &&
                        this.router.url.split('?')[0] != '/ft/overview' &&
                        this.router.url.split('?')[0] != '/ft/login' &&
                        this.router.url.split('?')[0] != '/verify_email' &&
                        this.router.url.split('?')[0] != '/reset_password' &&
                        this.router.url.split('?')[0] != '/reset-password' &&
                        this.router.url.split('?')[0].indexOf('/webplayer') == -1 &&
                        this.router.url.split('?')[0] != '/forgot-password' &&
                        this.router.url.split('?')[0] != '/createAccount' &&
                        this.router.url.split('?')[0] != '/create-account/paywall-success' &&
                        this.router.url.split('?')[0] != '/create-account/paywall-failed' &&
                        this.router.url.indexOf('/dashboard/download-video') == -1 &&
                        this.router.url.split('?')[0] != '/verify-email'
                    ) {
                        // http://localhost:4200/dashboard/download-video/asdfasdfasdfds@tt.com/234234234234234
                        console.log(this.router.url);
                        console.log(
                            ' this.router.url.indexOf/dashboard/download-video > -1',
                            this.router.url.indexOf('dashboard/download-video'),
                        );
                        if (!this.router.url.split('?')[0].includes('/login')) {
                            console.log('REDIRECT TO LOGIN ');
                            this.router.navigate(['login']);
                        }
                    }
                }
            })
            .catch((err) => {})
            .finally(() => {
                this.showSplashScreen = false;
            });
        this.disableRightClickOnVideoElement();

        this.prontoSettingService.getBackButtonDialogTextSubject().subscribe((data: BackButtonDataModel) => {
            this.backButtonDialogSetting.text = data.text ? data.text : '';
            this.backButtonDialogSetting.visible = data.visible ? data.visible : false;
        });
        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)
        ) {
            this.isMobileDevice = true;
        }

        /*
     all Subscribed Subjects are here
     */
        this.subscribedFunctions();
        // this.loadMusic();
    }

    disableRightClickOnVideoElement() {
        let chatScript = document.createElement('script');
        chatScript.type = 'text/javascript';
        chatScript.text = `
    $(document).ready(function() {
      $(document).on("contextmenu", "video", function(e){
        e.preventDefault();
     });

    });`;
        document.body.appendChild(chatScript);
    }

    ImpactCookieName = 'pronto-impact';
    verifyImpactCookie() {
        this.activatedRoute.queryParams.subscribe((params: Params) => {
            if (params && params['irclickid'] && params['irclickid'] != '') {
                const cookieVal: string = params['irclickid'].toString() + '';
                this.cookieService.set(this.ImpactCookieName, cookieVal, { expires: 1 });
            }
        });
    }

    private checkforredirect() {
        console.log('CHECK REDIRECTION .....');
        this.activatedRoute.queryParams.subscribe((params: Params) => {
            let video_id = params['video_id'];

            const authResult = this.activatedRoute.snapshot.queryParams['auth_result'];

            //social share response
            const socialShareResult = this.activatedRoute.snapshot.queryParams['result'];

            this.oSessionService.cSessionService_SetRedirectionMessage(this.activatedRoute.snapshot.queryParams['result']);
            //redirection message
            const message = this.activatedRoute.snapshot.queryParams['message'];

            let nRedirectURl = this.oSessionService.cSessionService_GetRedirectURL();
            if(this.activatedRoute.queryParams && params){
              console.log(params);
              console.log(this.activatedRoute.snapshot.queryParams['page']);
              console.log(this.activatedRoute.snapshot.queryParams['id']);
            }

            if(this.activatedRoute.snapshot.queryParams['page'] && this.activatedRoute.snapshot.queryParams['page'] =='webplayer'){
              //Dont redirect to other page .
            } else {
              console.log(' Redirection url ', nRedirectURl, this.activatedRoute.queryParams);
              console.log(this.activatedRoute);
              if (nRedirectURl == 1) {
                  this.router.navigateByUrl('/account-setting/social-channels');
                  this.resetRedirectCache();
                  return;
              } else if (nRedirectURl == 2) {
                  this.router.navigateByUrl('/account-setting/myAccount');

                  this.resetRedirectCache();
              } else if (nRedirectURl == 3) {
                  video_id = this.oSessionService.SessionService_GetVideoId();

                  this.router.navigateByUrl('/dashboard/socialmedia');
                  this.resetRedirectCache();
                  return;
              } else if (nRedirectURl == 4) {
                  video_id = this.oSessionService.SessionService_GetVideoId();

                  this.router.navigateByUrl('/published-video/timeline-view');

                  this.resetRedirectCache();
                  return;
              } else {
                  if (this.onboardinManger.loggedUser.getVideoCount() > 0) {
                      this.router.navigateByUrl('/dashboard/myvideos');
                  } else {
                      this.router.navigateByUrl('/dashboard/first-time-user');
                  }
              }
            }

        });
    }

    private resetRedirectCache() {
        setTimeout(() => {
            localStorage.removeItem('lsSetRedirectionMessage');
            localStorage.removeItem('lsSocialConnectRedirectURL');
        }, 3000);
    }

    // titleCaseWord(word: string) {/*  */
    //   if (!word) return word;
    //   return word[0].toUpperCase() + word.substr(1).toLowerCase();
    // }
    closeErrorModal() {
        if (this.errorService.errorMessage == 'Registered user already exists') {
            this.router.navigateByUrl('/login');
        }
        if (this.errorService.errorTitle == 'Reset Password Error') {
            this.router.navigateByUrl('/createAccount');
        }
        if (this.errorService.errorTitle == 'Email Already in Use') {
            this.router.navigateByUrl('/login');
        }
        if (this.errorService.errorTitle == 'Already Registered') {
            this.router.navigateByUrl('/login');
        }
        this.errorService.closeErrorDialogue();
    }
    goToSubscribe() {
        this.accountManager
            .ManageBilling(this.onboardinManger.loggedUser.email, this.onboardinManger.loggedUser.session_id)
            .then((url: string) => {
                window.location.href = url;
            });
        this.errorService.closeErrorDialogue();
    }
    closePlanModal() {
        this.planManager.closePlanDialogue();
    }

    replaceDuplicatedImage() {
        this.mediaManager.duplicatedImageReplaceEmitter.emit();
    }

    openMediaReplacementPopup() {
        console.log('OPen media dialog ...');
        this.mediaManager.openMediaReplacementPopup();
    }

    visibleContactSupport = false;
    placementContactSupport: NzDrawerPlacement = 'right';
    openContactSupport(): void {
        this.visibleContactSupport = true;
    }
    closeContactSupport(): void {
        this.visibleContactSupport = false;
    }
    closeBackButtonDailog(): void {
        this.prontoSettingService.closeBrowserBackButtonDialog();
    }

    redirectToBackPage(): void {
        this.prontoSettingService.closeBrowserBackButtonDialog();
        this.router.navigateByUrl('/dashboard/myvideos');
    }

    forgotPassword() {
        this.router.navigateByUrl('/forgot-password');
        this.errorService.closeErrorDialogue();
    }
    upgradeSelction() {
        (this.voiceStore.showSelectionConfirmation = false), (this.planManager.planDialogue = true);
    }
    confirmVoiceSelection() {
        this.voiceStore.voiceOverSelectVoice(true);
        this.voiceStore.showSelectionConfirmation = false;
    }

    subscribedFunctions() {
        this.voiceStore.confirmSelectedVoice.subscribe((val: any) => {
            // console.log('subscription called.');
            if (val == true) {
                if (this.voiceStore.lastSelectedVoiceId) {
                    this.voiceStore.lastSelectedVoiceId.selected = !this.voiceStore.lastSelectedVoiceId.selected;
                    this.voiceService.addNewItemInSelectedList(this.voiceStore.lastSelectedVoiceId);
                }
            }
        });
    }

    audioCurrentTime = 0;
    loadMusic() {
        let intervalAudioPlay = setInterval(() => {
            this.audioCurrentTime++;
            if (this.audioCurrentTime >= 100) {
                clearInterval(intervalAudioPlay);
            }
        }, 1000);
    }
    goToStartOver(val) {
        this.oSessionService.guestEmail = '';
        if (val == 'start over') {
            this.router.navigateByUrl('/login');
            this.closeErrorModal();
        } else {
            this.router.navigateByUrl('/dashboard/myvideos');
            this.closeErrorModal();
        }
    }
}
