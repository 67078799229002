import { Component, OnInit } from '@angular/core';
import { cSessionService } from './../../../services/session.service';
import { ActivatedRoute, Router } from '@angular/router';
import { cDataService } from './../../../services/data.service';
import { OnboardingManagerService } from './../../../Managers/onboarding-manager.service';
import { AccountManagerService } from './../../../Managers/account-manager.service';
import { ErrorService } from './../../../services/error.service';
import { environment } from 'src/environments/environment';
declare var $: any;
@Component({
  selector: 'app-sociall-channels',
  templateUrl: './sociall-channels.component.html',
  styleUrls: ['./sociall-channels.component.scss']
})
export class SociallChannelsComponent implements OnInit {
  connected_social_platforms: any;
  oSocialChannel: string;
  oUserName: any;
  sDisplayDisconnectErrorMessage: string;
  bDisplayErrorMessage: boolean = false;
  sSessionId: string;
  sUserEmail: string;
  socialMediaName: string;
  inCommingSocialMedia: string;
  disconnectMediaPopup = false;
  constructor(
    public onboardingManger: OnboardingManagerService,
    public accountManager: AccountManagerService,
    private oSessionService: cSessionService,

    private oDataService: cDataService,
    private oActivatedRoute: ActivatedRoute, public errorService: ErrorService
  ) { }

  ngOnInit() {
    const authResult = this.oActivatedRoute.snapshot.queryParams['auth_result'];
    console.log("SocialChannelsComponent : Auth result ==>", authResult);
    if (authResult != null && (authResult == 'google_auth_successful' || authResult == 'fb_auth_successful' || authResult == 'linkedin_auth_successful')) {
      const email = this.oActivatedRoute.snapshot.queryParamMap.get('email');
      const sessionId = this.oActivatedRoute.snapshot.queryParamMap.get('session_id');
      this.oSessionService.cSessionService_SetUserEmail(email)
      this.oSessionService.cSessionService_SetSessionId(sessionId);
    }

    this.sUserEmail = this.onboardingManger.loggedUser.email;
    this.sSessionId = this.onboardingManger.loggedUser.session_id;
    this.oUserName = this.onboardingManger.loggedUser.name;
    this.oSessionService.setAccountSettingHeaderName("Social Channels");
  }

  SocialChannelsComponent_SocialMediaChannelsLink(oData: string) {
    let sURL: string;
    let return_url = environment.API_HOST + "/account-setting/social-channels";
    if (oData == 'google') {

      sURL = this.oDataService.googleJoinLink + "?" + "email=" + this.sUserEmail + "&session_id=" + this.sSessionId;
    }
    else if (oData == 'facebook') {
      sURL = this.oDataService.faceBookJoinLink + "?" + "email=" + this.sUserEmail + "&session_id=" + this.sSessionId;
    }
    else if (oData == 'linkedin') {
      sURL = this.oDataService.linkedInJoinLink + "?" + "email=" + this.sUserEmail + "&session_id=" + this.sSessionId;
    }
    
    this.oSessionService.cSessionService_SetRedirectURL(1);
    location.assign(sURL);
  }

  isDisconnectModal(data: string): void {    
    this.disconnectMediaPopup = true;
    if (data == 'google') {
      this.socialMediaName = 'Youtube';
    }
    else {
      this.socialMediaName = data;
    }
    this.inCommingSocialMedia = data;
  }

  closeActivateModal(): void {
    this.disconnectMediaPopup = false;
  }

  SocialChannelsComponent_DisconnectSocialChannelOpenModal() {
    this.disconnectMediaPopup = false;
    let value = this.inCommingSocialMedia;
    this.accountManager.disconnectSocialPlatform(this.onboardingManger.loggedUser.email, this.onboardingManger.loggedUser.session_id, value).then(res => {
      switch (value) {
        case 'google':
          {
            this.onboardingManger.profile.connected_social_platforms.google = false;
            break;
          }
        case 'facebook':
          {
            this.onboardingManger.profile.connected_social_platforms.facebook = false;
            break;
          }
        case 'linkedin':
          {
            this.onboardingManger.profile.connected_social_platforms.linkedin = false;
            break;
          }
        default:
          break;
      }
    }).catch(err => {

      this.errorService.handleError(err.errorMessage, err.errorType, true);
    });
  }


  SocialChannelsComponent_UnsubscriptionModal() {
    console.log("working");
    // $('#subscription-popup').modal('show')
  }



}
