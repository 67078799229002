<div class="successMessage"> sharjeel success message
  <h5 *ngIf="this.showHead">{{this.title}}</h5>
        <div class="text-sm text-gray-3 pt-1 pb-4 mb-0" *ngIf="this.showLoader">
          <span *ngClass="{'noHead' : !this.showHead}">{{this.message}} sharjeel is testing</span>
          <div class="lds-default absolute-right" [ngClass]="{'noHead' : !this.showHead}">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>      
</div>