import { Component, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CreateVideoManagerService } from './../../Managers/create-video-manager.service';
import { VideoManagerService } from './../../Managers/video-manager.service';
import { cDataService } from './../../services/data.service';
import { cEditVideoService } from './../../services/edit-video.service';
import { cSessionService } from './../../services/session.service';
import { cWebSocketService } from './../../services/websocket.service';
import { Location } from '@angular/common';
import { ErrorService } from 'src/app/services/error.service';
import { OnboardingManagerService } from 'src/app/Managers/onboarding-manager.service';
import { SubscriptionplanManagerService } from 'src/app/Managers/subscriptionplan-manager.service';
import { LoaderService } from 'src/app/services/loader.service';
import { AspectRatioPopupComponent } from '../components/aspect-ratio-popup/aspect-ratio-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ProntoSettingService } from 'src/app/services/utils/pronto-setting.service';
import { SuccessMessageModalService } from 'src/app/services/success-message-modal.service';
import {  filter, pairwise, defaultIfEmpty } from 'rxjs/operators';

@Component({
    selector: 'app-dashboard-header',
    templateUrl: './dashboard-header.component.html',
    styleUrls: ['./dashboard-header.component.scss'],
})
export class DashboardHeaderComponent implements OnInit {
    bDisplaySchedularBtn: boolean = false;
    bFullHeader: boolean = false;
    oVideoDetails: any;
    bDisplayLoader: boolean = false;
    sUserEmail: string;
    sSessionId: string;
    // sVideoStatus: string;
    oSetTimeOut: any;
    bIsPublish: boolean = true;
    oGeneratedVideo: any = { url: '', thumb: '' };
    showMenu: any;
    oClosePopup: any = 0;
    showHeaderMenu = true;
    delPopup = false;
    unpublishPopup = false;
    sVideoId: any;
    paramsObject: any;
    isEdit = true;
    isTryNowFlow = false;
    incommingUrl: any;
    constructor(
        private oRouter: Router,
        private route: ActivatedRoute,
        private _location: Location,
        private errorService: ErrorService,
        public videoManager: VideoManagerService,
        private createVideoManger: CreateVideoManagerService,
        private oSessionService: cSessionService,
        private oEditVideoService: cEditVideoService,
        public onboardingManager: OnboardingManagerService,
        private oWebSocketService: cWebSocketService,
        private oDataService: cDataService,
        public subscriptionPlan: SubscriptionplanManagerService,
        public loader: LoaderService,
        private dialog: MatDialog,
        public pronto: ProntoSettingService,
        private successService: SuccessMessageModalService,
    ) {
        this.showMenu = this.oRouter.url;
        this.bDisplaySchedularBtn = !this.videoManager.selectedVideo?.schedule;
        if (
            this.oRouter.url === '/dashboard/download' ||
            this.oRouter.url === '/dashboard/socialmedia' ||
            this.oRouter.url === '/dashboard/overview' ||
            this.oRouter.url === '/manage-comments'
        ) {
            this.bFullHeader = true;
        }
        oRouter.events.pipe(
            filter(e => e instanceof NavigationEnd),
            pairwise()          
        ).subscribe(x=>{            
           this.incommingUrl = x[0];    
               console.log("chkUrl", this.incommingUrl);  
               if(this.incommingUrl.url === '/dashboard/myvideos' || this.incommingUrl.url === '/my-videos/draftsVideo' || this.incommingUrl.url === '/my-videos/locationVideos' || this.incommingUrl.url === '/my-videos/publishedVideo')    {
                this.pronto.previousUrl = this.incommingUrl.url; 
                console.log("chkUrl2", this.pronto.previousUrl); 
               }
        })
        }

    async ngOnInit() {
        console.log("chkUrl3", this.pronto.previousUrl); 
        if (this.onboardingManager.profile?.user_status && this.onboardingManager.profile.user_status == 'confirmed_not_subscribed') {
            this.showHeaderMenu = false;
        }

        // this.InitializeJquery();
        this.sSessionId = this.oSessionService.SessionService_GetSessionId();
        this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
        this.pronto.sVideoStatus = this.videoManager.selectedVideo?.publish_status;
        if (!this.videoManager.selectedVideo || this.pronto.sVideoStatus == '' || this.pronto.sVideoStatus == null) {
            this.sVideoId = this.oSessionService.SessionService_GetVideoId();
            if (this.sVideoId != '') {
                if (!this.sSessionId && this.route.snapshot?.params['session_id']) {
                    this.sSessionId = this.route.snapshot?.params['session_id'];
                }
                if (!this.sUserEmail && this.route.snapshot?.params['email']) {
                    this.sUserEmail = this.route.snapshot?.params['email'];
                }
                this.loader.showLoader();
                await this.videoManager
                    .getVideoById(this.sUserEmail, this.sSessionId, this.sVideoId)
                    .then((res) => {
                        this.videoManager.selectedVideo = res;
                        this.pronto.sVideoStatus = this.videoManager.selectedVideo?.publish_status;
                        this.loader.hideLoader();
                        console.log("chkvideoID2... ", this.videoManager.selectedVideo);

                        let sSignedURL = this.videoManager?.selectedVideo?.video_url;

                        if (!sSignedURL && this.oSessionService.cSessionService_GetGuestEmail()) {
                            let emailAdd = this.oSessionService.cSessionService_GetGuestEmail();
                            let sess = this.oSessionService.cSessionService_GetGuestSession();
                            this.isTryNowFlow = true;
                            console.log('Get video data infdo.. ');
                            this.videoManager.getVideoSignedURL(emailAdd, sess, this.videoManager.selectedVideo).then((data) => {
                                this.videoManager.selectedVideo.video_url = data.url;
                                console.log("Signed url found ...", this.videoManager.selectedVideo.video_url);
                            });
                        } else {
                            this.videoManager.getVideoAllSignedURL(this.sUserEmail, this.sSessionId, this.videoManager.selectedVideo).then((data) => {
                                data.output.map((item: any) => {

                                    if (item[this.videoManager.selectedVideo.thumb]) {
                                        this.videoManager.selectedVideo.thumbnail = item[this.videoManager.selectedVideo.thumb];
                                    }
                                })
                            });
                        }
                    })
                    .catch((err) => {
                        this.loader.hideLoader();
                    });
            } else {
                console.log('chkvideoID3', this.sVideoId);
                this.route.queryParamMap.subscribe(async (params) => {
                    this.paramsObject = { ...params.keys, ...params };
                    console.log(this.paramsObject);
                    this.sVideoId = this.paramsObject.params.video_id;
                    console.log('chkvideoID4', this.sVideoId);
                    this.loader.showLoader();
                    await this.videoManager
                        .getVideoById(this.sUserEmail, this.sSessionId, this.sVideoId)
                        .then((res) => {
                            this.videoManager.selectedVideo = res;
                            this.pronto.sVideoStatus = this.videoManager.selectedVideo?.publish_status;
                            this.loader.hideLoader();
                            console.log('chkvideoID5', this.videoManager.selectedVideo);
                        })
                        .catch((err) => {
                            this.loader.hideLoader();
                        });
                });
            }
        }
        this.DashboardHeaderComponent_DisableEdit();

        if (this.pronto.sVideoStatus == 'draft' && this.videoManager.selectedVideo?.fpath.includes('location_videos')) {
            this.isEdit = false;
        }
    }
    DashboardHeaderComponent_DisableEdit() {
        if (this.pronto.sVideoStatus == 'published' || this.pronto.sVideoStatus == 'scheduled') {
            this.bIsPublish = false;
        }
    }

    editVideo() {
        this.createVideoManger.setEditVideo(this.sUserEmail, this.sSessionId, this.videoManager.selectedVideo);
        if (
            (this.videoManager.selectedVideo.sap_video_id && this.videoManager.selectedVideo.sap_video_id != '') ||
            (this.videoManager.selectedVideo.template_name && this.videoManager.selectedVideo?.template_name == 'SAP')
        ) {
            // this.oRouter.navigate(['mv/overview']);
            this.oRouter.navigate(['mv/timeline-view']);
        } else {
            // this.oRouter.navigate(['wizard/overview']);
            this.oRouter.navigate(['wizard/timeline-view']);
        }
    }

    deleteVideo() {
        this.delPopup = false;
        this.videoManager
            .deleteVideo(this.sUserEmail, this.sSessionId, this.videoManager.selectedVideo.fpath)
            .then((_) => {
                if(this.pronto.previousUrl && this.pronto.previousUrl != ''){
                    console.log("chkdel", this.pronto.previousUrl);
                    
                    this.oRouter.navigate([this.pronto.previousUrl]);
                }
                else{
                    this.oRouter.navigate(['/my-videos/draftsVideo']);
                }
            })
            .catch((err) => { });
    }

    DashboardHeaderComponent_TogglePublishStatus() {
        this.unpublishPopup = false;
        let status = this.pronto.sVideoStatus === 'draft' ? 'published' : 'draft';

        this.videoManager
            .updateVideoStatus(this.sUserEmail, this.sSessionId, this.videoManager.selectedVideo.fpath, status)
            .then((res) => {
                this.videoManager.selectedVideo.publish_status = status;
                if (this.pronto.sVideoStatus === 'published') {
                    this.oRouter.navigate(['/my-videos/draftsVideo']);
                } else {
                    this.oRouter.navigate(['/published-video/timeline-view']);
                }
            })
            .catch((err) => {
                if (err.errorMessage.includes('monthly quota')) {
                    this.errorService.handleError(err.errorMessage, 'Monthly Quota Exceeded', true, false, true);
                }
            });
    }
    DashboardHeaderComponent_NavigateToDashboard() {
        this._location.back();

        // if ((this.pronto.sVideoStatus == 'draft') && (this.videoManager.selectedVideo?.fpath.includes("location_videos"))) {
        //   this.oRouter.navigate(['/my-videos/locationVideos']);
        // }
        // else if (this.pronto.sVideoStatus == 'draft') {
        //   this.oRouter.navigate(['/my-videos/draftsVideo']);
        // }
        // else {
        //   this.oRouter.navigate(['/dashboard/myvideos']);
        // }
    }
    DashboardHeaderComponent_GetClickLocation(event) {
        this.oClosePopup = 0;
        if (event.clientX <= 65) {
            this.oClosePopup = 1;
            setTimeout(() => {
                this.oClosePopup = 0;
            }, 200);
        }
    }

    isVisible = false;
    showModal(): void {
        if (!this.showHeaderMenu) {
            this.subscriptionPlan.showPlanDialogue();
        } else {
            this.isVisible = true;
        }
    }
    handleOk(): void {
        console.log('Button ok clicked!');
        this.isVisible = true;
    }
    handleCancel(): void {
        console.log('Button cancel clicked!');
        this.isVisible = false;
        this.delPopup = false;
        this.unpublishPopup = false;
    }
    delVideo(): void {
        console.log('chksub1');
        if (!this.showHeaderMenu) {
            this.subscriptionPlan.showPlanDialogue();
        } else {
            this.delPopup = true;
        }
    }
    PublishUnpublis(): void {
        console.log('chksub2');

        if (!this.showHeaderMenu) {
            this.subscriptionPlan.showPlanDialogue();
        } else {
            this.unpublishPopup = true;
        }
    }
    openExpectRationModel($event) {
        let video = this.videoManager.selectedVideo;
        let targetObj = $event.target.getBoundingClientRect();
        let __this = this;

        let dialogConf = {
            maxWidth: this.pronto.settings.aspect_popup.max_width,
            maxHeight: this.pronto.settings.aspect_popup.max_height,
            width: this.pronto.settings.aspect_popup.width,
            height: this.pronto.settings.aspect_popup.height,
            autoFocus: false,
            position: { left: (targetObj.left - 20) + 'px', top: targetObj.top + 30 + 'px' },
            panelClass: 'pronto-aspect-dialog',
            hideHeader: true,
            data: { video: video, hideLoader: true },
        };

        let showUploadModal = true;
        if (showUploadModal) {
            let dialogModal = this.dialog.open(AspectRatioPopupComponent, dialogConf);

            dialogModal.afterClosed().subscribe((data: any) => {
                if (data && data.success) {
                    __this.updateVideoAspects(data?.aspect, data?.video);
                }
            });
        } else {
            this.errorService.handleError('Brand does not have any Media Library', 'Logo Update', true, false, true);
        }
    }

    updateVideoAspects(ars, video) {
        if (ars && this.videoManager.selectedVideo) {
            if (!this.videoManager.selectedVideo.ars) {
                this.videoManager.selectedVideo.ars = [video.aspect_ratio];
            }
            this.videoManager.selectedVideo.ars.push(ars);
        }

        if (ars && this.videoManager.selectedVideo) {
            if (!this.videoManager.selectedVideo.ars) {

                this.videoManager.selectedVideo.ars = [video.aspect_ratio];
            }
            this.videoManager.selectedVideo.ars.push(...ars);
            // this.videoManager.videos[indexVideo].ars.push(ars);
        }
        this.successService.showInfoToaster("Resizing in progress. Find your new resized video(s) in your drafts.", true, true);
    }
    goToSubscribe() {
        this.subscriptionPlan.showPlanDialogue();
    }
}
