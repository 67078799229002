import { VoiceDataStore } from 'src/app/stores/voice.store';
import { VoiceOverService } from './../../services/voice-over.service';
import { Component, OnInit, ChangeDetectorRef, IterableDiffers, Input, Output, EventEmitter, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ConfigManagerService } from 'src/app/Managers/config-manager.service';
import { CreateVideoManagerService } from 'src/app/Managers/create-video-manager.service';
import { MediaManagerService } from 'src/app/Managers/media-manager.service';
import { OnboardingManagerService } from 'src/app/Managers/onboarding-manager.service';
import { VideoManagerService } from 'src/app/Managers/video-manager.service';
import { cDataService } from 'src/app/services/data.service';
import { ErrorService } from 'src/app/services/error.service';
import { cSessionService } from 'src/app/services/session.service';
import { SuccessMessageModalService } from 'src/app/services/success-message-modal.service';
import { VideoDataStore } from 'src/app/stores/video.store';
import { UploadFileDialogComponent } from '../upload-file-dialog/upload-file-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { ProntoSettingService } from 'src/app/services/utils/pronto-setting.service';
import { item } from 'src/app/components/modify-video/sap-video-timeline/sap-video-timeline.component';
import { BrowseVoiceOverComponent } from '../browse-voice-over/browse-voice-over.component';
import { VoiceOverFilterSearchWizardComponent } from '../voice-over-filter-search-wizard/voice-over-filter-search-wizard.component';
import { LoaderService } from 'src/app/services/loader.service';
import { video } from 'src/app/models/video';
import { DialogDataStore } from 'src/app/stores/dialogs.store';

@Component({
    selector: 'app-video-voice-over-selection',
    templateUrl: './video-voice-over-selection.component.html',
    styleUrls: ['./video-voice-over-selection.component.scss'],
})
export class VideoVoiceOverSelectionComponent implements OnInit {
    @Input() editVoicePanel;
    @Output() updateVoiceRecords = new EventEmitter();
    selectedOption = '';
    voiceGender = 'male';
    bShowTextToSpeechSection = true;
    sUploadedVoiceOverFileName: string = 'No file choosen.';
    public nVoiceOverFileSize: number = 0;
    oUploadedVoiceOverFile: any;
    public vo_text: string = '';
    sVoiceName: string = 'voice1';
    fContentForm: FormGroup;
    lUploadedFiles: any = [];
    nFileToUploadIndex: number = 0;
    loading: boolean = false;
    spinner: string;
    destroy$: Subject<boolean> = new Subject<boolean>();
    createdVoicesList: any[] = [];
    private differ: IterableDiffers;
    showRightPanel = true;
    listSelected = [];
    listFavorites = [];
    selectedVoicesDropDownList = [];
    favoritevoices = [];
    selectedAvatar: any;
    selectedVoiceStyles: any = [];
    lUserSelectedVisuals: any = [];
    selectedstyle = '';
    speaker_id = '';
    vo_mute = false;
    selectedVoiceFileInfo = undefined;
    speakerVoiceObj = undefined;
    uploadVoice = true;
    selectVoice = false;
    createVoiceLoader = false;
    email = '';
    session_id = '';
    public isLocation = false;
    numberOFVoiceCharacters = 500;
    setWellSideId: any;
    loadedWellSaidId = '';
    showVoiceVideoPreview = false;
    locationName = '';
    search_term = '';
    public searchTermText:string="";
    status =["draft", "published"];
    orderBySearch = 'created';
    sSelectedVideoId: string;
    isVoiceFromUploadOption = false;
    oAudio = new Audio();
    mathObj = Math;
    constructor(
        private oDataService: cDataService,
        public videoManger: CreateVideoManagerService,
        public mediaManger: MediaManagerService,
        public configManager: ConfigManagerService,
        public errorService: ErrorService,
        private successMessage: SuccessMessageModalService,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<UploadFileDialogComponent>,
        private cd: ChangeDetectorRef,
        public videoListingManager: VideoManagerService,
        public voiceService: VoiceOverService,
        public voiceStore: VoiceDataStore,
        public pSetting: ProntoSettingService,
        public loader: LoaderService,
        private onboardingManager: OnboardingManagerService,
        private dialogStore: DialogDataStore,
        @Inject(MAT_DIALOG_DATA) public popupData: any,
    ) {
        this.sSelectedVideoId = undefined;
        if (this.popupData) {
            // console.log(' popup data here ', this.popupData);
            if (this.popupData.isLocation) {
                this.isLocation = this.popupData.isLocation;
                if (this.popupData.media && this.popupData.media.media_obj != undefined) {
                    this.vo_text = this.popupData?.media?.text;
                    this.vo_text = this.popupData?.media?.text;
                    this.locationName = this.popupData?.media?.locationName ? this.popupData?.media?.locationName : '';

                    this.setWellSideId = this.popupData?.media?.artist_id;

                    // console.log(" this.popupData?.media?", this.popupData?.media?.selection);
                    this.selectedOption == this.popupData.media.selection ? this.popupData.media.selection : 'voiceover';
                    // console.log("Selected option ..... ", this.popupData.media.selection, this.selectedOption)
                    this.selectedVoiceFileInfo = this.popupData?.media?.media_obj;
                    this.speaker_id = this.popupData?.media?.artist_id;
                    this.loadedWellSaidId = this.speaker_id;
                    if(this.popupData?.media?.selection == 'voiceover' ||this.popupData?.media?.selection == 'voice' ){

                        this.speakerVoiceObj = {
                            selected: true,
                        };
                        this.speakerVoiceObj = this.popupData?.media?.media_obj;
                    }


                    this.videoManger.selectedVideo = new video();
                    this.videoManger.selectedVideo.duration = 30;
                    this.videoManger.selectedVideo.voice_wellsaid_selection = this.popupData?.media?.selection ? this.popupData?.media?.selection : 'voiceover';

                    if (this.speakerVoiceObj && typeof this.speakerVoiceObj === 'object' && (this.selectedOption == undefined || this.selectedOption == '' ||this.selectedOption == 'voiceover')) {
                       this.updateVideoAttr('vo_text',this.vo_text);
                       this.updateVideoAttr('voice_wellsaid_id',this.speaker_id);
                        this.speakerVoiceObj['current_time'] = 0;
                        if (!this.speakerVoiceObj.artist_id) {
                            this.speakerVoiceObj.artist_id = this.popupData?.media?.artist_id;
                        }
                        this.createdVoicesList.push(this.speakerVoiceObj);
                        // this.videoData.voice_file_obj = this.speakerVoiceObj;
                    } else {

                        this.videoData.voice_file_obj = this.popupData?.media?.media_obj;
                    }
                }
            }
        }
    }

    getVideoAttr(attr) {
        return this.videoManger.selectedVideo[attr] ? this.videoManger.selectedVideo[attr] : '';
    }
    get videoData() {
        return this.videoManger?.selectedVideo;
    }
    ngOnInit(): void {
        this.loadVoices();

        if (this.videoManger.selectedVideo) {
            if (this.videoData.duration == 6) {
                this.numberOFVoiceCharacters = 100;
            } else if (this.videoData.duration == 15) {
                this.numberOFVoiceCharacters = 250;
            } else if (this.videoData.duration == 60) {
                this.numberOFVoiceCharacters = 1000;
            }
            this.createdVoicesList = [];
            if (this.speakerVoiceObj && typeof this.speakerVoiceObj === 'object') {
                this.speakerVoiceObj.selected = true;
                this.speakerVoiceObj.name = this.speakerVoiceObj.fpath;
                this.createdVoicesList.push(this.speakerVoiceObj);
                this.getVoiceFileDuration(this.createdVoicesList[0].url, this.createdVoicesList[0]);
                this.videoData.voice_file_obj = this.speakerVoiceObj;
            }
            this.selectedOption = this.videoManger.selectedVideo.voice_wellsaid_selection
                ? this.videoManger.selectedVideo.voice_wellsaid_selection
                : 'voiceover';
            this.voiceGender = this.videoManger.selectedVideo.vo_gender;
            this.vo_mute = this.videoManger.selectedVideo?.vo_mute ? this.videoManger.selectedVideo?.vo_mute : false;
            this.vo_mute = false; // this.videoManger.selectedVideo?.vo_mute ? this.videoManger.selectedVideo?.vo_mute : false;
            this.email = this.pSetting.loginInfo().email;
            this.session_id = this.pSetting.loginInfo().session_id;

            if (
                !this.videoManger.selectedVideo.voice_wellsaid_selection &&
                this.videoManger.selectedVideo.voice_wellsaid_id &&
                this.videoManger.selectedVideo.voice_wellsaid_id != ''
            ) {
                this.videoManger.selectedVideo.voice_wellsaid_selection = 'voiceover';
            }
            if (
                this.videoManger.selectedVideo.voice_wellsaid_selection == 'voiceover' ||
                (!this.videoManger.selectedVideo.voice_wellsaid_selection && this.editVoicePanel)
            ) {
                this.vo_text = this.getVideoAttr('vo_text');
                this.selectedVoiceFileInfo = this.getVideoAttr('vo_file');
                this.speaker_id = this.getVideoAttr('voice_wellsaid_id');
                this.speakerVoiceObj = this.getVideoAttr('voice_file_obj');
                if (this.speakerVoiceObj) {
                    this.initializeVoiceObject();
                }
                if (this.speakerVoiceObj && typeof this.speakerVoiceObj === 'object') {

                    if(this.createdVoicesList && this.createdVoicesList.length == 0){
                        this.createdVoicesList.push(this.speakerVoiceObj);
                    }
                }
                this.voiceGender =
                    this.getVideoAttr('vo_gender') && this.getVideoAttr('vo_gender') != '' ? this.getVideoAttr('vo_gender') : 'male';
            } else if (this.videoManger.selectedVideo.voice_wellsaid_selection == 'upload') {

                this.isVoiceFromUploadOption = true;
                this.oUploadedVoiceOverFile = this.getVideoAttr('voice_file_obj');
                this.sUploadedVoiceOverFileName =
                    this.videoManger.selectedVideo?.voice_file_obj?.fpath && this.videoManger.selectedVideo?.voice_file_obj?.fpath != ''
                        ? this.videoManger.selectedVideo?.voice_file_obj?.fpath.split('/').pop()
                        : this.videoManger.selectedVideo?.voice_file_obj?.visual_path.split('/').pop();
                this.nVoiceOverFileSize = this.videoManger.selectedVideo.voice_file_obj.size;

            }

            if (
                (this.getVideoAttr('voice_wellsaid_id') && this.getVideoAttr('voice_wellsaid_id') != '') ||
                (this.loadedWellSaidId && this.loadedWellSaidId != '')
            ) {
                let wellSaid = this.getVideoAttr('voice_wellsaid_id') ? this.getVideoAttr('voice_wellsaid_id') : this.loadedWellSaidId;
                this.selectedVoicesDropDownList.map((item) => {
                    if (item.speech_styles && item.speech_styles.length > 0) {
                        let speaker = item.speech_styles.filter((speech) => speech.wellsaid_id == this.speaker_id);

                        if (speaker.length > 0) {
                            this.selectedAvatar = item;
                            this.selectedstyle = speaker[0].style;
                            this.selectedVoiceStyles = item.speech_styles;
                        }
                    }
                });
            } else {
                if (
                    this.selectedVoicesDropDownList &&
                    this.selectedVoicesDropDownList.length > 0 &&
                    this.selectedVoicesDropDownList[0].speech_styles
                ) {
                    this.selectedAvatar = this.selectedVoicesDropDownList[0];
                    this.selectedVoiceStyles = this.selectedVoicesDropDownList[0].speech_styles;
                    this.selectedstyle = this.selectedVoicesDropDownList[0].speech_styles[0].style;
                    this.speaker_id = this.selectedVoicesDropDownList[0].speech_styles[0].wellsaid_id;
                }
            }

            if (this.editNewsVoiceObj) {
                this.updateVoice();
            }
        } else {
            this.selectVoiceOption('voiceover');
        }

        this.voiceService.getSelectedVoiceOverList();
        this.voiceService.getFavoriteVoiceOverList();
        this.voiceStore.getFavoritesVoices().subscribe((item) => {
            this.listFavorites = item;
            this.loadVoices();
        });
        this.voiceStore.getSelectedVoices().subscribe((item) => {
            // console.log('Selected voice changed ....');
            this.listSelected = item;
            this.loadVoices();
        });

        if (this.editVoicePanel) {
            // console.log('Edit panel here ..');
            if (this.selectedOption == '' || !this.selectedOption) {
                this.selectedOption = 'upload';
                this.showRightPanel = false;
            }
        }

        // Select Video for Preview from media library
        this.mediaManger.replaceMediaFromVisualPopup.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
            this.mediaManger.showMediaReplacementPopup = false;
            if(this.mediaManger.replacedMediaComponent == 'voicemediareplacement'){
                if (data && data.length > 0) {
                    this.videoPreviewPlayer.src = data[0]?.signed_url;
                    this.voiceStore.voiceVideoPreviewVideoSrc = data[0].signed_url;
                    this.loadVideoForPreview();
                }
            }
        });
    }

    async initializeVoiceObject() {
        let signedUrl = await this.pSetting.getSignedUrl(this.email, this.session_id, this.speakerVoiceObj.fpath);
        // console.log(' LOAD SIGNED URL OF VIDEO ', signedUrl);
        this.speakerVoiceObj.url = signedUrl;
    }
    ContentComponent_UploadVoiceover() {
        this.bShowTextToSpeechSection = false;
    }

    // createVoice(){
    //   this.voiceService.generateTestToVoice(this.speaker_id, this.vo_text).then( (response) => {
    //     this.createdVoicesList.push({ fpath : '/assets/audio/test.mp3', url : '/assets/audio/test.mp3',
    //   selected : false, error: false,
    //   current_time : 0,
    //   duration : 42, play : false});
    //   });
    // }
    errorAudioGeneration = '';
    createVoice() {
        this.errorAudioGeneration = '';
        if (this.vo_text && this.vo_text != '' && this.vo_text.trim() != '') {
            this.createVoiceLoader = true;
            this.voiceService
                .generateTestToVoice(this.speaker_id, this.vo_text, this.locationName)
                .then((res) => {
                    this.createVoiceLoader = false;

                    let isErrorCase = false;
                    if (this.videoManger.selectedVideo) {
                        if (res.response && res.response.duration && res.response.duration > this.videoManger.selectedVideo.duration) {
                            isErrorCase = true;
                        }
                    }
                    this.createdVoicesList.push({
                        name: res.response.fpath.split('/').pop(),
                        fpath: res.response.fpath,
                        url: res.response.fpath_surl,
                        selected: false,
                        error: isErrorCase,
                        current_time: 0,
                        duration: res.response.duration,
                        play: false,
                        speaker: this.speaker_id,
                    });

                    if (this.editVoicePanel) {
                        this.updateVoice();
                    }
                    this.coundGeneratatedVoiceOvers();
                })
                .catch((error) => {
                    // console.log( " error ", error)
                    this.errorAudioGeneration = error.message ? error.message : error;
                    this.createVoiceLoader = false;
                });
        }
    }

    coundGeneratatedVoiceOvers() {
        if (this.selectedOption == 'voiceover') {
            this.updateVideoAttr('voiceGenSize', this.createdVoicesList ? this.createdVoicesList.length : 0);
            this.updateVideoAttr('voice_wellsaid_selection', this.selectedOption);
        } else {
            this.updateVideoAttr('voiceGenSize', 0);
        }
    }

    selectCreatedVoice(voice: any, flag: boolean) {
        this.createdVoicesList.map((item) => {
            item.selected = false;
        });
        if (flag) {
            this.selectedVoiceFileInfo = voice.fpath;
            this.speakerVoiceObj = voice;
            voice.selected = flag;
            this.selectedVoiceFileInfo = voice.fpath;
        } else {
            // console.log('Voice is not selected ...');
            this.speakerVoiceObj = undefined;
            this.selectedVoiceFileInfo = undefined;
            voice.selected = false;
            this.selectedVoiceFileInfo = '';
        }

        this.updateVoice();
    }

    // Upload image or video
    openFileUploadModal(uploadFileType, allowDuplicate = false) {
        const heading = 'Upload Media';

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '100wv';
        dialogConfig.height = '100vh';
        dialogConfig.panelClass = 'pronto-dialog';
        dialogConfig.closeOnNavigation = true;

        dialogConfig.data = {
            uploadFor: uploadFileType,
            multipleUpload: false,
            allowDuplicate: allowDuplicate,
            dropBoxText: '',
            heading: heading,
            mediaLibraryId:
                this.videoManger.selectedVideo?.brand_id &&
                this.videoManger.videoBrand?.media_libs &&
                this.videoManger.videoBrand?.media_libs.length > 0
                    ? this.videoManger.videoBrand?.media_libs[0]._id?.$oid
                    : undefined,
        };

        if (!dialogConfig.data.mediaLibraryId) {
            delete dialogConfig.data.mediaLibraryId;
        }

        let dialogModal = this.dialog.open(UploadFileDialogComponent, dialogConfig);
 this.mediaManger.replacedMediaComponent = 'voiceover-upload-voice';
        dialogModal.afterClosed().subscribe((data: any) => {
            if (data.status == 'success') {
                const result = data.result[0];
                this.successMessage.close();
                this.isVoiceFromUploadOption = true;
                if (uploadFileType == 'audio') {
                    this.ContentComponent_UploadVoiceover();
                    this.nVoiceOverFileSize = result.size;
                    this.successMessage.close();
                    this.oUploadedVoiceOverFile = result;
                    // console.log(result);
                    this.sUploadedVoiceOverFileName = result.name;
                    this.oUploadedVoiceOverFile.fpath = this.oUploadedVoiceOverFile.fpath
                        ? this.oUploadedVoiceOverFile.fpath
                        : this.oUploadedVoiceOverFile.visual_path;
                    this.bShowTextToSpeechSection = false;
                    this.updateVoice();

                    if (this.fContentForm?.patchValue) {
                        this.fContentForm.patchValue({
                            oUploadedVoiceOverFile: this.oUploadedVoiceOverFile,
                            nVoiceOverFileSize: this.nVoiceOverFileSize,
                        });
                    }
                } else {
                    this.lUploadedFiles.push({
                        filename: result.name,
                        fpath: result.visual_path,
                        thumb: result.thumbnail,
                        url: result.url,
                        size: result.size,
                    });
                    this.nFileToUploadIndex++;
                }

                this.cd.detectChanges();
            } else {
            }
        });

        this.mediaManger.replaceMediaFromVisualPopup.pipe(takeUntil(this.destroy$)).subscribe((item: any) => {
            if(this.mediaManger.replacedMediaComponent == 'voiceover-upload-voice'){
            if (item && item.length > 0 && item[0].visual_type == 'audio') {
                let result = item[0];
                this.ContentComponent_UploadVoiceover();
                this.nVoiceOverFileSize = result.size;
                this.isVoiceFromUploadOption = true;
                this.successMessage.close();
                // console.log(result);
                this.oUploadedVoiceOverFile = result;
                this.sUploadedVoiceOverFileName = result.name;
                this.videoManger.selectedVideo.voice_file = result.fpath;
                this.bShowTextToSpeechSection = false;
                this.updateVoice();

                if (this.fContentForm?.patchValue) {
                    this.fContentForm.patchValue({
                        oUploadedVoiceOverFile: this.oUploadedVoiceOverFile,
                        nVoiceOverFileSize: this.nVoiceOverFileSize,
                    });
                }
                }
            }
            this.mediaManger.showMediaReplacementPopup = false;
            this.cd.detectChanges();
        });
    }

    ContentComponent_ReturnFileSize(bytes, decimals = 2) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    isAudioPlaying = false;
    timeoutAudioPlayer = undefined;
    activePlayer: any = undefined;
    playingAudioObj = undefined;
    playingAudioCurrentTime = 0;
    playerInterval(i) {
        let __this = this;
        let rangEle = document.querySelector('#range-voice-' + i);
        if (this.activePlayer) {
            this.timeoutAudioPlayer = setInterval(() => {
                if (!__this.isAudioPlaying || __this.playingAudioObj.duration <= __this.playingAudioObj.current_time) {
                    __this.playingAudioObj.play = false;
                    clearInterval(this.timeoutAudioPlayer);
                } else {
                    // rangEle.setAttribute("value", __this.activePlayer.currentTime+"");
                    __this.playingAudioObj.current_time = Math.round(__this.activePlayer.currentTime);
                    __this.playingAudioCurrentTime = Math.round(__this.activePlayer.currentTime);
                }
            }, 100);
        }
    }

    pauseAllAudioSounds() {
        this.createdVoicesList.map((item, index) => {
            let audioEle = <HTMLAudioElement>document.querySelector('#audio-player-' + index);
            if (audioEle) {
                audioEle.pause();
                clearInterval(this.timeoutAudioPlayer);
            }
        });
    }
    playAudio(index, obj) {
        // if(this.playingAudioObj){
        //   this.playingAudioObj.current_time = 0;
        // }

        if (this.playingAudioObj && obj.fpath != this.playingAudioObj.fpath && this.activePlayer) {
            this.activePlayer.pause();
            this.activePlayer.current_time = 0;
        }
        this.pauseAllAudioSounds();
        // this.activePlayer.pause();
        this.playingAudioObj = obj;
        let shouldPlay = !obj.play;
        obj.play = !obj.play;
        let audioEle = <HTMLAudioElement>document.querySelector('#audio-player-' + index);
        let audioElet = document.querySelectorAll(`#audio-player-${index}`);
        if (!obj.obj && shouldPlay) {
            this.activePlayer = undefined;
            this.isAudioPlaying = false;
            this.playingAudioObj.current_time = 0;

            if (audioEle) {
                this.activePlayer = audioEle;
                audioEle.play();
                // console.log(' play audio ');
                this.isAudioPlaying = true;

                this.playerInterval(index);
            }
        } else {
            if (audioEle) {
                clearInterval(this.timeoutAudioPlayer);
                audioEle.pause();
                this.activePlayer = undefined;
                this.isAudioPlaying = false;
                this.playingAudioObj = undefined;
            }
        }
    }
    showMediaFromLibrary = false;
    showMediaModal() {
        this.showMediaFromLibrary = true;
        // this.bDisplayMyMediaSection = true;
    }
    closeMediaModal(): void {
        this.showMediaFromLibrary = false;
    }
    mediaSelected(event) {
        this.lUserSelectedVisuals = event;
        // console.log(event);
        this.oUploadedVoiceOverFile = this.lUserSelectedVisuals[0];
        // console.log('chkmedia', this.oUploadedVoiceOverFile);
        if(this.lUserSelectedVisuals[0] && this.lUserSelectedVisuals[0] != undefined){
            this.isVoiceFromUploadOption = true;
        }
        this.nVoiceOverFileSize = this.oUploadedVoiceOverFile.size;
        this.sUploadedVoiceOverFileName = this.oUploadedVoiceOverFile?.visual_path.split('/').pop();
        this.bShowTextToSpeechSection = false;
        this.updateVoice();
        // console.log('chkmedia3', this.videoManger.selectedVideo.voice_file);

        this.showMediaFromLibrary = false;
    }

    editNewsVoiceObj: any = {
        voice_wellsaid_selection: '',
        vo_gender: '',
        vo_text: '',
        voice_wellsaid_id: '',
        voice_file_obj: {},
        vo_file_url: '',
        voice_file_url: {},
    };

    selectedVoiceOverFile = undefined;
    private updateVideoAttr(attr: string, val: any) {
        if (this.editVoicePanel) {
            // console.log('Update voice info');
            this.editNewsVoiceObj[attr] = val;
            if (this.updateVoiceRecords) {
                // console.log("EDIT PANEL UPDATE VOICE RECORD CALLED..");
                this.updateVoiceRecords.emit(this.editNewsVoiceObj);
            }
        } else {
            if (this.videoManger.selectedVideo) {
                this.videoManger.selectedVideo[attr] = val;
            } else {
                this.selectedVoiceOverFile = val;
            }
        }
    }
    private updateVoice() {
        // console.log(' this.selectedOption >>> ', this.selectedOption);
        this.updateVideoAttr('voice_wellsaid_selection', this.selectedOption);
        this.coundGeneratatedVoiceOvers();
        if (this.selectedOption == 'voiceover') {
            this.updateVideoAttr('voiceGenSize', this.createdVoicesList ? this.createdVoicesList.length : 0);
            this.updateVideoAttr('voice_wellsaid_selection', this.selectedOption);
            if (this.speakerVoiceObj) {
                this.updateVideoAttr('voice_file_obj', this.speakerVoiceObj);
            }
            if (
                this.speakerVoiceObj &&
                this.speakerVoiceObj.selected != undefined &&
                this.speakerVoiceObj.selected &&
                this.speakerVoiceObj &&
                this.speaker_id != undefined &&
                this.speaker_id != ''
            ) {
                // console.log(" UPDATE VOICE ", this.speakerVoiceObj);
                this.updateVideoAttr('voice_wellsaid_selection', this.selectedOption);
                this.updateVideoAttr('vo_gender', this.voiceGender);
                this.updateVideoAttr('vo_text', this.vo_text);
                this.updateVideoAttr('vo_file', this.speakerVoiceObj.fpath ? this.speakerVoiceObj.fpath : '');
                this.updateVideoAttr('voice_file', this.speakerVoiceObj.fpath ? this.speakerVoiceObj.fpath : '');
                this.updateVideoAttr('vo_text_file', this.speakerVoiceObj.fpath);
                this.updateVideoAttr('voice_wellsaid_id', this.speaker_id);
                this.updateVideoAttr('vo_file_url', this.speakerVoiceObj?.url);
                this.updateVideoAttr('voice_wellsaid_selection', 'voiceover');
            } else {
                this.updateVideoAttr('voice_wellsaid_id', undefined);
                this.updateVideoAttr('vo_file', '');
                this.updateVideoAttr('voice_file', '');
                this.updateVideoAttr('vo_text_file', '');
                this.updateVideoAttr('voiceGenSize', 0);
            }
            // console.log(this.speakerVoiceObj);
        } else if (this.selectedOption == 'upload') {
            // console.log(" UPDATE VOICE Upload >>>>", this.oUploadedVoiceOverFile);
            this.videoManger.selectedVideo.voice_wellsaid_selection;
            this.updateVideoAttr(
                'voice_file',
                this.oUploadedVoiceOverFile?.fpath && this.oUploadedVoiceOverFile?.fpath != ''
                    ? this.oUploadedVoiceOverFile?.fpath
                    : this.oUploadedVoiceOverFile?.visual_path,
            );
            let uploadedFileObj = this.oUploadedVoiceOverFile;
            delete uploadedFileObj?.media_lib;
            delete uploadedFileObj?.media_lib_id;
            delete uploadedFileObj?.organization_id;
            delete uploadedFileObj?.updated;
            delete uploadedFileObj?.created;
            delete uploadedFileObj?._id;
            delete uploadedFileObj?._id;
            delete uploadedFileObj?.brand;
            delete uploadedFileObj?.brand_id;
            this.updateVideoAttr(
                'vo_file',
                this.oUploadedVoiceOverFile?.fpath && this.oUploadedVoiceOverFile?.fpath != ''
                    ? this.oUploadedVoiceOverFile?.fpath
                    : this.oUploadedVoiceOverFile?.visual_path,
            );

            this.updateVideoAttr('voice_wellsaid_selection', 'upload');
            this.updateVideoAttr('voice_file_obj', this.oUploadedVoiceOverFile);
            this.updateVideoAttr('vo_text', '');
            this.updateVideoAttr('vo_text_file', '');
            this.updateVideoAttr(
                'voice_file_url',
                this.oUploadedVoiceOverFile?.url && this.oUploadedVoiceOverFile?.url != ''
                    ? this.oUploadedVoiceOverFile?.url
                    : this.oUploadedVoiceOverFile?.visual_path,
            );
            // console.log(" update voice_file >>>>>>>", this.oUploadedVoiceOverFile);
        }
    }
    openBrowseVoiceOverModal() {
        const heading = 'Upload Media';

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '100wv';
        dialogConfig.height = '100vh';
        dialogConfig.panelClass = 'pronto-voice-selection-dialog';
        dialogConfig.closeOnNavigation = true;

        dialogConfig.data = {
            showBrowseVoice: true,
        };

        if (!dialogConfig.data.mediaLibraryId) {
            delete dialogConfig.data.mediaLibraryId;
        }

        let dialogModal = this.dialog.open(VoiceOverFilterSearchWizardComponent, dialogConfig);

        dialogModal.afterClosed().subscribe((data: any) => {
            if (data?.status == 'success') {
            } else {

            }
        });
    }
    selectAvatar(voice) {
        this.selectedVoiceStyles = voice.speech_styles;
        this.selectedstyle = voice.speech_styles[0].style;
        this.speaker_id = voice.speech_styles[0].wellsaid_id;
        // console.log(voice.speech_styles[0]);
        this.selectedAvatar = voice;

        // console.log(' speaker well said ', this.speaker_id, voice);
    }
    setWellSideIds(speech_styles) {
        this.selectedstyle = speech_styles.style;
        this.speaker_id = speech_styles.wellsaid_id;

        // console.log(' speaker well said ', this.speaker_id, speech_styles);
    }

    selectVoiceOption(option) {
        this.selectedOption = option;
        this.updateVoice();
    }

    // updateVoiceRecord() {
    //     let data = {
    //         speaker: {
    //             gender: this.voiceGender,
    //             speaker_id: this.speaker_id,
    //             voice_file: this.selectedVoiceFileInfo,
    //         },
    //         own: {
    //             voice_file: this.oUploadedVoiceOverFile.fpath,
    //         },
    //     };
    //     this.updateVoiceRecords.emit({ selection: this.selectedOption, data });
    // }

    loadVoices() {
        this.selectedVoicesDropDownList = this.voiceStore.selectedVoiceOverList.essentials.voices.concat(
            this.voiceStore.selectedVoiceOverList.premium.voices,
        );
        this.favoritevoices = this.voiceStore.favoriteVoiceOverList.essentials.voices.concat(
            this.voiceStore.favoriteVoiceOverList.premium.voices,
        );
        if (this.selectedVoicesDropDownList && this.selectedVoicesDropDownList.length > 0 && (!this.speaker_id || this.speaker_id == '')) {
            this.selectedAvatar = this.selectedVoicesDropDownList[0];
            this.selectedstyle = this.selectedVoicesDropDownList[0].speech_styles[0].style;
            this.speaker_id = this.selectedVoicesDropDownList[0].speech_styles[0].wellsaid_id;
            this.selectedVoiceStyles = this.selectedVoicesDropDownList[0].speech_styles;
        }
    }
    AddCreatedVoice(media) {
        let file = media.fpath.split('/').pop();
        let media_lib =
            this.videoManger.selectedVideo?.brand_id &&
            this.videoManger.videoBrand?.media_libs &&
            this.videoManger.videoBrand?.media_libs.length > 0
                ? this.videoManger.videoBrand?.media_libs[0]._id?.$oid
                : this.onboardingManager.profile.default_media_lib._id.$oid;
        let brand_id = this.videoManger.selectedVideo.brand_id ? this.videoManger.selectedVideo.brand_id : '';
        let fpath = media.fpath;
        this.voiceService.AddUserMedia(file, media_lib, brand_id, fpath);
    }
    deleteCreatedVoice(media, index) {
        let data = {
            email: this.email,
            session_id: this.session_id,
            fpath: media.fpath,
        };
        if (data.email) {
            this.voiceService.deleteVoiceOver(data);
        }
        this.createdVoicesList.map((item, i) => {
            if (i == index) {
                this.createdVoicesList.splice(i, 1);
            }
        });

        if (media.selected) {
            this.speakerVoiceObj = undefined;
            this.selectedVoiceFileInfo = undefined;
            media.selected = false;
            this.selectedVoiceFileInfo = '';
        }

        // console.log('Voice is not selected ...');

        this.updateVoice();
        this.coundGeneratatedVoiceOvers();
    }

    downloadCreatedVoice(media) {
        let nameofFile = media.fpath.split('/').pop();
        let sSignedURL = media.url;

        this.loader.showLoader();
        fetch(sSignedURL)
            .then((response) => response.blob())
            .then((blob) => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = nameofFile && nameofFile != '' ? nameofFile : 'pronto.mp3';
                link.click();
                this.loader.hideLoader();
            })
            .catch((error) => {
                this.loader.hideLoader();
                this.errorService.handleError(error.errorMessage, error.errorType, true);
            });
    }
    selectTab(tab) {
        if (tab == 'uploadVoice') {
            this.uploadVoice = true;
            this.selectVoice = false;
        } else {
            this.selectVoice = true;
            this.uploadVoice = false;
        }
    }
    subscribedFunctions() {
        this.voiceStore.updateFavoriteListObservable.subscribe((val: any) => {
            // console.log('subscription called.');
            if (val) {
                if (this.voiceStore.lastSelectedVoiceId) {
                    this.voiceStore.lastSelectedVoiceId.selected = !this.voiceStore.lastSelectedVoiceId.selected;
                    this.voiceService.addNewItemInSelectedList(this.voiceStore.lastSelectedVoiceId);
                }
            }
        });
    }

    updateVoiceText() {
        if (this.editVoicePanel) {
            this.updateVoice();
        }
    }

    getAvatarUrlBySpeakerId(media): any {
        // console.log(" MEDIA PICKED ", media);
        let speakerid = media.speaker ? media.speaker : media.artist_id;
        let indexMatch = 0;
        let list = this.selectedVoicesDropDownList.filter((item: any) => {
            let itemIndex = item.speech_styles.findIndex((style: any) => style.wellsaid_id == speakerid);
            if (itemIndex > -1) {
                indexMatch = itemIndex;
                return item;
            }
        });

        if (list && list.length > 0) {
            // console.log("Avatar url ");
            return list[0].avatar_url;
        }

        return this.pSetting.icons.avatar;
    }
    openVoiceEditor() {
        let __this = this;
        let voiceObj;
        if (!this.oUploadedVoiceOverFile && !this.speakerVoiceObj) {
            return;
        }
        if (this.selectedOption == 'upload') {
            voiceObj = this.oUploadedVoiceOverFile;
        } else {
            voiceObj = this.speakerVoiceObj;
        }
        voiceObj.start_point = 0;
        this.voiceStore.openVoiceEditorComponent(voiceObj, {}, (obj) => {
            if (obj.data) {
                // console.log(" obj selected ", obj);
                if (__this.selectedOption == 'upload') {
                    __this.oUploadedVoiceOverFile.vo_volume = obj?.data?.vo_volume;
                    __this.oUploadedVoiceOverFile.vo_fadein_duration = obj?.data?.vo_fadein_duration;
                    __this.oUploadedVoiceOverFile.vo_fadeout_duration = obj?.data?.vo_fadeout_duration;
                    __this.oUploadedVoiceOverFile.vo_mute = false; //obj.data.vo_mute;
                    __this.oUploadedVoiceOverFile.apply_fading = obj?.data?.apply_fading;
                    __this.oUploadedVoiceOverFile.vo_start_from = obj?.data?.start_from;
                    __this.oUploadedVoiceOverFile.fpath = obj?.data?.fpath;
                    __this.oUploadedVoiceOverFile.visual_path = obj?.data?.visual_path;
                    // console.log(' this.oUploadedVoiceOverFile.fpath ', this.oUploadedVoiceOverFile.fpath);
                } else {
                    // console.log(" voice over obj updated ..");
                    __this.speakerVoiceObj.vo_volume = obj?.data?.vo_volume;
                    __this.speakerVoiceObj.vo_fadein_duration = obj?.data?.vo_fadein_duration;
                    __this.speakerVoiceObj.vo_fadeout_duration = obj?.data?.vo_fadeout_duration;
                    __this.speakerVoiceObj.vo_mute = false; //obj.data.vo_mute;
                    __this.speakerVoiceObj.apply_fading = obj?.data?.apply_fading;
                    __this.speakerVoiceObj.vo_start_from = obj?.data?.start_from;
                }
            }
        });
    }
    returnFileName(filename) {
        // console.log("Name of the file ", filename);
        var extension = '';
        if (filename != '') {
            var splitt = filename?.split('.');
            if (splitt?.length > 0) {
                var filename = splitt[0];
                extension = splitt[1];
            }

            if (filename?.length > 15) {
                filename = filename?.substring(0, 15) + '';
            }
            var result = filename + '.' + extension;
        }
        return result;
    }

    loadVoiceMediaEditor() {
        let voiceData = {
            url: this.oUploadedVoiceOverFile.url,
            type: 'voice',
            duration: this.oUploadedVoiceOverFile.duration ? this.oUploadedVoiceOverFile.duration : 10,
            start_point: this.oUploadedVoiceOverFile.start_point ? this.oUploadedVoiceOverFile.start_point : 0,
            vo_mute: false, //this.vo_mute,
            vo_volume: this.oUploadedVoiceOverFile.vo_vlume,
            vo_fadein_duration: this.oUploadedVoiceOverFile.vo_fadein_duration,
            vo_fadeout_duration: this.oUploadedVoiceOverFile.vo_fadeout_duration,
            vo_apply_fading: this.oUploadedVoiceOverFile.vo_apply_fading,
        };
        let __this = this;
        // console.log(' LOAD VOICE EDITOR ');
        this.voiceStore.openVoiceEditorComponent(voiceData, undefined, (obj) => {
            // console.log(' obj selected >>>> :::: ', obj);
            // console.log(' -- log ', __this.oUploadedVoiceOverFile.fpath, obj.data.fpath);
            if (__this.selectedOption == 'upload') {
                __this.oUploadedVoiceOverFile.vo_volume = obj.data.vo_volume;
                __this.oUploadedVoiceOverFile.vo_fadein_duration = obj.data.vo_fadein_duration;
                __this.oUploadedVoiceOverFile.vo_fadeout_duration = obj.data.vo_fadeout_duration;
                __this.oUploadedVoiceOverFile.fpath = obj.data.fpath;
                __this.oUploadedVoiceOverFile.visual_path = obj.data.fpath;

                // __this.oUploadedVoiceOverFile.vo_mute=obj.data.vo_mute;
                __this.oUploadedVoiceOverFile.vo_mute = false;
                __this.oUploadedVoiceOverFile.vo_start_from = obj.data.start_from;
                // console.log(' UPLOADED FILE NOW ', __this.oUploadedVoiceOverFile);
            } else {
                // console.log(" voice over obj updated ..");
                __this.speakerVoiceObj.vo_volume = obj.data.vo_volume;
                __this.speakerVoiceObj.vo_fadein_duration = obj.data.vo_fadein_duration;
                __this.speakerVoiceObj.vo_fadeout_duration = obj.data.vo_fadeout_duration;
                __this.oUploadedVoiceOverFile.fpath = obj.data.fpath;
                // __this.speakerVoiceObj.vo_mute=obj.data.vo_mute;
                __this.speakerVoiceObj.vo_mute = false;
                __this.speakerVoiceObj.vo_start_from = obj.data.start_from;
            }
        });
    }
    saveVoiceLoc() {
        // console.log(this.createdVoicesList);
        let selectedVoice = undefined;
        if(this.selectedOption == 'voiceover'){
            selectedVoice = this.createdVoicesList.filter((item) => item.selected);
        } else {
            selectedVoice = [this.oUploadedVoiceOverFile];
        }

        let selectedSpeaker = this.selectedAvatar;

        this.updateVoice();
        this.dialogRef.close({
            action: 'success',
            voices: selectedVoice,
            text: this.vo_text,
            gender: this.voiceGender,
            speaker: selectedVoice && selectedVoice.length > 0 ? selectedVoice[0].speaker : 0,
            style: this.selectedVoiceStyles,
            selection: this.selectedOption,
        });
    }

    cancelVoiceLoc() {
        this.dialogRef.close({ action: 'cancel' });
    }

    getVoiceFileDuration(src, obj) {
        // obj.current_time = 0;
        var audio = new Audio();
        audio.src = src;
        var due;
        this.getVal(audio, obj);
    }
    getVal(audio, obj) {
        audio.onloadedmetadata = function () {
            var val = audio.duration;
            obj.duration = Math.round(val);
        };
    }

    previewVideoAudioSync() {
        this.pauseAllAudioSounds();
        this.createdVoicesList.map((item=>{
            if(item.selected == true){
                item.play = false;
            }
        }))
        if(this.playingAudioObj && this.playingAudioObj != undefined){
            this.playingAudioObj.play = false;
        }
        this.showVoiceVideoPreview = true;
        this.showRightPanel = false;
        let __this = this;
        if(this.voiceStore.voiceVideoPreviewVideoSrc && this.voiceStore.voiceVideoPreviewVideoSrc != ''){
            this.voicePreview.firstLoad = false;
        }
        setTimeout(() => {
            __this.loadVideoPreviewObject(__this);
        }, 2000);
    }
    showPreviewSectionWiget(){
        this.voicePreview.loader = false;
        console.log(" display voice compare section and players >>" );
    }
    loadVideoForPreview() {
        this.voicePreview.firstLoad = false;
        this.voicePreview.loader = true;
        this.voicePreview.readystate = false;
        this.voicePreview.canplay = false;
        let __this = this;

            //console.log(  this.mediaEditorPlayer.nativeElement.readyState );
            let readyStateinterval =  setInterval( () =>{
            if(this.videoPreviewPlayer.readyState == 4){
                __this.voicePreview.readystate = true;
                clearInterval(readyStateinterval);
            }}, 100);
            this.videoPreviewPlayer.addEventListener('canplaythrough', function() {
                // __this.videoPreviewPlayer.play();

                __this.voicePreview.canplay = true;

          });

        let intervalVideoLoading =  setInterval( () =>{
            // console.log(__this.voicePreview.canplay ,'&&', __this.voicePreview.readystate);
            if( __this.voicePreview.canplay && __this.voicePreview.readystate ){
                // console.log(" READY STATE THROUGH NOW .....");
                __this.showPreviewSectionWiget();
                // this.showPreviewSectionWiget();
                clearInterval(intervalVideoLoading);
            }
        }, 100);

        // this.videoPreviewPlayer.addEventListener("progress", function() {
        //     // When buffer is 1 whole video is buffered
        //     // console.log("progress start..");
        // //     if (Math.round(video.buffered.end(0)) / Math.round(video.seekable.end(0)) === 1) {
        // //       // Entire video is downloaded
        // //    }
        //   }, false);
    }
    showMediaLibrary(type: string) {
        this.mediaManger.replacedMediaComponent = 'voicemediareplacement';
        this.mediaManger.showMediaReplacementPopup = true;
        this.mediaManger.replaceMediaTypeFromVisualPopup = { type: type, data: [], component: 'clip' };
    }
    searchVid() {
        // console.log('chk search'), this.searchTermText;
        this.search_term = this.searchTermText;
        this.videoListingManager.getUserVideos(this.email, this.session_id, this.status, -1, 20, 0, this.orderBySearch, this.search_term, '_id', true,);
    }

    clearSearchTerm() {
        this.searchTermText = '';
        this.search_term = '';
    }

    audioPreviewPlayer = undefined;
    videoPreviewPlayer = undefined;
    playPauseButton = undefined;
    volumeControl = undefined;
    audioProgressDiv = undefined;
    audioProgressBarSlider = undefined;
    currentTimeDisplay = undefined;
    totalTimeDisplay = undefined;
    previewVideoTimeController = undefined;
    mousedown = false;
    voicePreview = {
        loader : false,
        readystate : false,
        canplay : false,
        firstLoad : true
    }
    isPreviewAudioPlaying = false;

 resetVideoTime(e) {
    console.log(" time selected ", e.target.value);
    this.audioPreviewPlayer.pause();
    this.videoPreviewPlayer.pause();
    this.playPauseButton.src = '/assets/images/icons/play-icon-purple.png';
    // console.log(parseInt(this.value));
    this.audioPreviewPlayer.currentTime = e.target.value;
    const progressPercentage = (this.audioPreviewPlayer.currentTime / this.audioPreviewPlayer.duration) * 100;
    // this.audioProgressBarSlider.style.width = `${progressPercentage}%`;
    this.videoPreviewPlayer.currentTime = this.audioPreviewPlayer.currentTime;
    let __this = this;
    if(this.isPreviewAudioPlaying){
        let readyStateinterval =  setInterval( () =>{
            if(__this.videoPreviewPlayer.readyState == 4){
                __this.voicePreview.readystate = true;
                clearInterval(readyStateinterval);
                __this.audioPreviewPlayer.play();
                __this.videoPreviewPlayer.play();
                __this.playPauseButton.src = '/assets/images/icons/pause-player-purple.svg';
            }}, 10);

    }
}
    loadVideoPreviewObject(__this) {
        console.log(this.showVoiceVideoPreview, __this.showVoiceVideoPreview);
        this.audioPreviewPlayer = <HTMLAudioElement>document.getElementById('audio-preview-player');
        this.videoPreviewPlayer = <HTMLVideoElement>document.querySelector('.video-preview-player');
        if(this.voiceStore.voiceVideoPreviewVideoSrc && this.voiceStore.voiceVideoPreviewVideoSrc != ''){
            this.videoPreviewPlayer.src = this.voiceStore.voiceVideoPreviewVideoSrc;
            this.voicePreview.loader = true;
            __this.voicePreview.loader = true;
            this.voicePreview.firstLoad = false;
            __this.voicePreview.firstLoad = false;
            this.loadVideoForPreview();
        }
        this.playPauseButton = <HTMLImageElement>document.getElementById('play-pause-button');
        this.volumeControl = document.getElementById('volume-control-preview-player');
        // this.audioProgressDiv = <HTMLDivElement>document.querySelector('.progress-preview-player');
        // this.audioProgressBarSlider = <HTMLDivElement>document.getElementById('progress-bar-preview-player');
        this.currentTimeDisplay = document.getElementById('current-time-preview-player');
        this.totalTimeDisplay = document.getElementById('total-time-preview-player');
        this.previewVideoTimeController = document.getElementById("timeController");
        if(this.audioPreviewPlayer?.duration){
            this.previewVideoTimeController.setAttribute('max', this.audioPreviewPlayer.duration);
        }
        this.mousedown = false;
        this.isPreviewAudioPlaying = false;
        this.audioPreviewPlayer.currentTime = 0;
        this.videoPreviewPlayer.currentTime = 0;
        // console.log(' -- ', this.audioPreviewPlayer);
        // console.log('AUDIO PLAYUER DURATION ', this.audioPreviewPlayer.duration);
        // console.log('AUDIO playPauseButton ', this.playPauseButton);
        // console.log(' progressPreviewBar ', this.audioProgressDiv);
        if (this.playPauseButton) {
            this.playPauseButton.addEventListener('click', () => {
                if (this.isPreviewAudioPlaying) {
                    this.audioPreviewPlayer.pause();
                    this.videoPreviewPlayer.pause();
                    this.playPauseButton.src = '/assets/images/icons/play-icon-purple.png';
                } else {
                    this.audioPreviewPlayer.play();
                    this.videoPreviewPlayer.play();
                    this.playPauseButton.src = '/assets/images/icons/pause-player-purple.svg';
                }
                this.isPreviewAudioPlaying = !this.isPreviewAudioPlaying;
            });
        }

        if (this.previewVideoTimeController) {
            // this.audioProgressDiv.addEventListener('click',  () => (this.mousedown = true));
            // this.audioProgressDiv.addEventListener('click',  (e) => this.mousedown && this.scrub(e));
            this.previewVideoTimeController.addEventListener("input", (e) => ((this.resetVideoTime(e))));
            // this.audioProgressDiv.addEventListener('mousedown', () => (this.mousedown = true));
            // this.audioProgressDiv.addEventListener('mousemove', (e) => this.mousedown && this.scrub(e));
            // this.audioProgressDiv.addEventListener('mouseup', () => (this.mousedown = false));
        }

        if (this.audioPreviewPlayer) {
            this.audioPreviewPlayer?.addEventListener('ended', (event) => {
                this.audioPreviewPlayer.pause();
                this.videoPreviewPlayer.pause();
                this.audioPreviewPlayer.currentTime = 0;
                this.videoPreviewPlayer.currentTime = 0;
                this.playPauseButton.src = '/assets/images/icons/play-icon-purple.png';
                this.isPreviewAudioPlaying = false;
            });
            this.audioPreviewPlayer?.addEventListener('timeupdate', () => {
                const currentTime = this.audioPreviewPlayer.currentTime;
                const duration = this.audioPreviewPlayer.duration;

                const currentMinutes = Math.floor(currentTime / 60);
                const currentSeconds = Math.floor(currentTime % 60);
                const totalMinutes = Math.floor(duration / 60);
                const totalSeconds = Math.floor(duration % 60);

                this.currentTimeDisplay.textContent = `${currentMinutes}:${currentSeconds < 10 ? '0' : ''}${currentSeconds}`;
                this.totalTimeDisplay.textContent = `${totalMinutes}:${totalSeconds < 10 ? '0' : ''}${totalSeconds}`;
                this.handleProgress();
                // const progress = (currentTime / duration) * 100;
                // progressBar.style.width = `${progress}%`;
            });
        }
    }

    handleProgress() {
        const progressPercentage = (this.audioPreviewPlayer.currentTime / this.audioPreviewPlayer.duration) * 100;
        // this.audioProgressBarSlider.style.width = `${progressPercentage}%`;
        this.previewVideoTimeController.value = this.audioPreviewPlayer.currentTime;
    }
    scrub(e) {


            // let progressWidth = this.audioProgressDiv.offsetWidth ? this.audioProgressDiv.offsetWidth : 1;
            // const scrubTime = (e.offsetX / progressWidth) * this.audioPreviewPlayer.duration;
            // this.audioPreviewPlayer.currentTime = scrubTime;
            // this.videoPreviewPlayer.currentTime = scrubTime > this.videoPreviewPlayer.duration ? this.videoPreviewPlayer.duration : scrubTime;

    }
    // volumeControl.addEventListener("input", () => {
    //     audio.volume = volumeControl.value;
    // });

    hideVideoAudioSync() {
        this.showVoiceVideoPreview = false;
        this.showRightPanel = true;
    }
    CreatedVideosComponent_PlayVideo(videoItem) {
        videoItem.show_player = true;
        this.sSelectedVideoId = videoItem.fpath;
        let __this = this;
        if(this.videoListingManager&& this.videoListingManager.videos){
            setTimeout(() => {

                __this.videoListingManager.videos.forEach((element) => {
                    var video = <HTMLVideoElement>document.getElementById(element.fpath);
                    if (element.fpath == __this.sSelectedVideoId) {
                        video.play();
                    }
                });
            }, 100);
        }


    }
    getSafePath(video){
        return video.fpath;
        // return encodeURIComponent(video.fpath);
    }
    selectVideo(inCommingVideo){
        this.clearSearchTerm();
        // console.log("chkvideo",inCommingVideo);
        // this.videoPreviewPlayer.src = inCommingVideo.video_url;
        this.voiceStore.voiceVideoPreviewVideoSrc = inCommingVideo.video_url? inCommingVideo.video_url : inCommingVideo.url;
        this.previewVideoAudioSync();
    }
    // file upload version v2
    openFileUploadModalV2(type, head) {
        this.dialogStore.openFileUploadModal(
            (data) => {
                if (data?.status == 'success') {
                    const uploadedFiles = data.result[0];
                    uploadedFiles.video_url = uploadedFiles.url;


                    this.selectVideo(uploadedFiles);
                }
            },

            (data) => {
                if (data && data.length > 0) {
                    data[0].video_url = data[0].url;
                    this.selectVideo(data[0]);
                }
            },
            {fileType : type,
            heading: head}
        );
    }

    AudioSidePanelComponent_PlayAudio(oIncommingAudioUrl) {
        this.oAudio = new Audio();
        this.oAudio.src = oIncommingAudioUrl;
        this.oAudio.load();
        this.oAudio.play();
    }
    AudioSidePanelComponent_StopAudio(oIncommingAudioUrl) {
        let filename = oIncommingAudioUrl?.replace(/^.*(\\|\/|\:)/, '');
        if (this.oAudio.src?.replace(/^.*(\\|\/|\:)/, '') == filename) {
            this.oAudio.pause();
            this.oAudio = new Audio();
            this.oAudio.src = oIncommingAudioUrl;
            this.oAudio.load();
            this.oAudio.pause();
        }
    }
}
