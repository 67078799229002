import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MediaManagerService } from 'src/app/Managers/media-manager.service';
import { mediaFile } from 'src/app/models/media';
import { ErrorService } from 'src/app/services/error.service';
import { SuccessMessageModalService } from 'src/app/services/success-message-modal.service';

import { ConfigManagerService } from './../../Managers/config-manager.service';
import { CreateVideoManagerService } from './../../Managers/create-video-manager.service';
import { cDataService } from './../../services/data.service';
import { cSessionService } from './../../services/session.service';
import { cTemplateConfigService } from './../../services/template.service';
import { cWebSocketService } from './../../services/websocket.service';

@Component({
    selector: 'app-voiceover-sidepanel',
    templateUrl: './voiceover-sidepanel.component.html',
    styleUrls: ['./voiceover-sidepanel.component.scss'],
})
export class VoiceoverSidepanelComponent implements OnInit {
    bUploadVoiceOverFile: boolean;
    oUploadedVoiceOverFile: any;
    nVoiceOverFileSize: number = 0;
    sSessionId: any;
    sUserEmail: any;
    sUploadedVoiceOverFileName: string = 'No file choosen.';
    bShowTextToSpeechSection: boolean = true;
    sSelectedVoiceGender: string = 'male';
    oUserVoiceOverList: any = [];
    oVoiceOverList: any = [];
    public sVoiceName: string = '';
    nSelectedVoiceIndex: number;
    fVoiceoverForm: FormGroup;
    oAudio = new Audio();
    oSavedFormData: any;
    sVideoId: string;
    // sEditVideoId: string;
    lBlockConfigurations: any = [];
    bLetProntoChoose: boolean = true;
    bDisplayMyMediaSection: boolean = false;
    bDisplayUploadSection: boolean = true;
    lFilestoUpload: any = [];
    lUploadedFiles: any = [];
    lUserSelectedVisuals: any = [];
    sResetTextOverLays: string;
    bAudioSidepanel: boolean = false;
    va_text: string = 'va text';
    selectedDefaultVoiceOver = undefined;
    hasUploadNewVoiceFile = false;
    isTurnOff = false;
    isVoiceoverAdded = false;
    uploadVoice = true;
    selectVoice = false;
    editVoicePanel = true;
    isVoiceMuted: boolean = false;
    @Output() onClose = new EventEmitter<any>();
    @Output() oVoiceOverFileurl = new EventEmitter<string | any>();
    @Output() updateVoiceFileLength = new EventEmitter<any>();
    constructor(
        private oWebSocketService: cWebSocketService,
        private oSessionService: cSessionService,
        private oDataService: cDataService,
        private oFormBuilder: FormBuilder,
        private oTemplateConfigService: cTemplateConfigService,
        public videoManager: CreateVideoManagerService,
        public congifManager: ConfigManagerService,
        private mediaManager: MediaManagerService,
        private errorMessage: ErrorService,
        private successMessage: SuccessMessageModalService,
    ) {}

    async ngOnInit() {
        this.sSessionId = this.oSessionService.SessionService_GetSessionId();
        this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
        // this.sVideoId = this.oSessionService.SessionService_GetVideoId();
        // this.sEditVideoId = this.oSessionService.SessionService_GetEditVideoId();

        this.congifManager.voices.sort(this.sortAlphaNum);
        this.oUserVoiceOverList = this.congifManager.voices;

        if (!this.congifManager.voices || this.congifManager?.voices.length == 0) {
            await this.congifManager.getVoices(this.sUserEmail, this.sSessionId).then((c) => {
                this.oUserVoiceOverList = this.congifManager.voices;
            });
        }

        this.va_text = this.videoManager?.selectedVideo?.vo_text;

        if (this.videoManager?.selectedVideo?.selected_voice && this.videoManager?.selectedVideo?.selected_voice != '') {
            this.sVoiceName = this.videoManager?.selectedVideo?.selected_voice;

            if (this.congifManager.voices) {
                this.congifManager.voices.forEach((element) => {
                    if (element.name == this.sVoiceName) {
                        this.sSelectedVoiceGender = element.gender;
                    }
                });
            }
        } else {
            const elmFirst = this.congifManager.voices[0];
            // console.log( " elmFirst ", elmFirst);
            this.sSelectedVoiceGender = elmFirst.gender;
            this.sVoiceName = elmFirst.name;
            this.sSelectedVoiceGender = 'male';
            // console.log( "this ", this.sVoiceName);
        }

        if (this.videoManager.selectedVideo.vo_mute) {
            // this.isVoiceMuted = this.videoManager.selectedVideo.vo_mute;
            this.isVoiceMuted = false;
        }

        this.videoManager.selectedVideo.vo_mute = false;

        //this.oUserVoiceOverList.sort
        //console.log('voices2',this.oVoiceOverList);
        //console.log('voicesaaa',this.oVoiceOverList.sort(this.sortAlphaNum))
        // this.sSelectedVoiceGender =  this.congifManager.voices.filter(c=> c.name === this.videoManager.selectedVideo.selected_voice)[0]?.gender;
        // this.oSavedFormData = this.oSessionService.cSessionService_GetContentForm();
        this.ContentComponent_SelectVoiceGender(this.sSelectedVoiceGender, 'create');
        // this.sVoiceName = this.videoManager?.selectedVideo?.selected_voice;

        // if(this.nIncomingVoiceoverFile)
        // {
        //   this.voiceoverSource=this.nIncomingVoiceoverFile.url;
        // }
        /* if(this.oSavedFormData)
    {
      this.fVoiceoverForm = this.oFormBuilder.group(
      {
        session_id: this.oSavedFormData.session_id,
        email: this.oSavedFormData.email,
        vo_text : [this.oSavedFormData.vo_text],
        selected_voice : [this.oSavedFormData.selected_voice],
        pronto_clips : [this.oSavedFormData.pronto_clips],
        oUploadedVoiceOverFile : [this.oSavedFormData.oUploadedVoiceOverFile],
        nVoiceOverFileSize : [this.oSavedFormData.nVoiceOverFileSize],
        sGender : [this.oSavedFormData.sGender],
        lUploadedFiles : [this.oSavedFormData.lUploadedFiles],
        lUserSelectedVisuals : [this.oSavedFormData.lUserSelectedVisuals],
        bShowTextToSpeechToggle : this.oSavedFormData.bShowTextToSpeechToggle,
        bLetProntoChooseToggle : this.oSavedFormData.bLetProntoChooseToggle,
        bMyMediaLibraryToggle : this.oSavedFormData.bMyMediaLibraryToggle,
        bUploadSectionToggle : this.oSavedFormData.bUploadSectionToggle,
        lBlockConfig : [this.oSavedFormData.lBlockConfig],
        music_file : [this.oSavedFormData.music_file],
        cta : [this.oSavedFormData.cta]
      });
        this.bLetProntoChoose = this.oSavedFormData.bLetProntoChooseToggle;
        this.bDisplayMyMediaSection = this.oSavedFormData.bMyMediaLibraryToggle;
        this.bDisplayUploadSection = this.oSavedFormData.bUploadSectionToggle;
        this.bShowTextToSpeechSection = this.oSavedFormData.bShowTextToSpeechToggle;
        this.nVoiceOverFileSize = this.oSavedFormData.nVoiceOverFileSize;
        this.lUploadedFiles = this.oSavedFormData.lUploadedFiles;
        this.lUserSelectedVisuals = this.oSavedFormData.lUserSelectedVisuals;
        this.lBlockConfigurations=this.oSavedFormData.lBlockConfig;
        console.log("ContentComponent : BLOCK CONFIG ==> ",this.lBlockConfigurations);
        if(this.oSavedFormData.oUploadedVoiceOverFile)
        {
          this.sUploadedVoiceOverFileName = this.oSavedFormData.oUploadedVoiceOverFile.filename;
        }
        else
        {
          this.ContentComponent_SelectVoiceGender(this.oSavedFormData.sGender,'edit');
        }
    }
    else
    {
      this.fVoiceoverForm = this.oFormBuilder.group(
      {
        session_id: this.sSessionId,
        email: this.sUserEmail,
        vo_text : [''],
        selected_voice : [''],
        pronto_clips : [this.bLetProntoChoose],
        sGender : ['male'],
        oUploadedVoiceOverFile : [this.oUploadedVoiceOverFile],
        nVoiceOverFileSize : [0],
        lUploadedFiles : [this.lUploadedFiles],
        lUserSelectedVisuals : [this.lUserSelectedVisuals],
        bShowTextToSpeechToggle : this.bShowTextToSpeechSection,
        bLetProntoChooseToggle : this.bLetProntoChoose,
        bMyMediaLibraryToggle : this.bDisplayMyMediaSection,
        bUploadSectionToggle : this.bDisplayUploadSection,
        lBlockConfig : [[]],
        music_file : [this.oSavedFormData.music_file],
        cta : ['']
      });
      //by default male voice will be selected
      this.ContentComponent_SelectVoiceGender('male','create');
      const nSelectedDuraction  = this.oSessionService.cSessionService_GetOverviewForm().duration;
      const sSelectedTemplateName = this.oSessionService.cSessionService_GetLooknFeelForm().template_name;
      this.lBlockConfigurations=this.oTemplateConfigService.TemplateConfigService_FetchTemplateBlocks(nSelectedDuraction,sSelectedTemplateName);
      console.log("ContentComponent : BLOCK CONFIG ==> ",this.lBlockConfigurations);
      this.fVoiceoverForm.patchValue({lBlockConfig : this.lBlockConfigurations});
    }*/

    }

    ContentComponent_ToggleFileUploadType(nIncommingType: boolean) {
        this.bUploadVoiceOverFile = nIncommingType;
    }
    async ContentComponent_UploadedVoiceOverFile(event) {
        this.ContentComponent_UploadVoiceover();
        let file = event.target.files[0];
        if (file) {
            this.nVoiceOverFileSize = file.size;
            // console.log("upload audio file to the compoenent : File To Upload ==> ", file);
            var oFormData: any = new FormData();
            oFormData.append('session_id', this.sSessionId);
            oFormData.append('email', this.sUserEmail);
            oFormData.append('file', file);
            try {
                let media = new mediaFile();
                media.file = file;
                media.name = file.name;
                this.successMessage.success('Audio file is uploading, please wait', 'File Upload', true, false);
                await this.mediaManager.uploadMediaDefaultLibrary(this.sUserEmail, this.sSessionId, media).then(
                    async (result: any) => {
                        this.successMessage.close();
                        this.oUploadedVoiceOverFile = result;
                        this.sUploadedVoiceOverFileName = file.name;
                        this.hasUploadNewVoiceFile = true;

                        // this.videoManager.selectedVideo.voice_file_url = result.url;
                        // this.videoManager.selectedVideo.voice_file = result.visual_path;
                        this.oUploadedVoiceOverFile.fpath = result.visual_path;
                        this.oUploadedVoiceOverFile.voice_file = this.oUploadedVoiceOverFile.visual_path;
                        this.oUploadedVoiceOverFile.voice_file_url = result.url;
                        this.oUploadedVoiceOverFile.selected_voice = this.videoManager.selectedVideo.selected_voice;

                        // this.videoManager.selectedVideo.selected_voice = this.videoManager.selectedVideo.selected_voice;
                        // console.log( " updated object here ...", this.videoManager.selectedVideo);
                        // this.updateVoiceFileLength.emit();
                        // this.videoManager.selectedVideo.voice_file_url

                        //  this.fVoiceoverForm.patchValue({oUploadedVoiceOverFile : this.oUploadedVoiceOverFile, nVoiceOverFileSize : this.nVoiceOverFileSize});
                    },
                    (error) => {
                        this.successMessage.close();
                        this.oUploadedVoiceOverFile = null;
                        this.errorMessage.handleError(
                            error.error.errorMessage ? error.error.errorMessage : error.error,
                            'Upload Audio',
                            true,
                        );
                        // this.sDisplayResponseMessage = error.error.errorMessage;
                        // this.bDisplayErrorMessage = true;
                        // setTimeout(()=>{this.bDisplayErrorMessage = false;},5000);
                        console.error('ContentComponent_UploadedVoiceOverFile : Error ==>', error);
                    },
                );
            } catch (error) {
                this.successMessage.close();
                this.errorMessage.handleError(error.error.errorMessage ? error.error.errorMessage : error.error, 'Upload Audio', true);
                console.error('ContentComponent_UploadedVoiceOverFile : ERROR ==>', error);
            }
        }
    }
    ContentComponent_UploadVoiceover() {
        this.bShowTextToSpeechSection = false;
        // // this.fVoiceoverForm.patchValue({bShowTextToSpeechToggle : this.bShowTextToSpeechSection});
        //  this.fVoiceoverForm.removeControl('vo_text');
        //  this.fVoiceoverForm.removeControl('selected_voice');
        //  this.fVoiceoverForm.removeControl('sGender');
        //  this.fVoiceoverForm.removeControl('vo_text_file');
        //  this.fVoiceoverForm.addControl('oUploadedVoiceOverFile', new FormControl('', [Validators.required]));
    }
    ContentComponent_TextToSpeech() {
        this.bShowTextToSpeechSection = true;
        // this.oVoiceOverFileurl.emit('');
        this.nVoiceOverFileSize = 0;
        //   this.fVoiceoverForm.patchValue({nVoiceOverFileSize : this.nVoiceOverFileSize});
        this.sUploadedVoiceOverFileName;
        //    this.fVoiceoverForm.patchValue({bShowTextToSpeechToggle : this.bShowTextToSpeechSection});
        ///   this.fVoiceoverForm.removeControl('oUploadedVoiceOverFile');
        //   this.fVoiceoverForm.addControl('vo_text', new FormControl("", [Validators.required]));
        //  this.fVoiceoverForm.setControl('vo_text', this.oFormBuilder.control(""));
        //    this.fVoiceoverForm.addControl('selected_voice', new FormControl('', [Validators.required]));
        this.ContentComponent_SelectVoiceGender('male', 'create');
    }
    ContentComponent_SelectVoiceGender(value: any, mode: string) {
        this.sSelectedVoiceGender = value;
        this.sVoiceName = '';
        this.oVoiceOverList = [];
        this.oUserVoiceOverList.forEach((element) => {
            if (element.gender == value) {
                this.oVoiceOverList.push(element);
            }
        });

        this.sVoiceName = this.videoManager?.selectedVideo?.selected_voice;
        if (!this.videoManager?.selectedVideo?.selected_voice) {
            this.sVoiceName = this.oUserVoiceOverList[0].name;
        }
        if (mode === 'create') {
            // this.sVoiceName = this.videoManager?.selectedVideo?.selected_voice ? this.videoManager?.selectedVideo?.selected_voice : '';
            let nVoiceIndex = this.oVoiceOverList.findIndex((element: any) => {
                return element.name === this.sVoiceName;
            });
            // this.sVoiceName = this.oVoiceOverList[2].name;

            if (nVoiceIndex == -1) {
                nVoiceIndex = 0;
            }
            this.ContentComponent_SelectVoice(this.oVoiceOverList[nVoiceIndex], nVoiceIndex);
            this.sVoiceName = this.oVoiceOverList[nVoiceIndex] ? this.oVoiceOverList[nVoiceIndex].name : '';
            // this.ContentComponent_SelectVoice(this.oVoiceOverList[nVoiceIndex], nVoiceIndex);
        } else {
            const nVoiceIndex = this.oVoiceOverList.findIndex(
                (element: any) => element.value === this.videoManager.selectedVideo.selected_voice,
            );
            this.ContentComponent_SelectVoice(this.oVoiceOverList[nVoiceIndex], nVoiceIndex);
        }
        // this.fVoiceoverForm.addControl('sGender', new FormControl());
        // this.fVoiceoverForm.setControl('sGender', this.oFormBuilder.control(value));
    }
    ContentComponent_SelectVoice(select, index) {
        // console.log(index, select?.name);
        this.hasUploadNewVoiceFile = false;
        this.nSelectedVoiceIndex = index;
        this.sVoiceName = select?.name;
        this.selectedDefaultVoiceOver = select?.sample_voice;
        // this.fVoiceoverForm.patchValue({selected_voice : select.value});
    }
    VoiceOVerAdded() {
        this.isVoiceoverAdded = true;
        setTimeout(() => {
            this.isVoiceoverAdded = false;
        }, 3000);
    }
    ContentComponent_ReturnFileSize(bytes, decimals = 2) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    ContentComponent_PlayAudio(filepath) {
        this.selectedDefaultVoiceOver = filepath;
        let filename = filepath.replace(/^.*(\\|\/|\:)/, '');
        // console.log(filepath);
        this.oAudio = new Audio();
        this.oAudio.src = filepath;
        this.oAudio.load();
        this.oAudio.play();
    }
    ContentComponent_StopAudio(filepath) {
        let filename = filepath.replace(/^.*(\\|\/|\:)/, '');
        // console.log(filepath);
        if (this.oAudio.src.replace(/^.*(\\|\/|\:)/, '') == filename) {
            this.oAudio.pause();
            this.oAudio = new Audio();
            this.oAudio.src = filepath;
            this.oAudio.load();
        }
    }
    VideoTimelineView_SubmitfVoiceoverForm() {
        // console.log("voice",  this.videoManager.selectedVideo);
        // console.log("bShowTextToSpeechSection",  this.bShowTextToSpeechSection);
        // console.log("Voice over replaced .....", this.bShowTextToSpeechSection);
        console.log('new voice file ', this.hasUploadNewVoiceFile);
        if (this.hasUploadNewVoiceFile) {
            // console.log(" this.oUploadedVoiceOverFile ", this.oUploadedVoiceOverFile.voice_file);
            // console.log(" this.oUploadedVoiceOverFile.visual_path ", this.oUploadedVoiceOverFile.visual_path);
            // // this.videoManager.selectedVideo.fpath = this.oUploadedVoiceOverFile.fpath;
            this.oUploadedVoiceOverFile.voice_file = this.oUploadedVoiceOverFile.visual_path;

            this.videoManager.selectedVideo.voice_file = this.oUploadedVoiceOverFile.visual_path
                ? this.oUploadedVoiceOverFile.voice_file
                : this.oUploadedVoiceOverFile.voice_file;
            this.videoManager.selectedVideo.voice_file_url = this.oUploadedVoiceOverFile.voice_file_url;
            // this.videoManager.selectedVideo.selected_voice = this.oUploadedVoiceOverFile.selected_voice;
            console.log(' -- this.videoManager.selectedVideo.voice_file ', this.videoManager.selectedVideo.voice_file);
            console.log(' this.videoManager.selectedVideo ', this.videoManager.selectedVideo);

            this.oVoiceOverFileurl.emit({ defaultVoiceChange: true, visual_path: this.videoManager.selectedVideo.voice_file });
            this.updateVoiceFileLength.emit({ visual_path: this.videoManager.selectedVideo.voice_file });
            // this.videoManager.selectedVideo.vo_text = this.va_text;
            // this.videoManager.selectedVideo.selected_voice = this.sVoiceName;
            // this.videoManager.selectedVideo.voice_file_url = this.selectedDefaultVoiceOver;
            // this.videoManager.selectedVideo.voice_file = this.sVoiceName;
        } else {
            this.nVoiceOverFileSize = 0;
            this.videoManager.selectedVideo.vo_text = this.va_text;
            this.videoManager.selectedVideo.selected_voice = this.sVoiceName;
            this.videoManager.selectedVideo.voice_file_url = this.selectedDefaultVoiceOver;
            this.videoManager.selectedVideo.voice_file = this.sVoiceName;
            this.oVoiceOverFileurl.emit({ defaultVoiceChange: true });
        }
        // if (this.bShowTextToSpeechSection) {
        //   this.videoManager.selectedVideo.vo_text = this.va_text;
        //   this.videoManager.selectedVideo.selected_voice = this.sVoiceName;
        //   this.videoManager.selectedVideo.voice_file_url = this.selectedDefaultVoiceOver;
        //   this.videoManager.selectedVideo.voice_file = this.sVoiceName;
        //   this.oVoiceOverFileurl.emit({defaultVoiceChange : true});
        // }
        // //if user uploads voice over file
        // else {
        //   // console.log("File Path",   this.oUploadedVoiceOverFile.fpath);
        //   this.videoManager.selectedVideo.voice_file =
        //     this.oUploadedVoiceOverFile.fpath;
        //   this.videoManager.selectedVideo.selected_voice = "";
        //   this.videoManager.selectedVideo.vo_text = "";
        //   this.oVoiceOverFileurl.emit(this.oUploadedVoiceOverFile);
        // }

        // this.oSavedFormData = this.oSessionService.cSessionService_GetContentForm();
        // console.log("Getting Form From Local Storage ====>>>",this.oSavedFormData)
        // this.fVoiceoverForm.patchValue({
        //  music_file : this.oSavedFormData.music_file,
        // });
        //  this.oSessionService.cSessionService_SetContentForm(this.fVoiceoverForm.value);
        // console.log("Form Value From TimeLine ==>>>",this.fVoiceoverForm.value);
        this.hasUploadNewVoiceFile = false;
        this.closeVoiceover();
    }

    closeVoiceover(): void {
        this.onClose.emit();
        //this.visibleMedia = false;
        this.isVoiceoverAdded = false;
    }

    sortAlphaNum(a, b) {
        // console.log(a);
        // console.log(b);
        let reA = /[^a-zA-Z]/g;
        let reN = /[^0-9]/g;
        var aA = a.name.replace(reA, '');
        var bA = b.name.replace(reA, '');

        if (aA === bA) {
            var aN = parseInt(a.name.replace(reN, ''), 10);
            var bN = parseInt(b.name.replace(reN, ''), 10);
            return aN === bN ? 0 : aN > bN ? 1 : -1;
        } else {
            return aA > bA ? 1 : -1;
        }
    }
    VideoTimelineView_TurnOffVoiceOver() {
        this.isTurnOff = true;
        this.nVoiceOverFileSize = 0;
        this.va_text = '';
        if (this.hasUploadNewVoiceFile) {
            this.videoManager.selectedVideo.fpath = '';
            this.videoManager.selectedVideo.voice_file = '';
            this.videoManager.selectedVideo.voice_file_url = '';
            this.videoManager.selectedVideo.selected_voice = '';
            this.oVoiceOverFileurl.emit({ defaultVoiceChange: true });
        } else {
            this.videoManager.selectedVideo.vo_text = '';
            this.videoManager.selectedVideo.selected_voice = '';
            this.videoManager.selectedVideo.voice_file_url = '';
            this.videoManager.selectedVideo.voice_file = '';
            this.oVoiceOverFileurl.emit({ defaultVoiceChange: true });
        }
        this.hasUploadNewVoiceFile = false;
        this.closeVoiceover();
    }
    selectTab(tab) {
        if (tab == 'uploadVoice') {
            this.uploadVoice = true;
            this.selectVoice = false;
        } else {
            this.selectVoice = true;
            this.uploadVoice = false;
        }
    }

    voiceUpdatedObject: any;
    saveVoiceDate() {
        //  console.log("save voice data ", this.voiceUpdatedObject);
         this.updateVideo('voice_file_obj', this.voiceUpdatedObject);

         if( (!this.voiceUpdatedObject.vo_file || this.voiceUpdatedObject.vo_file == '')){
            this.voiceUpdatedObject.vo_text = "";
            this.voiceUpdatedObject.vo_file = "";
            this.voiceUpdatedObject.voice_file = "";
         }
        if (this.voiceUpdatedObject && (this.voiceUpdatedObject.vo_file || this.voiceUpdatedObject.vo_text_file || this.voiceUpdatedObject.voice_file)) {
            var startFrom = this.videoManager?.selectedVideo?.voice_file_obj?.start_from ? this.videoManager?.selectedVideo?.voice_file_obj?.start_from : 0;
            this.updateVideo('voice_wellsaid_selection', this.voiceUpdatedObject.voice_wellsaid_selection);
            this.updateVideo('vo_gender', this.voiceUpdatedObject.vo_gender);

            if (this.voiceUpdatedObject.voice_wellsaid_selection == 'voiceover') {
                this.updateVideo('vo_gender', this.voiceUpdatedObject.vo_gender);
                this.updateVideo('vo_text', this.voiceUpdatedObject.vo_text);
                this.updateVideo('vo_file', this.voiceUpdatedObject.vo_file ? this.voiceUpdatedObject.vo_file : '');
                this.updateVideo('vo_text_file', this.voiceUpdatedObject.voice_file ? this.voiceUpdatedObject.voice_file : '');
                this.updateVideo('voice_file', this.voiceUpdatedObject.voice_file ? this.voiceUpdatedObject.voice_file : '');
                this.updateVideo('voice_wellsaid_id', this.voiceUpdatedObject.voice_wellsaid_id);
                this.updateVideo('vo_file_url', this.voiceUpdatedObject.vo_file_url);
                this.voiceUpdatedObject.start_from = startFrom;
                this.updateVideo('voice_file_obj', this.voiceUpdatedObject.voice_file_obj);
                this.videoManager.selectedVideo.voice_file_obj.start_from = startFrom;

                // console.log(this.videoManager.selectedVideo);
            } else if (this.voiceUpdatedObject.voice_wellsaid_selection == 'upload') {

                this.updateVideo('voice_file', this.voiceUpdatedObject.voice_file_obj.fpath ? this.voiceUpdatedObject.voice_file_obj.fpath : '');
                this.updateVideo('vo_file', this.voiceUpdatedObject.voice_file_obj.fpath ? this.voiceUpdatedObject.voice_file_obj.fpath : '');
                this.updateVideo('vo_text_file', this.voiceUpdatedObject.voice_file_obj.fpath ? this.voiceUpdatedObject.voice_file_obj.fpath : '');

                let uploadedFileObj = this.voiceUpdatedObject.voice_file_obj;
                delete uploadedFileObj.media_lib;
                delete uploadedFileObj.media_lib_id;
                delete uploadedFileObj.organization_id;
                delete uploadedFileObj.updated;
                delete uploadedFileObj.created;
                delete uploadedFileObj._id;
                delete uploadedFileObj.brand;
                delete uploadedFileObj.brand_id;
                delete this.videoManager.selectedVideo.voice_wellsaid_id;
                delete this.videoManager.selectedVideo.vo_text;
                this.voiceUpdatedObject.start_from = startFrom;
                this.updateVideo('voice_file_obj', uploadedFileObj);
                this.videoManager.selectedVideo.voice_file_obj.start_from = startFrom;
                this.updateVideo('voice_file_url', this.voiceUpdatedObject.voice_file_url);

                // console.log(this.videoManager.selectedVideo);
            }
        } else {
            // console.log("Clear all Voices ");
            this.updateVideo('vo_file', "");
            this.updateVideo('vo_text_file', "");
            this.updateVideo('voice_file', "");
        }
        this.oVoiceOverFileurl.emit({ defaultVoiceChange: true, visual_path: this.videoManager.selectedVideo.voice_file });
        this.updateVoiceFileLength.emit({ visual_path: this.videoManager.selectedVideo.voice_file });
        // console.log("save voice data ends ...", this.videoManager.selectedVideo);
        this.closeVoiceover();
    }
    private updateVideo(attr: string, val: any) {
        this.videoManager.selectedVideo[attr] = val;
    }
    updateVoiceRecords($event) {
        this.voiceUpdatedObject = $event;
        // console.log(" saved Voice params this.voiceUpdatedObject ", this.voiceUpdatedObject);

        // console.log(" update voice info ");
    }
   muteVoice(event : any) {
       this.isVoiceMuted = event.target.checked;
       this.videoManager.selectedVideo.vo_mute = this.isVoiceMuted;
   }
}
