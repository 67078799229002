import { Component, OnInit } from '@angular/core';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { ConfigManagerService } from './../../Managers/config-manager.service';
import { OnboardingManagerService } from './../../Managers/onboarding-manager.service';
import { profile } from './../../models/onboarding';
import { cSessionService } from './../../services/session.service';

@Component({
    selector: 'app-account-setting',
    templateUrl: './account-setting.component.html',
    styleUrls: ['./account-setting.component.scss'],
})
export class AccountSettingComponent implements OnInit {
    sUserEmail: any;
    sSessionId: any;
    session: any;
    sideBarActive: any;
    constructor(
        public oSessionService: cSessionService,
        public onboardingManager: OnboardingManagerService,
        public configManager: ConfigManagerService,
    ) {
      console.log(' --- Account setting called .....  ');
        this.session = oSessionService;
        this.sSessionId = this.oSessionService.SessionService_GetSessionId();
        this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
        if (this.sSessionId !== '' && this.sUserEmail !== '') {
            if (!this.onboardingManager.profile) this.getProfile(this.sUserEmail, this.sSessionId);
        }
    }
    async getProfile(email, session) {
        await this.onboardingManager.getUserProfile(email, session).then((res: profile) => {});
    }
    ngOnInit(): void {
        this.sideBarActive = this.oSessionService.isSidebarActive;
        //  console.log("sideBarActive", this.sideBarActive);
    }
}
