                <!-- <div id="main-content" class="main-area"> -->
                    <div class="video-header row m-0 ms-3 px-4 pt-4 ps-0">
                        <p class="h3 weight-600 text-gray-2 pb-1 px-0 col-lg-8 mb-0">
                            <a href="javascript:void(0)"  (click)="loadBrandMediaLibrary()" class="me-2">
                                <img src="../../../../../assets/images/svg/back-arrow-with-tail.svg">
                            </a>
                            {{this.brandDetail.name && this.brandDetail.name!=''?this.brandDetail.name: 'Business Name'}}

                        </p>
                        <!-- <div class="ps-4 ms-2 mb-3">
                            <a href="#" class="text-xs text-gray-2">Admin</a> .
                            <a href="#" class="text-xs text-gray-3">Active</a>
                        </div> -->
                    </div>
                    <div class="container mx-0 ms-4 ps-1 business-media" id="container-view">

                        <div class="row filter-post">
                            <div class="col-md-12 p-0">
                                <ul class="breadcrumb-tabs nav m-0">
                                    <!-- <li class="nav-item ps-0">
                                        <a href="javascript:void(0)" (click)="loadBrandAccount()"
                                        class="nav-link">Account</a>
                                    </li> -->
                                    <li class="nav-item">
                                      <a href="javascript:void(0)" (click)="loadBrandBusiness()"
                                        class="nav-link"
                                        >Business Details</a
                                      >
                                    </li>
                                    <li class="nav-item">
                                      <a  [routerLink]="['/account-setting/manage-brand/', brandId, 'published-videos']"     class="nav-link"
                                        >Published Videos</a
                                      >
                                    </li>
                                    <li class="nav-item">
                                        <a href="javascript:void(0)" (click)="loadBrandMediaLibrary()"
                                        class="nav-link active">Media
                                            Library</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>


                    <div class="container mx-0 ms-4 ps-1 business-media" id="container-view">
                        <div class="row filter-post pt-4"><h4 class="ps-0">{{this.mediaLibraryDetail.name? this.mediaLibraryDetail.name  :"Brand Media Library" }}</h4></div>

                        <app-media-library-shared-myaccount
                        [showMediaLibAccess]="false"
                        [manageBrandMediaLib]="true"
                        [fullWidthView]="true"
                        [setBrandMediaLibraryName]="this.mediaLibraryDetail.name"
                        *ngIf="brandMediaId && brandMediaId != ''"></app-media-library-shared-myaccount>
                    </div>

