import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { cDataService } from './../../../services/data.service';
import { cSessionService } from './../../../services/session.service';
import { environment } from 'src/environments/environment';
import { OnboardingManagerService } from './../../../Managers/onboarding-manager.service';
import { profile, user } from './../../../models/onboarding';
import { ConfigManagerService } from './../../../Managers/config-manager.service';
import { ErrorService } from './../../../services/error.service';
import * as moment from 'moment';
import { SubscriptionComponent } from '../../myAccount/subscription/subscription.component';
import { SubscriptionplanManagerService } from 'src/app/Managers/subscriptionplan-manager.service';
import { VoiceDataStore } from 'src/app/stores/voice.store';

declare var $: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    sFacebookSigninLink: string;
    sGoogleSigninLink: string;
    sLinkedInSigninLink: string;
    sServerSocketURL = environment.API_HOST;
    fLoginForm: FormGroup;
    bDisplayPassword: boolean = false;
    bDisplayErrorMsg: boolean = false;
    sErrorMessage: string;
    oMetaInfo: any;
    nRedirectURl: any;
    sSessionId: any;

    constructor(
        private oCookieService: CookieService,
        private subscrptionManager: SubscriptionplanManagerService,
        private oFormBuilder: FormBuilder,
        private onboardingManager: OnboardingManagerService,
        private oRouter: Router,
        private route: ActivatedRoute,
        private oDataService: cDataService,
        private oSessionService: cSessionService,
        private oActivatedRoute: ActivatedRoute,
        private configManager: ConfigManagerService,
        public errorService: ErrorService,
        private voiceStore : VoiceDataStore
    ) { }

    ngOnInit() {
        console.log('Enter login page ..');
        this.oSessionService.SessionService_SetVideoTimeout();
        this.sSessionId = this.oSessionService.SessionService_GetSessionId();
        const sEmailRejex = /(^[\w-\.]+@([\w-]+\.)+[\w-]{2,14}$)/;
        const passRejex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@\-#\$%\^&\*])/;
        this.fLoginForm = this.oFormBuilder.group({
            email: ['', [Validators.required, Validators.pattern(sEmailRejex)]],
            password: ['', [Validators.required]],
        });
        if (!this.sSessionId) {
            let intercomScript1 = document.getElementById('intercom1');
            let intercomScript2 = document.getElementById('intercom2');
            if (intercomScript1) intercomScript1.remove();
            if (intercomScript2) intercomScript2.remove();

            const allCookie: any = this.oCookieService.getAll();
            Object.keys(allCookie).forEach((cookieItem) => {
                if (cookieItem != 'pronto-impact') {
                    this.oCookieService.delete(cookieItem);
                }
            });
            // this.oCookieService.deleteAll();
        }

        this.oActivatedRoute.queryParams.subscribe((params: Params) => {
            //facebook and google login configurations
            const authResult = this.oActivatedRoute.snapshot.queryParams['auth_result'];
            // console.log("Auth result : ==> ", authResult);
            //social share response
            const socialShareResult = this.oActivatedRoute.snapshot.queryParams['result'];
            // console.log("Social Share response : ==> ", socialShareResult);
            //redirection message
            const message = this.oActivatedRoute.snapshot.queryParams['message'];
            // console.log("Response Message : ==> ", message);

            this.sErrorMessage = message;
            if (authResult || socialShareResult) {
                this.nRedirectURl = this.oSessionService.cSessionService_GetRedirectURL();
                if (authResult) {
                    //connect/disconnect response
                    if (
                        authResult == 'google_auth_successful' ||
                        authResult == 'fb_auth_successful' ||
                        authResult == 'linkedin_auth_successful' ||
                        authResult == 'success'
                    ) {
                        // console.log("Into success case ..");
                        if (this.nRedirectURl == 0) {
                            const email = this.oActivatedRoute.snapshot.queryParamMap.get('email');
                            const sessionId = this.oActivatedRoute.snapshot.queryParamMap.get('session_id');
                            this.oSessionService.cSessionService_SetUserEmail(email);
                            this.oSessionService.cSessionService_SetSessionId(sessionId);
                            this.oSessionService.SessionService_StartSessionExpiryTimer();
                            this.oRouter.navigateByUrl('/dashboard/myvideos');
                        } else {
                            this.LoginComponent_RedirectToRespectivePage(this.nRedirectURl);
                        }
                    } else {
                        if (this.nRedirectURl == 0) {
                            this.bDisplayErrorMsg = true;
                            setTimeout(() => {
                                this.bDisplayErrorMsg = false;
                            }, 5000);
                        } else {
                            this.LoginComponent_RedirectToRespectivePage(this.nRedirectURl);
                        }
                    }
                } else {
                    //social share response
                    this.oSessionService.cSessionService_SetRedirectionMessage(socialShareResult);
                    this.LoginComponent_RedirectToRespectivePage(this.nRedirectURl);
                }
            } else {
                const session_id = this.oSessionService.SessionService_GetSessionId();

                const email = this.oSessionService.cSessionService_GetUserEmail();
                if (session_id) {
                    this.getProfile(email, session_id);
                    // this.LoginComponent_GetUserVideosCount(alreadyLogin)
                    // this.oRouter.navigateByUrl("/dashboard/myvideos");
                }
            }
        });
    }
    async getProfile(email, session) {
        await this.onboardingManager.getUserProfile(email, session).then(async (res: profile) => {
            this.configManager.getTemplates2(email, session, res.allowed_templates);

            await this.onboardingManager.getUserVideosCount(email, session).then((count : any) => {
                if (this.onboardingManager.profile.user_status == 'canceled') {
                    console.log('canceled---' + this.onboardingManager.profile);
                    var date = new Date(this.onboardingManager.profile.next_billing_date);
                    var date2 = moment(new Date(this.onboardingManager.profile.next_billing_date));
                    var tempDate = moment(new Date());

                    const diffDays = date2.diff(tempDate, 'days');
                    if (diffDays > 0) {
                        let errorMessage = 'Your subscription expires in ' + diffDays + ' days';
                        setTimeout(() => {
                            this.errorService.handleError(errorMessage, 'Subscription', true, false, true);
                        }, 3000);
                    } else {
                        if (this.configManager.metaInfo.stripe_enabled) {
                            this.oRouter.navigate(['/create-account/select-plan']);
                        }
                    }
                } else {
                    console.log( this.onboardingManager.profile.user_status ,  this.onboardingManager.profile.user_status == 'confirmed_not_subscribed');
                    if (this.onboardingManager.profile.user_status == 'confirmed_not_subscribed') {
                        console.log("NOT CONFIRMED AND SUBSCRIBED show modal ....");
                        this.subscrptionManager.showPlanDialogue();
                        console.log('route---' + this.onboardingManager.profile);
                    }

                    if (count > 0) {
                        this.oRouter.navigateByUrl('/dashboard/myvideos');
                    } else {
                        console.log('Navigate---' + this.onboardingManager.profile);
                        this.oRouter.navigateByUrl('/dashboard/first-time-user');
                    }
                }
            });
        });

        this.configManager.getVoices(email, session).then();
    }

    LoginComponent_RedirectToRespectivePage(sIncommingRedirection: number) {
        switch (sIncommingRedirection) {
            case 1:
                this.oRouter.navigateByUrl('/my-account');
                break;
            case 2:
                this.oRouter.navigateByUrl('/account-setting/social-channels');
                break;
            case 3:
                this.oRouter.navigateByUrl('/dashboard/socialmedia');
                break;
            case 4:
                this.oRouter.navigateByUrl('/published-video/timeline-view');
                break;
            default:
                this.oRouter.navigateByUrl('/my-account');
                break;
        }
    }

    LoginComponent_CreateSigninWithSocialMediaChannelsLink(oData: string) {
        let sURL: string;
        if (oData == 'facebook') {
            sURL = this.oDataService.faceBookJoinLink;
        } else if (oData == 'google') {
            sURL = this.oDataService.googleJoinLink;
        } else if (oData == 'linkedin') {
            sURL = this.oDataService.linkedInJoinLink;
        }

        console.log(' Login ::: ..... ', sURL);

        this.oSessionService.cSessionService_SetRedirectURL(0);
        location.assign(sURL);
    }

    LoginComponent_SendLoginRequest() {
        if (this.fLoginForm.valid) {
            this.Login(this.fLoginForm.get('email').value, this.fLoginForm.get('password').value);
        } else {
            this.fLoginForm.markAllAsTouched();
        }
    }

    LoginComponent_HandleMetaInfoResult = (InComingMessage) => {
        console.log('LoginComponent_HandleMetaInfoResult : Response ==> ', JSON.parse(InComingMessage));
        if (InComingMessage['errorMessage']) {
            console.error('LoginComponent_HandleMetaInfoResult : Error ==> ', InComingMessage);
        } else {
            // industries
            this.oMetaInfo = JSON.parse(InComingMessage).industry_key_terms;
            this.oSessionService.cSessionService_SetMetaInfo(this.oMetaInfo);
        }
    };

    private Login(username, password) {
        this.onboardingManager.signin(username, password).then(
            (response: user) => {
                console.log('ress' + response);
                console.log('USer Profile:', this.onboardingManager.profile);
                this.onboardingManager.profile.first_login = this.onboardingManager.profile.first_login;
                this.oSessionService.setUserOrganization(this.onboardingManager.profile.organization_id);
                // this.oSessionService.setLocalizationEnable(this.onboardingManager.profile.localization_enabled);
                this.voiceStore.updateUserVoicesFromProfile(this.onboardingManager?.profile);
                this.onboardingManager
                    .getUserVideosCount(this.fLoginForm.get('email').value, response.session_id)
                    .then((count) => {

                      this.onboardingManager.profile.video_counts = count;
                      if (this.onboardingManager.profile.plan != 'enterprise'

                      &&  (this.onboardingManager.profile.company_name == '' || !this.onboardingManager.profile.company_name)
                      &&  this.onboardingManager.profile.first_login) {
                        let profile = {
                            email : this.onboardingManager.profile.email,
                            _id : this.onboardingManager.profile._id,
                            session_id : this.onboardingManager.profile.session_id,
                            first_login : false,
                            ignore_plan: true
                        }
                        this.onboardingManager.updateBusinessDetail(profile, false).then(() => {
                            this.oRouter.navigate(['/createAccountCompany']);
                        }).catch(() => {
                            this.oRouter.navigate(['/createAccountCompany']);
                        })

                    } else {
                      let params = this.route.snapshot.queryParams;
                      if (params['redirectURL']) {
                          this.nRedirectURl = params['redirectURL'];
                      }

                            if (this.nRedirectURl) {
                                this.oRouter.navigateByUrl(this.nRedirectURl).catch(() => this.navigatetodashboard(count));
                            } else {
                                this.navigatetodashboard(count);
                            }
                        }

                    })
                    .catch((err) => {
                        if (err.errorType == 'To sign in, please verify your email. Check your inbox or spam folder for the verification email.') {
                            this.errorService.handleError(err.errorType, 'Verify Your Email to Sign In', true);
                        }
                        else {
                            this.errorService.handleError(err.errorType ? err.errorType : err.errorMessage, 'Sign in Failed', true);
                        }
                        // console.log(err);
                        // this.sErrorMessage = 'Incorrect email or password.';
                        // this.bDisplayErrorMsg = true;
                        // setTimeout(() => {
                        //     this.bDisplayErrorMsg = false;
                        // }, 5000);
                        // $('#error-popup').modal('show');
                    });
            },
            (err) => {
                console.log(err);
                let errorMessage = err.errorType ? err.errorType : err.errorMessage;
                console.log(err.type)
                if (err.errorType == 'To sign in, please verify your email. Check your inbox or spam folder for the verification email.') {
                    this.errorService.handleError(errorMessage, 'Verify Your Email to Sign In', true);
                }
                else {
                    this.errorService.handleError(err.errorType && (err.errorType != 'ValidationError') ? err.errorType : 'Invalid email or password. Please try again' , 'Sign in Failed', true);
                }
                // console.log(err);
                // this.sErrorMessage = 'Incorrect email or password.';
                // this.bDisplayErrorMsg = true;
                // setTimeout(() => {
                //     this.bDisplayErrorMsg = false;
                // }, 5000);
                // $('#error-popup').modal('show');
            },
        );
    }

    private navigatetodashboard(count: any) {
        if (this.onboardingManager.profile.user_status == 'confirmed_not_subscribed') {
            this.subscrptionManager.showPlanDialogue();
        }
        if (count > 0) {
            console.log(" *****   navigate to MY VIDEO PAGE ....");
            this.oRouter.navigateByUrl('/dashboard/myvideos');
        } else {
            this.oRouter.navigateByUrl('/dashboard/first-time-user');
        }
    }
}
